import { Component, OnInit } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router }                 from '@angular/router';
import { Store }                  from '@ngrx/store';
import { Observable }             from 'rxjs';

import * as moment from 'moment';


import { StoreState } from '../../state-management/store';

import { State as LoginDetailsState }  from '../../state-management/reducers/login-details';
import { State as WarrantyStatsState } from '../../state-management/reducers/warranty-stats';

import {
  FetchUserProfileRequestAction,
} from '../../state-management/actions/login-details';

import {
  FetchWarrantyFullStatsRequestAction,
  FetchWarrantyMonthlyStatsRequestAction,
} from '../../state-management/actions/warranty-stats';


/**
 * Summary
 *    Page allowing user to view statistics for Warranties.
 *
 * Description
 *    The Warranty Statistics page shows basic user profile information
 *    together with overall and month-based Warranty statistics.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty-statistics',
  templateUrl: './warranty-statistics.component.html',
  styleUrls: ['./warranty-statistics.component.scss']
})
export class WarrantyStatisticsComponent implements OnInit {

  // Store states
  public loginDetails$:  Observable<LoginDetailsState>;
  public warrantyStats$: Observable<WarrantyStatsState>;

  // Start and end dates for monthly stats
  public dateStart: Date = moment().subtract(1, 'years').toDate();
  public dateEnd:   Date = moment().toDate();

  // Monthly stats date selection form
  public fgDates: UntypedFormGroup;

  // Chart options for monthly stats
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
      }]
    },
  };


  constructor(
    private router: Router,
    private store:  Store<StoreState>
  ) {
    this.loginDetails$  = this.store.select('loginDetails');
    this.warrantyStats$ = this.store.select('warrantyStats');

    this.fgDates = new UntypedFormGroup({
      dateStart: new UntypedFormControl(this.dateStart),
      dateEnd:   new UntypedFormControl(this.dateEnd),
    });
  }

  ngOnInit() {
    this.store.dispatch(new FetchWarrantyFullStatsRequestAction());
    this.fetchMonthlyStats();

    // BUGFIX: dispatching in a timeout avoids
    // ExpressionChangedAfterItHasBeenCheckedError Angular error
    setTimeout(() => this.store.dispatch(new FetchUserProfileRequestAction()), 100);
  }


  /**
   * Converts month-based Warranty stats into a form usable by the chart
   *
   * @param {MonthlyWarrantyStat[]} data
   * @return {number[]}
   */
  chartData(data: any) {
    return data.map((v: any): number => v.value);
  }

  /**
   * Dispatches a request to fetch month-based Warranty statistics for the
   * selected start and end dates
   */
  fetchMonthlyStats() {
    this.store.dispatch(
      new FetchWarrantyMonthlyStatsRequestAction(
        {
          dateStart: moment(this.fgDates.value.dateStart).format('YYYY-MM-DD'),
          dateEnd:   moment(this.fgDates.value.dateEnd).format('YYYY-MM-DD'),
        }
      )
    );
  }

  /**
   * Given a profile start date, returns a formatted duration between then and
   * now
   *
   * @param {string} createdAt Start date (YYYY-MM-DD)
   * @return {string} Formatted duration
   */
  getMembershipLength(createdAt: string): string {
    return moment(createdAt).fromNow(true);
  }

  /**
   * Navigates to the /warranty-list route
   */
  goToWarrantyList() {
    this.router.navigate(['/warranty-list']);
  }
}
