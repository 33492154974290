<div class="container-fluid job-page">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">Register a job</h1>
    </div>

    <app-page-content page="JOB_SUBMIT" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="job-container background-white col-xs-12" *ngIf="state$ | async; let state">

      <app-job-details
        [productTypes]="(productState$ | async).products"
        submitLabel="Register"
        (onPreSubmit)="handleFormPreSubmit($event)"
        (onSubmit)="handleFormSubmit($event)"
      >
        <app-waiting-spinner class="form-header" kind="bar" *ngIf="state.pending">
          Registering job, please wait...
          <hr />
        </app-waiting-spinner>

        <app-message-panel class="form-header" kind="WARNING" *ngIf="state.error">
          {{ state.message }}
        </app-message-panel>

        <app-message-panel class="form-header" kind="SUCCESS" *ngIf="state.success">
          {{state.message}}
        </app-message-panel>
        <p class="product-list" *ngIf="(productState$ | async).productsPending">Loading products...</p>
      </app-job-details>

    </div>
  </div>

</div>
