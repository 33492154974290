import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AlertDataResponse,
  DeleteFilterResponse,
  DismissAlertResponse,
  FilterDataResponse,
  TimelineDataResponse,
} from '../../models/pro3';
import { Pro3Service } from '../../services/pro3.service';
import * as ActionTypes from '../actions/pro3';

@Injectable()
export class Pro3Effects {
  constructor(
    private filterService: Pro3Service,
    private actions$: Actions
  ) { }

   filterDataRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FILTER_DATA_REQUEST),
    switchMap((req: ActionTypes.FilterDataRequestAction): Observable<Action> => {
      return this.filterService.getFilterData(req.payload).pipe(
        map((res: FilterDataResponse): ActionTypes.FilterDataResponseAction =>
          new ActionTypes.FilterDataResponseAction(res)
        ));
    })));

   deleteFilterRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_FILTER_REQUEST),
    switchMap((req: ActionTypes.DeleteFilterRequestAction): Observable<Action> => {
      return this.filterService.deleteFilter(req.payload).pipe(
        map((res: DeleteFilterResponse): ActionTypes.DeleteFilterResponseAction =>
          new ActionTypes.DeleteFilterResponseAction(res)
        ));
    })));

   timelineDataRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.TIMELINE_DATA_REQUEST),
    switchMap((req: ActionTypes.TimelineDataRequestAction): Observable<Action> => {
      return this.filterService.getTimelineData(req.payload).pipe(
        map((res: TimelineDataResponse): ActionTypes.TimelineDataResponseAction =>
          new ActionTypes.TimelineDataResponseAction(res)
        ));
    })));

   alertDataRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ALERT_DATA_REQUEST),
    switchMap((req: ActionTypes.AlertDataRequestAction): Observable<Action> => {
      return this.filterService.getAlertData(req.payload).pipe(
        map((res: AlertDataResponse): ActionTypes.AlertDataResponseAction =>
          new ActionTypes.AlertDataResponseAction(res)
        ));
    })));

   DismissAlertRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DISMISS_ALERT_REQUEST),
    switchMap((req: ActionTypes.DismissAlertRequestAction): Observable<Action> => {
      return this.filterService.dismissAlert(req.payload).pipe(
        map((res: DismissAlertResponse): ActionTypes.DismissAlertResponseAction =>
          new ActionTypes.DismissAlertResponseAction(res)
        ));
    })));

}
