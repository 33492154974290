import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';

import {
  AbstractControl,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Store }      from '@ngrx/store';
import { Observable } from 'rxjs';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


import { StoreState }                 from '../../../state-management/store';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { State as AdminUsersState }   from '../../../state-management/reducers/admin-users';

import {
  FetchUserProfileRequestAction,
} from '../../../state-management/actions/login-details';


/**
 * Summary
 *    Modal dialog which allows a user to change their password
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-user-edit-password-modal',
  templateUrl: './user-edit-password-modal.component.html',
  styleUrls: ['./user-edit-password-modal.component.scss']
})
export class UserEditPasswordModalComponent implements OnInit {

  // Edit password form
  public fg: UntypedFormGroup;

  // If set, admin is changing a User's password, otherwise a User is changing
  // their own
  public adminUser: boolean = false;

  // Store state Observables
  public adminUsers$:   Observable<AdminUsersState>   = null;
  public loginDetails$: Observable<LoginDetailsState> = null;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserEditPasswordModalComponent>,
    private _fb:       UntypedFormBuilder,
    private store:     Store<StoreState>,
  ) {
    this.adminUser = data.adminUser;

    // Select from Store based on current user type
    if (this.adminUser)
      this.adminUsers$ = this.store.select('adminUsers');
    else
      this.loginDetails$ = this.store.select('loginDetails');

    // Custom validator which ensures that the password and confirmation fields
    // are both valid and match
    const passwordMatcher = (control: AbstractControl): {[key: string]: boolean} => {
      if (control.get('password').value === '' || control.get('passwordConfirm').value === '')
        return null;

      return (control.get('password').value === control.get('passwordConfirm').value ? null : { nomatch: true });
    };

    this.fg = this._fb.group({
      password:        ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')]],
      passwordConfirm: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$')]],
    }, {validator: passwordMatcher});
  }

  ngOnInit() { }


  /**
   * Closes the dialog with the new password if the form is valid
   */
  onSubmitForm() {
    if (this.fg.valid)
      this.dialogRef.close(this.fg.value.password);
  }

  /**
   * Generates the appropriate Bootstrap "form-group" CSS classes for a field
   * based on its validity
   *
   * @param {string} fieldName     FormGroup field name
   * @param {string} oppositeField FormGroup field name of secondary field that must also be valid
   * @return {string} CSS classes
   */
  formGroupClass(fieldName: string, oppositeField: string): string {
    const classes = 'form-group';

    const ff     = this.fg.controls[fieldName];
    const oField = this.fg.controls[oppositeField];
    if (!ff && !oField)
      return classes;

    return `${classes}${ff.value !== '' && oField.value !== '' && !this.fg.valid ? ' has-error' : ''}`;
  }

  /**
   * Called when the "Update password" button is clicked and calls
   * this.onSubmitForm()
   */
  saveForm() {
    this.onSubmitForm();
  }
}
