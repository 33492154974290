import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: true,
})
export class FilterPipe implements PipeTransform {

    transform(objectArray: any, property: string, value: any): any[] {
        if (objectArray) {
            return objectArray.filter((object: any) => object[property] === value);
        }
    }
}
