<div class="container-fluid">
  <div class="row content-page">
    <div class="container-title">
      <h1 class="white">Resources</h1>
    </div>

    <app-page-content page="CONTENT_ARCHIVE" field="intro"></app-page-content>

    <div class="content-container col-xs-12 no-pad">

      <div *ngFor="let resource of resourceArray" class="category-row">
        <h2 class="color-white">{{resource.categoryTitle}}</h2>
        <div class="row row-container">
          <div class="category-item col-sm-4 col-xs-12" *ngFor="let item of resource.items">
            <div
              [class]="(item.link ? 'background-white hover-background-adey-red hover-color-white pointer' : 'background-light-gray') + ' selection-box  align-center'"
              (click)="goToList(item.link, item.resource)">
              <div class="content">
                <i [class]="item.icon"></i>
                <h3>{{item.label}}</h3>
                <p class="item-note" *ngIf="item.additionalText">{{item.additionalText}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
