import { createSelector } from '@ngrx/store';

import { State } from '../reducers/popup';
import { StoreState } from '../store';

export const selectPopupState = (state: StoreState) => state.popup;

export const selectPopup = (name: string) => createSelector(
  selectPopupState,
  (state: State) => {
    const id = Object.keys(state.entities).find((popup) => state.entities[popup].popup_by_popup_id.name === name);
    return state.entities[id];
  },
);

export const selectPopupDisplayCount = (name: string) => createSelector(
  selectPopupState,
  (state: State) => {
    if (state.pending) {
      return null;
    }

    const id = Object.keys(state.entities).find((popup) => state.entities[popup].popup_by_popup_id.name === name);
    return state.entities[id] ? state.entities[id].display_count : 0;
  },
);
