/**
 * content-pages reducer: maintains state for dynamic page content
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  pages,
  SitePage,
} from '../../models/content-pages';

import * as actions from '../actions/content-pages';


// State interface definition: maintains a list of SitePage models which
// contain values for all dynamic fields for each page on the site
export interface State {
  pages:   SitePage[];
  pending: boolean;
  error:   string;
}


const defaultState: State = {

  // The "pages" array from the import above contains default values for all
  // pages. These defaults will be merged with any data obtained from the API.
  pages,

  pending: false,
  error:   null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch all pages and their field values from the API
     */
    case actions.FETCH_PAGE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error:   null,
      });

    /**
     * Response from fetching all page field content from the API
     */
    case actions.FETCH_PAGE_CONTENT_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        pages:   action.payload.error
          ? state.pages
          : state.pages.map((page: SitePage): SitePage => {
            const respPage: SitePage[] = action.payload.pages.filter((p: SitePage): boolean => p.id === page.id);
            if (respPage.length < 1)
              return page;
            return Object.assign({}, page, {
              fields: respPage[0].fields,
            });
          }),
      });

    /**
     * Request to update one or more fields for a specified page
     */
    case actions.UPDATE_PAGE_CONTENT_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error:   null,
      });

    /**
     * Response from updating one or more page fields
     */
    case actions.UPDATE_PAGE_CONTENT_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        pages:   action.payload.error
          ? state.pages
          : state.pages.map((page: SitePage): SitePage => {
            if (page.id !== action.payload.page.id)
              return page;
            return Object.assign({}, page, {
              fields: action.payload.page.fields,
            });
          }),
      });

    default:
      return state;
  }
}
