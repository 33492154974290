<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Training</h1>
    </div>

    <div class="training-container background-white col-xs-12 align-center">
      <h2 class="color-adey-gray">Watch this space for more information coming soon on ADEY training courses around the UK.</h2>

      <button 
        class="btn background-adey-red hover-background-adey-blue color-white no-radius"
        type="button" 
        (click)="goToPage('/home')">
        Return home
      </button>

    </div>

  </div>
</div>