/**
 * login-details reducer: maintains all state relating to user login,
 * registration, profile, token, etc.
 */
import { CurrentUser } from '../../models/login';
import { UserRole } from '../../models/registration';
import { AccreditationItem, UserProfile } from '../../models/user-profile';
import * as loginActions from '../actions/login-details';
import * as registrationActions from '../actions/registration';

// State interface definition
export interface State {

  // Details of currently logged-in user
  user: CurrentUser;

  // Current user's profile data
  currentProfile: UserProfile;

  // Flag to indicate if user is logged in
  loggedIn: boolean;

  // Token state: JWT string, JWT error and validated flag
  token: string;
  tokenError: string;
  tokenValidated: boolean;

  // Request pending and response error
  pending: boolean;
  error: string;

  // Error and success messages from password reset system
  resetError: string;
  resetMessage: string;

  // Pending flag and response message/error from user activation system
  activationPending: boolean;
  activationMessage: string;
  activationError: string;

  // List of possible AccreditationItems for a user profile and pending flag
  accreditations: AccreditationItem[];
  accreditationsPending: boolean;

  // List of allowed UserRoles (currently unused)
  roles: UserRole[];

  // Pending flag and response error for the PromoShop link
  promoShopPending: boolean;
  promoShopError: string;

  //return page after login
  returnPage?: string;

  //open modal register water tester
  openModalRegisterWaterTester?: false;

  registerWaterTesterCredentials?: null;
}


const defaultState: State = {
  accreditations: null,
  accreditationsPending: false,
  activationError: null,
  activationMessage: null,
  activationPending: false,
  currentProfile: null,
  error: null,
  loggedIn: false,
  pending: false,
  promoShopError: null,
  promoShopPending: false,
  resetError: null,
  resetMessage: null,
  roles: [],
  tokenError: null,
  token: null,
  tokenValidated: false,
  user: null,
  returnPage: null,
  openModalRegisterWaterTester: false,
  registerWaterTesterCredentials: null
};


export function reducer(
  state: State = defaultState,
  action: loginActions.Actions | registrationActions.Actions,
): State {
  switch (action.type) {
    /**
     * Request to activate the current user based on the token sent in the
     * user's activation e-mail
     */
    case loginActions.ACTIVATE_USER_REQUEST:
      return Object.assign({}, state, {
        activationPending: true,
        activationError: null,
        activationMessage: null,
      });

    /**
     * Response from a user activation request
     */
    case loginActions.ACTIVATE_USER_RESPONSE:
      return Object.assign({}, state, {
        activationPending: false,
        activationMessage: action.payload.message,
        activationError: action.payload.error,
      });

    /**
     * Request to check the user's current login token (JWT) validity
     */
    case loginActions.CHECK_LOGIN_TOKEN_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        tokenError: null,
        tokenValidated: false,
      });

    /**
     * Response from checking a user's login token (JWT) validity
     */
    case loginActions.CHECK_LOGIN_TOKEN_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        tokenError: action.payload.error,
        tokenValidated: action.payload.valid,
        loggedIn: action.payload.valid,
        user: action.payload.valid ? action.payload.user : null,
      });

    /**
     * Request to create a new token allowing access to the PromoShop
     */
    case loginActions.CREATE_PROMOSHOP_TOKEN_REQUEST:
      return Object.assign({}, state, {
        promoShopPending: true,
        promoShopError: null,
      });

    /**
     * Response from the request to create a new PromoShop token
     */
    case loginActions.CREATE_PROMOSHOP_TOKEN_RESPONSE:
      return Object.assign({}, state, {
        promoShopPending: false,
        promoShopError: action.payload.error,
        currentProfile: action.payload.error
          ? state.currentProfile
          : Object.assign({}, state.currentProfile, { promoShopToken: action.payload.token }),
      });

    /**
     * Request to fetch a list of possible AccreditationItems for editing a
     * profile and registration
     */
    case loginActions.FETCH_ACCREDITATIONS_REQUEST:
      return Object.assign({}, state, {
        accreditationsPending: true,
        accreditations: null,
        error: null,
      });

    /**
     * Response from fetching a list of AccreditationItems
     */
    case loginActions.FETCH_ACCREDITATIONS_RESPONSE:
      return Object.assign({}, state, {
        accreditationsPending: false,
        accreditations: action.payload.accreditations,
        error: action.payload.error,
      });

    /**
     * Request to fetch the current user's profile (if logged in)
     */
    case loginActions.FETCH_USER_PROFILE_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error: null,
      });

    /**
     * Response from fetching the current user's profile
     */
    case loginActions.FETCH_USER_PROFILE_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        currentProfile: action.payload.profile,
      });

    /**
     * Response from fetching a list of UserRoles
     */
    case registrationActions.FETCH_USER_ROLES_RESPONSE:
      return Object.assign({}, state, {
        roles: action.payload.error ? state.roles : action.payload.roles,
      });

    /**
     * Request to login from a username and password
     */
    case loginActions.LOGIN_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error: null,
        user: null,
        token: null,
        loggedIn: false,
        resetError: null,
        resetMessage: null,
        activationMessage: null,
      });

    /**
     * Response from a login request
     */
    case loginActions.LOGIN_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        user: action.payload.user,
        currentProfile: action.payload.profile ? action.payload.profile : state.currentProfile,
        token: action.payload.token,
        loggedIn: !!action.payload.user,
        resetError: null,
        resetMessage: null,
        activationMessage: null,
      });

    /**
     * Logout for the current user: resets all state relating to the current
     * user
     */
    case loginActions.LOGOUT:
      return Object.assign({}, state, {
        user: null,
        currentProfile: null,
        loggedIn: false,
        pending: false,
        error: null,
        token: null,
        tokenValidated: false,
        resetError: null,
        resetMessage: null,
        activationMessage: null,
      });

    /**
     * Request to load a stored login token (JWT)
     */
    case loginActions.LOAD_LOGIN_TOKEN_REQUEST:
      return Object.assign({}, state, { error: null, token: null });

    /**
     * Response from loading a stored login token (JWT)
     */
    case loginActions.LOAD_LOGIN_TOKEN_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        token: action.payload.token,
      });

    case loginActions.RESEND_ACTIVATION_EMAIL_REQUEST:
      return Object.assign({}, state, { pending: true, error: null });

    case loginActions.RESEND_ACTIVATION_EMAIL_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        activationMessage: action.payload.message
      });

    /**
     * Request to initiate a password reset for the current user
     */
    case loginActions.REQUEST_RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, { pending: true, resetError: null, resetMessage: null });

    /**
     * Response from a request to initiate a password reset for the current
     * user
     */
    case loginActions.REQUEST_RESET_PASSWORD_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        resetError: action.payload.error,
        resetMessage: action.payload.message
      });

    /**
     * Request to reset a password for the current user based on a token
     * obtained from the reset password e-mail.
     */
    case loginActions.RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, { pending: true, resetError: null, resetMessage: null });

    /**
     * Response from a reset password request
     */
    case loginActions.RESET_PASSWORD_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        resetError: action.payload.error,
        resetMessage: action.payload.message
      });

    /**
     * Request to update the current user's profile
     */
    case loginActions.UPDATE_USER_PROFILE_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error: null,
      });

    /**
     * Response from updating the current user's profile
     */
    case loginActions.UPDATE_USER_PROFILE_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        currentProfile: action.payload.error ? state.currentProfile : Object.assign({}, state.currentProfile, action.payload.profile),
        user: action.payload.error
          ? state.user
          : Object.assign({}, state.user, {
            title: action.payload.profile.title,
            forename: action.payload.profile.firstName,
            surname: action.payload.profile.lastName,
            optIns: action.payload.profile.optIns ? action.payload.profile.optIns : state.user.optIns,
          }),
      });

    /**
     * Request to water tester login from a username and password
     */
    case loginActions.LOGIN_WATER_TESTER_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error: null,
      });

    /**
     * Response from a water tester login request
     */
    case loginActions.LOGIN_WATER_TESTER_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        user: action.payload.user,
        token: action.payload.token,
        loggedIn: !!action.payload.token,
        openModalRegisterWaterTester: false,
      });

    case loginActions.MODAL_REGISTER_WATER_TESTER_REQUEST:
      return Object.assign({}, state, {
        openModalRegisterWaterTester: true,
        registerWaterTesterCredentials: action.payload
      });

    /**
     * Register water tester
     */
    case loginActions.REGISTER_WATER_TESTER_REQUEST:
      return Object.assign({}, state, {
        registerWaterTester: action.payload
      });
    default:
      return state;
  }
}
