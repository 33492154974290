import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-pro3',
    templateUrl: './admin-pro3.component.html',
    styleUrls: ['./admin-pro3.component.scss']
})
export class AdminPro3Component {
    constructor() { }

    public goToSense(): void {
      window.open('https://sense.adey.com/', '_blank');
    }
}
