/**
 * diary models
 */

import * as moment from 'moment';


/**
 * Encapsulates a single event that can be shown in a user's diary. Either a
 * user event or an ADEY avent.
 */
export class DiaryEvent {
  id: string;

  // TRUE if the user created the event, FALSE if created by ADEY
  userEvent: boolean;

  // ID of owning user if userEvent
  userId?: string;

  // YYYY-MM-DD of event start
  date: string;

  // HH:MM:SS of event start (UTC)
  time: string;

  // Duration of event in minutes
  duration: number;

  // If TRUE, the event lasts for the entire day specified in date
  allDay: boolean;

  // ID of the EventType
  typeOfEvent: string;

  // Name of the EventType
  typeName?: string;

  // Brief description
  details: string;

  // Full description
  description?: string;

  // URL of image to be displayed with the event (currently unused)
  imageUrl?: string;

  location?: string;
  note?:     string;
  merchant?: string;

  // TRUE if user has booked this event (currently unused)
  booked: boolean;

  // TRUE when event has been posted to API
  committed: boolean;

  // TRUE when event has been marked for deletion
  deleted: boolean;

  createdAt: string;
  updatedAt: string;

  /**
   * Converts the model to the API data format (POST)
   *
   * @param {DiaryEvent} appData
   * @return {object}
   */
  static toApiPostData(appData: DiaryEvent): any {
    const data: any = {
      diaryeventtype_id: appData.typeOfEvent,
      user_event:        appData.userEvent,
      startdate:         moment(appData.date + ' ' + appData.time).format('YYYY-MM-DD HH:mm:ss'),
      duration:          appData.duration,
      allday:            appData.allDay,
      location:          appData.location,
      details:           appData.details,
      description:       appData.description,
      merchant:          appData.merchant,
      additional_notes:  appData.note,
    };

    return appData.userEvent
      ? Object.assign({}, data, {save_on_behalf_of: appData.userId})
      : data;
  }

  /**
   * Converts the model to the API data format (PATCH)
   *
   * @param {DiaryEvent} appData
   * @return {object}
   */
  static toApiPatchData(appData: DiaryEvent): any {
    const data: any = {
      id:                appData.id,
      diaryeventtype_id: appData.typeOfEvent,
      user_event:        appData.userEvent,
      user_id:           appData.userId,
      startdate:         moment(appData.date + ' ' + appData.time).format('YYYY-MM-DD HH:mm:ss'),
      duration:          appData.duration,
      allday:            appData.allDay,
      location:          appData.location,
      details:           appData.details,
      description:       appData.description,
      merchant:          appData.merchant,
      additional_notes:  appData.note,
    };
    return appData.userEvent
      ? Object.assign({}, data, {save_on_behalf_of: appData.userId})
      : data;
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {DiaryEvent}
   */
  static fromApiData(apiData: any): DiaryEvent {
    const e: DiaryEvent = new DiaryEvent();

    e.id          = apiData.id;
    e.typeOfEvent = apiData.diaryeventtype_id;
    e.userId      = apiData.user_id;
    e.date        = moment(apiData.startdate).format('YYYY-MM-DD');
    e.time        = moment(apiData.startdate).format('HH:mm:ss');
    e.duration    = apiData.duration;
    e.allDay      = apiData.allday;
    e.location    = apiData.location;
    e.details     = apiData.details;
    e.description = apiData.description;
    e.createdAt   = apiData.created_at;
    e.updatedAt   = apiData.updated_at;
    e.userEvent   = apiData.user_event;
    e.note        = apiData.additional_notes;
    e.merchant    = apiData.merchant;

    e.typeName = apiData.diaryeventtype_by_diaryeventtype_id
      ? apiData.diaryeventtype_by_diaryeventtype_id.title
      : apiData.diaryeventtype_id,

    e.committed = true;

    return e;
  }

  /**
   * Returns an example model
   *
   * @param {string} eventType If set, forces generated event to be a specifid type
   * @return {DiaryEvent}
   */
  static getExample(eventType: string = null): DiaryEvent {
    const id = Math.floor(Math.random() * 1000).toString();
    const rdate = moment.utc()
      .add(Math.floor(Math.random() * 20) - 10, 'days')
      .add(Math.floor(Math.random() * 10) - 5, 'hours')
      .minute(0);
    const userEvent = Math.floor(Math.random() * 10) % 2 === 0;
    const typeOfEvent = EventType.getExample(eventType);

    return {
      id,
      userEvent,
      typeOfEvent: typeOfEvent.id.toString(),
      userId:      userEvent ? Math.floor(Math.random() * 1000).toString() : null,
      date:        rdate.format('YYYY-MM-DD'),
      time:        rdate.format('HH:mm:ss'),
      duration:    120,
      allDay:      Math.floor(Math.random() * 10) % 2 === 0,
      details:     `Details of ${typeOfEvent.name} event ID ${id}`,
      description: `Full description for the ${typeOfEvent.name} event with an ID of ${id}`,
      booked:      Math.floor(Math.random() * 2) % 1 === 0,
      location:    'Location of work',
      note:        'Sample note',
      merchant:    'Sample merchant',
      committed:   true,
      deleted:     false,
      createdAt:   rdate.format('YYYY-MM-DD'),
      updatedAt:   rdate.format('HH:mm:ss')
    };
  }

  /**
   * Returns the specified DiaryEvent with updated dates and duration based on
   * new start and end dates
   *
   * @param {DiaryEvent} e     Event to update
   * @param {Date}       start New start date for event
   * @param {Date}       end   New end date for event
   * @return {DiaryEvent} New updated model
   */
  static updateTimes(e: DiaryEvent, start: Date, end: Date): DiaryEvent {
    return Object.assign({}, e, {
      date: moment.utc(start).format('YYYY-MM-DD'),
      time: moment.utc(start).format('HH:mm:ss'),
      duration: moment.duration(moment(end).diff(moment(start))).asMinutes(),
    });
  }
}

/**
 * Encapsulates a single event type. Event types can be managed by admins.
 */
export class EventType {
  id:   string;
  name: string;

  /**
   * Returns an example model
   *
   * @param {string} eventType If set, returns an example of the specified type
   * @return {EventType}
   */
  static getExample(eventType: string = null): EventType {
    const ets = [
      { id: '0', name: 'Service'},
      { id: '1', name: 'Installation'},
      { id: '2', name: 'Training'},
      { id: '3', name: 'Meeting' },
      { id: '4', name: 'Quote' },
      { id: '5', name: 'Customer visit' },
      { id: '6', name: 'Other' }
    ];

    if (eventType)
    {
      const filtered: EventType[] = ets.filter((et: EventType): boolean => et.name.toLowerCase() === eventType.toLowerCase());
      return filtered.length > 0 ? filtered[0] : ets[Math.floor(Math.random() * ets.length)];
    }
    else
      return ets[Math.floor(Math.random() * ets.length)];
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {EventType}
   */
  static fromAPI(data: any): EventType {
    return {
      id:   data.id.toString(),
      name: data.title,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {EventType} et
   * @return {object}
   */
  static toAPI(et: EventType): any {

    // TODO: add "chevron_colour" to EventType model

    return {
      id:             et.id ? parseInt(et.id, 10) : null,
      title:          et.name,
      chevron_colour: 'green',
    };
  }
}


/**
 * Dispatched to indicate that a DiaryEvent has been updated in the API
 * successfully
 */
export interface CommitEventResponse {
  error:  string;
  id:     string;
  newId?: string;
}

export interface FetchEventsResponse {
  error: string;
  items: DiaryEvent[];
}

export interface FetchEventTypesResponse {
  error: string;
  types: EventType[];
}
