/**
 * content-pages actions: defines all actions relating to management of dynamic
 * page content.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  FetchPageContentResponse,
  SitePage,
  UpdatePageContentRequest,
  UpdatePageContentResponse,
} from '../../models/content-pages';


// Action type definitions
export const FETCH_PAGE_CONTENT_REQUEST   = 'FETCH_PAGE_CONTENT_REQUEST';
export const FETCH_PAGE_CONTENT_RESPONSE  = 'FETCH_PAGE_CONTENT_RESPONSE';
export const UPDATE_PAGE_CONTENT_REQUEST  = 'UPDATE_PAGE_CONTENT_REQUEST';
export const UPDATE_PAGE_CONTENT_RESPONSE = 'UPDATE_PAGE_CONTENT_RESPONSE';


// Action definitions
export class FetchPageContentRequestAction implements Action {
  readonly type = FETCH_PAGE_CONTENT_REQUEST;
}

export class FetchPageContentResponseAction implements Action {
  readonly type = FETCH_PAGE_CONTENT_RESPONSE;
  constructor(public payload: FetchPageContentResponse) {}
}

export class UpdatePageContentRequestAction implements Action {
  readonly type = UPDATE_PAGE_CONTENT_REQUEST;
  constructor(public payload: UpdatePageContentRequest) {}
}

export class UpdatePageContentResponseAction implements Action {
  readonly type = UPDATE_PAGE_CONTENT_RESPONSE;
  constructor(public payload: UpdatePageContentResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchPageContentRequestAction
  | FetchPageContentResponseAction
  | UpdatePageContentRequestAction
  | UpdatePageContentResponseAction;
