
import {forkJoin as observableForkJoin, of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * AdminService: provides all functionality related to the admin CMS
 */





import { Injectable } from '@angular/core';

import {
  AddAdminEventRequest,
  AddAdminEventResponse,
  AddAdminEventTypeRequest,
  AddAdminEventTypeResponse,
  DeleteAdminEventRequest,
  DeleteAdminEventResponse,
  DeleteAdminEventTypeRequest,
  DeleteAdminEventTypeResponse,
  FetchAdminEventsRequest,
  FetchAdminEventsResponse,
  UpdateAdminEventRequest,
  UpdateAdminEventResponse,
  UpdateAdminEventTypeRequest,
  UpdateAdminEventTypeResponse,
} from '../models/admin-events';
import {
  AddAdminJobRequest,
  AddAdminJobResponse,
  AddAdminPromoCodeRequest,
  AddAdminPromoCodeResponse,
  AddAdminWarrantyRequest,
  AddAdminWarrantyResponse,
  DeleteAdminJobRequest,
  DeleteAdminJobResponse,
  DeleteAdminPromoCodeRequest,
  DeleteAdminPromoCodeResponse,
  DeleteAdminWarrantyRequest,
  DeleteAdminWarrantyResponse,
  FetchAdminJobsRequest,
  FetchAdminJobsResponse,
  FetchAdminPromoCodesResponse,
  FetchAdminWarrantiesRequest,
  FetchAdminWarrantiesResponse,
  UpdateAdminCustomerRequest,
  UpdateAdminCustomerResponse,
  UpdateAdminJobRequest,
  UpdateAdminJobResponse,
  UpdateAdminPromoCodeRequest,
  UpdateAdminPromoCodeResponse,
  UpdateAdminWarrantyRequest,
  UpdateAdminWarrantyResponse,
  UpdateRegisteredProductRequest,
  UpdateRegisteredProductResponse,
} from '../models/admin-jobs';
import {
  AddAdminResourceRequest,
  AddAdminResourceResponse,
  DeleteAdminResourceRequest,
  DeleteAdminResourceResponse,
  FetchAdminResourcesRequest,
  FetchAdminResourcesResponse,
  UpdateAdminResourceRequest,
  UpdateAdminResourceResponse,
} from '../models/admin-resources';
import {
  AddAdminPromotionRequest,
  AddAdminPromotionResponse,
  DeleteAdminPromotionRequest,
  DeleteAdminPromotionResponse,
  FetchAdminPromotionsResponse,
  FetchUserPointsHistoryRequest,
  FetchUserPointsHistoryResponse,
  PointsHistory,
  Promotion,
  TransferAdminUserPointsRequest,
  TransferAdminUserPointsResponse,
  UpdateAdminUserPointsRequest,
  UpdateAdminUserPointsResponse,
} from '../models/admin-rewards';
import {
  AdminStats,
  FetchAdminStatsDownloadRequest,
  FetchAdminStatsDownloadResponse,
  FetchAdminStatsRequest,
  FetchAdminStatsResponse,
} from '../models/admin-stats';
import {
  AddAdminUserRequest,
  AddAdminUserResponse,
  AdminUser,
  DeleteAdminUserRequest,
  DeleteAdminUserResponse,
  FetchAdminUsersRequest,
  FetchAdminUsersResponse,
  UpdateAdminUserRequest,
  UpdateAdminUserResponse,
  UserPoints,
} from '../models/admin-users';
import { PageField, UpdatePageContentRequest, UpdatePageContentResponse } from '../models/content-pages';
import { Customer } from '../models/customer';
import {
  CustomerSuggestion,
  FetchCustomerSuggestionRequest,
  FetchCustomerSuggestionResponse,
} from '../models/customer-suggestion';
import { DiaryEvent, EventType } from '../models/diary';
import { Job } from '../models/job';
import { ResourceItem } from '../models/resources';
import { AccreditationItem, FetchAccreditationsResponse } from '../models/user-profile';
import { FetchUserSuggestionRequest, FetchUserSuggestionResponse, UserSuggestion } from '../models/user-suggestion';
import { PromoCode, Warranty } from '../models/warranty';
import { AdminService as MockService } from './admin.service.mock';
import { ApiService } from './api.service';



@Injectable()
export class AdminService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService: MockService = null;

  constructor(private apiService: ApiService) {
    this.mockService = new MockService();
  }

  /**
   * Adds a new DiaryEvent
   *
   * @param {AddAdminEventRequest} req
   * @return {Observable<AddAdminEventResponse>}
   */
  addEvent(req: AddAdminEventRequest): Observable<AddAdminEventResponse> {
    return this.apiService.apiPost('/admin/_table/diaryevent', DiaryEvent.toApiPostData(req.event)).pipe(
      map((res: any): AddAdminEventResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add event: invalid response from server',
          event: valid
            ? Object.assign({}, req.event, { id: res.resource[0].id })
            : req.event,
        };
      }),
      catchError((err: any): Observable<AddAdminEventResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add event: ${err.error.message}`
            : 'Unable to add event',
          event: req.event,
        })
      ),);
  }

  /**
   * Adds a new EventType
   *
   * @param {AddAdminEventTypeRequest} req
   * @return {Observable<AddAdminEventTypeResponse>}
   */
  addEventType(req: AddAdminEventTypeRequest): Observable<AddAdminEventTypeResponse> {
    return this.apiService.apiPost('/admin/_table/diaryeventtype', EventType.toAPI(req.type)).pipe(
      map((res: any): AddAdminEventTypeResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add event type: invalid response from server',
          type: valid
            ? Object.assign({}, req.type, { id: res.resource[0].id })
            : null,
        };
      }),
      catchError((err: any): Observable<AddAdminEventTypeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add event type: ${err.error.message}`
            : 'Unable to add event type',
          type: null,
        })
      ),);
  }

  /**
   * Adds a new Job
   *
   * @param {AddAdminJobRequest} req
   * @return {Observable<AddAdminJobResponse>}
   */
  addJob(req: AddAdminJobRequest): Observable<AddAdminJobResponse> {
    // TODO: handle adding of Jobs for existing customers
    return this.apiService.apiPost('/admin/job', Job.toApiAdminJob(req.job)).pipe(
      map((res: any): AddAdminJobResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add job: invalid response from server',
          job: valid
            ? Object.assign({}, req.job, { id: res.resource[0].id })
            : req.job,
        };
      }),
      catchError((err: any): Observable<AddAdminJobResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add job: ${err.error.message}`
            : 'Unable to add job',
          job: req.job,
        })
      ),);
  }

  /**
   * Adds a new PromoCode
   *
   * @param {AddAdminPromoCodeRequest} req
   * @return {Observable<AddAdminPromoCodeResponse>}
   */
  addPromoCode(req: AddAdminPromoCodeRequest): Observable<AddAdminPromoCodeResponse> {
    return this.apiService.apiPost('/admin/_table/promocode', PromoCode.toAPI(req.promoCode)).pipe(
      map((res: any): AddAdminPromoCodeResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add promotional code: invalid response from server',
          promoCode: valid ? Object.assign({}, req.promoCode, { id: res.resource[0].id, committed: true }) : req.promoCode,
        };
      }),
      catchError((err: any): Observable<AddAdminPromoCodeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add promotional code: ${err.error.message}`
            : 'Unable to add promotional code',
          promoCode: req.promoCode,
        })
      ),);
  }

  addPromotion(req: AddAdminPromotionRequest): Observable<AddAdminPromotionResponse> {
    return this.apiService.apiPost('/admin/promotions', Promotion.toAPI(req.promotion)).pipe(
      map((res: any): AddAdminPromotionResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add promotion: invalid response from server',
          promotionId: valid ? res.resource[0].id : null,
        };
      }),
      catchError((err: any): Observable<AddAdminPromotionResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add promotion: ${err.error.message}`
            : 'Unable to add promotion',
          promotionId: null,
        })
      ),);
  }

  /**
   * Adds a new Resource
   *
   * @param {AddAdminResourceRequest} req
   * @return {Observable<AddAdminResourceResponse>}
   */
  addResource(req: AddAdminResourceRequest): Observable<AddAdminResourceResponse> {
    return this.apiService.apiPost('/admin/save-resource', ResourceItem.toAPI(req.resource)).pipe(
      map((res: any): AddAdminResourceResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          resource: valid
            ? Object.assign({}, req.resource, { id: res.resource[0].id })
            : req.resource,
        };
      }),
      catchError((err: any): Observable<AddAdminResourceResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add resource: ${err.error.message}`
            : 'Unable to add resource',
          resource: req.resource,
        })
      ),);
  }

  /**
   * Adds a new User
   *
   * @param {AddAdminUserRequest} req
   * @return {Observable<AddAdminUserResponse>}
   */
  addUser(req: AddAdminUserRequest): Observable<AddAdminUserResponse> {
    const data = AdminUser.toAPINewUser(req.user);

    if (req.user.profile.ignoreSerial)
      data.ignoreSerial = req.user.profile.ignoreSerial;

    return this.apiService.apiPost('/admin/_table/user', data).pipe(
      map((res: any): AddAdminUserResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add user: invalid response from server',
          user: valid
            ? Object.assign({}, req.user, { id: res.resource[0].id })
            : req.user,
        };
      }),
      catchError((err: any): Observable<AddAdminUserResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add user: ${err.error.message}`
            : 'Unable to add user',
          user: req.user,
        })
      ),);
  }

  /**
   * Adds a new Warranty
   *
   * @param {AddAdminWarrantyRequest} req
   * @return {Observable<AddAdminWarrantyResponse>}
   */
  addWarranty(req: AddAdminWarrantyRequest): Observable<AddAdminWarrantyResponse> {

    // TODO: handle adding of Warranties for existing customers

    return this.apiService.apiPost('/admin/warranty', Warranty.toApiPost(req.warranty)).pipe(
      map((res: any): AddAdminWarrantyResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to add warranty: invalid response from server',
          warranty: valid
            ? Object.assign({}, req.warranty, { id: res.resource[0].id })
            : req.warranty,
        };
      }),
      catchError((err: any): Observable<AddAdminWarrantyResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to add warranty: ${err.error.message}`
            : 'Unable to add warranty',
          warranty: req.warranty,
        })
      ),);
  }

  /**
   * Deletes an existing DiaryEvent
   *
   * @param {DeleteAdminEventRequest} req
   * @return {Observable<DeleteAdminEventResponse>}
   */
  deleteEvent(req: DeleteAdminEventRequest): Observable<DeleteAdminEventResponse> {
    return this.apiService.apiDelete('/admin/_table/diaryevent/' + encodeURIComponent(req.event.id)).pipe(
      map((res: any): DeleteAdminEventResponse => {
        return {
          error: null,
          event: req.event,
        };
      }),
      catchError((err: any): Observable<DeleteAdminEventResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete event: ${err.error.message}`
            : 'Unable to delete event',
          event: req.event,
        })
      ),);
  }

  /**
   * Deletes an existing EventType
   *
   * @param {DeleteAdminEventTypeRequest} req
   * @return {Observable<DeleteAdminEventTypeResponse>}
   */
  deleteEventType(req: DeleteAdminEventTypeRequest): Observable<DeleteAdminEventTypeResponse> {
    return this.apiService.apiDelete('/admin/_table/diaryeventtype/' + encodeURIComponent(req.type.id)).pipe(
      map((res: any): DeleteAdminEventTypeResponse => {
        return {
          error: null,
          type: req.type,
        };
      }),
      catchError((err: any): Observable<DeleteAdminEventTypeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete event type: ${err.error.message}`
            : 'Unable to delete event type',
          type: req.type,
        })
      ),);
  }

  /**
   * Deletes an existing Job
   *
   * @param { DeleteAdminJobRequest } req
   * @return { Observable<DeleteAdminJobResponse> }
   */
  deleteJob(req: DeleteAdminJobRequest): Observable<DeleteAdminJobResponse> {

    const apiData: any = { id: req.job.id };

    return this.apiService.apiPatch('/admin/job/' + req.job.id + '/delete', apiData).pipe(
      map((res: any): DeleteAdminJobResponse => {
        return {
          error: null,
          job: req.job,
        };
      }),
      catchError((err: any): Observable<DeleteAdminJobResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete job: ${err.error.message}`
            : 'Unable to delete job',
          job: req.job,
        })
      ),);
  }

  deletePromotion(req: DeleteAdminPromotionRequest): Observable<DeleteAdminPromotionResponse> {
    return this.apiService.apiDelete(`/admin/promotions/${encodeURIComponent(req.id)}`).pipe(
      map((res: any): DeleteAdminPromotionResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to delete promotion',
          id: valid ? res.resource[0].id : null,
        };
      }),
      catchError((err: any): Observable<DeleteAdminPromotionResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete promotion: ${err.error.message}`
            : 'Unable to delete promotion',
          id: null,
        })
      ),);
  }

  /**
   * Deletes an existing PromoCode
   *
   * @param {DeleteAdminPromoCodeRequest} req
   * @return {Observable<DeleteAdminPromoCodeResponse>}
   */
  deletePromoCode(req: DeleteAdminPromoCodeRequest): Observable<DeleteAdminPromoCodeResponse> {
    return this.apiService.apiDelete('/admin/_table/promocode/' + encodeURIComponent(req.promoCode.id)).pipe(
      map((res: any): DeleteAdminPromoCodeResponse => {
        return {
          error: null,
          promoCode: req.promoCode,
        };
      }),
      catchError((err: any): Observable<DeleteAdminPromoCodeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete promotional code: ${err.error.message}`
            : 'Unable to delete promotional code',
          promoCode: req.promoCode,
        })
      ),);
  }

  /**
   * Deletes an existing Resource
   *
   * @param {DeleteAdminResourceRequest} req
   * @return {Observable<DeleteAdminResourceResponse>}
   */
  deleteResource(req: DeleteAdminResourceRequest): Observable<DeleteAdminResourceResponse> {
    return this.apiService.apiDelete('/admin/_table/resource/' + encodeURIComponent(req.resource.id)).pipe(
      map((res: any): DeleteAdminResourceResponse => {
        return {
          error: null,
          id: req.resource.id,
        };
      }),
      catchError((err: any): Observable<DeleteAdminResourceResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete resource: ${err.error.message}`
            : 'Unable to delete resource',
          id: req.resource.id,
        })
      ),);
  }

  /**
   * Deletes an existing User
   *
   * @param {DeleteAdminUserRequest} req
   * @return {Observable<DeleteAdminUserResponse>}
   */
  deleteUser(req: DeleteAdminUserRequest): Observable<DeleteAdminUserResponse> {

    const apiData: any = { id: req.user.id };

    return this.apiService.apiPatch('/admin/user/' + req.user.id + '/delete', apiData).pipe(
      map((res: any): DeleteAdminUserResponse => {
        return {
          error: null,
          user: req.user,
        };
      }),
      catchError((err: any): Observable<DeleteAdminUserResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete user: ${err.error.message}`
            : 'Unable to delete user',
          user: req.user,
        })
      ),);
  }

  /**
   * Deletes an existing Warranty
   *
   * @param {DeleteAdminWarrantyRequest} req
   * @return {Observable<DeleteAdminWarrantyResponse>}
   */
  deleteWarranty(req: DeleteAdminWarrantyRequest): Observable<DeleteAdminWarrantyResponse> {

    const apiData: any = { id: req.warranty.id };

    return this.apiService.apiPatch('/admin/warranty/' + req.warranty.id + '/delete', apiData).pipe(
      map((res: any): DeleteAdminWarrantyResponse => {
        return {
          error: null,
          warranty: req.warranty,
        };
      }),
      catchError((err: any): Observable<DeleteAdminWarrantyResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete warranty: ${err.error.message}`
            : 'Unable to delete warranty',
          warranty: req.warranty,
        })
      ),);
  }

  /**
   * Fetches AccreditationItem models
   *
   * @param {Observable} req
   * @return {Observable<AddAdminEventResponse>}
   */
  fetchAccreditations(): Observable<FetchAccreditationsResponse> {
    return this.apiService.apiGet('/get-accreditations').pipe(
      map((res: any): FetchAccreditationsResponse => {
        const mappedAccreditationArray = [];

        if (res && res.resource)
          for (let i = 0; i < res.resource.length; i++)
            mappedAccreditationArray.push(AccreditationItem.getFromApi(res.resource[i]));

        return {
          error: null,
          accreditations: mappedAccreditationArray,
        };
      }),
      catchError((err): Observable<FetchAccreditationsResponse> => {
        return observableOf({
          error: err.message,
          accreditations: null,
        });
      }),);
  }

  /**
   * Fetches DiaryEvent models
   *
   * @param {FetchAdminEventsRequest} req
   * @return {Observable<FetchAdminEventsResponse>}
   */
  fetchEvents(req: FetchAdminEventsRequest): Observable<FetchAdminEventsResponse> {
    const qs: string[] = [];

    // ADEY/User event filtering
    if (req.adeyEvents)
      qs.push('filter=' + encodeURIComponent('(user_event=false)'));
    else
      qs.push('filter=' + encodeURIComponent(`(user_id=${req.userId})`));

    // Pagination
    qs.push('offset=' + encodeURIComponent((req.perPage * (req.pageNum - 1)).toString()));
    qs.push('limit=' + encodeURIComponent(req.perPage.toString()));
    qs.push('include_count=true');

    // Related models
    qs.push('related=diaryeventtype_by_diaryeventtype_id');

    // Sorting
    qs.push('order=' + encodeURIComponent('startdate asc'));

    return this.apiService.apiGet('/admin/_table/diaryevent?' + qs.join('&')).pipe(
      map((res: any): FetchAdminEventsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        if (req.adeyEvents) {
          return {
            adeyError: valid ? null : 'Invalid response from server',
            userError: null,
            adeyEvents: valid ? res.resource.map(DiaryEvent.fromApiData) : null,
            userEvents: null,
            totalPagesAdey: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 1,
            totalPagesUser: 0,
          };
        }
        else {
          return {
            adeyError: null,
            userError: valid ? null : 'Invalid response from server',
            adeyEvents: null,
            userEvents: valid ? res.resource.map(DiaryEvent.fromApiData) : null,
            totalPagesAdey: 0,
            totalPagesUser: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 1,
          };
        }
      }),
      catchError((err: any): Observable<FetchAdminEventsResponse> =>
        req.adeyEvents
          ? observableOf({
            totalPagesAdey: 0,
            totalPagesUser: 0,
            userEvents: null,
            userError: null,
            adeyEvents: null,
            adeyError: err && err.error && err.error.message
              ? `Unable to get Adey events: ${err.error.message}`
              : 'Unable to get Adey events',
          })
          : observableOf({
            totalPagesAdey: 0,
            totalPagesUser: 0,
            adeyEvents: null,
            adeyError: null,
            userEvents: null,
            userError: err && err.error && err.error.message
              ? `Unable to get user events: ${err.error.message}`
              : 'Unable to get user events',
          }),
      ),);
  }

  /**
   * Fetches Job models
   *
   * @param {FetchAdminJobsRequest} req
   * @return {Observable<FetchAdminJobsResponse>}
   */
  fetchJobs(req: FetchAdminJobsRequest): Observable<FetchAdminJobsResponse> {

    const offset = req.perPage * (req.pageNum - 1);
    const limit = req.perPage;

    let apiReq: Observable<any> = null;

    if (req.type.toUpperCase() === 'USER') {
      const qs: string[] = [];

      qs.push('filter=' + encodeURIComponent(`(user_id=${req.query})`));

      // Pagination
      qs.push('offset=' + encodeURIComponent(offset.toString()));
      qs.push('limit=' + encodeURIComponent(limit.toString()));
      qs.push('include_count=true');

      // Related models
      qs.push('related=warranty_by_job_id,customer_by_customer_id,servicerecord_by_job_id');

      apiReq = this.apiService.apiGet('/admin/_table/job?' + qs.join('&'));
    }
    else
      apiReq = this.apiService.apiPost('/get-jobs-by-customer-name', { query: req.query, offset, limit });

    return apiReq.pipe(
      map((res: any): FetchAdminJobsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          jobs: valid ? res.resource.map(Job.fromAPI) : [],
          totalPages: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 0,
        };
      }),
      catchError((err: any): Observable<FetchAdminJobsResponse> =>
        observableOf({
          totalPages: 0,
          jobs: null,
          error: err && err.error && err.error.message
            ? `Unable to get jobs: ${err.error.message}`
            : 'Unable to get jobs',
        })
      ),);
  }

  /**
   * Fetches PromoCode models
   *
   * @param {Observable} req
   * @return {Observable<AddAdminEventResponse>}
   */
  fetchPromoCodes(): Observable<FetchAdminPromoCodesResponse> {
    return this.apiService.apiGet('/admin/_table/promocode').pipe(
      map((res: any): FetchAdminPromoCodesResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Unable to fetch promotional codes: invalid response from server',
          promoCodes: valid ? res.resource.map(PromoCode.fromAPI) : [],
        };
      }),
      catchError((err: any): Observable<FetchAdminPromoCodesResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to fetch promotional codes: ${err.error.message}`
            : 'Unable to fetch promotional codes',
          promoCodes: [],
        })
      ),);
  }

  /**
   * Fetches points promotions
   *
   * @return {Observable<FetchAdminPromotionsResponse>}
   */
  fetchPromotions(): Observable<FetchAdminPromotionsResponse> {
    return this.apiService.apiGet('/admin/promotions').pipe(
      map((res: any): FetchAdminPromotionsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Unable to fetch points promotions: invalid response from server',
          promotions: valid ? res.resource.map(Promotion.fromAPI) : [],
        };
      }),
      catchError((err: any): Observable<FetchAdminPromotionsResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to fetch promotions: ${err.error.message}`
            : 'Unable to fetch promotions',
          promotions: [],
        })
      ),);
  }

  /**
   * Fetches ResourceItem models
   *
   * @param {FetchAdminResourcesRequest} req
   * @return {Observable<FetchAdminResourcesResponse>}
   */
  fetchResources(req: FetchAdminResourcesRequest): Observable<FetchAdminResourcesResponse> {
    const offset = req.perPage * (req.pageNum - 1);
    const limit = req.perPage;

    return this.apiService.apiPost(
      `/search-resources?offset=${offset}&limit=${limit}`,
      {
        page: req.pageName,
        category: null,
        search: {
          title: null,
          titleOperator: 'OR',
          keywords: null,
          keywordsOperator: 'OR',
          docType: null,
          docTypeOperator: 'OR',
        }
      }
    ).pipe(
      map((res: any): FetchAdminResourcesResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          resources: valid ? res.resource.map(ResourceItem.fromAPI) : null,
          totalPages: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 1,
        };
      }),
      catchError((err: any): Observable<FetchAdminResourcesResponse> =>
        observableOf({
          totalPages: 0,
          resources: null,
          error: err && err.error && err.error.message
            ? `Unable to get resources: ${err.error.message}`
            : 'Unable to get resources',
        })
      ),);
  }

  /**
   * Fetches statistics for the admin dashboard
   *
   * @param {Observable} req
   * @return {Observable<FetchAdminStatsResponse>}
   */
  fetchStats(req: FetchAdminStatsRequest): Observable<FetchAdminStatsResponse> {
    const qs: string[] = [];

    // ADEY/Stats filtering
    if (req.region != null) {
      qs.push('region=' + encodeURIComponent(req.region.toString()));
    }
    if (req.minDate != null) {
      qs.push('minDate=' + encodeURIComponent(req.minDate.toString()));
    }
    if (req.maxDate != null) {
      qs.push('maxDate=' + encodeURIComponent(req.maxDate.toString()));
    }

    return this.apiService.apiGet('/admin/stats?' + qs.join('&')).pipe(
      map((res: any): FetchAdminStatsResponse => {
        return {
          error: null,
          stats: AdminStats.fromAPI(res),
        };

      }),
      catchError((err: any): Observable<FetchAdminStatsResponse> =>
        observableOf({
          stats: null,
          error: err && err.error && err.error.message
            ? `Unable to fetch statistics: ${err.error.message}`
            : 'Unable to fetch statistics',
        })
      ),);
  }

  /**
   * Fetches a download CSV for a specified statistic category and optional
   * product ID
   *
   * @param {Observable} req
   * @return {Observable<FetchAdminStatsDownloadResponse>}
   */
  fetchStatsDownload(req: FetchAdminStatsDownloadRequest): Observable<FetchAdminStatsDownloadResponse> {
    const qs: string[] = [];
    // ADEY/Stats filtering
    if (req.region != null) {
      qs.push('region=' + encodeURIComponent(req.region.toString()));
    }
    if (req.minDate != null) {
      qs.push('minDate=' + encodeURIComponent(req.minDate.toString()));
    }
    if (req.maxDate != null) {
      qs.push('maxDate=' + encodeURIComponent(req.maxDate.toString()));
    }

    return this.apiService.apiPostText('/admin/stat_download?' + qs.join('&'), req).pipe(
      map((res: any): FetchAdminStatsDownloadResponse => {
        return {
          error: null,
          data: btoa(res),
        };
      }),
      catchError((err: any): Observable<FetchAdminStatsDownloadResponse> =>
        observableOf({
          data: null,
          error: err && err.error && err.error.message
            ? `Unable to fetch statistics report: ${err.error.message}`
            : 'Unable to fetch statistics report',
        })
      ),);
  }

  /**
   * Fetches user points history
   *
   * @param {FetchUserPointsHistoryRequest} req
   * @return {Observable<FetchAdminPromotionsResponse>}
   */
  fetchUserPointsHistory(req: FetchUserPointsHistoryRequest): Observable<FetchUserPointsHistoryResponse> {
    return this.apiService.apiGet(`/admin/promotions/history/${req.id}`).pipe(
      map((res: any): FetchUserPointsHistoryResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Unable to fetch user\'s points history: invalid response from server',
          pointsHistory: valid ? res.resource.map(PointsHistory.fromAPI) : [],
        };
      }),
      catchError((err: any): Observable<FetchUserPointsHistoryResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to fetch user\'s points history: ${err.error.message}`
            : 'Unable to fetch user\'s points history',
          pointsHistory: [],
        })
      ),);
  }

  /**
   * Fetches AdminUser models
   *
   * @param {FetchAdminUsersRequest} req
   * @return {Observable<FetchAdminUsersResponse>}
   */
  fetchUsers(req: FetchAdminUsersRequest): Observable<FetchAdminUsersResponse> {
    const qs: string[] = [];

    let filterString: string = '';

    // Filtering
    if (req.userType) {
      filterString += `(role_id=${req.userType})`;
    }

    if (req.userId) {
      if (filterString !== '') {
        filterString += ' and ';
      }
      filterString += `(id=${req.userId})`;
    }

    if (req.userName) {
      if (filterString !== '') {
        filterString += ' and ';
      }
      filterString += `(full_name LIKE "%${req.userName}%")`;
    }

    if (req.userCompanyName) {
      if (filterString !== '') {
        filterString += ' and ';
      }
      filterString += `(companyname LIKE "%${req.userCompanyName}%")`;
    }

    if (req.userAddress) {
      if (filterString !== '') {
        filterString += ' and ';
      }

      filterString += `(full_address LIKE "%${req.userAddress}%")`;
    }

    if (req.onlyShowDeleted) {
      if (filterString !== '') {
        filterString += ' and ';
      }

      filterString += '(deleted_at IS NOT NULL)';
    }

    if (req.onlyShowRequestedDeletion) {
      if (filterString !== '') {
        filterString += ' and ';
      }

      filterString += '(requested_deletion_at IS NOT NULL)';
    }

    if (filterString !== '') {
      qs.push('filter=' + encodeURIComponent(filterString));
    }

    // Pagination
    qs.push('offset=' + encodeURIComponent((req.perPage * (req.page - 1)).toString()));
    qs.push('limit=' + encodeURIComponent(req.perPage.toString()));
    qs.push('include_count=true');

    // Related models
    qs.push('related=role_by_role_id,howheard_by_howheard_id,accreditation_by_accreditation_user,user_opt_ins_by_user_id,business_tool_registrations_by_user_id');

    // Sorting
    qs.push('order=' + encodeURIComponent('first_name asc,last_name asc'));

    return this.apiService.apiGet('/admin/_table/user?' + qs.join('&')).pipe(
      map((res: any): FetchAdminUsersResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          users: valid ? res.resource.map(AdminUser.fromAPI) : null,
          totalPages: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 1,
        };
      }),
      catchError((err: any): Observable<FetchAdminUsersResponse> =>
        observableOf({
          totalPages: 0,
          users: null,
          error: err && err.error && err.error.message
            ? `Unable to get users: ${err.error.message}`
            : 'Unable to get users',
        })
      ),);
  }

  /**
   * Fetches UserSuggestion models in response to a name query
   *
   * @param {FetchUserSuggestionRequest} req
   * @return {Observable<FetchUserSuggestionResponse>}
   */
  fetchUserSuggestions(req: FetchUserSuggestionRequest): Observable<FetchUserSuggestionResponse> {
    const qs: string[] = [];

    // Filtering
    switch (req.field) {
      case 'COMPANY':
        qs.push('filter=' + encodeURIComponent(`(companyname LIKE "%${req.query}%")`));
        break;
      case 'POSTCODE':
        qs.push('filter=' + encodeURIComponent(`(postcode LIKE "%${req.query}%")`));
        break;
      default:
        qs.push('filter=' + encodeURIComponent(`(full_name LIKE "%${req.query}%")`));
        break;
    }

    // Fields
    qs.push('fields=id,title,first_name,last_name, companyname, postcode');

    return this.apiService.apiGet('/admin/_table/user?' + qs.join('&')).pipe(
      map((res: any): FetchUserSuggestionResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          query: req.query,
          error: valid ? null : 'Invalid response from server',
          users: valid ? res.resource.map(UserSuggestion.fromAPI) : null,
        };
      }),
      catchError((err: any): Observable<FetchUserSuggestionResponse> =>
        observableOf({
          query: req.query,
          users: null,
          error: err && err.error && err.error.message
            ? `Unable to get users: ${err.error.message}`
            : 'Unable to get users',
        })
      ),);
  }

  /**
   * Fetches CustomerSuggestion models in response to a name query
   *
   * @param { FetchCustomerSuggestionRequest } req
   * @return { Observable<FetchCustomerSuggestionResponse> }
   */
  fetchCustomerSuggestions(req: FetchCustomerSuggestionRequest): Observable<FetchCustomerSuggestionResponse> {
    const qs: string[] = [];

    qs.push('filter=' + encodeURIComponent(`(full_name LIKE "%${req.query}%")`));

    qs.push('fields=id,title,first_name,surname, postcode');

    return this.apiService.apiGet('/admin/_table/customer?' + qs.join('&')).pipe(
      map((res: any): FetchCustomerSuggestionResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          query: req.query,
          error: valid ? null : 'Invalid response from server',
          customers: valid ? res.resource.map(CustomerSuggestion.fromAPI) : null,
        };
      }),catchError((err: any): Observable<FetchCustomerSuggestionResponse> =>
        observableOf({
          query: req.query,
          customers: null,
          error: err && err.error && err.error.message
            ? `Unable to get customers: ${err.error.message}`
            : 'Unable to get customers',
        })
      ),);
  }

  /**
   * Fetches Warranty models
   *
   * @param {FetchAdminWarrantiesRequest} req
   * @return {Observable<FetchAdminWarrantiesResponse>}
   */
  fetchWarranties(req: FetchAdminWarrantiesRequest): Observable<FetchAdminWarrantiesResponse> {
    const offset = req.perPage * (req.pageNum - 1);
    const limit = req.perPage;
    const upcoming = req.upcoming;

    let apiReq: Observable<any> = null;

    if (req.type.toUpperCase() === 'USER') {
      const qs: string[] = [];

      qs.push('filter=' + encodeURIComponent(`(user_id=${req.query})`));

      // Pagination
      qs.push('offset=' + encodeURIComponent(offset.toString()));
      qs.push('limit=' + encodeURIComponent(limit.toString()));
      qs.push('include_count=true');

      // Related models
      qs.push('related=product_by_product_id,job_by_job_id,warranty_by_job_id,customer_by_customer_id,servicerecord_by_warranty_id');

      apiReq = this.apiService.apiGet('/admin/_table/warranty?' + qs.join('&'));
    }
    else if (req.type.toUpperCase() === 'WARRANTY') {
      const qs: string[] = [];
      qs.push('filter=' + encodeURI(req.query));

      // Pagination
      qs.push('offset=' + encodeURIComponent(offset.toString()));
      qs.push('limit=' + encodeURIComponent(limit.toString()));
      qs.push(`order=install_date%20${(upcoming) ? 'ASC' : 'DESC'}`);
      qs.push('include_count=true');

      // Related models
      qs.push('related=product_by_product_id,job_by_job_id,warranty_by_job_id,customer_by_customer_id,servicerecord_by_warranty_id');

      apiReq = this.apiService.apiGet('/admin/_table/warranty?' + qs.join('&'));

    }
    else if (req.type.toUpperCase() === 'CUSTOMER') {
      apiReq = this.apiService.apiPost('/get-warranties-by-customer-name', { query: req.query, offset, limit });
    }

    return apiReq.pipe(
      map((res: any): FetchAdminWarrantiesResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          warranties: valid ? res.resource.map(Warranty.getFromApi) : [],
          totalPages: valid && res.meta && res.meta.count ? Math.ceil(res.meta.count / req.perPage) : 0,
        };
      }),
      catchError((err: any): Observable<FetchAdminWarrantiesResponse> =>
        observableOf({
          totalPages: 0,
          warranties: null,
          error: err && err.error && err.error.message
            ? `Unable to get warranties: ${err.error.message}`
            : 'Unable to get warranties',
        })
      ),);
  }

  /**
   * Transfers reward points from one user to another
   *
   * @param {TransferAdminUserPointsRequest} req
   * @return {Observable<TransferAdminUserPointsResponse>}
   */
  transferUserPoints(req: TransferAdminUserPointsRequest): Observable<TransferAdminUserPointsResponse> {

    const pointsFrom: UserPoints = req.userFrom.points;
    const pointsTo: UserPoints = req.userTo.points;

    pointsFrom.earned -= req.amount;
    pointsTo.earned += req.amount;

    const requests: Observable<any>[] = [];

    // 1. Deduct points from first User
    requests.push(
      this.apiService.apiPatch('/admin/_table/user', Object.assign({}, UserPoints.toAPI(pointsFrom), { id: req.userFrom.id })).pipe(
        map((res: any): boolean =>
          !!(res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id)
        ),
        catchError((err: any): Observable<boolean> =>
          observableOf(false)
        ),)
    );

    // 2. Increase points of second user
    requests.push(
      this.apiService.apiPatch('/admin/_table/user', Object.assign({}, UserPoints.toAPI(pointsTo), { id: req.userTo.id })).pipe(
        map((res: any): boolean =>
          !!(res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id)
        ),
        catchError((err: any): Observable<boolean> =>
          observableOf(false)
        ),)
    );

    // 3. Join both requests and return an overall response
    return observableForkJoin(requests).pipe(
      map((res: any): TransferAdminUserPointsResponse => {

        // Valid if both PATCH requests resulted in success
        const valid: boolean = res.filter((success: boolean): boolean => success).length === 2;

        return {
          error: valid ? null : 'Unable to transfer points: one or more updates failed',
          userFrom: valid
            ? Object.assign({}, req.userFrom, { points: pointsFrom })
            : req.userFrom,
          userTo: valid
            ? Object.assign({}, req.userTo, { points: pointsTo })
            : req.userTo,
        };
      }),
      catchError((err: any): Observable<TransferAdminUserPointsResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to transfer user points: ${err.error.message}`
            : 'Unable to transfer user points',
          userFrom: req.userFrom,
          userTo: req.userTo,
        })
      ),);
  }

  /**
   * Updates an existing DiaryEvent
   *
   * @param {UpdateAdminEventRequest} req
   * @return {Observable<UpdateAdminEventResponse>}
   */
  updateEvent(req: UpdateAdminEventRequest): Observable<UpdateAdminEventResponse> {
    return this.apiService.apiPatch('/admin/_table/diaryevent', DiaryEvent.toApiPatchData(req.event)).pipe(
      map((res: any): UpdateAdminEventResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update event: invalid response from server',
          event: req.event,
        };
      }),
      catchError((err: any): Observable<UpdateAdminEventResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update event: ${err.error.message}`
            : 'Unable to update event',
          event: req.event,
        })
      ),);
  }

  /**
   * Updates an existing EventType
   *
   * @param {UpdateAdminEventTypeRequest} req
   * @return {Observable<UpdateAdminEventTypeResponse>}
   */
  updateEventType(req: UpdateAdminEventTypeRequest): Observable<UpdateAdminEventTypeResponse> {
    return this.apiService.apiPatch('/admin/_table/diaryeventtype', EventType.toAPI(req.type)).pipe(
      map((res: any): UpdateAdminEventTypeResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update event type: invalid response from server',
          type: req.type,
        };
      }),
      catchError((err: any): Observable<UpdateAdminEventTypeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update event type: ${err.error.message}`
            : 'Unable to update event type',
          type: req.type,
        })
      ),);
  }

  /**
   * Updates an existing Job
   *
   * @param {UpdateAdminJobRequest} req
   * @return {Observable<UpdateAdminJobResponse>}
   */
  updateJob(req: UpdateAdminJobRequest): Observable<UpdateAdminJobResponse> {
    return this.apiService.apiPatch('/admin/_table/job', Job.toApiJobNewCustomer(req.job)).pipe(
      map((res: any): UpdateAdminJobResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          job: req.job,
        };
      }),
      catchError((err: any): Observable<UpdateAdminJobResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update job: ${err.error.message}`
            : 'Unable to update job',
          job: req.job,
        })
      ),);
  }

  /**
   * Updates one or more field values for a specified SitePage
   *
   * @param {UpdatePageContentRequest} req
   * @return {Observable<UpdatePageContentResponse>}
   */
  updatePageContent(req: UpdatePageContentRequest): Observable<UpdatePageContentResponse> {

    // Page fields have to be updated one at a time, so loop through all fields
    // and perform a PATCH for each, then forkJoin() Observables
    const requests: Observable<boolean>[] = req.page.fields.map((pf: PageField): Observable<boolean> => {

      // Field must have an ID from the API in order to be updated
      if (!pf.id)
        return observableOf(false);

      // Update current field and return a Boolean response
      return this.apiService.apiPatch('/admin/_table/sitepagefield', PageField.toAPI(pf)).pipe(
        map((res: any): boolean =>
          !!(res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id)
        ),
        catchError((err: any): Observable<boolean> => observableOf(false)),);
    });

    // Concatenate all PATCH requests and return the appropriate response
    return observableForkJoin(requests).pipe(
      map((res: any): UpdatePageContentResponse => {

        // Valid if all PATCH requests resulted in success
        const valid: boolean = res.filter((success: boolean): boolean => success).length === requests.length;

        return {
          error: valid ? null : 'Unable to update page fields: one or more pages failed to update',
          page: req.page,
        };
      }),
      catchError((err: any): Observable<UpdatePageContentResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update page fields: ${err.error.message}`
            : 'Unable to update page fields',
          page: req.page,
        })
      ),);
  }

  /**
   * Updates an existing PromoCode
   *
   * @param {UpdateAdminPromoCodeRequest} req
   * @return {Observable<UpdateAdminPromoCodeResponse>}
   */
  updatePromoCode(req: UpdateAdminPromoCodeRequest): Observable<UpdateAdminPromoCodeResponse> {
    return this.apiService.apiPatch('/admin/_table/promocode', PromoCode.toAPI(req.promoCode)).pipe(
      map((res: any): UpdateAdminPromoCodeResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update promotional code: invalid response from server',
          promoCode: valid ? Object.assign({}, req.promoCode, { committed: true }) : req.promoCode,
        };
      }),
      catchError((err: any): Observable<UpdateAdminPromoCodeResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update promotional code: ${err.error.message}`
            : 'Unable to update promotional code',
          promoCode: req.promoCode,
        })
      ),);
  }

  /**
   * Updates an existing Resource
   *
   * @param {UpdateAdminResourceRequest} req
   * @return {Observable<UpdateAdminResourceResponse>}
   */
  updateResource(req: UpdateAdminResourceRequest): Observable<UpdateAdminResourceResponse> {
    return this.apiService.apiPatch('/admin/save-resource', ResourceItem.toAPI(req.resource)).pipe(
      map((res: any): UpdateAdminResourceResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          resource: req.resource,
        };
      }),
      catchError((err: any): Observable<UpdateAdminResourceResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update resource: ${err.error.message}`
            : 'Unable to update resource',
          resource: req.resource,
        })
      ),);
  }

  /**
   * Updates an existing AdminUser
   *
   * @param {UpdateAdminUserRequest} req
   * @return {Observable<UpdateAdminUserResponse>}
   */
  updateUser(req: UpdateAdminUserRequest): Observable<UpdateAdminUserResponse> {
    return this.apiService.apiPatch(
      '/admin/update-profile',
      Object.assign({}, AdminUser.toAPI(req.user), { save_on_behalf_of: parseInt(req.user.id, 10) })
    ).pipe(
      map((res: any): UpdateAdminUserResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update user: invalid response from server',
          user: valid ? Object.assign({}, req.user, { committed: true }) : req.user,
        };
      }),
      catchError((err: any): Observable<UpdateAdminUserResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update user: ${err.error.message}`
            : 'Unable to update user',
          user: req.user,
        })
      ),);
  }

  /**
   * Updates the reward points for a specified user
   *
   * @param {UpdateAdminUserPointsRequest} req
   * @return {Observable<UpdateAdminUserPointsResponse>}
   */
  updateUserPoints(req: UpdateAdminUserPointsRequest): Observable<UpdateAdminUserPointsResponse> {
    const apiData: any = Object.assign({}, UserPoints.toAPI(req.points), { id: req.user.id });

    apiData.role_id = req.user.profile.userType;

    return this.apiService.apiPatch('/admin/_table/user', apiData).pipe(
      map((res: any): UpdateAdminUserPointsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update user points: invalid response from server',
          user: valid ? Object.assign({}, req.user, { committed: true, points: req.points }) : req.user,
        };
      }),
      catchError((err: any): Observable<UpdateAdminUserPointsResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update user points: ${err.error.message}`
            : 'Unable to update user points',
          user: req.user,
        })
      ),);
  }

  /**
   * Updates an existing Warranty
   *
   * @param {UpdateAdminWarrantyRequest} req
   * @return {Observable<UpdateAdminWarrantyResponse>}
   */
  updateWarranty(req: UpdateAdminWarrantyRequest): Observable<UpdateAdminWarrantyResponse> {
    return this.apiService.apiPatch('/admin/_table/job', Warranty.toApiPost(req.warranty)).pipe(
      map((res: any): UpdateAdminWarrantyResponse => {
        // For updating warranty specific details
        this.apiService.apiPatch('/admin/_table/warranty', { id: req.warranty.warranty_id, product_id: req.warranty.productId, product_serial_number: req.warranty.serialNumber, install_date: req.warranty.dateOfInstallation, save_on_behalf_of: req.warranty.userId })
          .subscribe(() => { });
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          warranty: req.warranty,
        };
      }),
      catchError((err: any): Observable<UpdateAdminWarrantyResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update warranty: ${err.error.message}`
            : 'Unable to update warranty',
          warranty: req.warranty,
        })
      ),);
  }

  /**
   * Updates an existing Customer
   *
   * @param {UpdateAdminCustomerRequest} req
   * @return {Observable<UpdateAdminCustomerResponse>}
   */
  updateCustomer(req: UpdateAdminCustomerRequest): Observable<UpdateAdminCustomerResponse> {
    const apiData: any = Object.assign({}, Customer.toAPIUpdateCustomer(req.customer), { id: req.customer.customerId });

    return this.apiService.apiPatch('/admin/_table/customer', apiData).pipe(
      map((res: any): UpdateAdminCustomerResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          customer: req.customer,
        };
      }),
      catchError((err: any): Observable<UpdateAdminCustomerResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update customer: ${err.error.message}`
            : 'Unable to update customer',
          customer: req.customer,
        })
      ),);
  }

  /**
   * Updates an entry in the registered product table
   *
   * @param {UpdateRegisteredProductRequest} req
   * @return {Observable<UpdateRegisteredProductResponse>}
   */
  updateRegisteredProduct(req: UpdateRegisteredProductRequest): Observable<UpdateRegisteredProductResponse> {
    const apiData: any = { user_id: req.userId, new_serial_number: req.newSerialNumber, old_serial_number: req.oldSerialNumber, product_id: req.productId };

    return this.apiService.apiPatch('/update-registered-product', apiData).pipe(
      map((res: any): UpdateRegisteredProductResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          registeredProduct: req.newSerialNumber,
        };
      }),
      catchError((err: any): Observable<UpdateRegisteredProductResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update customer: ${err.error.message}`
            : 'Unable to update customer',
          registeredProduct: req.newSerialNumber,
        })
      ),);
  }

}
