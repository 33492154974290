
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {DFResponse, DFTableRequest} from '../models/df-proclub';
import {HostedCompany} from '../models/hosted-companies';

/**
 * Provides AdminCompanyService
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

@Injectable()
export class AdminCompanyService {
    /**
     * Inject the API service
     *
     * @param apiService Application's main API data connection
     */
    constructor(
        private apiService: ApiService,
    ) {
    }

    /**
     * Get a list of companies
     *
     * @param req Request object
     */
    getCompanyList(req: DFTableRequest): Observable<DFResponse<HostedCompany>> {
        //Get the full filter string given a provided device filter
        req.filter = req.buildFilter();

        //Build the URI to submit
        const uri = '/hosted-company?pagesize=300&orderby=name ASC' + req.getFullQuery();

        //Send the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<HostedCompany> => {
                return res;
            }),
            catchError((err: any): Observable<DFResponse<HostedCompany>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Could not fetch device data: ${err.error.message}`
                        : err,
                    data: null,
                    paging: null,
                    timing: null,
                })
            ),);
    }
}
