import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable ,  Subscription } from 'rxjs';

import { SparesRewardsRequestAction } from '../../../state-management/actions/rewards';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { State as RewardsState } from '../../../state-management/reducers/rewards';
import { StoreState } from '../../../state-management/store';

@Component({
  selector: 'app-spares-redeem',
  templateUrl: './spares-redeem.component.html',
  styleUrls: ['./spares-redeem.component.scss']
})
export class SparesRedeemComponent implements OnDestroy, OnInit {

  // Store states
  public loginDetails$: Observable<LoginDetailsState>;
  public rewards$: Observable<RewardsState>;

  // Subscription to store state
  private loginSub: Subscription = null;

  // Form
  public fg: UntypedFormGroup = null;

  // Flag to indicate if form has been submitted
  public submitted: boolean = false;

  // Maximum number of points available to spend
  public maxPoints: number;

  // List of available vouchers
  public availableVouchers: string[] = [];


  constructor(
    private _fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<StoreState>,
  ) {
    // Custom validator: ensures that the user has enough points to redeem a
    // selected voucher
    const maxValue = (control: AbstractControl): { [key: string]: boolean } => {
      if (!control.value) {
        return null;
      }

      return (parseInt(control.value, 10) > this.maxPoints ? { error: true } : null);
    };

    this.fg = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      points: ['', [Validators.required, maxValue]],
    });

    this.loginDetails$ = this.store.select('loginDetails');
    this.rewards$ = this.store.select('rewards');
  }

  ngOnInit() {
    this.loginSub = this.loginDetails$.subscribe((res) => {
      if (res && res.currentProfile) {
        // Pre-populate form fields with user profile values
        this.fg.get('email').setValue(res.currentProfile.email);

        // Set the maximum number of redeemable points to the number of points
        // that the user has available
        this.maxPoints = parseInt(res.currentProfile.pointsCurrent, 10);
      }
    });
  }

  ngOnDestroy() {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
  }

  /**
   * Sets the appropriate Bootstrap form-group CSS classes based on a field
   * validity
   *
   * @param {string} fieldName    FormGroup field name
   * @param {string} extraClasses Optional extra CSS classes to append
   * @return {string} CSS classes to apply to form-group element
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses) {
      classes += ` ${extraClasses}`;
    }

    const ff = this.fg.controls[fieldName];
    if (!ff) {
      return classes;
    }

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Dispatches the redeem request if the form is valid
   */
  submitForm() {
    this.submitted = true;

    if (this.fg.valid) {
      this.store.dispatch(new SparesRewardsRequestAction({
        form: this.fg.value,
        callback: () => this.router.navigate(['/rewards']),
      }));
    }
  }
}
