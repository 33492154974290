
import {debounceTime} from 'rxjs/operators';


import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Store }        from '@ngrx/store';
import { Observable ,  Subscription }   from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import * as moment from 'moment';


import { StoreState }                from '../../../state-management/store';
import { State as AdminEventsState } from '../../../state-management/reducers/admin-events';

import {
  FetchEventTypesRequestAction,
} from '../../../state-management/actions/diary';

import {
  AddAdminEventRequestAction,
  AddAdminEventTypeRequestAction,
  DeleteAdminEventRequestAction,
  DeleteAdminEventTypeRequestAction,
  FetchAdminEventsRequestAction,
  UpdateAdminEventRequestAction,
  UpdateAdminEventTypeRequestAction,
} from '../../../state-management/actions/admin-events';

import {
  FetchUserSuggestionRequestAction,
} from '../../../state-management/actions/user-suggestion';

import { DiaryEvent, EventType } from '../../../models/diary';

import { UserSuggestion } from '../../../models/user-suggestion';

import { DiarySingleEventComponent } from '../../diary/diary-single-event/diary-single-event.component';


/**
 * Summary
 *    CMS page for managing EventTypes, user events and ADEY events
 *
 * Description
 *    Displays all EventTypes and allows management. Displays all ADEY events
 *    in a paginated list. Allows a specific user to be selected which then
 *    displays all of the user's events in a separate paginated list.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.scss']
})
export class AdminEventsComponent implements OnDestroy, OnInit {

  // "adminEvents" from store
  public adminEvents$: Observable<AdminEventsState>;

  // Subscription to above Observable
  private adminEventsSub$: Subscription = null;

  // Form for adding/editing event types
  public fgEventType: UntypedFormGroup;

  // Form for selecting user
  public fgUserSelect: UntypedFormGroup;

  // Subscription to the fgUserSelect.userId.valueChanges Observable
  private userSelectSub$: Subscription = null;

  // Event type being added/edited
  public et: EventType = null;

  // Local pagination variables (from store)
  public pageNumAdey:    number = 1;
  public totalPagesAdey: number = 0;
  public pageNumUser:    number = 1;
  public totalPagesUser: number = 0;

  // Local copy of adminEvents.eventTypes.types
  public eventTypes: EventType[] = [];

  // Reference to event edit dialog
  private dialogRef;


  constructor(
    private dialog: MatDialog,
    private fb:     UntypedFormBuilder,
    private store:  Store<StoreState>,
  ) {
    this.adminEvents$ = this.store.select('adminEvents');

    this.fgEventType = this.fb.group({
      name: ['', Validators.required],
    });

    this.fgUserSelect = this.fb.group({
      userId: [null, Validators.required],
    });
  }

  ngOnInit() {
    // Update local state when store changes
    this.adminEventsSub$ = this.adminEvents$.subscribe((state: AdminEventsState) => {
      this.eventTypes     = state.eventTypes.types;
      this.pageNumAdey    = state.adeyEvents.pageNum;
      this.totalPagesAdey = state.adeyEvents.totalPages;
      this.pageNumUser    = state.userEvents.pageNum;
      this.totalPagesUser = state.userEvents.totalPages;
    });
    
    this.store.dispatch(new FetchEventTypesRequestAction());
    
    this.fetchAdeyEvents();
    
    // Fetch user suggestions when the user select form changes
    this.userSelectSub$ = this.fgUserSelect.get('userId')
    .valueChanges.pipe(
    debounceTime(500))
    .subscribe((v: string) => {
      this.store.dispatch(new FetchUserSuggestionRequestAction({query: v}));
    });
  }

  ngOnDestroy() {
    if (this.adminEventsSub$)
      this.adminEventsSub$.unsubscribe();
    if (this.userSelectSub$)
      this.userSelectSub$.unsubscribe();
  }


  /**
   * Sets up the EventType form for adding a new item
   */
  addEventType() {
    this.et = {id: null, name: ''};
    this.fgEventType.get('name').setValue(this.et.name);
  }

  /**
   * Asks for confirmation and then deletes the specified EventType
   *
   * @param {EventType} et EventType to delete
   */
  deleteEventType(et: EventType) {
    if (confirm(`Are you sure you want to delete the event type "${et.name}"?`))
      this.store.dispatch(new DeleteAdminEventTypeRequestAction({type: et}));
  }

  /**
   * Sets up the EventType form for editing an existing item
   *
   * @param {EventType} et EventType to edit
   */
  editEventType(et: EventType) {
    this.et = et;
    this.fgEventType.get('name').setValue(et.name);
  }

  /**
   * Updates or adds an EventType if the form is valid
   */
  saveEventType() {
    if (this.fgEventType.valid)
    {
      if (!this.et.id)
        this.store.dispatch(
          new AddAdminEventTypeRequestAction(
            {type: {id: null, name: this.fgEventType.value.name}}
          )
        );
      else
        this.store.dispatch(
          new UpdateAdminEventTypeRequestAction(
            {type: Object.assign({}, this.et, {name: this.fgEventType.value.name})}
          )
        );
      this.et = null;
    }
  }


  /**
   * Opens DiarySingleEventComponent dialog to add a new ADEY event
   */
  addAdeyEvent() {
    this.dialogRef = this.dialog.open(DiarySingleEventComponent, {
      data: {
        adminMode:  true,
        readOnly:   false,
        eventTypes: this.eventTypes,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new AddAdminEventRequestAction({event: result as DiaryEvent}));
    });
  }

  /**
   * Opens DiarySingleEventComponent dialog to add a new user event for the
   * currently selected user
   */
  addUserEvent() {
    const selectedUser = this.fgUserSelect.get('userId').value;
    this.dialogRef = this.dialog.open(DiarySingleEventComponent, {
      data: {
        adminMode:  true,
        readOnly:   false,
        eventTypes: this.eventTypes,
        userId:     selectedUser ? selectedUser.id : null,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new AddAdminEventRequestAction({event: result as DiaryEvent}));
    });
  }

  /**
   * Asks for confirmation and then deletes the specified DiaryEvent
   *
   * @param {DiaryEvent} e DiaryEvent to delete
   */
  deleteEvent(e: DiaryEvent) {
    if (confirm(`Are you sure you want to delete the "${e.details}" event?`))
      this.store.dispatch(new DeleteAdminEventRequestAction({event: e}));
  }

  /**
   * Opens DiarySingleEventComponent with a copy of the specified DiaryEvent.
   * When saved, the new DiaryEvent is added.
   *
   * @param {DiaryEvent} e DiaryEvent to duplicate
   */
  duplicateEvent(e: DiaryEvent) {
    this.dialogRef = this.dialog.open(DiarySingleEventComponent, {
      data: {
        event:      Object.assign({}, e, {id: null}),
        adminMode:  true,
        readOnly:   false,
        eventTypes: this.eventTypes
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new AddAdminEventRequestAction({event: result as DiaryEvent}));
    });
  }

  /**
   * Opens DiarySingleEventComponent dialog to edit an existing DiaryEvent
   *
   * @param {DiaryEvent} e DiaryEvent to edit
   */
  editEvent(e: DiaryEvent) {
    this.dialogRef = this.dialog.open(DiarySingleEventComponent, {
      data: {
        event:      e,
        adminMode:  true,
        readOnly:   false,
        eventTypes: this.eventTypes
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateAdminEventRequestAction({event: result as DiaryEvent}));
    });
  }

  /**
   * Fetches a page of ADEY events
   */
  fetchAdeyEvents() {
    this.store.dispatch(new FetchAdminEventsRequestAction({
      adeyEvents: true,
      userEvents: false,
      userId:     null,
      pageNum:    this.pageNumAdey,
      perPage:    8,
    }));
  }

  /**
   * Fetches a page of user events (based on the currently selected user)
   */
  fetchUserEvents() {
    this.store.dispatch(new FetchAdminEventsRequestAction({
      adeyEvents: false,
      userEvents: true,
      userId:     this.fgUserSelect.value.userId.id,
      pageNum:    this.pageNumUser,
      perPage:    8,
    }));
  }

  /**
   * Returns the start time of a DiaryEvent as a JavaScript Date object
   *
   * @param {DiaryEvent} e
   * @return {Date}
   */
  getEventDateTime(e: DiaryEvent): Date {
    return moment.utc(`${e.date} ${e.time}`).toDate();
  }

  /**
   * Loads the next page of ADEY events
   */
  pageNextAdey() {
    this.pageNumAdey = Math.min(this.totalPagesAdey, this.pageNumAdey + 1);
    this.fetchAdeyEvents();
  }

  /**
   * Loads the previous page of ADEY events
   */
  pagePrevAdey() {
    this.pageNumAdey = Math.max(1, this.pageNumAdey - 1);
    this.fetchAdeyEvents();
  }

  /**
   * Loads the next page of user events
   */
  pageNextUser() {
    this.pageNumUser = Math.min(this.totalPagesUser, this.pageNumUser + 1);
    this.fetchUserEvents();
  }

  /**
   * Loads the previous page of user events
   */
  pagePrevUser() {
    this.pageNumUser = Math.max(1, this.pageNumUser - 1);
    this.fetchUserEvents();
  }

  /**
   * Returns a formatted user suggestion for display in the autocomplete panel
   *
   * @param {UserSuggestion} u
   * @return {string}
   */
  displayUserSuggestion(u: UserSuggestion): string {
    return u ? `${u.title} ${u.firstName} ${u.lastName}` : '';
  }

  /**
   * Given a number of minutes, returns a more friendly string contains hours
   * and minutes as necessary
   *
   * @param {number} eventMinutes Event duration in minutes
   * @return {string} Formatted duration string
   */
  getFormattedDuration(eventMinutes: number): string {
    let formattedDuration: string = '';
    const duration = moment.duration(eventMinutes, 'minutes');
    const hours:   string = duration.hours().toString();
    const minutes: string = duration.minutes().toString();
    if (duration.hours()) formattedDuration = hours + ' hours ';
    if (duration.minutes()) formattedDuration += minutes + ' minutes';
    return formattedDuration;
  }
}
