/**
 * email reducer: maintains state for the "Contact Us" and voucher forms
 */

import { Action, ActionReducer } from '@ngrx/store';


import * as actions from '../actions/email';


// State interface definition: simple pending flag and response status messages
export interface State {
  error:   string;
  message: string;
  pending: boolean;
}


const defaultState: State = {
  error:   null,
  message: null,
  pending: false,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Initialises the state ready for a fresh form entry
     */
    case actions.INIT_EMAIL_STATE:
      return Object.assign({}, state, {
        error:   null,
        message: null,
        pending: false,
      });

    /**
     * Handles submission of the contact form
     */
    case actions.POST_CONTACT_US_REQUEST:
      return Object.assign({}, state, {
        error:   null,
        message: null,
        pending: true,
      });

    /**
     * Handles response to contact form submission
     */
    case actions.POST_CONTACT_US_RESPONSE:
      return Object.assign({}, state, {
        error:   action.payload.error,
        message: action.payload.message,
        pending: false,
      });

    /**
     * Handles submission of the voucher redeem form
     */
    case actions.POST_VOUCHER_REDEEM_REQUEST:
      return Object.assign({}, state, {
        error:   null,
        message: null,
        pending: true,
      });

    /**
     * Handles response to voucher redeem form submission
     */
    case actions.POST_VOUCHER_REDEEM_RESPONSE:
      return Object.assign({}, state, {
        error:   action.payload.error,
        message: action.payload.message,
        pending: false,
      });

    /**
     * Handles submission of the demokit redeem form
     */
    case actions.POST_DEMOKIT_REDEEM_REQUEST:
      return Object.assign({}, state, {
        error:   null,
        message: null,
        pending: true,
      });

    /**
     * Handles response to demokit redeem form submission
     */
    case actions.POST_DEMOKIT_REDEEM_RESPONSE:
      return Object.assign({}, state, {
        error:   action.payload.error,
        message: action.payload.message,
        pending: false,
      });

    default:
      return state;
  }
}
