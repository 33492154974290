<div class="content-pages row" *ngIf="contentPages$ | async; let contentPages">

  <app-message-panel kind="ERROR" *ngIf="contentPages.error">{{contentPages.error}}</app-message-panel>

  <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let page of contentPages.pages">
    <mat-card>
      <mat-card-title>{{page.title}}</mat-card-title>
      <mat-card-subtitle>{{page.fields.length}} field(s)</mat-card-subtitle>
      <mat-card-content>{{page.description}}</mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="editPage(page)">Edit page content</button>
      </mat-card-actions>
    </mat-card>
  </div>

</div>