import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';



import { Injectable } from '@angular/core';

import { ApiSenseService } from './api-sense.service';

import { SystemMessageResponse } from '../models/system-message';
import * as moment from 'moment';
import {SystemMessageModalComponent} from '../components/system-message-modal/system-message-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable()
export class SystemMessageService {

  constructor(
    private apiService: ApiSenseService,
    private dialog: MatDialog
  ) { }

  /**
   * System message from the SENSE API
   *
   * @return {Observable<SystemMessageResponse>}
   */
  getSystemMessage(): Observable<SystemMessageResponse> {
    return this.apiService.apiGet('/system/messages/active').pipe(
      map((res: any): SystemMessageResponse => {

        if (res.data.length > 0 && res.data[0].type === 'maintenance') {
          this.showSystemMessageModal(res.data);
        }

        return {
          response: res.data,
          error:         null,
        };
      }),
      catchError((err: any): Observable<SystemMessageResponse> => {
        return observableOf({
          response: null,
          error: err.message,
        });
      }),);
  }

  private showSystemMessageModal(data): void {

    let systemMessageDismissed = JSON.parse(localStorage.getItem('message_system_dismissed'));
    if (systemMessageDismissed) {
      localStorage.removeItem('message_system_dismissed');
      systemMessageDismissed = null;
    }

    setTimeout(() => {
      if (!this.dialog.getDialogById('message_system')) {
        this.dialog.open(SystemMessageModalComponent, {
          id: 'message_system',
          panelClass: 'feature-modal-dialog',
          width: '500px',
          data: data
        });
      }
    }, 0);
  }
}
