/**
 * warranty-stats actions: defines all actions relating to the user's "Warranty
 * Statistics" page.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  FetchWarrantyFullStatsResponse,
  FetchMonthlyWarrantyStatsRequest,
  FetchMonthlyWarrantyStatsResponse,
} from '../../models/warranty';


// Action type definitions
export const FETCH_WARRANTY_FULL_STATS_REQUEST     = 'FETCH_WARRANTY_FULL_STATS_REQUEST';
export const FETCH_WARRANTY_FULL_STATS_RESPONSE    = 'FETCH_WARRANTY_FULL_STATS_RESPONSE';
export const FETCH_WARRANTY_MONTHLY_STATS_REQUEST  = 'FETCH_WARRANTY_MONTHLY_STATS_REQUEST';
export const FETCH_WARRANTY_MONTHLY_STATS_RESPONSE = 'FETCH_WARRANTY_MONTHLY_STATS_RESPONSE';


// Action definitions
export class FetchWarrantyFullStatsRequestAction implements Action {
  readonly type = FETCH_WARRANTY_FULL_STATS_REQUEST;
}

export class FetchWarrantyFullStatsResponseAction implements Action {
  readonly type = FETCH_WARRANTY_FULL_STATS_RESPONSE;
  constructor(public payload: FetchWarrantyFullStatsResponse) {}
}

export class FetchWarrantyMonthlyStatsRequestAction implements Action {
  readonly type = FETCH_WARRANTY_MONTHLY_STATS_REQUEST;
  constructor(public payload: FetchMonthlyWarrantyStatsRequest) {}
}

export class FetchWarrantyMonthlyStatsResponseAction implements Action {
  readonly type = FETCH_WARRANTY_MONTHLY_STATS_RESPONSE;
  constructor(public payload: FetchMonthlyWarrantyStatsResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchWarrantyFullStatsRequestAction
  | FetchWarrantyFullStatsResponseAction
  | FetchWarrantyMonthlyStatsRequestAction
  | FetchWarrantyMonthlyStatsResponseAction;
