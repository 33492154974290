/**
 * registration models
 */

import { UserProfile, SimpleUserProfile } from './user-profile';


/**
 * Encapsulates a single user role
 */
export class UserRole {
  id: string;
  name: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {UserRole}
   */
  static fromAPI(data: any) {
    return {
      id:   data.id,
      name: data.title,
    };
  }
}


export interface FetchUserRolesResponse {
  error: string;
  roles: UserRole[];
}

export class RegistrationRequest {

  userType:                   number;
  title:                      string;
  firstName:                  string;
  lastName:                   string;
  dob:                        string;
  dob_yyyy:                   number;
  dob_mm:                     number;
  dob_dd:                     number;
  address1:                   string;
  address2?:                  string;
  town?:                      string;
  county?:                    string;
  countries_by_country_id: {
    country_code: string,
    country_name: string
  };
  country_id:                 string;
  postCode:                   string;
  email:                      string;
  password:                   string;
  mobile:                     string;
  telephone?:                 string;
  companyName:                string;
  companySize:                string;
  howDidYouHear?:             number;
  projects?:                  string;
  gasSafeRegistered?:         boolean;
  gasSafeNumber?:             string;
  oftecRegistered?:           boolean;
  oftecNumber?:               string;
  magnaCleanseOwned?:         boolean;
  magnaCleanseSerial?:        string;
  contract?:                  boolean;
  accreditations?:            any;
  preferredboiler?:           string;
  contactByPost:              boolean;
  contactByPhone:             boolean;
  contactByEmail:             boolean;
  dataProtectionConfirm?:     boolean;
  termsAndConditionsConfirm?: boolean;

  /**
   * Converts a UserProfile model to a RegistrationRequest
   *
   * @param {UserProfile} p
   * @return RegistrationRequest
   */
  static fromUserProfile(p: UserProfile): RegistrationRequest {
    return Object.assign({}, p);
  }
}

export class SimpleRegistrationRequest {

  userType:                   number;
  email:                      string;
  password:                   string;
  dob:                        string;
  country_id:                 string;
  companyName:                string;
  firstName:                  string;
  lastName:                   string;
  telephone:                  number;
  address1:                   string;
  address2?:                  string;
  town?:                      string;
  county?:                    string;
  postCode:                   string;
  simpleMagnaCleanse?:        boolean;
  dataProtectionConfirm?:     boolean;
  termsAndConditionsConfirm?: boolean;

  /**
   * Converts a SimpleUserProfile model to a SimpleRegistrationRequest
   *
   * @param {UserProfile} p
   * @return RegistrationRequest
   */
  static fromUserProfile(p: SimpleUserProfile): SimpleRegistrationRequest {
    return Object.assign({}, p);
  }
}

export interface RegistrationResponse {
  userId:  string;
  activated?: boolean;
  error:   boolean;
  message: string;
}
