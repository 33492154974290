export const environment = {
  production: true,
  name: 'production',
  api: {
    baseSenseUrl: 'https://api.sense.adey.com/v1',
    baseUrl: 'https://adeyproclub.com/api/v5.0.0',
    agm_key: 'AIzaSyDXRrfueE6ieAT6ctrWkbVPTN5mXNAvEhg',
  },
  files: {
    baseUrl: 'https://adeyproclub.com/',
  },
  externalRoutes: {
    policy_privacy: 'https://www.adey.com/privacy-policy',
    faqs: 'https://www.adey.com/storage/app/media/FAQs_ProCheck.pdf',
    license: 'https://www.adey.com/storage/app/media/Adey_Water_Testing_App_Licence.pdf'
  },
  proCheckUrl: 'procheck.adey.com',
  businessTools: {
    tradehelp: 'https://office.tradehelp.co.uk',
    truequote: 'https://account.truequote.co.uk',
  },
};
