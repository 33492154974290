/**
 * resource models
 */

import { environment } from '../../environments/environment';


export enum BooleanOperator {
  AND = 1,
  OR,
}


/**
 * Encapsulates an attachment for a Resource (reference to a downloadable file
 * or a video)
 */
class ResourceAttachment {

  // MIME type of the file (if applicable)
  contentType: string;

  // DOWNLOAD (downloadable file) or VIDEO (streaming from URL)
  itemType: string;

  // For DOWNLOAD types, the name that should be given to the file when
  // downloaded
  downloadName: string;

  // Relative URL for the item
  url: string;

  // Absolute URL to be used on the site (read-only, generated from "url" and
  // file server base URL in environment)
  siteUrl?: string;

  // Attachment data when adding/updating resource (base64)
  data?: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {ResourceAttachment}
   */
  static fromAPI(data: any): ResourceAttachment {
    return {
      url:          data.attachment_url,
      contentType:  data.attachment_mime_type,
      downloadName: data.attachment_download_name,
      itemType:     data.attachment_type,
      siteUrl: data.attachment_type === 'DOWNLOAD'
        ? environment.files.baseUrl + data.attachment_url
        : data.attachment_url,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {ResourceAttachment} r
   * @return {object}
   */
  static toAPI(r: ResourceAttachment): any {
    return {
      attachment_url:           r.url,
      attachment_mime_type:     r.contentType,
      attachment_download_name: r.downloadName,
      attachment_type:          r.itemType,
      data:                     r.data,
    };
  }

  /**
   * Returns an example model
   *
   * @param {string} itemType Returns an example of this type
   * @return {ResourceAttachment}
   */
  static getExample(itemType: string = 'DOWNLOAD'): ResourceAttachment {
    return {
      url: itemType === 'DOWNLOAD'
        ? '/assets/test-pdf.pdf'
        : 'https://www.youtube.com/embed/3GrsEIFVr4A',
      contentType: itemType === 'DOWNLOAD' ? 'application/x-pdf' : null,
      itemType,
      downloadName: itemType === 'DOWNLOAD' ? 'TestPDF.pdf' : null,
    };
  }
}

/**
 * Encapsulates a single resource, which can be a downloadable file or a
 * streamable video
 */
export class ResourceItem {
  id: string;

  // True when item has been confirmed by authority (API)
  confirmed?: boolean;

  title:       string;
  description: string;

  // Flag to indicate if item is visible to users
  visible: boolean;

  // Order in which the resources appear on the frontend
  order?: number;

  // Attachment contains the content URL and content type
  attachment: ResourceAttachment;

  // Item thumbnail URL
  imageUrl: string;

  // Thumbnail image data when adding/updating resource (base64)
  imageData?: string;

  // Resource pages in which this item should be included
  pages: string[];

  // Categories used for structuring of resources within the specified resource
  // page
  categories: string[];

  // Keywords used during resource search
  keywords: string[];

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {ResourceItem}
   */
  static fromAPI(data: any): ResourceItem {
    return {
      id:          data.id,
      title:       data.title,
      description: data.description,
      imageUrl:    data.imageurl ? environment.files.baseUrl + data.imageurl.toString() : null,
      visible:     data.visible,
      order:       data.order,
      confirmed:   true,
      attachment:  ResourceAttachment.fromAPI(data),
      pages: data.sitepage_by_resource_sitepage
        ? data.sitepage_by_resource_sitepage.map((v: any): string => v.code)
        : [],
      categories: data.category_by_category_resource
        ? data.category_by_category_resource.map((v: any): string => v.title)
        : [],
      keywords: data.tag_by_resource_tag
        ? data.tag_by_resource_tag.map((v: any): string => v.title)
        : [],
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {ResourceItem} r
   * @return {object}
   */
  static toAPI(r: ResourceItem): any {
    return Object.assign(
      {
        id:          r.id,
        title:       r.title,
        description: r.description,
        visible:     r.visible,
        order:       r.order,
        image_data:  r.imageData,

        pages:      r.pages,
        categories: r.categories,
        keywords:   r.keywords,

        // Do not send URL as it will be overwritten if set by the full site
        // URL
        //imageurl: r.imageUrl,
      },
      ResourceAttachment.toAPI(r.attachment)
    );
  }

  /**
   * Returns an example model
   *
   * @return {ResourceItem}
   */
  static getExample(): ResourceItem {
    const id:         string   = Math.floor(Math.random() * 1000).toString();
    const itemType:   string   = Math.floor(Math.random() * 10) % 2 === 0 ? 'VIDEO' : 'DOWNLOAD';
    const pages:      string[] = [];
    const categories: string[] = [];
    const keywords:   string[] = [];

    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Sales Tools');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Resources');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Homeowner Videos');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Infographics');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Installation guides');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Product brochures');
    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Other resources');

    if (Math.floor(Math.random() * 10) % 2 === 0) pages.push('Another page');
    if (Math.floor(Math.random() * 10) % 2 === 0) categories.push('Example 1');
    if (Math.floor(Math.random() * 10) % 2 === 0) categories.push('Example 2');
    if (Math.floor(Math.random() * 10) % 2 === 0) categories.push('Example 3');
    if (Math.floor(Math.random() * 10) % 2 === 0) keywords.push('term-1');
    if (Math.floor(Math.random() * 10) % 2 === 0) keywords.push('term-2');
    if (Math.floor(Math.random() * 10) % 2 === 0) keywords.push('term-3');

    return {
      id,
      confirmed:   true,
      title:       `Test ${itemType} #${id}`,
      description: `Pages: (${pages.join(', ')}), ` +
        `categories: (${categories.join(', ')}), ` +
        `keywords: (${keywords.join(', ')}). `,
      visible:    true,
      attachment: ResourceAttachment.getExample(itemType),
      imageUrl:   'http://lorempixel.com/200/400/technics/' + Math.floor(Math.random() * 10).toString(),
      pages,
      categories,
      keywords,
    };
  }
}

/**
 * Encapsulates all search criteria used when requesting resources
 */
export class ResourceItemSearchCriteria {

  // Values used to filter based on resource fields
  title:    string;
  keywords: string[];
  docType:  string;

  // Boolean operators for each field controlling if any or all must match
  titleOperator:    BooleanOperator;
  keywordsOperator: BooleanOperator;
  docTypeOperator:  BooleanOperator;

  /**
   * Converts the model to the API data format
   *
   * @param {ResourceItemSearchCriteria} v
   * @return {object}
   */
  static toAPI(v: ResourceItemSearchCriteria): any {
    return {
      title:            v ? v.title    : null,
      docType:          v ? v.docType  : null,
      keywords:         v ? v.keywords : null,
      titleOperator:    v ? (v.titleOperator    === BooleanOperator.AND ? 'AND' : 'OR') : 'OR',
      keywordsOperator: v ? (v.keywordsOperator === BooleanOperator.AND ? 'AND' : 'OR') : 'OR',
      docTypeOperator:  v ? (v.docTypeOperator  === BooleanOperator.AND ? 'AND' : 'OR') : 'OR',
    };
  }
}


export class FetchResourceItemsRequest {
  page:     string;
  category: string;
  search:   ResourceItemSearchCriteria;
}

export class FetchResourceItemsResponse {
  error: string;
  items: ResourceItem[];
}
