/**
 * postcode-lookup actions: defines all actions relating to postcode and
 * address lookups.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  GetPostcodeAddressRequest,
  GetPostcodeAddressResponse,
  PostcodeLookupRequest,
  PostcodeLookupResponse,
} from '../../models/postcode-lookup';


// Action type definitions
export const INIT_POSTCODE_LOOKUP          = 'INIT_POSTCODE_LOOKUP';
export const GET_POSTCODE_ADDRESS_REQUEST  = 'GET_POSTCODE_ADDRESS_REQUEST';
export const GET_POSTCODE_ADDRESS_RESPONSE = 'GET_POSTCODE_ADDRESS_RESPONSE';
export const POSTCODE_LOOKUP_REQUEST       = 'POSTCODE_LOOKUP_REQUEST';
export const POSTCODE_LOOKUP_RESPONSE      = 'POSTCODE_LOOKUP_RESPONSE';


// Action definitions
export class InitPostcodeLookup implements Action {
  readonly type = INIT_POSTCODE_LOOKUP;
}

export class GetPostcodeAddressRequestAction implements Action {
  readonly type = GET_POSTCODE_ADDRESS_REQUEST;
  constructor(public payload: GetPostcodeAddressRequest) {}
}

export class GetPostcodeAddressResponseAction implements Action {
  readonly type = GET_POSTCODE_ADDRESS_RESPONSE;
  constructor(public payload: GetPostcodeAddressResponse) {}
}

export class PostcodeLookupRequestAction implements Action {
  readonly type = POSTCODE_LOOKUP_REQUEST;
  constructor(public payload: PostcodeLookupRequest) {}
}

export class PostcodeLookupResponseAction implements Action {
  readonly type = POSTCODE_LOOKUP_RESPONSE;
  constructor(public payload: PostcodeLookupResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | InitPostcodeLookup
  | GetPostcodeAddressRequestAction
  | GetPostcodeAddressResponseAction
  | PostcodeLookupRequestAction
  | PostcodeLookupResponseAction;
