<div class="modal-wrapper">
  <h1 class="text-center" matDialogTitle>SYSTEM MESSAGE</h1>
  <h2 class="text-center" matDialogTitle>SCHEDULE MAINTENANCE</h2>

  <ng-container >
    <mat-dialog-content>
      <div class="row">
        <div *ngIf="!typeMessage" class="col-xs-12">
          <p>We’re making some exciting changes,</p>
          <p>which mean that you won’t be able to use</p>
          <p>this portal for a little while.</p>
          <p>The maintenance is taking place:</p>
          <p>{{startDate}} – {{endDate}}</p>
          <p>We hope to get back up and running as soon as possible</p>
        </div>
        <div *ngIf="typeMessage" class="col-xs-12">
          <p>We’re currently making some exciting changes, which mean you can’t use this portal right now.</p>
          <p>We hope to get back up and running as soon as possible.</p>
          <p>Sorry for any inconvenience caused.</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="btn btn-full-width background-adey-red white-themes" style="color: white" mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  </ng-container>

</div>
