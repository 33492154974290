import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OnboardingQuestion } from '../../models/onboarding-questions';
import * as actions from '../actions/onboarding-questions';

export interface State extends EntityState<OnboardingQuestion> {
  error: string;
  pending: boolean;
}

export const adapter: EntityAdapter<OnboardingQuestion> = createEntityAdapter<OnboardingQuestion>();

export const initialState: State = adapter.getInitialState({
  error: null,
  pending: false,
});

export function reducer(state: State = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.GET_ONBOARDING_QUESTIONS:
      return { ...state, ...{ pending: true } };
    case actions.GET_ONBOARDING_QUESTIONS_SUCCESS:
      return adapter.addMany(action.payload, state);
    case actions.GET_ONBOARDING_QUESTIONS_FAILURE:
      return { ...state, ...{ error: action.error.message } };
    case actions.PUT_ONBOARDING_ANSWERS_FAILURE:
      return { ...state, ...{ error: action.error.message, pending: false } };
    case actions.PUT_ONBOARDING_ANSWERS_SUCCESS:
      return { ...state, ...{ error: null, message: action.payload.message, pending: false } };
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
} = adapter.getSelectors();
