<h2 matDialogTitle>Update password</h2>

<mat-dialog-content>
  <div class="validation-fields" *ngIf="adminUsers$ | async; let adminUsers">
    <app-message-panel kind="ERROR" *ngIf="adminUsers.error">
      {{adminUsers.error}}
    </app-message-panel>
  </div>
  <div class="validation-fields" *ngIf="loginDetails$ | async; let loginDetails">
    <app-message-panel kind="ERROR" *ngIf="loginDetails.error">
      {{(loginDetails$|async).error}}
    </app-message-panel>
  </div>

  <form [formGroup]="fg" novalidate (ngSubmit)="onSubmitForm()">
    <div [class]="formGroupClass('password', 'passwordConfirm')">
      <input type="password" class="form-control" id="password" formControlName="password" placeholder="New password">
    </div>
    <div [class]="formGroupClass('passwordConfirm', 'password')">
      <input type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm" placeholder="Confirm password">
    </div>
  </form>
  <p>Your password must be at least 8 characters and contain<br> at least 1 lowercase, uppercase and special character.</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button matDialogClose>Close</button>
  <button mat-button (click)="saveForm()" [disabled]="!fg.valid">Update password</button>
</mat-dialog-actions>
