import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-tools-widget',
  templateUrl: './business-tools-widget.component.html',
  styleUrls: ['./business-tools-widget.component.scss']
})
export class BusinessToolsWidgetComponent {

  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }

  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string) {
    this.router.navigate([pageRef]);
  }
}
