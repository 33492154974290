import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable ,  Subscription } from 'rxjs';

import { Warranty } from '../../models/warranty';
import { FetchUserProfileRequestAction } from '../../state-management/actions/login-details';
import { FetchWarrantiesRequestAction, UpdateWarrantyRequestAction } from '../../state-management/actions/warranty-list';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as WarrantyListState } from '../../state-management/reducers/warranty-list';
import { StoreState } from '../../state-management/store';
import { WarrantyEditModalComponent } from '../warranty/warranty-edit-modal/warranty-edit-modal.component';

/**
 * Summary
 *    Page allowing user to view/edit all registered Warranties
 *
 * Description
 *    The Warranty List page shows a list of all user Warranties, each with a
 *    link to view, edit, download and print them. The page also displays basic
 *    Warranty statistics.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty-list',
  templateUrl: './warranty-list.component.html',
  styleUrls: ['./warranty-list.component.scss']
})
export class WarrantyListComponent implements OnInit, OnDestroy {

  // Current date for display
  public currentDate: Date = moment().toDate();

  // Local pagination variables (from store)
  public pageNumWarranties: number = 1;
  public totalPagesWarranties: number = 0;

  // Paginated page numbers
  public pagination: number[] = [];

  // Filters for the pages records
  public fg: UntypedFormGroup;

  public userId: string = null;

  // Store state
  public warrantyList$: Observable<WarrantyListState>;
  public loginDetails$: Observable<LoginDetailsState>;

  // Subscription to above Observable
  private warrantyListSub$: Subscription = null;
  private loginDetailsSub$: Subscription = null;

  // Reference to warranty details dialog
  private dialogRef_details;

  constructor(
    private dialog: MatDialog,
    private store: Store<StoreState>,
    private fb: UntypedFormBuilder,
  ) {
    this.loginDetails$ = this.store.select('loginDetails');
  }

  ngOnInit() {
    // OrderBy form
    this.fg = this.fb.group({ orderBy: [false] });
    this.fg.get('orderBy').valueChanges.subscribe(() => this.fetchAllWarranties());

    this.store.dispatch(new FetchUserProfileRequestAction());

    this.warrantyList$ = this.store.select('warrantyList');

    // Update local state when store changes
    this.warrantyListSub$ = this.warrantyList$.subscribe((state: WarrantyListState) => {
      this.pageNumWarranties = state.pageNum;
      this.totalPagesWarranties = state.totalPages;

      if (this.totalPagesWarranties) this.paginate();
    });

    // Subscribe to the state in order to check if a pwarrantyListSubromoShopToken is present
    this.loginDetailsSub$ = this.loginDetails$.subscribe((state: LoginDetailsState) => {
      if (state.user && state.currentProfile) {
        this.userId = state.user.admin ? null : state.currentProfile.id;
        this.fetchAllWarranties();
      }
    });
  }

  /**
   * Sets the pagination variable based on the current and total pages
   */
  paginate() {
    const pagination = [];

    let maxNumbersToPaginate = 9;

    if (this.pageNumWarranties > 1) {
      let timesNumbersAdded = 0;

      for (let i = this.pageNumWarranties; i > 1; i--) {
        if (timesNumbersAdded >= 5) break;

        timesNumbersAdded++;
        maxNumbersToPaginate--;
        pagination.unshift(this.pageNumWarranties - timesNumbersAdded);
      }
    }

    pagination.push(this.pageNumWarranties);

    if (this.totalPagesWarranties > this.pageNumWarranties) {
      for (let i = this.pageNumWarranties + 1; i < this.totalPagesWarranties + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    this.pagination = pagination;
  }

  /**
   * Fetches all warranties
   *
   * @param {number}        pageNum     Page number of results to fetch
   */
  fetchAllWarranties(pageNum: number = 1) {
    const perPage = 50;
    this.store.dispatch(new FetchWarrantiesRequestAction({
      id: this.userId,
      perPage,
      pageNum,
      upcoming: this.fg.get('orderBy').value,
    }));
  }

  /**
   * Fetches the next page of Warranties
   *
   * @param {number} page The number of pages to go forward by, defaults to one
   */
  pageNextWarranties(page: number = 1) {
    this.pageNumWarranties = Math.min(this.totalPagesWarranties, this.pageNumWarranties + page);
    this.fetchAllWarranties(this.pageNumWarranties);
  }

  /**
   * Fetches the previous page of Warranties
   *
   * @param {number} page The number of pages to go back by, defaults to one
   */
  pagePrevWarranties(page: number = 1) {
    this.pageNumWarranties = Math.max(1, this.pageNumWarranties - page);
    this.fetchAllWarranties(this.pageNumWarranties);
  }

  /**
   * Fetches a specific warranty page
   */
  goToPageWarranties(page: number) {
    this.pageNumWarranties = page;
    this.fetchAllWarranties(this.pageNumWarranties);
  }

  /**
   * Opens WarrantyEditModalComponent with the specified Warranty. On close,
   * the Warranty is updated if a new model is passed in the dialog result.
   *
   * @param {Warranty} w
   */
  editWarranty(w: Warranty) {
    this.dialogRef_details = this.dialog.open(WarrantyEditModalComponent, {
      data: {
        warrantyId: w.id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_details.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.store.dispatch(new UpdateWarrantyRequestAction(Object.assign({}, w, result)));
          }
        }
      );
  }

  /**
   * Returns a formatted date (YYYY-MM-DD) or "-" if NULL
   *
   * @param {string} dateStr Initial date string (must be in a known date format)
   * @return {string} Formatted date or "-"
   */
  formatDate(dateStr: string): string {
    if (dateStr)
      return moment(dateStr).format('YYYY-MM-DD');
    else
      return '-';
  }

  /**
   * Converts a date string to a JavaScript Date object
   *
   * @param {string} dateStr Date string (must be in a known date format)
   * @return {Date}
   */
  toDate(dateStr: string): Date {
    return moment(dateStr).toDate();
  }

  ngOnDestroy() {
    if (this.warrantyListSub$) {
      this.warrantyListSub$.unsubscribe();
    }

    if (this.loginDetailsSub$) {
      this.loginDetailsSub$.unsubscribe();
    }
  }
}