import { BusinessTool, BusinessToolVerificationToken } from '../../models/business-tools';
import * as businessToolsActions from '../actions/business-tools';

export interface State {
  error: boolean;
  message: string;
  pending: boolean;
  verificationToken: BusinessToolVerificationToken;
  businessTools: BusinessTool[];
}

const defaultState: State = {
  error: false,
  message: null,
  pending: false,
  verificationToken: null,
  businessTools: null,
};


export function reducer(
  state: State = defaultState,
  action: businessToolsActions.Actions,
): State {
  switch (action.type) {
    case businessToolsActions.FETCH_BUSINESS_TOOLS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        message: null,
        pending: true,
        businessTools: null,
      });
    case businessToolsActions.FETCH_BUSINESS_TOOLS_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        message: action.payload.message,
        pending: false,
        businessTools: action.payload.data,
      });
    case businessToolsActions.FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST:
      return Object.assign({}, state, {
        error: null,
        message: null,
        pending: true,
        verificationToken: null,
      });
    case businessToolsActions.FETCH_BUSINESS_TOOLS_VERIFICATION_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        message: action.payload.message,
        pending: false,
        verificationToken: action.payload.data,
      });
    default:
      return state;
  }
}
