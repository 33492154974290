<div class="container-fluid admin-users-page">

  <div class="row content-page" *ngIf="adminUsers$ | async; let adminUsers">

    <div class="container-title">
      <h1 class="white">Users</h1>
      <button class="btn btn-default pull-right" (click)="addUser(adminUsers.accreditations)">Add a new user...</button>
    </div>

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <h2 class="color-adey-red">Search</h2>
        <form [formGroup]="fgSearch" novalidate (ngSubmit)="performSearch($event)">
          <div class="row">
            <div class="col-xs-12 col-sm-3">
              <mat-form-field>
                <mat-select formControlName="userType" placeholder="User type">
                  <mat-option [value]="null">-- Any --</mat-option>
                  <mat-option *ngFor="let r of roleOptions" [value]="r.value">{{r.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2">
              <mat-form-field>
                <input matInput formControlName="userId" placeholder="User ID" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2">
              <mat-form-field>
                <input matInput formControlName="userName" placeholder="Name" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2">
              <mat-form-field>
                <input matInput formControlName="userCompanyName" placeholder="Company name" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2">
              <mat-form-field>
                <input matInput formControlName="userAddress" placeholder="Address" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-2">
              <mat-checkbox md-no-ink class="md-primary" formControlName="onlyShowDeleted">Only show deleted users
              </mat-checkbox>
            </div>
            <div class="col-xs-12 col-sm-2">
              <mat-checkbox md-no-ink class="md-primary" formControlName="onlyShowRequestedDeletion">Only show users who have requested deletion
              </mat-checkbox>
            </div>
          </div>
          <div class="form-group">
            <input class="btn btn-default" type="submit" value="Search users" />
          </div>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="adminUsers.error">
      <div class="section-container background-white col-xs-12">
        <app-message-panel kind="ERROR">{{adminUsers.error}}</app-message-panel>
      </div>
    </div>

    <div class="row" *ngIf="adminUsers.pending">
      <div class="section-container background-white col-xs-12">
        <app-waiting-spinner kind="bar">Loading users...</app-waiting-spinner>
      </div>
    </div>

    <div class="row" *ngIf="!adminUsers.pending && (!adminUsers.users || adminUsers.users.length === 0)">
      <div class="section-container background-white col-xs-12">
        <app-message-panel kind="INFO">No users to display</app-message-panel>
      </div>
    </div>

    <div class="row" *ngIf="!adminUsers.pending && (adminUsers.users && adminUsers.users.length > 0)">
      <div class="section-container background-white col-xs-12">
        <h2 class="color-adey-red">User list</h2>

        <div class="row pagination-wrapper">
          <div class="col-xs-12 text-center">
            <ul class="pagination">
              <!-- / Prev btns -->
              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="goToPage(1)">&lt;&lt; First</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev(5)">&lt;&lt;</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev()">&lt;</a>
              </li>
              <!-- / Prev btns -->

              <!-- Pagination -->
              <li *ngFor="let num of pagination" [class.active]="num === pageNum" class="page-item">
                <a class="page-link" (click)="goToPage(num)">{{ num }}</a>
              </li>
              <!-- / Pagination -->


              <!-- / Next btns -->
              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext()">&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext(5)">&gt;&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="goToPage(totalPages)">Last &gt;&gt;</a>
              </li>
              <!-- / Next btns -->
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let user of adminUsers.users">
            <mat-card>
              <mat-card-header>
                <img mat-card-avatar [src]="user.profile.photoUrl" alt="Profile photo"
                  *ngIf="user.profile.photoUrl && !user.pending" />
                <app-waiting-spinner kind="spinner" *ngIf="user.pending"></app-waiting-spinner>
                <mat-card-title>
                  Name: {{user.profile.title}} {{user.profile.firstName}} {{user.profile.lastName}}
                </mat-card-title>
                <mat-card-subtitle>Company: {{user.profile?.companyName}}</mat-card-subtitle>
                <mat-card-subtitle>User ID: {{user.id}}</mat-card-subtitle>
                <mat-card-subtitle>User type: {{user.role?.name}}</mat-card-subtitle>
                <mat-card-subtitle>User type ID: {{user.profile?.userType}}</mat-card-subtitle>
                <mat-card-subtitle [ngClass]="user.profile?.requestedDeletionAt ? 'color-adey-red' : ''">Deletion requested on: {{ user.profile?.requestedDeletionAt |
                  date }}
                </mat-card-subtitle>
                <mat-card-subtitle [ngClass]="user.profile?.deletedAt ? 'color-adey-red' : ''">Deleted on: {{ user.profile?.deletedAt | date }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <strong *ngIf="user.admin">ADMIN</strong>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="editUser(user, adminUsers.accreditations)">Edit</button>
                <button mat-button (click)="deleteUser(user)">Delete</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>