<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white prod-selector">Product selector</h1>
    </div>
    <app-page-content page="PRODUCT_SELECTOR" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="product-selector-container background-white col-xs-12" *ngIf="state$ | async; let state">


      <app-message-panel kind="ERROR" *ngIf="state.error">{{state.error}}</app-message-panel>

      <app-product-selector
        [fetching]="state.pending"
        [results]="state.results"
        (onQuery)="performQuery($event)"
      >
      </app-product-selector>
    </div>

  </div>
</div>
