<div class="container-fluid">
  <div class="row content-page">
    <div class="container-title has-sub" [ngSwitch]="pageName">
      <h1 class="white" *ngSwitchCase="'Homeowner videos'">Homeowner resources</h1>
      <h1 class="white" *ngSwitchCase="'Installation guides'">Installer resources</h1>
      <h1 class="white" *ngSwitchDefault>{{ pageName }}</h1>
    </div>

    <app-page-content page="HOMEOWNER_VIDEOS" field="intro" *ngIf="pageName === 'Homeowner videos'" class="color-white intro-above-content"></app-page-content>
    <app-page-content
      page="CUSTOMER_INFORMATION" field="intro"
      *ngIf="pageName === 'Installation guides'
              || pageName === 'Product brochures'
              || pageName === 'Other resources'"
      class="color-white intro-above-content">
    </app-page-content>
    <div class="color-white intro-above-content" *ngIf="pageName === 'Infographics'">
      Here you'll find a range of ADEY® homeowner infographics to help you support your customers.
      <br><br>
    </div>


    <div class="content-container col-xs-12 no-pad" *ngIf="resources$ | async; let resources">


      <div class="background-white search-container">

        <form class="form-inline" [formGroup]="fgSearch" novalidate (ngSubmit)="onSearchSubmit($event)">

          <h2>Search</h2>

          <div class="form-group">
            <label>Category</label>
            <select formControlName="category" class="form-control" placeholder="Category">
              <option [value]="null">-- Any --</option>
              <option *ngFor="let c of categories" [value]="c">{{c}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Description</label>
            <select class="form-control" formControlName="descriptionOperator">
              <option [value]="null">-- Any --</option>
              <option value="AND">AND</option>
              <option value="OR">OR</option>
            </select>
          </div>

          <div class="form-group">
            <label for="description">&nbsp;</label>
            <input type="text" class="form-control" formControlName="description" placeholder="Title and description" />
          </div>

          <div class="form-group">
            <label>Keywords</label>
            <select class="form-control" formControlName="keywordsOperator">
              <option [value]="null">-- Any --</option>
              <option value="AND">AND</option>
              <option value="OR">OR</option>
            </select>
          </div>

          <div class="form-group">
            <label for="keywords">&nbsp;</label>
            <input type="text" class="form-control" formControlName="keywords" placeholder="Tags or keywords" />
          </div>



          <!--<div class="form-group">
            <mat-select formControlName="docTypeOperator">
              <mat-option value="AND">AND</mat-option>
              <mat-option value="OR">OR</mat-option>
            </mat-select>
            <mat-select formControlName="itemType" placeholder="Resource type">
              <mat-option [value]="null">-- Any --</mat-option>
              <mat-option value="DOWLOAD">Downloads</mat-option>
              <mat-option value="VIDEO">Videos</mat-option>
            </mat-select>
          </div>-->



          <div class="form-group">
            <input class="btn background-green color-white no-radius full-width" type="submit" value="Search" />
          </div>

        </form>
      </div>

      <app-message-panel kind="ERROR" *ngIf="resources.error">{{resources.error}}</app-message-panel>

      <app-waiting-spinner kind="bar" *ngIf="resources.pending" class="color-white">Loading resources...</app-waiting-spinner>

      <app-message-panel
        kind="INFO"
        *ngIf="!resources.pending && (!resources.items || resources.items.length === 0)"
      >
        No resources to display
      </app-message-panel>

      <div class="row" *ngIf="!resources.pending && resources.items && resources.items.length > 0">
        <app-content-item
          *ngFor="let item of resources.items"
          [resourceItem]="item"
          [widget]="false"
          class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
        >
        </app-content-item>
      </div>

    </div>
  </div>
</div>
