import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchCustomerSuggestionResponse } from '../../models/customer-suggestion';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/customer-suggestion';

@Injectable()
export class CustomerSuggestionEffects {
    constructor(
        private service: AdminService,
        private actions$: Actions
    ) { }

    /**
     * Fetch the customer suggestions from the API.
     *
     * @type {Observable<any>}
     */
     fetchCustomerSuggestions$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.FETCH_CUSTOMER_SUGGESTION_REQUEST),
        switchMap((req: ActionTypes.FetchCustomerSuggestionRequestAction): Observable<ActionTypes.FetchCustomerSuggestionResponseAction> =>
            this.service.fetchCustomerSuggestions(req.payload).pipe(
                map((res: FetchCustomerSuggestionResponse): ActionTypes.FetchCustomerSuggestionResponseAction =>
                    new ActionTypes.FetchCustomerSuggestionResponseAction(res)
                ))
        )));
}
