<button
  type="button"
  [class]="'btn ' + (btnClass ? btnClass : '')"
  *ngIf="downloadBlob"
  (click)="downloadFile()"
>
  {{(btnText ? btnText : 'Download')}}
</button>
<a
  [class]="'btn ' + (btnClass ? btnClass : '')"
  *ngIf="!downloadBlob"
  [href]="fileURL"
  target="_blank"
>
  {{(btnText ? btnText : 'Download')}}
</a>
