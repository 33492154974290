import { CurrentUser, Permission } from './login';
import { ProCheckSite } from './procheck-site';
import { Injectable } from "@angular/core";

export interface NavItem {

  title: string;
  icon: string;
  link: string;
  href?: string;
  customClasses: string;
  textClass: string;
  permission?: Partial<Permission>;
  userRoles: number[];
  position: number;
}

/**
 * Navigation bar items (normal users)
 */
@Injectable()
export class NavItems {
  items: NavItem[] = [
    {
      title: 'Home',
      icon: 'icon-home',
      link: '/home',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2, 6, 7],
      position: 10
    },
    {
      title: 'Rewards',
      icon: 'icon-rewards',
      link: '/rewards',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 30
    },
    {
      title: 'Job records',
      icon: 'icon-list',
      link: '/service-dashboard',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 40
    },
    {
      title: 'MagnaClean Professional3',
      icon: 'icon-fitted',
      link: '/pro3-list',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 50
    },
    {
      title: 'Diary',
      icon: 'icon-diary',
      link: '/diary',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 70
    },
    {
      title: 'Training',
      icon: 'icon-training',
      link: '/training',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 80
    },
    {
      title: 'Parts & Spares',
      icon: 'icon-parts',
      link: null,
      href: 'http://www.adeyspares.co.uk/',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 90
    },
    {
      title: 'Watertest',
      icon: 'icon-water-test',
      link: '/procheck-redirect',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: { permission_entity: 'ProCheck.Result' },
      userRoles: [1, 4],
      position: 95
    },
    {
      title: 'Resources',
      icon: 'fa fa-file-text-o',
      link: '/content-archive',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 100
    },
    {
      title: 'Contact us',
      icon: 'icon-marketing',
      link: '/contact-us',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [2],
      position: 210
    },
    {
      title: 'Register a Job / Warranty',
      icon: null,
      link: '/submission-selection',
      customClasses: 'border-light-gray warranty-submit background-green',
      textClass: 'color-white',
      permission: null,
      userRoles: [2],
      position: 300
    },
    {
      title: 'Home',
      icon: 'icon-home',
      link: '/admin-dashboard',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 10
    },
    {
      title: 'Users',
      icon: 'icon-user',
      link: '/admin-users',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 20
    },
    {
      title: 'Jobs/Warranties',
      icon: 'icon-warranty-submit',
      link: '/admin-jobs',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 30
    },
    {
      title: 'Sense Admin',
      icon: 'icon-fitted',
      link: '/admin-pro3',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: { permission_entity: 'Pro3.Device', action: 'Read', scope: 'All' },
      userRoles: [],
      position: 60
    },
    {
      title: 'Content',
      icon: 'icon-list',
      link: '/admin-content',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 85
    },
    {
      title: 'Rewards',
      icon: 'icon-rewards',
      link: '/admin-rewards',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 95
    },
    {
      title: 'Events',
      icon: 'icon-diary',
      link: '/admin-events',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: null,
      userRoles: [1],
      position: 105
    },
    {
      title: 'Profile',
      icon: 'icon-user',
      link: '/profile',
      customClasses: 'border-light-gray',
      textClass: 'color-gray',
      permission: { permission_entity: 'ProClub.User', scope: 'Own' },
      userRoles: [2],
      position: 200
    },
  ];

  private static checkPermissionsForItem(permission: Permission, toCheck: Partial<Permission>) {
    //...is the permission relevant to the item...
    if (permission.permission_entity !== toCheck.permission_entity) {
      return false;
    }

    //...is the action matching the requirement...
    if (toCheck.action && permission.action !== toCheck.action) {
      return false;
    }

    //...is it allowed in this scope?
    if (toCheck.scope) {
      //...Allowed at all scopes?
      if (permission.scope === 'All') {
        return true;
      }

      //...or does scope match?
      return (permission.scope === toCheck.scope);
    }

    // Otherwise the permission is a match
    return true;
  }

  /**
   * Get an ordered list of nav items with a filter callback applied
   *
   * @param filterFn
   */
  getItems(filterFn?: (item: NavItem) => any) {
    return this.items.filter(filterFn).sort((a: NavItem, b: NavItem) => {
      return a.position - b.position;
    });
  }

  /**
   * Get an ordered list of nav items for a given user
   *
   * @param user
   */
  getItemsForUser(user: CurrentUser) {
    const proCheck = ProCheckSite.getCheck();
    //Get the appropriate nav items based on the user role

    //First grab the user's default role (from old functionality)...
    const role = user.role_id;

    //Get a filtered and sorted list of nav items
    return this.getItems((item) => {
      //ignore profile item when we are on proCheck
      if (proCheck && item.title === 'Profile') {
        return false;
      }
      //...first keep existing functionality by basing on just role...
      if (item.userRoles.includes(role)) {
        return true;
      }

      //Is a permission defined on the item?
      if (!item.permission) {
        return false;
      }

      //...if they have no permissions for anything, then they can't have this one...
      if (!user.permissions) {
        return false;
      }

      //..then, see if they have permission to access the resource (for any company at a menu level):
      const hasPermission = (user.permissions.some((permission) => {
        return NavItems.checkPermissionsForItem(permission, item.permission);
      }));

      return hasPermission;
    });
  }


  /**
     * Get the default (first in the list) item for a given user
     *
     * @param user
     */
  getDefaultItemForUser(user: CurrentUser) {
    let defaultItem: NavItem = null;

    //Get the list of items for the user
    const userItems = this.getItemsForUser(user);

    //Check to see if we are on procheck:
    if (ProCheckSite.getCheck()) {
      defaultItem = userItems.find((i) => (i.link === ProCheckSite.DEFAULT_ROUTE));
    }

    //If defaultItem is defined, return that, otherwise return the first available from the user's items
    return defaultItem || userItems.shift();
  }

  /**
   * Resolve a user's default route
   *
   * @param user
   */
  getDefaultRouteForUser(user: CurrentUser) {
    return this.getDefaultItemForUser(user).link;
  }
}
