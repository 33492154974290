import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchResourceItemsResponse } from '../../models/resources';
import { ResourceService } from '../../services/resource.service';
import * as ActionTypes from '../actions/resources';

@Injectable()
export class ResourceEffects {
  constructor(
    private service: ResourceService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchResourceItemsRequestAction, call
   * ResourceService::fetchResources() and dispatch a new
   * FetchResourceItemsResponseAction with the response.
   */
   fetchResources$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_RESOURCE_ITEMS_REQUEST),
    switchMap((req: ActionTypes.FetchResourceItemsRequestAction): Observable<ActionTypes.FetchResourceItemsResponseAction> =>
      this.service.fetchResources(req.payload).pipe(
        map((res: FetchResourceItemsResponse): ActionTypes.FetchResourceItemsResponseAction =>
          new ActionTypes.FetchResourceItemsResponseAction(res)
        ))
    )));
}
