<form [formGroup]="fg" novalidate (ngSubmit)="onSubmitForm()">

  <ng-content select=".form-header"></ng-content>

  <app-message-panel kind="WARNING" *ngIf="errors">{{errors}}</app-message-panel>

  <div class="product-section col-xs-12 col-sm-6">

    <div class="col-xs-12">
      <h3 class="color-gray">Product details</h3>
    </div>

    <div [class]="formGroupClass('userId', 'col-xs-12 col-sm-10 col-md-6 clear-both')" *ngIf="adminMode">
      <label class="control-label">User ID <span class="color-adey-red">*</span></label>
      <input type="text" class="form-control" formControlName="userId" />
    </div>

    <div [class]="formGroupClass('productType', 'col-xs-12 col-sm-10 col-md-6 clear-both')">
      <label class="control-label">Product type <span class="color-adey-red">*</span></label>
      <ng-content select=".product-list"></ng-content>

      <select class="form-control" formControlName="productType" (change)="handleProductChange()"
        *ngIf="productTypes && productTypes.products">
        <option value="">-- Please select --</option>
        <option *ngFor="let pt of productTypes.products" [value]="pt.id"
          [selected]="(warranty)? pt.id === warranty.productId : false">{{pt.name}}</option>
      </select>
    </div>

    <div *ngIf="!warranty && fg.value.productType === '40'" class="col-xs-12 color-adey-red">
      <p>Pro3 warranty is automatically activated once the Pro3 is connected. You can register the warranty here, but
        in order to connect the filter and earn points for the Pro3 install, please follow the connection process.</p>
    </div>

    <div *ngIf="!warranty && (fg.value.productType === '56' || fg.value.productType === '57')" class="col-xs-12 color-adey-red">
      <p>Micro3 warranty is automatically activated once the Micro3 is connected. You can register the warranty here, but
        in order to connect the filter and earn points for the Micro3 install, please follow the connection process.</p>
    </div>

    <div *ngIf="showTypeOfFilterQuestion">
      <div [class]="formGroupClass('typeOfBoiler', 'col-xs-12 col-sm-10 col-md-6 clear-both')">
        <label class="control-label">Nature of install <span class="color-adey-red">*</span></label>
        <br>
        <mat-radio-group formControlName="typeOfBoiler" (change)="checkShouldShowFilterQuestion()">
          <mat-radio-button value="New">New boiler</mat-radio-button>
          <mat-radio-button value="Existing">Existing boiler</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="fg.value.typeOfBoiler === 'Existing'"
        [class]="formGroupClass('typeOfFilter', 'col-xs-12 col-sm-10 col-md-6 clear-both')">
        <label class="control-label">Are you replacing an existing <strong>filter</strong>? <span
            class="color-adey-red">*</span></label>
        <mat-radio-group formControlName="typeOfFilter">
          <mat-radio-button value="Replacement">Yes</mat-radio-button>
          <mat-radio-button value="New">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div [class]="formGroupClass('serialNumber', 'col-xs-12 col-sm-10 col-md-6 clear-both')">
      <label class="control-label">Serial number <span class="color-adey-red">*</span></label>
      <input type="text" class="form-control async-validation" formControlName="serialNumber"
        [readonly]="warranty?.productId === 40" />
      <span class="async-validation-status">
        <app-waiting-spinner kind="spinner">Checking serial number...</app-waiting-spinner>
      </span>
      <p class="text-danger" *ngIf="!serialNumberValid() && serialNumberError()">
        {{serialNumberError()}}
      </p>
    </div>

    <div
      [class]="formGroupClass('dateOfInstallation', 'col-xs-12 col-sm-10 col-md-6 clear-both button-right date-input')">
      <mat-form-field>
        <input matInput formControlName="dateOfInstallation" [min]="minDate" [max]="maxDate"
          [matDatepicker]="dpInstallationDate" placeholder="Date of installation" />
        <mat-datepicker #dpInstallationDate></mat-datepicker>
      </mat-form-field>
      <mat-datepicker-toggle [for]="dpInstallationDate" *ngIf="!readOnly"></mat-datepicker-toggle>
    </div>

    <!-- TODO: Removed temporarily, restore when required
    <div [class]="formGroupClass('expiryDate', 'col-xs-12 col-sm-10 col-md-6 clear-both button-right')">
      <mat-form-field>
        <input matInput formControlName="expiryDate" [matDatepicker]="dpExpiryDate" placeholder="Expiry date" />
        <mat-datepicker #dpExpiryDate></mat-datepicker>
      </mat-form-field>
      <mat-datepicker-toggle [for]="dpExpiryDate" *ngIf="!readOnly"></mat-datepicker-toggle>
    </div>
    -->

    <div *ngIf="!register" [class]="formGroupClass('oldFilterBrand', 'col-xs-12')">
      <label class="control-label">Old filter brand (if applicable)</label>
      <input type="text" class="form-control" formControlName="oldFilterBrand" />
    </div>

    <div *ngIf="!register" [class]="formGroupClass('warrantyLength', 'col-xs-12')">
      <label class="control-label">Boiler warranty expiry date (year)</label>
      <select class="form-control" formControlName="warrantyLength">
        <option value="0" disabled selected>Please select a year</option>
        <option [value]="year" *ngFor="let year of expiryYears">{{year}}</option>
      </select>
    </div>

  </div>

  <div class="customer-section col-xs-12 col-sm-6">
    <div class="col-xs-12">
      <h3 class="color-gray">Customer details</h3>
      <p class="footnote uppercase color-adey-red medium" *ngIf="!warranty">Your customer will not receive any
        confirmation of the warranty if a customer e-mail address is not supplied</p>
    </div>
    <div class="customer-details" formGroupName="customer">
      <div [class]="formGroupCustomerClass('customerTitle', 'col-xs-12 col-sm-10 col-md-6')">
        <label class="control-label">Customer title <span class="color-adey-red">*</span></label>
        <select class="form-control" formControlName="customerTitle" placeholder="Mr">
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
        </select>
      </div>

      <!-- <div *ngIf="!register" [class]="formGroupCustomerClass('customerForename', 'col-xs-12')">
        <label class="control-label">Customer forename <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="customerForename" />
      </div> -->

      <div [class]="formGroupCustomerClass('customerSurname', 'col-xs-12')">
        <label class="control-label">Customer surname <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="customerSurname" />
      </div>

      <div [class]="formGroupCustomerClass('customerPostcode', 'col-xs-12')">
        <label class="control-label">Postcode <span class="color-adey-red">*</span></label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="customerPostcode" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" [disabled]="!enablePostcodeLookup" (click)="postcodeLookup()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
          </span>
        </div>
      </div>

      <div [class]="formGroupCustomerClass('customerAddress1', 'col-xs-12')">
        <label class="control-label">Address line 1 <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="customerAddress1" />
      </div>

      <div [class]="formGroupCustomerClass('customerAddress2', 'col-xs-12')">
        <label class="control-label">Address line 2</label>
        <input type="text" class="form-control" formControlName="customerAddress2" />
      </div>

      <div [class]="formGroupCustomerClass('customerTown', 'col-xs-12')">
        <label class="control-label">Town</label>
        <input type="text" class="form-control" formControlName="customerTown" />
      </div>

      <div [class]="formGroupCustomerClass('customerCounty', 'col-xs-12')">
        <label class="control-label">County</label>
        <input type="text" class="form-control" formControlName="customerCounty" />
      </div>

      <div formGroupName="emails">
        <div [class]="formGroupCustomerClass('emails', 'col-xs-12')">
          <label class="control-label">Customer email <span class="color-adey-red">*</span></label>
          <input type="email" class="form-control" formControlName="customerEmail" />
        </div>
        <div [class]="formGroupCustomerClass('emails', 'col-xs-12')">
          <label class="control-label">Confirm customer email <span class="color-adey-red">*</span></label>
          <input type="email" class="form-control" formControlName="customerConfirmEmail" />
        </div>
      </div>

      <div *ngIf="!register" [class]="formGroupCustomerClass('customerPhone', 'col-xs-12')">
        <label class="control-label">Phone</label>
        <input type="email" class="form-control" formControlName="customerPhone" />
      </div>

      <!-- <div class="form-group col-xs-12 full-width-label standard-input">
        <label class="control-label"><strong>How would the customer like to keep updated?</strong></label>
      </div>
      <div class="form-group wrap col-xs-12 full-width-label standard-input">
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_email" formControlName="customerContactByEmail">
            Email
          </mat-checkbox>
        </div>
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_phone" formControlName="customerContactByPhone">
            Phone/SMS
          </mat-checkbox>
        </div>
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_post" formControlName="customerContactByPost">
            Post
          </mat-checkbox>
        </div>
      </div> -->
    </div>
    <div class="col-xs-12 renewal-checkbox">
      <mat-checkbox md-no-ink class="md-primary" formControlName="addRenewalDateToCalendar">
        Add renewal date to my calendar
      </mat-checkbox>

      <!--<mat-checkbox md-no-ink class="md-primary" formControlName="notifyClientBeforeExpiry">
        Notify client 1 month before this warranty expires
      </mat-checkbox>-->
    </div>
    <div class="promo-container col-xs-12">
      <div class="col-xs-12 no-pad promo-box background-beige">
        <div [class]="formGroupClass('promoCode', 'col-sm-6 col-xs-12 no-margin')" *ngIf="adminMode || !warranty">
          <input type="text" class="form-control no-margin" formControlName="promoCode" placeholder="Promo code" />
          <app-waiting-spinner kind="spinner" *ngIf="promoCode && promoCode.pending">
            Checking your promotional code...
          </app-waiting-spinner>
        </div>
        <div [class]="formGroupClass('promoCode', 'col-sm-6 col-xs-12 no-margin')"
          *ngIf="!adminMode && warranty && fg.value.promoCode">
          <span><strong>Promo code:</strong> {{fg.value.promoCode}}</span>
          <input type="text" class="form-control no-margin" formControlName="promoCode" placeholder="Promo code" />
        </div>
        <div class="btn-group col-sm-6 col-xs-12" *ngIf="!readOnly && showSubmit">
          <button class="btn background-green color-white full-width">{{submitLabel}}</button>
        </div>
        <div class="col-xs-12" *ngIf="adminMode || !warranty">
          <app-message-panel kind="ERROR" *ngIf="promoCode && promoCode.error">
            {{promoCode.error}}
          </app-message-panel>
          <app-message-panel kind="SUCCESS" *ngIf="promoCode && !promoCode.error && promoCode.code && promoCode.valid">
            Promotion applied: {{promoCode.description}}
          </app-message-panel>
          <app-message-panel kind="WARNING"
            *ngIf="promoCode && !promoCode.error && promoCode.code && !promoCode.pending && !promoCode.valid">
            Invalid promotional code
          </app-message-panel>
        </div>
      </div>
    </div>
  </div>

  <ng-content select=".form-footer"></ng-content>

</form>