import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ProductCheckSerialResponse } from '../../models/product';
import { ProductSelectQueryResponse } from '../../models/product-selector';
import { ProductSelectorService } from '../../services/product-selector.service';
import * as ActionTypes from '../actions/products';

@Injectable()
export class ProductsEffects {
  constructor(
    private service: ProductSelectorService,
    private actions$: Actions
  ) { }

  /**
   * For a ProductSelectQueryRequestAction, call
   * ProductSelectorService::query() and dispatch a new
   * ProductSelectQueryResponseAction with the response.
   */
   query$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PRODUCT_SELECT_QUERY_REQUEST),
    switchMap((req: ActionTypes.ProductSelectQueryRequestAction): Observable<ActionTypes.ProductSelectQueryResponseAction> =>
      this.service.query(req.payload).pipe(
        map((res: ProductSelectQueryResponse): ActionTypes.ProductSelectQueryResponseAction =>
          new ActionTypes.ProductSelectQueryResponseAction(res)
        ))
    )));

  /**
   * For a ProductCheckSerialRequestAction, call
   * ProductSelectorService::checkSerial() and dispatch a new
   * ProductCheckSerialResponseAction with the response.
   */
   checkSerial$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PRODUCT_CHECK_SERIAL_REQUEST),
    switchMap((req: ActionTypes.ProductCheckSerialRequestAction): Observable<ActionTypes.ProductCheckSerialResponseAction> =>
      this.service.checkSerial(req.payload).pipe(
        map((res: ProductCheckSerialResponse): ActionTypes.ProductCheckSerialResponseAction =>
          new ActionTypes.ProductCheckSerialResponseAction(res)
        ))
    )));
}
