/**
 * Provides AdminPro3Effects
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
    AdminDevice,
    AdminDeviceAlert,
    AdminDeviceDetailItem,
    AdminDeviceNote,
    AdminDeviceStatus,
    AdminDismissAlertResultItem,
} from '../../models/admin-pro3';
import { DFResponse } from '../../models/df-proclub';
import { AdminInstallerService } from '../../services/admin-installer.service';
import { AdminPro3Service } from '../../services/admin-pro3.service';
import * as ActionTypes from '../actions/admin-pro3';

/**
 * NGRX Actions for the Pro3 Admin Portal
 */
@Injectable()
export class AdminPro3Effects {
    /**
     * Set up required injected thingies
     *
     * @param adminPro3Service
     * @param actions$
     */
    constructor(
        private adminPro3Service: AdminPro3Service,
        private actions$: Actions,
        private adminInstallerService: AdminInstallerService
    ) {
    }

     adminDeviceAlertRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_ALERT_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceAlertRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceAlerts(req.payload).pipe(
                map((res: DFResponse<AdminDeviceAlert>): ActionTypes.AdminDeviceAlertResponseAction =>
                    new ActionTypes.AdminDeviceAlertResponseAction(res)
                ));
        })));

     adminDeviceAlertExportRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_ALERT_EXPORT_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceAlertExportRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceAlerts(req.payload, true).pipe(
                map((res: DFResponse<AdminDeviceAlert>): ActionTypes.AdminDeviceAlertExportResponseAction =>
                    new ActionTypes.AdminDeviceAlertExportResponseAction(res)
                ));
        })));

     adminDeviceListRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceList(req.payload).pipe(
                map((res: DFResponse<AdminDevice>): ActionTypes.AdminDeviceResponseAction =>
                    new ActionTypes.AdminDeviceResponseAction(res)
                ));
        })));

     adminDeviceListExportRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_EXPORT_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceExportRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceList(req.payload, true).pipe(
                map((res: DFResponse<AdminDevice>): ActionTypes.AdminDeviceExportResponseAction =>
                    new ActionTypes.AdminDeviceExportResponseAction(res)
                ));
        })));

     adminDeviceStatusRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_STATUS_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceStatusRequestAction): Observable<Action> => {
            return this.adminPro3Service.getPro3Status(req.payload).pipe(
                map((res: DFResponse<AdminDeviceStatus>): ActionTypes.AdminDeviceStatusResponseAction =>
                    new ActionTypes.AdminDeviceStatusResponseAction(res)
                ));
        })));

     adminDeviceDetailRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_DETAIL_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceDetailRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceDetail(req.payload).pipe(
                map((res: DFResponse<AdminDeviceDetailItem>): ActionTypes.AdminDeviceDetailResponseAction =>
                    new ActionTypes.AdminDeviceDetailResponseAction(res)
                ));
        })));

     adminDeviceNoteRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DEVICE_NOTE_REQUEST),
        switchMap((req: ActionTypes.AdminDeviceNoteRequestAction): Observable<Action> => {
            return this.adminPro3Service.getDeviceNote(req.payload).pipe(
                map((res: DFResponse<AdminDeviceNote>): ActionTypes.AdminDeviceNoteResponseAction =>
                    new ActionTypes.AdminDeviceNoteResponseAction(res)
                ));
        })));

     adminGetExistingWarranty: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_GET_MAGSENSE_WARRANTY_INFO),
        switchMap((req: ActionTypes.AdminGetMagsenseWarrantyInfoAction) => {
            return this.adminPro3Service.getWarrantyInfo(req.payload)
                .pipe(
                    map((result) => new ActionTypes.AdminGetMagsenseWarrantyInfoActionSuccess(result)),
                    catchError(err => of(new ActionTypes.AdminGetMagsenseWarrantyInfoActionFailed(err)))
                );
        }
        )));

     adminGetInstallerForWarranty: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST),
        switchMap((req: ActionTypes.AdminGetInstallerFromPartialRequest): Observable<Action> => {
            return this.adminInstallerService.findUserByPartial(req.payload)
                .pipe(
                    map((result: any) =>
                        new ActionTypes.AdminGetInstallerFromPartialResponse(result.data.pop()))
                );
        })));

     adminDismissAlertRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_DISMISS_ALERT_REQUEST),
        switchMap((req: ActionTypes.AdminDismissAlertRequestAction): Observable<Action> => {
            return this.adminPro3Service.dismissAlert(req.payload).pipe(
                map((res: DFResponse<AdminDismissAlertResultItem>): ActionTypes.AdminDismissAlertResponseAction =>
                    new ActionTypes.AdminDismissAlertResponseAction(res)
                ));
        })));

     adminRegisterWarrantyRequest: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ADMIN_REGISTER_MAGSENSE_DEVICE_REQUEST),
        switchMap((req: ActionTypes.AdminRegisterMagsenseDeviceRequestAction): Observable<Action> => {
            return this.adminPro3Service.registerWarranty(req.payload).pipe(
                map((res?: any): ActionTypes.AdminRegisterMagsenseDeviceResponseAction =>
                    new ActionTypes.AdminRegisterMagsenseDeviceResponseAction()
                ),
                catchError((err) => of(new ActionTypes.AdminRegisterMagsenseDeviceFailureAction(err)))
            );
        })));
}
