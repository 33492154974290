<div class="container-fluid contact-page">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Contact us</h1>
    </div>

    <app-page-content page="CONTACT_US" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="contact-container background-white col-xs-12" >

      <div class="contact-content" *ngIf="loginDetails$ | async; let loginDetails">

        <app-waiting-spinner kind="bar" *ngIf="(emailDetails$ | async).pending">Sending email, please wait...</app-waiting-spinner>
        <app-message-panel kind="ERROR" *ngIf="(emailDetails$ | async).error">
          {{ (emailDetails$ | async).error }}
        </app-message-panel>

        <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending">Loading form, please wait...</app-waiting-spinner>
        <div class="contact-form-container align-center" *ngIf="!loginDetails.pending">
          <h1 class="color-adey-red medium">Got a question?</h1>
          <h2 class="color-dark-gray">
            You can call our Customer Services team on 01242 546717 or email them on
            <a href="mailto:proclub@adey.com">proclub@adey.com</a>.
            Alternatively, just fill in the form below with your contact
            details and message and someone will get back to you.
          </h2>

          <div class="row" *ngIf="(emailDetails$ | async).message">
            <div class="col-xs-6 col col-xs-offset-3">
              <app-message-panel kind="SUCCESS">
                {{ (emailDetails$ | async).message }}
                <hr />
                <button class="btn background-adey-red color-white" (click)="goHome()">Go home</button>
              </app-message-panel>
            </div>
          </div>

          <form [formGroup]="fg" class="contact-form align-left" novalidate (ngSubmit)="submitContactForm()" *ngIf="!(emailDetails$ | async).message">
            <div [class]="formGroupClass('name')">
              <label class="control-label">Name <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="name"/>
            </div>
            <div [class]="formGroupClass('email')">
              <label class="control-label">Email <span class="color-adey-red">*</span></label>
              <input type="email" class="form-control" formControlName="email"/>
            </div>
            <div [class]="formGroupClass('message')">
              <label class="control-label">Reason for request <span class="color-adey-red">*</span></label>
              <textarea  class="form-control" formControlName="message" placeholder="Please tell us the reason for your request"></textarea>
            </div>

            <input class="btn full-width background-adey-red color-white" type="submit" value="Submit" />

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
