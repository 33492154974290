/**
 * admin-rewards actions: defines all actions relating to the reward (user
 * points) management admin CMS.
 */

import { Action } from '@ngrx/store';

// Import models
import {
  AddAdminPromotionRequest,
  AddAdminPromotionResponse,
  DeleteAdminPromotionRequest,
  DeleteAdminPromotionResponse,
  FetchAdminPromotionsResponse,
  FetchUserPointsHistoryRequest,
  FetchUserPointsHistoryResponse,
  TransferAdminUserPointsRequest,
  TransferAdminUserPointsResponse,
  UpdateAdminUserPointsRequest,
  UpdateAdminUserPointsResponse,
} from '../../models/admin-rewards';

// Action type definitions
export const ADD_ADMIN_PROMOTION_REQUEST = 'ADD_ADMIN_PROMOTION_REQUEST';
export const ADD_ADMIN_PROMOTION_RESPONSE = 'ADD_ADMIN_PROMOTION_RESPONSE';
export const DELETE_ADMIN_PROMOTION_REQUEST = 'DELETE_ADMIN_PROMOTION_REQUEST';
export const DELETE_ADMIN_PROMOTION_RESPONSE = 'DELETE_ADMIN_PROMOTION_RESPONSE';
export const FETCH_ADMIN_PROMOTIONS_REQUEST = 'FETCH_ADMIN_PROMOTIONS_REQUEST';
export const FETCH_ADMIN_PROMOTIONS_RESPONSE = 'FETCH_ADMIN_PROMOTIONS_RESPONSE';
export const FETCH_USER_POINTS_HISTORY_REQUEST = 'FETCH_USER_POINTS_HISTORY_REQUEST';
export const FETCH_USER_POINTS_HISTORY_RESPONSE = 'FETCH_USER_POINTS_HISTORY_RESPONSE';
export const TRANSFER_ADMIN_USER_POINTS_REQUEST = 'TRANSFER_ADMIN_USER_POINTS_REQUEST';
export const TRANSFER_ADMIN_USER_POINTS_RESPONSE = 'TRANSFER_ADMIN_USER_POINTS_RESPONSE';
export const UPDATE_ADMIN_USER_POINTS_REQUEST = 'UPDATE_ADMIN_USER_POINTS_REQUEST';
export const UPDATE_ADMIN_USER_POINTS_RESPONSE = 'UPDATE_ADMIN_USER_POINTS_RESPONSE';

// Action definitions
export class AddAdminPromotionRequestAction implements Action {
  readonly type = ADD_ADMIN_PROMOTION_REQUEST;
  constructor(public payload: AddAdminPromotionRequest) { }
}

export class AddAdminPromotionResponseAction implements Action {
  readonly type = ADD_ADMIN_PROMOTION_RESPONSE;
  constructor(public payload: AddAdminPromotionResponse) { }
}
export class DeleteAdminPromotionRequestAction implements Action {
  readonly type = DELETE_ADMIN_PROMOTION_REQUEST;
  constructor(public payload: DeleteAdminPromotionRequest) { }
}

export class DeleteAdminPromotionResponseAction implements Action {
  readonly type = DELETE_ADMIN_PROMOTION_RESPONSE;
  constructor(public payload: DeleteAdminPromotionResponse) { }
}

export class FetchAdminPromotionsRequestAction implements Action {
  readonly type = FETCH_ADMIN_PROMOTIONS_REQUEST;
  constructor() { }
}

export class FetchAdminPromotionsResponseAction implements Action {
  readonly type = FETCH_ADMIN_PROMOTIONS_RESPONSE;
  constructor(public payload: FetchAdminPromotionsResponse) { }
}

export class FetchUserPointsHistoryRequestAction implements Action {
  readonly type = FETCH_USER_POINTS_HISTORY_REQUEST;
  constructor(public payload: FetchUserPointsHistoryRequest) { }
}

export class FetchUserPointsHistoryResponseAction implements Action {
  readonly type = FETCH_USER_POINTS_HISTORY_RESPONSE;
  constructor(public payload: FetchUserPointsHistoryResponse) { }
}

export class TransferAdminUserPointsRequestAction implements Action {
  readonly type = TRANSFER_ADMIN_USER_POINTS_REQUEST;
  constructor(public payload: TransferAdminUserPointsRequest) { }
}

export class TransferAdminUserPointsResponseAction implements Action {
  readonly type = TRANSFER_ADMIN_USER_POINTS_RESPONSE;
  constructor(public payload: TransferAdminUserPointsResponse) { }
}

export class UpdateAdminUserPointsRequestAction implements Action {
  readonly type = UPDATE_ADMIN_USER_POINTS_REQUEST;
  constructor(public payload: UpdateAdminUserPointsRequest) { }
}

export class UpdateAdminUserPointsResponseAction implements Action {
  readonly type = UPDATE_ADMIN_USER_POINTS_RESPONSE;
  constructor(public payload: UpdateAdminUserPointsResponse) { }
}

// Export Actions type as a union of all defined actions
export type Actions =
  | AddAdminPromotionRequestAction
  | AddAdminPromotionResponseAction
  | DeleteAdminPromotionRequestAction
  | DeleteAdminPromotionResponseAction
  | FetchAdminPromotionsRequestAction
  | FetchAdminPromotionsResponseAction
  | FetchUserPointsHistoryRequestAction
  | FetchUserPointsHistoryResponseAction
  | TransferAdminUserPointsRequestAction
  | TransferAdminUserPointsResponseAction
  | UpdateAdminUserPointsRequestAction
  | UpdateAdminUserPointsResponseAction;
