import { Action } from '@ngrx/store';
import { OnboardingQuestion, OnboardingQuestionErrorResponse } from '../../models/onboarding-questions';

export const GET_ONBOARDING_QUESTIONS = 'GET_ONBOARDING_QUESTIONS';
export const GET_ONBOARDING_QUESTIONS_SUCCESS = 'GET_ONBOARDING_QUESTIONS_SUCCESS';
export const GET_ONBOARDING_QUESTIONS_FAILURE = 'GET_ONBOARDING_QUESTIONS_FAILURE';
export const PUT_ONBOARDING_ANSWERS = 'PUT_ONBOARDING_ANSWERS';
export const PUT_ONBOARDING_ANSWERS_FAILURE = 'PUT_ONBOARDING_ANSWERS_FAILURE';
export const PUT_ONBOARDING_ANSWERS_SUCCESS = 'PUT_ONBOARDING_ANSWERS_SUCCESS';

export class GetOnboardingQuestions implements Action {
  readonly type = GET_ONBOARDING_QUESTIONS;
}

export class GetOnboardingQuestionsSuccess implements Action {
  readonly type = GET_ONBOARDING_QUESTIONS_SUCCESS;
  constructor(public payload: OnboardingQuestion[]) { }
}

export class GetOnboardingQuestionsFailure implements Action {
  readonly type = GET_ONBOARDING_QUESTIONS_FAILURE;
  constructor(public error: OnboardingQuestionErrorResponse) { }
}

export class PutOnboardingAnswers implements Action {
  readonly type = PUT_ONBOARDING_ANSWERS;
  constructor(public payload: Record<string, string>) { }
}

export class PutOnboardingAnswersFailure implements Action {
  readonly type = PUT_ONBOARDING_ANSWERS_FAILURE;
  constructor(public error: OnboardingQuestionErrorResponse) { }
}
export class PutOnboardingAnswersSuccess implements Action {
  readonly type = PUT_ONBOARDING_ANSWERS_SUCCESS;
  constructor(public payload: any) { }
}

export type Actions =
  | GetOnboardingQuestions
  | GetOnboardingQuestionsSuccess
  | GetOnboardingQuestionsFailure
  | PutOnboardingAnswers
  | PutOnboardingAnswersFailure
  | PutOnboardingAnswersSuccess;
