import {
  Component,
  OnInit,
} from '@angular/core';

import { Router }       from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store }        from '@ngrx/store';
import { Observable ,  Subscription }   from 'rxjs';

import * as moment from 'moment';


import { StoreState } from '../../state-management/store';

import { State as DiaryState }          from '../../state-management/reducers/diary';
import { State as LoginDetailsState }   from '../../state-management/reducers/login-details';
import { State as ServiceRecordsState } from '../../state-management/reducers/service-record';

import { ProfileEditModalComponent } from './profile-edit-modal/profile-edit-modal.component';

import {
  FetchUserProfileRequestAction,
  UpdateUserProfileRequestAction,
  FetchAccreditationsRequestAction,
} from '../../state-management/actions/login-details';

import {
  FetchEventsRequestAction,
  FetchEventTypesRequestAction,
} from '../../state-management/actions/diary';

import {
  FetchUserRolesRequestAction,
} from '../../state-management/actions/registration';

import {
  FetchServiceRecordsRequestAction,
  UpdateServiceRecordRequestAction
} from '../../state-management/actions/service-record';

import { UserRole } from '../../models/registration';

import {
  AccreditationItem,
  UserProfile,
} from '../../models/user-profile';


/**
 * Summary
 *    Page used for displaying a user's profile details with links to edit
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  // Store states and Subscriptions
  public loginDetails$:   Observable<LoginDetailsState>;
  public diary$:          Observable<DiaryState>;
  public serviceRecords$: Observable<ServiceRecordsState>;

  // Array of accreditations (icons)
  public accreditationSet: any[];

  // Array of quick-links for buttons
  public quickLinks: any[];

  // Array of UserRole obtained from loginDetails Subscription
  public roles: UserRole[] = [];

  constructor(
    private store:  Store<StoreState>,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.loginDetails$   = this.store.select('loginDetails');
    this.diary$          = this.store.select('diary');
    this.serviceRecords$ = this.store.select('serviceRecord');

    // Set of "badges" that a user can have (currently unused)
    this.accreditationSet = [
      { icon: 'gas-safe.png' },
      { icon: 'diploma.png' },
      { icon: 'multiple-diploma.png' },
      { icon: 'certified.png' },
      { icon: 'camera.png' },
      { icon: 'year.png' },
      { icon: 'reviewed.png' },
      { icon: 'educated.png' },
      { icon: 'efficient.png' },
      { icon: 'trophy.png' }
    ];

    // Quick-link buttons
    this.quickLinks = [
      {
        label: 'Marketing & sales resources',
        link: '/content-archive'
      },
      {
        label: 'Job record dashboard',
        link: '/service-dashboard'
      },
      {
        label: 'Your diary',
        link: '/diary'
      },
      {
        label: 'Product selector',
        link: '/product-selector'
      },
    ];
  }

  ngOnInit() {

    // Fetch all necessary profile data

    this.store.dispatch(new FetchAccreditationsRequestAction());
    this.store.dispatch(new FetchUserProfileRequestAction());
    this.store.dispatch(new FetchUserRolesRequestAction());
    this.store.dispatch(new FetchEventTypesRequestAction());
    this.store.dispatch(new FetchEventsRequestAction());

    this.store.dispatch(
      new FetchServiceRecordsRequestAction({
        pageNo:        1,
        perPage:       4,
        customerQuery: null,
        upcoming:      false,
      })
    );
  }


  /**
   * Navigates to a new route
   *
   * @param {string} pageRef Route to which to navigate
   */
  goToPage(pageRef: string) {
    this.router.navigate([pageRef]);
  }

  /**
   * Given a starting date, returns the number of years from that date until
   * the present
   *
   * @param {string}  started Starting date (YYYY-MM-DD)
   * @param {boolean} single  If set, "years" prefix will not be added
   * @return {string}
   */
  getYearsAtClub(started: string, single: boolean): string {
    const a = moment(started);
    const b = moment();

    if (single)
      return a.diff(b, 'years').toString();
    else
      return a.diff(b, 'years').toString() + ' years';
  }

  /**
   * Opens ProfileEditModalComponent to edit a user's profile
   *
   * @param {any} data Object containing UserProfile, accreditations and available roles
   */
  editProfileDetails(data: any) {
    const dialogRef = this.dialog.open(ProfileEditModalComponent, {
      data: {
        userProfile:    data.profile,
        accreditations: data.accreditations,
        roles:          this.roles,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new UpdateUserProfileRequestAction({profile: result}));
      }
    });
  }
}
