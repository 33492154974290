<h2 matDialogTitle>Edit page content</h2>

<mat-dialog-content>

  <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>

  <form [formGroup]="fg" novalidate *ngIf="page">
    <div *ngFor="let f of page.fields">

      <mat-form-field *ngIf="f.type === 'text'">
        <input matInput [formControlName]="f.fieldName" [placeholder]="f.label" />
      </mat-form-field>

      <mat-form-field *ngIf="f.type === 'textarea'">
        <textarea matInput [formControlName]="f.fieldName" [placeholder]="f.label"></textarea>
      </mat-form-field>

      <div *ngIf="f.type === 'rich'">
        <app-rich-editor [initialContent]="f.current" [elementId]="f.fieldName + 'Editor'"
          (onChange)="onRichEditorChange(f, $event)">
        </app-rich-editor>
      </div>

    </div>
  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>