/**
 * main models
 */

import {environment} from '../../environments/environment';
import { Injectable } from "@angular/core";

/**
 * Links for site footer
 */
@Injectable()
export class ExternalLinks
{
  topLinks: any[] = [
    {
      label: 'ADEY®',
      url: 'http://www.adey.com/'
    },
    {
      label: 'Products',
      url: 'http://www.adey.com/categories'
    },
    {
      label: 'Best Practice',
      url: 'http://www.adey.com/best-practice'
    },
    {
      label: 'Support',
      url: 'http://www.adey.com/support'
    },
    {
      label: 'Contact Us',
      url: 'http://www.adey.com/contact'
    },
  ];
  siteLinks: any[] = [
    {
      label: 'About us',
      url: 'http://www.adey.com/aboutus'
    },
    {
      label:      'Warranty',
      url:        null,
      routerLink: '/warranty',
    },
    {
      label: 'Awards',
      url: 'http://www.adey.com/aboutus#awards'
    },
    {
      label: 'Terms',
      url: 'https://www.adey.com/adeyproclub-terms-and-conditions'
    },
    {
      label: 'Privacy Policy',
      url: environment.externalRoutes.policy_privacy
    },
    {
      label: 'Cookies policy',
      url: 'http://www.adey.com/terms#cookies'
    },
    {
      label: 'Sales team',
      url: 'http://www.adey.com/installers/contact#salesteam'
    },
  ];
}
