<div class="container-fluid rewards-page">
  <div class="row content-page ">

    <div class="container-title has-sub">
      <h1 class="white">Rewards</h1>
    </div>

    <app-page-content page="REWARDS" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="rewards-container background-white col-xs-12" *ngIf="loginDetails$ | async; let loginDetails">
      <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending && !loginDetails.currentProfile" class="col-xs-12">
        Loading rewards...
      </app-waiting-spinner>

      <app-message-panel kind="SUCCESS" *ngIf="(rewards$ | async).message && !(rewards$ | async).error">
        <ng-container *ngIf="(rewards$ | async).installHubVoucherCode else pointsTransfer">
          {{ (rewards$ | async).message }}
          <div class="voucher-code">
            {{ (rewards$ | async).installHubVoucherCode }}
            <button class="btn background-adey-red color-white" ngxClipboard
              [cbContent]="(rewards$ | async).installHubVoucherCode" (click)="copyClicked = true">{{ copyClicked ?
              'Copied!' : 'Click to copy' }}</button>
          </div>
          <hr />
          <button class="btn background-adey-red color-white" (click)="openStore()">Log in to spend your
            voucher</button>
        </ng-container>

        <ng-template #pointsTransfer>
          Points transfer successful.
          <hr />
          <button class="btn background-adey-red color-white" (click)="goHome()">Go home</button>
        </ng-template>
      </app-message-panel>

      <app-message-panel kind="SUCCESS" *ngIf="(emailDetails$ | async).message">
        {{(emailDetails$ | async).message}}
        <hr />
        <button class="btn background-adey-red color-white" (click)="goHome()">Go home</button>
      </app-message-panel>

      <div class="rewards-content" *ngIf="loginDetails.currentProfile">
        <div class="profile-container">
          <div class="profile-badge border-box border-light-gray align-center">
            <div class="content">
              <div class="ie-image-patch profile-photo"
                *ngIf="loginDetails.currentProfile && loginDetails.currentProfile.photoUrl"
                [style.backgroundImage]="'url(' + loginDetails.currentProfile.photoUrl + ')'">
                <img [src]="loginDetails.currentProfile.photoUrl" />
              </div>
              <div class="profile-photo-placeholder background-lighter-gray color-white align-center"
                *ngIf="loginDetails.currentProfile && !loginDetails.currentProfile.photoUrl">
                <i class="icon-user"></i>
              </div>
              <div class="user-description">
                <h2 class="color-dark-gray user-name">{{loginDetails.currentProfile.firstName + ' ' +
                  loginDetails.currentProfile.lastName}}</h2>
                <p class="color-dark-gray" *ngIf="loginDetails.currentProfile.createdAt">
                  Member for {{getMembershipLength(loginDetails.currentProfile.createdAt)}}
                </p>
                <h3 class="color-adey-red medium no-margin">{{loginDetails.currentProfile.pointsCurrent}} Points</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="rewards-wrapper row">
          <div class="reward-container col-lg-4 col-md-6">
            <div class="border-box border-light-gray">
              <img src="/assets/i/pages/rewards/rewards_spares_teaser.png" />
              <h2 class="color-adey-red">Transfer points to your ADEY Spares account</h2>
              <p class="description">We supply the spare parts that protect the nation's central heating, and now you
                can use your ProClub points to purchase them. To transfer points you've earned through registering your
                MagnaClean filters, submit the number of points you want to transfer to your Spares account, and the
                next time you sign in, they'll be ready to spend.</p>
              <button class="btn background-green color-white no-radius" routerLink="spares">
                Redeem points
              </button>
            </div>
          </div>

          <div class="reward-container col-lg-4 col-md-6"
            [ngClass]="!loginDetails.currentProfile.pointsClaimable ? 'disabled' : ''">
            <div class="border-box border-light-gray">
              <img src="/assets/i/pages/rewards/rewards_voucher_teaser.jpg" />
              <h2 class="color-adey-red">Treat yourself</h2>
              <p class="description">It's easy to trade points you've earned for retail vouchers to spend in shops or
                restaurants - the choice is yours! Complete the form and how many points you'd like to exchange, then
                press submit. We'll send them to your email address and you can spend them on whatever you wish -
                whether it's a treat for the family or something for yourself, make sure you enjoy the reward you've
                earned!</p>
              <button class="btn background-green color-white no-radius"
                [disabled]="!loginDetails.currentProfile.pointsClaimable" routerLink="love2shop">
                Redeem voucher
              </button>
              <div *ngIf="!loginDetails.currentProfile.pointsClaimable" class="overlay">
                <div class="text">To unlock this reward register a MagnaCleanse system flush unit, connect a MagnaClean
                  Professional3 Sense filter or sign up to ADEY ProCheck.</div>
              </div>
            </div>
          </div>

          <div class="reward-container col-lg-4 col-md-6" *ngIf="warrantyList$">
            <div class="border-box border-light-gray">
              <img src="/assets/i/pages/rewards/rewards_promoshop_teaser.jpg" />
              <h2 class="color-adey-red">Get great gear</h2>
              <p class="description">To exchange the points you've earned from your MagnaClean Professional2®
                registrations,
                for items from the ADEY® promo shop, click to select the items you would like to receive and go through
                to checkout.
                Perhaps you'd like a beanie hat, or even a tool set takes your fancy - they're your points to spend on
                whatever you wish.
                At the checkout it works like any other online shop and your chosen goods will then be delivered to your
                business address.</p>
              <button class="btn background-green color-white no-radius"
                (click)="promoShopAccess(loginDetails.currentProfile)">
                <span *ngIf="!loginDetails.promoShopPending">
                  Redeem points
                </span>
                <app-waiting-spinner kind="spinner" *ngIf="loginDetails.promoShopPending"></app-waiting-spinner>
              </button>
              <app-message-panel kind="ERROR" *ngIf="loginDetails.promoShopError">{{loginDetails.promoShopError}}
              </app-message-panel>
            </div>
          </div>

          <div class="reward-container col-lg-4 col-md-6">
            <div class="border-box border-light-gray">
              <img src="/assets/i/pages/rewards/rewards_demokit_teaser.png" />
              <h2 class="color-adey-red">Order Demo Kits</h2>
              <p class="description">
                Give your customer a true understanding of what sludge is, what it looks like, how a filter works, how
                inhibitor works and how to test if they have sludge with the ADEY Demonstration Kits. Order your tools
                using ProClub points to educated the homeowner and join the fight to STOP SLUDGE DEAD.
              </p>
              <button class="btn background-green color-white no-radius" routerLink="demokit">
                Redeem Demo Kit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="rewards-table col-xs-12">
        <div class="scroll-container">
          <table class="item-table border-box border-light-gray">
            <tr class="no-pad">
              <th class="background-adey-red color-white">Membership level</th>
              <th class="background-adey-red color-white">Number of installs per year</th>
              <th class="background-adey-red color-white">Rewards per install</th>
            </tr>
            <tr class="no-pad">
              <td>Member</td>
              <td>0 - 19</td>
              <td>1</td>
            </tr>
            <tr class="no-pad">
              <td>Associate</td>
              <td>20 - 29</td>
              <td>2</td>
            </tr>
            <tr class="no-pad">
              <td>Partner</td>
              <td>30 - 39</td>
              <td>3</td>
            </tr>
            <tr class="no-pad">
              <td>Pro Partner</td>
              <td>40 - 49</td>
              <td>4</td>
            </tr>
            <tr class="no-pad">
              <td>Pro Partner+</td>
              <td>50+</td>
              <td>5</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
