import { Action } from '@ngrx/store';
import { FetchCustomerSuggestionRequest, FetchCustomerSuggestionResponse } from "../../models/customer-suggestion";

/**
 * Action type Definitions
 * @type {string}
 */
export const FETCH_CUSTOMER_SUGGESTION_REQUEST  = 'FETCH_CUSTOMER_SUGGESTION_REQUEST';
export const FETCH_CUSTOMER_SUGGESTION_RESPONSE = 'FETCH_CUSTOMER_SUGGESTION_RESPONSE';

/**
 * Action definitions
 */
export class FetchCustomerSuggestionRequestAction implements Action {
    readonly type = FETCH_CUSTOMER_SUGGESTION_REQUEST;
    constructor(public payload: FetchCustomerSuggestionRequest) {}
}

export class FetchCustomerSuggestionResponseAction implements Action {
    readonly type = FETCH_CUSTOMER_SUGGESTION_RESPONSE;
    constructor(public payload: FetchCustomerSuggestionResponse) {}
}

/**
 * Export actions
 */
export type Actions = | FetchCustomerSuggestionRequestAction | FetchCustomerSuggestionResponseAction;


