
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * LoginService: provides all functionality related to user login, token
 * validation, user profile and registration, etc.
 */


import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';

import {
  ActivateUserRequest,
  ActivateUserResponse,
  CreatePromoShopTokenResponse,
  CurrentUser,
  FetchUserProfileResponse,
  LoadLoginTokenResponse,
  LoginRequest,
  LoginResponse,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TokenCheckResponse,
  WaterTesterRegisterRequest,
  WaterTesterRegisterResponse,
} from '../models/login';
import { ProCheckSite } from '../models/procheck-site';
import {
  AccreditationItem,
  FetchAccreditationsResponse,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
  UserProfile,
} from '../models/user-profile';
import {
  FetchUserProfileRequestAction,
  LoginRequestAction,
  ModalRegisterWaterTesterRequestAction
} from '../state-management/actions/login-details';
import { StoreState } from '../state-management/store';
import { ApiService } from './api.service';
import { LoginService as MockService } from './login.service.mock';


@Injectable()
export class LoginService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
  ) {
    this.mockService = new MockService();
  }

  /**
   * Activates the current user
   *
   * @param {ActivateUserRequest} req
   * @return {Observable<ActivateUserResponse>}
   */
  activateUser(req: ActivateUserRequest): Observable<ActivateUserResponse> {
    const proCheck = ProCheckSite.getCheck();
    if (proCheck) {
      return this.apiService.apiGet('/watertest/account/activate/' + req.token + '?ident=procheck-portal').pipe(
        map((res: any): ActivateUserResponse => {
          return {
            error: null,
            message: 'Your account has been activated, please log in to continue.',
          };
        }),
        catchError((err: any): Observable<ActivateUserResponse> => {
          const errorMessage = err && err.error ? err.error.message : null;
          return observableOf({
            message: null,
            error: errorMessage
              ? `Unable to activate user account: ${errorMessage}`
              : 'Unable to activate user account: unknown error',
          });
        }),);
    } else {
      return this.apiService.apiPost('/activate', req).pipe(
        map((res: any): ActivateUserResponse => {
          return {
            error: null,
            message: 'Your account has been activated, please log in to continue.',
            homeowner: res.resource[0].homeowner
          };
        }),
        catchError((err: any): Observable<ActivateUserResponse> => {
          const errorMessage = err && err.error ? err.error.message : null;
          return observableOf({
            message: null,
            error: errorMessage
              ? `Unable to activate user account: ${errorMessage}`
              : 'Unable to activate user account: unknown error',
          });
        }),);
    }
  }

  /**
   * Checks the currently loaded JWT for validity
   *
   * @return {Observable<TokenCheckResponse>}
   */
  checkToken(): Observable<TokenCheckResponse> {
    return this.apiService.apiGet('/current-user').pipe(
      map((res: any): TokenCheckResponse => {
        const valid: boolean = !!res.id;
        return {
          error: valid ? null : 'Invalid token',
          ready: true,
          user: valid ? CurrentUser.fromApi(res) : null,
          valid,
        };
      }),
      catchError((err: any): Observable<TokenCheckResponse> => {
        return observableOf({
          error: 'Invalid token',
          ready: true,
          valid: false,
          user: null,
        });
      }),);
  }

  /**
   * Creates a new PromoShop token for the current user, used when building a
   * link to the PromoShop
   *
   * @return {Observable<CreatePromoShopTokenResponse>}
   */
  createPromoShopToken(): Observable<CreatePromoShopTokenResponse> {
    return this.apiService.apiPost('/promoshop-access', null).pipe(
      map((res: any): CreatePromoShopTokenResponse => {
        const valid: boolean = res && res.success && res.promoshop_token;
        return {
          error: valid ? null : 'Invalid response from server',
          token: valid ? res.promoshop_token : null,
        };
      }),
      catchError((err: any): Observable<CreatePromoShopTokenResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to get token to access promo shop: ${err.error.message}`
            : 'Unable to get token to access promo shop',
          token: null,
        })
      ),);
  }

  /**
   * Fetches all AccreditationItem models
   *
   * @return {Observable<FetchAccreditationsResponse>}
   */
  fetchAccreditations(): Observable<FetchAccreditationsResponse> {
    return this.apiService.apiGet('/get-accreditations').pipe(
      map((res: any): FetchAccreditationsResponse => {
        const mappedAccreditationArray = [];

        if (res && res.resource)
          for (let i = 0; i < res.resource.length; i++)
            mappedAccreditationArray.push(AccreditationItem.getFromApi(res.resource[i]));

        return {
          error: null,
          accreditations: mappedAccreditationArray,
        };
      }),
      catchError((err): Observable<FetchAccreditationsResponse> => {
        return observableOf({
          error: err.message,
          accreditations: null,
        });
      }),);
  }

  /**
   * Fetches the current user's profile
   *
   * @return {Observable<FetchUserProfileResponse>}
   */
  fetchProfile(): Observable<FetchUserProfileResponse> {
    return this.apiService.apiGet('/current-user').pipe(
      map((res: any): FetchUserProfileResponse => {
        Sentry.setUser({
          'email': res.email,
          'id': res.id,
        });
        const valid: boolean = !!res.id;
        return {
          error: valid ? null : 'Invalid response from server',
          profile: valid ? UserProfile.fromAPIData(res) : null,
        };
      }),
      catchError((err: any): Observable<any> => {
        return observableOf({
          error: err && err.error && err.error.message ? err.error.message : 'Whoops, something went wrong',
          profile: null,
        });
      }),);
  }

  /**
   * Loads a stored login token (JWT) if possible
   *
   * @return {Observable<LoadLoginTokenResponse>}
   */
  loadToken(): Observable<LoadLoginTokenResponse> {
    return observableOf({
      error: null,
      token: window.localStorage.getItem('login_token'),
    });
  }

  /**
   * Attempts to login with a specified username and password
   *
   * @param {LoginRequest} req
   * @return {Observable<LoginResponse>}
   */
  login(req: LoginRequest): Observable<LoginResponse> {
    const proCheck = ProCheckSite.getCheck();
    const callFrom = proCheck ? '?from=procheck' : '';
    return this.apiService.apiPost('/login' + callFrom, { email: req.email, password: req.password }).pipe(
      map((res: any): LoginResponse => {

        const valid = res.user && res.token;

        // Store the token in localStorage
        if (valid)
          window.localStorage.setItem('login_token', res.token);

        return {
          error: valid ? null : 'User and/or token missing from response',
          ready: true,
          token: res.token,
          user: CurrentUser.fromApi(res.user),
          profile: UserProfile.fromAPIData(res.user),
        };
      }),
      catchError((err: any): Observable<LoginResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          error: (errorMessage ? errorMessage : 'Issue communicating with server'),
          ready: true,
          token: null,
          user: null,
          profile: null,
          redirectClientAdmin: null,
        });
      }),);
  }

  /**
 * Attempts to login with a specified username and password
 *
 * @param {LoginRequest} req
 * @return {Observable<LoginResponse>}
 */
  loginExisting(req: LoginRequest): Observable<LoginResponse> {

    return this.apiService.apiPost('/register-existing', {
      email: req.email,
      password: req.password,
      utm_source: req.utm_source,
      utm_campaign: req.utm_campaign,
      utm_medium: req.utm_medium
    }).pipe(map((res: any): LoginResponse => {

      const valid = res.user && res.token;

      // Store the token in localStorage
      if (valid)
        window.localStorage.setItem('login_token', res.token);

      return {
        error: valid ? null : 'User and/or token missing from response',
        ready: true,
        token: res.token,
        user: CurrentUser.fromApi(res.user),
        profile: UserProfile.fromAPIData(res.user),
      };
    }),
      catchError((err: any): Observable<LoginResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          error: (errorMessage ? errorMessage : 'Issue communicating with server'),
          ready: true,
          token: null,
          user: null,
          profile: null,
          redirectClientAdmin: null,
        });
      }),);
  }

  /**
   * Performs a logout for the current user (removes stored JWT)
   */
  logout() {
    try {
      window.localStorage.removeItem('login_token');
    } catch (error) {}
  }

  /**
   * Resend user account activation email
   * Password reset models are used here as they are identical
   */
  resendActivationEmail(req: RequestResetPasswordRequest): Observable<RequestResetPasswordResponse> {
    return this.apiService.apiPost('/resend-activation', { email: req.email }).pipe(
      map((res: any): RequestResetPasswordResponse => {
        return {
          error: null,
          message: 'Activation email successfully sent'
        };
      }),
      catchError((err: any): Observable<RequestResetPasswordResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          error: (errorMessage ? errorMessage : 'Issue communicating with server'),
          message: null
        });
      }),);
  }

  /**
   * Initiates a password reset for the user identified by e-mail address
   *
   * @param {RequestResetPasswordRequest} req
   * @return {Observable<RequestResetPasswordResponse>}
   */
  requestResetPassword(req: RequestResetPasswordRequest): Observable<RequestResetPasswordResponse> {
    const proCheck = ProCheckSite.getCheck();

    if (proCheck) {
      return this.apiService.apiGet('/watertest/account/forgot/' + req.email).pipe(
        map((res: any): RequestResetPasswordResponse => {
          return {
            error: null,
            message: 'An email has been sent to your account, please follow the instructions in this email to reset your password.'
          };
        }),
        catchError((err: any): Observable<RequestResetPasswordResponse> => {
          const errorMessage = err && err.error ? err.error.message : null;
          return observableOf({
            error: (errorMessage ? errorMessage : 'Issue communicating with server'),
            message: null
          });
        }),);
    } else {
      return this.apiService.apiPost('/send-password-reset', { email: req.email }).pipe(
        map((res: any): RequestResetPasswordResponse => {
          return {
            error: null,
            message: 'An email has been sent to your account, please follow the instructions in this email to reset your password.'
          };
        }),
        catchError((err: any): Observable<RequestResetPasswordResponse> => {
          const errorMessage = err && err.error ? err.error.message : null;
          return observableOf({
            error: (errorMessage ? errorMessage : 'Issue communicating with server'),
            message: null
          });
        }),);
    }
  }

  /**
   * Resets a user's password based on a specified user e-mail address and
   * reset token
   *
   * @param {ResetPasswordRequest} req
   * @return {Observable<ResetPasswordResponse>}
   */
  resetPassword(req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.apiService.apiPost('/complete-reset-password', {
      email: req.username,
      password: req.password,
      token: req.token,
    }).pipe(
      map((res: any): ResetPasswordResponse => {
        return {
          error: null,
          message: 'Password reset'
        };
      }),
      catchError((err: any): Observable<ResetPasswordResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          error: (errorMessage ? errorMessage : 'Unable to reset password: unknown error'),
          message: null,
        });
      }),);
  }

  /**
   * Updates the current user's profile
   *
   * @param {UpdateUserProfileRequest} req
   * @return {Observable<UpdateUserProfileResponse>}
   */
  updateUserProfile(req: UpdateUserProfileRequest): Observable<UpdateUserProfileResponse> {
    return this.apiService.apiPatch('/update-profile', UserProfile.toApiData(req.profile)).pipe(
      map((res: any): UpdateUserProfileResponse => {
        this.store.dispatch(new FetchUserProfileRequestAction());
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Unable to update user profile: invalid response from server',
          profile: req.profile,
        };
      }),
      catchError((err: any): Observable<UpdateUserProfileResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to update user profile: ${err.error.message}`
            : 'Unable to update user profile',
          profile: req.profile,
        })
      ),);
  }

  /**
   * Attempts to login with a specified username and password water tester
   *
   * @param {LoginRequest} req
   * @return {Observable<LoginResponse>}
   */
  loginWaterTester(req: LoginRequest): Observable<LoginResponse> {
    return this.apiService.apiPost('/watertest/check-user-login?from=portal', { email: req.email, password: req.password }).pipe(
      map((res: any): LoginResponse => {
        const valid = res[0].token;

        //console.log('service success : ', res);
        // if authorized from water tester APi login using proclub API
        if (valid) {
          this.store.dispatch(new LoginRequestAction({
            email: req.email,
            password: req.password,
            redirectClientAdmin: null,
            redirectPage: null,
          }));
        }

        return {
          error: valid ? null : 'User and/or token missing from response',
          ready: true,
          token: res[0].token,
          user: null,
          profile: null
        };
      }),
      catchError((err: any): Observable<LoginResponse> => {
        const errorMessage = err && err.error.summary ? err.error.summary : null;
        //console.log('service error : ', err.status);
        if (err.status === 412) {
          this.store.dispatch(new ModalRegisterWaterTesterRequestAction({
             email: req.email,
             password: req.password,
           }));
        }
        return observableOf({
          error: (errorMessage ? errorMessage : 'Issue communicating with server'),
          ready: true,
          token: null,
          user: null,
          profile: null,
          redirectClientAdmin: null,
          code: err.status
        });
      }),);
  }

  registerWaterTester(req: WaterTesterRegisterRequest): Observable<WaterTesterRegisterResponse> {
    return this.apiService.apiPost('/watertest/user-auto-register', {
      email: req.email,
      password: req.password,
      activationCode: req.activationCode,
      questions: [{ question: req.question, answer: req.answer }],
    }).pipe(
      map((res: any): WaterTesterRegisterResponse => {
        return {
          error: null,
          response: res
        };
      }),
      catchError((err: any): Observable<WaterTesterRegisterResponse> => {
        const errorMessage = err && err.error.summary ? err.error.summary : null;
        return observableOf({
          error: (errorMessage ? errorMessage : 'Issue communicating with server'),
          response: null
        });
      }),);
  }
}
