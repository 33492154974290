import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { SetUserPopupDisplayCount } from '../../../state-management/actions/popup';
import { selectPopupDisplayCount } from '../../../state-management/selectors/popup';
import { StoreState } from '../../../state-management/store';

const MAX_TIMES_TO_DISPLAY = 2;
const URL: string = 'https://hubspot.adey.com/win-your-heating';

@Component({
  selector: 'app-promotion-modal',
  templateUrl: './promotion-modal.component.html',
  styleUrls: ['./promotion-modal.component.scss']
})
export class PromotionModalComponent implements OnInit, OnDestroy {
  private selectPopupDisplayCount$: Observable<Number>;
  private selectPopupDisplayCountSub: Subscription;
  private popupDisplayCount = 0;
  
  constructor(
    public dialogRef: MatDialogRef<PromotionModalComponent>,
    private store: Store<StoreState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectPopupDisplayCount$ = this.store.select(selectPopupDisplayCount('win-back-your-heating'));
  }

  ngOnInit() {
    this.selectPopupDisplayCountSub = this.selectPopupDisplayCount$.subscribe((count) => {
      if (count) {
        this.popupDisplayCount = Number(count);
      }
    });
  }

  ngOnDestroy() {
    if (this.selectPopupDisplayCountSub) {
      this.selectPopupDisplayCountSub.unsubscribe();
    }
  }

  public open(): void {
    window.open(URL, '_blank');
    this.store.dispatch(new SetUserPopupDisplayCount({ name: 'win-back-your-heating', count: MAX_TIMES_TO_DISPLAY }));
    this.dialogRef.close();
  }

  public dismiss(): void {
    this.popupDisplayCount++;
    this.store.dispatch(new SetUserPopupDisplayCount({ name: 'win-back-your-heating', count: this.popupDisplayCount }));

    this.dialogRef.close();
  }

}
