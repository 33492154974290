import { Injectable } from '@angular/core';
import { ApiSenseService } from './api-sense.service';
import { OnboardingQuestionResponse } from '../models/onboarding-questions';
import { ApiService } from './api.service';

@Injectable()
export class OnboardingQuestionsService {

  constructor(
    private senseApiService: ApiSenseService,
    private apiService: ApiService
  ) { }

  public async getQuestions(): Promise<OnboardingQuestionResponse> {
    return await this.senseApiService.apiGet<OnboardingQuestionResponse>('/onboarding/questions').toPromise();
  }

  public async putAnswers(answers: Record<string, string>): Promise<any> {
    return await this.apiService.apiPut('/onboarding/answers', answers).toPromise();
  }
}
