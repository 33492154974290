
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
/**
 * Mock for AdminService (see main service for full details)
 */





import { Injectable } from '@angular/core';

import {
  AddAdminEventRequest,
  AddAdminEventResponse,
  AddAdminEventTypeRequest,
  AddAdminEventTypeResponse,
  DeleteAdminEventRequest,
  DeleteAdminEventResponse,
  DeleteAdminEventTypeRequest,
  DeleteAdminEventTypeResponse,
  FetchAdminEventsRequest,
  FetchAdminEventsResponse,
  UpdateAdminEventRequest,
  UpdateAdminEventResponse,
  UpdateAdminEventTypeRequest,
  UpdateAdminEventTypeResponse,
} from '../models/admin-events';

import {
  AddAdminJobRequest,
  AddAdminJobResponse,
  DeleteAdminJobRequest,
  DeleteAdminJobResponse,
  FetchAdminJobsRequest,
  FetchAdminJobsResponse,
  UpdateAdminJobRequest,
  UpdateAdminJobResponse,

  AddAdminPromoCodeRequest,
  AddAdminPromoCodeResponse,
  DeleteAdminPromoCodeRequest,
  DeleteAdminPromoCodeResponse,
  FetchAdminPromoCodesResponse,
  UpdateAdminPromoCodeRequest,
  UpdateAdminPromoCodeResponse,

  AddAdminWarrantyRequest,
  AddAdminWarrantyResponse,
  DeleteAdminWarrantyRequest,
  DeleteAdminWarrantyResponse,
  FetchAdminWarrantiesRequest,
  FetchAdminWarrantiesResponse,
  UpdateAdminWarrantyRequest,
  UpdateAdminWarrantyResponse,
} from '../models/admin-jobs';

import {
  AddAdminResourceRequest,
  AddAdminResourceResponse,
  DeleteAdminResourceRequest,
  DeleteAdminResourceResponse,
  FetchAdminResourcesRequest,
  FetchAdminResourcesResponse,
  UpdateAdminResourceRequest,
  UpdateAdminResourceResponse,
} from '../models/admin-resources';

import {
  TransferAdminUserPointsRequest,
  TransferAdminUserPointsResponse,
  UpdateAdminUserPointsRequest,
  UpdateAdminUserPointsResponse,
} from '../models/admin-rewards';

import {
  AdminStats,
  FetchAdminStatsRequest,
  FetchAdminStatsResponse,
} from '../models/admin-stats';

import {
  AdminUser,

  AddAdminUserRequest,
  AddAdminUserResponse,
  DeleteAdminUserRequest,
  DeleteAdminUserResponse,
  FetchAdminUsersRequest,
  FetchAdminUsersResponse,
  UpdateAdminUserRequest,
  UpdateAdminUserResponse,
} from '../models/admin-users';

import {
  FetchPageContentResponse,
  UpdatePageContentRequest,
  UpdatePageContentResponse,
} from '../models/content-pages';

import {
  DiaryEvent,
} from '../models/diary';

import {
  Job,
} from '../models/job';

import {
  ResourceItem,
} from '../models/resources';

import {
  UserSuggestion,

  FetchUserSuggestionRequest,
  FetchUserSuggestionResponse,
} from '../models/user-suggestion';

import {
  PromoCode,
  Warranty,
} from '../models/warranty';


@Injectable()
export class AdminService {

  addEvent(req: AddAdminEventRequest): Observable<AddAdminEventResponse> {
    const valid = req.event && !req.event.id;
    const validUser = !req.event.userEvent || (req.event.userEvent && req.event.userId);
    let error = null;
    if (!valid)
      error = 'Cannot add an event with an ID';
    else if (!validUser)
      error = 'User events must specify the owning user\'s ID';
    return observableOf({
        error,
        event: error ? null : Object.assign({}, req.event, {id: Math.floor(Math.random() * 1000).toString()}),
      }).pipe(
      delay(1000));
  }

  addEventType(req: AddAdminEventTypeRequest): Observable<AddAdminEventTypeResponse> {
    const valid = req.type.toString().trim().length > 0;
    return observableOf({
        error: valid ? null : 'No event type name specified',
        type:  valid ? Object.assign({}, req.type, {id: Math.floor(Math.random() * 1000)}) : null,
      }).pipe(
      delay(1000));
  }

  addJob(req: AddAdminJobRequest): Observable<AddAdminJobResponse> {
    const valid = req.job && !req.job.id;
    const validUser = !!req.job.userId;
    let error = null;
    if (!valid)
      error = 'Cannot add a job with an ID';
    else if (!validUser)
      error = 'No user ID specified';
    return observableOf({
        error,
        job: error ? null : Object.assign({}, req.job, {id: Math.floor(Math.random() * 1000).toString()}),
      }).pipe(
      delay(1000));
  }

  addPromoCode(req: AddAdminPromoCodeRequest): Observable<AddAdminPromoCodeResponse> {
    const valid = req.promoCode && req.promoCode.code;
    return observableOf({
        error:     valid ? null : 'Promotional code must have a code value',
        promoCode: valid ? req.promoCode : null,
      }).pipe(
      delay(1000));
  }

  addResource(req: AddAdminResourceRequest): Observable<AddAdminResourceResponse> {
    const valid = req.resource && !req.resource.id;
    return observableOf({
        error:    valid ? null : 'Cannot add a new resource with an ID',
        resource: valid
          ? Object.assign({}, req.resource, {id: Math.floor(Math.random() * 1000).toString()})
          : null,
      }).pipe(
      delay(1000));
  }

  addUser(req: AddAdminUserRequest): Observable<AddAdminUserResponse> {
    const valid = req.user && !req.user.id;
    return observableOf({
        error: valid ? null : 'Cannot add a new user with an ID',
        user:  valid
          ? Object.assign({}, req.user, {id: Math.floor(Math.random() * 1000).toString()})
          : null,
      }).pipe(
      delay(1000));
  }

  addWarranty(req: AddAdminWarrantyRequest): Observable<AddAdminWarrantyResponse> {
    const valid = req.warranty && !req.warranty.id;
    const validUser = !!req.warranty.userId;
    let error = null;
    if (!valid)
      error = 'Cannot add a warranty with an ID';
    else if (!validUser)
      error = 'No user ID specified';
    return observableOf({
        error,
        warranty: error ? null : Object.assign({}, req.warranty, {id: Math.floor(Math.random() * 1000).toString()}),
      }).pipe(
      delay(1000));
  }

  deleteEvent(req: DeleteAdminEventRequest): Observable<DeleteAdminEventResponse> {
    const valid = req.event && req.event.id;
    return observableOf({
        error: valid ? null : 'Event to delete not specified',
        event: req.event,
      }).pipe(
      delay(1000));
  }

  deleteEventType(req: DeleteAdminEventTypeRequest): Observable<DeleteAdminEventTypeResponse> {
    const valid = !!req.type.id;
    return observableOf({
        error: valid ? null : 'Event type ID to delete not specified',
        type:  req.type,
      }).pipe(
      delay(1000));
  }

  deleteJob(req: DeleteAdminJobRequest): Observable<DeleteAdminJobResponse> {
    const valid = req.job && req.job.id;
    return observableOf({
        error: valid ? null : 'No job ID specified',
        job:   req.job,
      }).pipe(
      delay(1000));
  }

  deletePromoCode(req: DeleteAdminPromoCodeRequest): Observable<DeleteAdminPromoCodeResponse> {
    const valid = req.promoCode && req.promoCode.code;
    return observableOf({
        error:     valid ? null          : 'No code specified',
        promoCode: valid ? req.promoCode : null,
      }).pipe(
      delay(1000));
  }

  deleteResource(req: DeleteAdminResourceRequest): Observable<DeleteAdminResourceResponse> {
    const valid = req.resource && req.resource.id;
    return observableOf({
        error: valid ? null            : 'Resource ID not specified',
        id:    valid ? req.resource.id : null,
      }).pipe(
      delay(1000));
  }

  deleteUser(req: DeleteAdminUserRequest): Observable<DeleteAdminUserResponse> {
    const valid = req.user && req.user.id;
    return observableOf({
        error: valid ? null     : 'User ID not specified',
        user:  valid ? req.user : null,
      }).pipe(
      delay(1000));
  }

  deleteWarranty(req: DeleteAdminWarrantyRequest): Observable<DeleteAdminWarrantyResponse> {
    const valid = req.warranty && req.warranty.id;
    return observableOf({
        error:    valid ? null : 'No warranty ID specified',
        warranty: req.warranty,
      }).pipe(
      delay(1000));
  }

  fetchEvents(req: FetchAdminEventsRequest): Observable<FetchAdminEventsResponse> {
    if (req.perPage <= 0 || req.pageNum < 1)
      return observableOf({
          adeyEvents:     null,
          userEvents:     null,
          adeyError:      req.adeyEvents ? 'Invalid page number' : null,
          userError:      req.userEvents ? 'Invalid page number' : null,
          totalPagesAdey: 0,
          totalPagesUser: 0,
        }).pipe(
        delay(1000));

    const totalToGenerate: number = (req.perPage * 5) + Math.floor(req.perPage / 2);
    const allEvents: DiaryEvent[] = [];
    for (let i = 0; i < totalToGenerate; i++)
      allEvents.push(DiaryEvent.getExample());

    const adeyEvents: DiaryEvent[] = allEvents.filter((e: DiaryEvent) => req.adeyEvents && !e.userEvent);
    const userEvents: DiaryEvent[] = allEvents.filter((e: DiaryEvent) => req.userEvents && e.userEvent && req.userId === e.userId);

    return observableOf({
        adeyEvents: req.adeyEvents
          ? adeyEvents.slice(req.perPage * (req.pageNum - 1), req.pageNum * req.perPage)
          : null,
        userEvents: req.userEvents
          ? userEvents.slice(req.perPage * (req.pageNum - 1), req.pageNum * req.perPage)
          : null,
        adeyError:      null,
        userError:      null,
        totalPagesAdey: Math.ceil(adeyEvents.length / req.perPage),
        totalPagesUser: Math.ceil(userEvents.length / req.perPage),
      }).pipe(
      delay(1000));
  }

  fetchJobs(req: FetchAdminJobsRequest): Observable<FetchAdminJobsResponse> {
    if (req.perPage <= 0 || req.pageNum < 1)
    return observableOf({
        error:      'Invalid page number',
        jobs:       [],
        totalPages: 0,
      }).pipe(
      delay(1000));

    const totalToGenerate: number = (req.perPage * 5) + Math.floor(req.perPage / 2);
    const allJobs: Job[] = [];
    for (let i = 0; i < totalToGenerate; i++)
      allJobs.push(Job.getExample());

    const filteredJobs: Job[] = req.type === 'CUSTOMER'
      ? allJobs.filter((v: Job): boolean =>
        `${v.customer.customerTitle} ${v.customer.customerSurname}`.toLowerCase().includes(req.query.toLowerCase())
      )
      : allJobs;

    return observableOf({
        error:      null,
        jobs:       filteredJobs.slice(req.perPage * (req.pageNum - 1), req.pageNum * req.perPage),
        totalPages: Math.ceil(filteredJobs.length / req.perPage),
      }).pipe(
      delay(1000));
  }

  fetchPromoCodes(): Observable<FetchAdminPromoCodesResponse> {
    const promoCodes: PromoCode[] = [];
    for (let i = 0; i < 10; i++)
      promoCodes.push(PromoCode.getExample());

    return observableOf({
        error: null,
        promoCodes,
      }).pipe(
      delay(1000));
  }

  fetchResources(req: FetchAdminResourcesRequest): Observable<FetchAdminResourcesResponse> {
    if (req.perPage <= 0 || req.pageNum < 1)
    return observableOf({
        error:      'Invalid page',
        resources:  null,
        totalPages: 0,
      }).pipe(
      delay(1000));

    const totalToGenerate: number = (req.perPage * 5) + Math.floor(req.perPage / 2);
    const allResources: ResourceItem[] = [];
    for (let i = 0; i < totalToGenerate; i++)
      allResources.push(ResourceItem.getExample());

    const resources = allResources.filter((ri: ResourceItem) => (
      !req.pageName || req.pageName.trim().length === 0 ||
      ri.pages
        .filter((p: string) =>
          p.toLowerCase() === req.pageName.toLowerCase()
        )
        .length > 0
    ));

    return observableOf({
        error:      null,
        resources:  resources.slice(req.perPage * (req.pageNum - 1), req.pageNum * req.perPage),
        totalPages: Math.ceil(resources.length / req.perPage),
      }).pipe(
      delay(1000));
  }

  fetchStats(req: FetchAdminStatsRequest): Observable<FetchAdminStatsResponse> {
    return observableOf({
        error: null,
        stats: AdminStats.getExample(),
      }).pipe(
      delay(1000));
  }

  fetchUsers(req: FetchAdminUsersRequest): Observable<FetchAdminUsersResponse> {
    if (req.perPage <= 0 || req.page < 1)
    return observableOf({
        error:      'Invalid page',
        users:      null,
        totalPages: 0,
      }).pipe(
      delay(1000));

    const totalToGenerate: number = (req.perPage * 2) + Math.floor(req.perPage / 2);
    const allUsers: AdminUser[] = [];
    for (let i = 0; i < totalToGenerate; i++)
      allUsers.push(AdminUser.getExample());

    let filteredUsers = allUsers;
    if (req.userName || req.userAddress || req.userType)
    {
      if (req.userName)
        filteredUsers = filteredUsers.filter((u: AdminUser): boolean => {
          if (!u.profile)
            return false;
          const name = `${u.profile.title} ${u.profile.firstName} ${u.profile.lastName}`;
          return name.toLowerCase().includes(req.userName.toLowerCase());
        });

      if (req.userAddress)
        filteredUsers = filteredUsers.filter((u: AdminUser): boolean => {
          if (!u.profile)
            return false;
          const address = (
            u.profile.address1 + ' ' +
            u.profile.address2 + ' ' +
            u.profile.town     + ' ' +
            u.profile.county   + ' ' +
            u.profile.postCode
          );
          return address.toLowerCase().includes(req.userAddress.toLowerCase());
        });

      if (req.userType)
      {
        if (req.userType === 1) // ADMIN
          filteredUsers = filteredUsers.filter((u: AdminUser): boolean => u.admin);
        else
          filteredUsers = filteredUsers.filter((u: AdminUser): boolean => {
            if (!u.profile)
              return false;
            return u.profile.userType === req.userType;
          });
      }
    }

    return observableOf({
        error:      null,
        users:      filteredUsers.slice(req.perPage * (req.page - 1), req.page * req.perPage),
        totalPages: Math.ceil(filteredUsers.length / req.perPage),
      }).pipe(
      delay(1000));
  }

  fetchUserSuggestions(req: FetchUserSuggestionRequest): Observable<FetchUserSuggestionResponse> {
    const allUsers: UserSuggestion[] = [];
    for (let i = 0; i < 100; i++)
      allUsers.push(UserSuggestion.getExample());

    const users = allUsers.filter((u: UserSuggestion) => (
      `${u.title} ${u.firstName} ${u.lastName}`.toLowerCase().includes(req.query.toString().trim().toLowerCase())
    ));

    return observableOf({
        error: null,
        query: req.query,
        users,
      }).pipe(
      delay(1000));
  }

  fetchWarranties(req: FetchAdminWarrantiesRequest): Observable<FetchAdminWarrantiesResponse> {
    if (req.perPage <= 0 || req.pageNum < 1)
    return observableOf({
        error:      'Invalid page number',
        warranties: [],
        totalPages: 0,
      }).pipe(
      delay(1000));

    const totalToGenerate: number = (req.perPage * 5) + Math.floor(req.perPage / 2);
    const allWarranties: Warranty[] = [];
    for (let i = 0; i < totalToGenerate; i++)
      allWarranties.push(Warranty.getExample());

    const filteredWarranties: Warranty[] = req.type === 'CUSTOMER'
      ? allWarranties.filter((v: Warranty): boolean =>
        `${v.customer.customerTitle} ${v.customer.customerSurname}`.toLowerCase().includes(req.query.toLowerCase())
      )
      : allWarranties;

    return observableOf({
        error:      null,
        warranties: filteredWarranties.slice(req.perPage * (req.pageNum - 1), req.pageNum * req.perPage),
        totalPages: Math.ceil(filteredWarranties.length / req.perPage),
      }).pipe(
      delay(1000));
  }

  transferUserPoints(req: TransferAdminUserPointsRequest): Observable<TransferAdminUserPointsResponse> {
    let error: string = null;

    if (!req.userFrom.id)
      error = 'Sending user missing ID';
    else if (!req.userTo.id)
      error = 'Receiving user missing ID';
    else if (req.amount < 0 || req.amount > req.userFrom.points.earned - req.userFrom.points.spent)
      error = 'Invalid amount';

    return observableOf({
        error,
        userFrom: error
          ? req.userFrom
          : Object.assign({}, req.userFrom, {
            points: {
              earned: req.userFrom.points.earned - req.amount,
              spent:  req.userFrom.points.spent,
            }}),
        userTo: error
          ? req.userTo
          : Object.assign({}, req.userTo, {
            points: {
              earned: req.userTo.points.earned + req.amount,
              spent:  req.userTo.points.spent,
            }}),
      }).pipe(
      delay(1000));
  }

  updateEvent(req: UpdateAdminEventRequest): Observable<UpdateAdminEventResponse> {
    const valid = req.event && req.event.id;
    const validUser = !req.event.userEvent || (req.event.userEvent && req.event.userId);
    let error = null;
    if (!valid)
      error = 'Event to update not specified';
    else if (!validUser)
      error = 'When creating a user event, the owning user\'s ID must be specified';

    return observableOf({
        error,
        event: req.event,
      }).pipe(
      delay(1000));
  }

  updateEventType(req: UpdateAdminEventTypeRequest): Observable<UpdateAdminEventTypeResponse> {
    const valid = !!req.type.id;
    return observableOf({
        error: valid ? null : 'Event type ID must be specified',
        type:  req.type,
      }).pipe(
      delay(1000));
  }

  updateJob(req: UpdateAdminJobRequest): Observable<UpdateAdminJobResponse> {
    const valid = req.job && req.job.id;
    return observableOf({
        error: valid ? null : 'No job ID specified',
        job:   req.job,
      }).pipe(
      delay(1000));
  }

  updatePageContent(req: UpdatePageContentRequest): Observable<UpdatePageContentResponse> {
    return observableOf({
        error: null,
        page:  req.page,
      }).pipe(
      delay(1000));
  }

  updatePromoCode(req: UpdateAdminPromoCodeRequest): Observable<UpdateAdminPromoCodeResponse> {
    const valid = req.promoCode && req.promoCode.code;
    return observableOf({
        error:     valid ? null : 'No code specified',
        promoCode: valid ? req.promoCode : null,
      }).pipe(
      delay(1000));
  }

  updateResource(req: UpdateAdminResourceRequest): Observable<UpdateAdminResourceResponse> {
    const valid: boolean = !!req.resource.id;

    return observableOf({
        error:    valid ? null : 'Missing resource ID',
        resource: req.resource,
      }).pipe(
      delay(1000));
  }

  updateUser(req: UpdateAdminUserRequest): Observable<UpdateAdminUserResponse> {
    const valid: boolean = !!req.user.id;

    return observableOf({
        error: valid ? null : 'Missing user ID',
        user:  req.user,
      }).pipe(
      delay(1000));
  }

  updateUserPoints(req: UpdateAdminUserPointsRequest): Observable<UpdateAdminUserPointsResponse> {
    const valid: boolean = !!req.user.id && req.points.earned >= 0 && req.points.spent >= 0 && req.points.spent <= req.points.earned;

    return observableOf({
        error: valid ? null : 'Invalid request',
        user:  valid ? Object.assign({}, req.user, {points: req.points}) : req.user,
      }).pipe(
      delay(1000));
  }

  updateWarranty(req: UpdateAdminWarrantyRequest): Observable<UpdateAdminWarrantyResponse> {
    const valid = req.warranty && req.warranty.id;
    return observableOf({
        error:    valid ? null : 'No warranty ID specified',
        warranty: req.warranty,
      }).pipe(
      delay(1000));
  }

}
