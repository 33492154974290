/**
 * resources actions: defines all actions related to viewing resources
 * (brochures, videos, etc.)
 */

import { Action } from '@ngrx/store';


// Import models
import {
  FetchResourceItemsRequest,
  FetchResourceItemsResponse,
  ResourceItem,
} from '../../models/resources';


// Action type definitions
export const FETCH_RESOURCE_ITEMS_REQUEST  = 'FETCH_RESOURCE_ITEMS_REQUEST';
export const FETCH_RESOURCE_ITEMS_RESPONSE = 'FETCH_RESOURCE_ITEMS_RESPONSE';


// Action definitions
export class FetchResourceItemsRequestAction implements Action {
  readonly type = FETCH_RESOURCE_ITEMS_REQUEST;
  constructor(public payload: FetchResourceItemsRequest) {}
}

export class FetchResourceItemsResponseAction implements Action {
  readonly type = FETCH_RESOURCE_ITEMS_RESPONSE;
  constructor(public payload: FetchResourceItemsResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchResourceItemsRequestAction
  | FetchResourceItemsResponseAction;
