/**
 * Define all actions to system message
 */
import { Action } from '@ngrx/store';

import {
  SystemMessageRequest,
  SystemMessageResponse,
} from '../../models/system-message';

export const SYSTEM_MESSAGE_REQUEST = 'SYSTEM_MESSAGE_REQUEST';
export const SYSTEM_MESSAGE_RESPONSE = 'SYSTEM_MESSAGE_RESPONSE';

// Action definitions
export class SystemMessageRequestAction implements Action {
  readonly type = SYSTEM_MESSAGE_REQUEST;
  constructor(public payload: SystemMessageRequest) { }
}

export class SystemMessageResponseAction implements Action {
  readonly type = SYSTEM_MESSAGE_RESPONSE;
  constructor(public payload: SystemMessageResponse) { }
}


// Export Actions type as a union of all defined actions
export type Actions =
  | SystemMessageRequestAction
  | SystemMessageResponseAction
  ;
