import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Store }      from '@ngrx/store';
import { Router }     from '@angular/router';
import { Observable } from 'rxjs';


import { StoreState }                     from '../../state-management/store';
import { State as RegisterJobState }      from '../../state-management/reducers/register-job';
import { State as RegisterWarrantyState } from '../../state-management/reducers/register-warranty';

import {
  Job,
  ProductType,
  RegisterJobRequest,
} from '../../models/job';

import {
  FetchProductTypesRequestAction,
} from '../../state-management/actions/register-warranty';

import {
  RegisterJobInitAction,
  RegisterJobRequestAction,
} from '../../state-management/actions/register-job';


/**
 * Summary
 *    Page for Job registration
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobComponent implements OnInit {

  // Store states
  public state$:        Observable<RegisterJobState>;
  public productState$: Observable<RegisterWarrantyState>;


  constructor(
    private router: Router,
    private store:  Store<StoreState>,
  ) {
    this.state$        = this.store.select('registerJob');
    this.productState$ = this.store.select('registerWarranty');

    this.store.dispatch(new RegisterJobInitAction());
    this.store.dispatch(new FetchProductTypesRequestAction());
  }

  ngOnInit() { }


  /**
   * Called via JobDetailsComponent::onPreSubmit() before a new Job is
   * submitted
   */
  handleFormPreSubmit() {
    this.store.dispatch(new RegisterJobInitAction());
  }

  /**
   * Called via JobDetailsComponent::onSubmit() when a new Job is being
   * submitted
   *
   * @param {Job} job
   */
  handleFormSubmit(job: Job) {
    this.store.dispatch(new RegisterJobRequestAction(RegisterJobRequest.fromJob(job)));
  }
}
