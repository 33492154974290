import { Component, OnInit } from '@angular/core';

/**
 * Summary
 *    Provides a tabbed navigation interface for page content and resource
 *    admin pages
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrls: ['./admin-content.component.scss']
})
export class AdminContentComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
