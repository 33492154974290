/**
 * warranty-list reducer: maintains state for the warranty list page
 */
import {
  Warranty,
  WarrantyStats,
} from '../../models/warranty';

import * as actions from '../actions/warranty-list';


// State interface definition: maintains a list of Warranty models, a current
// Warranty (for editing), WarrantyStats and PDF rendering state
export interface State {

  // List of Warranty models for the User, pending flag and error/success
  // message
  warranties: Warranty[];
  pending:    boolean;
  error:      string;
  message:    string;

  pageNum:    number;
  totalPages: number;
  upcoming:   boolean;

  // Warranty to be edited and pending flag
  warranty:        Warranty;
  warrantyPending: boolean;

  // Current statistics for display in page
  stats: WarrantyStats;

  // PDF rendering state ("pdfData" is base64-encoded PDF file contents when
  // the PDF has been rendered)
  pdfPending: boolean;
  pdfError:   string;
  pdfData:    string;
}


const defaultState: State = {
  error:           null,
  message:         null,
  pdfData:         null,
  pdfError:        null,
  pdfPending:      false,
  pending:         false,
  stats:           null,
  warranties:      [],
  warranty:        null,
  warrantyPending: false,
  pageNum:         1,
  totalPages:      0,
  upcoming:        false,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Resets the state ready for editing a Warranty
     */
    case actions.INIT_WARRANTY_REQUEST:
      return Object.assign({}, state, {warrantyPending: false, warranty: null});

    /**
     * Request to fetch a specified Warranty
     */
    case actions.FETCH_WARRANTY_REQUEST:
      return Object.assign({}, state, {warrantyPending: true, warranty: null});

    /**
     * Response from fetching a specified Warranty
     */
    case actions.FETCH_WARRANTY_RESPONSE:
      return Object.assign({}, state, {
        warrantyPending:    false,
        warranty:   action.payload.error ? state.warranty : action.payload.warranty,
      });

    /**
     * Request to fetch a list of Warranties
     */
    case actions.FETCH_WARRANTIES_REQUEST:
      return Object.assign({}, state, {pending: true, warranty: null, pageNum: action.payload.pageNum});

    /**
     * Response from fetching a list of Warranties
     */
    case actions.FETCH_WARRANTIES_RESPONSE:
      return Object.assign({}, state, {
        pending:    false,
        error:      action.payload.error,
        stats:      action.payload.error ? state.stats      : action.payload.stats,
        warranties: action.payload.error ? state.warranties : action.payload.items,
        totalPages: action.payload.totalPages,
      });

    /**
     * Request to render and return a PDF for a specified Warranty ID
     */
    case actions.FETCH_WARRANTY_PDF_REQUEST:
      return Object.assign({}, state, {pdfPending: true, pdfError: null, pdfData: null});

    /**
     * Response from request to render a specified Warranty as a PDF. Upon
     * success, response contains base64-encoded PDF data.
     */
    case actions.FETCH_WARRANTY_PDF_RESPONSE:
      return Object.assign({}, state, {
        pdfPending: false,
        pdfError:   action.payload.error,
        pdfData:    action.payload.pdfData,
      });

    /**
     * Request to update an existing Warranty
     */
    case actions.UPDATE_WARRANTY_REQUEST:
      return Object.assign({}, state, {pending: true});

    /**
     * Response from updating an existing Warranty
     */
    case actions.UPDATE_WARRANTY_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        warranties: action.payload.error
          ? state.warranties
          : state.warranties.map((w: Warranty) => w.id === action.payload.warranty.id ? action.payload.warranty : w),
      });

    default:
      return state;
  }
}
