<!--<form [formGroup]="fg" class="form-inline" novalidate (ngSubmit)="onSubmitForm()">-->
    <!---->
<!--</form>-->

<form [formGroup]="fg" class="form-inline" novalidate (ngSubmit)="onSubmitForm()">

    <div class="row content-page">
        <div class="col-md-7 col-md-offset-2 optin-container">
            <h1>Contact Preferences</h1>

            <p>&nbsp;</p>
            <p>As a ProClub member, not only will you have access to the benefits of the ProClub membership, the ProClub website and ProClub app, Adey will also provide you with a variety of topical and interesting information on a regular basis. This is your chance to let us know what information you would like to receive and how you would like to receive it.</p>
            <p>&nbsp;</p>

            <p>I'd like to hear about other goods and services Adey offers that are similar to those that I have already purchased or enquired about</p>
            <p>&nbsp;</p>
            <mat-checkbox md-no-ink class="md-primary" formControlName="offers_by_email">
                Yes, by email
            </mat-checkbox>

            <mat-checkbox md-no-ink class="md-primary" formControlName="offers_by_sms">
                Yes, by SMS
            </mat-checkbox>

            <mat-checkbox md-no-ink class="md-primary" formControlName="offers_by_telephone">
                Yes, by telephone
            </mat-checkbox>

            <p>&nbsp;</p>
            <p>I’d like to get information about Adey’s other goods, services or events that may be of interest to me</p>
            <p>&nbsp;</p>

            <mat-checkbox md-no-ink class="md-primary" formControlName="information_by_email">
                Yes, by email
            </mat-checkbox>

            <mat-checkbox md-no-ink class="md-primary" formControlName="information_by_sms">
                Yes, by SMS
            </mat-checkbox>

            <mat-checkbox md-no-ink class="md-primary" formControlName="information_by_telephone">
                Yes, by telephone
            </mat-checkbox>

            <p>&nbsp;</p>

            <!--<p>Are you happy for ADEY to publish your Company name, contact telephone number and email address on our 'Find an Installer' section on <a href="https://adey.com" target="_blank" title="Adey">adey.com</a> for Customers to contact you for business? <span style="color: red;">*</span></p>-->
            <!--<div class="radio-buttons">-->
                <!--<input type="radio" value="yes" id="new_filter" formControlName="add_to_find_an_installer" name="add_to_find_an_installer">-->
                <!--<label style="margin-right: 10px;">Yes</label>-->

                <!--<input type="radio" value="no" id="existing_filter" formControlName="add_to_find_an_installer" name="add_to_find_an_installer">-->
                <!--<label>No</label>-->
            <!--</div>-->

            <!--<div class="alert alert-danger" style="margin-bottom: 0px; margin-top: 10px;" *ngIf="errors">-->
                <!--<p>{{ errors }}</p>-->
            <!--</div>-->

            <!--<p>&nbsp;</p>-->
            <p>If you don’t want to receive anything, just leave the above tick boxes blank. If you do want to hear about any of the above you can change your mind at any point, ask us to stop contacting you, and we will do so without delay. Rest assured, all personal data we collect is held in accordance with our Privacy Policy, a copy of which can be found here <a href="https://www.adey.com/privacy-policy" title="Privacy Policy">https://www.adey.com/privacy-policy</a></p>
            <p>&nbsp;</p>

            <input type="submit" class="btn btn-primary feature-btn branding-color-white branding-background-adey-blue" value="Save Preferences" />
        </div>
    </div>
</form>

