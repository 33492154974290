import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';

declare var require: any;
export const packageJson = {
  VERSION: require('../../../package.json').version,
};

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() {
    if (environment.production) {
      Sentry.init({
        dsn: 'https://6894a2c29f8d4d46b4bbc2bfd30315ee@sentry.behold.dev/5',
        environment: environment.name,
        release: `adey-web-portal@${packageJson.VERSION}`,
      });
    }
  }

  public handleError(error: any): void {
    try {
      // We can prompt the user for more information leading up to the error but this can sometimes fail
      const eventId = Sentry.captureException(error.originalError || error);
      Sentry.showReportDialog({ eventId });
    } catch (e) { }
  }
}
