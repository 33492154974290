import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchPageContentResponse, UpdatePageContentResponse } from '../../models/content-pages';
import { AdminService } from '../../services/admin.service';
import { ContentService } from '../../services/content.service';
import * as ActionTypes from '../actions/content-pages';

@Injectable()
export class ContentPagesEffects {
  constructor(
    private adminService: AdminService,
    private contentService: ContentService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchPageContentRequestAction, call
   * ContentService::fetchPageContent() and dispatch a new
   * FetchPageContentResponseAction with the response.
   */
   fetchPageContent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_PAGE_CONTENT_REQUEST),
    switchMap((req: ActionTypes.FetchPageContentRequestAction): Observable<ActionTypes.FetchPageContentResponseAction> =>
      this.contentService.fetchPageContent().pipe(
        map((res: FetchPageContentResponse): ActionTypes.FetchPageContentResponseAction =>
          new ActionTypes.FetchPageContentResponseAction(res)
        ))
    )));

  /**
   * For an UpdatePageContentRequestAction, call
   * AdminService::updatePageContent() and dispatch a new
   * UpdatePageContentResponseAction with the response.
   */
   updatePageContent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_PAGE_CONTENT_REQUEST),
    switchMap((req: ActionTypes.UpdatePageContentRequestAction): Observable<ActionTypes.UpdatePageContentResponseAction> =>
      this.adminService.updatePageContent(req.payload).pipe(
        map((res: UpdatePageContentResponse): ActionTypes.UpdatePageContentResponseAction =>
          new ActionTypes.UpdatePageContentResponseAction(res)
        ))
    )));
}
