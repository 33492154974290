<div *ngIf="userProfile" [class]="'profile-badge border-box border-light-gray ' + (isWidget ? 'widget' : 'page')">
  <div class="ie-image-patch profile-photo transform-y-offset-50" *ngIf="userProfile.photoUrl"
    [style.backgroundImage]="'url(' + userProfile.photoUrl + ')'">
    <img [src]="userProfile.photoUrl" />
  </div>
  <div class="profile-photo-placeholder transform-y-offset-50 background-lighter-gray color-white align-center"
    *ngIf="!userProfile.photoUrl">
    <i class="icon-user"></i>
  </div>
  <div class="content col-sm-7 col-xs-12">
    <h2 class="color-light-gray user-name">{{userProfile.firstName + ' ' + userProfile.lastName}}</h2>
    <p class="medium membership-mobile" (click)="goToPage('/rewards')">{{userProfile.memberLevel}}</p>
    <p class="color-gray" *ngIf="userProfile.createdAt">
      Member for {{getMembershipLength(userProfile.createdAt)}}
    </p>
    <h3 class="color-adey-red medium no-margin">{{userProfile.pointsCurrent}} Points</h3>
    <button class="btn btn-link no-pad color-adey-red" (click)="editProfile(userProfile, accreditations)">Edit
      profile</button>
  </div>
  <div class="profile-level-container pull-right" (click)="goToPage('/rewards')">
    <div class="profile-level-badge background-light-gray color-adey-gray align-center">
      <i class="icon-parts"></i>
      <p>{{userProfile.memberLevel}}</p>
    </div>
  </div>
</div>