import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';

/**
 * Summary
 *    Placeholder training page
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }

  /**
   * Navigates to the specified route
   *
   * @param {string} page Route to which to navigate
   */
  goToPage(page: string) {
    this.router.navigate([page]);
  }
}
