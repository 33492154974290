<div class="widget-body border-box border-lighter-gray warranty-widget background-white col-xs-12">
  <img class="feature-image-left" src="assets/i/widgets/magnaclean.png">
  <div class="content">
    <h2 class="color-gray">Need to register a job or warranty?</h2>
    <h2 class="color-adey-red">Sign it up below...</h2>
  </div>
  <div class="widget-button-container">
    <button class="btn background-green no-radius color-white" (click)="goToPage('/submission-selection')">Register</button>
  </div>
</div>
