<div class="profile-photo-upload">

  <div class="ie-image-patch photo-img" *ngIf="initialUrl && !photoData" [style.backgroundImage]="'url(' + initialUrl + ')'">
    <img [src]="initialUrl"  alt="Profile photo" />
  </div>
  <div class="ie-image-patch photo-img" *ngIf="photoData" [style.backgroundImage]="'url(' + photoData + ')'">
    <img [src]="photoData" alt="Profile photo" />
  </div>

  <input
    type="file"
    id="files"
    placeholder="Profile photo image"
    (change)="handleFile($event)"
    *ngIf="fileAPISupported"
  />
  <label for="files" class="btn background-green color-white">Upload profile photo</label>

  <button class="btn btn-danger" *ngIf="photoData" (click)="resetUpload()">Reset</button>

  <app-message-panel kind="ERROR" *ngIf="!fileAPISupported">
    Unfortunately your browser does not provide support for updating your
    profile photo.
  </app-message-panel>

  <app-message-panel kind="ERROR" *ngIf="errors">
    {{errors}}
  </app-message-panel>

</div>
