<div [class]="'background-white ' + (!widget ? 'content-item' : 'widget-item') + ' ' + (resourceItem.attachment && resourceItem.attachment.itemType === 'VIDEO' ? 'video-feature' : '')">
  <div 
      class="col-xs-4 featured-image no-pad background-white " 
      *ngIf="resourceItem.imageUrl"
      (click)="openVideoPlayer(resourceItem.attachment)">
    <div class="ie-image-patch full" [style.backgroundImage]="'url(' + resourceItem.imageUrl + ')'">
      <img [src]="resourceItem.imageUrl">
    </div>
    <i class="fa fa-play-circle-o play-icon color-white transform-xy-offset-50" *ngIf="resourceItem.attachment && resourceItem.attachment.itemType === 'VIDEO'"></i>
  </div>
  <div [class]="'background-white content-box col-xs-12 ' + (resourceItem.imageUrl || !widget ? 'col-sm-8' : 'col-sm-12')">
    <div class="content" *ngIf="!widget">
      <h2 class="color-adey-red">{{resourceItem.title}}</h2>
      <p>{{resourceItem.description}}</p>
    </div>
    <app-file-download
      *ngIf="resourceItem.attachment && resourceItem.attachment.itemType === 'DOWNLOAD'"
      [fileURL]="resourceItem.attachment.siteUrl ? resourceItem.attachment.siteUrl : resourceItem.attachment.url"
      [fileType]="resourceItem.attachment.contentType"
      [fileName]="resourceItem.attachment.downloadName"
      btnText="Download"
      [btnClass]="'color-white full-width no-radius ' + (widget ? 'widget background-adey-red' : 'background-green')"
      [downloadBlob]="false"
    >
    </app-file-download>

    <app-video
      *ngIf="resourceItem.attachment && resourceItem.attachment.itemType === 'VIDEO' && ((!resourceItem.imageUrl && !widget) || (resourceItem.imageUrl && widget))"
      [videoUrl]="resourceItem.attachment.url"
      buttonText="Watch Video"
      buttonClass="widget"
    >
    </app-video>

  </div>
</div>
