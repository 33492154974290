export class Country {
  id: string;
  country_code: string;
  country_name: string;
  enabled: boolean;
}

export class CountryResponse {

  countries: Country[];
  error?: string;

  static fromApiData(apiData: any): Country {
    const country: Country = new Country();

    country.id = apiData.id;
    country.country_code = apiData.country_code;
    country.country_name = apiData.country_name;
    country.enabled = apiData.enabled;

    return country;
  }
}
