/**
 * admin-resources actions: defines all actions relating to the resource
 * management admin CMS.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AddAdminResourceRequest,
  AddAdminResourceResponse,
  DeleteAdminResourceRequest,
  DeleteAdminResourceResponse,
  FetchAdminResourcesRequest,
  FetchAdminResourcesResponse,
  UpdateAdminResourceRequest,
  UpdateAdminResourceResponse,
} from '../../models/admin-resources';

import {
  ResourceItem,
} from '../../models/resources';


// Action type definitions
export const ADD_ADMIN_RESOURCE_REQUEST     = 'ADD_ADMIN_RESOURCE_REQUEST';
export const ADD_ADMIN_RESOURCE_RESPONSE    = 'ADD_ADMIN_RESOURCE_RESPONSE';
export const DELETE_ADMIN_RESOURCE_REQUEST  = 'DELETE_ADMIN_RESOURCE_REQUEST';
export const DELETE_ADMIN_RESOURCE_RESPONSE = 'DELETE_ADMIN_RESOURCE_RESPONSE';
export const FETCH_ADMIN_RESOURCES_REQUEST  = 'FETCH_ADMIN_RESOURCES_REQUEST';
export const FETCH_ADMIN_RESOURCES_RESPONSE = 'FETCH_ADMIN_RESOURCES_RESPONSE';
export const UPDATE_ADMIN_RESOURCE_REQUEST  = 'UPDATE_ADMIN_RESOURCE_REQUEST';
export const UPDATE_ADMIN_RESOURCE_RESPONSE = 'UPDATE_ADMIN_RESOURCE_RESPONSE';


// Action definitions
export class AddAdminResourceRequestAction implements Action {
  readonly type = ADD_ADMIN_RESOURCE_REQUEST;
  constructor(public payload: AddAdminResourceRequest) {}
}

export class AddAdminResourceResponseAction implements Action {
  readonly type = ADD_ADMIN_RESOURCE_RESPONSE;
  constructor(public payload: AddAdminResourceResponse) {}
}

export class DeleteAdminResourceRequestAction implements Action {
  readonly type = DELETE_ADMIN_RESOURCE_REQUEST;
  constructor(public payload: DeleteAdminResourceRequest) {}
}

export class DeleteAdminResourceResponseAction implements Action {
  readonly type = DELETE_ADMIN_RESOURCE_RESPONSE;
  constructor(public payload: DeleteAdminResourceResponse) {}
}

export class FetchAdminResourcesRequestAction implements Action {
  readonly type = FETCH_ADMIN_RESOURCES_REQUEST;
  constructor(public payload: FetchAdminResourcesRequest) {}
}

export class FetchAdminResourcesResponseAction implements Action {
  readonly type = FETCH_ADMIN_RESOURCES_RESPONSE;
  constructor(public payload: FetchAdminResourcesResponse) {}
}

export class UpdateAdminResourceRequestAction implements Action {
  readonly type = UPDATE_ADMIN_RESOURCE_REQUEST;
  constructor(public payload: UpdateAdminResourceRequest) {}
}

export class UpdateAdminResourceResponseAction implements Action {
  readonly type = UPDATE_ADMIN_RESOURCE_RESPONSE;
  constructor(public payload: UpdateAdminResourceResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | AddAdminResourceRequestAction
  | AddAdminResourceResponseAction
  | DeleteAdminResourceRequestAction
  | DeleteAdminResourceResponseAction
  | FetchAdminResourcesRequestAction
  | FetchAdminResourcesResponseAction
  | UpdateAdminResourceRequestAction
  | UpdateAdminResourceResponseAction;
