import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/onboarding-questions';
import * as fromOnboardingQuestions from '../reducers/onboarding-questions';
import {selectOnboardingQuestionsAnswers} from './login-details';

export const selectOnboardingQuestionsState = createFeatureSelector<State>('onboardingQuestions');

export const selectOnboardingQuestions = createSelector(
  selectOnboardingQuestionsState,
  fromOnboardingQuestions.selectAll
);

export const selectOutstandingOnboardingQuestions = createSelector(
  selectOnboardingQuestionsAnswers,
  selectOnboardingQuestions,
  (answers, questions) => {
    try {
      Object.keys(answers).forEach((answer) => {
        const questionIndex = questions.findIndex(x => x.id === answer);

        questions.splice(questionIndex, 1);
      });

      return questions;

    } catch (error) {
      return null;
    }
  },
);

export const selectHasOutstandingOnboardingQuestions = createSelector(
  selectOutstandingOnboardingQuestions,
  (questions) => {
    try {
      if (questions) {
        return questions.length > 0;
      } else {
        return true;
      }
    } catch (error) {
      return null;
    }
  }
);
