<div class="container-fluid">
  <div class="row content-page" *ngIf="state$ | async; let state">

    <div class="container-title">
      <div class="service-title">
        <h1 class="white">Job record dashboard <i class="decor icon-network"></i></h1>
        <app-page-content page="SERVICE_RECORDS" field="intro" class="color-white intro"></app-page-content>
        <h2 class="cur-date color-adey-red">{{currentDate | date}}</h2>
      </div>
      <div class="services-quick-info">
        <div class="info-title background-beige align-center">
          <span class="color-gray">Services</span>
        </div>
        <div class="info-container background-white align-center">
          <div class="border-box border-light-gray" *ngIf="state.pendingStats">
            <app-waiting-spinner kind="spinner">Fetching statistics...</app-waiting-spinner>
          </div>
          <div class="border-box border-light-gray" *ngIf="!state.pendingStats && !state.stats">
            <p>No job record statistics to display</p>
          </div>
          <div class="border-box border-light-gray" *ngIf="!state.pendingStats && state.stats">
            <div class="info-block background-adey-blue">
              <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-white.png">
              <h2 class="color-white">{{state.stats.completed}}</h2>
              <p class="color-white">Services Completed</p>
            </div>
            <div class="info-block background-adey-red">
              <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-white.png">
              <h2 class="color-white">{{state.stats.pending}}</h2>
              <p class="color-white">Services Pending</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="services-list background-white col-xs-12 no-pad">
      <div class="list-title background-beige align-center">
        <span class="color-gray">Record</span>
      </div>
      <div class="list-items background-white">
        <!--<div class="search-container">
          <form class="form-inline" [formGroup]="fgQuery" novalidate (ngSubmit)="setCustomerQuery($event)">
            <span>Filter by: </span>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="name" placeholder="Customer name" />
            </div>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="name" placeholder="Customer postcode" />
            </div>
            <div class="form-group">
              <select formControlName="status" class="form-control">
                <option value="">Service status</option>
                <option value="incomplete">Incomplete</option>
                <option value="complete">Complete</option>
              </select>
            </div>
            <div class="form-group">
              <input [matDatepicker]="datePicker" class="form-control" formControlName="nextService" placeholder="Next service date"/>
              <mat-datepicker-toggle [for]="datePicker" class="date-picker-button"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
            </div>
            <div class="form-group">
              <input class="btn background-green color-white no-radius full-width" type="submit" value="Search" />
            </div>

          </form>
        </div>-->
        <div *ngIf="state.pending">
          <app-waiting-spinner kind="spinner">Fetching job records...</app-waiting-spinner>
        </div>

        <app-message-panel kind="ERROR" *ngIf="state.error">{{state.error}}</app-message-panel>

        <!--<form
          class="form-inline"
          [formGroup]="fgQuery"
          novalidate
          *ngIf="!state.pending"
          (ngSubmit)="setCustomerQuery($event)"
        >
          <div class="form-group">
            <input class="form-control" formControlName="customerQuery" placeholder="Filter by customer name" />
          </div>
          <div class="form-group">
            <input class="btn btn-default" type="submit" value="Filter" />
          </div>
        </form>-->

        <div *ngIf="!state.pending && state.records && state.records.length === 0">
          <p>No job records found</p>
        </div>

        <div class="table-container" *ngIf="!state.pending && state.records && state.records.length > 0">
          <div class="row pagination-wrapper">
            <div class="col-xs-9 text-center">
              <ul class="pagination">
                <!-- / Next btns -->
                <li *ngIf="state.currentPage > 1" class="page-item">
                  <a class="page-link" (click)="fetchRecords()">&lt;&lt; First</a>
                </li>

                <li *ngIf="state.currentPage > 1" class="page-item">
                  <a class="page-link" (click)="prevFivePages()">&lt;&lt;</a>
                </li>

                <li *ngIf="state.currentPage > 1" class="page-item">
                  <a class="page-link" (click)="prevPage()">&lt;</a>
                </li>
                <!-- / Next btns -->

                <!-- Pagination -->
                <li *ngFor="let num of pagination" [class.active]="num === state.currentPage" class="page-item">
                  <a class="page-link" (click)="fetchRecords(num)">{{ num }}</a>
                </li>
                <!-- / Pagination -->


                <!-- / Next btns -->
                <li *ngIf="state.currentPage < state.totalPages" class="page-item">
                  <a class="page-link" (click)="nextPage()">&gt;</a>
                </li>

                <li *ngIf="state.currentPage < state.totalPages" class="page-item">
                  <a class="page-link" (click)="nextFivePages()">&gt;&gt;</a>
                </li>

                <li *ngIf="state.currentPage < state.totalPages" class="page-item">
                  <a class="page-link" (click)="fetchRecords(state.totalPages)">Last &gt;&gt;</a>
                </li>
                <!-- / Next btns -->
              </ul>
            </div>

            <div class="col-xs-3">
              <form [formGroup]="fgFilters" novalidate>
                <mat-form-field>
                  <mat-select formControlName="orderBy">
                    <mat-option [value]="false">Sort by: Newest</mat-option>
                    <mat-option [value]="true">Sort by: Oldest</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </div>


          <table class="item-table">
            <tr class="no-pad border-none">
              <th>
                <div class="row-title align-center background-blue-black color-white">
                  <div class="content">
                    <i class="icon-user"></i>
                    <p class="no-margin">Customer name</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center background-blue-black color-white">
                  <div class="content">
                    <i class="icon-home"></i>
                    <p class="no-margin">Customer postcode</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center background-blue-black color-white">
                  <div class="content">
                    <i class="icon-date"></i>
                    <p class="no-margin">Date of next service</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center background-blue-black color-white">
                  <div class="content">
                    <i class="icon-timer"></i>
                    <p class="no-margin">Status of service</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center background-blue-black color-white">
                  <div class="content">
                    <i class="icon-date"></i>
                    <p class="no-margin">Warranty expiry date</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-adey-red border color-blue-black">
                  <div class="content">
                    <i class="icon-mc3"></i>
                    <p class="no-margin">MC3+ or MC5 used?</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-blue border color-blue-black">
                  <div class="content">
                    <i class="icon-flush"></i>
                    <p class="no-margin">MagnaCleanse flush?</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-orange border color-blue-black">
                  <div class="content">
                    <i class="icon-fitted"></i>
                    <p class="no-margin">MagnaClean fitted?</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-yellow border color-blue-black">
                  <div class="content">
                    <i class="icon-mc1"></i>
                    <p class="no-margin">MC1 used?</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-green border color-blue-black">
                  <div class="content">
                    <i class="icon-water-test"></i>
                    <p class="no-margin">Water test complete?</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center border-purple border color-blue-black">
                  <div class="content">
                    <i class="icon-service-complete"></i>
                    <p class="no-margin">Service complete?</p>
                  </div>
                </div>
              </th>
            </tr>
            <tr [class]="'border-lighter-gray table-data' + (sr.confirmed ? '' : ' unconfirmed') + (calculateExpiry(sr.warranty).valid? '' : 'expired')" *ngFor="let sr of state.records">
              <td class="color-dark-gray">
                <app-waiting-spinner kind="spinner" *ngIf="!sr.confirmed" [spinnerSize]="25" [spinnerStroke]="2"></app-waiting-spinner>
                <span
                  class="customer-name"
                  *ngIf="sr.confirmed"
                  (click)="handleClickRecord(sr)"
                > {{sr.customer.customerTitle}} {{sr.customer.customerSurname}}</span>
              </td>
              <td class="color-dark-gray">{{sr.customer.customerPostcode}}</td>
              <td class="color-dark-gray">
                <span *ngIf="sr.lastServiceDate">{{ sr.lastServiceDate | date }}</span>
              </td>
              <td class="color-dark-gray">{{sr.lastServiceStatus}}</td>
              <td class="color-dark-gray expiry-date">
                <span *ngIf="sr.job !== null && sr.job.filterExpiryDate !== null">
                  {{ formatDateForSafari(sr.job?.filterExpiryDate) | date }}
                </span>
              </td>
              <td class="color-adey-red">
                <i
                  *ngIf="sr.type === 'JOB'"
                  (click)="toggleFlag(sr, sr.flags, 'mc3Used', 'MC3 + used?', 'mc3EditFields')"
                  [class]="'icon-check'+ (sr.flags.mc3Used ? '' : ' disabled')"
                ></i>
                <i class="fa fa-minus disabled" *ngIf="sr.type === 'WARRANTY'"></i>
              </td>
              <td class="color-blue">
                <i
                  *ngIf="sr.type === 'JOB'"
                  (click)="toggleFlag(sr, sr.flags, 'magnaCleanseFlush', 'MagnaCleanse flush?')"
                  [class]="'icon-check'+ (sr.flags.magnaCleanseFlush ? '' : ' disabled')"
                ></i>
                <i class="fa fa-minus disabled" *ngIf="sr.type === 'WARRANTY'"></i>
              </td>
              <td class="color-orange">
                <i
                  (click)="toggleFlag(sr, sr.flags, 'magnaCleanFitted', 'MagnaClean fitted?', 'filterEditFields')"
                  [class]="'icon-check' + (sr.flags.magnaCleanFitted ? '' : ' disabled')"
                ></i>
              </td>
              <td class="color-yellow">
                <i
                  *ngIf="sr.type === 'JOB'"
                  (click)="toggleFlag(sr, sr.flags, 'mc1Used', 'MC1 used?', 'mc1EditFields')"
                  [class]="'icon-check' + (sr.flags.mc1Used ? '' : ' disabled')"
                ></i>
                <i class="fa fa-minus disabled" *ngIf="sr.type === 'WARRANTY'"></i>
              </td>
              <td class="color-green">
                <i
                  *ngIf="sr.type === 'JOB'"
                  (click)="toggleFlag(sr, sr.flags, 'waterTestComplete', 'Water test complete?', 'waterTestFields')"
                  [class]="'icon-check' + (sr.flags.waterTestComplete ? '' : ' disabled')"
                ></i>
                <i class="fa fa-minus disabled" *ngIf="sr.type === 'WARRANTY'"></i>
              </td>
              <td class="color-purple">
                <i
                  *ngIf="sr.type === 'JOB'"
                  (click)="toggleFlag(sr, sr.flags, 'serviceComplete', 'Service complete?', 'serviceCompleteEditFields')"
                  [class]="'icon-check' + (sr.flags.serviceComplete ? '' : ' disabled')"
                ></i>
                <i class="fa fa-minus disabled" *ngIf="sr.type === 'WARRANTY'"></i>
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>

  </div>
</div>
