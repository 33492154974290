<h2 matDialogTitle *ngIf="!printMode && viewMode">View warranty</h2>
<h2 matDialogTitle *ngIf="!printMode && !viewMode">Edit warranty</h2>
<h2 matDialogTitle *ngIf="printMode">Download/print warranty</h2>

<mat-dialog-content>

  <app-waiting-spinner kind="bar" *ngIf="(state$ | async).warrantyPending">Loading Warranty...</app-waiting-spinner>

  <app-warranty-details
    *ngIf="!printMode && (data.warrantyId && (state$ | async).warranty) || !data.warrantyId"
    [productTypes]="productTypes$ | async"
    [adminMode]="adminMode"
    [defaultUserId]="data.userId"
    [readOnly]="viewMode"
    [showSubmit]="false"
    [warranty]="(state$ | async).warranty"
    (onSubmit)="onSubmitForm($event)"
  ></app-warranty-details>

  <app-printable-warranty
    *ngIf="printMode"
    [warranty]="(state$ | async).warranty"
  ></app-printable-warranty>

  <app-message-panel kind="ERROR" *ngIf="(state$|async).pdfError">{{(state$|async).pdfError}}</app-message-panel>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button *ngIf="!printMode && viewMode" (click)="viewMode = false">Edit</button>
  <button mat-button *ngIf="!printMode && !viewMode" (click)="onSaveClick($event)">Save changes</button>

  <ng-container *ngIf="state$ | async; let state">

    <button mat-button *ngIf="!printMode" (click)="showPrintVersion(state.warranty)">Download/print</button>
    <button mat-button *ngIf="printMode" (click)="hidePrintVersion()">Back</button>

    <a
      mat-button
      *ngIf="printMode && !ieDownloadMode && pdfUrl"
      [href]="pdfUrl"
      [download]="getDownloadFilename(state.warranty)"
    >
      Download
    </a>

    <button
      mat-button
      *ngIf="printMode && ieDownloadMode && pdfUrl"
      (click)="ieDownload(state.warranty)"
    >
      Download
    </button>

    <app-waiting-spinner
      type="spinner"
      [spinnerSize]="25"
      [spinnerStroke]="2.5"
      *ngIf="state.pdfPending"
    >
      Generating PDF...
    </app-waiting-spinner>

  </ng-container>

  <button mat-button *ngIf="printMode" (click)="printView()">Print</button>
  <button mat-button [matDialogClose]="false">Cancel</button>
</mat-dialog-actions>
