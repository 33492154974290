<div class="container-fluid register-component" *ngIf="reviewInvitation$ | async;let invitation">
  <div class="row">
    <a href="/">
      <img src="/assets/i/common/logos/adey-pro-check-logo-r.png" alt="ADEY ProCheck Logo" class="top-right-logo proCheckLogo">
    </a>
    <a href="/" class="nav-home-btn">Back to Login</a>
    <div class="col-xs-12 register-container">
      <div  class="col-lg-12" >
        <h1>{{pageTitle}}</h1>
        <br>
        <div class="col-lg-6">
          <app-waiting-spinner kind="bar" *ngIf="invitation.pending" class="col-xs-12">{{invitation.message}}</app-waiting-spinner>
        </div>
        <br>
        <div *ngIf="!invitation.pending">
          <div *ngIf="error_terms_portal || error_policy || error_terms_app || form_errors || (invitation.message && !invitation.invitation_accepted)" class="alert alert-danger message-size">
            {{error_terms_portal || error_policy || error_terms_app || form_errors || invitation.message}}
          </div>
          <div *ngIf="invitation.invitation_accepted" class="alert alert-success message-size">
            {{invitation.message}}
          </div>
          <button *ngIf="invitation.invitation_accepted" class="btn btn-primary" (click)="onConfirmSuccess()">Return to login page</button>
          <div *ngIf="invitation.results && !invitation.invitation_accepted" >
            <p>You have been invited by <strong>{{invitation.results['invited_by']['first_name']}}</strong> to work as a <strong><span *ngIf="invitation.results['role']==5">Admin </span>water tester</strong>
              <span *ngIf="invitation.results['hosted_company']['name']">for <strong>{{invitation.results['hosted_company']['name']}}</strong></span>.
            </p>
            <!--          <p><strong>Description:</strong> Water tester.</p>-->
            <p>Upon accepting this invitation, this will replace any existing roles you may already have had.</p>
            <br>
            <form [formGroup]="reviewInvitationForm" novalidate (ngSubmit)="onSubmitForm()">
              <div *ngIf="invitation.results['activation_code']" [class]="formGroupClass('activationCode')">
                <label class="control-label">Activation code<span class="color-adey-red">*</span></label>
                <input  type="text" required="required" class="form-control input-medium" formControlName="activationCode"/>
              </div>
              <br>
              <!-- t&c's -->
              <div [class]="formGroupClass('termsAndConditionsConfirm')">
                <mat-checkbox md-no-ink class="md-primary" formControlName="termsAndConditionsConfirm">
                  Confirm that you agree to our <a href="/tc-portal-login" target="_blank">Terms &amp; Conditions</a> *
                </mat-checkbox>
              </div>
              <div [class]="formGroupClass('dataProtectionConfirm')">
                <mat-checkbox md-no-ink class="md-primary" formControlName="dataProtectionConfirm">
                  Confirm that you agree to our <a href="http://www.adey.com/privacy-policy" target="_blank">Privacy Policy</a> *
                </mat-checkbox>
              </div>
              <div [class]="formGroupClass('termsAndConditionsConfirmApp')">
                <mat-checkbox md-no-ink class="md-primary" formControlName="termsAndConditionsConfirmApp">
                  Confirm you agree to our <a href="/tc-app-login" target="_blank">Terms & Conditions - App</a> *
                </mat-checkbox>
              </div>
              <br>
              <button type="submit" class="btn btn-full-width background-adey-red color-white white-theme">
                Accept Invitation
              </button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
