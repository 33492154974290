<div id="pdf" *ngIf="warranty">
	<div class="logo-div">
		<img src="assets/i/pdf/adey-logo.png" class="logo-img" alt="ADEY Logo"/>
	</div>

	<div class="header-div">
		<h1 class="header-title">ADEY SERVICE / WARRANTY DETAILS</h1>
	</div>

	<div>
		<p *ngIf="warranty.user">
			<span class="detail-bold">Installer Name: </span>{{warranty.user.title + ' ' + warranty.user.firstName + ' ' + warranty.user.lastName}}<br>
			<span class="detail-bold">Email address: </span>{{warranty.user.email}}<br>
			<span class="detail-bold">Telephone number: </span>{{warranty.user.telephone}}
		</p>

		<p>
			<span class="detail-bold">Customer Name: </span> {{warranty.customer.customerTitle + ' ' + warranty.customer.customerSurname}}<br>
      
      <span class="detail-bold">Address 1: </span>{{ warranty.customer.customerAddress1 }}
      <br *ngIf="warranty.customer.customerAddress2"/>
      <span *ngIf="warranty.customer.customerAddress2" class="detail-bold">Address 2: </span>{{ warranty.customer.customerAddress2 }}
      <br *ngIf="warranty.customer.customerTown" />
      <span *ngIf="warranty.customer.customerTown" class="detail-bold">Town/City: </span>{{ warranty.customer.customerTown }}
      <br *ngIf="warranty.customer.customerCounty" />
      <span *ngIf="warranty.customer.customerCounty" class="detail-bold">County/State: </span>{{ warranty.customer.customerCounty }}
      <br/>

			<span class="detail-bold">Postcode: </span>{{warranty.customer.customerPostcode}}
		</p>
	</div>

	<div>
		<p>
      This is a record of a warranty registered on
      <span class="detail-bold">{{getDate(warranty.dateOfInstallation)}}</span>
      by the above named company.
    </p>
	</div>

  <p>
    <span class="detail-bold">Product type: </span>{{warranty.productType}}
  </p>
  <p>
    <span class="detail-bold">Serial number: </span>{{warranty.serialNumber}}
  </p>
  <p>
    <span class="detail-bold">Installation date: </span> {{getDate(warranty.dateOfInstallation)}}
  </p>
  <p>
    <span class="detail-bold">Expiry date: </span> {{getDate(warranty.expiryDate)}}
  </p>
  <p>
    <span class="detail-bold">Boiler brand: </span> {{warranty.boilerId}}
  </p>
  <p>
    <span class="detail-bold">Boiler warranty expiry date (year): </span> {{warranty.warrantyLength}}
  </p>
  <p>
    <span class="detail-bold">Old filter brand (if applicable): </span> {{warranty.oldFilterBrand}}
  </p>
</div>
