/**
 * customer models
 */

import { UserProfile } from './user-profile';


/**
 * Encapsulates a single customer record for Jobs, Warranties, etc.
 */
export class Customer {
  customerId?:       number;
  customerTitle:     string;
  customerForename?:  string;
  customerSurname?:  string;
  customerAddress1?: string;
  customerAddress2?: string;
  customerTown?:     string;
  customerPhone?:    string;
  customerCounty?:   string;
  customerPostcode?: string;
  customerEmail?:    string;

  customerContactByPost?:   boolean;
  customerContactByEmail?:  boolean;
  customerContactByPhone?:  boolean;

  save_on_behalf_of?: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {Customer}
   */
  static fromFormData(formData: any): Customer {
    const c: Customer = new Customer();

    c.customerId        = formData.customerId;
    c.customerTitle     = formData.customerTitle;
    // Don't send a null forename to the API
    if (formData.customerForename) {
      c.customerForename  = formData.customerForename;
    }
    c.customerSurname   = formData.customerSurname;
    c.customerAddress1  = formData.customerAddress1;
    c.customerAddress2  = formData.customerAddress2;
    c.customerTown      = formData.customerTown;
    c.customerPhone     = formData.customerPhone;
    c.customerCounty    = formData.customerCounty;
    c.customerPostcode  = formData.customerPostcode;
    c.customerEmail     = formData.emails.customerEmail;

    c.customerContactByPost   = formData.customerContactByPost;
    c.customerContactByEmail  = formData.customerContactByEmail;
    c.customerContactByPhone  = formData.customerContactByPhone;

    return c;
  }

  /**
   * Converts an existing model instance into data ready to be loaded into an
   * edit form
   *
   * @param {Customer} appData
   * @return {object}
   */
  static toFormData(appData: Customer): any {
    return {
    customerId:       appData.customerId,
    customerTitle:    appData.customerTitle,
    customerForename: appData.customerForename,
    customerSurname:  appData.customerSurname,
    customerAddress1: appData.customerAddress1,
    customerAddress2: appData.customerAddress2,
    customerTown:     appData.customerTown,
    customerPhone:    appData.customerPhone,
    customerCounty:   appData.customerCounty,
    customerPostcode: appData.customerPostcode,
    customerEmail:    appData.customerEmail,

    customerContactByPost:  appData.customerContactByPost,
    customerContactByEmail: appData.customerContactByEmail,
    customerContactByPhone: appData.customerContactByPhone

    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {Customer}
   */
  static fromApiData(apiData: any): Customer {
    const c: Customer = new Customer();

    c.customerId        = apiData.id;
    c.customerTitle     = apiData.title;
    c.customerForename  = apiData.first_name;
    c.customerSurname   = apiData.surname;
    c.customerAddress1  = apiData.address_1;
    c.customerAddress2  = apiData.address_2;
    c.customerTown      = apiData.town_city;
    c.customerPhone     = apiData.phone;
    c.customerCounty    = apiData.county_state;
    c.customerPostcode  = apiData.postcode;
    c.customerEmail     = apiData.email;

    c.customerContactByPost   = apiData.contact_by_post;
    c.customerContactByEmail  = apiData.contact_by_email;
    c.customerContactByPhone  = apiData.contact_by_phone;

    return c;
  }

  /**
   * Converts the model to the API data format
   *
   * @param {Customer} c
   * @return {object}
   */
  static toAPI(c: Customer): any {
    return {
      customer_title:            c.customerTitle,
      customer_first_name:       c.customerForename,
      customer_surname:          c.customerSurname,
      customer_phone:            c.customerPhone,
      customer_email:            c.customerEmail,
      customer_address_1:         c.customerAddress1,
      customer_address_2:         c.customerAddress2,
      customer_address_3:         null, // TODO: Customer.customerAddress3
      customer_town_city:        c.customerTown,
      customer_county_state:     c.customerCounty,
      customer_postcode:         c.customerPostcode,
      customer_contact_by_post:  c.customerContactByPost,
      customer_contact_by_phone: c.customerContactByPhone,
      customer_contact_by_email: c.customerContactByEmail,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {Customer} c
   * @return {object}
   */
  static toAPIUpdateCustomer(c: Customer): any {
    return {
      title:            c.customerTitle,
      first_name:       c.customerForename,
      surname:          c.customerSurname,
      phone:            c.customerPhone,
      email:            c.customerEmail,
      address_1:         c.customerAddress1,
      address_2:         c.customerAddress2,
      address_3:         null, // TODO: Customer.customerAddress3
      town_city:        c.customerTown,
      county_state:     c.customerCounty,
      postcode:         c.customerPostcode,
      contact_by_post:  c.customerContactByPost,
      contact_by_phone: c.customerContactByPhone,
      contact_by_email: c.customerContactByEmail,
      save_on_behalf_of:c.save_on_behalf_of,
    };
  }

  /**
   * Returns an example model
   *
   * @return {Customer}
   */
  static getExample(): Customer {
    const user: UserProfile = UserProfile.getExample();

    return {
      customerId:        Math.floor(Math.random() * 1000),
      customerTitle:     user.title,
      customerForename:  user.firstName,
      customerSurname:   user.lastName,
      customerAddress1:  user.address1,
      customerAddress2:  user.address2,
      customerTown:      user.town,
      customerCounty:    user.town,
      customerPostcode:  user.postCode,
      customerEmail:     user.email,
      customerPhone:     user.telephone,

      customerContactByPost:   false,
      customerContactByEmail:  false,
      customerContactByPhone:  false,
    };
  }
}
