<div class="container-fluid">
  <div class="row content-page no-pad">

    <div class="col-sm-8 padded-container overflow">
      <div class="container-title has-sub">
        <h1 class="white">Diary</h1>
      </div>

      <app-page-content page="DIARY" field="intro" class="color-white intro-above-content"></app-page-content>

      <div class="diary-container background-white col-xs-12">
        <app-message-panel kind="ERROR" *ngIf="(diary$ | async).error">
          {{(diary$ | async).error}}
        </app-message-panel>

        <app-diary-calendar
          view="month"
          [readOnly]="false"
          [events]="(diary$|async).events"
          [eventTypes]="eventTypes"
          (onEventAdded)="handleAddEvent($event)"
          (onEventEdited)="handleEditEvent($event)"
          (onEventDeleted)="handleDeleteEvent($event)"
        >
        </app-diary-calendar>
      </div>
    </div>

    <div class="col-sm-4 upcoming-container">
      <h1 class="color-adey-red">Upcoming events</h1>
      <app-diary-list-events
        [events]="(diary$|async).events"
        [filterUpcoming]="true"
        perPage="8"
        [pagination]="false"
        (onSelectEvent)="editEvent($event)"
      >
      </app-diary-list-events>
    </div>

  </div>
</div>
