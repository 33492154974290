import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AddAdminPromotionResponse,
  DeleteAdminPromotionResponse,
  FetchAdminPromotionsResponse,
  FetchUserPointsHistoryResponse,
  TransferAdminUserPointsResponse,
  UpdateAdminUserPointsResponse,
} from '../../models/admin-rewards';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-rewards';

@Injectable()
export class AdminRewardEffects {
  constructor(
    private actions$: Actions,
    private service: AdminService,
  ) { }

   addAdminPromotion$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_ADMIN_PROMOTION_REQUEST),
    switchMap((req: ActionTypes.AddAdminPromotionRequestAction): Observable<ActionTypes.AddAdminPromotionResponseAction> =>
      this.service.addPromotion(req.payload).pipe(
        map((res: AddAdminPromotionResponse): ActionTypes.AddAdminPromotionResponseAction =>
          new ActionTypes.AddAdminPromotionResponseAction(res)
        ))
    )));

   deleteAdminPromotion$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_ADMIN_PROMOTION_REQUEST),
    switchMap((req: ActionTypes.DeleteAdminPromotionRequestAction): Observable<ActionTypes.DeleteAdminPromotionResponseAction> =>
      this.service.deletePromotion(req.payload).pipe(
        map((res: DeleteAdminPromotionResponse): ActionTypes.DeleteAdminPromotionResponseAction =>
          new ActionTypes.DeleteAdminPromotionResponseAction(res)
        ))
    )));

   fetchAdminPromotions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_PROMOTIONS_REQUEST),
    switchMap((): Observable<ActionTypes.FetchAdminPromotionsResponseAction> =>
      this.service.fetchPromotions().pipe(
        map((res: FetchAdminPromotionsResponse): ActionTypes.FetchAdminPromotionsResponseAction =>
          new ActionTypes.FetchAdminPromotionsResponseAction(res)
        ))
    )));

   fetchUserPointsHistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_USER_POINTS_HISTORY_REQUEST),
    switchMap((req: ActionTypes.FetchUserPointsHistoryRequestAction): Observable<ActionTypes.FetchUserPointsHistoryResponseAction> =>
      this.service.fetchUserPointsHistory(req.payload).pipe(
        map((res: FetchUserPointsHistoryResponse): ActionTypes.FetchUserPointsHistoryResponseAction =>
          new ActionTypes.FetchUserPointsHistoryResponseAction(res)
        ))
    )));

  /**
   * For an TransferAdminUserPointsRequestAction, call
   * AdminService::updateUserPoints() and dispatch a new
   * TransferAdminUserPointsResponseAction with the response.
   */
   transferUserPoints$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.TRANSFER_ADMIN_USER_POINTS_REQUEST),
    switchMap((req: ActionTypes.TransferAdminUserPointsRequestAction): Observable<ActionTypes.TransferAdminUserPointsResponseAction> =>
      this.service.transferUserPoints(req.payload).pipe(
        map((res: TransferAdminUserPointsResponse): ActionTypes.TransferAdminUserPointsResponseAction =>
          new ActionTypes.TransferAdminUserPointsResponseAction(res)
        ))
    )));

  /**
   * For an UpdateAdminUserPointsRequestAction, call
   * AdminService::updateUserPoints() and dispatch a new
   * UpdateAdminUserPointsResponseAction with the response.
   */
   updateUserPoints$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ADMIN_USER_POINTS_REQUEST),
    switchMap((req: ActionTypes.UpdateAdminUserPointsRequestAction): Observable<ActionTypes.UpdateAdminUserPointsResponseAction> =>
      this.service.updateUserPoints(req.payload).pipe(
        map((res: UpdateAdminUserPointsResponse): ActionTypes.UpdateAdminUserPointsResponseAction =>
          new ActionTypes.UpdateAdminUserPointsResponseAction(res)
        ))
    )));
}
