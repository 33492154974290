/**
 * Provides NGRX Reducer for the Pro3 Admin Portal
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import {
    AdminDevice,
    AdminDeviceStatus,
    AdminDeviceDetailItem,
    AdminDeviceAlert, AdminPro3RegisterWarrantyRequest, AdminDeviceNote
} from '../../models/admin-pro3';

import * as actions from '../actions/admin-pro3';
import {DFResponse} from '../../models/df-proclub';
import {UserProfile} from '../../models/user-profile';

/**
 * Definition of the state
 */
export interface State {

    // Pending flags and error message
    deviceAlertsPending: boolean;
    deviceDataPending: boolean;
    deviceDetailPending: boolean;
    alertDataPending: boolean;
    statusDataPending: boolean;

    dismissAlertPending: boolean;
    error: string;
    message: string;
    type: string;

    //filter data items
    hosted_company_id: number;

    //Items with DF endpoint meta-info:
    deviceAlerts: DFResponse<AdminDeviceAlert>;
    deviceData: DFResponse<AdminDevice>;
    deviceDataExport: DFResponse<AdminDevice>;
    deviceAlertDataExport: DFResponse<AdminDevice>;
    alertData: DFResponse<AdminDevice>;
    statusData: AdminDeviceStatus[];

    //Single-object responses
    deviceDetail: AdminDeviceDetailItem;
    deviceNote: AdminDeviceNote;

    //Warranty Registration
    installer: Partial<UserProfile>;
    warrantyDetails: AdminPro3RegisterWarrantyRequest;

    //export device/alert list disable button
    deviceExportButtonDisabled: boolean;
    alertExportButtonDisabled: boolean;
}

/**
 * Default state values
 */
export const defaultState: State = {
    deviceAlertsPending: true,
    deviceDataPending: true,
    deviceDetailPending: true,
    alertDataPending: true,
    statusDataPending: true,

    error: null,
    message: null,
    type: null,
    dismissAlertPending: true,

    hosted_company_id: null,

    deviceAlerts: null,
    deviceData: null,
    deviceDataExport: null,
    deviceAlertDataExport: null,
    alertData: null,

    deviceDetail: null,
    deviceNote: undefined,

    statusData: [],

    installer: null,
    warrantyDetails: null,

    deviceExportButtonDisabled: false,
    alertExportButtonDisabled: false
};

/**
 * NGRX Reducer interpreting actions to perform against the Pro3 Admin store
 *
 * @param state
 * @param action
 */
export function reducer(
    state: State = defaultState,
    action: actions.Actions
): State {
    let newState;
    switch (action.type) {
        case actions.ADMIN_DEVICE_ALERT_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_ALERT_REQUEST,
                alertDataPending: true,
                error: null,
                alertData: null,
                alertExportButtonDisabled: true
            });
            break;
        case actions.ADMIN_DEVICE_ALERT_EXPORT_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_ALERT_EXPORT_REQUEST,
                error: null,
                alertExportButtonDisabled: true
            });
            break;
        case actions.ADMIN_DEVICE_ALERT_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_ALERT_RESPONSE,
                alertDataPending: false,
                error: action.payload.error,
                alertData: action.payload,
                alertExportButtonDisabled: !(action.payload.paging.results > 0)
            });
            break;
        case actions.ADMIN_DEVICE_ALERT_EXPORT_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_ALERT_EXPORT_RESPONSE,
                error: null,
                alertExportButtonDisabled: false,
                deviceAlertDataExport: action.payload
            });
            break;

        case actions.ADMIN_DEVICE_ALERT_EXPORT_CLEAR_DATA_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_ALERT_EXPORT_CLEAR_DATA_REQUEST,
                deviceAlertDataExport: null,
            });
        break;

        case actions.ADMIN_DEVICE_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_REQUEST,
                deviceDataPending: true,
                error: null,
                deviceData: null,
                deviceExportButtonDisabled: true
            });
            break;

        case actions.ADMIN_DEVICE_EXPORT_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_EXPORT_REQUEST,
                error: null,
                deviceExportButtonDisabled: true,
                deviceDataExport: null
            });
            break;

        case actions.ADMIN_DEVICE_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_RESPONSE,
                deviceDataPending: false,
                error: action.payload.error,
                deviceData: action.payload,
                deviceExportButtonDisabled: !(action.payload.paging.results > 0)
            });
            break;

        case actions.ADMIN_DEVICE_EXPORT_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_EXPORT_RESPONSE,
                deviceExportButtonDisabled: false,
                deviceDataExport: action.payload,
            });
            break;

        case actions.ADMIN_DEVICE_EXPORT_CLEAR_DATA_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_EXPORT_CLEAR_DATA_REQUEST,
                deviceDataExport: null,
            });
          break;

        case actions.ADMIN_DEVICE_DETAIL_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_DETAIL_REQUEST,
                deviceDetailPending: true,
                error: null,
                deviceDetail: null,
            });
            break;

        case actions.ADMIN_DEVICE_DETAIL_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_DETAIL_RESPONSE,
                deviceDetailPending: false,
                error: action.payload.error,
                deviceDetail: action.payload.data.pop(),
            });
            break;

        case actions.ADMIN_DEVICE_NOTE_REQUEST:
            newState = Object.assign({}, state, {
                deviceNote: undefined,
            });
            break;

        case actions.ADMIN_DEVICE_NOTE_RESPONSE:
            newState = Object.assign({}, state, {
                deviceNote: action.payload.data.pop(),
            });
            break;

        case actions.ADMIN_DEVICE_STATUS_REQUEST:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_STATUS_REQUEST,
                statusDataPending: true,
                error: null,
                statusData: null
            });
            break;

        case actions.ADMIN_DEVICE_STATUS_RESPONSE:
            newState = Object.assign({}, state, {
                type: actions.ADMIN_DEVICE_STATUS_RESPONSE,
                statusDataPending: false,
                error: action.payload.error,
                statusData: action.payload.data
            });
            break;


        case actions.ADMIN_DISMISS_ALERT_REQUEST:
            newState = Object.assign({}, state, {
                type: 'ADMIN_DISMISS_ALERT_REQUEST',
                dismissAlertPending: true,
                error: null,
                message: null,
            });
            break;

        case actions.ADMIN_DISMISS_ALERT_RESPONSE:
            newState = Object.assign({}, state, {
                type: 'ADMIN_DISMISS_ALERT_RESPONSE',
                dismissAlertPending: false,
                error: ((action.payload.error) ? null : action.payload.error),
                // message:                   action.payload.message,
            });

            break;

        case actions.ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST:
            // console.log(action, state);
            break;

        case actions.ADMIN_GET_INSTALLER_FROM_PARTIAL_RESPONSE:
            newState = Object.assign({}, state, {
                installer: action.payload,
            });
            break;

        case actions.ADMIN_GET_MAGSENSE_WARRANTY_INFO_SUCCESS:
            newState = Object.assign({}, state, {
                warrantyDetails: action.payload.data[0],
            });
            break;

        default:
            newState = state;
            break;
    }
    // console.log(action, state, newState);
    return newState;
}
