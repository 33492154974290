import { Component } from '@angular/core';


@Component({
  selector: 'app-warranty-list',
  templateUrl: './pro3-list.component.html',
  styleUrls: ['./pro3-list.component.scss']
})
export class Pro3ListComponent {

  constructor() { }

  public goToSense(): void {
    window.open('https://sense.adey.com/', '_blank');
  }
}
