<div class="modal-wrapper">
  <h2 *ngIf="!proCheckView" matDialogTitle>Forgotten password?</h2>
  <h2 *ngIf="proCheckView" matDialogTitle>Forgot password?</h2>

  <ng-container
    *ngIf="!(loginDetails$|async).pending && !(loginDetails$|async).resetMessage && !(loginDetails$|async).resetError">
    <mat-dialog-content>
      <p>Did you know: You now have one login for all ADEY services, so any changes you make here will apply to your ADEY ProCheck® and ProClub® accounts.</p>
      <p>Enter your email below to receive an email with instruction on how to reset your password.</p>
      <form [formGroup]="resetForm" class="row">
        <mat-form-field class="col-xs-12">
          <input matInput placeholder="Email" formControlName="email">
          <mat-error *ngIf="!resetForm.controls.email.error">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Cancel</button>
      <button mat-button [disabled]="!resetForm.controls.email.valid" (click)="submit()">Reset password</button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="(loginDetails$|async).pending">
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12 spinner-wrapper">
          <app-waiting-spinner kind="spinner"></app-waiting-spinner>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Cancel</button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="(loginDetails$|async).resetMessage">
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12">
          <p>{{ (loginDetails$|async).resetMessage }}</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="(loginDetails$|async).resetError">
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12">
          <p>{{ (loginDetails$|async).resetError }}</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Cancel</button>
      <button mat-button [disabled]="!resetForm.controls.email.valid" (click)="submit()">Retry</button>
    </mat-dialog-actions>
  </ng-container>

</div>
