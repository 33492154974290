<div class="container-fluid job-page">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Savings calculator</h1>
    </div>

    <app-page-content page="ROI_CALCULATOR" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="cost-container background-white col-xs-12">
      <form [formGroup]="fg" (ngSubmit)="submitRoiForm()">

        <div class="costs-section col-xs-12 col-sm-12 col-md-6 border-lighter-gray">

          <div class="header-text">
            <p class="border-lighter-gray">
              Use this handy tool to calculate the ROI for your customers and
              demonstrate the savings that can be made by following the 6 stage
              ADEY® best practice process.
            </p>
          </div>

          <div class="cost-inputs">
            <div [class]="formGroupClass('annualGasCost', 'half-width-input')">
              <label>Annual Gas Cost (<span [translate]="'CURRENCY'"></span>)</label>
              <input type="number" class="form-control" (change)="detectFieldChanges($event, 'annualGasCost', 'annualLpgCost')" formControlName="annualGasCost" />
            </div>
            <p class="input-divider">Or</p>
            <div [class]="formGroupClass('annualLpgCost', 'half-width-input')">
              <label>Annual LPG Cost</label>
              <input type="number" class="form-control" formControlName="annualLpgCost" (change)="detectFieldChanges($event, 'annualLpgCost', 'annualGasCost')" />
            </div>
          </div>

          <div class="radio-products">
            <div class="product-container" *ngFor="let product of productSelections">
              <div class="product-description">
                <div class="product-name background-adey-blue color-white">
                  <p>{{product.name}}</p>
                </div>
                <div class="product-percentage background-adey-red color-white">
                  <i class="icon-check"></i>
                </div>
              </div>
            </div>
          </div>

          <input type="submit" class="calculate-button btn background-green color-white full-width no-radius" value="Calculate" />

          <div class="results-section row" *ngIf="totalSavings !== null">
            <div class="col-xs-6 percentage">
              <p><strong>6% Saving</strong><p>
              <p>With a MagnaClean</p>
            </div>
            <div class="col-xs-6 cost">
              <p><strong><span [translate]="'CURRENCY'"></span>{{totalSavings}} Saving</strong><p>
              <p>Over 1 Year</p>
            </div>
          </div>

        </div>

        <div class="photo-section hidden-xs hidden-sm col-md-6">
          <img class="img-responsive" src="assets/i/pages/roi/lifestyle.jpg" />
        </div>

      </form>
    </div>
  </div>
</div>
