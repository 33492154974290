<div class="file-upload">

  <img class="img-responsive file-img" [src]="initialUrl" *ngIf="imageMode && initialUrl && !fileData" alt="Current photo" />
  <img class="img-responsive file-img" [src]="fileData"  *ngIf="imageMode && fileData" alt="New photo" />

  <label for="fileInput">{{placeholder}}</label>
  <input
    id="fileInput"
    type="file"
    [placeholder]="placeholder"
    (change)="handleFile($event)"
    *ngIf="fileAPISupported"
  />

  <button mat-button *ngIf="fileData" (click)="resetUpload()">Reset</button>

  <app-message-panel kind="ERROR" *ngIf="!fileAPISupported">
    Unfortunately your browser does not provide support for uploading files.
  </app-message-panel>

  <app-message-panel kind="ERROR" *ngIf="errors">
    {{errors}}
  </app-message-panel>

</div>
