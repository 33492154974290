<h2 matDialogTitle>Update</h2>

<mat-dialog-content>
  <form [formGroup]="flagUpdate" novalidate (ngSubmit)="submitDialog()" *ngIf="flagUpdate">
    <mat-checkbox md-no-ink class="md-primary" formControlName="flagChecked">{{data.flagLabel}}</mat-checkbox>

    <div *ngIf="flagUpdate.get('flagChecked') && flagUpdate.get('flagChecked').value === true">
      <div class="col-xs-12 radio-buttons no-pad" *ngIf="flagUpdate.get('filterInstall')">
        <input type="radio" value="New" id="new_system" formControlName="filterInstall" name="filterInstall">
        <label for="new_system" [class]="flagUpdate.controls.filterInstall.value == 'New' ? 'background-adey-red color-white' : 'background-beige color-gray'">New</label>
        <input type="radio" value="Replacement" id="existing_system" formControlName="filterInstall" name="filterInstall">
        <label for="existing_system" [class]="flagUpdate.controls.filterInstall.value == 'Replacement' ? 'background-adey-red color-white' : 'background-beige color-gray'">Replacement</label>
      </div>

      <div class="form-group" *ngIf="flagUpdate.get('filterInstallDate')">
        <mat-form-field>
          <input matInput formControlName="filterInstallDate" [min]="minDate" [max]="maxDate" [matDatepicker]="dpFilterInstallDate" placeholder="Filter installation Date"/>
          <mat-datepicker #dpFilterInstallDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpFilterInstallDate" *ngIf="!readOnly"></mat-datepicker-toggle>
      </div>

      <div class="form-group" *ngIf="flagUpdate.get('serial')">
        <mat-form-field class="full-width">
          <input type="text" matInput formControlName="serial" placeholder="Serial No." />
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="flagUpdate.get('completionDate')">
        <mat-form-field>
          <input matInput formControlName="completionDate" [min]="minDate" [max]="maxDate" [matDatepicker]="dpCompletionDate" placeholder="Service completion date"/>
          <mat-datepicker #dpCompletionDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpCompletionDate"></mat-datepicker-toggle>
      </div>
    </div>

    <button
      class="btn background-green full-width no-radius color-white mar-bottom-12"
      *ngIf="data.flagTitle && data.flagTitle === 'waterTestComplete'"
      type="button"
      (click)="openWaterTest()">Check water test results</button>
    <button class="btn background-green full-width no-radius color-white" type="submit">Update flags</button>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
</mat-dialog-actions>
