import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FeatureErrorResponse, FeatureResponse } from '../../models/feature';
import { FeatureService } from '../../services/feature.service';
import * as ActionTypes from '../actions/feature';

@Injectable()
export class FeatureEffects {
  constructor(
    private service: FeatureService,
    private actions$: Actions
  ) { }

   sparesRewardsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GET_FEATURES),
    switchMap(() => this.service.getUserFeatures()
      .then((payload: FeatureResponse) => new ActionTypes.GetFeaturesSuccess(payload.resource))
      .catch((error: FeatureErrorResponse) => new ActionTypes.GetFeaturesFailure(error))
    )));
}
