/**
 * products actions: defines actions for the product selector and checking of
 * product serial numbers.
 */

import { Action } from '@ngrx/store';


// Import models
import { Product } from '../../models/product';

import {
  ProductSelectQueryRequest,
  ProductSelectQueryResponse,
} from '../../models/product-selector';

import {
  ProductCheckSerialRequest,
  ProductCheckSerialResponse,
} from '../../models/product';


// Action type definitions
export const PRODUCT_SELECT_QUERY_REQUEST  = 'PRODUCT_SELECT_QUERY_REQUEST';
export const PRODUCT_SELECT_QUERY_RESPONSE = 'PRODUCT_SELECT_QUERY_RESPONSE';

export const PRODUCT_CHECK_SERIAL_REQUEST  = 'PRODUCT_CHECK_SERIAL_REQUEST';
export const PRODUCT_CHECK_SERIAL_RESPONSE = 'PRODUCT_CHECK_SERIAL_RESPONSE';


// Action definitions
export class ProductSelectQueryRequestAction implements Action {
  readonly type = PRODUCT_SELECT_QUERY_REQUEST;
  constructor(public payload: ProductSelectQueryRequest) {}
}

export class ProductSelectQueryResponseAction implements Action {
  readonly type = PRODUCT_SELECT_QUERY_RESPONSE;
  constructor(public payload: ProductSelectQueryResponse) {}
}

export class ProductCheckSerialRequestAction implements Action {
  readonly type = PRODUCT_CHECK_SERIAL_REQUEST;
  constructor(public payload: ProductCheckSerialRequest) {}
}

export class ProductCheckSerialResponseAction implements Action {
  readonly type = PRODUCT_CHECK_SERIAL_RESPONSE;
  constructor(public payload: ProductCheckSerialResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | ProductSelectQueryRequestAction
  | ProductSelectQueryResponseAction
  | ProductCheckSerialRequestAction
  | ProductCheckSerialResponseAction;
