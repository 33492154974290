import * as loginActions from '../actions/login-details';
import { WaterTesterRegisterRequest } from '../../models/login';

// State interface definition
export interface State {

  // Details of currently logged-in user
  registerData: WaterTesterRegisterRequest;

  // Request pending and response error
  pending: boolean;
  error: string;
  success: boolean;

}


const defaultState: State = {
  registerData: null,
  pending: false,
  error: null,
  success: false
};


export function reducer(
  state: State = defaultState,
  action: loginActions.Actions,
): State {
  switch (action.type) {

    /**
     * Register water tester
     */
    case loginActions.REGISTER_WATER_TESTER_REQUEST:
      return Object.assign({}, state, {
        registerWaterTester: action.payload,
        pending: true
      });

    /**
     * Register water tester response
     */
    case loginActions.REGISTER_WATER_TESTER_RESPONSE:
      //console.log('payload ' , action.payload)
      return Object.assign({}, state, {
        registerWaterTester: action.payload,
        pending: false,
        error: action.payload.error,
        success: action.payload.error === null
      });
    default:
      return state;
  }
}
