/**
 * DiaryService: provides all functionality related to the diary, calendar and
 * events
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';




import * as moment from 'moment';

import { Injectable } from '@angular/core';


import { ApiService } from './api.service';

import { DiaryService as MockService } from './diary.service.mock';

import {
  CalendarEventAction,
} from 'angular-calendar';

import {
  DiaryEvent,
  EventType,

  CommitEventResponse,
  FetchEventsResponse,
  FetchEventTypesResponse,
} from '../models/diary';


@Injectable()
export class DiaryService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;


  constructor(
    private apiService: ApiService,
  ) {
    this.mockService = new MockService();
  }


  /**
   * Adds a new DiaryEvent
   *
   * @param {DiaryEvent} req
   * @return {Observable<CommitEventResponse>}
   */
  addEvent(req: DiaryEvent): Observable<CommitEventResponse> {
    return this.apiService.apiPost('/diaryevent', DiaryEvent.toApiPostData(req)).pipe(
      map((res: any): CommitEventResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          id:    null,
          newId: res.resource[0].id,
        };
      }),
      catchError((err: any): Observable<CommitEventResponse> => {
        return observableOf({
          id:    null,
          newId: null,
          error: err && err.error && err.error.message
            ? `Unable to add event: ${err.error.message}`
            : 'Unable to add event',
        });
      }),);
  }

  /**
   * Books a specified DiaryEvent (currently unused)
   *
   * @param {string} id ID of the DiaryEvent to book
   * @return {Observable<CommitEventResponse>}
   */
  bookEvent(id: string): Observable<CommitEventResponse> {
    return this.mockService.bookEvent(id);
  }

  /**
   * Deletes an existing DiaryEvent
   *
   * @param {string} id ID of the DiaryEvent to delete
   * @return {Observable<CommitEventResponse>}
   */
  deleteEvent(id: string): Observable<CommitEventResponse> {
    return this.apiService.apiDelete('/diaryevent/' + encodeURIComponent(id)).pipe(
      map((res: any): CommitEventResponse => {
        return {
          error: null,
          id,
        };
      }),
      catchError((err: any): Observable<CommitEventResponse> => {
        return observableOf({
          error: err && err.error && err.error.message
            ? `Unable to delete event: ${err.error.message}`
            : 'Unable to delete event',
          id: null,
        });
      }),);
  }

  /**
   * Fetches DiaryEvent models
   *
   * @return {Observable<FetchEventsResponse>}
   */
  fetchEvents(): Observable<FetchEventsResponse> {
    return this.apiService.apiGet('/current-events').pipe(
      map((res: any): FetchEventsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
          return {
            error: valid ? null : 'Invalid response from server',
            items: valid ? res.resource.map(DiaryEvent.fromApiData) : null,
          };
      }),
      catchError((err: any): Observable<FetchEventsResponse> =>
        observableOf({
          items: null,
          error: err && err.error && err.error.message
            ? `Unable to get events: ${err.error.message}`
            : 'Unable to get events',
        })
      ),);
  }

  /**
   * Fetches EventType models
   *
   * @return {Observable<FetchEventTypesResponse>}
   */
  fetchEventTypes(): Observable<FetchEventTypesResponse> {
    return this.apiService.apiGet('/diaryeventtypes').pipe(
      map((res: any): FetchEventTypesResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          types: valid ? res.resource.map(EventType.fromAPI) : null,
        };
      }),
      catchError((err: any): Observable<FetchEventTypesResponse> =>
        observableOf({
          types: null,
          error: err && err.error && err.error.message
            ? `Unable to get event types: ${err.error.message}`
            : 'Unable to get event types',
        })
      ),);
  }

  /**
   * Updated an existing DiaryEvent
   *
   * @param {DiaryEvent} req
   * @return {Observable<CommitEventResponse>}
   */
  updateEvent(req: DiaryEvent): Observable<CommitEventResponse> {
    return this.apiService.apiPatch(`/diaryevent/` + encodeURIComponent(req.id), DiaryEvent.toApiPatchData(req)).pipe(
      map((res: any): CommitEventResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          error: valid ? null : 'Invalid response from server',
          id:    valid ? res.resource[0].id : null,
        };
      }),
      catchError((err: any): Observable<CommitEventResponse> => {
        return observableOf({
          id: null,
          error: err && err.error && err.error.message
            ? `Unable to update event: ${err.error.message}`
            : 'Unable to update event',
        });
      }),);
  }

}
