import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AddAdminEventResponse,
  AddAdminEventTypeResponse,
  DeleteAdminEventResponse,
  DeleteAdminEventTypeResponse,
  FetchAdminEventsResponse,
  UpdateAdminEventResponse,
  UpdateAdminEventTypeResponse,
} from '../../models/admin-events';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-events';






@Injectable()
export class AdminEventsEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For an AddAdminEventRequestAction, call AdminService::addEvent() and
   * dispatch a new AddAdminEventResponseAction with the response.
   */
   addEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_ADMIN_EVENT_REQUEST),
    switchMap((req: ActionTypes.AddAdminEventRequestAction): Observable<ActionTypes.AddAdminEventResponseAction> =>
      this.service.addEvent(req.payload).pipe(
        map((res: AddAdminEventResponse): ActionTypes.AddAdminEventResponseAction =>
          new ActionTypes.AddAdminEventResponseAction(res)
        ))
    )));

  /**
   * For an AddAdminEventTypeRequestAction, call AdminService::addEventType()
   * and dispatch a new AddAdminEventTypeResponseAction with the response.
   */
   addEventType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_ADMIN_EVENT_TYPE_REQUEST),
    switchMap((req: ActionTypes.AddAdminEventTypeRequestAction): Observable<ActionTypes.AddAdminEventTypeResponseAction> =>
      this.service.addEventType(req.payload).pipe(
        map((res: AddAdminEventTypeResponse): ActionTypes.AddAdminEventTypeResponseAction =>
          new ActionTypes.AddAdminEventTypeResponseAction(res)
        ))
    )));

  /**
   * For a DeleteAdminEventRequestAction, call AdminService::deleteEvent() and
   * dispatch a new DeleteAdminEventResponseAction with the response.
   */
   deleteEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_ADMIN_EVENT_REQUEST),
    switchMap((req: ActionTypes.DeleteAdminEventRequestAction): Observable<ActionTypes.DeleteAdminEventResponseAction> =>
      this.service.deleteEvent(req.payload).pipe(
        map((res: DeleteAdminEventResponse): ActionTypes.DeleteAdminEventResponseAction =>
          new ActionTypes.DeleteAdminEventResponseAction(res)
        ))
    )));

  /**
   * For a DeleteAdminEventTypeRequestAction, call
   * AdminService::deleteEventType() and dispatch a new
   * DeleteAdminEventTypeResponseAction with the response.
   */
   deleteEventType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_ADMIN_EVENT_TYPE_REQUEST),
    switchMap((req: ActionTypes.DeleteAdminEventTypeRequestAction): Observable<ActionTypes.DeleteAdminEventTypeResponseAction> =>
      this.service.deleteEventType(req.payload).pipe(
        map((res: DeleteAdminEventTypeResponse): ActionTypes.DeleteAdminEventTypeResponseAction =>
          new ActionTypes.DeleteAdminEventTypeResponseAction(res)
        ))
    )));

  /**
   * For a FetchAdminEventsRequestAction, call AdminService::fetchEvents() and
   * dispatch a new FetchAdminEventsResponseAction with the response.
   */
   fetchEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST),
    switchMap((req: ActionTypes.FetchAdminEventsRequestAction): Observable<ActionTypes.FetchAdminEventsResponseAction> =>
      this.service.fetchEvents(req.payload).pipe(
        map((res: FetchAdminEventsResponse): ActionTypes.FetchAdminEventsResponseAction =>
          new ActionTypes.FetchAdminEventsResponseAction(res)
        ))
    )));

  /**
   * For an UpdateAdminEventRequestAction, call AdminService::updateEvent() and
   * dispatch a new UpdateAdminEventResponseAction with the response.
   */
   updateEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ADMIN_EVENT_REQUEST),
    switchMap((req: ActionTypes.UpdateAdminEventRequestAction): Observable<ActionTypes.UpdateAdminEventResponseAction> =>
      this.service.updateEvent(req.payload).pipe(
        map((res: UpdateAdminEventResponse): ActionTypes.UpdateAdminEventResponseAction =>
          new ActionTypes.UpdateAdminEventResponseAction(res)
        ))
    )));

  /**
   * For an UpdateAdminEventTypeRequestAction, call
   * AdminService::updateEventType() and dispatch a new
   * UpdateAdminEventTypeResponseAction with the response.
   */
   updateEventType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ADMIN_EVENT_TYPE_REQUEST),
    switchMap((req: ActionTypes.UpdateAdminEventTypeRequestAction): Observable<ActionTypes.UpdateAdminEventTypeResponseAction> =>
      this.service.updateEventType(req.payload).pipe(
        map((res: UpdateAdminEventTypeResponse): ActionTypes.UpdateAdminEventTypeResponseAction =>
          new ActionTypes.UpdateAdminEventTypeResponseAction(res)
        ))
    )));
}
