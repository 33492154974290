import { Component, Input, OnInit } from '@angular/core';

/**
 * Summary
 *    Display a message on the page.
 *    
 *
 * Description
 *    Message body will be displayed within a styled container depending on the type of message
 *    to display. For example, success messages will be displayed in a green container while
 *    error messages will be displayed within a red container.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss']
})
export class MessagePanelComponent implements OnInit {

  // Changes the colour of the message box depending on the value of kind
  @Input() kind = 'INFO';

  constructor() { }

  ngOnInit() {
  }

  /**
   * Determine the classes to be added to the message container depending on the kind input
   *
   * @return {string} Return the correct container class based on the kind input
   */
  getClass(): string {
    switch (this.kind.toUpperCase())
    {
      case 'ERROR':
        return 'alert alert-danger';
      case 'WARNING':
        return 'alert alert-warning';
      case 'SUCCESS':
        return 'alert alert-success';
      default:
        return 'alert alert-info';
    }
  }

}
