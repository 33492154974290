import { Injectable } from '@angular/core';
import { FeatureResponse } from '../models/feature';
import { ApiService } from './api.service';

@Injectable()
export class FeatureService {

  constructor(
    private apiService: ApiService,
  ) { }

  public async getUserFeatures(): Promise<FeatureResponse> {
    return await this.apiService.apiGet<FeatureResponse>('/features').toPromise();
  }
}
