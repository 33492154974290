<div class="widget-body border-box border-lighter-gray profile-widget background-white col-xs-12">
  
  <ng-content select=".widget-loading"></ng-content>
  <div class="profile-details" *ngIf="!profilePending">
    <app-profile-percentage
      class="profile-complete-percentage" 
      [userProfile]="currentProfile"
      [isWidget]="true"
    ></app-profile-percentage>
    <app-profile-header 
      class="profile-details" 
      [userProfile]="currentProfile"
      [isWidget]="true"
    ></app-profile-header>
  </div>
  <div class="widget-button-container">
    <button class="btn background-green color-white full-width no-radius" (click)="goToPage('/profile')">Open profile</button>
  </div>
</div>