/**
 * admin-jobs actions: defines all actions relating to the
 * job/warranty/promo-code management admin CMS.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AddAdminJobRequest,
  AddAdminJobResponse,
  DeleteAdminJobRequest,
  DeleteAdminJobResponse,
  FetchAdminJobsRequest,
  FetchAdminJobsResponse,
  UpdateAdminJobRequest,
  UpdateAdminJobResponse,

  AddAdminPromoCodeRequest,
  AddAdminPromoCodeResponse,
  DeleteAdminPromoCodeRequest,
  DeleteAdminPromoCodeResponse,
  FetchAdminPromoCodesResponse,
  UpdateAdminPromoCodeRequest,
  UpdateAdminPromoCodeResponse,

  AddAdminWarrantyRequest,
  AddAdminWarrantyResponse,
  DeleteAdminWarrantyRequest,
  DeleteAdminWarrantyResponse,
  FetchAdminWarrantiesRequest,
  FetchAdminWarrantiesResponse,
  UpdateAdminWarrantyRequest,
  UpdateAdminWarrantyResponse,

  UpdateAdminCustomerRequest,
  UpdateAdminCustomerResponse,

  UpdateRegisteredProductRequest,
  UpdateRegisteredProductResponse,
} from '../../models/admin-jobs';


// Action type definitions
export const ADD_ADMIN_JOB_REQUEST     = 'ADD_ADMIN_JOB_REQUEST';
export const ADD_ADMIN_JOB_RESPONSE    = 'ADD_ADMIN_JOB_RESPONSE';
export const DELETE_ADMIN_JOB_REQUEST  = 'DELETE_ADMIN_JOB_REQUEST';
export const DELETE_ADMIN_JOB_RESPONSE = 'DELETE_ADMIN_JOB_RESPONSE';
export const FETCH_ADMIN_JOBS_REQUEST  = 'FETCH_ADMIN_JOBS_REQUEST';
export const FETCH_ADMIN_JOBS_RESPONSE = 'FETCH_ADMIN_JOBS_RESPONSE';
export const UPDATE_ADMIN_JOB_REQUEST  = 'UPDATE_ADMIN_JOB_REQUEST';
export const UPDATE_ADMIN_JOB_RESPONSE = 'UPDATE_ADMIN_JOB_RESPONSE';

export const ADD_ADMIN_PROMO_CODE_REQUEST     = 'ADD_ADMIN_PROMO_CODE_REQUEST';
export const ADD_ADMIN_PROMO_CODE_RESPONSE    = 'ADD_ADMIN_PROMO_CODE_RESPONSE';
export const DELETE_ADMIN_PROMO_CODE_REQUEST  = 'DELETE_ADMIN_PROMO_CODE_REQUEST';
export const DELETE_ADMIN_PROMO_CODE_RESPONSE = 'DELETE_ADMIN_PROMO_CODE_RESPONSE';
export const FETCH_ADMIN_PROMO_CODES_REQUEST  = 'FETCH_ADMIN_PROMO_CODES_REQUEST';
export const FETCH_ADMIN_PROMO_CODES_RESPONSE = 'FETCH_ADMIN_PROMO_CODES_RESPONSE';
export const UPDATE_ADMIN_PROMO_CODE_REQUEST  = 'UPDATE_ADMIN_PROMO_CODE_REQUEST';
export const UPDATE_ADMIN_PROMO_CODE_RESPONSE = 'UPDATE_ADMIN_PROMO_CODE_RESPONSE';

export const ADD_ADMIN_WARRANTY_REQUEST      = 'ADD_ADMIN_WARRANTY_REQUEST';
export const ADD_ADMIN_WARRANTY_RESPONSE     = 'ADD_ADMIN_WARRANTY_RESPONSE';
export const DELETE_ADMIN_WARRANTY_REQUEST   = 'DELETE_ADMIN_WARRANTY_REQUEST';
export const DELETE_ADMIN_WARRANTY_RESPONSE  = 'DELETE_ADMIN_WARRANTY_RESPONSE';
export const FETCH_ADMIN_WARRANTIES_REQUEST  = 'FETCH_ADMIN_WARRANTIES_REQUEST';
export const FETCH_ADMIN_WARRANTIES_RESPONSE = 'FETCH_ADMIN_WARRANTIES_RESPONSE';
export const UPDATE_ADMIN_WARRANTY_REQUEST   = 'UPDATE_ADMIN_WARRANTY_REQUEST';
export const UPDATE_ADMIN_WARRANTY_RESPONSE  = 'UPDATE_ADMIN_WARRANTY_RESPONSE';

export const UPDATE_ADMIN_CUSTOMER_REQUEST = 'UPDATE_ADMIN_CUSTOMER_REQUEST';
export const UPDATE_ADMIN_CUSTOMER_RESPONSE = 'UPDATE_ADMIN_CUSTOMER_RESPONSE';

export const UPDATE_REGISTERED_PRODUCT_REQUEST = 'UPDATE_REGISTERED_PRODUCT_REQUEST';
export const UPDATE_REGISTERED_PRODUCT_RESPONSE = 'UPDATE_REGISTERED_PRODUCT_RESPONSE';


// Action definitions
export class AddAdminJobRequestAction implements Action {
  readonly type = ADD_ADMIN_JOB_REQUEST;
  constructor(public payload: AddAdminJobRequest) {}
}

export class AddAdminJobResponseAction implements Action {
  readonly type = ADD_ADMIN_JOB_RESPONSE;
  constructor(public payload: AddAdminJobResponse) {}
}

export class DeleteAdminJobRequestAction implements Action {
  readonly type = DELETE_ADMIN_JOB_REQUEST;
  constructor(public payload: DeleteAdminJobRequest) {}
}

export class DeleteAdminJobResponseAction implements Action {
  readonly type = DELETE_ADMIN_JOB_RESPONSE;
  constructor(public payload: DeleteAdminJobResponse) {}
}

export class FetchAdminJobsRequestAction implements Action {
  readonly type = FETCH_ADMIN_JOBS_REQUEST;
  constructor(public payload: FetchAdminJobsRequest) {}
}

export class FetchAdminJobsResponseAction implements Action {
  readonly type = FETCH_ADMIN_JOBS_RESPONSE;
  constructor(public payload: FetchAdminJobsResponse) {}
}

export class UpdateAdminJobRequestAction implements Action {
  readonly type = UPDATE_ADMIN_JOB_REQUEST;
  constructor(public payload: UpdateAdminJobRequest) {}
}

export class UpdateAdminJobResponseAction implements Action {
  readonly type = UPDATE_ADMIN_JOB_RESPONSE;
  constructor(public payload: UpdateAdminJobResponse) {}
}


export class AddAdminPromoCodeRequestAction implements Action {
  readonly type = ADD_ADMIN_PROMO_CODE_REQUEST;
  constructor(public payload: AddAdminPromoCodeRequest) {}
}

export class AddAdminPromoCodeResponseAction implements Action {
  readonly type = ADD_ADMIN_PROMO_CODE_RESPONSE;
  constructor(public payload: AddAdminPromoCodeResponse) {}
}

export class DeleteAdminPromoCodeRequestAction implements Action {
  readonly type = DELETE_ADMIN_PROMO_CODE_REQUEST;
  constructor(public payload: DeleteAdminPromoCodeRequest) {}
}

export class DeleteAdminPromoCodeResponseAction implements Action {
  readonly type = DELETE_ADMIN_PROMO_CODE_RESPONSE;
  constructor(public payload: DeleteAdminPromoCodeResponse) {}
}

export class FetchAdminPromoCodesRequestAction implements Action {
  readonly type = FETCH_ADMIN_PROMO_CODES_REQUEST;
}

export class FetchAdminPromoCodesResponseAction implements Action {
  readonly type = FETCH_ADMIN_PROMO_CODES_RESPONSE;
  constructor(public payload: FetchAdminPromoCodesResponse) {}
}

export class UpdateAdminPromoCodeRequestAction implements Action {
  readonly type = UPDATE_ADMIN_PROMO_CODE_REQUEST;
  constructor(public payload: UpdateAdminPromoCodeRequest) {}
}

export class UpdateAdminPromoCodeResponseAction implements Action {
  readonly type = UPDATE_ADMIN_PROMO_CODE_RESPONSE;
  constructor(public payload: UpdateAdminPromoCodeResponse) {}
}


export class AddAdminWarrantyRequestAction implements Action {
  readonly type = ADD_ADMIN_WARRANTY_REQUEST;
  constructor(public payload: AddAdminWarrantyRequest) {}
}

export class AddAdminWarrantyResponseAction implements Action {
  readonly type = ADD_ADMIN_WARRANTY_RESPONSE;
  constructor(public payload: AddAdminWarrantyResponse) {}
}

export class DeleteAdminWarrantyRequestAction implements Action {
  readonly type = DELETE_ADMIN_WARRANTY_REQUEST;
  constructor(public payload: DeleteAdminWarrantyRequest) {}
}

export class DeleteAdminWarrantyResponseAction implements Action {
  readonly type = DELETE_ADMIN_WARRANTY_RESPONSE;
  constructor(public payload: DeleteAdminWarrantyResponse) {}
}

export class FetchAdminWarrantiesRequestAction implements Action {
  readonly type = FETCH_ADMIN_WARRANTIES_REQUEST;
  constructor(public payload: FetchAdminWarrantiesRequest) {}
}

export class FetchAdminWarrantiesResponseAction implements Action {
  readonly type = FETCH_ADMIN_WARRANTIES_RESPONSE;
  constructor(public payload: FetchAdminWarrantiesResponse) {}
}

export class UpdateAdminWarrantyRequestAction implements Action {
  readonly type = UPDATE_ADMIN_WARRANTY_REQUEST;
  constructor(public payload: UpdateAdminWarrantyRequest) {}
}

export class UpdateAdminWarrantyResponseAction implements Action {
  readonly type = UPDATE_ADMIN_WARRANTY_RESPONSE;
  constructor(public payload: UpdateAdminWarrantyResponse) {}
}

export class UpdateAdminCustomerRequestAction implements Action {
  readonly type = UPDATE_ADMIN_CUSTOMER_REQUEST;
  constructor(public payload: UpdateAdminCustomerRequest) {}
}

export class UpdateAdminCustomerResponseAction implements Action {
  readonly type = UPDATE_ADMIN_CUSTOMER_RESPONSE;
  constructor(public payload: UpdateAdminCustomerResponse) {}
}

export class UpdateRegisteredProductRequestAction implements Action {
  readonly type = UPDATE_REGISTERED_PRODUCT_REQUEST;
  constructor(public payload: UpdateRegisteredProductRequest) {}
}

export class UpdateRegisteredProductResponseAction implements Action {
  readonly type = UPDATE_REGISTERED_PRODUCT_RESPONSE;
  constructor(public payload: UpdateRegisteredProductResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | AddAdminJobRequestAction
  | AddAdminJobResponseAction
  | DeleteAdminJobRequestAction
  | DeleteAdminJobResponseAction
  | FetchAdminJobsRequestAction
  | FetchAdminJobsResponseAction
  | UpdateAdminJobRequestAction
  | UpdateAdminJobResponseAction

  | AddAdminPromoCodeRequestAction
  | AddAdminPromoCodeResponseAction
  | DeleteAdminPromoCodeRequestAction
  | DeleteAdminPromoCodeResponseAction
  | FetchAdminPromoCodesRequestAction
  | FetchAdminPromoCodesResponseAction
  | UpdateAdminPromoCodeRequestAction
  | UpdateAdminPromoCodeResponseAction

  | AddAdminWarrantyRequestAction
  | AddAdminWarrantyResponseAction
  | DeleteAdminWarrantyRequestAction
  | DeleteAdminWarrantyResponseAction
  | FetchAdminWarrantiesRequestAction
  | FetchAdminWarrantiesResponseAction
  | UpdateAdminWarrantyRequestAction
  | UpdateAdminWarrantyResponseAction

  | UpdateAdminCustomerRequestAction
  | UpdateAdminCustomerResponseAction

  | UpdateRegisteredProductRequestAction
  | UpdateRegisteredProductResponseAction;
