/**
 * warranty-list actions: defines all actions relating to the user's warranty
 * list
 */

import { Action } from '@ngrx/store';


// Import models
import {
  FetchWarrantyRequest,
  FetchWarrantyResponse,
  FetchWarrantiesRequest,
  FetchWarrantiesResponse,
  FetchWarrantyPdfRequest,
  FetchWarrantyPdfResponse,
  UpdateWarrantyResponse,
  Warranty,
} from '../../models/warranty';


// Action type definitions
export const INIT_WARRANTY_REQUEST       = 'INIT_WARRANTY_REQUEST';
export const FETCH_WARRANTY_REQUEST      = 'FETCH_WARRANTY_REQUEST';
export const FETCH_WARRANTY_RESPONSE     = 'FETCH_WARRANTY_RESPONSE';
export const FETCH_WARRANTIES_REQUEST    = 'FETCH_WARRANTIES_REQUEST';
export const FETCH_WARRANTIES_RESPONSE   = 'FETCH_WARRANTIES_RESPONSE';
export const FETCH_WARRANTY_PDF_REQUEST  = 'FETCH_WARRANTY_PDF_REQUEST';
export const FETCH_WARRANTY_PDF_RESPONSE = 'FETCH_WARRANTY_PDF_RESPONSE';
export const UPDATE_WARRANTY_REQUEST     = 'UPDATE_WARRANTY_REQUEST';
export const UPDATE_WARRANTY_RESPONSE    = 'UPDATE_WARRANTY_RESPONSE';


// Action definitions
export class InitWarrantyRequestAction implements Action {
  readonly type = INIT_WARRANTY_REQUEST;
}

export class FetchWarrantyRequestAction implements Action {
  readonly type = FETCH_WARRANTY_REQUEST;
  constructor(public payload: FetchWarrantyRequest) {}
}

export class FetchWarrantyResponseAction implements Action {
  readonly type = FETCH_WARRANTY_RESPONSE;
  constructor(public payload: FetchWarrantyResponse) {}
}

export class FetchWarrantiesRequestAction implements Action {
  readonly type = FETCH_WARRANTIES_REQUEST;
  constructor(public payload: FetchWarrantiesRequest) {}
}

export class FetchWarrantiesResponseAction implements Action {
  readonly type = FETCH_WARRANTIES_RESPONSE;
  constructor(public payload: FetchWarrantiesResponse) {}
}

export class FetchWarrantyPdfRequestAction implements Action {
  readonly type = FETCH_WARRANTY_PDF_REQUEST;
  constructor(public payload: FetchWarrantyPdfRequest) {}
}

export class FetchWarrantyPdfResponseAction implements Action {
  readonly type = FETCH_WARRANTY_PDF_RESPONSE;
  constructor(public payload: FetchWarrantyPdfResponse) {}
}

export class UpdateWarrantyRequestAction implements Action {
  readonly type = UPDATE_WARRANTY_REQUEST;
  constructor(public payload: Warranty) {}
}

export class UpdateWarrantyResponseAction implements Action {
  readonly type = UPDATE_WARRANTY_RESPONSE;
  constructor(public payload: UpdateWarrantyResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | InitWarrantyRequestAction
  | FetchWarrantyRequestAction
  | FetchWarrantyResponseAction
  | FetchWarrantiesRequestAction
  | FetchWarrantiesResponseAction
  | FetchWarrantyPdfRequestAction
  | FetchWarrantyPdfResponseAction
  | UpdateWarrantyRequestAction
  | UpdateWarrantyResponseAction;
