<div class="container-fluid">

  <div class="row content-page" *ngIf="warrantyList$ | async; let warrantyList">

    <div class="container-title">
      <h1 class="white">Warranty list</h1>
      <div class="warranty-quick-info">
        <div class="info-title background-beige align-center">
          <span class="color-gray">Warranties</span>
        </div>
        <div class="info-container background-white align-center">
          <div class="border-box border-light-gray" *ngIf="warrantyList.pending">
            <app-waiting-spinner kind="spinner">Fetching statistics...</app-waiting-spinner>
          </div>
          <div class="border-box border-light-gray" *ngIf="!warrantyList.pending && !warrantyList.stats">
            <p>No warranty statistics to display</p>
          </div>
          <div class="border-box border-light-gray" *ngIf="!warrantyList.pending && warrantyList.stats">
            <div class="info-block background-adey-blue" style="width: 100%;">
              <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-white.png">
              <h2 class="color-white">{{ warrantyList.stats.count }}</h2>
              <p class="color-white">Completed warranties</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="cur-date color-adey-red">{{currentDate | date}}</h2>
    </div>


    <div class="warranty-list background-white col-xs-12 no-pad">
      <div class="list-title background-beige align-center">
        <span class="color-gray">Warranties</span>
      </div>
      <div class="list-items background-white">

        <app-page-content page="WARRANTY_LIST" field="intro"></app-page-content>

        <div *ngIf="warrantyList.pending">
          <app-waiting-spinner kind="spinner">Fetching warranties...</app-waiting-spinner>
        </div>

        <app-message-panel kind="ERROR" *ngIf="warrantyList.error">{{ warrantyList.error }}</app-message-panel>

        <div *ngIf="!warrantyList.pending && warrantyList.length === 0">
          <p>No warranties have been registered</p>
        </div>

        <div class="table-container" *ngIf="!warrantyList.pending && warrantyList.warranties.length > 0">
          <div class="row pagination-wrapper">
            <div class="col-xs-9 text-center">
              <ul class="pagination">
                <!-- / Prev btns -->
                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(1)">&lt;&lt; First</a>
                </li>

                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevWarranties(5)">&lt;&lt;</a>
                </li>

                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevWarranties()">&lt;</a>
                </li>
                <!-- / Prev btns -->

                <!-- Pagination -->
                <li *ngFor="let num of pagination" [class.active]="num === pageNumWarranties" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(num)">{{ num }}</a>
                </li>
                <!-- / Pagination -->


                <!-- / Next btns -->
                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="pageNextWarranties()">&gt;</a>
                </li>

                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="pageNextWarranties(5)">&gt;&gt;</a>
                </li>

                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(totalPagesWarranties)">Last &gt;&gt;</a>
                </li>
                <!-- / Next btns -->
              </ul>
            </div>

            <div class="col-xs-3">
              <form [formGroup]="fg" novalidate>
                <mat-form-field>
                  <mat-select formControlName="orderBy">
                    <mat-option [value]="false">Sort by: Newest</mat-option>
                    <mat-option [value]="true">Sort by: Oldest</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </div>
          <br>
          <table class="item-table">
            <tr class="border-light-gray no-pad">
              <th>
                <div class="row-title align-center color-white background-blue-black ">
                  <div class="content">
                    <i class="icon-user"></i>
                    <p class="no-margin">Customer name</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center color-white background-blue-black ">
                  <div class="content">
                    <i class="icon-date"></i>
                    <p class="no-margin">Warranty date</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center color-white background-blue-black ">
                  <div class="content">
                    <i class="icon-timer"></i>
                    <p class="no-margin">Warranty expiration</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center color-white background-blue-black ">
                  <div class="content">
                    <i class="icon-fitted"></i>
                    <p class="no-margin">Product</p>
                  </div>
                </div>
              </th>
              <th>
                <div class="row-title align-center color-white background-blue-black ">
                  <div class="content">
                    <i class="icon-rewards"></i>
                    <p class="no-margin">Points</p>
                  </div>
                </div>
              </th>
            </tr>
            <tr *ngFor="let w of warrantyList.warranties" class="item border-light-gray" (click)="editWarranty(w)">
              <td class="color-gray">
                <span *ngIf="w.customer">
                  {{w.customer.customerTitle}} {{w.customer.customerSurname}}
                </span>
                <span *ngIf="!w.customer"> - </span>
              </td>
              <td class="color-gray">{{ toDate(w.dateOfInstallation) | date }}</td>
              <td *ngIf="w.expiryDate" class="color-gray">{{ formatDate(w.expiryDate) | date }}</td>
              <td *ngIf="!w.expiryDate" class="color-gray"></td>
              <td class="color-gray">{{ w.productType }}</td>
              <td class="color-gray" [innerHTML]="w | warrantyPoints"></td>
            </tr>
          </table>
          <br>
          <br>
        </div>
      </div>
    </div>

  </div>
</div>