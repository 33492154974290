<div class="widget-body service-widget background-white col-xs-12">
  <div [class]="'service-container border-box border-lighter-gray ' + additionalClasses" *ngIf="records">

    <div class="text-center" *ngIf="pending">
      <app-waiting-spinner kind="spinner"></app-waiting-spinner>
    </div>

    <div class="widget-list" *ngIf="!pending">

      <div class="widget-item" *ngFor="let record of records.slice(0, 4)" (click)="handleClickRecord(record)">
        <p class="record-time">{{getRecordDate(record)}} | <strong>{{getRecordTime(record)}}</strong></p>
        <div class="record-details border-lighter-gray">
          <p>{{getRecordDescription(record)}}</p>
          <i>{{getRecordLocation(record)}}</i>
        </div>
        <hr />
      </div>

    </div>

    <div class="text-center widget-buttons">
      <button class="btn background-green color-white no-radius" (click)="goToDashboard()">Job record dashboard</button>
      <button class="btn background-green color-white no-radius" (click)="goToJob()">Add new record</button>
    </div>

  </div>
</div>
