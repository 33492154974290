/**
 * Mock for JobService (see main service for full details)
 */


import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';




import { Injectable }                        from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import * as moment from 'moment';

import { Customer } from '../models/customer';

import {
  ProductType,
  RegisterJobRequest,
  RegisterJobResponse,
  FetchJobRequest,
  FetchJobResponse,
  FetchJobPdfRequest,
  FetchJobPdfResponse,
  Job,
} from '../models/job';

@Injectable()
export class JobService {

  getProductTypes(): Observable<ProductType[]> {
    return observableOf([
        {id: 'type1', name: 'Product type 1'},
        {id: 'type2', name: 'Product type 2'},
        {id: 'type3', name: 'Product type 3'},
        {id: 'type4', name: 'Product type 4'},
        {id: 'type5', name: 'Product type 5'},
      ]).pipe(
      delay(1000));
  }

  getJobData(req: FetchJobRequest): Observable<FetchJobResponse> {

    const jobData: Job = Job.getExample();

    jobData.customer = Customer.toFormData(jobData.customer);

    return observableOf({
      job: jobData,
      error: null,
      message: null,
    }).pipe(
    delay(1000));
  }

  getJobPDF(req: FetchJobPdfRequest): Observable<FetchJobPdfResponse> {
    const valid: boolean = !!req.id;
    return observableOf({
        error: valid ? null : 'Invalid Job ID',
        pdfData: valid
          ? 'JVBERi0xLjAKMSAwIG9iajw8L1R5cGUvQ2F0YWxvZy9QYWdlcyAyIDAgUj4+ZW5kb2JqIDIgMCBvYmo8PC9UeXBlL1BhZ2VzL0tpZHNbMyAwIFJdL0NvdW50IDE+PmVuZG9iaiAzIDAgb2JqPDwvVHlwZS9QYWdlL01lZGlhQm94WzAgMCAzIDNdPj5lbmRvYmoKeHJlZgowIDQKMDAwMDAwMDAwMCA2NTUzNSBmCjAwMDAwMDAwMTAgMDAwMDAgbgowMDAwMDAwMDUzIDAwMDAwIG4KMDAwMDAwMDEwMiAwMDAwMCBuCnRyYWlsZXI8PC9TaXplIDQvUm9vdCAxIDAgUj4+CnN0YXJ0eHJlZgoxNDkKJUVPRgo='
          : null,
      }).pipe(
      delay(2000));
  }

  submit(req: RegisterJobRequest): Observable<RegisterJobResponse> {
    const valid = true;

    // NOTE: submit req.warranty as request payload to API

    return observableOf({
        error:   !valid,
        message: valid ? 'Job submission successful' : 'The request contained an invalid serial number',
      }).pipe(
      delay(1000));
  }

}
