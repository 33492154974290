import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
} from 'angular-calendar';
import * as moment from 'moment';

import { DiaryEvent, EventType } from '../../../models/diary';
import { DiaryListEventsModalComponent } from '../diary-list-events-modal/diary-list-events-modal.component';
import { DiarySingleEventComponent } from '../diary-single-event/diary-single-event.component';
import { CustomDateFormatter } from './custom-date-formatter';


interface DiaryCalendarEvent extends CalendarEvent {
  diaryEvent: DiaryEvent;
}

/**
 * Summary
 *    Display the diary's main body.
 *    
 *
 * Description
 *    Display the diary interface and allow the user to cycle dates and switch the current view.
 *    events will filter into the correct view.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-calendar',
  templateUrl: './diary-calendar.component.html',
  styleUrls: ['./diary-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class DiaryCalendarComponent implements OnChanges, OnDestroy, OnInit {
  // Array of events to display
  @Input() events: DiaryEvent[] = [];

  // Array of available event types
  @Input() eventTypes: EventType[] = [];

  // Flag to indicate if events cannot be edited
  @Input() readOnly: boolean = false;

  // Default to viewing today's date in "month" mode
  @Input() view: string = 'month';
  @Input() viewDate: Date = new Date();

  // Flag to indicate if ".small" CSS class should be applied
  @Input() small: boolean = false;


  // Fired after an event has been added via a modal pop-up
  @Output() onEventAdded = new EventEmitter<DiaryEvent>();

  // Fired when the delete button is clicked
  @Output() onEventDeleted = new EventEmitter<DiaryEvent>();

  // Fired after an event has been edited via a modal pop-up
  @Output() onEventEdited = new EventEmitter<DiaryEvent>();


  // Input events mapped to DiaryCalendarEvent
  private _events: DiaryCalendarEvent[] = null;

  // Actions attached to each DiaryCalendarEvent (unless readOnly is set)
  private actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent<DiaryCalendarEvent> }): any => {
        this.editEvent(event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent<DiaryCalendarEvent> }): void => {
        this.deleteEvent(event);
      }
    }
  ];

  // References to modal dialogs
  private dialogRef_add;
  private dialogRef_edit;
  private dialogRef_list;

  /**
   * Constructor for page
   *
   * @param {MatDialog} dialog Initialises a MatDialog component so that a new modal can be created
   */
  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  /**
   * Called when the onChange event is triggered internally, will remap the events based on the changes
   */
  ngOnChanges() {
    this._events = this.events && this.events.length > 0
      ? this.events.map(evt => this.toDiaryCalendarEvent(evt, this.readOnly ? null : this.actions, !this.readOnly))
      : null;
  }

  /**
   * Called when the user selects a blank date on the calendar or the "Add event" button
   *
   * @param {Date} date The selected date will be passed through considering the user selected a blank day on the diary
   */
  addEvent(date: Date): void {
    if (this.readOnly)
      return;

    // open a new DiarySingleEventComponent within a popup 
    this.dialogRef_add = this.dialog.open(DiarySingleEventComponent, {
      data: {
        date: date ? date : new Date(),
        event: null,
        eventTypes: this.eventTypes,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    // If the modal is closed with data, emit that data back to diary.component.ts
    // so that the new event can be added
    this.dialogRef_add.afterClosed().subscribe(result => {
      if (result)
        this.onEventAdded.emit(result);
    });
  }

  /**
   * Called when the user selects a date on the calendar
   *
   * @param {Date} date The date of the selected day
   * @param {DiaryCalendarEvent[]} events The list of events linked to the given date
   */
  dayClicked({ date, events }: { date: Date; events: DiaryCalendarEvent[] }): void {

    // If the day contains no events, open the DiarySingleEventComponent as a
    // modal to add a new event
    if (!events || events.length === 0)
      this.addEvent(date);

    // If the day contains a single event, open the DiarySingleEventComponent
    // as a modal to edit it
    else if (events.length === 1)
      this.editEvent(events[0]);

    // If the day contains multiple events, open the
    // DiaryListEventsModalComponent as a modal to list them
    else {
      this.dialogRef_list = this.dialog.open(DiaryListEventsModalComponent, {
        data: {
          date,
          events: events.map(e => e.diaryEvent)
        },
        panelClass: 'feature-modal-dialog',
      });

      // If the user has selected a date from the list, open it in a separate modal
      this.dialogRef_list.afterClosed().subscribe(result => {
        if (result)
          this.editEvent(this.toDiaryCalendarEvent(result));
      });
    }
  }

  /**
   * Called if the user selects the delete event button from the DiarySingleEventComponent modal
   *
   * @param {DiaryCalendarEvent} e The event to be deleted
   */
  deleteEvent(e: CalendarEvent<DiaryCalendarEvent>): void {
    if (this.readOnly)
      return;

    // Emit the event to be deleted back to to diary.component.ts
    this.onEventDeleted.emit(e.meta.diaryEvent);
  }

  /**
   * Called if the user selects an existing event from the diary or diary event list
   *
   * @param {DiaryCalendarEvent} e The event to be edited
   */
  editEvent(e: CalendarEvent<DiaryCalendarEvent>): void {
    // Open a new DiarySingleEventComponent modal with the selected event's data
    this.dialogRef_edit = this.dialog.open(DiarySingleEventComponent, {
      data: {
        event: e.meta.diaryEvent,
        readOnly: this.readOnly,
        eventTypes: this.eventTypes,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    // Determine which action to dispatch based on the data passed back when
    // the modal is closed.
    this.dialogRef_edit.afterClosed().subscribe(result => {
      if (result && !this.readOnly && result.returnRef) {
        if (result.returnRef === 'SAVE')
          this.onEventEdited.emit(Object.assign({}, e.meta.diaryEvent, result));
        else if (result.returnRef === 'DELETE')
          this.deleteEvent(this.toDiaryCalendarEvent(result));
      }
    });
  }

  /**
   * Update the times of a given event 
   *
   * @param {any} event     The event to be edited
   * @param {any} newStart The new start time for the event
   * @param {any} newEnd   The new end time for the event
   */
  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    if (this.readOnly)
      return;

    // Update the times of a given event
    this.onEventEdited.emit(
      Object.assign(
        {},
        (event as DiaryCalendarEvent).diaryEvent,
        DiaryEvent.updateTimes((event as DiaryCalendarEvent).diaryEvent, newStart, newEnd)
      )
    );
  }

  /**
   * Called when the user clicks a blank space on the day view of the diary
   *
   * @param {Date} date The date of the event to add
   */
  hourClicked({ date }: { date: Date }): void {
    if (this.readOnly)
      return;

    // Open a blank event modal with the given date
    this.addEvent(date);
  }

  /**
   * Create a new DiaryCalendarEvent object using event data
   *
   * @param {DiaryEvent} v                    The data for the given event
   * @param {CalendarEventAction[]} actions   Action buttons to be displayed in the modal
   * @param {boolean} draggable               Flag to determine if event can be dragged
   *
   * @return {DiaryCalendarEvent} Return the event data and actions in the form of a new DiaryCalendarEvent object
   */
  toDiaryCalendarEvent(v: DiaryEvent, actions: CalendarEventAction[] = null, draggable: boolean = true): DiaryCalendarEvent {
    // The start date of the event based on the value of the event's allDay flag
    const startDate = v.allDay ? moment.utc(v.date + ' 00:00:00') : moment.utc(v.date + ' ' + v.time);
    // The end date of the event calculated by adding the duration to the start date
    const endDate = moment(startDate).add(v.duration, 'm');

    // The list of available colours for the event
    const colours = {
      red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
      },
      blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
      },
      yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
      },
      grey: {
        primary: '#333333',
        secondary: '#aaaaaa',
      },
    };

    // Set the colour based on the event state
    let colour;
    if (!v.userEvent)
      colour = colours.red;
    else if (!v.committed)
      colour = colours.grey;
    else if (v.deleted)
      colour = colours.red;
    else if (v.userEvent)
      colour = colours.blue;
    else
      colour = colours.yellow;

    // return the event data as a DiaryCalendarEvent object
    return {
      diaryEvent: v,

      start: startDate.toDate(),
      end: endDate.toDate(),
      title: v.details,
      color: colour,

      // Only add action buttons for user-created events
      actions: v.userEvent ? actions : null,

      // Event can only be moved/resized if it is a user-created event
      resizable: {
        beforeStart: draggable && v.userEvent,
        afterEnd: draggable && v.userEvent,
      },

      // TODO: allow dragging, see https://github.com/mattlewis92/angular-calendar/issues/373
      //draggable: draggable && v.userEvent,
      draggable: false,
    };
  }

  /**
   * Overwrite the month view within the angular-calendar component
   *
   * @param {CalendarMonthViewDay[]} body data passed in from angular-calendar
   */
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    // overwrite the css class of each day component to remove native unwanted styling
    body.forEach(day => {
      if (day.isFuture) {
        if (moment(day.date).isSame(moment(), 'week')) {
          day.cssClass = 'same-week-event';
        }
      }
      day.events.forEach(event => {
        if (!event['diaryEvent'].userEvent) {
          day.cssClass = 'adey-event';
        }
      });
    });

  }
}
