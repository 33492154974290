import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AcceptInvitationQueryResponse,
  ReviewInvitationQueryResponse,
  SendInvitationQueryResponse,
} from '../../models/invitation';
import { InvitationService } from '../../services/invitation.service';
import * as ActionTypes from '../actions/invitation';

@Injectable()
export class InvitationEffects {
  constructor(
    private service: InvitationService,
    private actions$: Actions
  ) { }

  /**
   * For a ReviewInvitationQueryResponseAction, call service.reviewInvitation() and
   * dispatch a new ReviewInvitationQueryResponseAction with the response.
   */
   reviewInvitation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REVIEW_INVITATION_QUERY_REQUEST),
    switchMap((req: ActionTypes.ReviewInvitationQueryRequestAction): Observable<ActionTypes.ReviewInvitationQueryResponseAction> =>
      this.service.reviewInvitation(req.payload).pipe(
        map((res: ReviewInvitationQueryResponse): ActionTypes.ReviewInvitationQueryResponseAction =>
          new ActionTypes.ReviewInvitationQueryResponseAction(res)))
    )));

  /**
   * For a SendInvitationQueryResponseAction, call service.sendInvitation() and
   * dispatch a new SendInvitationQueryResponseAction with the response.
   */
   sendInvitation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SEND_INVITATION_QUERY_REQUEST),
    switchMap((req: ActionTypes.SendInvitationQueryRequestAction): Observable<ActionTypes.SendInvitationQueryResponseAction> =>
      this.service.sendInvitation(req.payload).pipe(
        map((res: SendInvitationQueryResponse): ActionTypes.SendInvitationQueryResponseAction =>
          new ActionTypes.SendInvitationQueryResponseAction(res)))
    )));

  /**
   * For a AcceptInvitationQueryResponseAction, call service.acceptInvitation() and
   * dispatch a new AcceptInvitationQueryResponseAction with the response.
   */
   acceptInvitation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ACCEPT_INVITATION_QUERY_REQUEST),
    switchMap((req: ActionTypes.AcceptInvitationQueryRequestAction): Observable<ActionTypes.AcceptInvitationQueryResponseAction> =>
      this.service.acceptInvitation(req.payload).pipe(
        map((res: AcceptInvitationQueryResponse): ActionTypes.AcceptInvitationQueryResponseAction =>
          new ActionTypes.AcceptInvitationQueryResponseAction(res)))
    )));
}
