/**
 * invitation actions: defines actions for accept and collect the invitation details
 */

import { Action } from '@ngrx/store';


// Import models
import {
  ReviewInvitationQueryRequest,
  ReviewInvitationQueryResponse,
  AcceptInvitationQueryRequest,
  AcceptInvitationQueryResponse,
  SendInvitationQueryRequest,
  SendInvitationQueryResponse
} from '../../models/invitation';


// Action type definitions
export const REVIEW_INVITATION_QUERY_REQUEST  = 'REVIEW_INVITATION_QUERY_REQUEST';
export const REVIEW_INVITATION_QUERY_RESPONSE = 'REVIEW_INVITATION_QUERY_RESPONSE';
export const ACCEPT_INVITATION_QUERY_REQUEST  = 'ACCEPT_INVITATION_QUERY_REQUEST';
export const ACCEPT_INVITATION_QUERY_RESPONSE = 'ACCEPT_INVITATION_QUERY_RESPONSE';
export const SEND_INVITATION_QUERY_REQUEST    = 'SEND_INVITATION_QUERY_REQUEST';
export const SEND_INVITATION_QUERY_RESPONSE   = 'SEND_INVITATION_QUERY_RESPONSE';


// Action definitions
export class ReviewInvitationQueryRequestAction implements Action {
  readonly type = REVIEW_INVITATION_QUERY_REQUEST;
  constructor(public payload: ReviewInvitationQueryRequest) {}
}

export class ReviewInvitationQueryResponseAction implements Action {
  readonly type = REVIEW_INVITATION_QUERY_RESPONSE;
  constructor(public payload: ReviewInvitationQueryResponse) {}
}

export class AcceptInvitationQueryRequestAction implements Action {
  readonly type = ACCEPT_INVITATION_QUERY_REQUEST;
  constructor(public payload: AcceptInvitationQueryRequest) {}
}

export class AcceptInvitationQueryResponseAction implements Action {
  readonly type = ACCEPT_INVITATION_QUERY_RESPONSE;
  constructor(public payload: AcceptInvitationQueryResponse) {}
}

export class SendInvitationQueryRequestAction implements Action {
  readonly type = SEND_INVITATION_QUERY_REQUEST;
  constructor(public payload: SendInvitationQueryRequest) {}
}

export class SendInvitationQueryResponseAction implements Action {
  readonly type = SEND_INVITATION_QUERY_RESPONSE;
  constructor(public payload: SendInvitationQueryResponse) {}
}

// Export Actions type as a union of all defined actions
export type Actions =
  | ReviewInvitationQueryRequestAction
  | ReviewInvitationQueryResponseAction
  | AcceptInvitationQueryRequestAction
  | AcceptInvitationQueryResponseAction
  | SendInvitationQueryRequestAction
  | SendInvitationQueryResponseAction
  ;

