/**
 * content-pages models
 */

/**
 * Encapsulates a single field on a single value
 */
export class PageField {
  // Name of the field as used when selecting the field to display on the page
  fieldName: string;

  // Field type which determines the type of input control given: "text"
  // (single-line text input), "textarea" (multi-line text input) or "rich"
  // (rich-text input, outputting to HTML)
  type: string;

  // Field label
  label: string;

  // Initial/default value of field
  initial: string;

  // Current value of field (will be displayed whenever the field content is
  // requested)
  current: string;

  // ID of the field as stored in the API (used when updating)
  id?: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {PageField}
   */
  static fromAPI(data: any): PageField {
    return {
      id: data.id.toString(),
      fieldName: data.fieldname,
      type: data.type,
      label: data.label,
      initial: data.initial,
      current: data.current,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {PageField} v
   * @return {object}
   */
  static toAPI(v: PageField): any {
    return {
      id: v.id ? parseInt(v.id, 10) : null,
      fieldname: v.fieldName,
      type: v.type,
      label: v.label,
      initial: v.initial,
      current: v.current,
    };
  }
}

/**
 * Encapsulates a single page on the site that has one or more PageFields
 * attached
 */
export class SitePage {
  // ID of the page (e.g. "DIARY") used when selecting a field from a page to
  // display
  id: string;

  // Page title and description (shown in admin area)
  title: string;
  description: string;

  // Array of fields belonging to page
  fields: PageField[];

  // ID from database
  dbId?: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {SitePage}
   */
  static fromAPI(data: any): SitePage {
    return {
      id: data.code,
      dbId: data.id.toString(),
      title: null,
      description: null,
      fields: data.sitepagefield_by_sitepage_id
        ? data.sitepagefield_by_sitepage_id.map(PageField.fromAPI)
        : [],
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {SitePage} v
   * @return {object}
   */
  static toAPI(v: SitePage): any {
    return {
      id: v.dbId ? parseInt(v.dbId, 10) : null,
      code: v.id,
    };
  }
}

export interface FetchPageContentResponse {
  error: string;
  pages: SitePage[];
}

export interface UpdatePageContentRequest {
  page: SitePage;
}

export interface UpdatePageContentResponse {
  error: string;
  page: SitePage;
}

/**
 * Hard-coded initial content for every SitePage and every PageField
 */
export const pages: SitePage[] = [
  {
    id: 'CONTENT_ARCHIVE',
    title: 'Content archive',
    description:
      'Main hub page allowing access to sales tools, resources, ROI calculator',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: null,
        current: null,
      },
    ],
  },
  {
    id: 'DIARY',
    title: 'Diary',
    description: 'Main diary page showing calendar and event list',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Stay organised with the diary. Here you can add important dates or events and also keep a log of
                  previously completed jobs and when the services are due.
                  We’ll also add in any ADEY® training courses that we think you might find useful, and with a few
                  simple clicks you can book yourself on quickly and easily.`,
        current: `Stay organised with the diary. Here you can add important dates or events and also keep a log of
                  previously completed jobs and when the services are due.
                  We’ll also add in any ADEY® training courses that we think you might find useful, and with a few
                  simple clicks you can book yourself on quickly and easily.`,
      },
    ],
  },
  {
    id: 'DASHBOARD',
    title: 'Dashboard',
    description: 'Main user dashboard containing widgets',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: null,
        current: null,
      },
    ],
  },
  {
    id: 'LOGIN',
    title: 'Login',
    description: 'Where users login to the site',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: null,
        current: null,
      },
    ],
  },
  {
    id: 'NOT_FOUND',
    title: 'Not found (404)',
    description:
      'Page that is displayed when the user requests an invalid page (404 error)',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: null,
        current: null,
      },
    ],
  },
  {
    id: 'PRODUCT_SELECTOR',
    title: 'Product selector',
    description: 'Used by installer to get suggested products for a job',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Part of the ADEY® Best Practice approach is knowing the right product to use in each situation.
                  If you’re unsure, or you simply want to confirm what you think is right, then the product selector
                  is a handy tool. <br>
                  Answer 5 simple questions about your customers’ central heating system and we will provide a recommendation
                  for each stage of the ADEY Best Practice Approach – whether it’s for an installation,
                  service or repair, using the right combination of Clean, MagnaCleanse® , MagnaClean®, Protect, Test and Maintain.`,
        current: `Part of the ADEY® Best Practice approach is knowing the right product to use in each situation.
                  If you’re unsure, or you simply want to confirm what you think is right, then the product selector
                  is a handy tool. <br>
                  Answer 5 simple questions about your customers’ central heating system and we will provide a recommendation
                  for each stage of the ADEY Best Practice Approach – whether it’s for an installation,
                  service or repair, using the right combination of Clean, MagnaCleanse® , MagnaClean®, Protect, Test and Maintain.`,
      },
    ],
  },
  {
    id: 'PROFILE',
    title: 'Profile',
    description: 'User profile page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Your profile page is all about you.  Use this as a hub to keep an overview of all the important information you
                  may need. Make sure you include your company name and logo, your accreditations and keep your contact details up
                  to date. Your profile also tells you how many points you’ve earned, your upcoming events or appointments,
                  and is a place to keep an eye on those glowing customer reviews.`,
        current: `Your profile page is all about you.  Use this as a hub to keep an overview of all the important information you
                  may need. Make sure you include your company name and logo, your accreditations and keep your contact details up
                  to date. Your profile also tells you how many points you’ve earned, your upcoming events or appointments,
                  and is a place to keep an eye on those glowing customer reviews.`,
      },
    ],
  },
  {
    id: 'REGISTER',
    title: 'Register',
    description: 'User registration page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `<p>ADEY ProClub™ is an essential tool developed to help you manage your business. It enables you to easily access your customer service records, earn points to exchange for ADEY merchandise and Love2Shop vouchers, and all members of the ADEY ProClub™
                  benefit from a 20% discount on ADEY® Spares. What’s more, it’s all in the palm of your hand.</p>
                  <ul>
                    <li><strong>App</strong> – Our barcode scanner makes it even easier to register warranties, simply scan the barcode and you’re done.</li>
                    <li><strong>Earn rewards</strong> – Collect points by registering <i>Professional2®</i> and <i>Micro2®</i> warranties which can be exchanged for ADEY® merchandise and Love2Shop vouchers.</li>
                    <li><strong>ADEY® Spares discount</strong> – All members of the ADEY ProClub™ benefit from a 20% discount on ADEY® Spares.</li>
                  </ul>`,
        current: `<p>ADEY ProClub™ is an essential tool developed to help you manage your business. It enables you to easily access your customer service records, earn points to exchange for ADEY merchandise and Love2Shop vouchers, and all members of the ADEY ProClub™
                  benefit from a 20% discount on ADEY® Spares. What’s more, it’s all in the palm of your hand.</p>
                  <ul>
                    <li><strong>App</strong> – Our barcode scanner makes it even easier to register warranties, simply scan the barcode and you’re done.</li>
                    <li><strong>Earn rewards</strong> – Collect points by registering <i>Professional2®</i> and <i>Micro2®</i> warranties which can be exchanged for ADEY® merchandise and Love2Shop vouchers.</li>
                    <li><strong>ADEY® Spares discount</strong> – All members of the ADEY ProClub™ benefit from a 20% discount on ADEY® Spares.</li>
                  </ul>`,
      },
    ],
  },
  {
    id: 'HOMEOWNER_VIDEOS',
    title: 'Homeowner Videos',
    description: 'Listing of videos',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `The ADEY® videos are quick and easy to engage with. Whether you want to show your customer how ADEY products can benefits
                  them, double-check the installation instructions, or check out the latest technology from ADEY – it’s all here.`,
        current: `The ADEY® videos are quick and easy to engage with. Whether you want to show your customer how ADEY products can benefits
                  them, double-check the installation instructions, or check out the latest technology from ADEY – it’s all here.`,
      },
    ],
  },
  {
    id: 'REWARDS',
    title: 'Rewards',
    description: 'User rewards listing page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `<p>It’s time to reward yourself after all that hard work! Collect points by registering <em>Professional2®</em> and <em>Micro2®</em> warranties which can be exchanged for ADEY® merchandise and Love2Shop vouchers.</p>
                  <p>The more you register, the more you earn. As you install and register your <em>MagnaClean Professional2®</em> and <em>Micro2®</em> filters, your earning potential increases. The chart below outlines how points are allocated as you move up the scale.
                    After the first 12 months of membership, your rewards level will be reviewed every month to establish how many filters you have registered during the previous 12 months to confirm your membership level. Please read the <a class="white-link" href="http://www.adey.com/terms-and-conditions"
                    target="_blank" rel="noopener">terms and conditions</a>.</p>`,
        current: `<p>It’s time to reward yourself after all that hard work! Collect points by registering <em>Professional2®</em> and <em>Micro2®</em> warranties which can be exchanged for ADEY® merchandise and Love2Shop vouchers.</p>
                  <p>The more you register, the more you earn. As you install and register your <em>MagnaClean Professional2®</em> and <em>Micro2®</em> filters, your earning potential increases. The chart below outlines how points are allocated as you move up the scale.
                    After the first 12 months of membership, your rewards level will be reviewed every month to establish how many filters you have registered during the previous 12 months to confirm your membership level. Please read the <a class="white-link" href="http://www.adey.com/terms-and-conditions"
                    target="_blank" rel="noopener">terms and conditions</a>.</p>`,
      },
    ],
  },
  {
    id: 'CUSTOMER_INFORMATION',
    title: 'Product & Customer Information',
    description: 'Listing of downloadable information',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Here you’ll find all the product brochures and detailed installation guides to answer any questions you may
                  have whilst you are out and about on a job. In addition you can also access handy infographics and heating
                  guides for your customers.<br>
                  Visit the videos page for installation demonstrations or to show your customers how it works and how it
                  could benefit them.`,
        current: `Here you’ll find all the product brochures and detailed installation guides to answer any questions you may
                  have whilst you are out and about on a job. In addition you can also access handy infographics and heating
                  guides for your customers.<br>
                  Visit the videos page for installation demonstrations or to show your customers how it works and how it
                  could benefit them.`,
      },
    ],
  },
  {
    id: 'SERVICE_RECORDS',
    title: 'Job records',
    description: "Listing of a user's job records and stats",
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Every time you install a boiler or central heating system, attend a repair, call-out or service
                  you can register each job and ADEY® product that you use.  By doing so, you’ll have a
                  comprehensive history of all the activity you have carried out for each customer, and
                  you can then refer back to the service record whenever you need to, and download or print
                  a copy for your customer.<br>
                  This is a complete overview of every completed job or warranty registration that you have logged.
                  You can ensure that you’ve completed each stage of the ADEY Best Practice approach, access water
                  test results, and if you want to see more detail for individual jobs, you simply need to click on
                  the record.`,
        current: `Every time you install a boiler or central heating system, attend a repair, call-out or service
                  you can register each job and ADEY® product that you use.  By doing so, you’ll have a
                  comprehensive history of all the activity you have carried out for each customer, and
                  you can then refer back to the service record whenever you need to, and download or print
                  a copy for your customer.<br>
                  This is a complete overview of every completed job or warranty registration that you have logged.
                  You can ensure that you’ve completed each stage of the ADEY Best Practice approach, access water
                  test results, and if you want to see more detail for individual jobs, you simply need to click on
                  the record.`,
      },
    ],
  },
  {
    id: 'WARRANTY_LIST',
    title: 'Warranty list',
    description: 'Warranty listing page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: null,
        current: null,
      },
    ],
  },
  {
    id: 'WARRANTY_STATS',
    title: 'Warranty statistics',
    description: 'Warranty statistics page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Here is an overview of the filter warranties that you have registered. To see registrations in
                  the past, simply select different dates and the graph will change automatically.`,
        current: `Here is an overview of the filter warranties that you have registered. To see registrations in
                  the past, simply select different dates and the graph will change automatically.`,
      },
    ],
  },
  {
    id: 'WARRANTY_SUBMIT',
    title: 'Warranty submit',
    description: 'Warranty submission page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `<p>Registering the warranty for the MagnaClean® filters you fit on each job ensures that you always have a record of the work you’ve completed and provides peace of mind. Once registered, from the date of installation your warranty covers you against
                  defective materials or manufacture. If, in the opinion of ADEY®, the product fails to meet published specifications due to a defect in materials or manufacture, we will repair or replace the defective unit free of charge. <a href="http://www.adey.com/terms#tenyearwarranty"
                  target="_blank" rel="noopener">Terms and conditions</a><br><br> To register the MagnaClean warranty you’ll need to fill out the form with the customer’s details and the product serial number. We’ll automatically add a reminder into your calendar and send you an email to make
                  sure you know when it’s time for the annual service so you can contact your customer when the time comes.<br><br> Don’t forget, if you’re registering a <em>MagnaClean Professional2® </em>or <em>Micro2®</em>&nbsp;you’ll be able to collect up to 5
                  points per <a href="rewards" target="_blank" rel="noopener">filter</a>, that you can exchange for ADEY branded merchandise or high street vouchers.</p>`,
        current: `<p>Registering the warranty for the MagnaClean® filters you fit on each job ensures that you always have a record of the work you’ve completed and provides peace of mind. Once registered, from the date of installation your warranty covers you against
                  defective materials or manufacture. If, in the opinion of ADEY®, the product fails to meet published specifications due to a defect in materials or manufacture, we will repair or replace the defective unit free of charge. <a href="http://www.adey.com/terms#tenyearwarranty"
                  target="_blank" rel="noopener">Terms and conditions</a><br><br> To register the MagnaClean warranty you’ll need to fill out the form with the customer’s details and the product serial number. We’ll automatically add a reminder into your calendar and send you an email to make
                  sure you know when it’s time for the annual service so you can contact your customer when the time comes.<br><br> Don’t forget, if you’re registering a <em>MagnaClean Professional2® </em>or <em>Micro2®</em>&nbsp;you’ll be able to collect up to 5
                  points per <a href="rewards" target="_blank" rel="noopener">filter</a>, that you can exchange for ADEY branded merchandise or high street vouchers.</p>`,
      },
    ],
  },
  {
    id: 'JOB_SUBMIT',
    title: 'Job submit',
    description: 'Job submission page',
    fields: [
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Introduction',
        initial: `Registering the warranty for the MagnaClean® filters you fit on each job ensures that you always have
                  a record of the work you’ve completed and provides peace of mind. Once registered, from the date of
                  installation your warranty covers you against defective materials or manufacture. If, in the opinion
                  of ADEY®, the product fails to meet published specifications due to a defect in materials or manufacture,
                  we will repair or replace the defective unit free of charge.
                  <a href="http://www.adey.com/terms#tenyearwarranty" target="_blank">Terms and conditions</a><br><br>
                  Follow the ADEY Best Practice™ approach when registering a job – by adding all the ADEY products you
                  use you’ll have a comprehensive service record to look back on and send on to your customers. <br><br>
                  By following each of the steps Clean, MagnaCleanse® , MagnaClean®, Protect, Test and Maintain, and
                  making a record of them in your ADEY ProClub™ account, you can make sure you have all the information
                  for the customer on how you have treated their system.  This includes accurate water test results –
                  click <a href="http://www.adey.com/installers/watertreatment/watertesting#watertestkit" target="_blank">here</a>
                  to find out more about the ADEY Water Testing Service and how you and your customers could benefit.<br><br>
                  Remember you can register any MagnaClean filter through the ADEY ProClub™ but with MagnaClean
                  Professional2® you can earn up to 5 points per filter which you can exchange for high street
                  vouchers and branded merchandise.`,
        current: `Registering the warranty for the MagnaClean® filters you fit on each job ensures that you always have
                  a record of the work you’ve completed and provides peace of mind. Once registered, from the date of
                  installation your warranty covers you against defective materials or manufacture. If, in the opinion
                  of ADEY®, the product fails to meet published specifications due to a defect in materials or manufacture,
                  we will repair or replace the defective unit free of charge.
                  <a href="http://www.adey.com/terms#tenyearwarranty" target="_blank">Terms and conditions</a><br><br>
                  Follow the ADEY Best Practice™ approach when registering a job – by adding all the ADEY products you
                  use you’ll have a comprehensive service record to look back on and send on to your customers. <br><br>
                  By following each of the steps Clean, MagnaCleanse® , MagnaClean®, Protect, Test and Maintain, and
                  making a record of them in your ADEY ProClub™ account, you can make sure you have all the information
                  for the customer on how you have treated their system.  This includes accurate water test results –
                  click <a href="http://www.adey.com/installers/watertreatment/watertesting#watertestkit" target="_blank">here</a>
                  to find out more about the ADEY Water Testing Service and how you and your customers could benefit.<br><br>
                  Remember you can register any MagnaClean filter through the ADEY ProClub™ but with MagnaClean
                  Professional2® you can earn up to 5 points per filter which you can exchange for high street
                  vouchers and branded merchandise.`,
      },
    ],
  },
  {
    id: 'test',
    title: 'API Test Page',
    description: 'sitepage model from API used for testing',
    fields: [
      {
        fieldName: 'title',
        type: 'textarea',
        label: 'Title',
        initial: 'Initial title content',
        current: 'Current title content',
      },
      {
        fieldName: 'description',
        type: 'textarea',
        label: 'Description',
        initial: 'Initial description content',
        current: 'Current description content',
      },
      {
        fieldName: 'other',
        type: 'textarea',
        label: 'Other field',
        initial: 'Initial other content',
        current: 'Current other content',
      },
    ],
  },
  {
    id: 'BUSINESS_TOOLS',
    title: 'Business Tools',
    description: 'Business tools landing page',
    fields: [
      {
        fieldName: 'title',
        type: 'text',
        label: 'Page title',
        initial: 'Business Tools',
        current: 'Business Tools',
      },
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>Helping you grow your business with ease.</p>
          <p>From Service Plans and Financing Solutions to Online Quote Builders and Lead Generation, the NEW ADEY Business Tools will help you work smarter not harder.</p>
          <p>Best of all, they are easy to set up and guratanteed to secure your business earn you more.</p>
          <p>Exclusive offers through ADEY ProClub.</p>`,
        current: `<p>Helping you grow your business with ease.</p>
          <p>From Service Plans and Financing Solutions to Online Quote Builders and Lead Generation, the NEW ADEY Business Tools will help you work smarter not harder.</p>
          <p>Best of all, they are easy to set up and guratanteed to secure your business earn you more.</p>
          <p>Exclusive offers through ADEY ProClub.</p>`,
      },
    ],
  },
  {
    id: 'TRUEQUOTE',
    title: 'TrueQuote',
    description: 'TrueQuote find out more page',
    fields: [
      {
        fieldName: 'title',
        type: 'text',
        label: 'Page title',
        initial: 'TrueQuote',
        current: 'Online Quoting Tool',
      },
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TrueQuote intro</p>`,
        current: ``,
      },
      {
        fieldName: 'content',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TrueQuote content</p>`,
        current: `<p><img src="https://www.adey.com/storage/app/media/banner/Business%20Tools/TrueQuote.svg" alt="" width="363" height="77" /></p>
        <p>Turn your website from a business card into a hard-working lead generation tool with the Online Quote Builder brought to you by TrueQuote.</p>
        <p>Designed to be easily added to your website, this handy tool means your customers can get a boiler quote from you without your involvement.</p>
        <p>Tailor your boiler packages to suit what you like to work with, and set the prices that work for you - it's completely in your control.</p>
        <ul>
        <li style="text-align: left;">Generate leads on your website</li>
        <li>Instant notification of new lead</li>
        <li>Look professional</li>
        <li>Save time on admin</li>
        <li>Reduce time in &lsquo;unlikely to win&rsquo; visits</li>
        <li>Upsell additional products &amp; services</li>
        <li>Fully tailored to your products of choice and charges</li>
        <li>Gain better insight to your online business with detailed analytics</li>
        <li>Get access to special offers and bonus features</li>
        </ul>
        <p><img src="https://www.adey.com/storage/app/media/Business%20Tools/screenshot_mobile.png" alt="" width="424" height="464" /></p>
        <p><br /><strong>EXCLUSIVE ADEY PROCLUB OFFER &ndash; Get 12 months for the price of 10 (&pound;40 saving)</strong></p>
        <p>Sound good? Start your journey to better lead generation today!</p>`,
      },
    ],
  },
  {
    id: 'TRADEHELP',
    title: 'TradeHelp',
    description: 'TradeHelp find out more page',
    fields: [
      {
        fieldName: 'title',
        type: 'text',
        label: 'Page title',
        initial: 'TradeHelp',
        current: 'Service Plans',
      },
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TradeHelp intro</p>`,
        current: ``,
      },
      {
        fieldName: 'content',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TradeHelp content</p>`,
        current: `<p>Generate year-round income and increase customer loyalty with your own dedicated service plans from TradeHelp.</p>
        <p><img src="https://www.adey.com/storage/app/media/Business%20Tools/Logo.jpg" alt="" width="331" height="99" /></p>
        <p>TradeHelp is a quick and easy tool that enables you to offer customised service plans to your customers without hassle. Easy sign-up, complete T&amp;Cs, and total direct debit management mean you can start offering your own plans with ease today.</p>
        <ul>
        <li>Guaranteed income all-year-round</li>
        <li>Plan your time better with long term service view</li>
        <li>Keep customers between annual service schedule</li>
        <li>Professionalise your business</li>
        <li>Easy to setup &amp; easy to use</li>
        <li>No need to manage payments</li>
        </ul>
        <p><img src="https://www.adey.com/storage/app/media/banner/Business%20Tools/tradehelp%20-%20Copy.jpg" alt="" width="534" height="362" /></p>
        <p><strong>EXCLUSIVE ADEY PROCLUB OFFER &ndash; Get your first 3 months FREE</strong></p>
        <p>Sound interesting? Start offering your customers service plans today!</p>`,
      },
    ],
  },
  {
    id: 'INSTALLER_HUB',
    title: 'Installer Hub',
    description: 'Installer Hub find out more page',
    fields: [
      {
        fieldName: 'title',
        type: 'text',
        label: 'Page title',
        initial: 'Installer Hub',
        current: '',
      },
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Page intro',
        initial: '<p>Installer intro</p>',
        current: '',
      },
      {
        fieldName: 'content',
        type: 'rich',
        label: 'Page intro',
        initial: '<p>Installer content</p>',
        current: `<p>Welcome to Installer Hub<br />Your dedicated marketing toolkit<br />Login to get exclusive access to:<br />&nbsp; &nbsp; &bull; Pre-designed marketing literature personalised to you<br />&nbsp; &nbsp; &bull; Technical information and specifications<br />&nbsp; &nbsp; &bull; Product images and logos to help you look professional</p>`,
      },
    ],
  },
  {
    id: 'TRUEQUOTE_WEBSITE',
    title: 'TrueQuote Website',
    description: 'TrueQuote Website find out more page',
    fields: [
      {
        fieldName: 'title',
        type: 'text',
        label: 'Page title',
        initial: 'TrueQuote Website',
        current: 'TrueQuote Website Tool',
      },
      {
        fieldName: 'intro',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TrueQuote Website intro</p>`,
        current: ``,
      },
      {
        fieldName: 'content',
        type: 'rich',
        label: 'Page intro',
        initial: `<p>TrueQuote Website content</p>`,
        current: `<p><img src="https://images.truequote.co.uk/logos/truequote/horizontal-bg-light.svg" alt="TrueQuote logo" width="300" height="63" /></p>
        <p>&nbsp;</p>
        <p><strong>Get a TrueQuote Website &ndash; with Built in quoting boiler quoting tool.</strong></p>
        <p>&nbsp;</p>
        <p>In addition to the original boiler quoting tool &ndash; TrueQuote now provide a website with integrated boiler quoting tool allowing you to advertise all your products and services as well as providing and instant online boiler quote.</p>
        <p>&nbsp;</p>
        <ul class="ak-ul" data-indent-level="1">
        <li>
        <p data-renderer-start-pos="901">Create your own website in minutes -- boiler quoting tool included.</p>
        </li>
        <li>
        <p data-renderer-start-pos="972">Advertise all aspects of your business &amp; the services &amp; products you provide.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1053">Select from multiple templates.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1088">Fully customisable</p>
        </li>
        <li>
        <p data-renderer-start-pos="1110">Choose from hundreds of preloaded images from your favourite manufacturers or upload your own.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1208">Personalise your website, add your business logo and staff profiles.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1280">Choose your website colour scheme to match your business.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1341">Advertise care plans, finance, renewable technologies as well as Gas, Oil, LPG installations.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1438">Set your own boiler packages and prices.</p>
        </li>
        <li>
        <p data-renderer-start-pos="1482">Full one to one support, set up and guidance provided.</p>
        </li>
        </ul>`,
      },
    ],
  },
];
