/**
 * register-warranty reducer: maintains state used during Warranty registration
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  RegisterWarrantyRequest,
  RegisterWarrantyResponse,
  ProductType,
} from '../../models/warranty';

import * as actions from '../actions/register-warranty';


// Maintains state related to checking promotional codes during Warranty
// registration
interface PromoCodeState {
  pending:     boolean;
  error:       string;
  code:        string;
  valid:       boolean;
  description: string;
}

// State interface definition: maintains all state related to the Warranty
// registration and edit forms
export interface State {

  // Latest Warranty registration request and response
  currentRequest:  RegisterWarrantyRequest;
  currentResponse: RegisterWarrantyResponse;

  // State of submission
  pending: boolean;
  error:   string;
  success: boolean;
  message: string;

  // List of products and pending flag
  productsPending: boolean;
  products:        ProductType[];

  // Current PromoCodeState used during Warranty registration
  promoCode: PromoCodeState;
}


const defaultState: State = {
  currentRequest:  null,
  currentResponse: null,
  error:           null,
  message:         null,
  pending:         false,
  products:        null,
  productsPending: false,
  success:         false,
  promoCode: {
    pending:     false,
    error:       null,
    code:        null,
    valid:       false,
    description: null,
  },
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to check a specified promotional code
     */
    case actions.CHECK_PROMO_CODE_REQUEST:
      return Object.assign({}, state, {
        promoCode: Object.assign({}, state.promoCode, {
          pending:     true,
          error:       null,
          code:        action.payload,
          valid:       false,
          description: null,
        }),
      });

    /**
     * Response from checking a promotional code. Response contains valid flag
     * as well as promotion details if the code is valid.
     */
    case actions.CHECK_PROMO_CODE_RESPONSE:
      return Object.assign({}, state, {
        promoCode: Object.assign({}, state.promoCode, {
          pending:     false,
          error:       action.payload.error,
          valid:       action.payload.valid,
          description: action.payload.valid
            ? action.payload.promoCode.name
            : null,
        }),
      });

    /**
     * Request to fetch products for selection in the form
     */
    case actions.FETCH_PRODUCT_TYPES_REQUEST:
      return Object.assign({}, state, {
        productsPending:  true,
        products:         null
      });

    /**
     * Request from request to fetch products
     */
    case actions.FETCH_PRODUCT_TYPES_RESPONSE:
      return Object.assign({}, state, {
        productsPending:  false,
        products:         action.payload
      });

    /**
     * Clears the state ready for a new Warranty submission
     */
    case actions.REGISTER_WARRANTY_INIT:
      return Object.assign({}, state, {
        currentRequest:  null,
        currentResponse: null,
        pending:         false,
        error:           null,
        success:         false,
        message:         null,
      });

    /**
     * Request to register a new Warranty
     */
    case actions.REGISTER_WARRANTY_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending:         true,
          error:           null,
          currentRequest:  action.payload,
          currentResponse: null,
          message:         null,
        }
      );

    /**
     * Response from registering a new Warranty
     */
    case actions.REGISTER_WARRANTY_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending:         false,
          error:           action.payload.error ? action.payload.message : null,
          currentResponse: action.payload,
          success:         !action.payload.error,
          message:         action.payload.message,
        }
      );

    default:
      return state;
  }
}
