import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UserPopup } from '../../models/popup';
import * as actions from '../actions/popup';

export interface State extends EntityState<UserPopup> {
  error: any;
  pending: boolean;
}

export const adapter: EntityAdapter<UserPopup> = createEntityAdapter<UserPopup>();

export const initialState: State = adapter.getInitialState({
  error: null,
  pending: false,
});

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.GET_USER_POPUP:
      return { ...state, ...{ pending: true } };
    case actions.GET_USER_POPUP_SUCCESS:
      return adapter.setAll(action.payload, { ...state, pending: false });
    case actions.GET_USER_POPUP_FAILURE:
      return { ...state, ...{ error: action.error, pending: false } };
    default:
      return state;
  }
}
