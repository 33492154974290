import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  AddServiceRequestResponse,
  FetchServiceRecordsResponse,
  FetchServiceRecordStatsResponse,
  SetServiceRecordFlagResponse,
  UpdateServiceRecordResponse,
} from '../../models/service-record';
import { ServiceRecordService } from '../../services/service-record.service';
import * as ActionTypes from '../actions/service-record';
import { StoreState } from '../store';

@Injectable()
export class ServiceRecordEffects {
  constructor(
    private actions$: Actions,
    private service: ServiceRecordService,
    private store: Store<StoreState>
  ) { }

  /**
   * For an AddServiceRequestRequestAction, call
   * ServiceRecordsService::addServiceRequest() and dispatch a new
   * AddServiceRequestResponseAction with the response.
   */
   addServiceRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_SERVICE_REQUEST_REQUEST),
    switchMap((req: ActionTypes.AddServiceRequestRequestAction): Observable<ActionTypes.AddServiceRequestResponseAction> =>
      this.service.addServiceRequest(req.payload).pipe(
        map((res: AddServiceRequestResponse): ActionTypes.AddServiceRequestResponseAction =>
          new ActionTypes.AddServiceRequestResponseAction(res)
        ))
    )));

  /**
   * For a FetchServiceRecordsRequestAction, call
   * ServiceRecordsService::fetchRecords() and dispatch a new
   * FetchServiceRecordsResponseAction with the response.
   */
   fetchServiceRecords$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_SERVICE_RECORDS_REQUEST),
    switchMap((req: ActionTypes.FetchServiceRecordsRequestAction): Observable<ActionTypes.FetchServiceRecordsResponseAction> =>
      this.service.fetchRecords(req.payload).pipe(
        map((res: FetchServiceRecordsResponse): ActionTypes.FetchServiceRecordsResponseAction =>
          new ActionTypes.FetchServiceRecordsResponseAction(res)
        ))
    )));

  /**
   * For a FetchServiceRecordStatsRequestAction, call
   * ServiceRecordsService::fetchStats() and dispatch a new
   * FetchServiceRecordStatsResponseAction with the response.
   */
   fetchServiceRecordStats$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_SERVICE_RECORD_STATS_REQUEST),
    switchMap((req: ActionTypes.FetchServiceRecordStatsRequestAction): Observable<ActionTypes.FetchServiceRecordStatsResponseAction> =>
      this.service.fetchStats().pipe(
        map((res: FetchServiceRecordStatsResponse): ActionTypes.FetchServiceRecordStatsResponseAction =>
          new ActionTypes.FetchServiceRecordStatsResponseAction(res)
        ))
    )));

  /**
   * For a NextServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
   nextPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.NEXT_SERVICE_RECORD_PAGE),
    withLatestFrom(this.store.select('serviceRecord')),
    map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage: s.perPage,
        pageNo: s.currentPage,
        upcoming: s.upcoming,
        customerQuery: s.customerQuery,
      });
    }),));

  /**
   * For a NextFiveServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
   nextFivePages$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.NEXT_FIVE_SERVICE_RECORD_PAGE),
    withLatestFrom(this.store.select('serviceRecord')),
    map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage: s.perPage,
        pageNo: s.currentPage,
        upcoming: s.upcoming,
        customerQuery: s.customerQuery,
      });
    }),));

  /**
   * For a PrevFiveServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
   prevFivePages$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PREV_FIVE_SERVICE_RECORD_PAGE),
    withLatestFrom(this.store.select('serviceRecord')),
    map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage: s.perPage,
        pageNo: s.currentPage,
        upcoming: s.upcoming,
        customerQuery: s.customerQuery,
      });
    }),));

  /**
   * For a PrevServiceRecordPageAction, dispatch a new
   * FetchServiceRecordsRequestAction with the updated page number from the
   * state.
   */
   prevPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PREV_SERVICE_RECORD_PAGE),
    withLatestFrom(this.store.select('serviceRecord')),
    map(([a, s]): ActionTypes.FetchServiceRecordsRequestAction => {
      return new ActionTypes.FetchServiceRecordsRequestAction({
        perPage: s.perPage,
        pageNo: s.currentPage,
        upcoming: s.upcoming,
        customerQuery: s.customerQuery,
      });
    }),));

  /**
   * For a SetServiceRecordFlagRequestAction, call
   * ServiceRecordService::setFlags() and dispatch a new
   * SetServiceRecordFlagResponseAction with the response.
   *
   * Uses concatMap() so that multiple actions will be processed serially
   * rather than being merged as with switchMap().
   */
   setFlags$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SET_SERVICE_RECORD_FLAG_REQUEST),
    concatMap((req: ActionTypes.SetServiceRecordFlagRequestAction): Observable<ActionTypes.SetServiceRecordFlagResponseAction> =>
      this.service.setFlags(req.payload).pipe(
        map((res: SetServiceRecordFlagResponse): ActionTypes.SetServiceRecordFlagResponseAction =>
          new ActionTypes.SetServiceRecordFlagResponseAction(res)
        ))
    )));

  /**
   * For an UpdateServiceRecordRequestAction, call
   * ServiceRecordService::update() and dispatch a new
   * UpdateServiceRecordResponseAction with the response.
   */
   update$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_SERVICE_RECORD_REQUEST),
    switchMap((req: ActionTypes.UpdateServiceRecordRequestAction): Observable<ActionTypes.UpdateServiceRecordResponseAction> =>
      this.service.update(req.payload).pipe(
        map((res: UpdateServiceRecordResponse): ActionTypes.UpdateServiceRecordResponseAction =>
          new ActionTypes.UpdateServiceRecordResponseAction(res)
        ))
    )));
}
