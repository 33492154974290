<div class="container-fluid">

  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Content Management</h1>
    </div>

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <nav mat-tab-nav-bar>
          <a
            mat-tab-link
            routerLink="/admin-content/pages"
            routerLinkActive #rla1="routerLinkActive"
            [active]="rla1.isActive"
          >
            Pages
          </a>
          <a
            mat-tab-link
            routerLink="/admin-content/resources"
            routerLinkActive #rla2="routerLinkActive"
            [active]="rla2.isActive"
          >
            Resources
          </a>
        </nav>

        <div class="tab-content">
          <router-outlet></router-outlet>
        </div>

      </div>
    </div>

  </div>

</div>
