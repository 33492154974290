<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">
        <app-page-content page="TRADEHELP" field="title"></app-page-content>
      </h1>
    </div>

    <app-page-content page="TRADEHELP" field="intro" class="intro-above-content color-white"></app-page-content>

    <div class="background-white padded-container col-xs-12">
      <app-page-content page="TRADEHELP" field="content"></app-page-content>

      <form ngNoForm action="{{ formUrl }}/adeysignup" method="GET" target="_blank">
        <input type="hidden" name="referralKey" value="{{ formData?.referralKey }}" />
        <input type="hidden" name="companyName" value="{{ formData?.companyName }}" />
        <input type="hidden" name="firstName" value="{{ formData?.firstName }}" />
        <input type="hidden" name="surName" value="{{ formData?.surName }}" />
        <input type="hidden" name="contactNo" value="{{ formData?.contactNo }}" />
        <button class="btn background-green color-white no-radius" type="submit">
          Sign up
        </button>
      </form>
    </div>

  </div>
</div>