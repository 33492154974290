/**
 * admin-jobs reducer: maintains state for the Job/Warranty management admin
 * CMS.
 */

import { Action, ActionReducer } from '@ngrx/store';


import { Job }                 from '../../models/job';
import { UserSuggestion }      from '../../models/user-suggestion';
import { CustomerSuggestion } from '../../models/customer-suggestion';
import { PromoCode, Warranty } from '../../models/warranty';

import * as adminActions          from '../actions/admin-jobs';
import * as userSuggestionActions from '../actions/user-suggestion';
import * as customerSuggestionActions from '../actions/customer-suggestion';


// State interface definitions

/**
 * Manages list of PromoCode models
 */
interface PromoCodesState {
  pending: boolean;
  error:   string;
  items:   PromoCode[];
}

/**
 * Manages paginated list of Job models
 */
interface JobsState {
  pending:    boolean;
  error:      string;
  items:      Job[];
  pageNum:    number;
  totalPages: number;
}

/**
 * Manages paginated list of Warranty models
 */
interface WarrantiesState {
  pending:    boolean;
  error:      string;
  items:      Warranty[];
  pageNum:    number;
  totalPages: number;
}

export interface State {
  promoCodes: PromoCodesState;
  jobs:       JobsState;
  warranties: WarrantiesState;

  // List of suggestions when searching for users as well as a pending flag
  pendingUsers: boolean;
  pendingCustomers: boolean;
  users:        UserSuggestion[];
  customers:    CustomerSuggestion[];
}


const defaultState: State = {
  promoCodes: {
    pending: false,
    error:   null,
    items:   [],
  },
  jobs: {
    pending:    false,
    error:      null,
    items:      [],
    pageNum:    1,
    totalPages: 0,
  },
  warranties: {
    pending:    false,
    error:      null,
    items:      [],
    pageNum:    1,
    totalPages: 0,
  },
  users:        [],
  customers: [],
  pendingUsers: false,
  pendingCustomers: false,
};


export function reducer(
  state:  State = defaultState,
  action: adminActions.Actions | userSuggestionActions.Actions | customerSuggestionActions.Actions,
): State {
  switch (action.type)
  {
    /**
     * Request to add a new Job
     */
    case adminActions.ADD_ADMIN_JOB_REQUEST:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending: true,
          error:   null,
        })
      });

    /**
     * Response from adding a new Job
     */
    case adminActions.ADD_ADMIN_JOB_RESPONSE:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending: false,
          error:   action.payload.error,
          items:   []
        })
      }
    );

    /**
     * Request to add a new PromoCode
     */
    case adminActions.ADD_ADMIN_PROMO_CODE_REQUEST:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: true,
          error:   null,
        })
      });

    /**
     * Response from adding a new PromoCode
     */
    case adminActions.ADD_ADMIN_PROMO_CODE_RESPONSE:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: false,
          error:   action.payload.error,
          items:   action.payload.error
            ? state.promoCodes.items
            : [...state.promoCodes.items, action.payload.promoCode],
        })
      });

    /**
     * Request to add a new Warranty
     */
    case adminActions.ADD_ADMIN_WARRANTY_REQUEST:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending: true,
          error:   null,
        })
      });

    /**
     * Response from adding a new Warranty
     */
    case adminActions.ADD_ADMIN_WARRANTY_RESPONSE:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending: false,
          error:   action.payload.error,
          items:   action.payload.error
            ? state.warranties.items
            : [...state.warranties.items, Object.assign({}, action.payload.warranty, {committed: true})],
        })
      });

    /**
     * Request to delete a Job
     */
    case adminActions.DELETE_ADMIN_JOB_REQUEST:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          error: null,
          items: state.jobs.items.map((j: Job): Job =>
            j.id === action.payload.job.id
              ? Object.assign({}, j, {committed: false})
              : j
          ),
        })
      });

    /**
     * Response from deleting a Job
     */
    case adminActions.DELETE_ADMIN_JOB_RESPONSE:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          error: action.payload.error,
          items: action.payload.error
            ? state.jobs.items
            : state.jobs.items.filter((j: Job): boolean =>
              j.id !== action.payload.job.id
            ),
        })
      });

    /**
     * Request to delete a PromoCode
     */
    case adminActions.DELETE_ADMIN_PROMO_CODE_REQUEST:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: true,
          error:   null,
          items:   state.promoCodes.items.map((p: PromoCode): PromoCode =>
            p.id === action.payload.promoCode.id ? Object.assign({}, p, {committed: false}) : p
          ),
        })
      });

    /**
     * Response from deleting a PromoCode
     */
    case adminActions.DELETE_ADMIN_PROMO_CODE_RESPONSE:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: false,
          error:   action.payload.error,
          items:   action.payload.error
            ? state.promoCodes.items
            : state.promoCodes.items.filter((p: PromoCode): boolean => p.id !== action.payload.promoCode.id),
        })
      });

    /**
     * Request to delete a Warranty
     */
    case adminActions.DELETE_ADMIN_WARRANTY_REQUEST:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          error: null,
          items: state.warranties.items.map((w: Warranty): Warranty =>
            w.id === action.payload.warranty.id
              ? Object.assign({}, w, {committed: false})
              : w
          ),
        })
      });

    /**
     * Response from deleting a Warranty
     */
    case adminActions.DELETE_ADMIN_WARRANTY_RESPONSE:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          error: action.payload.error,
          items: action.payload.error
            ? state.warranties.items
            : state.warranties.items.filter((w: Warranty): boolean =>
              w.id !== action.payload.warranty.id
            ),
        })
      });

    /**
     * Request to fetch a page of Jobs for a User
     */
    case adminActions.FETCH_ADMIN_JOBS_REQUEST:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          pending: true,
          error:   null,
          pageNum: action.payload.pageNum,
        })
      });

    /**
     * Response from fetching a page of Jobs for a User
     */
    case adminActions.FETCH_ADMIN_JOBS_RESPONSE:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          pending:    false,
          error:      action.payload.error,
          totalPages: action.payload.totalPages,
          items: action.payload.error
            ? state.jobs.items
            : action.payload.jobs,
        })
      });

    /**
     * Request to fetch all PromoCodes
     */
    case adminActions.FETCH_ADMIN_PROMO_CODES_REQUEST:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: true,
          error:   null,
        })
      });

    /**
     * Response from fetching all PromoCodes
     */
    case adminActions.FETCH_ADMIN_PROMO_CODES_RESPONSE:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: false,
          error:   action.payload.error,
          items:   action.payload.error
            ? state.promoCodes.items
            : action.payload.promoCodes,
        })
      });

    /**
     * Request to fetch a page of Warranties for a User
     */
    case adminActions.FETCH_ADMIN_WARRANTIES_REQUEST:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending: true,
          error:   null,
          pageNum: action.payload.pageNum,
        })
      });

    /**
     * Response from fetching a page of Warranties for a User
     */
    case adminActions.FETCH_ADMIN_WARRANTIES_RESPONSE:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          pending:    false,
          error:      action.payload.error,
          totalPages: action.payload.totalPages,
          items: action.payload.error
            ? state.warranties.items
            : action.payload.warranties,
        })
      });

    /**
     * Request to fetch a list of UserSuggestions based on a name query
     */
    case userSuggestionActions.FETCH_USER_SUGGESTION_REQUEST:
      return Object.assign({}, state, {pendingUsers: true, users: []});

    /**
     * Response from fetching a list of UserSuggestions
     */
    case userSuggestionActions.FETCH_USER_SUGGESTION_RESPONSE:
      return Object.assign({}, state, {pendingUsers: false, users: action.payload.users});

    /**
     * Request to fetch a list of UserSuggestions based on a name query
     */
    case customerSuggestionActions.FETCH_CUSTOMER_SUGGESTION_REQUEST:
        return Object.assign({}, state, { pendingCustomers: true, customers: [] });

    /**
     * Response from fetching a list of UserSuggestions
     */
    case customerSuggestionActions.FETCH_CUSTOMER_SUGGESTION_RESPONSE:
        return Object.assign({}, state, { pendingCustomers: false, customers: action.payload.customers });

    /**
     * Request to update an existing Job
     */
    case adminActions.UPDATE_ADMIN_JOB_REQUEST:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          error: null,
          items: state.jobs.items.map((j: Job): Job =>
            j.id === action.payload.job.id
              ? Object.assign({}, j, {committed: false})
              : j
          ),
        })
      });

    /**
     * Response from updating an existing Job
     */
    case adminActions.UPDATE_ADMIN_JOB_RESPONSE:
      return Object.assign({}, state, {
        jobs: Object.assign({}, state.jobs, {
          error: action.payload.error,
          items: action.payload.error
            ? state.jobs.items
            : state.jobs.items.map((j: Job): Job =>
              j.id === action.payload.job.id
                ? Object.assign({}, action.payload.job, {committed: true})
                : j
            ),
        })
      });

    /**
     * Request to update an existing PromoCode
     */
    case adminActions.UPDATE_ADMIN_PROMO_CODE_REQUEST:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: true,
          error:   null,
          items:   state.promoCodes.items.map((p: PromoCode): PromoCode =>
            p.id === action.payload.promoCode.id ? Object.assign({}, p, {committed: false}) : p
          ),
        })
      });

    /**
     * Response from updating an existing PromoCode
     */
    case adminActions.UPDATE_ADMIN_PROMO_CODE_RESPONSE:
      return Object.assign({}, state, {
        promoCodes: Object.assign({}, state.promoCodes, {
          pending: false,
          error:   action.payload.error,
          items:   action.payload.error
            ? state.promoCodes.items
            : state.promoCodes.items.map((p: PromoCode): PromoCode =>
              p.id === action.payload.promoCode.id ? Object.assign({}, action.payload.promoCode, {committed: true}) : p
            ),
        })
      });

    /**
     * Request to update an existing Warranty
     */
    case adminActions.UPDATE_ADMIN_WARRANTY_REQUEST:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          error: null,
          items: state.warranties.items.map((w: Warranty): Warranty =>
            w.id === action.payload.warranty.id
              ? Object.assign({}, w, {committed: false})
              : w
          ),
        })
      });

    /**
     * Response from updating an existing Warranty
     */
    case adminActions.UPDATE_ADMIN_WARRANTY_RESPONSE:
      return Object.assign({}, state, {
        warranties: Object.assign({}, state.warranties, {
          error: action.payload.error,
          items: action.payload.error
            ? state.warranties.items
            : state.warranties.items.map((w: Warranty): Warranty =>
              w.id === action.payload.warranty.id
                ? Object.assign({}, action.payload.warranty, {committed: true})
                : w
            ),
        })
      });

    default:
      return state;
  }
}
