import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CheckPromoCodeResponse, FetchProductTypesResponse, RegisterWarrantyResponse } from '../../models/warranty';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/register-warranty';

@Injectable()
export class RegisterWarrantyEffects {
  constructor(
    private service: WarrantyService,
    private actions$: Actions
  ) { }

  /**
   * For a CheckPromoCodeRequestAction, call WarrantyService::checkPromoCode()
   * and dispatch a new CheckPromoCodeResponseAction with the response.
   */
   checkPromoCode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CHECK_PROMO_CODE_REQUEST),
    switchMap((req: ActionTypes.CheckPromoCodeRequestAction): Observable<ActionTypes.CheckPromoCodeResponseAction> =>
      this.service.checkPromoCode(req.payload).pipe(
        map((res: CheckPromoCodeResponse): ActionTypes.CheckPromoCodeResponseAction =>
          new ActionTypes.CheckPromoCodeResponseAction(res)
        ))
    )));

  /**
   * For a FetchProductTypesRequestAction, call
   * WarrantyService::FetchProductTypes() and dispatch a new
   * FetchProductTypesResponseAction with the response.
   */
   FetchProductTypesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_PRODUCT_TYPES_REQUEST),
    switchMap((req: ActionTypes.FetchProductTypesRequestAction): Observable<Action> => {
      return this.service.FetchProductTypes(true).pipe(
        map((res: FetchProductTypesResponse): ActionTypes.FetchProductTypesResponseAction =>
          new ActionTypes.FetchProductTypesResponseAction(res)
        ));
    })));

  /**
   * For a RegisterWarrantyRequestAction, call
   * WarrantyService::submit() and dispatch a new
   * RegisterWarrantyResponseAction with the response.
   */
   registerWarrantyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REGISTER_WARRANTY_REQUEST),
    switchMap((req: ActionTypes.RegisterWarrantyRequestAction): Observable<Action> => {
      return this.service.submit(req.payload).pipe(
        map((res: RegisterWarrantyResponse): ActionTypes.RegisterWarrantyResponseAction =>
          new ActionTypes.RegisterWarrantyResponseAction(res)
        ));
    })));
}
