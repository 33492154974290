import { AbstractControl, UntypedFormGroup } from '@angular/forms';

/**
 * Given a FormGroup, marks the group and all child controls/groups recursively
 * as touched. Also calls updateValueAndValidity().
 *
 * @param {FormGroup} fg The parent FormGroup to mark as touched
 * @return {void}
 */
export function markFormAsTouched(fg: UntypedFormGroup) {
  if (!fg)
    return;

  fg.updateValueAndValidity();
  fg.markAsTouched();

  if (!fg.controls)
    return;

  Object.keys(fg.controls).forEach((controlName: string) => {
    const child: AbstractControl = fg.get(controlName);
    if (!child)
      return;

    child.updateValueAndValidity();
    child.markAsTouched();

    if (child instanceof UntypedFormGroup)
      markFormAsTouched(child);
  });
}

/**
 * Creates a Blob from Base64-encoded data and a specified MIME type
 *
 * @param {string} contentType The MIME type of the encoded data (e.g. "image/png")
 * @param {string} data        The Base64-encoded file data
 * @return {Blob}
 */
export function b64ToBlob(contentType: string, data: string) {
  const byteChars: string = atob(data);
  return new Blob([byteChars], {type: contentType});
}
