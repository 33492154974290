import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Summary
 *    Custom confirm prompt.
 *    
 *
 * Description
 *    Overwrites the default confirm prompt in order to use angular material.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-confirm-prompt',
  templateUrl: './confirm-prompt.component.html',
  styleUrls: ['./confirm-prompt.component.scss']
})
export class ConfirmPromptComponent implements OnInit {

  // Initialise prompt instance to map passed variables
  public prompt: any = {
    title:       '',
    description: '',
    close:       '',
    confirm:     '',
  };

  
  /**
   * Constructor for page, created reference to data passed into the modal
   *
   * @param {MAT_DIALOG_DATA} data Contains reference to data passed into the modal when created
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // Set prompt to the value of the data passed into the modal
    if (data) {
      this.prompt = data;
    }
  }

  ngOnInit() {
  }
}
