/**
 * email actions: defines all actions relating to sending a "Contact Us"
 * request and redeeming vouchers.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  PostContactUsRequest,
  PostContactUsResponse,
  PostVoucherRedeemRequest,
  PostVoucherRedeemResponse,
  PostDemokitRedeemRequest,
  PostDemokitRedeemResponse,
} from '../../models/email';


// Action type definitions
export const INIT_EMAIL_STATE  = 'INIT_EMAIL_STATE';
export const POST_CONTACT_US_REQUEST  = 'POST_CONTACT_US_REQUEST';
export const POST_CONTACT_US_RESPONSE = 'POST_CONTACT_US_RESPONSE';
export const POST_VOUCHER_REDEEM_REQUEST  = 'POST_VOUCHER_REDEEM_REQUEST';
export const POST_VOUCHER_REDEEM_RESPONSE = 'POST_VOUCHER_REDEEM_RESPONSE';
export const POST_DEMOKIT_REDEEM_REQUEST  = 'POST_DEMOKIT_REDEEM_REQUEST';
export const POST_DEMOKIT_REDEEM_RESPONSE = 'POST_DEMOKIT_REDEEM_RESPONSE';


// Action definitions
export class InitEmailStateAction implements Action {
  readonly type = INIT_EMAIL_STATE;
}

export class PostContactUsRequestAction implements Action {
  readonly type = POST_CONTACT_US_REQUEST;
  constructor(public payload: PostContactUsRequest) {}
}

export class PostContactUsResponseAction implements Action {
  readonly type = POST_CONTACT_US_RESPONSE;
  constructor(public payload: PostContactUsResponse) {}
}

export class PostVoucherRedeemRequestAction implements Action {
  readonly type = POST_VOUCHER_REDEEM_REQUEST;
  constructor(public payload: PostVoucherRedeemRequest) {}
}

export class PostVoucherRedeemResponseAction implements Action {
  readonly type = POST_VOUCHER_REDEEM_RESPONSE;
  constructor(public payload: PostVoucherRedeemResponse) {}
}

export class PostDemokitRedeemRequestAction implements Action {
  readonly type = POST_DEMOKIT_REDEEM_REQUEST;
  constructor(public payload: PostDemokitRedeemRequest) {}
}

export class PostDemokitRedeemResponseAction implements Action {
  readonly type = POST_DEMOKIT_REDEEM_RESPONSE;
  constructor(public payload: PostDemokitRedeemResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | InitEmailStateAction
  | PostContactUsRequestAction
  | PostContactUsResponseAction
  | PostVoucherRedeemRequestAction
  | PostVoucherRedeemResponseAction
  | PostDemokitRedeemRequestAction
  | PostDemokitRedeemResponseAction;
