/**
 * service-record actions: defines all actions relating to management service
 * records.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AddServiceRequestResponse,
  FetchServiceRecordStatsResponse,
  FetchServiceRecordsRequest,
  FetchServiceRecordsResponse,
  ServiceRecord,
  ServiceRequest,
  SetServiceRecordFlagRequest,
  SetServiceRecordFlagResponse,
  UpdateServiceRecordRequest,
  UpdateServiceRecordResponse,
} from '../../models/service-record';


// Action type definitions
export const ADD_SERVICE_REQUEST_REQUEST         = 'ADD_SERVICE_REQUEST_REQUEST';
export const ADD_SERVICE_REQUEST_RESPONSE        = 'ADD_SERVICE_REQUEST_RESPONSE';
export const FETCH_SERVICE_RECORD_STATS_REQUEST  = 'FETCH_SERVICE_RECORD_STATS_REQUEST';
export const FETCH_SERVICE_RECORD_STATS_RESPONSE = 'FETCH_SERVICE_RECORD_STATS_RESPONSE';
export const FETCH_SERVICE_RECORDS_REQUEST       = 'FETCH_SERVICE_RECORDS_REQUEST';
export const FETCH_SERVICE_RECORDS_RESPONSE      = 'FETCH_SERVICE_RECORDS_RESPONSE';
export const NEXT_SERVICE_RECORD_PAGE            = 'NEXT_SERVICE_RECORD_PAGE';
export const PREV_SERVICE_RECORD_PAGE            = 'PREV_SERVICE_RECORD_PAGE';
export const SET_SERVICE_RECORD_FLAG_REQUEST     = 'SET_SERVICE_RECORD_FLAG_REQUEST';
export const SET_SERVICE_RECORD_FLAG_RESPONSE    = 'SET_SERVICE_RECORD_FLAG_RESPONSE';
export const UPDATE_SERVICE_RECORD_REQUEST       = 'UPDATE_SERVICE_RECORD_REQUEST';
export const UPDATE_SERVICE_RECORD_RESPONSE      = 'UPDATE_SERVICE_RECORD_RESPONSE';
export const NEXT_FIVE_SERVICE_RECORD_PAGE       = 'NEXT_FIVE_SERVICE_RECORD_PAGE';
export const PREV_FIVE_SERVICE_RECORD_PAGE       = 'PREV_FIVE_SERVICE_RECORD_PAGE';


// Action definitions
export class AddServiceRequestRequestAction implements Action {
  readonly type = ADD_SERVICE_REQUEST_REQUEST;
  constructor(public payload: ServiceRequest) {}
}

export class AddServiceRequestResponseAction implements Action {
  readonly type = ADD_SERVICE_REQUEST_RESPONSE;
  constructor(public payload: AddServiceRequestResponse) {}
}

export class FetchServiceRecordStatsRequestAction implements Action {
  readonly type = FETCH_SERVICE_RECORD_STATS_REQUEST;
}

export class FetchServiceRecordStatsResponseAction implements Action {
  readonly type = FETCH_SERVICE_RECORD_STATS_RESPONSE;
  constructor(public payload: FetchServiceRecordStatsResponse) {}
}

export class FetchServiceRecordsRequestAction implements Action {
  readonly type = FETCH_SERVICE_RECORDS_REQUEST;
  constructor(public payload: FetchServiceRecordsRequest) {}
}

export class FetchServiceRecordsResponseAction implements Action {
  readonly type = FETCH_SERVICE_RECORDS_RESPONSE;
  constructor(public payload: FetchServiceRecordsResponse) {}
}

export class NextServiceRecordPageAction implements Action {
  readonly type = NEXT_SERVICE_RECORD_PAGE;
}

export class PrevServiceRecordPageAction implements Action {
  readonly type = PREV_SERVICE_RECORD_PAGE;
}

export class NextFiveServiceRecordPageAction implements Action {
  readonly type = NEXT_FIVE_SERVICE_RECORD_PAGE;
}

export class PrevFiveServiceRecordPageAction implements Action {
  readonly type = PREV_FIVE_SERVICE_RECORD_PAGE;
}

export class SetServiceRecordFlagRequestAction implements Action {
  readonly type = SET_SERVICE_RECORD_FLAG_REQUEST;
  constructor(public payload: SetServiceRecordFlagRequest) {}
}

export class SetServiceRecordFlagResponseAction implements Action {
  readonly type = SET_SERVICE_RECORD_FLAG_RESPONSE;
  constructor(public payload: SetServiceRecordFlagResponse) {}
}

export class UpdateServiceRecordRequestAction implements Action {
  readonly type = UPDATE_SERVICE_RECORD_REQUEST;
  constructor(public payload: UpdateServiceRecordRequest) {}
}

export class UpdateServiceRecordResponseAction implements Action {
  readonly type = UPDATE_SERVICE_RECORD_RESPONSE;
  constructor(public payload: UpdateServiceRecordResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchServiceRecordStatsRequestAction
  | FetchServiceRecordStatsResponseAction
  | FetchServiceRecordsRequestAction
  | FetchServiceRecordsResponseAction
  | NextServiceRecordPageAction
  | PrevServiceRecordPageAction
  | SetServiceRecordFlagRequestAction
  | SetServiceRecordFlagResponseAction
  | UpdateServiceRecordRequestAction
  | UpdateServiceRecordResponseAction
  | NextFiveServiceRecordPageAction
  | PrevFiveServiceRecordPageAction;
