/**
 * admin-users models
 */
import { BusinessToolRegistration, BusinessToolRegistrationAPIResponse } from './business-tools';
import { UserRole } from './registration';
import { UserProfile } from './user-profile';

/**
 * Stores a user's points
 */
export class UserPoints {
  earned: number;
  spent: number;
  current: number;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {UserPoints}
   */
  static fromAPI(data: any): UserPoints {
    return {
      earned: data.points_rewarded,
      spent: data.points_redeemed,
      current: data.points_current,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {UserPoints} v
   * @return {object}
   */
  static toAPI(v: UserPoints): any {
    return {
      points_rewarded: v ? v.earned : 0,
      points_redeemed: v ? v.spent : 0,
      points_current: v ? v.earned - v.spent : 0,
    };
  }
}

/**
 * Encapsulates a single user record for use by an admin
 */
export class AdminUser {
  id: string;
  active: boolean;
  admin: boolean;
  profile: UserProfile;
  points: UserPoints;
  // If model is waiting for API response
  pending?: boolean;
  role: UserRole;
  businessToolRegistrations: BusinessToolRegistration[];

  /**
   * Returns an example model
   *
   * @return {AdminUser}
   */
  static getExample(): AdminUser {
    const earned: number = Math.floor(Math.random() * 1000) + 10;
    const spent: number = Math.floor(Math.random() * (earned / 1.5));
    const current: number = 0;

    return {
      id: Math.floor(Math.random() * 1000).toString(),
      active: true,
      admin: Math.floor(Math.random() * 10) % 2 === 0,
      pending: false,
      profile: UserProfile.getExample(),
      points: { earned, spent, current },
      role: {
        id: '2',
        name: 'installer',
      },
      businessToolRegistrations: [],
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {AdminUser}
   */
  static fromAPI(data: any): AdminUser {
    return {
      id: data.id.toString(),
      active: data.activated,
      pending: false,
      profile: UserProfile.fromAPIData(data),
      points: UserPoints.fromAPI(data),
      role: UserRole.fromAPI(data.role_by_role_id),
      admin: data.role_by_role_id && data.role_by_role_id.title
        ? data.role_by_role_id.title === 'admin'
        : data.role_id === 1,
      // We only need to know about verified business tool registrations
      businessToolRegistrations: data.business_tool_registrations_by_user_id ? data.business_tool_registrations_by_user_id.filter((b: BusinessToolRegistrationAPIResponse) => b.verified_at !== null).map(BusinessToolRegistration.fromAPI) : [],
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {AdminUser} u
   * @return {object}
   */
  static toAPI(u: AdminUser): any {
    return Object.assign(
      {
        id: u.id ? parseInt(u.id, 10) : null,
        activated: u.active,
      },
      UserPoints.toAPI(u.points),
      UserProfile.toApiData(u.profile),
    );
  }

  /**
   * Converts the model to the API data format used when adding a new user
   *
   * @param {AdminUser} u
   * @return {object}
   */
  static toAPINewUser(u: AdminUser): any {
    const data: any = AdminUser.toAPI(u);
    return Object.assign(
      {},
      data,
      { accreditation_user_by_user_id: UserProfile.toApiRegistrationData(u.profile).accreditation_user_by_user_id }
    );
  }
}


export interface AddAdminUserRequest {
  user: AdminUser;
}

export interface AddAdminUserResponse {
  error: string;
  user: AdminUser;
}

export interface DeleteAdminUserRequest {
  user: AdminUser;
}

export interface DeleteAdminUserResponse {
  error: string;
  user: AdminUser;
}

export interface FetchAdminUsersRequest {
  onlyShowDeleted?: boolean;
  onlyShowRequestedDeletion?: boolean;

  // Role filter
  userType: number;

  userId?: string;

  // Partial name filter
  userName: string;

  userCompanyName?: string;

  // Partial address filter
  userAddress: string;

  // Pagination
  perPage: number;
  page: number;
}

export interface FetchAdminUsersResponse {
  error: string;
  users: AdminUser[];
  totalPages: number;
}

export interface UpdateAdminUserRequest {
  user: AdminUser;
}

export interface UpdateAdminUserResponse {
  error: string;
  user: AdminUser;
}
