<div class="container-fluid user-profile">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">Profile</h1>
    </div>
    <app-page-content page="PROFILE" field="intro" class="color-white intro-above-content"></app-page-content>

    <div class="profile-container background-white col-xs-12" *ngIf="loginDetails$ | async; let loginDetails">

      <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending">Loading profile...</app-waiting-spinner>

      <app-message-panel kind="ERROR" *ngIf="loginDetails.error">
        {{ loginDetails.error }}
      </app-message-panel>

      <div class="profile-wrapper" *ngIf="loginDetails.currentProfile">

        <app-profile-header
          class="profile-details col-lg-8 col-md-12 col-sm-12 col-xs-12 left-item-padding"
          [userProfile]="loginDetails.currentProfile"
          [accreditations]="loginDetails.accreditations"
          (editCallback)="editProfileDetails($event)"
        ></app-profile-header>

        <app-profile-percentage
          class="profile-complete-percentage right-item-padding col-lg-4 col-md-12 col-sm-12 col-xs-12"
          [userProfile]="loginDetails.currentProfile"
        ></app-profile-percentage>

        <!--<div class="experience-accreditations-container left-item-padding col-sm-8 col-xs-12">
          <div class="border-box border-light-gray col-xs-12 no-pad">



          </div>
        </div>-->

        <!--<div class="accreditations-block col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div class="border-box border-light-gray">
            <h4 class="no-margin">Accreditations</h4>
            <div class="accreditation-container">
              <img *ngFor="let accreditation of accreditationSet" [src]="'/assets/i/icons/' + accreditation.icon" class="col-md-2 col-sm-3 col-xs-3" />
            </div>
          </div>
        </div>
        <div class="years-block col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div class="border-box border-light-gray">
            <div class="info-block align-center">
              <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-blue.png">
              <h2 class="color-blue">{{getYearsAtClub(loginDetails.currentProfile.createdAt, true)}}</h2>
              <p class="color-blue">{{getYearsAtClub(loginDetails.currentProfile.createdAt, false)}} with the club</p>
            </div>
            <div class="info-block align-center">
              <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-blue.png">
              <h2 class="color-blue">10</h2>
              <p class="color-blue">10 year warranty</p>
            </div>
          </div>
        </div>-->

        <div class="widget-containers left-item-padding col-sm-8 col-xs-12">
          <div class="widget-container col-sm-6 col-xs-12">
            <div class="widget-content">
              <app-service-widget
                [records]="(serviceRecords$|async).records"
                additionalClasses="profile-widget"
                (onRequestAdded)="handleAddServiceRequest($event)"
              >
              </app-service-widget>

            </div>
          </div>
          <div class="widget-container col-sm-6 col-xs-12">
            <div class="widget-content">
              <app-diary-widget
                [events]="(diary$|async).events"
                additionalClasses="profile-widget"
                [eventTypes]="(diary$|async).eventTypes"
              >
              </app-diary-widget>
            </div>
          </div>
        </div>


        <div class="points-container right-item-padding col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div class="info-block background-adey-blue align-center no-radius">
            <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-white.png">
            <h2 class="color-white">{{loginDetails.currentProfile.pointsRewarded}}</h2>
            <p class="color-white">Points earned</p>
          </div>
          <div class="info-block background-adey-red align-center no-radius">
            <img class="transform-x-offset-50" src="/assets/i/common/rings/adey-ring-white.png">
            <h2 class="color-white">{{loginDetails.currentProfile.pointsRedeemed}}</h2>
            <p class="color-white">Points spent</p>
          </div>
        </div>

        <div class="quicklinks-container right-item-padding col-sm-6 col-md-6 col-lg-4 col-xs-12">
          <button (click)="goToPage(link.link)" class="btn background-green color-white no-radius" *ngFor="let link of quickLinks">{{link.label}}</button>
        </div>
      </div>

      <!--
      <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending">
        Updating profile details...
      </app-waiting-spinner>-->

      <!--<app-profile-details
        [profileData]="loginDetails.currentProfile"
        (onSubmit)="onSaveDetails($event)"
      >
      </app-profile-details>-->
    </div>
  </div>
</div>
