import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AddAdminResourceResponse,
  DeleteAdminResourceResponse,
  FetchAdminResourcesResponse,
  UpdateAdminResourceResponse,
} from '../../models/admin-resources';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-resources';

@Injectable()
export class AdminResourcesEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For an AddAdminResourceRequestAction, call AdminService::addResource() and
   * dispatch a new AddAdminResourceResponseAction with the response.
   */
   addResource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_ADMIN_RESOURCE_REQUEST),
    switchMap((req: ActionTypes.AddAdminResourceRequestAction): Observable<ActionTypes.AddAdminResourceResponseAction> =>
      this.service.addResource(req.payload).pipe(
        map((res: AddAdminResourceResponse): ActionTypes.AddAdminResourceResponseAction =>
          new ActionTypes.AddAdminResourceResponseAction(res)
        ))
    )));

  /**
   * For a DeleteAdminResourceRequestAction, call
   * AdminService::deleteResource() and dispatch a new
   * DeleteAdminResourceResponseAction with the response.
   */
   deleteResource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_ADMIN_RESOURCE_REQUEST),
    switchMap((req: ActionTypes.DeleteAdminResourceRequestAction): Observable<ActionTypes.DeleteAdminResourceResponseAction> =>
      this.service.deleteResource(req.payload).pipe(
        map((res: DeleteAdminResourceResponse): ActionTypes.DeleteAdminResourceResponseAction =>
          new ActionTypes.DeleteAdminResourceResponseAction(res)
        ))
    )));

  /**
   * For a FetchAdminResourcesRequestAction, call
   * AdminService::fetchResources() and dispatch a new
   * FetchAdminResourcesResponseAction with the response.
   */
   fetchResources$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_RESOURCES_REQUEST),
    switchMap((req: ActionTypes.FetchAdminResourcesRequestAction): Observable<ActionTypes.FetchAdminResourcesResponseAction> =>
      this.service.fetchResources(req.payload).pipe(
        map((res: FetchAdminResourcesResponse): ActionTypes.FetchAdminResourcesResponseAction =>
          new ActionTypes.FetchAdminResourcesResponseAction(res)
        ))
    )));

  /**
   * For an UpdateAdminResourceRequestAction, call
   * AdminService::updateResource() and dispatch a new
   * UpdateAdminResourceResponseAction with the response.
   */
   updateResource$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ADMIN_RESOURCE_REQUEST),
    switchMap((req: ActionTypes.UpdateAdminResourceRequestAction): Observable<ActionTypes.UpdateAdminResourceResponseAction> =>
      this.service.updateResource(req.payload).pipe(
        map((res: UpdateAdminResourceResponse): ActionTypes.UpdateAdminResourceResponseAction =>
          new ActionTypes.UpdateAdminResourceResponseAction(res)
        ))
    )));
}
