<div class="product-selector">

  <div class="query-form">
    <h3 class="medium color-gray no-margin">Product selector</h3>
    <form class="form-inline" [formGroup]="fgQuery" novalidate (ngSubmit)="handleQuerySubmit()">
      <div class="form-group radio-buttons">
        <label class="required">System Type</label>
        <div *ngFor="let v of systemTypeOptions" class="radio-button">
          <input type="radio" [value]="v.value" [id]="v.label" formControlName="systemType" name="systemType">
          <label [for]="v.label" [class]="fgQuery.controls.systemType.value == v.value ? 'background-adey-red color-white' : 'background-beige color-gray'">{{v.label}}</label>
        </div>
        <select class="form-control radio-select" formControlName="systemType">
          <option *ngFor="let v of systemTypeOptions" [value]="v.value">{{v.label}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="required">Type of Work</label>
        <select class="form-control" formControlName="typeOfWork">
          <option *ngFor="let v of typeOfWorkOptions" [value]="v.value">{{v.label}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="required">Pipework</label>
        <select class="form-control" formControlName="pipework">
          <option *ngFor="let v of filteredPipeworkOptions()" [value]="v.value">{{v.label}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="required">Pipework Size</label>
        <select class="form-control" formControlName="pipeworkSize">
          <option *ngFor="let v of filteredPipeworkSizeOptions()" [value]="v.value">{{v.label}}</option>
        </select>
      </div>
      <div
        class="form-group"
        *ngIf="fgQuery.controls.typeOfWork.value === 'standard_boiler_service' || fgQuery.controls.typeOfWork.value === 'maintenance_call_out'"
      >
        <label>System Filter</label>
        <select class="form-control" formControlName="systemFilter">
          <option *ngFor="let v of systemFilterOptions" [value]="v.value">{{v.label}}</option>
        </select>
      </div>
    </form>
  </div>

  <div class="results" *ngIf="firstQuery">
    <h3 class="medium color-gray">Results</h3>
    <app-waiting-spinner kind="bar" *ngIf="fetching" class="loading-bar">Searching...</app-waiting-spinner>
    <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>
    <p *ngIf="!fetching && results.length === 0">No products found</p>
    <div class="row" *ngIf="results.length > 0">
      <div
        *ngFor="let g of productGroups; let gi = index"
        [class]="'product-group' + (productsInGroup(g.id) && productsInGroup(g.id).length == 0 ? ' no-products' : '')"
      >
        <img [src]="'/assets/i/common/rings/adey-ring-' + g.colour + '.png'" class="icon-ring transform-x-offset-50" />
        <i [class]="'group-icon ' + g.icon"></i>
        <h4>{{g.label}}</h4>
        <div *ngIf="productsInGroup(g.id); let gp">
          <div class="product-item media align-left" *ngFor="let p of gp">
            <div class="media-left" *ngIf="p.imageUrl">
              <img class="media-object" [src]="imageBaseUrl + p.imageUrl" [alt]="p.title" />
              <div [class]="'corner ' + g.colour"></div>
            </div>
            <div class="media-body">
              <h5 class="media-heading">{{p.title}}</h5>
              <p *ngIf="p.subtitle">{{p.subtitle}}</p>
              <p>{{p.description}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="productsInGroup(g.id).length == 0">
          <div class="product-item pad-left align-center">
            No products found in category
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
