import { Action } from '@ngrx/store';

import {
  FilterDataRequest,
  FilterDataResponse,
  TimelineDataRequest,
  TimelineDataResponse,
  TimelineData,
  AlertDataRequest,
  AlertDataResponse,
  DismissAlertRequest,
  DismissAlertResponse,
  DeleteFilterRequest,
  DeleteFilterResponse
} from '../../models/pro3';


// Action type definitions
export const FILTER_DATA_REQUEST            = 'FILTER_DATA_REQUEST';
export const FILTER_DATA_RESPONSE           = 'FILTER_DATA_RESPONSE';
export const TIMELINE_DATA_REQUEST          = 'TIMELINE_DATA_REQUEST';
export const TIMELINE_DATA_RESPONSE         = 'TIMELINE_DATA_RESPONSE';
export const ALERT_DATA_REQUEST             = 'ALERT_DATA_REQUEST';
export const ALERT_DATA_RESPONSE            = 'ALERT_DATA_RESPONSE';
export const DISMISS_ALERT_REQUEST          = 'DISMISS_ALERT_REQUEST';
export const DISMISS_ALERT_RESPONSE         = 'DISMISS_ALERT_RESPONSE';
export const DELETE_FILTER_REQUEST          = 'DELETE_FILTER_REQUEST';
export const DELETE_FILTER_RESPONSE         = 'DELETE_FILTER_RESPONSE';


// Action definitions
export class FilterDataRequestAction implements Action {
  readonly type = FILTER_DATA_REQUEST;
  constructor(public payload: FilterDataRequest) {}
}

export class FilterDataResponseAction implements Action {
  readonly type = FILTER_DATA_RESPONSE;
  constructor(public payload: FilterDataResponse) {}
}

export class TimelineDataRequestAction implements Action {
  readonly type = TIMELINE_DATA_REQUEST;
  constructor(public payload: TimelineDataRequest) {}
}

export class TimelineDataResponseAction implements Action {
  readonly type = TIMELINE_DATA_RESPONSE;
  constructor(public payload: TimelineDataResponse) {}
}

export class AlertDataRequestAction implements Action {
  readonly type = ALERT_DATA_REQUEST;
  constructor(public payload: AlertDataRequest) {}
}

export class AlertDataResponseAction implements Action {
  readonly type = ALERT_DATA_RESPONSE;
  constructor(public payload: AlertDataResponse) {}
}

export class DismissAlertRequestAction implements Action {
  readonly type = DISMISS_ALERT_REQUEST;
  constructor(public payload: DismissAlertRequest) {}
}

export class DismissAlertResponseAction implements Action {
  readonly type = DISMISS_ALERT_RESPONSE;
  constructor(public payload: DismissAlertResponse) {}
}

export class DeleteFilterRequestAction implements Action {
  readonly type = DELETE_FILTER_REQUEST;
  constructor(public payload: DeleteFilterRequest) {}
}

export class DeleteFilterResponseAction implements Action {
  readonly type = DELETE_FILTER_RESPONSE;
  constructor(public payload: DeleteFilterResponse) {}
}



// Export Actions type as a union of all defined actions
export type Actions =
  | FilterDataRequestAction
  | FilterDataResponseAction
  | TimelineDataRequestAction
  | TimelineDataResponseAction
  | AlertDataRequestAction
  | AlertDataResponseAction
  | DismissAlertRequestAction
  | DismissAlertResponseAction
  | DeleteFilterRequestAction
  | DeleteFilterResponseAction;
