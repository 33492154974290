/**
 * register-warranty actions: defines all actions for registering Warranties.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  CheckPromoCodeResponse,
  FetchProductTypesResponse,
  RegisterWarrantyRequest,
  RegisterWarrantyResponse,
} from '../../models/warranty';


// Action type definitions
export const CHECK_PROMO_CODE_REQUEST     = 'CHECK_PROMO_CODE_REQUEST';
export const CHECK_PROMO_CODE_RESPONSE    = 'CHECK_PROMO_CODE_RESPONSE';
export const FETCH_PRODUCT_TYPES_REQUEST  = 'FETCH_PRODUCT_TYPES_REQUEST';
export const FETCH_PRODUCT_TYPES_RESPONSE = 'FETCH_PRODUCT_TYPES_RESPONSE';
export const REGISTER_WARRANTY_INIT       = 'REGISTER_WARRANTY_INIT';
export const REGISTER_WARRANTY_REQUEST    = 'REGISTER_WARRANTY_REQUEST';
export const REGISTER_WARRANTY_RESPONSE   = 'REGISTER_WARRANTY_RESPONSE';


// Action definitions
export class CheckPromoCodeRequestAction implements Action {
  readonly type = CHECK_PROMO_CODE_REQUEST;
  constructor(public payload: string) {}
}

export class CheckPromoCodeResponseAction implements Action {
  readonly type = CHECK_PROMO_CODE_RESPONSE;
  constructor(public payload: CheckPromoCodeResponse) {}
}

export class FetchProductTypesRequestAction implements Action {
  readonly type = FETCH_PRODUCT_TYPES_REQUEST;
}

export class FetchProductTypesResponseAction implements Action {
  readonly type = FETCH_PRODUCT_TYPES_RESPONSE;
  constructor(public payload: FetchProductTypesResponse) {}
}

export class RegisterWarrantyInitAction implements Action {
  readonly type = REGISTER_WARRANTY_INIT;
}

export class RegisterWarrantyRequestAction implements Action {
  readonly type = REGISTER_WARRANTY_REQUEST;
  constructor(public payload: RegisterWarrantyRequest) {}
}

export class RegisterWarrantyResponseAction implements Action {
  readonly type = REGISTER_WARRANTY_RESPONSE;
  constructor(public payload: RegisterWarrantyResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | CheckPromoCodeRequestAction
  | CheckPromoCodeResponseAction
  | FetchProductTypesRequestAction
  | FetchProductTypesResponseAction
  | RegisterWarrantyInitAction
  | RegisterWarrantyRequestAction
  | RegisterWarrantyResponseAction;
