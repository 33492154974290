import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { StoreState } from '../store';
import { OnboardingQuestionErrorResponse, OnboardingQuestionResponse } from '../../models/onboarding-questions';
import * as ActionTypes from '../actions/onboarding-questions';
import { OnboardingQuestionsService } from '../../services/onboarding-questions.service';
import { selectLoginDetailsState, selectOnboardingQuestionsAnswers } from '../selectors/login-details';
import { FetchUserProfileRequestAction } from '../actions/login-details';
import { Router } from '@angular/router';

@Injectable()
export class OnboardingQuestionEffects {
  constructor(
    private service: OnboardingQuestionsService,
    private actions$: Actions,
    private store: Store<StoreState>,
    private router: Router,
  ) { }

  onboardingQuestionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GET_ONBOARDING_QUESTIONS),
    switchMap(() => this.service.getQuestions()
      .then((payload: OnboardingQuestionResponse) => new ActionTypes.GetOnboardingQuestionsSuccess(payload.data))
      .catch((error: OnboardingQuestionErrorResponse) => new ActionTypes.GetOnboardingQuestionsFailure(error))
    )));

    onboardingAnswersRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(ActionTypes.PUT_ONBOARDING_ANSWERS),
      withLatestFrom(this.store.select(selectOnboardingQuestionsAnswers)),
      switchMap(([req, answers]: [ActionTypes.PutOnboardingAnswers, object]) => this.service.putAnswers({ ...answers, ...req.payload })
        .then((payload) => new ActionTypes.PutOnboardingAnswersSuccess(payload))
        .catch((error: any) => new ActionTypes.PutOnboardingAnswersFailure(error))
      )));

  onboardingAnswersSuccess$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PUT_ONBOARDING_ANSWERS_SUCCESS),
    map((): void => {
      this.store.dispatch(new FetchUserProfileRequestAction());
      const user$ = this.store.select(selectLoginDetailsState);

      user$.subscribe(user => {
        if (user.currentProfile.optIns.length === 0) {
          this.router.navigate(['/optin']);
        } else {
          this.router.navigate(['/home']);
        }
      });
    })
  ), { dispatch: false });
}
