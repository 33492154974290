<div class="container-fluid homepage">

  <div class="row content-page">
    <app-message-panel kind="ERROR" *ngIf="(resources$|async).error">{{(resources$|async).error}}</app-message-panel>
    <app-message-panel kind="ERROR" *ngIf="(loginDetails$|async).error">{{(loginDetails$|async).error}}
    </app-message-panel>
    <app-message-panel kind="ERROR" *ngIf="(serviceRecords$|async).error">{{(serviceRecords$|async).error}}
    </app-message-panel>

    <app-page-content page="DASHBOARD" field="intro"></app-page-content>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Register job / warranty</h3>
      </div>
      <div class="widget-content">
        <app-warranty-widget></app-warranty-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Business tools</h3>
      </div>
      <div class="widget-content">
        <app-business-tools-widget></app-business-tools-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Your rewards</h3>
      </div>
      <div class="widget-content">
        <app-rewards-widget [userProfile]="(loginDetails$|async).currentProfile"
                            [profilePending]="(loginDetails$|async).pending">
          <app-waiting-spinner kind="spinner" color="warn" class="rewards-loading align-center"
                               *ngIf="(loginDetails$|async).pending"></app-waiting-spinner>
        </app-rewards-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>My profile</h3>
      </div>
      <div class="widget-content">

        <app-profile-widget [currentProfile]="(loginDetails$|async).currentProfile"
                            [profilePending]="(loginDetails$|async).pending">
          <app-waiting-spinner kind="spinner" class="widget-loading align-center" *ngIf="(loginDetails$|async).pending">
          </app-waiting-spinner>
        </app-profile-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Job records</h3>
      </div>
      <div class="widget-content">
        <app-service-widget additionalClasses="homepage-widget" [pending]="(serviceRecords$|async).pending"
                            [records]="(serviceRecords$|async).records" (onRequestAdded)="handleJobUpdateRequest($event)">
        </app-service-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Resources</h3>
      </div>
      <div class="widget-content">
        <app-resource-widget [resources]="(resources$|async).items" [pending]="(resources$|async).pending">
        </app-resource-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Savings calculator</h3>
      </div>
      <div class="widget-content">
        <app-roi-widget></app-roi-widget>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-xs-12 widget-container">
      <div class="widget-header background-beige">
        <h3>Product selector</h3>
      </div>
      <div class="widget-content">
        <app-product-widget></app-product-widget>
      </div>
    </div>
  </div>
</div>
