import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchUserRolesResponse, RegistrationResponse } from '../../models/registration';
import { RegisterService } from '../../services/register.service';
import * as ActionTypes from '../actions/registration';

@Injectable()
export class RegistrationEffects {
  constructor(
    private registerService: RegisterService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchUserRolesRequestAction, call RegisterService::fetchUserRoles$()
   * and dispatch a new FetchUserRolesResponseAction with the response.
   */
   fetchUserRoles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_USER_ROLES_REQUEST),
    switchMap((): Observable<ActionTypes.FetchUserRolesResponseAction> =>
      this.registerService.fetchUserRoles().pipe(
        map((res: FetchUserRolesResponse): ActionTypes.FetchUserRolesResponseAction =>
          new ActionTypes.FetchUserRolesResponseAction(res)
        ))
    )));

  /**
   * For a RegistrationRequestAction, call RegisterService::registerNewUser()
   * and dispatch a new RegistrationResponseAction with the response.
   */
   registerRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REGISTRATION_REQUEST),
    switchMap((req: ActionTypes.RegistrationRequestAction): Observable<Action> => {
      return this.registerService.registerNewUser(req.payload).pipe(
        map((res: RegistrationResponse): ActionTypes.RegistrationResponseAction => new ActionTypes.RegistrationResponseAction(res)));
    })));

  /**
   * For a SimpleRegistrationRequestAction, call RegisterService::registerNewUser()
   * and dispatch a new RegistrationResponseAction with the response.
   */
   simpleRegisterRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SIMPLE_REGISTRATION_REQUEST),
    switchMap((req: ActionTypes.SimpleRegistrationRequestAction): Observable<Action> => {
      return this.registerService.registerNewSimpleUser(req.payload).pipe(
        map((res: RegistrationResponse): ActionTypes.RegistrationResponseAction => new ActionTypes.RegistrationResponseAction(res)));
    })));

  /**
   * For a RegistrationWaterTesterRequestAction, call RegisterService::registerNewWaterTesterUser()
   * and dispatch a new RegistrationWaterTesterResponseAction with the response.
   */
   registerWaterTesterRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REGISTRATION_WATER_TESTER_REQUEST),
    switchMap((req: ActionTypes.RegistrationWaterTesterRequestAction): Observable<Action> => {
      return this.registerService.registerNewWaterTesterUser(req.payload).pipe(
        map((res: RegistrationResponse): ActionTypes.RegistrationWaterTesterResponseAction => new ActionTypes.RegistrationWaterTesterResponseAction(res)));
    })));
}
