/**
 * admin-rewards models
 */

import * as moment from 'moment';
import {
  AdminUser,
  UserPoints,
} from './admin-users';

export interface AddAdminPromotionRequest {
  promotion: Promotion;
}

export interface AddAdminPromotionResponse {
  error: string;
  promotionId: string;
}

export interface DeleteAdminPromotionRequest {
  id: string;
}

export interface DeleteAdminPromotionResponse {
  error: string;
  id: string;
}

export interface FetchAdminPromotionsResponse {
  error: string | null;
  promotions: Promotion[];
}

export interface FetchUserPointsHistoryRequest {
  id: string;
}

export interface FetchUserPointsHistoryResponse {
  error: string | null;
  pointsHistory: PointsHistory[];
}

export class PointsHistory {
  createdAt: string;
  productId: number | string;
  serialNumber: string;
  pointsAwarded: number;
  promotionName: string;
  promotionReference: string;

  /**
   * Creates instance from API data model 
   * @param data
   */
  static fromAPI(data: any): PointsHistory {
    return {
      createdAt: moment(data.created_at).format('YYYY-MM-DD'),
      productId: data.points_promotions_by_promotion_id ? data.points_promotions_by_promotion_id.product_id : null,
      serialNumber: data.warranty_by_warranty_id ? data.warranty_by_warranty_id.product_serial_number : null,
      pointsAwarded: data.points_awarded,
      promotionName: data.points_promotions_by_promotion_id ? data.points_promotions_by_promotion_id.name : null,
      promotionReference: data.points_promotions_by_promotion_id ? data.points_promotions_by_promotion_id.reference : null,
    };
  }
}

/**
 * Points promotion model
 */
export class Promotion {
  id: string;
  productId: number;
  name: string;
  description: string;
  reference: string;
  points: number;
  startDate: string;
  endDate: string;
  userId: string;
  userName: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  /**
   * Creates instance from API data model 
   * @param data
   */
  static fromAPI(data: any): Promotion {
    return {
      id: data.id,
      productId: data.product_id,
      name: data.name,
      description: data.description,
      reference: data.reference,
      points: data.points,
      startDate: moment(data.start_date).format('YYYY-MM-DD'),
      endDate: moment(data.end_date).format('YYYY-MM-DD'),
      userId: data.user_id,
      userName: data.user_by_user_id ? `${data.user_by_user_id.first_name} ${data.user_by_user_id.last_name}` : data.user_id,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      deletedAt: data.deleted_at,
    };
  }

  /**
   * Converts the model to the API data format
   * @param promo
   */
  static toAPI(promo: Promotion): any {
    return {
      product_id: promo.productId,
      name: promo.name,
      description: promo.description,
      reference: promo.reference,
      start_date: moment(promo.startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(promo.endDate).format('YYYY-MM-DD HH:mm:ss'),
      points: promo.points,
    };
  }
}

export interface TransferAdminUserPointsRequest {

  // Points are deducted from userFrom and added to userTo
  userFrom: AdminUser;
  userTo: AdminUser;

  // Number of points to transfer (userFrom must have enough)
  amount: number;
}

export interface TransferAdminUserPointsResponse {
  error: string;
  userFrom: AdminUser;
  userTo: AdminUser;
}

export interface UpdateAdminUserPointsRequest {
  user: AdminUser;
  points: UserPoints;
}

export interface UpdateAdminUserPointsResponse {
  error: string;
  user: AdminUser;
}
