import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State as LoginDetailsState }   from '../../state-management/reducers/login-details';
import {Observable} from "rxjs";
import {StoreState} from "../../state-management/store";

@Component({
    selector: 'app-user-optin',
    templateUrl: './user-optin.component.html',
    styleUrls: ['./user-optin.component.scss']
})
export class UserOptinComponent implements OnInit {

    // Store states and Subscriptions
    public loginDetails$:   Observable<LoginDetailsState>;

    constructor(
        private store:  Store<StoreState>,
        private router: Router
    ) {
        this.loginDetails$   = this.store.select('loginDetails');
    }

    ngOnInit() {}

}
