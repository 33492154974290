<div class="container-fluid">

  <div class="row content-page" *ngIf="adminEvents$ | async; let adminEvents">

    <div class="container-title">
      <h1 class="white">Events</h1>
    </div>

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <button mat-button class="pull-right" (click)="addEventType()">Add new event type</button>
        <h2 class="color-adey-red">Event types</h2>
        <app-message-panel kind="ERROR" *ngIf="adminEvents.eventTypes.error">{{adminEvents.eventTypes.error}}</app-message-panel>
        <app-waiting-spinner kind="spinner" *ngIf="adminEvents.eventTypes.pending">Loading event types...</app-waiting-spinner>
        <div *ngIf="adminEvents.eventTypes.types.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let et of adminEvents.eventTypes.types">
              <p matLine>{{et.name}}</p>
              <button mat-button class="pull-right" (click)="editEventType(et)">Edit</button>
              <button mat-button class="pull-right" (click)="deleteEventType(et)">Delete</button>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="event-type-edit" *ngIf="et !== null">
          <hr />
          <form [formGroup]="fgEventType" id="fgEventType" novalidate (ngSubmit)="saveEventType()">
            <label *ngIf="!et.id">Add event type:</label>
            <label *ngIf="et.id">Edit event type:</label>
            <mat-form-field>
              <input matInput formControlName="name" placeholder="Event type label" />
            </mat-form-field>
            <button mat-button form="fgEventType" type="submit">Save</button>
          </form>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <button mat-button class="pull-right" (click)="addAdeyEvent()">Add new ADEY event</button>
        <h2 class="color-adey-red">ADEY Events</h2>
        <app-message-panel kind="ERROR" *ngIf="adminEvents.adeyEvents.error">{{adminEvents.adeyEvents.error}}</app-message-panel>
        <app-message-panel
          kind="INFO"
          *ngIf="!adminEvents.adeyEvents.error && !adminEvents.adeyEvents.pending && adminEvents.adeyEvents.events.length === 0"
        >
          No events to display
        </app-message-panel>
        <app-waiting-spinner kind="spinner" *ngIf="adminEvents.adeyEvents.pending">Loading ADEY events...</app-waiting-spinner>

        <div class="event-list" *ngIf="adminEvents.adeyEvents.events.length > 0">

          <div class="row">
            <div class="list-pagination col-xs-12">
              <button
                class="btn btn-default"
                *ngIf="pageNumAdey > 1"
                (click)="pagePrevAdey()"
              >
                &lt; Prev
              </button>
              <span>Page {{pageNumAdey}} of {{totalPagesAdey}}</span>
              <button
                class="btn btn-default"
                *ngIf="pageNumAdey < totalPagesAdey"
                (click)="pageNextAdey()"
              >
                Next &gt;
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let ev of adminEvents.adeyEvents.events">
            <mat-card>
              <mat-card-header>
                <app-waiting-spinner kind="spinner" *ngIf="!ev.committed"></app-waiting-spinner>
                <mat-card-title>
                  {{ev.details}}
                </mat-card-title>
                <mat-card-subtitle>
                  Type: {{ev.typeName ? ev.typeName : ev.typeOfEvent}}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p>Date: {{getEventDateTime(ev) | date}}</p>
                <p *ngIf="!ev.allDay">Duration: {{ getFormattedDuration(ev.duration) }}</p>
                <p *ngIf="ev.allDay">All day event</p>
                <p>{{ev.description}}</p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="editEvent(ev)">Edit</button>
                <button mat-button (click)="deleteEvent(ev)">Delete</button>
                <button mat-button (click)="duplicateEvent(ev)">Duplicate</button>
              </mat-card-actions>
            </mat-card>
          </div>

        </div>

      </div>
    </div>

    <hr />

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <button mat-button class="pull-right" (click)="addUserEvent()">Add new user event</button>
        <h2 class="color-adey-red">User Events</h2>

        <form [formGroup]="fgUserSelect" id="fgUserSelect" novalidate (ngSubmit)="fetchUserEvents()">
          <mat-form-field>
            <input matInput formControlName="userId" placeholder="User name" [matAutocomplete]="autoUser" />
          </mat-form-field>
          <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserSuggestion">
            <mat-option *ngFor="let u of adminEvents.users" [value]="u">
              {{u.title}} {{u.firstName}} {{u.lastName}} ({{u.id}})
            </mat-option>
          </mat-autocomplete>
          <button mat-button form="fgUserSelect" type="submit">Show events</button>
        </form>

        <hr />

        <app-message-panel kind="ERROR" *ngIf="adminEvents.userEvents.error">{{adminEvents.userEvents.error}}</app-message-panel>
        <app-message-panel
          kind="INFO"
          *ngIf="!adminEvents.userEvents.error && !adminEvents.userEvents.pending && adminEvents.userEvents.events.length === 0"
        >
          No events to display
        </app-message-panel>
        <app-waiting-spinner kind="spinner" *ngIf="adminEvents.userEvents.pending">Loading user events...</app-waiting-spinner>

        <div class="event-list" *ngIf="adminEvents.userEvents.events.length > 0">

          <div class="row">
            <div class="list-pagination col-xs-12">
              <button
                class="btn btn-default"
                *ngIf="pageNumUser > 1"
                (click)="pagePrevUser()"
              >
                &lt; Prev
              </button>
              <span>Page {{pageNumUser}} of {{totalPagesUser}}</span>
              <button
                class="btn btn-default"
                *ngIf="pageNumUser < totalPagesUser"
                (click)="pageNextUser()"
              >
                Next &gt;
              </button>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let ev of adminEvents.userEvents.events">
            <mat-card>
              <mat-card-header>
                <app-waiting-spinner kind="spinner" *ngIf="!ev.committed"></app-waiting-spinner>
                <mat-card-title>
                  {{ev.details}}
                </mat-card-title>
                <mat-card-subtitle>
                  Type: {{ev.typeName ? ev.typeName : ev.typeOfEvent}}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p>Date: {{getEventDateTime(ev) | date}}</p>
                <p *ngIf="!ev.allDay">Duration: {{ev.duration}} minutes</p>
                <p *ngIf="ev.allDay">All day event</p>
                <p>{{ev.description}}</p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="editEvent(ev)">Edit</button>
                <button mat-button (click)="deleteEvent(ev)">Delete</button>
                <button mat-button (click)="duplicateEvent(ev)">Duplicate</button>
              </mat-card-actions>
            </mat-card>
          </div>

        </div>

      </div>
    </div>

  </div>

</div>
