<h2 matDialogTitle>Please select a matching address</h2>

<mat-dialog-content>
  <div *ngIf="postcodeLookupState$ | async; let state">
    <app-waiting-spinner kind="spinner" *ngIf="state.pendingAddress">Loading address...</app-waiting-spinner>
    <app-waiting-spinner kind="spinner" *ngIf="state.pendingPostcode"></app-waiting-spinner>
    <app-message-panel kind="ERROR" *ngIf="state.errorPostcode">{{state.errorPostcode}}</app-message-panel>
    <app-message-panel kind="ERROR" *ngIf="state.errorAddress">{{state.errorAddress}}</app-message-panel>
    <mat-list *ngIf="!state.pendingPostcode && state.results && state.results.length > 0">
      <mat-list-item *ngFor="let r of state.results" (click)="onSelectItem(r)" class="cursor-pointer">{{r.text}} {{r.description}}</mat-list-item>
    </mat-list>
    <p *ngIf="!state.pendingPostcode && (!state.results || state.results.length === 0)">
      No addresses found matching <strong>{{data.postcode}}</strong>
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Close</button>
</mat-dialog-actions>
