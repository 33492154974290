import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  FetchWarrantiesResponse,
  FetchWarrantyPdfResponse,
  FetchWarrantyResponse,
  UpdateWarrantyResponse,
} from '../../models/warranty';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/warranty-list';

@Injectable()
export class WarrantiesEffects {
  constructor(
    private service: WarrantyService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchWarrantyRequestAction, call
   * WarrantyService::fetchWarranty() and dispatch a new
   * FetchWarrantyResponseAction with the response.
   */
   fetchWarrantyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_WARRANTY_REQUEST),
    switchMap((req: ActionTypes.FetchWarrantyRequestAction): Observable<Action> => {
      return this.service.fetchWarranty(req.payload).pipe(
        map((res: FetchWarrantyResponse): ActionTypes.FetchWarrantyResponseAction => {
          return new ActionTypes.FetchWarrantyResponseAction(res);
        }));
    })));

  /**
   * For a FetchWarrantiesRequestAction, call
   * WarrantyService::fetchWarranties() and dispatch a new
   * FetchWarrantiesResponseAction with the response.
   */
   fetchWarrantiesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_WARRANTIES_REQUEST),
    switchMap((req: ActionTypes.FetchWarrantiesRequestAction): Observable<Action> => {
      return this.service.fetchWarranties(req.payload).pipe(
        map((res: FetchWarrantiesResponse): ActionTypes.FetchWarrantiesResponseAction =>
          new ActionTypes.FetchWarrantiesResponseAction(res)
        ));
    })));

  /**
   * For a FetchWarrantyPdfRequestAction, call
   * WarrantyService::getWarrantyPDF() and dispatch a new
   * FetchWarrantyPdfResponseAction with the response.
   */
   fetchWarrantyPdfRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_WARRANTY_PDF_REQUEST),
    switchMap((req: ActionTypes.FetchWarrantyPdfRequestAction): Observable<ActionTypes.FetchWarrantyPdfResponseAction> => {
      return this.service.getWarrantyPDF(req.payload).pipe(
        map((res: FetchWarrantyPdfResponse): ActionTypes.FetchWarrantyPdfResponseAction =>
          new ActionTypes.FetchWarrantyPdfResponseAction(res)
        ));
    })));

  /**
   * For an UpdateWarrantyRequestAction, call WarrantyService::updateWarranty()
   * and dispatch a new UpdateWarrantyResponseAction with the response.
   */
   updateWarrantyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_WARRANTY_REQUEST),
    switchMap((req: ActionTypes.UpdateWarrantyRequestAction): Observable<Action> =>
      this.service.updateWarranty(req.payload).pipe(
        map((res: UpdateWarrantyResponse): ActionTypes.UpdateWarrantyResponseAction =>
          new ActionTypes.UpdateWarrantyResponseAction(res)
        ))
    )));
}
