<div [class]="'event-list' + (pagination ? ' has-pagination' : ' scroll-container')">
  <p *ngIf="!pageEvents || pageEvents.length === 0">
    There are no upcoming events
  </p>
  <mat-list *ngIf="pageEvents && pageEvents.length > 0" class="no-pad">
    <app-diary-event
      *ngFor="let e of pageEvents"
      [event]="e"
      (onEventClick)="onEventClick($event)"
      [class]="(pagination ? 'border-light-gray' : 'border-white' )"
    >
    </app-diary-event>
  </mat-list>
  <button
    class="btn btn-default prev-button"
    *ngIf="pageEvents && pageEvents.length > 0 && pagination"
    [disabled]="!(perPage > 0 && pageNumber > 1)"
    (click)="pagePrev()"
  >
    &lt;
  </button>
  <button
    class="btn btn-default next-button"
    *ngIf="pageEvents && pageEvents.length > 0 && pagination"
    [disabled]="!(perPage > 0 && filteredEvents.length > perPage * pageNumber)"
    (click)="pageNext()"
  >
    &gt;
  </button>
</div>

<button
  class="btn btn-link color-white event-list-more"
  type="button"
  (click)="loadMore()"
  *ngIf="filteredEvents.length > displayPerPage"
>
  See more...
</button>
