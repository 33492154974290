import { Component, OnInit, Input } from '@angular/core';

import { Router } from '@angular/router';
import { UserProfile } from '../../../models/user-profile';

/**
 * Summary
 *    Display a summary of the user's rewards in a widget.
 *    
 *
 * Description
 *    Display a summary of the user's rewards in a widget, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-rewards-widget',
  templateUrl: './rewards-widget.component.html',
  styleUrls: ['./rewards-widget.component.scss']
})
export class RewardsWidgetComponent implements OnInit {
  // The current user's profile
  @Input() userProfile: UserProfile = null;
  // Flag to determine if the data is still loading
  @Input() profilePending: boolean = false;
  
  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  
  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }
}
