import { Component, Input, OnInit } from '@angular/core';

/**
 * Summary
 *    Provides a common waiting spinner/bar component
 *
 *
 * Description
 *    This component provides a common way of displaying a progress spinner or
 *    bar in the app. Internally an Angular Material component is used. Child
 *    content can also be included which will be displayed alongside the
 *    spinner or bar.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-waiting-spinner',
  templateUrl: './waiting-spinner.component.html',
  styleUrls: ['./waiting-spinner.component.scss']
})
export class WaitingSpinnerComponent implements OnInit {

  // The kind of component to use ("spinner" or "bar")
  @Input() kind: string = 'spinner';

  // The Material colour to use
  @Input() colorType: string = 'primary';

  // Radius of the spinner (in pixels)
  @Input() spinnerSize = 50;

  // Stroke width of the spinner (in pixels)
  @Input() spinnerStroke = 5;


  constructor() { }

  ngOnInit() { }
}
