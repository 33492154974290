import { Component, OnInit, Inject } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * Summary
 *    Modal dialog used for playing YouTube videos
 *
 *
 * Description
 *    Embeds a YouTube video in an internal iframe allowing a user to view the
 *    video within the modal dialog.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-youtube-modal',
  templateUrl: './youtube-modal.component.html',
  styleUrls: ['./youtube-modal.component.scss']
})
export class YoutubeModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() { }
}
