/**
 * admin-users actions: defines all actions relating to the user management
 * admin CMS.
 */
import { Action } from '@ngrx/store';

import {
  AddAdminUserRequest,
  AddAdminUserResponse,
  DeleteAdminUserRequest,
  DeleteAdminUserResponse,
  FetchAdminUsersRequest,
  FetchAdminUsersResponse,
  UpdateAdminUserRequest,
  UpdateAdminUserResponse,
} from '../../models/admin-users';
import { FetchAccreditationsResponse } from '../../models/user-profile';

// Action type definitions
export const ADD_ADMIN_USER_REQUEST = 'ADD_ADMIN_USER_REQUEST';
export const ADD_ADMIN_USER_RESPONSE = 'ADD_ADMIN_USER_RESPONSE';
export const DELETE_ADMIN_USER_REQUEST = 'DELETE_ADMIN_USER_REQUEST';
export const DELETE_ADMIN_USER_RESPONSE = 'DELETE_ADMIN_USER_RESPONSE';
export const FETCH_ADMIN_USERS_REQUEST = 'FETCH_ADMIN_USERS_REQUEST';
export const FETCH_ADMIN_USERS_RESPONSE = 'FETCH_ADMIN_USERS_RESPONSE';
export const UPDATE_ADMIN_USER_REQUEST = 'UPDATE_ADMIN_USER_REQUEST';
export const UPDATE_ADMIN_USER_RESPONSE = 'UPDATE_ADMIN_USER_RESPONSE';
export const FETCH_ACCREDITATIONS_REQUEST = 'FETCH_ACCREDITATIONS_REQUEST';
export const FETCH_ACCREDITATIONS_RESPONSE = 'FETCH_ACCREDITATIONS_RESPONSE';


// Action definitions
export class AddAdminUserRequestAction implements Action {
  readonly type = ADD_ADMIN_USER_REQUEST;
  constructor(public payload: AddAdminUserRequest) { }
}

export class AddAdminUserResponseAction implements Action {
  readonly type = ADD_ADMIN_USER_RESPONSE;
  constructor(public payload: AddAdminUserResponse) { }
}

export class DeleteAdminUserRequestAction implements Action {
  readonly type = DELETE_ADMIN_USER_REQUEST;
  constructor(public payload: DeleteAdminUserRequest) { }
}

export class DeleteAdminUserResponseAction implements Action {
  readonly type = DELETE_ADMIN_USER_RESPONSE;
  constructor(public payload: DeleteAdminUserResponse) { }
}

export class FetchAdminUsersRequestAction implements Action {
  readonly type = FETCH_ADMIN_USERS_REQUEST;
  constructor(public payload: FetchAdminUsersRequest) { }
}

export class FetchAdminUsersResponseAction implements Action {
  readonly type = FETCH_ADMIN_USERS_RESPONSE;
  constructor(public payload: FetchAdminUsersResponse) { }
}

export class UpdateAdminUserRequestAction implements Action {
  readonly type = UPDATE_ADMIN_USER_REQUEST;
  constructor(public payload: UpdateAdminUserRequest) { }
}

export class UpdateAdminUserResponseAction implements Action {
  readonly type = UPDATE_ADMIN_USER_RESPONSE;
  constructor(public payload: UpdateAdminUserResponse) { }
}

export class FetchAccreditationsRequestAction implements Action {
  readonly type = FETCH_ACCREDITATIONS_REQUEST;
}
export class FetchAccreditationsResponseAction implements Action {
  readonly type = FETCH_ACCREDITATIONS_RESPONSE;
  constructor(public payload: FetchAccreditationsResponse) { }
}


// Export Actions type as a union of all defined actions
export type Actions =
  | AddAdminUserRequestAction
  | AddAdminUserResponseAction
  | DeleteAdminUserRequestAction
  | DeleteAdminUserResponseAction
  | FetchAdminUsersRequestAction
  | FetchAdminUsersResponseAction
  | UpdateAdminUserRequestAction
  | UpdateAdminUserResponseAction
  | FetchAccreditationsRequestAction
  | FetchAccreditationsResponseAction;
