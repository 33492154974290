/**
 * procheck-site models
 */

import {environment} from '../../environments/environment';

/**
 * Check is if proCheck site
 */
export class ProCheckSite {
  static DEFAULT_ROUTE = '/watertest/dashboard';

  /**
   * Returns if proCheck or not
   *
   * @return boolean
   */
  static getCheck(): boolean {
    const pattern = environment.proCheckUrl;
    //console.log(pattern);
    const uri = window.location.href;
    const re = new RegExp(pattern, 'gi');
    return ( uri.search(re) > -1 );
  }
}

