
import {map} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { Store }        from '@ngrx/store';
import { Observable ,  Subscription }   from 'rxjs';


import { StoreState }              from '../../../state-management/store';
import { State as ResourcesState } from '../../../state-management/reducers/resources';

import { FetchResourceItemsRequestAction } from '../../../state-management/actions/resources';

import {
  BooleanOperator,
  FetchResourceItemsRequest,
} from '../../../models/resources';


/**
 * Summary
 *    Displays a list of selected resources.
 *
 *
 * Description
 *    Display a list of videos and downloadable documents depending on the page url.
 *    Items can be filtered by the user in order to search for specific keywords or categories.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnDestroy, OnInit {

  // Selected store "resources"
  public resources$: Observable<ResourcesState>;

  // Subscription to the "pageName" route parameter
  private pageNameSub$: Subscription;

  // Subscription to the "resources" store reducer
  private resourcesSub$: Subscription;

  // Current page name for display
  public pageName: string;

  // Search form
  public fgSearch: UntypedFormGroup;

  // List of categories, obtained from the result items
  public categories: string[] = [];

  /**
   * Constructor for page, initialises store, router and formbuilder.
   *
   * @param {FormBuilder} fb          Initialises a FormBuilder instance
   * @param {ActivatedRoute} route    Initialises ActivatedRoute object so that data can be extracted from the url
   * @param {Router} router           Initialises Router object providing navigation between pages
   * @param {Store<StoreState>} store Initialises Store instance
   */
  constructor(
    private fb:     UntypedFormBuilder,
    private route:  ActivatedRoute,
    private router: Router,
    private store:  Store<StoreState>,
  ) {
  }

  /**
   * When the page loads, retrieve and map data from the url so that it can be used to
   * retrieve the correct items from the API.
   */
  ngOnInit() {
    // map the values from route.paramMap and subscribe in order to get the result.
    this.pageNameSub$ = this.route.paramMap.pipe(
      map((params: ParamMap) => {
        // return the data in this format.
        const data = {
          id: params.get('id'),
          resource: params.get('resource')
        };
        return data;
      }))
      .subscribe((params: any) => {
        if (params)
        {
          // Set a reference to the page name.
          this.pageName = params.id;

          // Pass the data to loadResources() with uncategorised data.
          this.loadResources({
            page:     params.id,
            category: null,
            search:   {
              docType: params.resource,
              docTypeOperator: 1,
              keywords: null,
              keywordsOperator: null,
              title: '',
              titleOperator: null
            },
          });
        }
      });

    // Set a reference to the resource state.
    this.resources$ = this.store.select('resources');

    // Subscribe to the resource state in order to retrieve a list of categories
    // based on the data, for use in the filter.
    this.resourcesSub$ = this.resources$.subscribe((resources: ResourcesState) => {
      this.buildForm(resources.request);
      if (resources.items.length > 0)
        this.categories = Object.keys(
          resources.items.reduce((a, v) => {
            v.categories.forEach((c) => {
              a[c] = true;
            });
            return a;
          }, {})
        );
    });
  }

  /**
   * Unsubscribe from the resource state and route.paramMap when the page is
   * destroyed in order to free up memory.
   */
  ngOnDestroy() {
    if (this.resourcesSub$)
      this.resourcesSub$.unsubscribe();
    if (this.pageNameSub$)
      this.pageNameSub$.unsubscribe();
  }

  /**
   * Construct the filter form based on the data provided from the resource state.
   *
   * @param {FetchResourceItemsRequest} init Data provided by the resource state
   */
  buildForm(init: FetchResourceItemsRequest = null) {
    this.fgSearch = this.fb.group({
      category:            init && init.category ? init.category   : null,
      description:         init && init.search ? init.search.title : '',
      keywords:            init && init.search && init.search.keywords ? init.search.keywords.join(' ') : '',
      itemType:            init && init.search ? init.search.docType          : null,
      docTypeOperator:     init && init.search ? init.search.docTypeOperator  : 'AND',
      descriptionOperator: init && init.search ? init.search.titleOperator    : 'AND',
      keywordsOperator:    init && init.search ? init.search.keywordsOperator : 'AND',
    });
  }

  /**
   * Dispatch loadResources to the state in order to retrieve a list of items that
   * match the search criteria.
   *
   * @param {FetchResourceItemsRequest} req The search criteria used to return the correct content
   */
  loadResources(req: FetchResourceItemsRequest = null) {
    this.store.dispatch(
      new FetchResourceItemsRequestAction(
        req ? req : {
          page:     this.pageName,
          category: null,
          search:   null,
        }
      )
    );
  }

  /**
   * Called when the user submits the form, will call loadResources() with a
   * new set of search criteria based on form values.
   */
  onSearchSubmit() {
    this.loadResources({
      page:     this.pageName,
      category: this.fgSearch.value.category,
      search: {
        title:            this.fgSearch.value.description,
        keywords:         this.fgSearch.value.keywords.trim().length > 0 ? this.fgSearch.value.keywords.split(' ') : null,
        docType:          this.fgSearch.value.itemType,
        titleOperator:    this.fgSearch.value.descriptionOperator === 'AND' ? BooleanOperator.AND : BooleanOperator.OR,
        keywordsOperator: this.fgSearch.value.keywordsOperator   === 'AND' ? BooleanOperator.AND : BooleanOperator.OR,
        docTypeOperator:  this.fgSearch.value.docTypeOperator    === 'AND' ? BooleanOperator.AND : BooleanOperator.OR,
      },
    });
  }
}
