<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">
        <app-page-content page="TRUEQUOTE_WEBSITE" field="title"></app-page-content>
      </h1>
    </div>

    <app-page-content page="TRUEQUOTE_WEBSITE" field="intro" class="intro-above-content color-white"></app-page-content>

    <div class="background-white padded-container col-xs-12">
      <app-page-content page="TRUEQUOTE_WEBSITE" field="content"></app-page-content>
      <br>
      <a class="btn background-green color-white no-radius" href="https://truequote.co.uk/adey" target="_blank">
        Get your own ADEY Website today
      </a>
    </div>

  </div>
</div>