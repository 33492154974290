<!-- <h1 mat-dialog-title>All NEW MagnaCleanse animation for homeowners!</h1> -->

<mat-dialog-content>
  <img (click)="open()" class="promo-image" alt="Win back your heating bills" src="assets/i/promotional/wbyhb.png">
  <!-- <div class="embed-responsive embed-responsive-16by9">
    <iframe src="https://www.youtube.com/embed/V1Xz54FdKQU" title="All NEW MagnaCleanse animation for homeowners!"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div> -->
  <br>
  <!-- <p>Show your customers what the MagnaCleanse flushing process is and its benefits with this short video.</p> -->
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="open()">Tell Me More</button>
  <button mat-button (click)="dismiss()">Maybe Later</button>
</mat-dialog-actions>