import { Action } from '@ngrx/store';

import {
  InstallerHubVoucherRequest,
  InstallerHubVoucherValues,
  RewardsResponse,
  SparesRewardsRequest,
} from '../../models/rewards';

export const INIT_REWARDS_STATE = 'INIT_REWARDS_STATE';
export const INSTALLER_HUB_VOUCHER_REQUEST = 'INSTALLER_HUB_VOUCHER_REQUEST';
export const INSTALLER_HUB_VOUCHER_RESPONSE = 'INSTALLER_HUB_VOUCHER_RESPONSE';
export const INSTALLER_HUB_VOUCHER_VALUES_REQUEST = 'INSTALLER_HUB_VOUCHER_VALUES_REQUEST';
export const INSTALLER_HUB_VOUCHER_VALUES_RESPONSE = 'INSTALLER_HUB_VOUCHER_VALUES_RESPONSE';
export const SPARES_REWARDS_REQUEST = 'SPARES_REWARDS_REQUEST';
export const SPARES_REWARDS_RESPONSE = 'SPARES_REWARDS_RESPONSE';

export class InitRewardsStateAction implements Action {
  readonly type = INIT_REWARDS_STATE;
}

export class InstallerHubVoucherRequestAction implements Action {
  readonly type = INSTALLER_HUB_VOUCHER_REQUEST;
  constructor(public payload: InstallerHubVoucherRequest) { }
}

export class InstallerHubVoucherResponseAction implements Action {
  readonly type = INSTALLER_HUB_VOUCHER_RESPONSE;
  constructor(public payload: RewardsResponse<string>) { }
}

export class InstallerHubVoucherValuesRequestAction implements Action {
  readonly type = INSTALLER_HUB_VOUCHER_VALUES_REQUEST;
  constructor() { }
}

export class InstallerHubVoucherValuesResponseAction implements Action {
  readonly type = INSTALLER_HUB_VOUCHER_VALUES_RESPONSE;
  constructor(public payload: RewardsResponse<InstallerHubVoucherValues>) { }
}

export class SparesRewardsRequestAction implements Action {
  readonly type = SPARES_REWARDS_REQUEST;
  constructor(public payload: SparesRewardsRequest) { }
}

export class SparesRewardsResponseAction implements Action {
  readonly type = SPARES_REWARDS_RESPONSE;
  constructor(public payload: RewardsResponse) { }
}

export type Actions =
  | InitRewardsStateAction
  | InstallerHubVoucherRequestAction
  | InstallerHubVoucherResponseAction
  | InstallerHubVoucherValuesRequestAction
  | InstallerHubVoucherValuesResponseAction
  | SparesRewardsRequestAction
  | SparesRewardsResponseAction;
