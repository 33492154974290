<div class="container-fluid">

  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">ADEY ProCheck&reg;</h1>
    </div>

    <div class="background-white col-xs-12">
      <h1 class="color-adey-red">Looking to review and resend your ADEY ProCheck® test results?</h1>
      <h2><strong>We've moved to the new <i>ADEY Sense&reg;</i> portal!</strong></h2>
      <a href="https://sense.adey.com/" target="_blank">
        <img alt="ADEY Sense logo" src="assets/i/common/logos/adey-sense-logo.svg">
      </a>
      <p>See all the properties you’ve tested in one place, view results, and send test certificates to any valid email address.</p>
      <p>You’ll even see which properties are due their next annual check!</p>
      <br>
      <p>Go to ADEY Sense and login using your ADEY ProCheck® or ADEY ProClub® details.</p>
      <br>
      <button (click)="goToSense()" mat-raised-button color="primary" class="mat-raised-button mat-primary"><strong>OPEN ADEY SENSE</strong></button>
    </div>

  </div>
</div>
