import { InstallerHubVoucherValues } from '../../models/rewards';
import * as actions from '../actions/rewards';

export interface State {

  error: boolean;
  installHubVoucherCode: string;
  installHubVoucherValues: InstallerHubVoucherValues;
  message: string;
  pending: boolean;
}

const defaultState: State = {
  error: null,
  installHubVoucherCode: null,
  installHubVoucherValues: null,
  message: null,
  pending: false,
};

export function reducer(
  state: State = defaultState,
  action: actions.Actions
): State {
  switch (action.type) {

    case actions.INIT_REWARDS_STATE:
      return Object.assign({}, state, {
        error: null,
        installHubVoucherCode: null,
        installHubVoucherValues: null,
        message: null,
        pending: false,
      });

    case actions.INSTALLER_HUB_VOUCHER_REQUEST:
      return Object.assign({}, state, {
        error: null,
        installHubVoucherCode: null,
        message: null,
        pending: true,
      });

    case actions.INSTALLER_HUB_VOUCHER_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        installHubVoucherCode: action.payload.payload,
        message: action.payload.message,
        pending: false,
      });

    case actions.INSTALLER_HUB_VOUCHER_VALUES_REQUEST:
      return Object.assign({}, state, {
        error: null,
        installHubVoucherCode: null,
        installHubVoucherValues: null,
        message: null,
        pending: true,
      });

    case actions.INSTALLER_HUB_VOUCHER_VALUES_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        installHubVoucherValues: action.payload.payload,
        message: action.payload.message,
        pending: false,
      });

    case actions.SPARES_REWARDS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        message: null,
        pending: true,
      });

    case actions.SPARES_REWARDS_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        message: action.payload.message,
        pending: false,
      });

    default:
      return state;
  }
}
