/**
 * Provides NGRX Actions for the Pro3 Admin Portal
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */

// Created using PhpStorm

//required classes
import {Action} from '@ngrx/store';

import {
    AdminDismissAlertRequest,
    AdminDevice,
    AdminDeviceStatus,
    AdminDeviceDetailRequest,
    AdminDeviceDetailItem,
    AdminDeviceAlert,
    AdminDismissAlertResultItem,
    AdminDeviceStatusRequest,
    AdminPro3RegisterWarrantyRequest,
    AdminDeviceNoteRequest, AdminDeviceNote
} from '../../models/admin-pro3';

import {DFResponse, DFSPRequest, DFTableRequest} from '../../models/df-proclub';
import {UserProfile} from '../../models/user-profile';

// Action type definitions
export const ADMIN_DISMISS_ALERT_REQUEST = 'ADMIN_DISMISS_ALERT_REQUEST';
export const ADMIN_DISMISS_ALERT_RESPONSE = 'ADMIN_DISMISS_ALERT_RESPONSE';

export const ADMIN_DEVICE_ALERT_REQUEST = 'ADMIN_DEVICE_ALERT_REQUEST';
export const ADMIN_DEVICE_ALERT_EXPORT_REQUEST = 'ADMIN_DEVICE_ALERT_EXPORT_REQUEST';

export const ADMIN_DEVICE_ALERT_RESPONSE = 'ADMIN_DEVICE_ALERT_RESPONSE';
export const ADMIN_DEVICE_ALERT_EXPORT_RESPONSE = 'ADMIN_DEVICE_ALERT_EXPORT_RESPONSE';

export const ADMIN_DEVICE_ALERT_EXPORT_CLEAR_DATA_REQUEST = 'ADMIN_DEVICE_ALERT_EXPORT_CLEAR_DATA_REQUEST';

export const ADMIN_DEVICE_STATUS_REQUEST = 'ADMIN_DEVICE_STATUS_REQUEST';
export const ADMIN_DEVICE_STATUS_RESPONSE = 'ADMIN_DEVICE_STATUS_RESPONSE';

export const ADMIN_DEVICE_REQUEST = 'ADMIN_DEVICE_REQUEST';
export const ADMIN_DEVICE_EXPORT_REQUEST = 'ADMIN_DEVICE_EXPORT_REQUEST';
export const ADMIN_DEVICE_EXPORT_CLEAR_DATA_REQUEST = 'ADMIN_DEVICE_EXPORT_CLEAR_DATA_REQUEST';

export const ADMIN_DEVICE_RESPONSE = 'ADMIN_DEVICE_RESPONSE';
export const ADMIN_DEVICE_EXPORT_RESPONSE = 'ADMIN_DEVICE_EXPORT_RESPONSE';

export const ADMIN_DEVICE_DETAIL_REQUEST = 'ADMIN_DEVICE_DETAIL_REQUEST';
export const ADMIN_DEVICE_DETAIL_RESPONSE = 'ADMIN_DEVICE_DETAIL_RESPONSE';

export const ADMIN_DEVICE_NOTE_REQUEST = 'ADMIN_DEVICE_NOTE_REQUEST';
export const ADMIN_DEVICE_NOTE_RESPONSE = 'ADMIN_DEVICE_NOTE_RESPONSE';

export const ADMIN_DEVICE_SAVE_NOTE_REQUEST = 'ADMIN_DEVICE_SAVE_NOTE_REQUEST';

export const ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST = 'ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST';
export const ADMIN_GET_INSTALLER_FROM_PARTIAL_RESPONSE = 'ADMIN_GET_INSTALLER_FROM_PARTIAL_RESPONSE';

export const ADMIN_REGISTER_MAGSENSE_DEVICE_REQUEST = 'ADMIN_REGISTER_DEVICE_WARRANTY_REQUEST';
export const ADMIN_REGISTER_MAGSENSE_DEVICE_RESPONSE = 'ADMIN_REGISTER_DEVICE_WARRANTY_RESPONSE';
export const ADMIN_REGISTER_MAGSENSE_DEVICE_FAILURE = 'ADMIN_REGISTER_DEVICE_WARRANTY_FAILURE';

export const ADMIN_GET_MAGSENSE_WARRANTY_INFO = 'ADMIN_GET_MAGSENSE_WARRANTY_INFO';
export const ADMIN_GET_MAGSENSE_WARRANTY_INFO_SUCCESS = 'ADMIN_GET_MAGSENSE_WARRANTY_INFO_SUCCESS';
export const ADMIN_GET_MAGSENSE_WARRANTY_INFO_FAILED = 'ADMIN_GET_MAGSENSE_WARRANTY_INFO_FAILED';


// Action definitions
export class AdminDeviceRequestAction implements Action {
    readonly type = ADMIN_DEVICE_REQUEST;

    constructor(public payload: DFTableRequest) {
    }
}

export class AdminDeviceExportRequestAction implements Action {
  readonly type = ADMIN_DEVICE_EXPORT_REQUEST;

  constructor(public payload: DFTableRequest) {
  }
}

export class AdminDeviceExportClearDataRequestAction implements Action {
  readonly type = ADMIN_DEVICE_EXPORT_CLEAR_DATA_REQUEST;

  constructor() {
  }
}

export class AdminDeviceResponseAction implements Action {
    readonly type = ADMIN_DEVICE_RESPONSE;

    constructor(public payload: DFResponse<AdminDevice>) {
    }
}

export class AdminDeviceExportResponseAction implements Action {
  readonly type = ADMIN_DEVICE_EXPORT_RESPONSE;

  constructor(public payload: DFResponse<AdminDevice>) {
  }
}

export class AdminDeviceDetailRequestAction implements Action {
    readonly type = ADMIN_DEVICE_DETAIL_REQUEST;

    constructor(public payload: AdminDeviceDetailRequest) {
    }
}

export class AdminDeviceDetailResponseAction implements Action {
    readonly type = ADMIN_DEVICE_DETAIL_RESPONSE;

    constructor(public payload: DFResponse<AdminDeviceDetailItem>) {
    }
}

export class AdminDeviceNoteRequestAction implements Action {
    readonly type = ADMIN_DEVICE_NOTE_REQUEST;

    constructor(public payload: AdminDeviceNoteRequest) {
    }
}

export class AdminDeviceNoteResponseAction implements Action {
    readonly type = ADMIN_DEVICE_NOTE_RESPONSE;

    constructor(public payload: DFResponse<AdminDeviceNote>) {
    }
}

export class AdminDeviceSaveNoteRequestAction implements Action {
    readonly type = ADMIN_DEVICE_SAVE_NOTE_REQUEST;

    constructor(public payload: Partial<AdminDeviceNote>) {
    }
}

export class AdminDeviceAlertRequestAction implements Action {
    readonly type = ADMIN_DEVICE_ALERT_REQUEST;

    constructor(public payload: DFTableRequest) {
    }
}

export class AdminDeviceAlertExportRequestAction implements Action {
  readonly type = ADMIN_DEVICE_ALERT_EXPORT_REQUEST;

  constructor(public payload: DFTableRequest) {
  }
}

export class AdminDeviceAlertResponseAction implements Action {
    readonly type = ADMIN_DEVICE_ALERT_RESPONSE;

    constructor(public payload: DFResponse<AdminDeviceAlert>) {
    }
}

export class AdminDeviceAlertExportResponseAction implements Action {
  readonly type = ADMIN_DEVICE_ALERT_EXPORT_RESPONSE;

  constructor(public payload: DFResponse<AdminDeviceAlert>) {
  }
}

export class AdminDeviceAlertExportClearDataRequestAction implements Action {
  readonly type = ADMIN_DEVICE_ALERT_EXPORT_CLEAR_DATA_REQUEST;

  constructor() {
  }
}

export class AdminDeviceStatusRequestAction implements Action {
    readonly type = ADMIN_DEVICE_STATUS_REQUEST;

    constructor(public payload: DFSPRequest<AdminDeviceStatusRequest>) {
    }
}

export class AdminDeviceStatusResponseAction implements Action {
    readonly type = ADMIN_DEVICE_STATUS_RESPONSE;

    constructor(public payload: DFResponse<AdminDeviceStatus>) {
    }
}

export class AdminDismissAlertRequestAction implements Action {
    readonly type = ADMIN_DISMISS_ALERT_REQUEST;

    constructor(public payload: AdminDismissAlertRequest) {
    }
}

export class AdminDismissAlertResponseAction implements Action {
    readonly type = ADMIN_DISMISS_ALERT_RESPONSE;

    constructor(public payload: DFResponse<AdminDismissAlertResultItem>) {
    }
}

export class AdminGetMagsenseWarrantyInfoActionSuccess implements Action {
    readonly type = ADMIN_GET_MAGSENSE_WARRANTY_INFO_SUCCESS;

    constructor(public payload: DFResponse<AdminPro3RegisterWarrantyRequest>) {
    }
}

export class AdminGetMagsenseWarrantyInfoAction implements Action {
    readonly type = ADMIN_GET_MAGSENSE_WARRANTY_INFO;

    constructor(public payload: DFSPRequest<{ device_id: number }>) {
    }
}

export class AdminGetMagsenseWarrantyInfoActionFailed implements Action {
    readonly type = ADMIN_GET_MAGSENSE_WARRANTY_INFO_FAILED;

    constructor(public error: any) {
    }
}

export class AdminGetInstallerFromPartialRequest implements Action {
    readonly type = ADMIN_GET_INSTALLER_FROM_PARTIAL_REQUEST;

    constructor(public payload: Partial<UserProfile>) {
    }
}

export class AdminGetInstallerFromPartialResponse implements Action {
    readonly type = ADMIN_GET_INSTALLER_FROM_PARTIAL_RESPONSE;

    constructor(public payload: Partial<UserProfile>) {
    }
}

export class AdminRegisterMagsenseDeviceRequestAction implements Action {
    readonly type = ADMIN_REGISTER_MAGSENSE_DEVICE_REQUEST;

    constructor(public payload: DFSPRequest<AdminPro3RegisterWarrantyRequest>) {
    }
}

export class AdminRegisterMagsenseDeviceResponseAction implements Action {
    readonly type = ADMIN_REGISTER_MAGSENSE_DEVICE_RESPONSE;

    // constructor(public payload: DFSPRequest<AdminPro3RegisterWarrantyRequest>) {
    // }
}

export class AdminRegisterMagsenseDeviceFailureAction implements Action {
    readonly type = ADMIN_REGISTER_MAGSENSE_DEVICE_FAILURE;

    constructor(public error: any) {
    }
}

// Export Actions type as a union of all defined actions
export type Actions =
    | AdminDeviceAlertRequestAction
    | AdminDeviceAlertExportRequestAction
    | AdminDeviceAlertExportClearDataRequestAction

    | AdminDeviceAlertResponseAction
    | AdminDeviceAlertExportResponseAction

    | AdminDeviceRequestAction
    | AdminDeviceResponseAction

    | AdminDeviceExportRequestAction
    | AdminDeviceExportResponseAction
    | AdminDeviceExportClearDataRequestAction

    | AdminDeviceDetailRequestAction
    | AdminDeviceDetailResponseAction

    | AdminDeviceNoteRequestAction
    | AdminDeviceNoteResponseAction
    | AdminDeviceSaveNoteRequestAction

    | AdminDeviceStatusRequestAction
    | AdminDeviceStatusResponseAction

    | AdminDismissAlertRequestAction
    | AdminDismissAlertResponseAction

    | AdminGetInstallerFromPartialRequest
    | AdminGetInstallerFromPartialResponse

    | AdminRegisterMagsenseDeviceFailureAction
    | AdminRegisterMagsenseDeviceRequestAction
    | AdminRegisterMagsenseDeviceResponseAction

    | AdminGetMagsenseWarrantyInfoAction
    | AdminGetMagsenseWarrantyInfoActionSuccess
    | AdminGetMagsenseWarrantyInfoActionFailed
    ;
