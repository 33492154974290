<div class="container-fluid">

  <div class="row content-page" *ngIf="adminJobs$ | async; let adminJobs">

    <div class="container-title">
      <h1 class="white">Jobs &amp; Warranties</h1>
    </div>

    <mat-accordion>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>Search for jobs and warranties</mat-expansion-panel-header>

        <form [formGroup]="fgSearchType">
          <mat-form-field>
            <mat-select formControlName="type" placeholder="Search type">
              <mat-option value="CUSTOMER">Search by customer name</mat-option>
              <mat-option value="USER">Search by specific installer</mat-option>
              <mat-option value="WARRANTY">Search all warranties</mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <hr />

        <div *ngIf="fgSearchType.value.type === 'USER'">
          <form [formGroup]="fgUserSelect" id="fgUserSelect" novalidate (ngSubmit)="fetchUserItems(true, true, 1, panelJobs)">

            <!-- User Search -->
            <mat-form-field>
              <input matInput formControlName="userId" placeholder="User name" [matAutocomplete]="autoUser" />
            </mat-form-field>

            <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserSuggestion">
              <mat-option *ngFor="let u of adminJobs.users" [value]="u">
                {{u.title}} {{u.firstName}} {{u.lastName}} ({{u.id}})
              </mat-option>
            </mat-autocomplete>

            &nbsp; or &nbsp;

            <!-- Company Name Search -->
            <mat-form-field>
              <input matInput formControlName="companyUserId" placeholder="Company Name" [matAutocomplete]="autoCompany" />
            </mat-form-field>

            <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayCompanySuggestion">
              <mat-option *ngFor="let u of adminJobs.users" [value]="u">
                {{ u.companyName }}, {{ u.title }} {{ u.firstName }} {{ u.lastName }} ({{u.id}})
              </mat-option>
            </mat-autocomplete>

            &nbsp; or &nbsp;

            <!-- Postcode Search -->
            <mat-form-field>
              <input matInput formControlName="postCodeUserId" placeholder="Postcode" [matAutocomplete]="autoPostcode" />
            </mat-form-field>

            <mat-autocomplete #autoPostcode="matAutocomplete" [displayWith]="displayPostcodeSuggestion">
              <mat-option *ngFor="let u of adminJobs.users" [value]="u">
                {{ u.postCode }}, {{ u.title }} {{ u.firstName }} {{ u.lastName }} ({{u.id}})
              </mat-option>
            </mat-autocomplete>

            <br />
            <button mat-raised-button color="primary" form="fgUserSelect" type="submit">
              <span *ngIf="!adminJobs.pendingUsers">Show jobs and warranties for installer</span>
              <app-waiting-spinner
                kind="spinner"
                colorType="accent"
                [spinnerSize]="25"
                [spinnerStroke]="2"
                *ngIf="adminJobs.pendingUsers"
              ></app-waiting-spinner>
            </button>
          </form>
        </div>

        <div *ngIf="fgSearchType.value.type === 'CUSTOMER'">

          <form [formGroup]="fgCustomerSelect" id="fgCustomerSelect" novalidate (ngSubmit)="fetchCustomerItems(true, true, 1, panelWarranties)">
            <mat-form-field>
              <input matInput formControlName="customerName" placeholder="Customer name" [matAutocomplete]="autoCustomer"/>
            </mat-form-field>

            <mat-autocomplete #autoCustomer="matAutocomplete" [displayWith]="displayCustomerSuggestion">
              <mat-option *ngFor="let c of adminJobs.customers" [value]="c">
                {{ c.firstName }} {{ c.lastName }}
              </mat-option>
            </mat-autocomplete>

            <br />
            <button mat-raised-button color="primary" form="fgCustomerSelect" type="submit">
              <span *ngIf="!adminJobs.pendingCustomers">Show jobs and warranties for customer</span>
              <app-waiting-spinner
                      kind="spinner"
                      colorType="accent"
                      [spinnerSize]="25"
                      [spinnerStroke]="2"
                      *ngIf="adminJobs.pendingCustomers"
              ></app-waiting-spinner>
            </button>
          </form>
        </div>

        <div *ngIf="fgSearchType.value.type === 'WARRANTY'">
          <form [formGroup]="fgWarrantyFilter" id="fgWarrantyFilter" novalidate (ngSubmit)="fetchAllWarranties(1, panelWarranties)">
            <mat-form-field>
              <input matInput formControlName="nameFilter" placeholder="Name to filter by" />
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="postcodeFilter" placeholder="Postcode to filter by" />
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="addressFilter" placeholder="Address partial to filter by" />
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="serialFilter" placeholder="Serial Number to filter by" />
            </mat-form-field>
            <br />
            <button mat-raised-button color="primary" form="fgWarrantyFilter" type="submit">Show warranties</button>
          </form>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel #panelJobs>
        <mat-expansion-panel-header>Jobs</mat-expansion-panel-header>

        <button mat-button class="pull-right" (click)="addJob()">Add new job</button>

        <app-message-panel kind="ERROR" *ngIf="adminJobs.jobs.error">{{adminJobs.jobs.error}}</app-message-panel>
        <app-message-panel
          kind="INFO"
          *ngIf="!adminJobs.jobs.error && !adminJobs.jobs.pending && adminJobs.jobs.items.length === 0"
        >
          No jobs to display; please use search above.
        </app-message-panel>
        <app-waiting-spinner kind="spinner" *ngIf="adminJobs.jobs.pending">Loading jobs...</app-waiting-spinner>

        <div class="item-list" *ngIf="adminJobs.jobs.items.length > 0">

          <div class="row pagination-wrapper">
            <div class="col-xs-12 text-center">
              <ul class="pagination">
                <!-- / Prev btns -->
                <li *ngIf="pageNumJobs > 1" class="page-item">
                  <a class="page-link" (click)="goToPageJobs(1)">&lt;&lt; First</a>
                </li>

                <li *ngIf="pageNumJobs > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevJobs(5)">&lt;&lt;</a>
                </li>

                <li *ngIf="pageNumJobs > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevJobs()">&lt;</a>
                </li>
                <!-- / Prev btns -->

                <!-- Pagination -->
                <li *ngFor="let num of pagination" [class.active]="num === pageNumJobs" class="page-item">
                  <a class="page-link" (click)="goToPageJobs(num)">{{ num }}</a>
                </li>
                <!-- / Pagination -->

                <!-- / Next btns -->
                <li *ngIf="pageNumJobs < totalPagesJobs" class="page-item">
                  <a class="page-link" (click)="pageNextJobs()">&gt;</a>
                </li>

                <li *ngIf="pageNumJobs < totalPagesJobs" class="page-item">
                  <a class="page-link" (click)="pageNextJobs(5)">&gt;&gt;</a>
                </li>

                <li *ngIf="pageNumJobs < totalPagesJobs" class="page-item">
                  <a class="page-link" (click)="goToPageJobs(totalPagesJobs)">Last &gt;&gt;</a>
                </li>
                <!-- / Next btns -->
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let job of adminJobs.jobs.items">
              <mat-card>
                <mat-card-header>
                  <app-waiting-spinner kind="spinner" *ngIf="!job.committed"></app-waiting-spinner>
                  <mat-card-title>
                    ID: {{job.id}} (<span *ngIf="job.customer">{{job.customer.customerTitle}}</span> {{job.customer.customerSurname}})
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button (click)="editJob(job)">Edit</button>
                  <button mat-button (click)="deleteJob(job)">Delete</button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>

        </div>
      </mat-expansion-panel>

      <mat-expansion-panel #panelWarranties>
        <mat-expansion-panel-header>Warranties</mat-expansion-panel-header>

        <button mat-button class="pull-right" (click)="addWarranty()">Add new warranty</button>

        <app-message-panel kind="ERROR" *ngIf="adminJobs.warranties.error">{{adminJobs.warranties.error}}</app-message-panel>
        <app-message-panel
          kind="INFO"
          *ngIf="!adminJobs.warranties.error && !adminJobs.warranties.pending && adminJobs.warranties.items.length === 0"
        >
          No warranties to display; please use search above.
        </app-message-panel>
        <app-waiting-spinner kind="spinner" *ngIf="adminJobs.warranties.pending">Loading warranties...</app-waiting-spinner>

        <div class="item-list" *ngIf="adminJobs.warranties.items.length > 0">
          <div class="row pagination-wrapper">
            <div class="col-xs-9 text-center">
              <ul class="pagination">
                <!-- / Prev btns -->
                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(1)">&lt;&lt; First</a>
                </li>

                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevWarranties(5)">&lt;&lt;</a>
                </li>

                <li *ngIf="pageNumWarranties > 1" class="page-item">
                  <a class="page-link" (click)="pagePrevWarranties()">&lt;</a>
                </li>
                <!-- / Prev btns -->

                <!-- Pagination -->
                <li *ngFor="let num of pagination" [class.active]="num === pageNumWarranties" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(num)">{{ num }}</a>
                </li>
                <!-- / Pagination -->

                <!-- / Next btns -->
                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="pageNextWarranties()">&gt;</a>
                </li>

                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="pageNextWarranties(5)">&gt;&gt;</a>
                </li>

                <li *ngIf="pageNumWarranties < totalPagesWarranties" class="page-item">
                  <a class="page-link" (click)="goToPageWarranties(totalPagesWarranties)">Last &gt;&gt;</a>
                </li>
                <!-- / Next btns -->
              </ul>
            </div>

            <div class="col-xs-3">
              <form [formGroup]="fgFilters" novalidate>
                <mat-form-field>
                  <mat-select formControlName="orderBy">
                    <mat-option [value]="false">Sort by: Newest</mat-option>
                    <mat-option [value]="true">Sort by: Oldest</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let w of adminJobs.warranties.items">
              <mat-card [class.expired]="!calculateExpiry(w).valid">
                <mat-card-header>
                  <app-waiting-spinner kind="spinner" *ngIf="!w.committed"></app-waiting-spinner>
                  <mat-card-title>
                    ID: {{w.id}} (<span *ngIf="w.customer">{{w.customer.customerTitle}}</span> <span *ngIf="w.customer">{{w.customer.customerSurname}}</span>)
                  </mat-card-title>
                  <mat-card-subtitle *ngIf="w.dateOfInstallation.toLowerCase() !== 'invalid date'">
                    Date of installation: {{ w.dateOfInstallation | date }}
                  </mat-card-subtitle>
                  <mat-card-subtitle *ngIf="w.expiryDate">
                    Date of expiration: <span>{{ w.expiryDate | date }}</span>
                  </mat-card-subtitle>
                  <mat-card-subtitle>
                    Product Type: <span>{{ w.productType }}</span>
                  </mat-card-subtitle>
                  <mat-card-subtitle>
                    Points Earned: <span>{{ w.points }}</span>
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button (click)="editWarranty(w)">Edit</button>
                  <button mat-button (click)="deleteWarranty(w)">Delete</button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>

        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>Promotional codes</mat-expansion-panel-header>

        <button mat-button class="pull-right" (click)="addPromoCode()">Add new promotional code</button>

        <form [formGroup]="fgPromoCode" id="fgPromoCode" novalidate (ngSubmit)="updatePromoCode()">
          <app-message-panel kind="ERROR" *ngIf="promoCodeErrors">{{promoCodeErrors}}</app-message-panel>
          <div class="col-xs-12 col-sm-6">
            <mat-form-field>
              <input matInput formControlName="code" placeholder="Code" />
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="name" placeholder="Friendly name" />
            </mat-form-field>
            <mat-checkbox formControlName="enabled">Enabled?</mat-checkbox>
          </div>
          <div class="col-xs-12 col-sm-6">
            <mat-form-field>
              <input matInput formControlName="usesPerUser" placeholder="Allowed uses per user" />
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="calcType" placeholder="Calculation type">
                <mat-option value="ADD">Add number of points</mat-option>
                <mat-option value="MUL">Multiply points by</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="calcValue" placeholder="Calculation value" />
            </mat-form-field>
          </div>
          <hr />
          <button mat-button form="fgPromoCode" type="submit">Add/update</button>
        </form>

        <hr />

        <app-message-panel kind="ERROR" *ngIf="adminJobs.promoCodes.error">{{adminJobs.promoCodes.error}}</app-message-panel>
        <app-waiting-spinner kind="bar" *ngIf="adminJobs.promoCodes.pending">Loading promotional codes...</app-waiting-spinner>

        <div class="promo-code-list row">
          <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let p of adminJobs.promoCodes.items">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{p.code}}: {{p.name}} ({{p.enabled ? 'ENABLED' : 'DISABLED'}})</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-waiting-spinner kind="spinner" *ngIf="!p.committed"></app-waiting-spinner>
                <p matLine *ngIf="p.committed && p.calcType === 'ADD'">Adds {{p.calcValue}} to received points</p>
                <p matLine *ngIf="p.committed && p.calcType === 'MUL'">Multiplies received points by {{p.calcValue}}</p>
                <p matLine *ngIf="p.committed && p.usesPerUser !== 0">Limited to {{p.usesPerUser}} uses per individual user</p>
                <p matLine *ngIf="p.committed && p.usesPerUser === 0">Unlimited uses per user</p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="editPromoCode(p)">Edit</button>
                <button mat-button (click)="deletePromoCode(p)">Delete</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>

      </mat-expansion-panel>

    </mat-accordion>

  </div>

</div>
