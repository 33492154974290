<div class="container-fluid">
  <div class="row content-page">
    <div class="container-title">
      <h1 class="white">MagnaClean Sense Admin Panel</h1>
    </div>
    <div class="background-white col-xs-12">
      <h1 class="color-adey-red">LOOKING TO MONITOR YOUR <i>MagnaClean Professional3 Sense&reg;?</i></h1>
      <h2><strong>It's moved to the <i>ADEY Sense&reg;</i> portal!</strong></h2>
      <a href="https://sense.adey.com/" target="_blank">
        <img alt="ADEY Sense logo" src="assets/i/common/logos/adey-sense-logo.svg">
      </a>
      <p><strong>Visit the portal now and login using your <i>ADEY ProClub&reg;</i> details.</strong></p>
      <br>
      <button (click)="goToSense()" mat-raised-button color="primary"><strong>OPEN ADEY SENSE</strong></button>
    </div>
  </div>
</div>