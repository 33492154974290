<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Register a job / warranty</h1>
    </div>

    <app-page-content page="SUBMISSION_SELECTION" field="intro"></app-page-content>

    <div class="submission-container col-xs-12">
      <div class="row">
        <div class="submission-selection col-sm-6 col-xs-12 job-selection">
          <div class="selection-box background-white align-center hover-background-adey-red hover-color-white"
          (click)="selectSubmission('/job')"
          >
            <i class="icon-mc3"></i>
            <i class="icon-flush"></i>
            <i class="icon-mc1"></i>
            <i class="icon-water-test"></i>
            <i class="icon-service-complete"></i>
            <h2>Register a job</h2>
          </div>
        </div>
        <div class="submission-selection col-sm-6 col-xs-12">
          <div
            class="selection-box background-white align-center hover-background-adey-red hover-color-white"
            (click)="selectSubmission('/warranty')"
          >
            <i class="icon-warranty-submit"></i>
            <h2>Register a warranty</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
