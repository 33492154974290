/**
 * admin-events reducer: maintains state for the event management admin CMS.
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  UserSuggestion,
} from '../../models/user-suggestion';

import {
  DiaryEvent,
  EventType,
} from '../../models/diary';

import * as adminActions          from '../actions/admin-events';
import * as diaryActions          from '../actions/diary';
import * as userSuggestionActions from '../actions/user-suggestion';


// State interface definitions

/**
 * Manages a paginated list of ADEY (not user) DiaryEvent models
 */
interface AdeyEventsState {
  pending:    boolean;
  error:      string;
  events:     DiaryEvent[];
  pageNum:    number;
  totalPages: number;
}

/**
 * Manages a list of EventType models
 */
interface EventTypesState {
  pending: boolean;
  error:   string;
  types:   EventType[];
}

/**
 * Manages a paginated list of user (not ADEY) DiaryEvent models
 */
interface UserEventsState {
  pending:    boolean;
  error:      string;
  events:     DiaryEvent[];
  pageNum:    number;
  totalPages: number;
}

export interface State {
  adeyEvents: AdeyEventsState;
  eventTypes: EventTypesState;
  userEvents: UserEventsState;

  // List of suggestions when searching for a User
  users: UserSuggestion[];
}


const defaultState: State = {
  adeyEvents: {
    pending:    false,
    error:      null,
    events:     [],
    pageNum:    1,
    totalPages: 0,
  },
  eventTypes: {
    pending: false,
    error:   null,
    types:   [],
  },
  userEvents: {
    pending:    false,
    error:      null,
    events:     [],
    pageNum:    1,
    totalPages: 0,
  },
  users: [],
};


export function reducer(
  state:  State = defaultState,
  action: adminActions.Actions | diaryActions.Actions | userSuggestionActions.Actions,
): State {
  switch (action.type)
  {
    /**
     * Request to add a new DiaryEvent
     */
    case adminActions.ADD_ADMIN_EVENT_REQUEST:
      return Object.assign({}, state, {
        adeyEvents: !action.payload.event.userEvent
          ? Object.assign({}, state.adeyEvents, {
            pending: true,
            error:   null,
          })
          : state.adeyEvents,
        userEvents: action.payload.event.userEvent
          ? Object.assign({}, state.userEvents, {
            pending: true,
            error:   null,
          })
          : state.userEvents,
      });

    /**
     * Response from adding a new DiaryEvent
     */
    case adminActions.ADD_ADMIN_EVENT_RESPONSE:
      return Object.assign({}, state, {
        adeyEvents: !action.payload.event.userEvent
          ? Object.assign({}, state.adeyEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.adeyEvents.events
              : [...state.adeyEvents.events, Object.assign({}, action.payload.event, {committed: true})],
          })
          : state.adeyEvents,
        userEvents: action.payload.event.userEvent
          ? Object.assign({}, state.userEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.userEvents.events
              : [...state.userEvents.events, Object.assign({}, action.payload.event, {committed: true})],
          })
          : state.userEvents,
      });

    /**
     * Request to add a new EventType
     */
    case adminActions.ADD_ADMIN_EVENT_TYPE_REQUEST:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {pending: true, error: null}),
      });

    /**
     * Response from adding a new EventType
     */
    case adminActions.ADD_ADMIN_EVENT_TYPE_RESPONSE:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {
          pending: false,
          error:   action.payload.error,
          types:   action.payload.error
            ? state.eventTypes.types
            : [...state.eventTypes.types, action.payload.type],
        }),
      });

    /**
     * Request to delete a DiaryEvent
     *
     * Handled with UPDATE_ADMIN_EVENT_REQUEST (next)
     */
    case adminActions.DELETE_ADMIN_EVENT_REQUEST:

    /**
     * Request to update an existing DiaryEvent
     */
    case adminActions.UPDATE_ADMIN_EVENT_REQUEST:
      return Object.assign({}, state, {
        adeyEvents: !action.payload.event.userEvent
          ? Object.assign({}, state.adeyEvents, {
            pending: true,
            error:   null,
            events: state.adeyEvents.events.map((e: DiaryEvent): DiaryEvent =>
              e.id === action.payload.event.id
                ? Object.assign({}, e, {committed: false})
                : e
            ),
          })
          : state.adeyEvents,
        userEvents: action.payload.event.userEvent
          ? Object.assign({}, state.userEvents, {
            pending: true,
            error:   null,
            events: state.userEvents.events.map((e: DiaryEvent): DiaryEvent =>
              e.id === action.payload.event.id
                ? Object.assign({}, e, {committed: false})
                : e
            ),
          })
          : state.userEvents,
      });

    /**
     * Response from deleting a DiaryEvent
     */
    case adminActions.DELETE_ADMIN_EVENT_RESPONSE:
      return Object.assign({}, state, {
        adeyEvents: !action.payload.event.userEvent
          ? Object.assign({}, state.adeyEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.adeyEvents.events
              : state.adeyEvents.events.filter((e: DiaryEvent): boolean => e.id !== action.payload.event.id),
          })
          : state.adeyEvents,
        userEvents: action.payload.event.userEvent
          ? Object.assign({}, state.userEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.userEvents.events
              : state.userEvents.events.filter((e: DiaryEvent): boolean => e.id !== action.payload.event.id),
          })
          : state.userEvents,
      });

    /**
     * Request to delete an EventType
     */
    case adminActions.DELETE_ADMIN_EVENT_TYPE_REQUEST:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {pending: true, error: null}),
      });

    /**
     * Response from deleting an EventType
     */
    case adminActions.DELETE_ADMIN_EVENT_TYPE_RESPONSE:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {
          pending: false,
          error:   action.payload.error,
          types:   action.payload.error
            ? state.eventTypes.types
            : state.eventTypes.types.filter((et: EventType): boolean => et.id !== action.payload.type.id),
        }),
      });

    /**
     * Request to fetch a list of DiaryEvents. Flags in the request indicate
     * which type (ADEY or User) of events are to be fetched.
     */
    case adminActions.FETCH_ADMIN_EVENTS_REQUEST:
      return Object.assign({}, state, {
        adeyEvents: action.payload.adeyEvents
          ? Object.assign({}, state.adeyEvents, {
            pending: true,
            error:   null,
            pageNum: action.payload.pageNum,
          })
          : state.adeyEvents,
        userEvents: action.payload.userEvents
          ? Object.assign({}, state.userEvents, {
            pending: true,
            error:   null,
            pageNum: action.payload.pageNum,
          })
          : state.userEvents,
      });

    /**
     * Response from fetching a list of events (depending on request flags).
     */
    case adminActions.FETCH_ADMIN_EVENTS_RESPONSE:
      return Object.assign({}, state, {
        adeyEvents: action.payload.adeyEvents || action.payload.adeyError
          ? Object.assign({}, state.adeyEvents, {
            pending:    false,
            error:      action.payload.adeyError,
            events:     action.payload.adeyEvents,
            totalPages: action.payload.totalPagesAdey,
          })
          : state.adeyEvents,
        userEvents: action.payload.userEvents || action.payload.userError
          ? Object.assign({}, state.userEvents, {
            pending:    false,
            error:      action.payload.userError,
            events:     action.payload.userEvents,
            totalPages: action.payload.totalPagesUser,
          })
          : state.userEvents,
      });

    /**
     * Request to fetch a list of EventTypes
     */
    case diaryActions.FETCH_EVENT_TYPES_REQUEST:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {pending: true, error: null}),
      });

    /**
     * Response from fetching a list of EventTypes
     */
    case diaryActions.FETCH_EVENT_TYPES_RESPONSE:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {
          pending: false,
          error:   action.payload.error,
          types:   action.payload.error ? state.eventTypes.types : action.payload.types,
        }),
      });

    /**
     * Request to fetch a list of UserSuggestions based on a name query
     */
    case userSuggestionActions.FETCH_USER_SUGGESTION_REQUEST:
      return Object.assign({}, state, {users: []});

    /**
     * Response from fetching a list of UserSuggestions
     */
    case userSuggestionActions.FETCH_USER_SUGGESTION_RESPONSE:
      return Object.assign({}, state, {users: action.payload.users});

    // For UPDATE_ADMIN_EVENT_REQUEST, see DELETE_ADMIN_EVENT_REQUEST

    /**
     * Response from updating an existing DiaryEvent
     */
    case adminActions.UPDATE_ADMIN_EVENT_RESPONSE:
      return Object.assign({}, state, {
        adeyEvents: !action.payload.event.userEvent
          ? Object.assign({}, state.adeyEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.adeyEvents.events
              : state.adeyEvents.events.map((e: DiaryEvent): DiaryEvent =>
                e.id === action.payload.event.id
                  ? Object.assign({}, e, action.payload.event, {committed: true})
                  : e
                ),
          })
          : state.adeyEvents,
        userEvents: action.payload.event.userEvent
          ? Object.assign({}, state.userEvents, {
            pending: false,
            error:   action.payload.error,
            events:  action.payload.error
              ? state.userEvents.events
              : state.userEvents.events.map((e: DiaryEvent): DiaryEvent =>
                e.id === action.payload.event.id
                  ? Object.assign({}, e, action.payload.event, {committed: true})
                  : e
                ),
          })
          : state.userEvents,
      });

    /**
     * Request to update an existing EventType
     */
    case adminActions.UPDATE_ADMIN_EVENT_TYPE_REQUEST:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {pending: true, error: null}),
      });

    /**
     * Response from updating an existing EventType
     */
    case adminActions.UPDATE_ADMIN_EVENT_TYPE_RESPONSE:
      return Object.assign({}, state, {
        eventTypes: Object.assign({}, state.eventTypes, {
          pending: false,
          error:   action.payload.error,
          types:   action.payload.error
            ? state.eventTypes.types
            : state.eventTypes.types.map((et: EventType): EventType => et.id === action.payload.type.id ? action.payload.type : et),
        }),
      });

    default:
      return state;
  }
}
