import { Country } from '../../models/country';
import * as actions from '../actions/country';

export interface State {
  type: string;
  countryPending: boolean;
  error: string;
  countries: Country[];
}

const defaultState: State = {
  type: null,
  countryPending: null,
  error: null,
  countries: null
};


export function reducer(
  state: State = defaultState,
  action: actions.Actions
): State {
  switch (action.type) {
    case actions.COUNTRY_REQUEST:
      return Object.assign({}, state, {
        type: 'COUNTRY_REQUEST',
        countryPending: true,
        error: null,
        countries: null
      });

    case actions.COUNTRY_RESPONSE:
      return Object.assign({}, state, {
        type: 'COUNTRY_RESPONSE',
        countryPending: false,
        error: action.payload.error,
        countries: action.payload.countries
      });

    default:
      return state;
  }
}
