<div class="widget-body border-box border-lighter-gray rewards-widget background-white col-xs-12">
  <ng-content select=".rewards-loading"></ng-content>

  <div class="rewards">
    <mat-progress-bar color="primary" mode="determinate" value="34" *ngIf="!profilePending"></mat-progress-bar>

    <div class="points-calculation background-adey-red white-theme" *ngIf="!profilePending">
      <mat-progress-spinner
        class="transform-xy-offset-50 half-opacity"
        color="primary"
        mode="determinate"
        strokeWidth="3"
        value="100">
      </mat-progress-spinner>
      <div class="progress-info color-white align-center">
        <p class="percentage">{{ userProfile?.pointsCurrent }}</p>
        <p>Points</p>
      </div>
    </div>
    <div class="content" *ngIf="!profilePending">
      <h2 class="color-adey-red">Register your <i>MagnaClean Professional2</i>&reg; and <i>Micro2</i>&reg; warranties to earn points!</h2>
      <h2 class="color-light-gray sub">The more you register, the more you can earn.</h2>
    </div>
    <div [class]="'widget-button-container' + (profilePending ? ' full-width-display' : '')">
      <button class="btn background-green no-radius color-white" (click)="goToPage('/rewards')">Get rewards</button>
    </div>
  </div>
</div>
