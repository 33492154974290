/**
 * RegisterService: provides all functionality related to user registration
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';



import { Injectable } from '@angular/core';


import { ApiService } from './api.service';

import {
  FetchUserRolesResponse,
  RegistrationRequest,
  SimpleRegistrationRequest,
  RegistrationResponse,
  UserRole,
} from '../models/registration';

import { UserProfile, SimpleUserProfile } from '../models/user-profile';
import {RegistrationWaterTesterRequest, RegistrationWaterTesterResponse} from '../models/registration-watert-tester';


@Injectable()
export class RegisterService {

  constructor(
    private apiService: ApiService,
  ) {}


  /**
   * Fetches UserRole models
   *
   * @return {Observable<FetchUserRolesResponse>}
   */
  fetchUserRoles(): Observable<FetchUserRolesResponse> {
    return this.apiService.apiGet('/roles').pipe(
      map((res: any): FetchUserRolesResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          roles: valid ? res.resource.map(UserRole.fromAPI) : null,
        };
      }),
      catchError((err: any): Observable<FetchUserRolesResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to get available user roles: ${err.error.message}`
            : 'Unable to get available user roles',
          roles: null,
        })
      ),);
  }

  /**
   * Submits a new user registration
   *
   * @param {RegistrationRequest} req
   * @return {Observable<RegistrationResponse>}
   */
  registerNewUser(req: RegistrationRequest): Observable<RegistrationResponse> {

    return this.apiService.apiPost('/register', UserProfile.toApiRegistrationData(req)).pipe(
      map((res: any): RegistrationResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          userId:  valid ? res.resource[0].id : null,
          activated:  valid ? res.resource[0].activated : false,
          error:   !valid,
          message: res.message,
        };
      }),
      catchError((err: any): Observable<RegistrationResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          userId:  null,
          activated:  false,
          message: (errorMessage ? errorMessage : 'Issue communicating with the server'),
          error:   true,
        });
      }),);
  }

  /**
   * Submits a new simple user registration
   *
   * @param {RegistrationRequest} req
   * @return {Observable<RegistrationResponse>}
   */
  registerNewSimpleUser(req: SimpleRegistrationRequest): Observable<RegistrationResponse> {
    return this.apiService.apiPost('/register-simple', SimpleUserProfile.toApiRegistrationData(req)).pipe(
      map((res: any): RegistrationResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource) && res.resource.length > 0 && res.resource[0].id;
        return {
          userId:  valid ? res.resource[0].id : null,
          activated: valid ? res.resource[0].activated : false,
          error:   !valid,
          message: res.message,
        };
      }),
      catchError((err: any): Observable<RegistrationResponse> => {
        const errorMessage = err && err.error ? err.error.message : null;
        return observableOf({
          userId:  null,
          activated: false,
          message: (errorMessage ? errorMessage : 'Issue communicating with the server'),
          error:   true,
        });
      }),);
  }

  /**
   * Submits a new water tester user registration
   *
   * @param {RegistrationRequest} req
   * @return {Observable<RegistrationResponse>}
   */
  registerNewWaterTesterUser(req: RegistrationWaterTesterRequest): Observable<RegistrationWaterTesterResponse> {
    return this.apiService.apiPost('/watertest/user?ident=procheck-portal', req).pipe(
      map((res: any): RegistrationWaterTesterResponse => {
        return {
          error:   false,
          message: res.message,
        };
      }),
      catchError((err: any): Observable<RegistrationWaterTesterResponse> => {
        const errorMessage = err && err.error ? err.error.summary : null;
        return observableOf({
          message: (errorMessage ? errorMessage : 'Issue communicating with the server'),
          error:   true,
        });
      }),);
  }

}
