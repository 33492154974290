import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Router } from '@angular/router';

/**
 * Summary
 *    Displays a list of buttons to link the user to resources.
 *
 *
 * Description
 *    This dynamic list of buttons consists of different page links,
 *    each button has a corresponding icon and name which is defined,
 *    within this class.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-content-archive',
  templateUrl: './content-archive.component.html',
  styleUrls: ['./content-archive.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentArchiveComponent implements OnInit {

  // Array of resource items to display
  public resourceArray: any[];


  /**
   * Constructor for page, will initialise the page structure
   *
   * @param {Router} router Initialises router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) {
    // The resource array is used to define the structure of the page, each item is
    // categorised into groups, each geoup has a category title. This is displayed above the
    // group items. The resource variable defines the type of resources listed on the page,
    // at the moment, they are either set as page, VIDEO or DOWNLOAD. Video and Download resources
    // link to the content-list component with the category being set to the resource type, whereas
    // a page resource will link to a separate page all together as defined by the link value.
    this.resourceArray = [
      {
        categoryTitle: 'Customer',
        items: [
          {
            label: 'Savings calculator',
            icon: 'fa fa-calculator',
            link: '/roi-calculator',
            resource: 'PAGE',
            additionalText: '',
          },
          {
            label: 'Homeowner resources',
            icon: 'fa fa-home',
            link: 'Homeowner videos',
            resource: 'VIDEO',
            additionalText: '',
          },
          {
            label: 'Infographics',
            icon: 'fa fa-info-circle',
            link: 'Infographics',
            resource: 'DOWNLOAD',
            additionalText: '',
          }
        ]
      },
      {
        categoryTitle: 'Product',
        items: [
          {
            label: 'Installer resources',
            icon: 'fa fa-book',
            link: 'Installation guides',
            resource: 'DOWNLOAD',
            additionalText: '',
          },
          {
            label: 'Product brochures',
            icon: 'fa fa-file-text',
            link: 'Product brochures',
            resource: 'DOWNLOAD',
            additionalText: '',
          },
          {
            label: 'Product selector',
            icon: 'icon-parts',
            link: '/product-selector',
            resource: 'PAGE',
            additionalText: '',
          }
        ]
      },
      {
        categoryTitle: 'Business',
        items: [
          {
            label: 'Diary',
            icon: 'icon-diary',
            link: '/diary',
            resource: 'PAGE',
            additionalText: '',
          },
          {
            label: 'Other resources',
            icon: 'fa fa-info-circle',
            link: 'Other resources',
            resource: 'DOWNLOAD',
            additionalText: '',
          }
        ]
      },
      {
        categoryTitle: 'Videos',
        items: [
          {
            label: 'Videos',
            icon: 'fa fa-video-camera',
            link: 'Videos',
            resource: 'VIDEO',
            additionalText: '',
          },
        ]
      }
    ];
  }

  ngOnInit() {
  }

  /**
   * Called when the user clicks a group item, will navigate the user to the
   * correct page based on the selected item's data
   *
   * @param {string} pageName refers to the item's link variable
   * @param {string} resource refers to the item's resource variable
   */
  goToList(pageName: string, resource: string) {
    if (pageName !== null) {
      if (resource !== 'PAGE')
        this.router.navigate(['/content-list', pageName, resource]);
      else
        this.router.navigate([pageName]);
    }
  }
}
