import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { select, Store } from '@ngrx/store';
import { StoreState } from '../../../state-management/store';
import { Router } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  selectHasOutstandingOnboardingQuestions,
  selectOnboardingQuestions
} from '../../../state-management/selectors/onboarding-questions';
import { OnboardingQuestion } from '../../../models/onboarding-questions';
import { PutOnboardingAnswers } from '../../../state-management/actions/onboarding-questions';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  public loginDetails$:   Observable<LoginDetailsState>;
  public questionsState$: Observable<OnboardingQuestion[]>;

  @Input() userProfile: any;

  public submitted: boolean = false;
  public errors: any;
  public fg: UntypedFormGroup = null;

  constructor(
    private store:  Store<StoreState>,
    private router: Router,
    private _fb:    UntypedFormBuilder
  ) {
    this.loginDetails$   = this.store.select('loginDetails');
    this.questionsState$ = this.store.pipe(select(selectOnboardingQuestions));
  }

  ngOnInit() {
    const hasOutstandingQuestions$ = this.store.pipe(select(selectHasOutstandingOnboardingQuestions));
    let hasOutstandingQuestions: boolean = true;

    hasOutstandingQuestions$.subscribe(has => {
      hasOutstandingQuestions = has;
    });

    if (!hasOutstandingQuestions) {
      this.router.navigate(['/home']);
    }

    this.buildForm();
  }

  buildForm() {
    this.fg = this._fb.group({});

    // For each of the questions add as a form group field.
    this.questionsState$.subscribe(questions => {
      questions.map(question => {
        this.fg.addControl(question.id, new FormControl('', Validators.required));
      });
    });
  }

  onSubmitForm() {
    // Get and format errors
    Object.keys(this.fg.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.fg.get(key).errors;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.errors = { field: key, type: keyError, value: controlErrors[keyError] };
        });
      }
    });

    if (this.fg.valid && !this.errors) {
      this.store.dispatch(new PutOnboardingAnswers(this.fg.value));
    }
  }
}
