import { Action } from '@ngrx/store';
import { BusinessToolResponse, BusinessToolVerificationTokenRequest, BusinessToolVerificationTokenResponse } from '../../models/business-tools';

export const FETCH_BUSINESS_TOOLS_REQUEST = 'FETCH_BUSINESS_TOOLS_REQUEST';
export const FETCH_BUSINESS_TOOLS_RESPONSE = 'FETCH_BUSINESS_TOOLS_RESPONSE';
export const FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST = 'FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST';
export const FETCH_BUSINESS_TOOLS_VERIFICATION_RESPONSE = 'FETCH_BUSINESS_TOOLS_VERIFICATION_RESPONSE';

export class FetchBusinessToolsRequestAction implements Action {
  readonly type = FETCH_BUSINESS_TOOLS_REQUEST;
  constructor() { }
}

export class FetchBusinessToolsResponseAction implements Action {
  readonly type = FETCH_BUSINESS_TOOLS_RESPONSE;
  constructor(public payload: BusinessToolResponse) { }
}

export class FetchBusinessToolsVerificationTokenRequestAction implements Action {
  readonly type = FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST;
  constructor(public payload: BusinessToolVerificationTokenRequest) { }
}

export class FetchBusinessToolsVerificationTokenResponseAction implements Action {
  readonly type = FETCH_BUSINESS_TOOLS_VERIFICATION_RESPONSE;
  constructor(public payload: BusinessToolVerificationTokenResponse) { }
}

export type Actions =
  | FetchBusinessToolsRequestAction
  | FetchBusinessToolsResponseAction
  | FetchBusinessToolsVerificationTokenRequestAction
  | FetchBusinessToolsVerificationTokenResponseAction;
