<div class="content-resources row" *ngIf="adminResources$ | async; let adminResources">

  <div class="col-xs-12">

    <app-message-panel kind="ERROR" *ngIf="adminResources.error">{{adminResources.error}}</app-message-panel>

    <div class="row">
      <div class="col-xs-12">
        <form [formGroup]="fgPageSelect">
          <button class="btn btn-default pull-right" (click)="addResource()">Add new resource</button>
          <mat-form-field>
            <mat-select formControlName="pageName" placeholder="Page name">
              <mat-option value="Business tools">Business tools</mat-option>
              <mat-option value="Dashboard">Dashboard</mat-option>
              <mat-option value="Homeowner videos">Homeowner videos</mat-option>
              <mat-option value="Infographics">Infographics</mat-option>
              <mat-option value="Installation guides">Installation guides</mat-option>
              <mat-option value="Other resources">Other resources</mat-option>
              <mat-option value="Product brochures">Product brochures</mat-option>
              <mat-option value="Resources">Resources</mat-option>
              <mat-option value="Sales tools">Sales tools</mat-option>
              <mat-option value="Videos">Videos</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <hr />
      </div>
    </div>

    <div class="row">

      <app-waiting-spinner kind="bar" *ngIf="adminResources.pending">Loading resources...</app-waiting-spinner>

      <app-message-panel kind="INFO" *ngIf="!adminResources.pending && adminResources.resources.length === 0">
        No resources to display
      </app-message-panel>

      <div class="resource-list" *ngIf="!adminResources.pending && adminResources.resources.length > 0">

        <div class="row pagination-wrapper">
          <div class="col-xs-12 text-center">
            <ul class="pagination">
              <!-- / Prev btns -->
              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="goToPage(1)">&lt;&lt; First</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev(5)">&lt;&lt;</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev()">&lt;</a>
              </li>
              <!-- / Prev btns -->

              <!-- Pagination -->
              <li *ngFor="let num of pagination" [class.active]="num === pageNum" class="page-item">
                <a class="page-link" (click)="goToPage(num)">{{ num }}</a>
              </li>
              <!-- / Pagination -->


              <!-- / Next btns -->
              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext()">&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext(5)">&gt;&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="goToPage(totalPages)">Last &gt;&gt;</a>
              </li>
              <!-- / Next btns -->
            </ul>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let r of adminResources.resources">
          <mat-card>
            <mat-card-header>
              <app-waiting-spinner kind="spinner" *ngIf="!r.confirmed"></app-waiting-spinner>
              <img mat-card-avatar [src]="r.imageUrl" *ngIf="r.imageUrl && r.confirmed" [alt]="r.title + ' thumbnail'" />
              <mat-card-title>
                {{r.title}}
              </mat-card-title>
              <mat-card-subtitle>
                Type: {{r.attachment.itemType ? r.attachment.itemType.toLowerCase() : 'unknown'}}
              </mat-card-subtitle>
              <mat-card-subtitle>
                View priority: {{r.order}}
              </mat-card-subtitle>

            </mat-card-header>
            <mat-card-content>
              <!-- Not used for Business tools cards -->
              <ng-container *ngIf="r.attachment?.itemType !== 'CARD'">
                <p class="categories" *ngIf="r.categories">Categories: {{r.categories.join(', ')}}</p>
                <p class="keywords" *ngIf="r.keywords">Keywords: {{r.keywords.join(', ')}}</p>
              </ng-container>
              <p>{{r.description}}</p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="editResource(r)">Edit</button>
              <button mat-button (click)="deleteResource(r)">Delete</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

    </div>

  </div>

</div>
