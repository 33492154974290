<section>
  <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE SUBSCRIPTION SERVICES AND THIS SITE</strong></p>
  <p><strong>Terms of Use</strong></p>
  <p>These terms of use (together with the documents referred to in them) outline the terms under which Adey Innovation Limited (together with any of its group or affiliated companies) (referred to in these terms as <strong>we, us, our, Adey</strong>) permit you to subscribe to the subscription services which will enable you to store and access test results that you have submitted via the ADEY ProCheck mobile phone app (the <strong>"Subscription Services"</strong>), by logging on to the online portal which can be found at www.procheck.adey.com  (the <strong>"Site"</strong>).</p>
  <p>You will then be able to send copies of these test results to your customers, should you wish to do so.</p>
  <p>A full description of the Subscription Services can be found at www.adey.com/procheck.</p>
  <p>We reserve the right to add and/or change features and functionality provided as part of the Subscription Services without notice to you.</p>
  <p>Please read these terms of use carefully before you start to use our Subscription Services and the Site, as these will apply to your use of them. We recommend that you print a copy of these for future reference.</p>
  <p>By using the Subscription Services and the Site, you confirm that you accept these terms of use and that you agree to comply with them.</p>
  <p>If you do not agree to these terms of use, you may not make use of our Subscription Services or the Site.</p>

  <p><strong>Changes to These Terms</strong></p>

  <p>We may revise these terms of use at any time by amending this page.</p>
  <p>Please check this page from time to time to take notice of any changes we make, as they are binding on you.</p>

  <p><strong>Changes to the Site</strong></p>

  <p>We may update the Site from time to time and may change the content of it at any time. However, please note that any of the content on the Site may be out of date at any given time, and we are under no obligation to update it.</p>
  <p>We do not guarantee that the Site, or any content on it, will be free from errors or omissions.</p>

  <p><strong>Registering On the Site</strong></p>

  <p>Save for the aspects of the Site that require a valid subscription, known as the Subscription Services, the Site is provided free of charge.</p>
  <p>To use the Site, including the Subscription Services, you will need to register by providing:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Your full name;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Your full address;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Your email address; and</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Your contact telephone number.</p>

  <p>Upon submitting the above information, you will be requested to create a password, unique to you, which you will use to access your personal section of the Site. This password must be kept confidential to you only and must not be shared with anyone else.  If we have reason to believe that you have provided your login details to someone else, or your account has been compromised, we reserve the right to suspend your access to the Site.  We will not be liable for any losses that may arise as a result of this suspension.</p>

  <p><strong>Access to Subscription Services</strong></p>

  <p>The level of Subscription Services that you will have access to depends upon your Subscription Level. There are 3 Subscription Levels available, as follows:</p>
  <p><strong>Installer.</strong>  The Installer Subscription Level is for installers, whether working on their own account, or on behalf of an Administrator.  The Installer Subscription Level will give the user access to live water test results as well as access to historic test results.</p>
  <p>To subscribe as an Installer you will need to:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Have purchased an ADEY ProCheck Kit and entered into the Site the unique activation code linked to that ADEY ProCheck Kit.  This will provide you with access to the Subscription Services for the length of time outlined on the ADEY Procheck Kit you have purchased.  For the avoidance of doubt, only one activation code may be used per account; or</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Have purchased a subscription to the Subscription Services via the Site at the price outlined at the time of purchase.  Any such subscription shall be valid for the term for which you purchased; or</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Be employed by, or be an approved contractor to, a company that has a contract with ADEY for the provision of water testing services, including access to the Subscription Services.  In such circumstances your access to the Subscription Services will be controlled by the company you are working with and you should contact the relevant person within that company to arrange for access to the Subscription Services.</p>
  <p>Please note, where your subscription is provided for you by your employer, or a company you contract to (as per 3 above) then any test results you upload onto the Site will be the property of the company and not yours.  If your access to the Subscription Services is removed by your company, or by you at your request, you will not be able to access any test results that were uploaded whilst your subscription was provided by that company.  You will still be able to access any test results uploaded separately, subject to you having a valid subscription of your own, as set out in 1 and 2 above.</p>

  <p>ADEY will not be liable to you in any way as a result of you not being able to access any test results due to your subscription to the Subscription Services having expired, lapsed, being suspended or cancelled.</p>
  <p><strong>Administrator.</strong>  The Administrator Subscription Level is for any company that employs, or subcontracts to, installers.  The Administrator Subscription Level will enable the account holder to invite installers to be linked to the Administrator’s account, and will give the account holder access to all tests completed by any installer it has approved to use the Administrator account holder’s account.</p>
  <p>To subscribe as an Administrator the company must be party to a current and subsisting agreement with ADEY for the purchase of ADEY ProCheck Kits on a repeating basis (i.e. monthly direct debit or invoice).</p>
  <p>Adey will suspend the account holder’s account upon the suspension or termination of the agreement with ADEY for the purchase of ADEY ProCheck Kits and ADEY will not be liable to you in any way as a result of you not being able to access any test results due to your subscription to the Subscription Services having expired, lapsed, being suspended or cancelled.</p>
  <p><strong>Monitoring.</strong>  The Monitoring Subscription Level is for house builders, housing associations, councils and social housing providers.  The Monitoring Subscription Level will give the user access to all tests completed on properties owned, run, or managed by the account holders.</p>
  <p>To subscribe to a Monitoring Account ADEY will agree with you a tariff for access to the account based on the amount of tests results you wish to include within your subscription.  Once you have reached the allotted amount of test results you have paid for as per your tariff subscription ADEY will not allow you to access any further test results until a revised tariff has been agreed and paid for.</p>
  <p>ADEY will not be liable to you in any way as a result of you not being able to access any test results due to your subscription to the Subscription Services having expired, lapsed, being suspended or cancelled.</p>

  <p><strong>Access to the Site</strong></p>

  <p>We do not guarantee that the Site, or any content on it, including current or previous test results, will always be available or be uninterrupted.</p>
  <p>We may suspend, withdraw, discontinue or change all or any part of the Site without notice.</p>
  <p>We will not be liable to you if for any reason the Site is unavailable at any time or for any period.</p>
  <p>You are responsible for making all arrangements necessary for you to have access to the Site.</p>
  <p>You are also responsible for ensuring that all persons who access the Site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>

  <p><strong>Intellectual Property Rights</strong></p>

  <p>We are the owner or the licensee of all intellectual property rights in the Site, and in the material published on it.  Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
  <p>You acknowledge that the ADEY ProCheck App and the ProCheck functionality enables the relevant user to access instantaneous water test results, as per the terms and conditions of the ADEY ProCheck App.  The data relating to the test results belong to ADEY and obtaining a subscription to the Subscription Services means that ADEY is granting to you a worldwide, exclusive (but not to the exclusion of ADEY), revocable and terminable licence to any test results that fall within the scope of the Subscription Level for which you have subscribed. This licence shall automatically terminate upon the suspension or termination of your Subscription.</p>
  <p>In the event that your account or Subscription is suspended, or terminated, ADEY makes no guarantees that it will be able to restore all data that you may have had access to prior to your account or Subscription having been suspended or terminated.</p>
  <p><strong>No Reliance on Information</strong></p>
  <p>Although we make reasonable efforts to update the information on the Site, we make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up-to-date.</p>

  <p><strong>Data Protection</strong></p>

  <p>By supplying personal data to us you are warranting that you have the right to supply such information to us and that we have the right to process it on your behalf for the purposes of supplying the test results to you.</p>
  <p>We warrant that we will process all personal data relating to you or your customers, supplied to us via the Site and the Services, in accordance with all relevant data protection laws, regulations and codes of practice and that we will not pass the personal data you supply to any third party outside of Adey without yours, or the data subject’s, prior consent.</p>
  <p>Where we process any personal data on behalf of the data owner when performing our obligations, the data owner shall be the controller of that data and we shall be the processor.</p>
  <p>The party uploading the data to the Site will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to us for the duration and purposes of this agreement so that we may lawfully use, process and transfer the personal data in accordance with this agreement on the data owner’s behalf.</p>
  <p>We shall, in relation to any personal data processed in connection with the performance by us of our obligations under this agreement:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) process that personal data only on the data owner’s instructions unless we are required by the laws of any member of the European Union or by the laws of the European Union applicable to us and/or any relevant UK Law to process personal data.</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) not transfer any personal data outside of the European Economic Area unless the following conditions are fulfilled:</p>

  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) we have put in place and obtained appropriate safeguards in relation to the transfer;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii) the data subject has enforceable rights and effective legal remedies;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iii) we comply with our obligations under the relevant data protection legislation by providing an adequate level of protection to any personal data that is transferred; and</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iv) we comply with reasonable instructions notified to us in advance by the data owner with respect to the processing of the personal data;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) assist the data owner, at the data owner's cost, in responding to any request from a data subject and in ensuring compliance with the data owner’s obligations under the relevant data protection legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) notify the data owner without undue delay on becoming aware of a personal data breach;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e) at the written direction of the data owner, delete or return personal data and copies thereof to the data owner on termination of the agreement unless required by any relevant law to store the personal data (and for these purposes the term "delete" shall mean to put such data beyond use); and</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) maintain complete and accurate records and information to demonstrate our compliance with these provisions immediately inform the data owner if, in our opinion, an instruction infringes any relevant data protection legislation.</p>
  <p>All parties to these terms shall ensure that they have in place appropriate technical and organisational measures to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measure).</p>
  <p>We confirm that where we make use of any third-party processors, we have entered or (as the case may be) will enter with the third-party processor into a written agreement incorporating terms which are substantially similar to those set out above. As between ADEY and the data owner, we shall remain fully liable for all acts or omissions of any third-party processor appointed by us.</p>

  <p><strong>Limitation of Our Liability</strong></p>

  <p>Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.</p>
  <p>To the extent permitted by law, we exclude all conditions, warranties, representations, or other terms which may apply to the Subscription Services and/or the Site or any content on it, whether express or implied.</p>
  <p>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- the results, figures or recommendations given by us in the test results or derived from your use of the Subscription Services and the Site;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- any delay in payment you may receive from your customer(s) for works performed that arises due to the test results not meeting your customer’s minimum standards or requirements or, as a result of the figures or recommendations given as part of the test results; or</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- use of, or inability to use, the Site.</p>
  <p>Where you are a business user (for example, an installer), please note that in particular, we will not be liable for:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- loss of profits, sales, business, or revenue;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- the cost of obtaining further Adey ProCheck® Kits;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- your time in having to revisit sites and obtain further samples for testing should previous samples be rejected or test results indicate that further tests should be undertaken;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- business interruption;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- loss of anticipated savings;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- loss of business opportunity, goodwill or reputation; or</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- any indirect or consequential loss or damage.</p>
  <p>If you are a consumer user, please note that we only provide the Subscription Services and the Site for guidance only and we strongly recommend that should you have any concerns about the system you are testing, you seek professional advice as a matter of urgency.</p>
  <p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Site or to your downloading of any content on it, or on any website linked to it.</p>

  <p><strong>Viruses</strong></p>

  <p>We do not guarantee that the Site will be secure or free from bugs or viruses.</p>
  <p>You are responsible for configuring your information technology, computer programmes and platform in order to access the Site. You should use your own virus protection software.</p>

  <p><strong>Applicable Law</strong></p>

  <p>If you are a consumer, please note that these terms of use, their subject matter and its formation, are governed by English law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
  <p>If you are a business, these terms of use, their subject matter and formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</p>
</section>
