<div [class]="'container-fluid register-component ' + (mode === 'update'? 'has-blue-back' : 'has-red-back')">
  <div class="row">
    <a href="/">
      <img src="/assets/i/common/logos/adey-pro-club-logo.png" alt="ADEY® ProClub Logo" class="top-right-logo">
    </a>
    <a href="/" class="nav-home-btn">Back to Login</a>

    <div class="col-xs-12 register-container">
      <h1>{{pageTitle}}</h1>
      <br>

      <p *ngIf="mode === 'update'">
        Please check your details below and update them as necessary, then click &quot;Update profile&quot; to continue.
        Please note that you will need to accept our terms and conditions and data protection policy at this point
        before you can continue to use
        the site.
      </p>

      <ng-container *ngIf="mode === 'simple'">
        <br>
        <p><strong>ADEY ProClub&reg; has been designed specifically for installers to help manage your
            business!</strong></p>
        <p>Not only does the ProClub offer quicker and easier warranty registrations for your filters, you can also
          easily access your customer records, receive a 20% discount on ADEY Spares, and earn points for your
          registrations.</p>
          <img class="proclub-benefits-icons" src="assets/i/pages/register/icons.png" alt="Register warranties, earn rewards, record jobs and access tools" />
        <p style="white-space: pre-line">Become a member for FREE!
          All you need to do is fill in the below information, accept the T&C's and you're in!</p>
        <p>For more information about ProClub, click <a href="https://www.adey.com/proclub" target="_blank">here</a>.</p>
      </ng-container>


      <div *ngIf="registerState$ | async; let registerState">

        <app-waiting-spinner kind="bar" *ngIf="registerState.pending" class="white-theme">
          Registering, please wait...
        </app-waiting-spinner>
        <app-waiting-spinner kind="bar" *ngIf="(loginDetailsState$|async).pending" class="white-theme">
          Updating profile, please wait...
        </app-waiting-spinner>

        <app-message-panel kind="ERROR" *ngIf="(loginDetailsState$|async).error">
          {{(loginDetailsState$|async).error}}
        </app-message-panel>

        <app-message-panel kind="INFO" *ngIf="registerState.registerSuccess">
          <p *ngIf="registerState.currentResponse && registerState.currentResponse.activated">
            Thank you for registering your account.
          </p>

          <p *ngIf="registerState.currentResponse && !registerState.currentResponse.activated">
            Thank you for registering your account. A confirmation email has been sent to your registered email address.
            Please follow the instructions in this email in order to complete your registration.
          </p>

          <div *ngIf="registerState.currentResponse && registerState.currentResponse.message">
            <hr />
            <div [innerHtml]="registerState.currentResponse.message"></div>
            <hr />
          </div>
          <hr *ngIf="!registerState.currentResponse?.message" />
          <button class="btn btn-primary" (click)="onConfirmSuccess()">Return to home page</button>
        </app-message-panel>

        <app-profile-details *ngIf="mode !== 'simple'"
          [profileData]="mode === 'new' ? null : (loginDetailsState$|async).currentProfile"
          [roles]="roles"
          [showTerms]="true" [showPhoto]="false" [submitLabel]="submitLabel"
          [accreditations]="(loginDetailsState$|async).accreditations"
          (onSubmit)="handleFormSubmit($event)">

          <app-waiting-spinner kind="bar" class="accreditation-loading"
            *ngIf="(loginDetailsState$|async).accreditationsPending" class="white-theme">
            Loading accredited bodies, please wait...
          </app-waiting-spinner>
          <app-message-panel class="form-header" kind="WARNING" *ngIf="registerState.error">
            {{registerState.error}}
          </app-message-panel>
        </app-profile-details>

        <app-profile-details *ngIf="mode === 'simple'" [simple]="true" [profileData]="mode === 'new'" [showTerms]="true"
          [showPhoto]="false" [submitLabel]="submitLabel" (onSimpleSubmit)="handleSimpleFormSubmit($event)">

          <app-waiting-spinner kind="bar" class="accreditation-loading"
            *ngIf="(loginDetailsState$|async).accreditationsPending" class="white-theme">
            Loading accredited bodies, please wait...
          </app-waiting-spinner>
          <app-message-panel class="form-header" kind="WARNING" *ngIf="registerState.error">
            {{registerState.error}}
          </app-message-panel>
        </app-profile-details>

      </div>


    </div>

  </div>
</div>
