/**
 * invitation models
 */

export interface AdditionalFieldSpecifications {
  name: string;
  type: string;
  label: string;
  required: boolean;
  default?: any;
  min?: number;
  max?: number;
  pattern?: string;
  padding?: string;
}


/**
 * Encapsulates a single invitation
 */
export class Invitation {
  name?:                                 string;
  email?:                                string;
  token?:                                string;
  invited_by?:                           string;
  roleTitle?:                            string;
  roleDescription?:                      string;
  roleTermsUrl?:                         string;
  roleTermsVersion?:                     number;
  hostedCompanyName?:                    string;
  hostedCompanyId?:                      number;
  additionalFieldSpecifications?:        AdditionalFieldSpecifications[];
  activationCode?:                       string;
  roleId?:                               number;

  /**
   * Returns an example model
   *
   * @return {Invitation}
   */
  static getExample(): Invitation {
    const additionalFields = [
      {
        'name': 'testkit_serial',
        'type': 'text',
        'label': 'Test Kit Serial No.',
        'required': true,
        'default': null,
        'min': 16,
        'max': 19,
        'pattern': '^(?:[a-fA-F0-9]{4}\\-?){4}$',
        'style': 'text-transform: uppercase;'
      }
    ];

    return {
      name:                                 'Carlos',
      email:                                'email@aadey.com',
      invited_by:                           'System15',
      roleTitle:                            'Water tester',
      roleDescription:                      'Description',
      roleTermsUrl:                         'https://www.adey.com',
      roleTermsVersion:                     1,
      hostedCompanyName:                    'ADEY',
      additionalFieldSpecifications:        additionalFields,
      roleId:                              null,
      hostedCompanyId:                     10,
    };
  }

  /**
   * Converts the model to the API data format send invite
   *
   * @param {Invitation} iv
   * @return {object}
   */
  static sendInviteToAPI(iv: Invitation): any {

    return {
      'name': iv.name,
      'email': iv.email,
      'new_role_id': iv.roleId,
      'hosted_company_id': iv.hostedCompanyId
    };
  }


  /**
   * Converts the model to the API data format accept invite
   *
   * @param {Invitation} invitation
   * @return {object}
   */
  static acceptInviteToAPI(invitation: Invitation): any {
    return {
      'token': invitation.token,
      'accept_terms': true,
      'confirm_role_replace': true,
      'activation_code': invitation.activationCode
    };
  }

}

export interface ReviewInvitationQueryRequest {
  token:    string;
}

export interface ReviewInvitationQueryResponse {
  message: string;
  results: Invitation;
  valid:   boolean;
}

export interface AcceptInvitationQueryRequest {
  token:    string;
  activationCode:  string;
}

export interface AcceptInvitationQueryResponse {
  message: string;
  valid:   boolean;
}

export interface SendInvitationQueryRequest {
  name:               string;
  email:              string;
  roleId:             number;
  hostedCompanyId:    number;
}

export interface SendInvitationQueryResponse {
  valid:   boolean;
  message: string;
}
