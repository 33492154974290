<div class="container-fluid" >
  <div [class]="'row content-page ' + ((loginDetails$ | async).loggedIn ? '' : 'full-height')">

    <div class="container-title">
      <h1 class="white">Oops...</h1>
    </div>

    <div class="page-not-found-container background-white col-xs-12 align-center">
      <h1 class="color-gray medium">404</h1>
      <h2 class="color-gray medium">Page Not Found</h2>

      <app-page-content page="NOT_FOUND" field="intro"></app-page-content>

      <p>Uh oh, it seems something’s sludged up. Go back to the homepage to find what you’re looking for.</p>
      <p class="mar-bottom">{{((loginDetails$ | async).loggedIn ? 'Click the link below to go back' : 'What would you like to do?')}}</p>
      <div class="btn-group" *ngIf="(loginDetails$ | async).loggedIn">

        <button
          *ngIf="getDefaultRoute$() | async; let route"
          class="btn background-adey-red hover-background-adey-blue color-white no-radius"
          type="button"
          (click)="goToPage(route)">
          Return home
        </button>

      </div>
      <div class="btn-group" *ngIf="!(loginDetails$ | async).loggedIn">
        <button class="btn background-adey-red hover-background-adey-blue color-white no-radius mar-right" type="button" (click)="goToPage('/')">I want to log in</button>
        <button class="btn background-adey-red hover-background-adey-blue color-white no-radius" type="button" (click)="goToPage('/register')">I want to register an account</button>
      </div>
    </div>

  </div>
</div>
