/**
 * filter models
 */

import * as moment from 'moment';


export class FilterData {
  deviceId: number;
  serialNo: string;
  filterNo: string;
  name: string;
  firmwareVer: string;
  systemType: string;
  productID: number;
  installUID: string;
  currentUID: string;
  threshold: number;
  warrantyID: number;
  productionFlag: number;
  lastCleaned: string;
  serviceDue: string;
  enableJsonLog: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  first_name: string;
  last_name: string;
  customerId: number;
  customerUser_id: number;
  customerTitle: string;
  customerFirst_name: string;
  customerSurname: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress_1: string;
  customerAddress_2: string;
  customerAddress_3: string;
  customerTown_city: string;
  customerCounty_state: string;
  customerPostcode: string;
}

export class MessageData {
  id: number;
  seqNo: number;
  messageType: string;
  rssi: number;
  battLevel: number;
  battVol: number;
  messageTimestamp: number;
  created_at: string;
  updated_at: string;
}

export class ReadingsData {
  id: number;
  deviceMessageID: number;
  session: number;
  filterLevel: number;
  readingTimestamp: number;
  lastReading: string;
  error: any;
  X: number;
  Y: number;
  Z: number;
  cleanDatum_X: number;
  cleanDatum_Y: number;
  cleanDatum_Z: number;
  battTemp: number;
  surfaceTemp: number;
  created_at: string;
  updated_at: string;
}

export class FilterDataItem {
  filter: FilterData;
  messages: MessageData;
  readings: ReadingsData;

  static getFromApi(apiData: any): FilterDataItem {

    const appFilterData: FilterData = new FilterData();
    appFilterData.deviceId = apiData.deviceId;
    appFilterData.serialNo = apiData.deviceSerialNo;
    appFilterData.filterNo = apiData.deviceFilterNo;
    appFilterData.name = apiData.deviceName;
    appFilterData.firmwareVer = apiData.deviceFirmwareVer;
    appFilterData.systemType = apiData.deviceSystemType;
    appFilterData.productID = apiData.deviceProductID;
    appFilterData.installUID = apiData.deviceInstallUID;
    appFilterData.currentUID = apiData.deviceCurrentUID;
    appFilterData.threshold = apiData.deviceThreshold;
    appFilterData.warrantyID = apiData.deviceWarrantyID;
    appFilterData.productionFlag = apiData.deviceProductionFlag;
    appFilterData.lastCleaned = apiData.deviceLastCleaned;
    appFilterData.serviceDue = moment(apiData.nextServiceDate).format('D MMMM YYYY');
    appFilterData.enableJsonLog = apiData.deviceEnableJsonLog;
    appFilterData.created_at = apiData.deviceCreated_at;
    appFilterData.updated_at = apiData.deviceUpdated_at;
    appFilterData.first_name = apiData.userFirst_name;
    appFilterData.last_name = apiData.userLast_name;
    appFilterData.customerId = apiData.customerId;
    appFilterData.customerUser_id = apiData.customerUser_id;
    appFilterData.customerTitle = apiData.customerTitle;
    appFilterData.customerFirst_name = apiData.customerFirst_name;
    appFilterData.customerSurname = apiData.customerSurname;
    appFilterData.customerPhone = apiData.customerPhone;
    appFilterData.customerEmail = apiData.customerEmail;
    appFilterData.customerAddress_1 = apiData.customerAddress_1;
    appFilterData.customerAddress_2 = apiData.customerAddress_2;
    appFilterData.customerAddress_3 = apiData.customerAddress_3;
    appFilterData.customerTown_city = apiData.customerTown_city;
    appFilterData.customerCounty_state = apiData.customerCounty_state;
    appFilterData.customerPostcode = apiData.customerPostcode;

    const appMessageData: MessageData = new MessageData();
    appMessageData.id = apiData.messageId;
    appMessageData.seqNo = apiData.messageSeqNo;
    appMessageData.messageType = apiData.messageMessageType;
    appMessageData.rssi = apiData.messageRssi;
    appMessageData.battLevel = apiData.messageBattLevel;
    appMessageData.battVol = apiData.messageBattVol;
    appMessageData.messageTimestamp = apiData.messageMessageTimestamp;
    appMessageData.created_at = apiData.messageCreated_at;
    appMessageData.updated_at = apiData.messageUpdated_at;

    const appReadingsData: ReadingsData = new ReadingsData();
    appReadingsData.id = apiData.readingId;
    appReadingsData.deviceMessageID = apiData.readingDeviceMessageID;
    appReadingsData.session = apiData.readingSession;
    appReadingsData.filterLevel = apiData.currentReading;
    appReadingsData.readingTimestamp = apiData.readingReadingTimestamp;
    appReadingsData.lastReading = apiData.readingReadingTimestamp ? moment.unix(apiData.readingReadingTimestamp).format('D MMMM YYYY H:mm') : 'NO READINGS';
    appReadingsData.error = apiData.readingError;
    appReadingsData.X = apiData.readingX;
    appReadingsData.Y = apiData.readingY;
    appReadingsData.Z = apiData.readingZ;
    appReadingsData.cleanDatum_X = apiData.readingCleanDatum_X;
    appReadingsData.cleanDatum_Y = apiData.readingCleanDatum_Y;
    appReadingsData.cleanDatum_Z = apiData.readingCleanDatum_Z;
    appReadingsData.battTemp = apiData.readingBattTemp;
    appReadingsData.surfaceTemp = apiData.readingSurfaceTemp;
    appReadingsData.created_at = apiData.readingCreated_at;
    appReadingsData.updated_at = apiData.readingUpdated_at;

    return {
      filter: appFilterData,
      messages: appMessageData,
      readings: appReadingsData
    };
  }
}

export class FilterDataRequest {
  uid: string;
}


export class FilterDataResponse {
  filterData: FilterDataItem[];
  error: string;
}

export class TimelineData {
  id: number;
  readingDatetime: string;
  rawPercent: string;
  readingPercent: string;

  static getFromApi(apiData: any): TimelineData {
    const appTimelineData: TimelineData = new TimelineData();
    appTimelineData.id = apiData.id;
    appTimelineData.readingDatetime = apiData.readingDatetime;
    appTimelineData.rawPercent = apiData.rawPercent;
    appTimelineData.readingPercent = apiData.readingPercent;

    return appTimelineData;
  }
}

export class TimelineDataRequest {
  deviceID: string;
  startDatetime?: string;
  endDatetime?: string;
}

export class TimelineDataResponse {
  timelineData: TimelineData[];
  error: string;
}

export class ChartData {
  chart: [{
    data: Array<any>,
    label: string
  }];
  labels: Array<any>;
  options: {
    responsive: boolean,
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: string
        }
      }],
      yAxes: [{
        display: boolean,
        scaleLabel: {
          display: boolean,
          labelString: string;
        }
      }]
    },
    title: {
      display: true,
      text: string
    }
  };
  type: string;
  legend: boolean;
}

export class AlertData {
  id: string;
  deviceID: string;
  installerText: string;
  installerCanClose: boolean;

  static getFromApi(apiData: any): AlertData {
    const appAlertData: AlertData = new AlertData();
    appAlertData.id = apiData.id;
    appAlertData.deviceID = apiData.deviceID;
    appAlertData.installerText = apiData.installerText;
    appAlertData.installerCanClose = apiData.installerCanClose;

    return appAlertData;
  }
}

export class AlertDataRequest {
  installerID: string;
}

export class AlertDataResponse {
  alertData: AlertData[];
  error: string;
}

export class DismissAlertRequest {
  id: string;
}

export class DismissAlertResponse {
  error: string;
  message: string;
}

export class DeleteFilterRequest {
  id: string;
}

export class DeleteFilterResponse {
  error: string;
  message: string;
}
