<h2 *ngIf="!data.resource" matDialogTitle>Add new resource</h2>
<h2 *ngIf="data.resource" matDialogTitle>Edit resource</h2>

<mat-dialog-content>

  <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>

  <form [formGroup]="fg" novalidate>
    <div class="row">

      <div class="col-sm-6 col-xs-12">
        <div class="form-group">
          <mat-form-field>
            <input matInput formControlName="title" placeholder="Title" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <textarea matInput formControlName="description" placeholder="Description"></textarea>
          </mat-form-field>
        </div>
        <ng-container *ngIf="fg.value.attachment.itemType !== 'CARD'">
          <div class="form-group">
            <mat-form-field>
              <mat-select matInput class="small" formControlName="addToPage" placeholder="Add to page...">
                <mat-option *ngFor="let page of availablePages" [value]="page">{{page}}</mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <button mat-button (click)="addPageToList()">Add page to list</button>
          </div>
          <div class="form-group">
            <mat-form-field>
              <textarea matInput formControlName="pages" placeholder="Pages (separated by comma)"><textarea></textarea>
            </mat-form-field>
          </div>
        </ng-container>
        <div *ngIf="fg.value.attachment.itemType !== 'CARD'" class="form-group">
          <mat-form-field>
            <textarea matInput formControlName="categories" placeholder="Categories (separated by comma)"></textarea>
          </mat-form-field>
        </div>
        <div *ngIf="fg.value.attachment.itemType !== 'CARD'" class="form-group">
          <mat-form-field>
            <textarea matInput formControlName="keywords" placeholder="Keywords (separated by comma)"></textarea>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <input matInput formControlName="order" placeholder="View priority" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox class="md-primary" formControlName="visible">Visible?</mat-checkbox>
        </div>
      </div>

      <div class="col-sm-6 col-xs-12" formGroupName="attachment">
        <div class="form-group">
          <app-file-upload [imageMode]="true" placeholder="Choose resource thumbnail image..."
            [initialUrl]="data.resource ? data.resource.imageUrl : null" (onData)="handleThumbnailData($event)">
          </app-file-upload>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-select formControlName="itemType" placeholder="Type">
              <mat-option value="DOWNLOAD">Downloadable file</mat-option>
              <mat-option value="VIDEO">Video</mat-option>
              <mat-option value="CARD">Card UI element</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="fg.value.attachment.itemType === 'VIDEO'">
          <mat-form-field>
            <input matInput formControlName="url" placeholder="Video URL" />
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="fg.value.attachment.itemType === 'DOWNLOAD'">
          <app-file-upload [imageMode]="false" placeholder="Choose file attachment..."
            [initialUrl]="fg.value.attachment.url" (onData)="handleAttachmentFileData($event)">
          </app-file-upload>
        </div>
        <div class="form-group" *ngIf="fg.value.attachment.itemType === 'DOWNLOAD'">
          <mat-form-field>
            <input matInput formControlName="downloadName" placeholder="Name of file when downloaded" />
          </mat-form-field>
        </div>

        <!-- We can repurpose these unused columns for CARD types -->
        <ng-container *ngIf="fg.value.attachment.itemType === 'CARD'">
          <div class="form-group">
            <mat-form-field>
              <input matInput formControlName="downloadName" placeholder="Find out more link" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <input matInput formControlName="url" placeholder="Sign in link" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>