import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';


/**
 * Summary
 *    Hub page for Job or Warranty submission
 *
 * Description
 *    Displays two buttons: one to register a new Job and one to register a new
 *    Warranty
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-submission-selection',
  templateUrl: './submission-selection.component.html',
  styleUrls: ['./submission-selection.component.scss']
})
export class SubmissionSelectionComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }

  /**
   * Navigates to the appropriate submission page
   *
   * @param {string} pageRef Route to which to navigate
   */
  selectSubmission(pageRef: string) {
    this.router.navigate([pageRef]);
  }
}
