<h2 *ngIf="newEvent" matDialogTitle>Add new event</h2>
<h2 *ngIf="!newEvent && ((userEvent && !readOnly) || (adminMode && !readOnly))" matDialogTitle>Edit event</h2>
<h2 *ngIf="!newEvent && (!userEvent || readOnly)" matDialogTitle>View event</h2>

<mat-dialog-content>
  <form id="eventForm" #eventFormRef="ngForm" [formGroup]="eventForm" novalidate (ngSubmit)="onSubmitForm()">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div [class]="formGroupClass('details')">
          <mat-form-field>
            <textarea
              matInput
              formControlName="details"
              placeholder="Brief event details"
            ></textarea>
            <mat-error>Event details are required</mat-error>
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('description')">
          <mat-form-field>
            <textarea
              matInput
              formControlName="description"
              placeholder="Event description"
            ></textarea>
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('typeOfEvent')">
          <mat-form-field>
            <mat-select
              formControlName="typeOfEvent"
              placeholder="Type of event"
            >
              <mat-option *ngFor="let t of data.eventTypes" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('location')">
          <mat-form-field>
            <input
              matInput
              formControlName="location"
              placeholder="Location"
            />
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('merchant')">
          <mat-form-field>
            <input
              matInput
              formControlName="merchant"
              placeholder="Merchant"
            />
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('note')">
          <mat-form-field>
            <input
              matInput
              formControlName="note"
              placeholder="Additional notes"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div [class]="formGroupClass('startdate')">
          <mat-form-field>
            <input
              matInput
              min="2017-01-01"
              (dateChange)="startDateChanged()"
              [matDatepicker]="picker"
              formControlName="startdate"
              placeholder="Event start date"
              [(ngModel)]="startDate"
            />
            <mat-datepicker-toggle [for]="picker" class="date-picker-button" *ngIf="userEvent && !readOnly"></mat-datepicker-toggle>
            <mat-error>Event date is required</mat-error>
          </mat-form-field>
          <mat-datepicker #picker (selectedChanged)="onStartDateChange($event)"></mat-datepicker>
        </div>
        <!--<div [class]="formGroupClass('time')">
          <mat-form-field>
            <input
              matInput
              formControlName="time"
              placeholder="Event start time"
            />
            <mat-error>Event start time is required</mat-error>
          </mat-form-field>
        </div>-->

        <div class="form-group dual-select">
          <mat-form-field>
            <mat-select formControlName="startTimeHour" placeholder="Start time">
              <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="startTimeMinute">
              <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div [class]="formGroupClass('endDate')">
          <mat-form-field>
            <input
              matInput
              [min]="eventForm.get('startdate').value"
              [matDatepicker]="endPicker"
              formControlName="endDate"
              placeholder="Event end date"
              [(ngModel)]="endDate"
            />
            <mat-datepicker-toggle [for]="endPicker" class="date-picker-button" *ngIf="userEvent && !readOnly"></mat-datepicker-toggle>
            <mat-error>Event end date is required</mat-error>
          </mat-form-field>
          <mat-datepicker #endPicker (selectedChanged)="onEndDateChange($event)"></mat-datepicker>
        </div>

        <div class="form-group dual-select">
          <mat-form-field>
            <mat-select formControlName="endTimeHour" placeholder="End time">
              <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="endTimeMinute">
              <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [class]="formGroupClass('allDay')">
          <mat-checkbox class="md-primary" formControlName="allDay" (change)="toggleAllDay($event)">All day event?</mat-checkbox>
        </div>
        <div *ngIf="adminMode">
          <div [class]="formGroupClass('userEvent')">
            <mat-checkbox class="md-primary" formControlName="userEvent">User (not ADEY) event?</mat-checkbox>
          </div>
          <div [class]="formGroupClass('userId')" *ngIf="eventForm.get('userEvent').value">
            <mat-form-field>
              <input matInput formControlName="userId" placeholder="Owning user's ID" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <!--<button *ngIf="!newEvent && !userEvent && !readOnly && !data.event.booked" mat-button (click)="onBookClick()">Book</button>-->
  <button *ngIf="!userEvent || readOnly" mat-button [matDialogClose]="false">Close</button>
  <button *ngIf="userEvent && !readOnly" mat-button [matDialogClose]="false">Cancel</button>
  <button *ngIf="userEvent && !readOnly && !newEvent" mat-button (click)="deleteEvent()">Delete</button>
  <button *ngIf="(userEvent || adminMode) && !readOnly" mat-button type="button" (click)="submitForm(eventFormRef)">Save</button>
</mat-dialog-actions>
