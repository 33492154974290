
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * EmailService: provides all functionality related the contact and redeem
 * voucher forms.
 */



import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  PostContactUsRequest,
  PostContactUsResponse,
  PostDemokitRedeemRequest,
  PostDemokitRedeemResponse,
  PostVoucherRedeemRequest,
  PostVoucherRedeemResponse,
} from '../models/email';
import { FetchUserProfileRequestAction } from '../state-management/actions/login-details';
import { StoreState } from '../state-management/store';
import { ApiService } from './api.service';



@Injectable()
export class EmailService {

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
  ) { }


  /**
   * Submits the contact form
   *
   * @param {PostContactUsRequest} req
   * @return {Observable<PostContactUsResponse}
   */
  postContactUsForm(req: PostContactUsRequest): Observable<PostContactUsResponse> {
    return this.apiService.apiPost('/contact-request', req).pipe(
      map((res: any): PostContactUsResponse => {
        return {
          message: res.message ? res.message : 'Email was successfully sent, we will try to respond as soon as possible.',
          error: null,
        };
      }),
      catchError((err: any): Observable<PostContactUsResponse> => {
        return observableOf({
          message: null,
          error: err.message ? err.message : 'Issue communicating with the server.',
        });
      }),);
  }

  /**
   * Submits the redeem voucher form
   *
   * @param {PostVoucherRedeemRequest} req
   * @return {Observable<PostVoucherRedeemResponse>}
   */
  postVoucherRedeemForm(req: PostVoucherRedeemRequest): Observable<PostVoucherRedeemResponse> {
    return this.apiService.apiPost('/voucher-request', req.form).pipe(
      map((res: any): PostVoucherRedeemResponse => {
        this.store.dispatch(new FetchUserProfileRequestAction());
        return {
          callback: req.callback,
          message: res.message ? res.message : 'Email was successfully sent, we will try to respond as soon as possible.',
          error: null,
        };
      }),
      catchError((err: any): Observable<PostVoucherRedeemResponse> => {
        return observableOf({
          message: null,
          error: err.error ? err.error.message ? err.error.message : 'Issue communicating with the server.' : 'Issue communicating with the server.',
        });
      }),);
  }

  /**
   * Submits the redeem Demokit form
   *
   * @param {PostDemokitRedeemRequest} req
   * @return {Observable<PostDemokitRedeemResponse>}
   */
  postDemokitRedeemForm(req: PostDemokitRedeemRequest): Observable<PostDemokitRedeemResponse> {
    return this.apiService.apiPost('/demokit-request', req.form).pipe(
      map((res: any): PostDemokitRedeemResponse => {
        this.store.dispatch(new FetchUserProfileRequestAction());
        return {
          callback: req.callback,
          message: res.message ? res.message : 'Email was successfully sent, we will try to respond as soon as possible.',
          error: null,
        };
      }),
      catchError((err: any): Observable<PostDemokitRedeemResponse> => {
        return observableOf({
          message: null,
          error: err.error ? err.error.message ? err.error.message : 'Issue communicating with the server.' : 'Issue communicating with the server.',
        });
      }),);
  }

}
