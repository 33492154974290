import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as moment from 'moment';

import { DiaryEvent } from '../../../models/diary';

/**
 * Summary
 *    Display the information for an event within a list view.
 *    
 *
 * Description
 *    This component will display the date and details of a given event within a list view.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-event',
  templateUrl: './diary-event.component.html',
  styleUrls: ['./diary-event.component.scss'],
})
export class DiaryEventComponent implements OnInit {
  // The data for the given event
  @Input() event: DiaryEvent;
  // The colour scheme to be displayed in the list
  @Input() colorScheme: string;
  // Callback when the event is clicked
  @Output() onEventClick = new EventEmitter<DiaryEvent>();

  constructor() { }

  ngOnInit() {
  }
  
  /**
   * Called when the user clicks on the event, will emit a callback to 
   * the parent component with the event data.
   */
  onClick() {
    this.onEventClick.emit(this.event);
  }

  /**
   * Return a moment object of the event's date and time
   */
  getEventMomentDate() {
    return moment(this.event.date + ' ' + this.event.time);
  }

  /**
   * Return the date of the event in "DD MM YYYY" format
   */
  getEventDate(): string {
    return this.getEventMomentDate().format('DD MMM YYYY');
  }

  /**
   * Return the time of the event in HH:mm format.
   */
  getEventTime(): string {
    return this.getEventMomentDate().format('HH:mm');
  }

  /**
   * Set the class of the event based on the event's data
   *
   * @return {string} Return the class to be applied to the event's html
   */
  getEventDateClass(): string {
    if (!this.event.userEvent)
      return 'fa-circle background-adey-red';
    else if (moment(this.event.date).isSame(moment(), 'day')) // Same Day
      return 'fa-exclamation background-adey-red';
    else if (moment(this.event.date).isBefore(moment(), 'day')) // Past Event
      return 'fa-circle background-light-gray';
    else if (moment(this.event.date).isSame(moment(), 'week')) // Same Week
      return 'fa-circle background-yellow';
    else
      return 'fa-circle background-green';
  }
}
