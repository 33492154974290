/**
 * product-selector models
 */

import { Product } from './product';


export class ProductSelectQueryRequest {

  // Search criteria
  systemType:    string;
  typeOfWork:    string;
  pipework:      string;
  pipeworkSize:  string;
  systemFilter?: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any} f Data from the form
   * @return {ProductSelectQueryRequest}
   */
  static fromForm(f: any): ProductSelectQueryRequest {
    return Object.assign({}, f);
  }
}

export interface ProductSelectQueryResponse {
  error:    string;
  products: Product[];
}
