import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchJobPdfResponse, FetchJobResponse, RegisterJobResponse, UpdateJobResponse } from '../../models/job';
import { JobService } from '../../services/job.service';
import * as ActionTypes from '../actions/register-job';

@Injectable()
export class RegisterJobEffects {
  constructor(
    private service: JobService,
    private actions$: Actions
  ) { }

  /**
   * For a RegisterJobRequestAction, call
   * JobService::submit() and dispatch a new
   * RegisterJobResponseAction with the response.
   */
   registerJobRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REGISTER_JOB_REQUEST),
    switchMap((req: ActionTypes.RegisterJobRequestAction): Observable<Action> => {
      return this.service.submitJob(req.payload).pipe(
        map((res: RegisterJobResponse): ActionTypes.RegisterJobResponseAction =>
          new ActionTypes.RegisterJobResponseAction(res)
        ));
    })));

  /**
   * For a FetchJobRequestAction, call
   * JobService::getJobData() and dispatch a new
   * FetchJobResponseAction with the response.
   */
   fetchJobRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_JOB_REQUEST),
    switchMap((req: ActionTypes.FetchJobRequestAction): Observable<Action> => {
      return this.service.getJobData(req.payload).pipe(
        map((res: FetchJobResponse): ActionTypes.FetchJobResponseAction =>
          new ActionTypes.FetchJobResponseAction(res)
        ));
    })));

  /**
   * For a FetchJobPdfRequestAction, call JobService::getJobPDF() and dispatch
   * a new FetchJobPdfResponseAction with the response.
   */
   fetchJobPdfRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_JOB_PDF_REQUEST),
    switchMap((req: ActionTypes.FetchJobPdfRequestAction): Observable<ActionTypes.FetchJobPdfResponseAction> => {
      return this.service.getJobPDF(req.payload).pipe(
        map((res: FetchJobPdfResponse): ActionTypes.FetchJobPdfResponseAction =>
          new ActionTypes.FetchJobPdfResponseAction(res)
        ));
    })));

  /**
   * For a UpdateJobRequestAction, call
   * JobService::submit() and dispatch a new
   * UpdateJobResponseAction with the response.
   */
   updateJobRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_JOB_REQUEST),
    switchMap((req: ActionTypes.UpdateJobRequestAction): Observable<Action> => {
      return this.service.updateJob(req.payload).pipe(
        map((res: UpdateJobResponse): ActionTypes.UpdateJobResponseAction =>
          new ActionTypes.UpdateJobResponseAction(res)
        ));
    })));
}
