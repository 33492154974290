import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

/**
 * Summary
 *    Display a link to the warranty / job submission page.
 *    
 *
 * Description
 *    Display a link to the warranty / job submission page, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty-widget',
  templateUrl: './warranty-widget.component.html',
  styleUrls: ['./warranty-widget.component.scss']
})
export class WarrantyWidgetComponent implements OnInit {

  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }
  
  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }

  ngOnInit() {
  }

}
