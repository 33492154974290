<form [formGroup]="fg" class="form-inline" novalidate (ngSubmit)="onSubmitForm()">
  <div class="row content-page">
    <div class="col-md-7 col-md-offset-2 optin-container">
      <h1>About You</h1>
      <app-message-panel kind="WARNING" *ngIf="errors && errors.type === 'required'">Please ensure that all fields are completed.</app-message-panel>

      <p>&nbsp;</p>

      <div *ngFor="let question of (questionsState$ | async)">
        <p>{{ question.title }}</p>

        <!-- Radio question type -->
        <ng-container *ngIf="question.type === 'radio'">
            <mat-radio-group formControlName="{{ question.id }}">
              <mat-radio-button md-no-ink class="md-primary" *ngFor="let option of question.options" value="{{ option.id }}">
                {{ option.title }}
              </mat-radio-button>
            </mat-radio-group>
        </ng-container>
        <!-- / Radio question type -->

        <p>&nbsp;</p>
      </div>

      <div>
        <input type="submit" class="btn btn-primary feature-btn branding-color-white branding-background-adey-blue" value="Save Preferences" />
      </div>
    </div>
  </div>
</form>
