/**
 * Mock for ProductSelectorService (see main service for full details)
 */


import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';




import { Injectable } from '@angular/core';

import { Product } from '../models/product';
import {
  ProductSelectQueryRequest,
  ProductSelectQueryResponse,
} from '../models/product-selector';

import {
  ProductCheckSerialRequest,
  ProductCheckSerialResponse,
} from '../models/product';


@Injectable()
export class ProductSelectorService {

  query(q: ProductSelectQueryRequest): Observable<ProductSelectQueryResponse> {

    const products = [];
    for (let i = 0; i < 10; i++)
      products.push(Product.getExample());

    return observableOf({
        error: null,
        products,
      }).pipe(
      delay(1000));
  }

  checkSerial(q: ProductCheckSerialRequest): Observable<ProductCheckSerialResponse> {
    return observableOf({
        valid: (q.serialNumber === 'INVALID' ? false : true),
        message: (q.serialNumber === 'INVALID' ? 'Serial number has already been used' : null),
      }).pipe(
      delay(1000));
  }

}
