/**
 * admin-stats models
 */

/**
 * Stores all statistics displayed on the admin dashboard
 */
export class OverviewStats {

  totalMembers: number;
  validMembers: number;
  loyalMembers: number;
  validMembersNew: number;
  newMembers: number;
  activeMembers: number;
  activeMembersPercent: number;
  lapsedMembers: number;
  invalidMembersOld: number;
  newMembersNoSn: number;
  totalWarranties: number;
  totalWarrantiesOld: number;
  totalWarrantiesNew: number;
  totalWarrantiesTimeouts: number;
  totalRewardPointsSpentResult: number;
  totalPromotionalPointsAccrued: number;
  totalRetrofit: number;
  totalPointsRedeemedLove2ShopVouchers: number;
  totalPointsRedeemedProClubShop: number;
  businessToolTradeHelp: number;
  businessToolTrueQuote: number;

  /**
   * Returns default statistics
   *
   * @return [OverviewStats]
   */
  static getDefault(): OverviewStats {
    return {
      totalMembers: 0,
      validMembers: 0,
      loyalMembers: 0,
      validMembersNew: 0,
      newMembers: 0,
      activeMembers: 0,
      activeMembersPercent: 0,
      lapsedMembers: 0,
      invalidMembersOld: 0,
      newMembersNoSn: 0,
      totalWarranties: 0,
      totalWarrantiesOld: 0,
      totalWarrantiesNew: 0,
      totalWarrantiesTimeouts: 0,
      totalRewardPointsSpentResult: 0,
      totalPromotionalPointsAccrued: 0,
      totalRetrofit: 0,
      totalPointsRedeemedLove2ShopVouchers: 0,
      totalPointsRedeemedProClubShop: 0,
      businessToolTradeHelp: 0,
      businessToolTrueQuote: 0,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {OverviewStats}
   */
  static fromAPI(data: any): OverviewStats {
    return {
      totalMembers: data.total_members,
      validMembers: data.valid_members,
      loyalMembers: data.loyal_members,
      validMembersNew: data.valid_members_new,
      newMembers: data.new_members,
      activeMembers: data.active_members,
      activeMembersPercent: data.active_members_percent,
      lapsedMembers: data.lapsed_members,
      invalidMembersOld: data.invalid_members_old,
      newMembersNoSn: data.new_members_no_sn,
      totalWarranties: data.total_warranties,
      totalWarrantiesOld: data.total_warranties_old,
      totalWarrantiesNew: data.total_warranties_new,
      totalWarrantiesTimeouts: data.total_warranties_timeouts,
      totalRewardPointsSpentResult: data.reward_points_spent_total,
      totalPromotionalPointsAccrued: data.promotional_points_accrued_total,
      totalRetrofit: data.total_retrofit,
      totalPointsRedeemedLove2ShopVouchers: 0,
      totalPointsRedeemedProClubShop: 0,
      businessToolTradeHelp: data.total_business_tool_tradehelp,
      businessToolTrueQuote: data.total_business_tool_truequote,
    };
  }
}

/**
 * Statistics for a single Product
 */
export class ProductStat {

  // Product ID and title
  id: number;
  title: string;

  // Number of Warranties submitted for Product
  warrantySubmissions: number;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {ProductStat}
   */
  static fromAPI(data: any): ProductStat {
    return {
      id: data.id,
      title: data.title,
      warrantySubmissions: data.warranty_submissions,
    };
  }
}

/**
 * Combination of OverviewStats and ProductStat for each available Product for
 * display on the admin dashboard
 */
export class AdminStats {

  overview: OverviewStats;
  products: ProductStat[];

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {AdminStats}
   */
  static fromAPI(data: any): AdminStats {
    return {
      overview: data.dashboard_stats && Array.isArray(data.dashboard_stats) && data.dashboard_stats.length > 0
        ? OverviewStats.fromAPI(data.dashboard_stats[0])
        : OverviewStats.getDefault(),
      products: data.product_stats && Array.isArray(data.product_stats)
        ? data.product_stats.sort((a, b) => a.title.localeCompare(b.title)).map(ProductStat.fromAPI)
        : [],
    };
  }

  /**
   * Returns an example model
   *
   * @return {AdminStats}
   */
  static getExample(): AdminStats {
    return {
      overview: OverviewStats.getDefault(),
      products: [],
    };
  }

}
export class FetchAdminStatsRequest {
  region: string;
  minDate: string;
  maxDate: string;
}

export class FetchAdminStatsResponse {
  error: string;
  stats: AdminStats;
}

export class FetchAdminStatsDownloadRequest {
  download_type: string;
  region: string;
  minDate: string;
  maxDate: string;
  product_id: number;
}

export class FetchAdminStatsDownloadResponse {
  error: string;
  data: string;
}
