/**
 * warranty-stats reducer: maintains state used in the warranty statistics page
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  MonthlyWarrantyStats,
  WarrantyFullStats,
} from '../../models/warranty';

import * as actions from '../actions/warranty-stats';


// State interface definition: maintains the current stats as well as queries
// for month-based stats
export interface State {

  // Current overall stats, pending flag and error message
  stats:   WarrantyFullStats;
  pending: boolean;
  error:   string;

  // Pending flags for overall stats and monthly stats requests
  pendStats:  boolean;
  pendMStats: boolean;

  // Start and end dates (YYYY-MM-DD) for querying monthly stats
  dateStart: string;
  dateEnd:   string;

  // Month-based stats over the requested start/end dates
  monthlyStats: MonthlyWarrantyStats;
}


const defaultState: State = {
  stats:        null,
  pending:      false,
  pendStats:    false,
  pendMStats:   false,
  error:        null,
  dateStart:    null,
  dateEnd:      null,
  monthlyStats: null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch the latest overall stats
     */
    case actions.FETCH_WARRANTY_FULL_STATS_REQUEST:
      return Object.assign({}, state, {pending: true, pendStats: true});

    /**
     * Response from fetching the latest overall stats
     */
    case actions.FETCH_WARRANTY_FULL_STATS_RESPONSE:
      return Object.assign({}, state, {
        pending:   false,
        pendStats: false,
        error:     action.payload.error,
        stats:     action.payload.error ? state.stats : action.payload.stats,
      });

    /**
     * Request to fetch month-based stats between a start and end date
     */
    case actions.FETCH_WARRANTY_MONTHLY_STATS_REQUEST:
      return Object.assign({}, state, {
        pending:    true,
        pendMStats: true,
        dateStart:  action.payload.dateStart,
        dateEnd:    action.payload.dateEnd,
      });

    /**
     * Response from fetching month-based stats
     */
    case actions.FETCH_WARRANTY_MONTHLY_STATS_RESPONSE:
      return Object.assign({}, state, {
        pending:      false,
        pendMStats:   false,
        error:        action.payload.error,
        monthlyStats: action.payload.error
          ? state.monthlyStats
          : action.payload.data,
      });

    default:
      return state;
  }
}
