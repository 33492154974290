/**
 * user-suggestion actions: defines all actions for fetching user suggestion
 * records (name and ID) based on a name query.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  FetchUserSuggestionRequest,
  FetchUserSuggestionResponse,
} from '../../models/user-suggestion';


// Action type definitions
export const FETCH_USER_SUGGESTION_REQUEST  = 'FETCH_USER_SUGGESTION_REQUEST';
export const FETCH_USER_SUGGESTION_RESPONSE = 'FETCH_USER_SUGGESTION_RESPONSE';


// Action definitions
export class FetchUserSuggestionRequestAction implements Action {
  readonly type = FETCH_USER_SUGGESTION_REQUEST;
  constructor(public payload: FetchUserSuggestionRequest) {}
}

export class FetchUserSuggestionResponseAction implements Action {
  readonly type = FETCH_USER_SUGGESTION_RESPONSE;
  constructor(public payload: FetchUserSuggestionResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchUserSuggestionRequestAction
  | FetchUserSuggestionResponseAction;
