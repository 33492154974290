/**
 * PostcodeLookupService: provides all functionality related to looking up
 * postcodes and addresses
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';




import { Injectable } from '@angular/core';


import { ApiService }                           from './api.service';
import { PostcodeLookupService as MockService } from './postcode-lookup.service.mock';


import {
  AddressListItem,
  FullAddressItem,
  GetPostcodeAddressRequest,
  GetPostcodeAddressResponse,
  PostcodeLookupRequest,
  PostcodeLookupResponse,
} from '../models/postcode-lookup';


@Injectable()
export class PostcodeLookupService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService: MockService = null;


  constructor(
    private apiService: ApiService,
  ) {
    this.mockService = new MockService();
  }


  /**
   * Performs a postcode query and returns a list of matching partial addresses
   *
   * @param {PostcodeLookupRequest} req
   * @return {Observable<PostcodeLookupResponse>}
   */
  queryPostcode(req: PostcodeLookupRequest): Observable<PostcodeLookupResponse> {

    return this.apiService.apiPost('/postcode-lookup', {post_code: req.postcode}).pipe(
      map((res: any): PostcodeLookupResponse => {
        const valid: boolean = res && Array.isArray(res);
        return {
          error:    valid ? null : 'Invalid response from server',
          callback: req.callback,
          results:  valid ? res.map(AddressListItem.fromAPI) : null,
        };
      }),
      catchError((err): Observable<PostcodeLookupResponse> => {
        return observableOf({
          error:    'Unable to lookup postcode: server error',
          callback: req.callback,
          results:  null
        });
      }),);
  }

  /**
   * Fetches a full address given an address ID obtained from queryPostcode()
   *
   * @param {GetPostcodeAddressRequest} req
   * @return {Observable<GetPostcodeAddressResponse>}
   */
  getAddressByID(req: GetPostcodeAddressRequest): Observable<GetPostcodeAddressResponse> {

    return this.apiService.apiPost('/postcode-retrieval', {id: req.id}).pipe(
      map((res: any): GetPostcodeAddressResponse => {
        const valid: boolean = res && Array.isArray(res) && res.length > 0 && res[0].Id;
        const error: string = valid
          ? null
          : (
            Array.isArray(res) && res.length > 0 && res[0].Description
              ? `Unable to get address: ${res[0].Description}`
              : 'Unable to get address'
          );
        return {
          error:    valid ? null : error,
          callback: req.callback,
          address:  valid ? FullAddressItem.fromAPI(res[0]) : null,
        };
      }),
      catchError((err): Observable<GetPostcodeAddressResponse> => {
        return observableOf({
          error:    'Unable to get address: server error',
          callback: req.callback,
          address:  null
        });
      }),);
  }

}
