<div id="pdf" *ngIf="job">

	<div class="logo-div">
		<img src="assets/i/pdf/adey-logo.png" class="logo-img" alt="ADEY Logo"/>
	</div>

	<div class="header-div">
		<h1 class="header-title">ADEY SERVICE / JOB RECORD</h1>
	</div>

	<div>
		<p *ngIf="job.user">
			<span class="detail-bold">Installer Name: </span>{{job.user.title + ' ' + job.user.firstName + ' ' + job.user.lastName}}<br>
			<span class="detail-bold">Email address: </span>{{job.user.email}}<br>
			<span class="detail-bold">Telephone number: </span>{{job.user.telephone}}
		</p>

		<p>
			<span class="detail-bold">Customer Name: </span> {{job.customer.customerTitle + ' ' + job.customer.customerSurname}}<br>
			<span class="detail-bold">Postcode: </span>{{job.customer.customerPostcode}}
		</p>
	</div>

	<div>
		<p>This is a record of the work undertaken on your boiler or central heating system by the above named company on <span class="detail-bold">{{getDate(job.filterInstallDate)}}</span> Please note this is not a warranty for any products used.</p>
	</div>

	<div class="table-div">
		<table>

			<tr *ngIf="job.cleanerUsed">
				<td class="left-cell"><img src="assets/i/pdf/clean-icon.png" class="icon-img" alt="Clean"/></td>
				<td>
          <p><span class="detail-bold">MC3+ or MC5</span> has been added to your system to remove sludge, debris and scale </p>
        </td>
			</tr>

			<tr *ngIf="job.magnaCleanseUsed">
				<td class="left-cell"><img src="assets/i/pdf/magnacleanse-icon.png" class="icon-img" alt="MagnaCleanse"/></td>
				<td>
					A MagnaCleanse system flush has been carried out on the system to remove suspended black iron oxide sludge and provide ongoing protection
					<!--<p><span class="detail-bold">Filter install: </span> {{job.filterInstall}}</p>
					<p><span class="detail-bold">Product type: </span> {{job.filterProductID}}</p>
					<p><span class="detail-bold">date: </span> {{getDate(job.filterInstallDate)}}</p>
					<p><span class="detail-bold">Filter serial: </span> {{job.filterSerial}}</p>-->
					<!--<p><span class="detail-bold">Old filter brand: </span> {{job.cleanerSerial}}</p>-->
          		</td>
			</tr>

			<tr *ngIf="job.filterUsed">
				<td class="left-cell"><img src="assets/i/pdf/magnaclean-icon.png" class="icon-img" alt="MagnaClean"/></td>
        <td>
          <span *ngIf="!job.filterProductName">A MagnaClean</span>
          <span *ngIf="job.filterProductName">A {{job.filterProductName}}</span>
          has been fitted to the system to help protect from the build up of black iron oxide sludge, to maintain system efficiency and prevent boiler breakdown.
        </td>
			</tr>

			<tr *ngIf="job.protectionUsed">
				<td class="left-cell"><img src="assets/i/pdf/protect-icon.png" class="icon-img" alt="Protect"/></td>
				<td>
          <p>MC1+ Central Heating System Protector has been added to the system to protect against corrosion and limescale and maintain heating efficiency</p>
        </td>
			</tr>

      <tr *ngIf="job.waterTestComplete">
				<td class="left-cell"><img src="assets/i/pdf/test-icon.png" class="icon-img" alt="Test"/></td>
				<td>A Water Test has been carried out on the system to check the water quality.  Results pending.</td>
			</tr>

			<tr>
				<td class="left-cell"><img src="assets/i/pdf/maintain-icon.png" class="icon-img" alt="Maintain"/></td>
				<td>An annual service should be carried out on every boiler and MagnaClean filter.</td>
			</tr>

		</table>
	</div>

</div>
