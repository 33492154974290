<div class="widget-body border-box border-lighter-gray resource-widget background-white col-xs-12">
  <div class="content-container col-xs-12 no-pad">

    <div class="text-center" *ngIf="pending">
      <app-waiting-spinner kind="spinner"></app-waiting-spinner>
    </div>

    <p *ngIf="resources && resources.length === 0 && !pending">
      No resources to display. Please click below to see all available
      resources.
    </p>

    <div *ngIf="resources && resources.length > 0 && !pending">
      <app-content-item
        *ngFor="let item of resources.slice(0, 3)"
        [resourceItem]="item"
        [widget]="true"
        class="col-xs-4 border-light-gray content-item"
      >
      </app-content-item>
    </div>

    <div class="widget-btn-container">
      <div class="widget-btn col-xs-12 background-lighter-gray">
        <button class="btn background-green color-white full-width no-radius" (click)="goToResources()">See all resources</button>
      </div>
    </div>
  </div>
</div>
