/**
 * product models
 */

import * as moment from 'moment';

/**
 * Encapsulates a single product
 */
export class Product {
  createdAt:                            string;
  description:                          string;
  id:                                   string;
  imageUrl:                             string;
  pointsValue:                          number;
  productGroupId:                       number;
  subTitle:                             string;
  title:                                string;
  updatedAt:                            string;
  prefix?:                              string;
  previousPrefix?:                      string;
  highestProducedSerialNumber?:         string;
  previousHighestProducedSerialNumber?: string;
  warrantyLength?:                      string;

  /**
   * Converts a Product model into a simple object which can be used to
   * generate select options
   *
   * @param {Product} product
   * @return {object}
   */
  static getProductForBasicSelection(product: Product): any {
    return {
      id:     product.id,
      name:   product.title,
      prefix: product.prefix,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiProduct API data
   * @return {Product}
   */
  static getProductsFromApi(apiProduct: any): Product {
    const appProduct: Product = new Product();

    appProduct.createdAt                           = apiProduct.created_at;
    appProduct.description                         = apiProduct.description;
    appProduct.id                                  = apiProduct.id;
    appProduct.imageUrl                            = apiProduct.imageurl;
    appProduct.pointsValue                         = apiProduct.reward_points;
    appProduct.productGroupId                      = apiProduct.productgroup_id;
    appProduct.subTitle                            = apiProduct.subtitle;
    appProduct.title                               = apiProduct.title;
    appProduct.updatedAt                           = apiProduct.updated_at;
    appProduct.prefix                              = apiProduct.prefix;
    appProduct.previousPrefix                      = apiProduct.previous_prefix;
    appProduct.highestProducedSerialNumber         = apiProduct.highest_produced_serial_number;
    appProduct.previousHighestProducedSerialNumber = apiProduct.previous_highest_produced_serial_number;
    appProduct.warrantyLength                           = apiProduct.default_warranty;

    return appProduct;
  }

  /**
   * Returns an example model
   *
   * @return {Product}
   */
  static getExample(): Product {
    const groups = [
      {value: 0, label: 'clean'},
      {value: 1, label: 'flush'},
      {value: 2, label: 'maintain'},
      {value: 3, label: 'protect'},
      {value: 4, label: 'test'},
    ];

    return {
      createdAt:      moment().format('YYYY/MM/DD HH:mm:ss'),
      description:    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed.',
      id:             Math.floor(Math.random() * 1000).toString(),
      imageUrl:       `http://lorempixel.com/128/128/technics/${Math.floor(Math.random() * 10)}`,
      pointsValue:    Math.floor(Math.random() * 1000),
      productGroupId: groups[Math.floor(Math.random() * groups.length)].value,
      subTitle:       `[${Math.floor(Math.random() * 100)} x ${Math.floor(Math.random() * 100)}]`,
      title:          `Example product #${Math.floor(Math.random() * 1000)}`,
      updatedAt:      moment().format('YYYY/MM/DD HH:mm:ss')
    };
  }
}

export interface ProductCheckSerialRequest {
  productId:    string;
  serialNumber: string;
}

export interface ProductCheckSerialResponse {
  valid:   boolean;
  message: string;
}
