import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FetchResourceItemsRequestAction } from '../../state-management/actions/resources';
import { State as ResourcesState } from '../../state-management/reducers/resources';
import { StoreState } from '../../state-management/store';

@Component({
  selector: 'app-business-tools',
  templateUrl: './business-tools.component.html',
  styleUrls: ['./business-tools.component.scss'],
})
export class BusinessToolsComponent {

  public resources$: Observable<ResourcesState>;

  constructor(
    private store: Store<StoreState>,
  ) {
    this.resources$ = this.store.select('resources');

    this.store.dispatch(new FetchResourceItemsRequestAction(
      {
        page: 'Business tools',
        category: null,
        search: null,
      }
    ));
  }
}
