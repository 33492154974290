/**
 * admin-rewards reducer: maintains state for the reward management admin CMS.
 */
import {
  AdminUser,
} from '../../models/admin-users';

import * as rewardActions from '../actions/admin-rewards';
import * as userActions from '../actions/admin-users';
import { PointsHistory, Promotion } from '../../models/admin-rewards';

// State interface definition: manages a paginated list of AdminUser models
export interface State {
  users: AdminUser[];
  pending: boolean;
  error: string;
  message: string;
  pageNum: number;
  totalPages: number;
  promotions: Promotion[];
  pointsHistory: PointsHistory[];
  type: string;
}

const defaultState: State = {
  users: [],
  pending: false,
  error: null,
  message: null,
  pageNum: 1,
  totalPages: 0,
  promotions: [],
  pointsHistory: [],
  type: null,
};

export function reducer(
  state: State = defaultState,
  action: rewardActions.Actions | userActions.Actions
): State {
  switch (action.type) {

    case rewardActions.ADD_ADMIN_PROMOTION_REQUEST:
      return Object.assign({}, state, {
        type: 'ADD_ADMIN_PROMOTION_REQUEST',
        pending: true,
        error: null,
      });

    case rewardActions.ADD_ADMIN_PROMOTION_RESPONSE:
      return Object.assign({}, state, {
        type: 'ADD_ADMIN_PROMOTION_RESPONSE',
        pending: false,
        error: action.payload.error,
      });

    case rewardActions.DELETE_ADMIN_PROMOTION_REQUEST:
      return Object.assign({}, state, {
        type: 'DELETE_ADMIN_PROMOTION_REQUEST',
        pending: true,
        error: null,
      });

    case rewardActions.DELETE_ADMIN_PROMOTION_RESPONSE:
      return Object.assign({}, state, {
        type: 'DELETE_ADMIN_PROMOTION_RESPONSE',
        pending: false,
        error: action.payload.error,
        promotions: state.promotions.filter((promotion: Promotion) =>
          promotion.id !== action.payload.id
        ),
      });

    case rewardActions.FETCH_ADMIN_PROMOTIONS_REQUEST:
      return Object.assign({}, state, {
        type: 'FETCH_ADMIN_PROMOTIONS_REQUEST',
        pending: true,
        error: null,
        promotions: [],
      });

    case rewardActions.FETCH_ADMIN_PROMOTIONS_RESPONSE:
      return Object.assign({}, state, {
        type: 'FETCH_ADMIN_PROMOTIONS_RESPONSE',
        pending: false,
        error: action.payload.error,
        promotions: action.payload.promotions,
      });

    case rewardActions.FETCH_USER_POINTS_HISTORY_REQUEST:
      return Object.assign({}, state, {
        type: 'FETCH_USER_POINTS_HISTORY_REQUEST',
        pending: true,
        error: null,
        pointsHistory: [],
      });

    case rewardActions.FETCH_USER_POINTS_HISTORY_RESPONSE:
      return Object.assign({}, state, {
        type: 'FETCH_USER_POINTS_HISTORY_RESPONSE',
        pending: false,
        error: action.payload.error,
        pointsHistory: action.payload.pointsHistory,
      });
    /**
     * Request to transfer a specified number of points from one User to
     * another
     */
    case rewardActions.TRANSFER_ADMIN_USER_POINTS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.userFrom.id || u.id === action.payload.userTo.id)
            return Object.assign({}, u, { pending: true });
          else
            return u;
        }),
      });

    /**
     * Response from transferring points between Users
     */
    case rewardActions.TRANSFER_ADMIN_USER_POINTS_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.userFrom.id)
            return Object.assign({}, u, { pending: false, points: action.payload.userFrom.points });
          else if (u.id === action.payload.userTo.id)
            return Object.assign({}, u, { pending: false, points: action.payload.userTo.points });
          else
            return u;
        }),
      });

    /**
     * Request to update a User's points
     */
    case rewardActions.UPDATE_ADMIN_USER_POINTS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.user.id)
            return Object.assign({}, u, { pending: true });
          else
            return u;
        }),
      });

    /**
     * Response from updating a User's points
     */
    case rewardActions.UPDATE_ADMIN_USER_POINTS_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.user.id)
            return Object.assign({}, u, { pending: false, points: action.payload.user.points });
          else
            return u;
        }),
      });

    /**
     * Request to fetch a page of Users
     */
    case userActions.FETCH_ADMIN_USERS_REQUEST:
      return Object.assign({}, state, {
        users: [],
        pending: true,
        error: null,
        pageNum: Math.max(1, action.payload.page),
        totalPages: 0,
      });

    /**
     * Response from fetching a page of Users
     */
    case userActions.FETCH_ADMIN_USERS_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        users: action.payload.error ? state.users : action.payload.users,
        totalPages: action.payload.error ? 0 : action.payload.totalPages,
      });

    default:
      return state;
  }
}
