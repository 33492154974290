/**
 * warranty models
 */

import * as moment from 'moment';


import { Customer }    from './customer';
import { Job }         from './job';
import { UserProfile } from './user-profile';


/**
 * Encapsulates a product that can be selected during Warranty registration
 */
export interface ProductType {
  id:   string;
  name: string;
}

/**
 * Encapsulates a promotional code that can be applied to a Warranty during
 * registration
 */
export class PromoCode {
  id?: string;

  // Actual code that needs to be entered by a user
  code: string;

  // Friendly name for the code
  name: string;

  // Set when model matches authority (API)
  committed?: boolean;

  // Code can only be used by user if enabled
  enabled: boolean;

  // Maximum uses allowed per user (0 for unlimited)
  usesPerUser: number;

  // Value for the calculation (see calcType)
  calcValue: number;

  // "MUL" (points multiplied by value) or "ADD" (value added to points)
  calcType: string;

  /**
   * Returns an example model
   *
   * @return {PromoCode}
   */
  static getExample(): PromoCode {
    const calcTypes: string[] = ['ADD', 'MUL'];
    const calcType: string = calcTypes[Math.floor(Math.random() * calcTypes.length)];

    return {
      code:        'code' + (Math.ceil(Math.random() * 1000) + 500).toString(),
      name:        'Increase your points',
      committed:   true,
      enabled:     Math.floor(Math.random() * 10) % 2 === 0,
      usesPerUser: Math.floor(Math.random() * 100),
      calcValue:   calcType === 'ADD'
        ? Math.ceil(Math.random() * 20)
        : Math.ceil(Math.random() * 5) + 1,
      calcType,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {PromoCode}
   */
  static fromAPI(data: any): PromoCode {
    return {
      id:          data.id,
      code:        data.code,
      name:        data.name,
      committed:   true,
      enabled:     data.enabled,
      usesPerUser: data.uses_per_user,
      calcValue:   data.calc_value,
      calcType:    data.calc_type,
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {PromoCode} pc
   * @return {object}
   */
  static toAPI(pc: PromoCode): any {
    return {
      id:            pc.id,
      code:          pc.code,
      name:          pc.name,
      enabled:       pc.enabled,
      uses_per_user: pc.usesPerUser,
      calc_value:    pc.calcValue,
      calc_type:     pc.calcType,
    };
  }
}

/**
 * Encapsulates a single Warranty record
 */
export class Warranty {
  id?:        string;
  warranty_id?: string;
  createdAt?: string;
  updatedAt?: string;

  // Set when the model matches the authority (API)
  committed?: boolean;

  // Product details
  productType:  string;
  productId?:   string;
  serialNumber: string;

  // YYYY-MM-DD
  dateOfInstallation: string;

  // YYYY-MM-DD
  expiryDate?: string;

  // NEW or EXISTING
  typeOfBoiler: string;
  typeOfFilter: string;

  boilerId?:       string;
  warrantyLength?: number;
  oldFilterBrand?: string;

  // Number of points awarded by submission of this Warranty (only set when
  // viewing an existing Warranty)
  pointsAwarded?: number;

  // Related Customer model
  customerId?: string;
  customer:    Customer;

  // Owner
  userId?: string;
  user?:   UserProfile;

  // Related Job model
  jobId?: string;
  job?:   Job;

  addRenewalDateToCalendar: boolean;
  notifyClientBeforeExpiry: boolean;

  promoCode?: string;
  points?: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {Warranty}
   */
  static fromFormData(formData: any): Warranty {
    return {
      id:                       formData.id.toString().trim().length === 0 ? null : formData.id.toString().trim(),
      userId:                   formData.userId,
      productType:              formData.productTypeName,
      productId:                formData.productType,
      serialNumber:             formData.serialNumber,
      dateOfInstallation:       moment(formData.dateOfInstallation).format('YYYY-MM-DD'),
      expiryDate:               formData.expiryDate ? moment(formData.expiryDate).format('YYYY-MM-DD') : null,
      typeOfBoiler:             formData.typeOfBoiler,
      typeOfFilter:             formData.typeOfFilter,
      oldFilterBrand:           formData.oldFilterBrand,
      warrantyLength:           formData.warrantyLength,
      customer:                 Customer.fromFormData(formData.customer),
      addRenewalDateToCalendar: formData.addRenewalDateToCalendar,
      notifyClientBeforeExpiry: formData.notifyClientBeforeExpiry,
      promoCode:                formData.promoCode,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {Warranty}
   */
  static getFromApi(apiData: any): Warranty {
    const appWarranty: Warranty = new Warranty();

    appWarranty.createdAt                 = apiData.created_at;
    appWarranty.updatedAt                 = apiData.updated_at;
    appWarranty.id                        = apiData.id;
    appWarranty.userId                    = apiData.user_id;
    appWarranty.customer                  = apiData.customer_by_customer_id ? Customer.fromApiData(apiData.customer_by_customer_id) : null,
    appWarranty.customerId                = apiData.customer_id;
    appWarranty.jobId                     = apiData.job_id;
    appWarranty.productType               = apiData.product_by_product_id ? apiData.product_by_product_id.title : '',
    appWarranty.productId                 = apiData.product_id;
    appWarranty.serialNumber              = apiData.product_serial_number;
    appWarranty.dateOfInstallation        = moment(apiData.install_date).format('YYYY-MM-DD');

    appWarranty.typeOfBoiler              = apiData.new_boiler;
    appWarranty.boilerId                  = apiData.boilerbrand;
    appWarranty.typeOfFilter              = apiData.new_filter;
    appWarranty.oldFilterBrand            = apiData.old_filter_brand;

    appWarranty.pointsAwarded             = apiData.points;
    appWarranty.addRenewalDateToCalendar  = apiData.add_renewal;
    appWarranty.notifyClientBeforeExpiry  = apiData.notify_client;
    appWarranty.promoCode                 = apiData.promo_code;
    appWarranty.points                 	  = apiData.points;

    appWarranty.expiryDate = apiData.job_by_job_id && apiData.job_by_job_id.filter_expiry_date
      ? moment(apiData.job_by_job_id.filter_expiry_date).format('YYYY-MM-DD')
      : null;

    appWarranty.warrantyLength = apiData.job_by_job_id && apiData.job_by_job_id.warranty_length
      ? apiData.job_by_job_id.warranty_length
      : null;

    appWarranty.job  = apiData.job_by_job_id   ? Job.fromAPI(apiData.job_by_job_id)               : null;
    appWarranty.user = apiData.user_by_user_id ? UserProfile.fromAPIData(apiData.user_by_user_id) : null;

    appWarranty.committed = true;

    return appWarranty;
  }

  /**
   * Returns an example model
   *
   * @return {Warranty}
   */
  static getExample(): Warranty {
    const user: UserProfile = UserProfile.getExample();
    return {
      createdAt:                moment().format('YYYY-MM-DD HH:mm:ss'),
      updatedAt:                moment().format('YYYY-MM-DD HH:mm:ss'),
      id:                       Math.floor(Math.random() * 1000).toString(),
      userId:                   Math.floor(Math.random() * 1000).toString(),
      jobId:                    Math.floor(Math.random() * 1000).toString(),
      productType:              Math.floor(Math.random() * 4).toString(),
      serialNumber:             'abcd1234',
      dateOfInstallation:       moment().subtract(Math.floor(Math.random() * 50), 'days').format('YYYY-MM-DD'),
      expiryDate:               moment().add(Math.floor(Math.random() * 50), 'days').format('YYYY-MM-DD'),
      typeOfBoiler:             Math.floor(Math.random() * 10) % 2 === 0 ? 'NEW' : 'EXISTING',
      boilerId:                 'Boiler Brand Ltd.',
      typeOfFilter:             Math.floor(Math.random() * 10) % 2 === 0 ? 'NEW' : 'EXISTING',
      oldFilterBrand:           'Old Filter Brand Ltd.',
      customer:                 Customer.getExample(),
      customerId:               Math.floor(Math.random() * 10).toString(),
      committed:                true,
      warrantyLength:           2014,
      pointsAwarded:            Math.floor(Math.random() * 1000),
      addRenewalDateToCalendar: Math.floor(Math.random() * 10) % 2 === 0,
      notifyClientBeforeExpiry: Math.floor(Math.random() * 10) % 2 === 0,
      promoCode:                'promo2017',
    };
  }

  /**
   * Converts the model to the API data format
   *
   * @param {Warranty} appData
   * @return {object}
   */
  static toApiPost(appData: Warranty): any {
    const data: any = Object.assign(
      {
        // TODO: handle warranty registration for existing customers
        //customer_id: appData.cust,

        product_id:            appData.productId ? appData.productId : appData.productType,
        boilerbrand:           appData.boilerId,
        product_serial_number: appData.serialNumber,
        install_date:          appData.dateOfInstallation,
        old_filter_brand:      appData.oldFilterBrand,
        new_boiler:            appData.typeOfBoiler === 'NEW',
        new_filter:            appData.typeOfFilter === 'NEW',
        add_renewal:           appData.addRenewalDateToCalendar,
        notify_client:         appData.notifyClientBeforeExpiry,
        promo_code:            appData.promoCode,
      },
      Customer.toAPI(appData.customer)
    );

    if (appData.id)
      data.id = parseInt(appData.id, 10);

    if (appData.userId)
      data.save_on_behalf_of = appData.userId;

    return data;
  }
}

/**
 * Encapsulates user Warranty statistics for a single month
 */
export class MonthlyWarrantyStat {
  label: string;
  key:   string;
  value: number;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {MonthlyWarrantyStat}
   */
  static fromAPI(data: any): MonthlyWarrantyStat {
    return {
      label: `${data.month}, ${data.year}`,
      key:   `${data.month}${data.year}`,
      value: data.warranties_registered,
    };
  }
}

/**
 * Encapsulates a range of month-based user Warranty statistics
 */
export class MonthlyWarrantyStats {
  data:   MonthlyWarrantyStat[];
  labels: string[];

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {MonthlyWarrantyStats}
   */
  static fromAPI(data: any): MonthlyWarrantyStats {
    return {
      data:   data.map(MonthlyWarrantyStat.fromAPI),
      labels: data.map((v: any): string => `${v.month}, ${v.year}`),
    };
  }
}

/**
 * Encapsulates the latest overall Warranty statistics for a user
 */
export interface WarrantyStats {
  count:      number;
  complete:   number;
  incomplete: number;
}

/**
 * Encapsulates all latest overall Warranty statistics for a user
 */
export class WarrantyFullStats {
  completeWarranties:   number;
  incompleteWarranties: number;

  totalWarranties:     number;
  warrantiesLastMonth: number;

  pointsAvailable: number;
  pointsEarned:    number;
  pointsSpent:     number;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {WarrantyFullStats}
   */
  static fromAPI(data: any): WarrantyFullStats {
    const completeWarranties: number = data.registered_all_time - data.incompleteWarranties;

    return {
      completeWarranties,
      incompleteWarranties: data.incomplete_warranties,

      totalWarranties:     data.registered_all_time,
      warrantiesLastMonth: data.registered_last_30_days,

      pointsAvailable: data.points_current,
      pointsEarned:    data.points_rewarded,
      pointsSpent:     data.points_redeemed,
    };
  }
}


export interface CheckPromoCodeResponse {
  valid:     boolean;
  error:     string;
  promoCode: PromoCode;
}

export interface CheckSerialNumberResponse {
  valid:   boolean;
  message: string;
  serial?: string;
}

export interface FetchProductTypesResponse {
  products: ProductType[];
}

export interface FetchMonthlyWarrantyStatsRequest {
  dateStart: string; // YYYY-MM-DD
  dateEnd:   string; // YYYY-MM-DD
}

export interface FetchMonthlyWarrantyStatsResponse {
  error: string;
  data:  MonthlyWarrantyStats;
}

export interface FetchWarrantyFullStatsResponse {
  error: string;
  stats: WarrantyFullStats;
}

export interface FetchWarrantyRequest {
  id: string;
}

export interface FetchWarrantyResponse {
  error: string;
  warranty: Warranty;
}

export interface FetchWarrantiesRequest {
  id: string;

  // Pagination
  pageNum: number;
  perPage: number;
  upcoming: boolean;
}

export interface FetchWarrantiesResponse {
  error: string;
  items: Warranty[];
  stats: WarrantyStats;

  // Total number of pages of results
  totalPages: number;
}

export interface FetchWarrantyPdfRequest {
  id: string;
}

export interface FetchWarrantyPdfResponse {
  error:   string;
  pdfData: string;
}

export class RegisterWarrantyRequest {
  warranty: Job;

  static fromWarranty(warranty: Job): RegisterWarrantyRequest {
    return {warranty};
  }
}

export interface RegisterWarrantyResponse {
  error:   boolean;
  message: string;
}

export interface UpdateWarrantyResponse {
  error:    string;
  warranty: Warranty;
}
