import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PostContactUsResponse, PostDemokitRedeemResponse, PostVoucherRedeemResponse } from '../../models/email';
import { EmailService } from '../../services/email.service';
import * as ActionTypes from '../actions/email';

@Injectable()
export class EmailEffects {
  constructor(
    private emailService: EmailService,
    private actions$: Actions
  ) { }

  /**
   * For a postContactUsRequestAction, call EmailService::postContactUsForm()
   * and dispatch a new PostContactUsResponseAction with the response.
   */
   postContactUsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.POST_CONTACT_US_REQUEST),
    switchMap((req: ActionTypes.PostContactUsRequestAction): Observable<ActionTypes.PostContactUsResponseAction> => {
      return this.emailService.postContactUsForm(req.payload).pipe(
        map((res: PostContactUsResponse): ActionTypes.PostContactUsResponseAction => new ActionTypes.PostContactUsResponseAction(res)));
    })));

  /**
   * For a postVoucherRedeemRequestAction, call EmailService::postVoucherRedeemForm()
   * and dispatch a new PostContactUsResponseAction with the response.
   */
   postVoucherRedeemRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.POST_VOUCHER_REDEEM_REQUEST),
    switchMap((req: ActionTypes.PostVoucherRedeemRequestAction): Observable<ActionTypes.PostVoucherRedeemResponseAction> => {
      return this.emailService.postVoucherRedeemForm(req.payload).pipe(
        map((res: PostVoucherRedeemResponse): ActionTypes.PostVoucherRedeemResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.PostVoucherRedeemResponseAction(res);
        }));
    })));

  /**
   * For a postDemokitRedeemRequestAction, call EmailService::postDemokitRedeemForm()
   * and dispatch a new PostDemokitResponseAction with the response.
   */
   postDemokitRedeemRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.POST_DEMOKIT_REDEEM_REQUEST),
    switchMap((req: ActionTypes.PostDemokitRedeemRequestAction): Observable<ActionTypes.PostDemokitRedeemResponseAction> => {
      return this.emailService.postDemokitRedeemForm(req.payload).pipe(
        map((res: PostDemokitRedeemResponse): ActionTypes.PostDemokitRedeemResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.PostDemokitRedeemResponseAction(res);
        }));
    })));

}
