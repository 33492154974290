import { ActionReducer } from '@ngrx/store';

import { reducer as adminEvents, State as AdminEventsState } from './reducers/admin-events';
import { reducer as adminJobs, State as AdminJobsState } from './reducers/admin-jobs';
import { reducer as adminPro3, State as AdminPro3State } from './reducers/admin-pro3';
import { reducer as adminResources, State as AdminResourcesState } from './reducers/admin-resources';
import { reducer as adminRewards, State as AdminRewardsState } from './reducers/admin-rewards';
import { reducer as adminStats, State as AdminStatsState } from './reducers/admin-stats';
import { reducer as adminUsers, State as AdminUsersState } from './reducers/admin-users';
import { reducer as businessTools, State as BusinessToolsState } from './reducers/business-tools';
import { reducer as contentPages, State as ContentPagesState } from './reducers/content-pages';
import { reducer as country, State as CountryState } from './reducers/country';
import { reducer as diary, State as DiaryState } from './reducers/diary';
import { reducer as email, State as EmailState } from './reducers/email';
import { reducer as feature, State as FeatureState } from './reducers/feature';
import { reducer as ReviewInvitation, State as ReviewInvitationState } from './reducers/invitation';
import { reducer as loginDetails, State as LoginDetailsState } from './reducers/login-details';
import { reducer as popup, State as PopupState } from './reducers/popup';
import { reducer as postcodeLookup, State as PostcodeLookupState } from './reducers/postcode-lookup';
import { reducer as pro3, State as Pro3State } from './reducers/pro3';
import { reducer as products, State as ProductsState } from './reducers/products';
import { reducer as registerJob, State as RegisterJobState } from './reducers/register-job';
import { reducer as registerWarranty, State as RegisterWarrantyState } from './reducers/register-warranty';
import { reducer as RegisterWaterTester, State as RegisterWaterTesterState } from './reducers/register-water-tester';
import { reducer as registration, State as RegistrationState } from './reducers/registration';
import { reducer as resources, State as ResourcesState } from './reducers/resources';
import { reducer as rewards, State as RewardsState } from './reducers/rewards';
import { reducer as serviceRecord, State as ServiceRecordState } from './reducers/service-record';
import { reducer as warrantyList, State as WarrantyListState } from './reducers/warranty-list';
import { reducer as warrantyStats, State as WarrantyStatsState } from './reducers/warranty-stats';
import { reducer as onboardingQuestions, State as OnboardingQuestionsState } from './reducers/onboarding-questions';

/**
 * StoreState interface: contains the state for all reducers in the store
 */
export interface StoreState {
  adminEvents: AdminEventsState;
  adminJobs: AdminJobsState;
  adminPro3: AdminPro3State;
  adminResources: AdminResourcesState;
  adminRewards: AdminRewardsState;
  adminStats: AdminStatsState;
  adminUsers: AdminUsersState;
  businessTools: BusinessToolsState;
  contentPages: ContentPagesState;
  country: CountryState;
  diary: DiaryState;
  email: EmailState;
  feature: FeatureState;
  loginDetails: LoginDetailsState;
  popup: PopupState;
  postcodeLookup: PostcodeLookupState;
  pro3: Pro3State;
  products: ProductsState;
  registerJob: RegisterJobState;
  registerWarranty: RegisterWarrantyState;
  registration: RegistrationState;
  resources: ResourcesState;
  rewards: RewardsState;
  serviceRecord: ServiceRecordState;
  warrantyList: WarrantyListState;
  warrantyStats: WarrantyStatsState;
  ReviewInvitation: ReviewInvitationState;
  RegisterWaterTester: RegisterWaterTesterState;
  onboardingQuestions: OnboardingQuestionsState;
}

/**
 * ReducerState interface: contains all store keys together with their
 * associated ActionReducer
 */
export interface ReducerState {
  adminEvents: ActionReducer<AdminEventsState>;
  adminJobs: ActionReducer<AdminJobsState>;
  adminPro3: ActionReducer<AdminPro3State>;
  adminResources: ActionReducer<AdminResourcesState>;
  adminRewards: ActionReducer<AdminRewardsState>;
  adminStats: ActionReducer<AdminStatsState>;
  adminUsers: ActionReducer<AdminUsersState>;
  businessTools: ActionReducer<BusinessToolsState>;
  contentPages: ActionReducer<ContentPagesState>;
  country: ActionReducer<CountryState>;
  diary: ActionReducer<DiaryState>;
  email: ActionReducer<EmailState>;
  feature: ActionReducer<FeatureState>;
  loginDetails: ActionReducer<LoginDetailsState>;
  popup: ActionReducer<PopupState>;
  postcodeLookup: ActionReducer<PostcodeLookupState>;
  pro3: ActionReducer<Pro3State>;
  products: ActionReducer<ProductsState>;
  registerJob: ActionReducer<RegisterJobState>;
  registerWarranty: ActionReducer<RegisterWarrantyState>;
  registration: ActionReducer<RegistrationState>;
  resources: ActionReducer<ResourcesState>;
  rewards: ActionReducer<RewardsState>;
  serviceRecord: ActionReducer<ServiceRecordState>;
  warrantyList: ActionReducer<WarrantyListState>;
  warrantyStats: ActionReducer<WarrantyStatsState>;
  ReviewInvitation: ActionReducer<ReviewInvitationState>;
  RegisterWaterTester: ActionReducer<RegisterWaterTesterState>;
  onboardingQuestions: ActionReducer<OnboardingQuestionsState>;
  //ResultEmail:      ActionReducer<ResultEmailState>;
}

/**
 * Export the store (key: reducer) which is used to initialise the StoreModule
 * in AppModule
 */
export const store: ReducerState = {
  adminEvents,
  adminJobs,
  adminPro3,
  adminResources,
  adminRewards,
  adminStats,
  adminUsers,

  businessTools,
  country,
  contentPages,
  diary,
  email,
  feature,
  loginDetails,
  popup,
  postcodeLookup,
  pro3,
  products,
  registerJob,
  registerWarranty,
  registration,
  resources,
  rewards,
  serviceRecord,
  warrantyList,
  warrantyStats,
  ReviewInvitation,
  RegisterWaterTester,
  onboardingQuestions
  //ResultEmail,
};
