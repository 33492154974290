/**
 * register-job reducer: maintains state used during Job registration
 */
import {
  RegisterJobRequest,
  RegisterJobResponse,
  Job,
} from '../../models/job';

import * as actions from '../actions/register-job';

// State interface definition: maintains all state related to the Job
// registration and edit forms
export interface State {

  // Latest Job registration request and response
  currentRequest: RegisterJobRequest;
  currentResponse: RegisterJobResponse;

  // State of submission
  pending: boolean;
  error: string;
  success: boolean;
  message: string;

  // Current Job data (used when editing)
  jobData: Job;

  // PDF rendering state ("pdfData" is base64-encoded PDF file contents when
  // the PDF has been rendered)
  pdfPending: boolean;
  pdfError: string;
  pdfData: string;
}


const defaultState: State = {
  currentRequest: null,
  currentResponse: null,
  error: null,
  jobData: null,
  message: null,
  pdfData: null,
  pdfError: null,
  pdfPending: false,
  pending: false,
  success: false,
};


export function reducer(
  state: State = defaultState,
  action: actions.Actions
): State {
  switch (action.type) {
    /**
     * Resets the state ready for a new Job submission
     */
    case actions.REGISTER_JOB_INIT:
      return Object.assign({}, state, {
        currentRequest: null,
        currentResponse: null,
        pending: false,
        error: null,
        success: false,
        message: null,
        jobData: null,
      });

    /**
     * Request to register a new Job
     */
    case actions.REGISTER_JOB_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: true,
          error: null,
          currentRequest: action.payload,
          currentResponse: null,
          message: null,
        }
      );

    /**
     * Response from registering a new Job
     */
    case actions.REGISTER_JOB_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending: false,
          error: action.payload.error ? action.payload.error : null,
          currentResponse: action.payload,
          success: !action.payload.error,
          message: action.payload.message,
        }
      );

    /**
     * Partial reset of state used during Job editing/submission
     */
    case actions.INIT_JOB_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: false,
          jobData: null,
        }
      );

    /**
     * Request to render and return a PDF for a specified Job ID
     */
    case actions.FETCH_JOB_PDF_REQUEST:
      return Object.assign({}, state, { pdfPending: true, pdfError: null, pdfData: null });

    /**
     * Response from request to render a specified Job as a PDF. Upon success,
     * response contains base64-encoded PDF data.
     */
    case actions.FETCH_JOB_PDF_RESPONSE:
      return Object.assign({}, state, {
        pdfPending: false,
        pdfError: action.payload.error,
        pdfData: action.payload.pdfData,
      });

    /**
     * Request to fetch a specified Job model
     */
    case actions.FETCH_JOB_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: true,
          error: null,
          currentRequest: action.payload,
          currentResponse: null,
          message: null,
          jobData: null,
        }
      );

    /**
     * Response to fetching a specified Job model
     */
    case actions.FETCH_JOB_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending: false,
          error: action.payload.error ? action.payload.message : null,
          currentResponse: action.payload,
          jobData: action.payload.job,
        }
      );

    /**
     * Request to update a Job
     */
    case actions.UPDATE_JOB_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: true,
          error: null,
          currentRequest: action.payload,
          currentResponse: null,
          message: null,
        }
      );

    /**
     * Response from updating a Job
     */
    case actions.UPDATE_JOB_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending: false,
          error: action.payload.error ? action.payload.error : null,
          currentResponse: action.payload,
          success: !action.payload.error,
          message: action.payload.message,
        }
      );

    default:
      return state;
  }
}
