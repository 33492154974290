/**
 * ResourceService: provides all functionality related to handling Resources
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';




import { Injectable } from '@angular/core';


import { ApiService } from './api.service';

import {
  BooleanOperator,
  FetchResourceItemsRequest,
  FetchResourceItemsResponse,
  ResourceItem,
  ResourceItemSearchCriteria,
} from '../models/resources';


@Injectable()
export class ResourceService {

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Fetches ResourceItem models based on search criteria
   *
   * @param {FetchResourceItemsRequest} req
   * @return {Observable<FetchResourceItemsResponse>}
   */
  fetchResources(req: FetchResourceItemsRequest): Observable<FetchResourceItemsResponse> {
    return this.apiService.apiPost(
      '/search-resources',
      {
        page:     req.page,
        category: req.category,
        search:   ResourceItemSearchCriteria.toAPI(req.search),
      }
    ).pipe(
      map((res: any): FetchResourceItemsResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          error: valid ? null : 'Invalid response from server',
          items: res.resource.map(ResourceItem.fromAPI),
        };
      }),
      catchError((err: any): Observable<FetchResourceItemsResponse> =>
        observableOf({
          error: err && err.error && err.error.message
            ? `Unable to get resources: ${err.error.message}`
            : 'Unable to get resources',
          items: null,
        })
      ),);
  }

}
