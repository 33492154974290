<div [class]="'widget-body diary-widget border-box border-lighter-gray background-white col-xs-12 ' + additionalClasses">
  <app-diary-calendar
    [small]="true"
    [events]="events"
    [eventTypes]="eventTypes"
    [readOnly]="true"
  >
  </app-diary-calendar>
  <div class="widget-button-container">
    <button class="btn background-green color-white full-width no-radius" (click)="goToDiary()">Open diary</button>
  </div>
</div>
