import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchMonthlyWarrantyStatsResponse, FetchWarrantyFullStatsResponse } from '../../models/warranty';
import { WarrantyService } from '../../services/warranty.service';
import * as ActionTypes from '../actions/warranty-stats';

@Injectable()
export class WarrantyStatsEffects {
  constructor(
    private service: WarrantyService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchWarrantyFullStatsRequestAction, call
   * WarrantyService::fetchFullStats() and dispatch a new
   * FetchWarrantyFullStatsResponseAction with the response.
   */
   fetchWarrantieFullStats$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_WARRANTY_FULL_STATS_REQUEST),
    switchMap((req: ActionTypes.FetchWarrantyFullStatsRequestAction): Observable<ActionTypes.FetchWarrantyFullStatsResponseAction> => {
      return this.service.fetchFullStats().pipe(
        map((res: FetchWarrantyFullStatsResponse): ActionTypes.FetchWarrantyFullStatsResponseAction =>
          new ActionTypes.FetchWarrantyFullStatsResponseAction(res)
        ));
    })));

  /**
   * For a FetchWarrantyMonthlyStatsRequestAction, call
   * WarrantyService::fetchMonthlyWarrantyStats() and dispatch a new
   * FetchWarrantyMonthlyStatsResponseAction with the response.
   */
   fetchWarrantyMonthlyStats$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_WARRANTY_MONTHLY_STATS_REQUEST),
    switchMap(
      (req: ActionTypes.FetchWarrantyMonthlyStatsRequestAction): Observable<ActionTypes.FetchWarrantyMonthlyStatsResponseAction> => {
        return this.service.fetchMonthlyWarrantyStats(req.payload).pipe(
          map((res: FetchMonthlyWarrantyStatsResponse): ActionTypes.FetchWarrantyMonthlyStatsResponseAction =>
            new ActionTypes.FetchWarrantyMonthlyStatsResponseAction(res)
          ));
      }
    )));
}
