/**
 * registration reducer: maintains state used during user registration
 */
import { RegistrationRequest, RegistrationResponse } from '../../models/registration';
import * as actions from '../actions/registration';

// State interface definition: maintains all state related to User registration
export interface State {

  // Latest User registration request and response
  currentRequest: RegistrationRequest;
  currentResponse: RegistrationResponse;

  // State of registration
  pending: boolean;
  error: string;
  registerSuccess: boolean;
}


const defaultState: State = {
  currentRequest: null,
  currentResponse: null,
  pending: false,
  error: null,
  registerSuccess: false,
};


export function reducer(
  state: State = defaultState,
  action: actions.Actions
): State {
  switch (action.type) {
    /**
     * Clears the state ready for a new User registration
     */
    case actions.REGISTRATION_INIT:
      return Object.assign({}, state, {
        currentRequest: null,
        currentResponse: null,
        pending: false,
        error: null,
        registerSuccess: false,
      });

    /**
     * Request to register a new User
     */
    case actions.REGISTRATION_REQUEST:
    case actions.SIMPLE_REGISTRATION_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: true,
          error: null,
          currentRequest: action.payload,
          currentResponse: null,
        }
      );

    /**
     * Response from registering a new User
     */
    case actions.REGISTRATION_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending: false,
          error: action.payload.error ? action.payload.message : null,
          currentResponse: action.payload,
          registerSuccess: !action.payload.error && action.payload.userId,
        }
      );

    /**
     * Request to register a new User water test
     */
    case actions.REGISTRATION_WATER_TESTER_REQUEST:
      return Object.assign(
        {}, state,
        {
          pending: true,
          error: null,
          currentRequest: action.payload,
          currentResponse: null,
        }
      );

    /**
     * Response from registering a new User water tester
     */
    case actions.REGISTRATION_WATER_TESTER_RESPONSE:
      return Object.assign(
        {}, state,
        {
          pending: false,
          error: action.payload.error ? action.payload.message : null,
          currentResponse: action.payload,
          registerSuccess: !action.payload.error,
        }
      );

    default:
      return state;
  }
}
