import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Warranty } from '../models/warranty';

@Pipe({
  name: 'warrantyPoints',
  pure: true
})
export class WarrantyPointsPipe implements PipeTransform {

  transform(warranty: Warranty): number | string {
    try {
      const dateOfInstallation = moment(warranty.dateOfInstallation);
      const expirationDate = dateOfInstallation.add(1, 'y');

      if (expirationDate.unix() < moment().unix()) {
        return '<span class="expired"><strong>EXPIRED</strong></span>';
      }
      return warranty.pointsAwarded ? warranty.pointsAwarded : 0;
    } catch (error) {
      return 0;
    }
  }

}
