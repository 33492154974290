import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs';

import { saveAs } from 'file-saver/FileSaver';

/**
 * Summary
 *    Self-contained component to handle the download of files.
 *
 *
 * Description
 *    Display a button on the page that will trigger the download of a specified file.
 *    The file, file name and file type will be passed into the component.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {

  // The url of the existing file
  @Input() fileURL: string;
  // The type of the file to download
  @Input() fileType: string;
  // The name of the given file
  @Input() fileName: string;
  // The text to be displayed on the button
  @Input() btnText: string;
  // Extra classes to be added to the button
  @Input() btnClass: string;

  // If set, file will be downloaded asynchronously and provided for saving via
  // file-saver. Otherwise, a simple <a> tag will be generated allowing the
  // link to be opened in a new tab.
  @Input() downloadBlob: boolean = true;

  // store a reference to headers object
  private headers: any;
  // store a reference to RequestOptions object
  private options: any;
  // reference to generated file header string
  private fileHeader: string;

  /**
   * Constructor for page, initialises reference to http in order to enable downloads from the page
   *
   * @param {Http} http initialise reference to http object
   */
  constructor(public http: HttpClient)
  {
    // Set the fileHeader string dependant on the value of fileType
    switch (this.fileType)
    {
      case 'PDF':
        this.fileHeader = 'application/pdf';
      break;
      case 'TEXT':
        this.fileHeader = 'text/plain';
      break;
      case 'IMG_PNG':
        this.fileHeader = 'image/png';
      break;
      case 'IMG_JPG':
        this.fileHeader = 'image/jpg';
      break;
      default:
        this.fileHeader = this.fileType;
    }

    // Create a new HttpHeaders reference with the fileHeader value set
    this.headers = new HttpHeaders({ 'Accept': this.fileHeader });
    // Create a new RequestOptions reference with the headers created above and set the response content type to blob
    this.options = { headers: this.headers, responseType: 'blob'};
  }

  ngOnInit() {}

  /**
   * Make a request to retrieve the file from the given file url using the options defined in the constructor
   *
   * @return {Observable<any>} Returns an Observable reference to the http.get() response
   */
  getClient(): Observable<any> {
    return this.http.get(this.fileURL, this.options);
  }

  /**
   * Subscrible to the Observable reference to http.get() returned from getClient() and call convertFile() with the result
   */
  downloadFile() {
    this.getClient()
      .subscribe(data => {
        // Pass the data to convertFile in blob format
        this.convertFile(data.blob());
      });
  }

  /**
   * Create a new blob from the given data and prompt the browser to open the save as dialogue
   */
  convertFile(data: any) {
    // Create blob from given data
    const blob = new Blob([ data ], { type : this.fileHeader });
    // Prompt the browser to open the save as prompt
    saveAs(blob, this.fileName);
  }

}
