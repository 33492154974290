<div class="container-fluid admin-rewards-page">

  <div class="row content-page" *ngIf="adminRewards$ | async; let adminRewards">

    <div class="container-title">
      <h1 class="white">Rewards</h1>
    </div>

    <div class="row">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header collapsedHeight="73px" expandedHeight="73px">
            <mat-panel-title class="color-adey-red">
              Points promotion
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-xs-12 text-right promotion-button-wrapper">
            <input class="btn btn-default" type="button" value="Set up new points promotion"
              (click)="createPromotion()" />
          </div>
          <table *ngIf="promotions.length" datatable class="row-border hover">
            <thead>
              <tr>
                <th>Promotion name</th>
                <th>Reference</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Created by</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let promotion of promotions" (click)="viewPromotion(promotion)">
                <td>{{ promotion.name }}</td>
                <td>{{ promotion.reference }}</td>
                <td>{{ promotion.startDate | date:'dd/MM/yy' }}</td>
                <td>{{ promotion.endDate | date:'dd/MM/yy' }}</td>
                <td>{{ promotion.userName }}</td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <h2 class="color-adey-red">Search</h2>
        <form [formGroup]="fgSearch" novalidate (ngSubmit)="performSearch($event)">
          <div class="row">
            <div class="col-xs-12 col-sm-4">
              <mat-form-field>
                <input matInput formControlName="userName" placeholder="Name" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-4">
              <mat-form-field>
                <input matInput formControlName="userAddress" placeholder="Address" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-group">
            <input class="btn btn-default" type="submit" value="Search users" />
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="section-container background-white col-xs-12">
        <h2 class="color-adey-red">Points transfer</h2>
        <app-message-panel kind="ERROR" *ngIf="transferErrors">{{transferErrors}}</app-message-panel>
        <form [formGroup]="fgTransfer" novalidate (ngSubmit)="performTransfer($event)">
          <div class="row">
            <div class="col-xs-12 col-sm-3">
              <p *ngIf="!transferFrom">Please select a user from which to transfer points</p>
              <div *ngIf="transferFrom">
                <h4>Transfer from:</h4>
                <span class="name">
                  {{transferFrom.profile.title}} {{transferFrom.profile.firstName}} {{transferFrom.profile.lastName}}
                </span>
                <span class="points">
                  ({{transferFrom.points.earned - transferFrom.points.spent}} points available)
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-3">
              <p *ngIf="!transferTo">Please select a user to which to transfer points</p>
              <div class="transfer-details" *ngIf="transferTo">
                <h4>Transfer to:</h4>
                <span class="name">
                  {{transferTo.profile.title}} {{transferTo.profile.firstName}} {{transferTo.profile.lastName}}
                </span>
                <span class="points">
                  ({{transferTo.points.earned - transferTo.points.spent}} points available)
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-3">
              <mat-form-field>
                <input matInput formControlName="transferAmount" placeholder="Points to transfer" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-3">
              <input class="btn btn-default" type="submit" value="Transfer points" />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="adminRewards.error">
      <div class="section-container background-white col-xs-12">
        <app-message-panel kind="ERROR">{{adminRewards.error}}</app-message-panel>
      </div>
    </div>

    <div class="row" *ngIf="adminRewards.pending">
      <div class="section-container background-white col-xs-12">
        <app-waiting-spinner kind="bar">Loading users...</app-waiting-spinner>
      </div>
    </div>

    <div class="row" *ngIf="!adminRewards.pending && (!adminRewards.users || adminRewards.users.length === 0)">
      <div class="section-container background-white col-xs-12">
        <app-message-panel kind="INFO">No users to display</app-message-panel>
      </div>
    </div>

    <div class="row" *ngIf="!adminRewards.pending && (adminRewards.users && adminRewards.users.length > 0)">
      <div class="section-container background-white col-xs-12">
        <h2 class="color-adey-red">User list</h2>

        <div class="row pagination-wrapper">
          <div class="col-xs-12 text-center">
            <ul class="pagination">
              <!-- / Prev btns -->
              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="goToPage(1)">&lt;&lt; First</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev(5)">&lt;&lt;</a>
              </li>

              <li *ngIf="pageNum > 1" class="page-item">
                <a class="page-link" (click)="pagePrev()">&lt;</a>
              </li>
              <!-- / Prev btns -->

              <!-- Pagination -->
              <li *ngFor="let num of pagination" [class.active]="num === pageNum" class="page-item">
                <a class="page-link" (click)="goToPage(num)">{{ num }}</a>
              </li>
              <!-- / Pagination -->


              <!-- / Next btns -->
              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext()">&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="pageNext(5)">&gt;&gt;</a>
              </li>

              <li *ngIf="pageNum < totalPages" class="page-item">
                <a class="page-link" (click)="goToPage(totalPages)">Last &gt;&gt;</a>
              </li>
              <!-- / Next btns -->
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let user of adminRewards.users">
            <mat-card>
              <mat-card-header>
                <img mat-card-avatar [src]="user.profile.photoUrl" alt="Profile photo"
                  *ngIf="user.profile.photoUrl && !user.pending" />
                <app-waiting-spinner kind="spinner" *ngIf="user.pending"></app-waiting-spinner>
                <mat-card-title>
                  {{user.profile.title}} {{user.profile.firstName}} {{user.profile.lastName}}
                </mat-card-title>
                <mat-card-subtitle>
                  ID: {{user.id}}, points: {{user.points.earned}} earned, {{user.points.spent}} spent
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <strong *ngIf="user.admin">ADMIN</strong>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="editUser(user)">Edit</button>
                <button mat-button (click)="setTransferFrom(user)">Transfer from</button>
                <button mat-button (click)="setTransferTo(user)">Transfer to</button>
                <button mat-button (click)="viewUserPointsReport(user)">Points report</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>