
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CountryResponse } from '../models/country';

@Injectable()
export class CountryService {

  constructor(
    private apiService: ApiService,
  ) { }

  getCountries(): Observable<CountryResponse> {
    return this.apiService.apiGet('/countries').pipe(
      map((res: any): CountryResponse => {
        return {
          countries: res.resource.map(CountryResponse.fromApiData),
          error: null,
        };
      }),
      catchError((err: any): Observable<CountryResponse> => {
        return observableOf({
          countries: null,
          error: err.error.message,
        });
      }),);
  }
}
