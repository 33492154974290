import { Injectable } from '@angular/core';

import { UserPopup } from '../models/popup';
import { ApiService } from './api.service';

@Injectable()
export class PopupService {

  constructor(
    private apiService: ApiService,
  ) { }

  public async getUserPopup(): Promise<UserPopup[]> {
    return await this.apiService.apiGet<UserPopup[]>('/popup').toPromise();
  }

  public async setUserPopupDisplayCount(name: string, count: number): Promise<any> {
    return await this.apiService.apiPost(`/popup/${name}/${count}`, {}).toPromise();
  }
}
