import { Component, Input } from '@angular/core';

import { UserProfile } from '../../../models/user-profile';

/**
 * Summary
 *    Displays a UserProfile completion percentage using a determinate spinner
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-profile-percentage',
  templateUrl: './profile-percentage.component.html',
  styleUrls: ['./profile-percentage.component.scss']
})
export class ProfilePercentageComponent {

  // UserProfile for user
  @Input() userProfile;

  // Flag to indicate if component is being used as a widget
  @Input() isWidget: boolean = false;

  constructor() { }

  /**
   * Returns the completion percentage for the specified UserProfile
   *
   * @param {UserProfile} profileData
   * @return {number}
   */
  getProfilePercentage(profileData: UserProfile): number {
    return UserProfile.getProfileCompletePercentage(profileData);
  }
}
