import { Component, OnInit, Inject }          from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
} from 'angular-calendar';


import { markFormAsTouched } from '../../../utility';

/**
 * Summary
 *    Display the event form with empty fields or existing event data.
 *
 *
 * Description
 *    Display the time, date and descriptions for a given event or allow the user to create
 *    a new event.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-single-event',
  templateUrl: './diary-single-event.component.html',
  styleUrls: ['./diary-single-event.component.scss']
})
export class DiarySingleEventComponent implements OnInit {

  // Event edit form
  public eventForm: UntypedFormGroup;

  // Flag to indicate if the form has been submitted
  public submitted: boolean = false;

  // Set if event is a user (non-ADEY) event
  public userEvent: boolean = false;

  // Set if a new event is being added
  public newEvent: boolean = false;

  // If set, form will be read-only
  public readOnly: boolean = false;

  // If set, form will be tailored for admin users
  public adminMode: boolean = false;

  // List of hours and minutes for selects
  public hours:   string[] = [];
  public minutes: string[] = [];

  // Event start and end dates
  public startDate: string;
  public endDate:   string;

  /**
   * Constructor for page
   *
   * @param {MAT_DIALOG_DATA} data                              Contains reference to data passed into the modal when created
   * @param {MatDialogRef<DiarySingleEventComponent>} dialogRef Create a reference to this dialog
   * @param {FormBuilder} _fb                                   Initialise FormBuilder instance to construct form
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DiarySingleEventComponent>,
    private _fb: UntypedFormBuilder
  ) { }

  /**
   * Initialise the event form and set the appropriate flags based on the state of the event
   */
  ngOnInit() {
    // If an event exists in the data, set newEvent to false
    this.newEvent  = !this.data.event;
    // Determine whether the event has been created by a user
    this.userEvent = this.newEvent || this.data.event.userEvent;
    // Set the readOnly flag
    this.readOnly  = this.data.readOnly;
    // Set the adminMode flag
    this.adminMode = this.data.adminMode;
    // Set the startDate value to the default value
    this.startDate = this.getDefault('date', 'DATE');
    // Set the endDate value to the default value
    this.endDate   = this.getDefault('date', 'ENDDATE');

    // Set the userID
    const userId   = this.data.userId;
    // Determine whether the event should be disabled for editing
    const disabled = this.readOnly || (!this.userEvent && !this.adminMode);
    const allDayDisabled = (this.getDefault('allDay', 'BOOL') || disabled ? true : false);

    // Empty array for hours and minutes inputs
    this.hours = [];
    this.minutes = [];

    // Fill the hours and minutes arrays with the appropriate values
    for (let i = 0; i <= 23; i++)
      this.hours.push(i.toString().length === 1 ? '0' + i.toString() : i.toString());
    for (let i = 0; i <= 59; i++) {
      if (i % 5 === 0)
        this.minutes.push(i.toString().length === 1 ? '0' + i.toString() : i.toString());
    }

    // Create the event form, using getDefault() to determine whether the initial values are empty or set
    // to an existing event
    this.eventForm = this._fb.group({
      details:     [{value: this.getDefault('details'),     disabled}, [Validators.required]],
      description: [{value: this.getDefault('description'), disabled}],
      typeOfEvent: [{value: this.getDefault('typeOfEvent'), disabled}, [Validators.required]],
      location:    [{value: this.getDefault('location'),    disabled}],
      note:        [{value: this.getDefault('note'),        disabled}],
      merchant:    [{value: this.getDefault('merchant'),    disabled}],

      startdate:       [{value: this.getDefault('date', 'DATE'),   disabled}, [Validators.required]],
      startTimeHour:   [{value: this.getDefault('time', 'HOUR'),   disabled: allDayDisabled}, [Validators.required]],
      startTimeMinute: [{value: this.getDefault('time', 'MINUTE'), disabled: allDayDisabled}, [Validators.required]],

      endDate:       [{value: this.getDefault('date',     'ENDDATE'),   disabled: allDayDisabled}, [Validators.required]],
      endTimeHour:   [{value: this.getDefault('time',     'ENDHOUR'),   disabled: allDayDisabled}, [Validators.required]],
      endTimeMinute: [{value: this.getDefault('time',     'ENDMINUTE'), disabled: allDayDisabled}, [Validators.required]],
      duration:      [{value: this.getDefault('duration', 'DURATION'),  disabled}, [Validators.required]],
      allDay:        [{value: this.getDefault('allDay',   'BOOL'),      disabled}, [Validators.required]],

      userEvent: [
        {
          value: this.newEvent && userId ? true : this.getDefault('userEvent', 'BOOL'),
          disabled,
        },
      ],
      userId: [
        {
          value: this.newEvent && userId ? userId : this.getDefault('userId', 'STRING'),
          disabled,
        },
      ],
    });
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName    The name of the field to check
   * @param {string} extraClasses Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';

    // Add extra classes to the string if any were passed in via html
    if (extraClasses)
      classes += ` ${extraClasses}`;

    // Get a reference to the specific field
    const ff = this.eventForm.controls[fieldName];
    if (!ff)
      return classes;

    // Return a string of classes based on the form and the current field's state
    return `${classes} ${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? 'has-error' : ''}`;
  }

  /**
   * Called when the user submits the form
   */
  onSubmitForm() {
    this.submitted = true;

    // If the form is valid, manipulate the data so that it is ready to be uploaded to the site
    if (this.eventForm.valid)
    {
      // Format the start date string to the correct format
      const dateStr     = moment(this.startDate).format('YYYY-MM-DD');
      // Combine the start hour and minute values into a single string
      const dateTimeStr = moment(this.eventForm.value.startTimeHour + ':' + this.eventForm.value.startTimeMinute, 'HH:mm').format('HH:mm:ss');
      // Format the end date string to the correct format
      const endDateStr = moment(this.endDate).format('YYYY-MM-DD');
      // Combine the end hour and minute values into a single string
      const endTimeStr = moment(this.eventForm.value.endTimeHour + ':' + this.eventForm.value.endTimeMinute, 'HH:mm').format('HH:mm:ss');

      // Create a moment object of both the start and end times
      const start = moment(dateStr    + ' ' + dateTimeStr).utc();
      const end   = moment(endDateStr + ' ' + endTimeStr).utc();

      // Determine the duration of the event by calculating the difference between the two dates
      // If the event is an all day event, manually set the duration to 480
      let duration;
      if (this.eventForm.get('allDay').value === true)
        duration = moment.duration(moment(dateStr + ' 17:00:00').diff(moment(dateStr + ' 09:00:00')));
      else
        duration = moment.duration(end.diff(start));

      const timeStr = moment(dateStr + ' ' + (this.eventForm.get('allDay').value === true ? '09:00:00' : dateTimeStr)).format('HH:mm:ss');

      // Pass the event data back to the parent component and close the dialog
      this.dialogRef.close(
        Object.assign({}, this.eventForm.value, {
          id:        this.getDefault('id', 'ID'),
          date:      this.eventForm.get('allDay').value ? dateStr    : start.format('YYYY-MM-DD'),
          time:      this.eventForm.get('allDay').value ? '09:00:00' : start.format('HH:mm:ss'),
          duration:  this.eventForm.get('allDay').value ? 480        : duration.asMinutes(),
          userEvent: this.adminMode ? this.eventForm.value.userEvent : true,
          returnRef: 'SAVE'
        })
      );
    }
  }

  /**
   * Disable or enable fields when the allDay checkbox is clicked
   *
   * @param {any} e  event passed from the input
   */
  toggleAllDay(e: any) {
    if (e.checked) {
      this.eventForm.get('startTimeHour').disable();
      this.eventForm.get('startTimeMinute').disable();
      this.eventForm.get('endDate').disable();
      this.eventForm.get('endTimeHour').disable();
      this.eventForm.get('endTimeMinute').disable();
    }
    else {
      this.eventForm.get('startTimeHour').enable();
      this.eventForm.get('startTimeMinute').enable();
      this.eventForm.get('endDate').enable();
      this.eventForm.get('endTimeHour').enable();
      this.eventForm.get('endTimeMinute').enable();
    }
  }

  /**
   * Called when the startdate is changed, if the enddate is before
   * the startdate, the enddate value will be set to the startdate
   */
  startDateChanged() {
    const startdate = this.eventForm.controls['startdate'].value;
    const enddate = this.eventForm.controls['endDate'].value;

    if (moment(enddate).isBefore(startdate)) {
      this.eventForm.controls['endDate'].setValue(startdate);
    }
  }

  /**
   * Called when the delete button is clicked, will close the modal and pass the request to
   * the parent modal along with the event ID
   */
  deleteEvent() {
    this.dialogRef.close(
      Object.assign({}, this.eventForm.value, {
        id:        this.getDefault('id', 'ID'),
        returnRef: 'DELETE'
      })
    );
  }

  /**
   * Will return a value for each field type dependant on whether the
   * user is viewing an existing event or creating a new one.
   *
   * @param {string} field     The name of the field
   * @param {string} fieldType The type of field to set
   *
   * @return {any} Will return the value for the given field
   */
  getDefault(field: string, fieldType: string = 'STRING'): any {
    // Set the initial values of the fields if the user is creating a new event
    if (!(this.data && this.data.event))
    {
      switch (fieldType)
      {
        case 'BOOL':
          return false;

        case 'DATE':
        case 'ENDDATE':
          return (this.data.date ? moment(this.data.date) : moment()).toDate();

        case 'DURATION':
          return 60;

        case 'HOUR':
        case 'MINUTE':
        case 'ENDHOUR':
        case 'ENDMINUTE':
          return '00';

        default: // STRING
          return null;
      }
    }

    const e = this.data.event;
    let endData;

    // Set the field values based on the value of the existing event
    switch (fieldType)
    {
      case 'BOOL':
        return !!e[field];

      case 'DATE':
        return moment.utc(e[field]).local().toDate();

      case 'ENDDATE':
        endData = moment.utc(e.date + ' ' + e.time).local().add(parseInt(e.duration, 10), 'minute');
        return moment(endData).toDate();

      case 'ID':
        return e.id;

      case 'DURATION':
        return parseInt(e[field], 10);

      case 'HOUR':
        return moment.utc(e.date + ' ' + e.time).local().format('HH');

      case 'MINUTE':
        return moment.utc(e.date + ' ' + e.time).local().format('mm');

      case 'ENDHOUR':
        endData = moment.utc(e.date + ' ' + e.time).local().add(parseInt(e.duration, 10), 'minute');
        return moment(endData).format('HH');

       case 'ENDMINUTE':
        endData = moment.utc(e.date + ' ' + e.time).local().add(parseInt(e.duration, 10), 'minute');
        return moment(endData).format('mm');

      default: // STRING
        return e[field] ? e[field].toString() : null;
    }
  }

  /**
   * Automatically set the value of endDate to the value of startDate if changed
   *
   * @param {any} value The startDate value
   */
  onStartDateChange(value) {
    if (moment(this.endDate).unix() < moment(value).unix()) {
      this.endDate = value;
    }
  }

  /**
   * Automatically set the value of startDate if endDate is changed
   *
   * @param {any} value The endDate value
   */
  onEndDateChange(value) {
    if (moment(this.startDate).unix() > moment(value).unix()) {
      this.startDate = value;
    }
  }

  /**
   * Called when the user clicks the submit form, this function exists because
   * the submit button is placed outside of the form
   *
   * @param {any} form The form content
   */
  submitForm(form) {
    // Mark as touched so that validation can occur with manual submission
    markFormAsTouched(this.eventForm);
    //Emit a call to submit the form
    form.ngSubmit.emit();
  }
}
