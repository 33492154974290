/**
 * products reducer: maintains state for serial number validation on Job and
 * Warranty forms as well as the product selector system
 */

import { Action, ActionReducer } from '@ngrx/store';


import * as actions from '../actions/products';

import { Product } from '../../models/product';

import {
  ProductSelectQueryRequest,
} from '../../models/product-selector';


// State interface definition: maintains a list of product selector results as
// well as the query and serial number validation state
export interface State {

  // Pending flag and error/success message for the product selector
  pending: boolean;
  error:   string;
  message: string;

  // Current product selector query values
  currentQuery: ProductSelectQueryRequest;

  // List of Products returned in response to query for the product selector
  results: Product[];

  // Pending flag, validation result and response message used during serial
  // number validation
  serialCheckPending: boolean;
  serialCheckResult:  boolean;
  serialCheckMessage: string;
}


const defaultState: State = {
  currentQuery:       null,
  error:              null,
  message:            null,
  pending:            false,
  results:            [],
  serialCheckMessage: null,
  serialCheckPending: false,
  serialCheckResult:  null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to perform a new product selector query
     */
    case actions.PRODUCT_SELECT_QUERY_REQUEST:
      return Object.assign({}, state, {
        pending:      true,
        error:        null,
        currentQuery: action.payload,
      });

    /**
     * Response from a product selector query
     */
    case actions.PRODUCT_SELECT_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        results: action.payload.products,
      });

    /**
     * Request to check a specified serial number for a specified Product
     */
    case actions.PRODUCT_CHECK_SERIAL_REQUEST:
      return Object.assign({}, state, {
        serialCheckPending: true,
        error:              null,
        serialCheckResult:  null,
        serialCheckMessage: null,
      });

    /**
     * Response from a Product serial number check request
     */
    case actions.PRODUCT_CHECK_SERIAL_RESPONSE:
      return Object.assign({}, state, {
        serialCheckPending: false,
        serialCheckResult:  action.payload.valid,
        serialCheckMessage: action.payload.message,
      });

    default:
      return state;
  }
}
