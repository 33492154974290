/**
 * invitation reducer: maintains state
 */

import * as actions from '../actions/invitation';


export interface State {

  // Pending flag and error/success message
  pending:              boolean;
  error:                string;
  success:              string;
  message:              string;
  currentQuery:         string;
  results?:             any;
  invitation_accepted?: boolean;

}


const defaultState: State = {
  pending:             false,
  error:               null,
  success:             null,
  message:             null,
  currentQuery:        null,
  results:             null,
  invitation_accepted: false,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request Review invitation
     */
    case actions.REVIEW_INVITATION_QUERY_REQUEST:
      return Object.assign({}, state, {
        pending:      true,
        error:        null,
        message:      'Review invitation request...',
        currentQuery: action.payload,
      });

    /**
     * Response Review invitation
     */
    case actions.REVIEW_INVITATION_QUERY_RESPONSE:
      // console.log('Displaying data', action.payload.results, 'reducers/invitation.ts:52');
      return Object.assign({}, state, {
        pending:      false,
        error:        action.payload.valid,
        message:      !action.payload.valid ? 'Invalid Token.' : null,
        results:      action.payload.results,
      });

    /**
     * Request Accept invitation
     */
    case actions.ACCEPT_INVITATION_QUERY_REQUEST:
      return Object.assign({}, state, {
        pending:      true,
        error:        null,
        message:      'Accepting invitation...',
        currentQuery: action.payload,
      });

    /**
     * Response Accept invitation
     */
    case actions.ACCEPT_INVITATION_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        invitation_accepted: true,
        message:      action.payload.message,
        success:      action.payload.valid ? action.payload.message : null,
        error:        !action.payload.valid ? action.payload.message : null,
      });

    /**
     * Request send invite
     */
    case actions.SEND_INVITATION_QUERY_REQUEST:
      return Object.assign({}, state, {
        pending:      true,
        error:        null,
        success:      null,
        message:      'Sending invitation...',
        currentQuery: action.payload,
      });

    /**
     * Response send invite
     */
    case actions.SEND_INVITATION_QUERY_RESPONSE:
      return Object.assign({}, state, {
        pending:      false,
        message:      null,
        success:      action.payload.valid ? action.payload.message : null,
        error:        !action.payload.valid ? action.payload.message : null,
        results:      action.payload,
      });

    default:
      return state;
  }
}
