import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Job, RegisterJobRequest } from '../../models/job';
import { GetUserPopup } from '../../state-management/actions/popup';
import { RegisterJobRequestAction } from '../../state-management/actions/register-job';
import {
  CheckPromoCodeRequestAction,
  FetchProductTypesRequestAction,
} from '../../state-management/actions/register-warranty';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { State as RegisterJobState } from '../../state-management/reducers/register-job';
import { State as RegisterWarrantyState } from '../../state-management/reducers/register-warranty';
import { StoreState } from '../../state-management/store';

/**
 * Summary
 *    Page for warranty registration
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WarrantyComponent implements OnInit {

  // Store state
  public state$: Observable<RegisterWarrantyState>;
  public jobState$: Observable<RegisterJobState>;
  public loginDetails$: Observable<LoginDetailsState>;

  @ViewChild('warrantyContainer') warrantyContainer !: ElementRef;

  constructor(
    private router: Router,
    private store: Store<StoreState>,
  ) {
    this.state$ = this.store.select('registerWarranty');
    this.jobState$ = this.store.select('registerJob');
    this.loginDetails$ = this.store.select('loginDetails');
  }

  ngOnInit() {
    this.store.dispatch(new FetchProductTypesRequestAction());
    this.store.dispatch(new GetUserPopup());
  }

  /**
   * Navigates to the /warranty-list route
   */
  goToMyList() {
    this.router.navigate(['/warranty-list']);
  }

  /**
   * Navigates to the /warranty-statistics route
   */
  goToMyStatistics() {
    this.router.navigate(['/warranty-statistics']);
  }

  /**
   * Called via WarrantyDetailsComponent::onPromoCode() when a promotional code
   * needs to be checked
   *
   * @param {string} code
   */
  handlePromoCode(code: string) {
    this.store.dispatch(new CheckPromoCodeRequestAction(code));
  }

  /**
   * Called via WarrantyDetailsComponent::onSubmit() when a new Warranty is
   * being submitted (as a Job model)
   *
   * @param {Job} warranty
   */
  handleFormSubmit(warranty: Job) {
    if (window) {
      const el = this.warrantyContainer.nativeElement;
      const paddingTop = window.getComputedStyle(el).paddingTop.replace(/\D/g, '');
      window.scrollTo(0, this.warrantyContainer.nativeElement.offsetTop - Number(paddingTop));
    }

    this.store.dispatch(new RegisterJobRequestAction(RegisterJobRequest.fromJob(warranty)));
  }
}
