<div *ngIf="!proCheckView" class="container-fluid login-component">
  <div class="row background-beige">
    <div [class]="'col-xs-12 feature-container container' + (proCheckView ? ' feature-container-bg-blue':'')">
      <img *ngIf="!proCheckView" src="assets/i/common/logos/adey-pro-club-logo-white.png" alt="ADEY® ProClub Logo"
        class="logo" />
      <img *ngIf="proCheckView" src="assets/i/common/logos/adey-pro-check_white_logo_r.png" alt="ADEY ProCheck Logo"
        class="logo" />
      <img *ngIf="!proCheckView" class="feature-img transform-xy-offset-50" alt="ADEY® ProClub Member"
        src="assets/i/login/adey-pro-club-installer.png">

      <div *ngIf="!proCheckView" class="feature-content">
        <h1>Welcome To The ADEY<span>&reg;</span> ProClub<span>&trade;</span></h1>
        <div class="description-block">
          <p>Together we’re the real deal</p>
        </div>
      </div>

      <div *ngIf="proCheckView" class="feature-content">
        <h1>Welcome to ADEY ProCheck<span>&reg;</span></h1>
        <div [class]="'description-block'+(proCheckView? ' description-block-bg-pro-check': '')">
          <p>Revolutionising water testing</p>
        </div>
      </div>
    </div>

    <div class="login-container col-xs-12 background-beige">
      <div class="login-gradient-sidebar"></div>
      <div class="login-content">
        <img class="login-icon" alt="Profile Icon" src="assets/i/login/login-icon.jpg">

        <app-page-content page="LOGIN" field="intro"></app-page-content>

        <app-message-panel kind="ERROR" *ngIf="(loginDetails$|async).error">
          <ng-container
            *ngIf="!(loginDetails$|async)?.error?.includes('activated') && !(loginDetails$|async)?.error?.includes('Invalid') && !(loginDetails$|async)?.error?.includes('unsuccessful')">
            {{ (loginDetails$|async).error }}
          </ng-container>
          <!-- user's account is not activated -->
          <ng-container *ngIf="(loginDetails$|async)?.error?.includes('activated')">
            <p>Your account hasn't been activated.</p>
            <p>Click the link in your activation email to activate your account.</p>
            <p>Can't find the email? Send another one <a class="cursor-pointer" (click)="resendActivation()">here</a>.
            </p>
          </ng-container>
          <!-- invalid username or password -->
          <ng-container *ngIf="(loginDetails$|async)?.error?.includes('Invalid')">
            <p>Either the username or password you entered is incorrect. Please try again.</p>
            <p>Forgotten your password? Click <a class="cursor-pointer" (click)="goToForgotten()">here</a> to reset your
              password.</p>
          </ng-container>
          <!-- user does not exist -->
          <ng-container *ngIf="(loginDetails$|async)?.error?.includes('unsuccessful')">
            <p>We can't find your account, click <a class="cursor-pointer" (click)="goToRegister()">here</a> to get
              started, or contact customer services on <a href="mail:info@adey.com">info@adey.com</a>.</p>
          </ng-container>
        </app-message-panel>
        <app-message-panel kind="SUCCESS" *ngIf="(loginDetails$|async).activationMessage">
          {{(loginDetails$|async).activationMessage}}
        </app-message-panel>

        <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmitForm()">
          <div [class]="formGroupClass('username')">
            <input type="email" id="username" class="form-control" formControlName="username"
              placeholder="Email address">
          </div>

          <div [class]="formGroupClass('password')">
            <input type="password" id="password" class="form-control" formControlName="password" placeholder="Password">
          </div>

          <button type="submit" class="btn btn-full-width background-adey-red white-theme">
            <app-waiting-spinner colorType="primary" class="color-white hover-color-white" kind="spinner"
              *ngIf="(loginDetails$|async).pending"></app-waiting-spinner>
            <span class="color-white hover-color-white" *ngIf="!(loginDetails$|async).pending">Log in</span>
          </button>

        </form>
        <div class="sign-in-options">
          <p>Not signed up? <button class="btn btn-link no-pad red-brand " (click)="goToRegister()">Create a
              profile</button></p>
          <p>
            <button *ngIf="!proCheckView" class="btn btn-link no-pad red-brand" (click)="goToForgotten()">Forgotten
              password?</button>
            <button *ngIf="proCheckView" class="btn btn-link no-pad red-brand" (click)="goToForgotten()">Forgot
              password?</button>
          </p>
        </div>

        <div *ngIf="!proCheckView" class="content text-left">

          <!-- Promotional text -->
          <!-- <div *ngIf="displayPromotion" class="color-adey-red">
            <p style="font-size: 1.6em;"><strong>Scheduled Maintenance</strong></p>
            <p style="font-size: 1.1em;"><strong>We will be undergoing scheduled maintenance on Thursday 24th February
                and Friday 25th February, ahead of launching our new game changing filter. Look out for the new features
                that support this product within the ADEY ProClub App.</strong></p>
            <p style="font-size: 1.1em;"><strong>The ProClub App and desktop will be unavailable for the duration of the
                maintenance.</strong></p>
            <p style="font-size: 1.1em;"><strong>We apologise for any inconvenience this may cause. If you have any
                queries, please contact our Customer Services team on 01242 546717.</strong></p>
            <p style="font-size: 1.1em;"><strong>The launch of our exciting new product will include an introductory
                special offer to all ProClub members so keep your eyes peeled.</strong></p>
          </div> -->
          <!-- / Promotional text -->

          <p>The only place to activate your ADEY® product warranty. As a member of the ADEY ProClub™, you are able to
            register a warranty for every ADEY filter you install for your customers.</p>
          <p>This process is best practice to give you and your customer email confirmation of the warranty and
            automatic reminders for when it's time for an annual service.</p>
          <p>As an added bonus, for every <em>MagnaClean Professional2®</em> and <em>Micro2®</em> you install and
            register in the ADEY ProClub™, you are rewarded with points to spend on items of your choice – Love2Shop
            vouchers or ADEY merchandise.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="proCheckView" class="container-fluid">

  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">ADEY ProCheck&reg;</h1>
    </div>

    <div class="background-white col-xs-12">
      <h1 class="color-adey-red">Looking to review and resend your ADEY ProCheck® test results?</h1>
      <h2><strong>We've moved to the new <i>ADEY Sense&reg;</i> portal!</strong></h2>
      <a href="https://sense.adey.com/" target="_blank">
        <img alt="ADEY Sense logo" src="assets/i/common/logos/adey-sense-logo.svg">
      </a>
      <p>See all the properties you’ve tested in one place, view results, and send test certificates to any valid email
        address.</p>
      <p>You’ll even see which properties are due their next annual check!</p>
      <br>
      <p>Go to ADEY Sense and login using your ADEY ProCheck® or ADEY ProClub® details.</p>
      <br>
      <button (click)="goToSense()" mat-raised-button color="primary"><strong>OPEN ADEY SENSE</strong></button>
    </div>

  </div>
</div>