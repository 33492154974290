<div [class]="'border-box border-light-gray align-center ' + (isWidget ? 'widget' : 'page')">
  <mat-progress-spinner
    class="transform-x-offset-50 half-opacity"
    color="accent"
    mode="determinate"
    [strokeWidth]="3"
    [diameter]="isWidget ? 135 : 165"
    value="100"
  >
  </mat-progress-spinner>
  <mat-progress-spinner
    class="transform-x-offset-50"
    color="primary"
    mode="determinate"
    [strokeWidth]="3"
    [diameter]="isWidget ? 135 : 165"
    [value]="getProfilePercentage(userProfile)">
  </mat-progress-spinner>
  <div class="progress-info color-light-gray">
    <p>Profile is</p>
    <p class="percentage color-adey-red">{{getProfilePercentage(userProfile)}}%</p>
    <p>complete</p>
  </div>
</div>
