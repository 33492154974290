import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { RewardsResponse } from '../../models/rewards';
import { RewardsService } from '../../services/rewards.service';
import * as ActionTypes from '../actions/rewards';

@Injectable()
export class RewardsEffects {
  constructor(
    private rewardsService: RewardsService,
    private actions$: Actions
  ) { }

   installerHubVoucherRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.INSTALLER_HUB_VOUCHER_REQUEST),
    switchMap((req: ActionTypes.InstallerHubVoucherRequestAction): Observable<ActionTypes.InstallerHubVoucherResponseAction> => {
      return this.rewardsService.getInstallerHubVoucher(req.payload).pipe(
        map((res: RewardsResponse<string>): ActionTypes.InstallerHubVoucherResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.InstallerHubVoucherResponseAction(res);
        }));
    })));

   installerHubVoucherValuesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.INSTALLER_HUB_VOUCHER_VALUES_REQUEST),
    switchMap((): Observable<ActionTypes.InstallerHubVoucherValuesResponseAction> => {
      return this.rewardsService.getInstallerHubVoucherValues().pipe(
        map((res: RewardsResponse): ActionTypes.InstallerHubVoucherValuesResponseAction => new ActionTypes.InstallerHubVoucherValuesResponseAction(res)));
    })));

   sparesRewardsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SPARES_REWARDS_REQUEST),
    switchMap((req: ActionTypes.SparesRewardsRequestAction): Observable<ActionTypes.SparesRewardsResponseAction> => {
      return this.rewardsService.redeemSparesRewards(req.payload).pipe(
        map((res: RewardsResponse): ActionTypes.SparesRewardsResponseAction => {
          if (res && res.callback) {
            res.callback();
          }
          return new ActionTypes.SparesRewardsResponseAction(res);
        }));
    })));
}
