import { Action } from '@ngrx/store';
import { Feature, FeatureErrorResponse } from '../../models/feature';

export const GET_FEATURES = 'GET_FEATURES';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_FAILURE = 'GET_FEATURES_FAILURE';

export class GetFeatures implements Action {
  readonly type = GET_FEATURES;
}

export class GetFeaturesSuccess implements Action {
  readonly type = GET_FEATURES_SUCCESS;
  constructor(public payload: Feature[]) { }
}

export class GetFeaturesFailure implements Action {
  readonly type = GET_FEATURES_FAILURE;
  constructor(public error: FeatureErrorResponse) { }
}

export type Actions =
  | GetFeatures
  | GetFeaturesSuccess
  | GetFeaturesFailure;
