import { Action } from '@ngrx/store';

import { UserPopup, UserPopupSetDisplayCountRequest } from '../../models/popup';

export const GET_USER_POPUP = 'GET_USER_POPUP';
export const GET_USER_POPUP_SUCCESS = 'GET_USER_POPUP_SUCCESS';
export const GET_USER_POPUP_FAILURE = 'GET_USER_POPUP_FAILURE';
export const SET_USER_POPUP_DISPLAY_COUNT = 'SET_USER_POPUP_DISPLAY_COUNT';
export const SET_USER_POPUP_DISPLAY_COUNT_SUCCESS = 'SET_USER_POPUP_DISPLAY_COUNT_SUCCESS';
export const SET_USER_POPUP_DISPLAY_COUNT_FAILURE = 'SET_USER_POPUP_DISPLAY_COUNT_FAILURE';

export class GetUserPopup implements Action {
  readonly type = GET_USER_POPUP;
}

export class GetUserPopupSuccess implements Action {
  readonly type = GET_USER_POPUP_SUCCESS;
  constructor(public payload: UserPopup[]) { }
}

export class GetUserPopupFailure implements Action {
  readonly type = GET_USER_POPUP_FAILURE;
  constructor(public error: any) { }
}

export class SetUserPopupDisplayCount implements Action {
  readonly type = SET_USER_POPUP_DISPLAY_COUNT;
  constructor(public request: UserPopupSetDisplayCountRequest) { }
}

export class SetUserPopupDisplayCountSuccess implements Action {
  readonly type = SET_USER_POPUP_DISPLAY_COUNT_SUCCESS;
}

export class SetUserPopupDisplayCountFailure implements Action {
  readonly type = SET_USER_POPUP_DISPLAY_COUNT_FAILURE;
  constructor(public error: any) { }
}

export type Actions =
  | GetUserPopup
  | GetUserPopupSuccess
  | GetUserPopupFailure
  | SetUserPopupDisplayCount
  | SetUserPopupDisplayCountSuccess
  | SetUserPopupDisplayCountFailure;
