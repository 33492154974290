<div class="container-fluid voucher-page">
  <div class="row content-page">

    <div class="container-title">
      <h1 class="white">Spares credit</h1>
    </div>

    <div class="voucher-container background-white col-xs-12">
      <div class="voucher-content col-xs-12" *ngIf="loginDetails$ | async; let loginDetails">

        <app-waiting-spinner kind="bar" *ngIf="(rewards$ | async).pending">Transferring points, please wait...
        </app-waiting-spinner>
        <app-message-panel kind="ERROR" *ngIf="(rewards$ | async).error">
          Points transfer was not successful. Please ensure your email address is correct and try again.
        </app-message-panel>

        <app-waiting-spinner kind="bar" *ngIf="loginDetails.pending">Loading form, please wait...</app-waiting-spinner>
        <div class="contact-form-container align-center" *ngIf="!loginDetails.pending">
          <h1 class="color-adey-red medium">Want to redeem your points?</h1>
          <h2 class="color-dark-gray">Simply enter your ADEY&reg; Spares account email address below to transfer your
            points to the ADEY&reg; Spares online store.
          </h2>

          <form [formGroup]="fg" class="contact-form align-left" novalidate (ngSubmit)="submitForm()">
            <div [class]="formGroupClass('email', 'col-sm-6 col-xs-12')">
              <label class="control-label">ADEY&reg; Spares account email <span class="color-adey-red">*</span></label>
              <input type="text" class="form-control" formControlName="email" />
            </div>
            <div class="col-xs-12">
              <p>If you do not have an ADEY&reg; Spares account yet, you can register today <a
                  href="https://www.adeyspares.co.uk/register" target="_blank">here</a>.</p>
            </div>
            <hr class="col-xs-12" />
            <div class="col-xs-12">
              <h3 class="color-adey-red">
                Points available: {{loginDetails.currentProfile.pointsCurrent}}
              </h3>
              <div [class]="formGroupClass('points')">
                <label class="control-label">Available transfers (1 point = <span>£</span>1)
                  <span class="color-adey-red">*</span></label>
                <input type="number" class="form-control" formControlName="points" />
              </div>
            </div>
            <div class="col-xs-12">
              <input class="btn full-width background-adey-red color-white" type="submit" value="Submit"
                [disabled]="(loginDetails.currentProfile.pointsCurrent < fg.value.points) ||(rewards$ | async).pending" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
