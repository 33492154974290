/**
 * postcode-lookup reducer: maintains state for the postcode and address lookup
 * system used in various forms
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  AddressListItem,
  FullAddressItem,
} from '../../models/postcode-lookup';

import * as actions from '../actions/postcode-lookup';


// State interface definition: maintains a list of AddressListItem as a result
// of a postcode lookup as well as a FullAddressItem as a response to selecting
// a single address.
export interface State {

  // Full address record obtained from API when selecting a single matched
  // address from a postcode lookup, as well as error message and pending flag
  address:        FullAddressItem;
  errorAddress:   string;
  pendingAddress: boolean;

  // List of partial address results obtained from the API during a single
  // postcode lookup, as well as error message and pending flag
  results:         AddressListItem[];
  errorPostcode:   string;
  pendingPostcode: boolean;

  // Current postcode lookup query
  query: string;
}


const defaultState: State = {
  address:         null,
  errorAddress:    null,
  errorPostcode:   null,
  pendingAddress:  false,
  pendingPostcode: false,
  query:           null,
  results:         null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Clears the state ready for a new lookup request
     */
    case actions.INIT_POSTCODE_LOOKUP:
      return Object.assign({}, state, defaultState);

    /**
     * Request to obtain a full address record from a returned partial address
     * returned during a postcode lookup
     */
    case actions.GET_POSTCODE_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        address:        null,
        errorAddress:   null,
        pendingAddress: true,
      });

    /**
     * Response from fetching a full address record
     */
    case actions.GET_POSTCODE_ADDRESS_RESPONSE:
      return Object.assign({}, state, {
        address:        action.payload.address,
        errorAddress:   action.payload.error,
        pendingAddress: false,
      });

    /**
     * Request to lookup a postcode
     */
    case actions.POSTCODE_LOOKUP_REQUEST:
      return Object.assign({}, state, {
        errorPostcode:   null,
        pendingPostcode: true,
        query:           action.payload.postcode,
        results:         null,
      });

    /**
     * Response from a postcode lookup: returns a list of AddressListItem upon
     * success which can then be used to do a full address lookup
     */
    case actions.POSTCODE_LOOKUP_RESPONSE:
      return Object.assign({}, state, {
        errorPostcode:   action.payload.error,
        pendingPostcode: false,
        results:         action.payload.results,
      });

    default:
      return state;
  }
}
