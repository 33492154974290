/**
 * service-record reducer: maintains state for the service record dashboard
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  ServiceRecord,
  ServiceRecordStats,
} from '../../models/service-record';

import * as actions from '../actions/service-record';


// State interface definition: maintains a paginated list of ServiceRecord
// models as well as ServiceRecordStats for display in the dashboard
export interface State {

  // User's ServiceRecord models as well as pending flag and error/success
  // messages
  records: ServiceRecord[];
  pending: boolean;
  error:   string;
  message: string;

  // Statistics from the API and pending flag
  stats:        ServiceRecordStats;
  pendingStats: boolean;

  // Pagination
  perPage:     number;
  currentPage: number;
  totalPages:  number;
  upcoming:    boolean;

  // Current customer query (filtering of service records by customer name)
  customerQuery: string;
}


const defaultState: State = {
  currentPage:   1,
  customerQuery: null,
  error:         null,
  message:       null,
  pending:       false,
  pendingStats:  false,
  perPage:       10,
  upcoming:      false,
  records:       [],
  stats:         null,
  totalPages:    1,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch latest service records statistics
     */
    case actions.FETCH_SERVICE_RECORD_STATS_REQUEST:
      return Object.assign({}, state, {
        pendingStats:  true,
      });

    /**
     * Response from request to fetch latest service records statistics
     */
    case actions.FETCH_SERVICE_RECORD_STATS_RESPONSE:
      return Object.assign({}, state, {
        pendingStats: false,
        error:        action.payload.error,
        stats:        action.payload.stats,
      });

    /**
     * Request to fetch all service records, optionally filtered by customer
     * name query
     */
    case actions.FETCH_SERVICE_RECORDS_REQUEST:
      return Object.assign({}, state, {
        pending:       true,
        error:         null,
        perPage:       action.payload.perPage,
        totalPages:    1,
        customerQuery: action.payload.customerQuery,
        currentPage: action.payload.pageNo,
      });

    /**
     * Response from fetching service records
     */
    case actions.FETCH_SERVICE_RECORDS_RESPONSE:
      return Object.assign({}, state, {
        pending:    false,
        error:      action.payload.error,
        records:    action.payload.records,
        totalPages: action.payload.totalPages,
      });

    /**
     * Loads the next page of results
     */
    case actions.NEXT_SERVICE_RECORD_PAGE:
      return Object.assign({}, state, {
        currentPage: state.currentPage + 1 <= state.totalPages
          ? state.currentPage + 1
          : state.currentPage,
      });


    /**
     * Loads the results for the page, 5 pages next of the current
     */
    case actions.NEXT_FIVE_SERVICE_RECORD_PAGE:
      return Object.assign({}, state, {
        currentPage: state.currentPage + 5 <= state.totalPages
          ? state.currentPage + 5
          : state.totalPages,
      });

    /**
     * Loads the previous page of results
     */
    case actions.PREV_SERVICE_RECORD_PAGE:
      return Object.assign({}, state, {
        currentPage: state.currentPage - 1 > 0
          ? state.currentPage - 1
          : state.currentPage,
      });

    /**
     * Loads the results for the page, 5 pages prev of the current
     */
    case actions.PREV_FIVE_SERVICE_RECORD_PAGE:
      return Object.assign({}, state, {
        currentPage: state.currentPage - 5 > 0
          ? state.currentPage - 5
          : 1,
      });

    /**
     * Request to set one or mode flags for a service record
     */
    case actions.SET_SERVICE_RECORD_FLAG_REQUEST:
      return Object.assign({}, state, {
        records: state.records.map((sr: ServiceRecord): ServiceRecord =>
          sr.id === action.payload.record.id
            ? Object.assign({}, sr, {
              confirmed: false,
              flags:     Object.assign({}, sr.flags, action.payload.flags),
            })
            : sr
        ),
      });

    /**
     * Response from setting one or more service record flags
     */
    case actions.SET_SERVICE_RECORD_FLAG_RESPONSE:
      return Object.assign({}, state, {
        records: state.records.map((sr: ServiceRecord): ServiceRecord =>
          sr.id === action.payload.record.id
            ? Object.assign({}, sr, {
              confirmed: true,
              flags:     Object.assign({}, sr.flags, action.payload.flags),
            })
            : sr
        ),
      });

    /**
     * Request to update an existing service record
     */
    case actions.UPDATE_SERVICE_RECORD_REQUEST:
      return Object.assign({}, state, {
        records: state.records.map((sr: ServiceRecord): ServiceRecord =>
          sr.id === action.payload.record.id
            ? Object.assign({}, sr, action.payload.record, {confirmed: false})
            : sr
        ),
      });

    /**
     * Response from updating an existing service record
     */
    case actions.UPDATE_SERVICE_RECORD_RESPONSE:
      return Object.assign({}, state, {
        records: state.records.map((sr: ServiceRecord): ServiceRecord =>
          sr.id === action.payload.record.id
            ? Object.assign({}, sr, action.payload.record, {confirmed: true})
            : sr
        ),
      });

    default:
      return state;
  }
}
