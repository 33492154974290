/**
 * ProductSelectorService: provides all functionality related to the product
 * selector system
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';




import { Injectable } from '@angular/core';


import { ApiService } from './api.service';

import { Product } from '../models/product';

import { ProductSelectorService as MockService } from './product-selector.service.mock';

import {
  ProductSelectQueryRequest,
  ProductSelectQueryResponse,
} from '../models/product-selector';

import {
  ProductCheckSerialRequest,
  ProductCheckSerialResponse,
} from '../models/product';


@Injectable()
export class ProductSelectorService {

  // Mock version of the service used to provide mock functionality where
  // necessary
  private mockService;


  constructor(private apiService: ApiService) {
    this.mockService = new MockService();
  }


  /**
   * Performs a product selector query
   *
   * @param {ProductSelectQueryRequest} q
   * @return {Observable<ProductSelectQueryResponse>}
   */
  query(q: ProductSelectQueryRequest): Observable<ProductSelectQueryResponse> {

    return this.apiService.apiPost(
      '/product-selector',
      {
        system_type:   q.systemType,
        type_of_work:  q.typeOfWork,
        pipework:      q.pipework,
        pipework_size: q.pipeworkSize ? q.pipeworkSize : 'null',
        system_filter: q.systemFilter ? q.systemFilter : 'no_filter',
      }
    ).pipe(

      map((res: any): ProductSelectQueryResponse => {
        const valid: boolean = res && res.resource && Array.isArray(res.resource);
        return {
          products: valid ? res.resource.map(Product.getProductsFromApi) : [],
          error:    valid ? null : 'Invalid response from server',
        };
      }),

      catchError((err: any): Observable<ProductSelectQueryResponse> => {
        return observableOf({
          products: [],
          error: err && err.error && err.error.message
            ? `Unable to fetch products: ${err.error.message}`
            : 'Unable to fetch products',
        });
      }),);
  }

  /**
   * Checks the validity of a serial number for a specified product
   *
   * @param {ProductCheckSerialRequest} q
   * @return {Observable<ProductCheckSerialResponse>}
   */
  checkSerial(q: ProductCheckSerialRequest): Observable<ProductCheckSerialResponse> {
    return this.apiService.apiPost('/check-serial-number', {product_id: parseInt(q.productId, 10), serial_number: q.serialNumber}).pipe(
      map((res: any): ProductCheckSerialResponse => {
        return {
          valid:   res.valid,
          message: res.valid ? null : (res.message ? res.message : 'Invalid serial number'),
        };
      }),
      catchError((err: any): Observable<ProductCheckSerialResponse> =>
        observableOf({
          valid:   false,
          message: err && err.error && err.error.message
            ? err.error.message
            : 'Invalid serial number',
        })
      ),);
  }

}
