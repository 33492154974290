import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Router } from '@angular/router';

import { DiaryEvent, EventType } from '../../../models/diary';

/**
 * Summary
 *    Displays a diary view within a widget.
 *    
 *
 * Description
 *    Display the diary component within a smaller widget, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-widget',
  templateUrl: './diary-widget.component.html',
  styleUrls: ['./diary-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiaryWidgetComponent implements OnInit {
  // The list of events to display
  @Input() events: DiaryEvent[] = [];
  // Additional classes to be added to the widget
  @Input() additionalClasses: string = '';
  // Available event types
  @Input() eventTypes: EventType[] = [];

  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  
  /**
   * Navigate the user to the diary page
   */
  goToDiary() {
    this.router.navigate(['/diary']);
  }

}
