/**
 * admin-stats actions: defines all actions relating to the admin dashboard.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AdminStats,
  FetchAdminStatsRequest,
  FetchAdminStatsResponse,
  FetchAdminStatsDownloadRequest,
  FetchAdminStatsDownloadResponse,
} from '../../models/admin-stats';


// Action type definitions
export const FETCH_ADMIN_STATS_REQUEST           = 'FETCH_ADMIN_STATS_REQUEST';
export const FETCH_ADMIN_STATS_RESPONSE          = 'FETCH_ADMIN_STATS_RESPONSE';
export const FETCH_ADMIN_STATS_DOWNLOAD_REQUEST  = 'FETCH_ADMIN_STATS_DOWNLOAD_REQUEST';
export const FETCH_ADMIN_STATS_DOWNLOAD_RESPONSE = 'FETCH_ADMIN_STATS_DOWNLOAD_RESPONSE';


// Action definitions
export class FetchAdminStatsRequestAction implements Action {
  readonly type = FETCH_ADMIN_STATS_REQUEST;
  constructor(public payload: FetchAdminStatsRequest) {}
}

export class FetchAdminStatsResponseAction implements Action {
  readonly type = FETCH_ADMIN_STATS_RESPONSE;
  constructor(public payload: FetchAdminStatsResponse) {}
}

export class FetchAdminStatsDownloadRequestAction implements Action {
  readonly type = FETCH_ADMIN_STATS_DOWNLOAD_REQUEST;
  constructor(public payload: FetchAdminStatsDownloadRequest) {}
}

export class FetchAdminStatsDownloadResponseAction implements Action {
  readonly type = FETCH_ADMIN_STATS_DOWNLOAD_RESPONSE;
  constructor(public payload: FetchAdminStatsDownloadResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | FetchAdminStatsRequestAction
  | FetchAdminStatsResponseAction
  | FetchAdminStatsDownloadRequestAction
  | FetchAdminStatsDownloadResponseAction;
