import { Customer } from './customer';

export class CustomerSuggestion {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    postCode: string;

    /**
     * Returns an example model.
     *
     * @returns {UserSuggestion}
     */
    static getExample(): CustomerSuggestion {
        const c: Customer = Customer.getExample();
        return {
            id:         Math.floor(Math.random() * 1000).toString(),
            title:      c.customerTitle,
            firstName:  c.customerForename,
            lastName:   c.customerSurname,
            postCode:   c.customerPostcode,
        };
    }

    /**
     * Creates instance from API data model
     *
     * @param data
     * @returns {UserSuggestion}
     */
    static fromAPI(data: any): CustomerSuggestion {
        return {
            id:         data.id,
            title:      data.title,
            firstName:  data.first_name,
            lastName:   data.surname,
            postCode:   data.postcode,
        };
    }
}

export interface FetchCustomerSuggestionRequest {
    query: string;
    field?: string;
}

export interface FetchCustomerSuggestionResponse {
    query: string;
    error: string;
    customers: CustomerSuggestion[];
}
