import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

import { Router } from '@angular/router';

import {
  UserProfile,
  AccreditationItem
} from '../../../models/user-profile';

/**
 * Summary
 *    Displays profile photo, name, membership length, membership level and
 *    link to edit profile
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent {

  // Current UserProfile model
  @Input() userProfile: UserProfile;

  // List of available accreditations
  @Input() accreditations: AccreditationItem[] = [];

  // If set, component is being used from within a homepage widget and should
  // render accordingly
  @Input() isWidget: boolean = false;

  // Called when the profile needs to be edited
  @Output() editCallback = new EventEmitter<any>();


  constructor(
    private router: Router,
  ) { }

  /**
   * Returns a duration from the specified date until the present
   *
   * @param {any} createdAt Starting date
   * @return {string} Formatted duration
   */
  getMembershipLength(createdAt: any): string {
    return moment(createdAt).fromNow(true);
  }

  /**
   * Emits the current profile and accreditations to this.editCallback in order
   * to initiate editing of a profile
   *
   * @param {UserProfile}         p Current user profile model
   * @param {AccreditationItem[]} accreditations
   */
  editProfile(p: UserProfile, accreditations: AccreditationItem[]) {
    const data = {
      profile: p,
      accreditations
    };

    this.editCallback.emit(data);
  }

  /**
   * Navigates to the specified route
   *
   * @param {string} url
   */
  goToPage(url: string) {
    this.router.navigate([url]);
  }
}
