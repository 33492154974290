/**
 * postcode-lookup models
 */

/**
 * Encapsulates a single suggested address resulting from a postcode lookup
 */
export class AddressListItem {
  id:          string;
  type:        string;
  text:        string;
  highlight:   string;
  description: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {AddressListItem}
   */
  static fromAPI(data: any): AddressListItem {
    return {
      id:          data.Id,
      type:        data.Type,
      text:        data.Text,
      highlight:   data.Highlight,
      description: data.Description,
    };
  }
}

/**
 * Encapsulates a single full address which is obtained using the ID of an
 * AddressListItem
 */
export class FullAddressItem {
  address1: string;
  address2: string;
  town:     string;
  county:   string;
  postcode: string;
  country_code: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {FullAddressItem}
   */
  static fromAPI(data: any): FullAddressItem {
    return {
      address1: data.Line1,
      address2: (data.Line2 ? `${data.Line2} ` : '') + (data.Line3 ? `${data.Line3} ` : '') + (data.Line4 ? `${data.Line4} ` : ''),
      town:     data.City,
      county:   data.ProvinceName,
      postcode: data.PostalCode,
      country_code: data.CountryIso2
    };
  }
}


export interface GetPostcodeAddressRequest {
  id:        string;
  callback?: Function;
}

export interface GetPostcodeAddressResponse {
  error:     string;
  address:   FullAddressItem;
  callback?: Function;
}

export interface PostcodeLookupRequest {
  postcode:  string;
  callback?: Function;
}

export interface PostcodeLookupResponse {
  error:     string;
  results:   AddressListItem[];
  callback?: Function;
}


/**
 * Returns a Boolean flag which indicates if the specified postcode is valid
 * based on regex provided by UK government.
 *
 * Ref: https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf).
 *
 * @param {string} input Postcode to check
 * @return {boolean}
 */
export function CheckValidPostcode(input: string): boolean {
  const ukPostCode = !!(
    input
      .replace(' ', '')
      .match(/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/i)
  );

  const eireCode = !!(
    input
      .replace(' ', '')
      .match(/(a(4[125]|6[37]|75|8[1-6]|9[12468])|c15|d([0o])[1-9]|1[0-8o]|2[024o]|6w|e(2[15]|3[24]|4[15]|53|91)|f(12|2[368]|3[15]|4[25]|5[26]|9[1-4])|h(1[2468]|23|5[34]|6[25]|[79]1)|k(3[246]|45|56|67|78)|n(3[79]|[49]1)|p(1[247]|2[45]|3[126]|4[37]|5[16]|6[17]|7[25]|8[15])|r(14|21|3[25]|4[25]|5[16]|9[35])|t(12|23|34|45|56)|v(1[45]|23|3[15]|42|9[2-5])|w(12|23|34|91)|x(35|42|91)|y(14|2[15]|3[45]))\s*[acdefhknoprtvwxy\d]{4}/ig)
  );
  return (ukPostCode || eireCode);
}
