import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import * as moment from 'moment';


import { DiaryEvent } from '../../../models/diary';


/**
 * Summary
 *    List upcoming events on the page.
 *    
 *
 * Description
 *    Display a list upcoming events on the page.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-list-events',
  templateUrl: './diary-list-events.component.html',
  styleUrls: ['./diary-list-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DiaryListEventsComponent implements OnChanges, OnInit {

  // List of events to display
  @Input() events: DiaryEvent[] = [];

  // Optional filters
  @Input() filterEventType: string  = null;
  @Input() filterUpcoming:  boolean = false;

  // If non-zero, limits number of events displayed
  @Input() perPage: number = 0;

  // Fired when an event is clicked
  @Output() onSelectEvent = new EventEmitter<DiaryEvent>();

  // If set, events will be paginated
  @Input() pagination: boolean = true;


  // Event list after filtering
  public filteredEvents: DiaryEvent[] = [];

  // Filtered event list after pagination
  public pageEvents: DiaryEvent[];

  // Current page number (for pagination)
  public pageNumber: number = 1;

  // Number of events to display on the page, increased in loadMore()
  public displayPerPage: number;

  // Multiplier for loadMore() (perPage * scrollValue)
  public scrollValue: number = 1;


  /**
   * Called when the onChange event is triggered internally
   */
  ngOnChanges() {
    // Update the displayPerPage value and call processEvents()
    
    this.displayPerPage = this.perPage;
    this.processEvents();
  }

  ngOnInit() {
  }


  /**
   * Called when an event is clicked, will emit the event data to the parent component
   *
   * @param {DiaryEvent} event Data for the selected event
   */
  onEventClick(event: DiaryEvent): void {
    this.onSelectEvent.emit(event);
  }

  /**
   * Called when pagination is active, will allow the user to cycle forwards through events
   */
  pageNext() {
    if (this.perPage <= 0)
      return;

    if (this.pageNumber >= Math.ceil(this.filteredEvents.length / this.perPage))
      return;

    this.pageNumber += 1;
    this.processEvents();
  }

  /**
   * Called when pagination is active, will allow the user to cycle backwards through events
   */
  pagePrev() {
    if (this.perPage <= 0)
      return;

    if (this.pageNumber <= 1)
    {
      this.pageNumber = 1;
      return;
    }

    this.pageNumber -= 1;
    this.processEvents();
  }

  /**
   * Filter events based on the type of event and whether they are upcoming or passed, once complete sort the events
   * by their date
   */
  processEvents() {
    this.filteredEvents = this.events
      .filter((v: DiaryEvent): boolean =>
        this.filterEventType
          ? v.typeOfEvent === this.filterEventType
          : true
      )
      .filter((v: DiaryEvent): boolean =>
        this.filterUpcoming
          ? moment.utc(v.date + ' ' + v.time).diff(moment.utc()) > 0
          : true
      )
      .sort((a: DiaryEvent, b: DiaryEvent): number => {
        const dateA = moment.utc(a.date + ' ' + a.time);
        const dateB = moment.utc(b.date + ' ' + b.time);
        return dateA.diff(dateB);
      });

    // If pagination is active only display a certain number of events based on the current page.
    // else - If infinite scroll is active only display a certain number of events based on the current scroll value.
    if (this.pagination) {
      if (this.perPage > 0)
        this.pageEvents = this.filteredEvents.slice(
          this.perPage * Math.max(this.pageNumber - 1, 0),
          Math.min(this.perPage * this.pageNumber, this.filteredEvents.length)
        );
      else
        this.pageEvents = this.filteredEvents;
    }
    
    else {
      if (this.displayPerPage > 0)
        this.pageEvents = this.filteredEvents.slice(0, this.displayPerPage);
      else
        this.pageEvents = this.filteredEvents;
    }
  }
  
  /**
   * Called when infinite scroll is active, will display events based on the current scroll value
   */
  loadMore() {
    this.scrollValue += 1;
    this.displayPerPage = this.scrollValue * this.perPage;
    this.processEvents();
  }
}
