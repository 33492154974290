import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

/**
 * Summary
 *    Overwrite methods within angular-calendar
 *    
 *
 * Description
 *    Overwrite angular-calendar functions in order to enhance or edit the way in which
 *    data is displayed
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // TODO: add explicit constructor

  
  /**
   * Overwrite the column header for the month view so that it displays a short date
   *
   * @param {date, local}  pass in the given date and local for each header
   *
   * @return {string}  return a new localised string for each column header
   */
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }

  /**
   * Overwrite the date string for the month view so that it displays in the correct format
   *
   * @param {date, local}  pass in the given date and local for the current month
   *
   * @return {string}  return a new localised string for the month
   */
  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      month: 'long',
      year:  'numeric',
    }).format(date);
  }
  
  /**
   * Overwrite the date string for the week view so that it displays in the correct format
   *
   * @param {date, local}  pass in the given date and local for the current week
   *
   * @return {string}  return a new localised string the week
   */
  public weekViewTitle({ date, locale }: DateFormatterParams): string {
    const weekStart: string = moment(date).utc().startOf('week').format('Do MMM YY');
    const weekEnd: string = moment(date).utc().endOf('week').format('Do MMM YY');
    return `${weekStart} - ${weekEnd}`;
  }

  /**
   * Overwrite the number string within each day for the month view
   *
   * @param {date, local}  pass in the given date and local for each day
   *
   * @return {string}  return a new localised string for the day
   */
  public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
    if (moment(date).isSame(moment(date).startOf('month')))
      return moment(date).format('D MMM');
    else
      return moment(date).format('D');
  }
}
