/**
 * admin-resources reducer: maintains state for the resource management admin
 * CMS.
 */
import { ResourceItem } from '../../models/resources';
import * as actions from '../actions/admin-resources';

// State interface definition: manages a paginated list of ResourceItem models
// for a single page ("pageName").
export interface State {
  pending: boolean;
  error: string;
  pageName: string;
  resources: ResourceItem[];
  pageNum: number;
  totalPages: number;
}


const defaultState: State = {
  pending: false,
  error: null,
  pageName: null,
  resources: [],
  pageNum: 1,
  totalPages: 0,
};


export function reducer(
  state: State = defaultState,
  action: actions.Actions,
): State {
  switch (action.type) {
    /**
     * Request to add a new Resource
     */
    case actions.ADD_ADMIN_RESOURCE_REQUEST:
      return Object.assign({}, state, { error: null });

    case actions.ADD_ADMIN_RESOURCE_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        resources: action.payload.error
          ? state.resources
          : [...state.resources, Object.assign({}, action.payload.resource, { confirmed: true })],
      });

    /**
     * Request to delete a Resource
     */
    case actions.DELETE_ADMIN_RESOURCE_REQUEST:
      return Object.assign({}, state, {
        error: null,
        resources: state.resources.map((r: ResourceItem): ResourceItem =>
          r.id === action.payload.resource.id
            ? Object.assign({}, r, { confirmed: false })
            : r
        ),
      });

    /**
     * Response from deleting a Resource
     */
    case actions.DELETE_ADMIN_RESOURCE_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        resources: action.payload.error
          ? state.resources
          : state.resources.filter((r: ResourceItem): boolean => r.id !== action.payload.id)
      });

    /**
     * Request to fetch a page of Resources for a specified page (filter)
     */
    case actions.FETCH_ADMIN_RESOURCES_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error: null,
        pageName: action.payload.pageName,
        resources: [],
        pageNum: Math.max(1, action.payload.pageNum),
        totalPages: 0,
      });

    /**
     * Response from fetching a page of Resources
     */
    case actions.FETCH_ADMIN_RESOURCES_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        resources: action.payload.error ? state.resources : action.payload.resources,
        totalPages: action.payload.error ? 0 : action.payload.totalPages,
      });

    /**
     * Request to update an existing Resource
     */
    case actions.UPDATE_ADMIN_RESOURCE_REQUEST:
      return Object.assign({}, state, {
        error: null,
        resources: state.resources.map((r: ResourceItem): ResourceItem =>
          r.id === action.payload.resource.id
            ? Object.assign({}, r, { confirmed: false })
            : r
        ),
      });

    /**
     * Response from updating an existing Resource
     */
    case actions.UPDATE_ADMIN_RESOURCE_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        resources: action.payload.error
          ? state.resources
          : state.resources.map((r: ResourceItem): ResourceItem =>
            r.id === action.payload.resource.id ? action.payload.resource : r
          ),
      });

    default:
      return state;
  }
}
