import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';

import {
  ActivateUserResponse,
  CreatePromoShopTokenResponse,
  FetchUserProfileResponse,
  LoadLoginTokenResponse,
  LoginResponse,
  RequestResetPasswordResponse,
  ResetPasswordResponse,
  TokenCheckResponse,
  WaterTesterRegisterResponse,
} from '../../models/login';
import { NavItems } from '../../models/nav-items';
import { ProCheckSite } from '../../models/procheck-site';
import { FetchAccreditationsResponse, UpdateUserProfileResponse } from '../../models/user-profile';
import { LoginService } from '../../services/login.service';
import { GetFeatures } from '../actions/feature';
import * as ActionTypes from '../actions/login-details';
import { selectHasOutstandingOnboardingQuestions } from '../selectors/onboarding-questions';
import { StoreState } from '../store';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private navItems: NavItems,
    private router: Router,
    private store: Store<StoreState>,
    private translate: TranslateService
  ) {}

  /**
   * For an ActivateUserRequestAction, call LoginService::activateUser() and
   * dispatch a new ActivateUserResponseAction with the response.
   */
  activateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.ACTIVATE_USER_REQUEST),
      switchMap(
        (
          req: ActionTypes.ActivateUserRequestAction
        ): Observable<ActionTypes.ActivateUserResponseAction> =>
          this.loginService
            .activateUser(req.payload)
            .pipe(
              map(
                (
                  res: ActivateUserResponse
                ): ActionTypes.ActivateUserResponseAction =>
                  new ActionTypes.ActivateUserResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For an ActivateUserResponseAction, redirect the user to /login. Do not
   * dispatch any further actions.
   */
  activateUserResponse$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.ACTIVATE_USER_RESPONSE),
        map((res: ActionTypes.ActivateUserResponseAction): void => {
          if (!res.payload.error && !res.payload.homeowner)
            this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  /**
   * For a CheckLoginTokenRequestAction, call LoginService::checkToken() and
   * dispatch a new CheckLoginTokenResponseAction with the response.
   */
  checkToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.CHECK_LOGIN_TOKEN_REQUEST),
      concatMap(
        (req: ActionTypes.CheckLoginTokenRequestAction): Observable<Action> =>
          this.loginService.checkToken().pipe(
            map((res: TokenCheckResponse): TokenCheckResponse => {
              const u = res.user;
              const proCheck = ProCheckSite.getCheck();

              if (u && !u.onboarding_question_answers) {
                // User hasn't completed onboarding, redirect them to the onboarding page.
                this.router.navigate(['/onboarding']);
              } else if (u && u.optIns.length === 0 && !proCheck) {
                // User hasn't completed their optins, redirect them to the optins page.
                this.router.navigate(['/optin']);
              } else if (res.valid && req.payload.redirectPage) {
                // Redirect client for other page if token is valid
                // requested
                this.router.navigate([req.payload.redirectPage]);
              } else {
                // Redirect client if token is invalid and if redirection has been
                // requested
                if (!res.valid && req.payload.redirectClientInvalid) {
                  this.router.navigate([req.payload.redirectClientInvalid]);
                }

                // Redirect client if token is valid and if redirection has been
                // requested (based on user type and confirmed flag)
                if (res.valid && req.payload.redirectClientValid) {
                  this.router.navigate([
                    this.navItems.getDefaultRouteForUser(res.user),
                  ]);
                }
              }
              return res;
            }),
            map(
              (
                res: TokenCheckResponse
              ): ActionTypes.CheckLoginTokenResponseAction =>
                new ActionTypes.CheckLoginTokenResponseAction(res)
            )
          )
      )
    )
  );

  /**
   * For a CheckLoginTokenResponseAction, dispatch a
   * FetchUserProfileRequestAction to get the latest user profile if the
   * response was valid.
   */

  checkTokenResponse$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.CHECK_LOGIN_TOKEN_RESPONSE),
      filter(
        (res: ActionTypes.CheckLoginTokenResponseAction): boolean =>
          res.payload.valid
      ),
      map((): ActionTypes.FetchUserProfileRequestAction => {
        this.store.dispatch(new GetFeatures());
        return new ActionTypes.FetchUserProfileRequestAction();
      })
    )
  );

  /**
   * For a CreatePromoShopTokenRequestAction, call
   * LoginService::createPromoShopToken() and dispatch a new
   * CreatePromoShopTokenResponseAction with the response.
   */
  createPromoShopToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.CREATE_PROMOSHOP_TOKEN_REQUEST),
      switchMap(
        (
          req: ActionTypes.CreatePromoShopTokenRequestAction
        ): Observable<ActionTypes.CreatePromoShopTokenResponseAction> =>
          this.loginService
            .createPromoShopToken()
            .pipe(
              map(
                (res: CreatePromoShopTokenResponse) =>
                  new ActionTypes.CreatePromoShopTokenResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For a FetchUserProfileRequestAction, call LoginService::fetchProfile() and
   * dispatch a new FetchUserProfileResponseAction with the response.
   */
  fetchProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_USER_PROFILE_REQUEST),
      concatMap(
        (
          _req: ActionTypes.FetchUserProfileRequestAction
        ): Observable<ActionTypes.FetchUserProfileResponseAction> =>
          this.loginService.fetchProfile().pipe(
            map((res: FetchUserProfileResponse) => {
              this.translate.use(
                res.profile.countries_by_country_id.country_code
              );

              return new ActionTypes.FetchUserProfileResponseAction(res);
            })
          )
      )
    )
  );

  /**
   * For a LoadLoginTokenRequestAction, call LoginService::loadToken() and
   * dispatch a new LoadLoginTokenResponseAction with the response.
   */
  loadToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.LOAD_LOGIN_TOKEN_REQUEST),
      switchMap(
        (): Observable<Action> =>
          this.loginService
            .loadToken()
            .pipe(
              map(
                (
                  res: LoadLoginTokenResponse
                ): ActionTypes.LoadLoginTokenResponseAction =>
                  new ActionTypes.LoadLoginTokenResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For a LoginRequestAction, call LoginService::login() and dispatch a new
   * LoginResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  loginRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.LOGIN_REQUEST),
      switchMap(
        (
          req: ActionTypes.LoginRequestAction
        ): Observable<ActionTypes.LoginResponseAction> =>
          this.loginService.login(req.payload).pipe(
            map((res: LoginResponse): ActionTypes.LoginResponseAction => {
              if (res.profile)
                this.translate.use(
                  res.profile.countries_by_country_id.country_code
                );
              // Handle redirects if login was successful
              const u = res.user;
              const proCheck = ProCheckSite.getCheck();

              const hasOutstandingQuestions$ = this.store.pipe(
                select(selectHasOutstandingOnboardingQuestions)
              );
              let hasOutstandingQuestions: boolean = true;

              // If login is successful, redirect
              if (u) {
                hasOutstandingQuestions$.subscribe((has) => {
                  hasOutstandingQuestions = has;

                  if (hasOutstandingQuestions) {
                    this.router.navigate(['/onboarding']);
                  }

                  if (u.optIns.length === 0 && !proCheck) {
                    this.router.navigate(['/optin']);
                  }

                  // Redirect client for other page if logged successfully
                  if (req.payload.redirectPage) {
                    this.router.navigate([req.payload.redirectPage]);
                  }

                  this.router.navigate([
                    this.navItems.getDefaultRouteForUser(u),
                  ]);
                });
              }

              return new ActionTypes.LoginResponseAction(res);
            })
          )
      )
    )
  );

  /**
   * Resend user account activation email
   * Password reset models are used here as they are identical
   */
  resendActivationEmailRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.RESEND_ACTIVATION_EMAIL_REQUEST),
      switchMap(
        (
          req: ActionTypes.ResendActivationEmailRequestAction
        ): Observable<ActionTypes.ResendActivationEmailResponseAction> =>
          this.loginService.resendActivationEmail(req.payload).pipe(
            map(
              (
                res: RequestResetPasswordResponse
              ): ActionTypes.ResendActivationEmailResponseAction => {
                return new ActionTypes.ResendActivationEmailResponseAction(res);
              }
            )
          )
      )
    )
  );

  /**
   * For a LoginRequestAction, call LoginService::login() and dispatch a new
   * LoginResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  loginExistingRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.LOGIN_EXISTING_REQUEST),
      switchMap(
        (
          req: ActionTypes.LoginExistingRequestAction
        ): Observable<ActionTypes.LoginResponseAction> =>
          this.loginService.loginExisting(req.payload).pipe(
            map((res: LoginResponse): ActionTypes.LoginResponseAction => {
              this.router.navigate([
                this.navItems.getDefaultRouteForUser(res.user),
              ]);
              return new ActionTypes.LoginResponseAction(res);
            })
          )
      )
    )
  );

  /**
   * For a LogoutAction, call LoginService::logout() and redirect the client to
   * "/". Do not dispatch any further actions.
   */
  logout$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.LOGOUT),
        map((a: ActionTypes.LogoutAction): void => {
          this.loginService.logout();
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  /**
   * For a RequestResetPasswordRequestAction, call
   * LoginService::requestResetPassword() and dispatch a new
   * RequestResetPasswordResponseAction with the response.
   */
  requestResetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.REQUEST_RESET_PASSWORD_REQUEST),
      switchMap(
        (
          req: ActionTypes.RequestResetPasswordRequestAction
        ): Observable<Action> =>
          this.loginService
            .requestResetPassword(req.payload)
            .pipe(
              map(
                (
                  res: RequestResetPasswordResponse
                ): ActionTypes.RequestResetPasswordResponseAction =>
                  new ActionTypes.RequestResetPasswordResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For a ResetPasswordRequestAction, call LoginService::resetPassword() and
   * dispatch a new ResetPasswordResponseAction with the response.
   */
  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.RESET_PASSWORD_REQUEST),
      switchMap(
        (req: ActionTypes.ResetPasswordRequestAction): Observable<Action> =>
          this.loginService
            .resetPassword(req.payload)
            .pipe(
              map(
                (
                  res: ResetPasswordResponse
                ): ActionTypes.ResetPasswordResponseAction =>
                  new ActionTypes.ResetPasswordResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For an UpdateUserProfileRequestAction, call
   * LoginService::updateUserProfile() and dispatch a new
   * UpdateUserProfileResponseAction with the response.
   */
  updateProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.UPDATE_USER_PROFILE_REQUEST),
      switchMap(
        (
          req: ActionTypes.UpdateUserProfileRequestAction
        ): Observable<ActionTypes.UpdateUserProfileResponseAction> =>
          this.loginService
            .updateUserProfile(req.payload)
            .pipe(
              map(
                (
                  res: UpdateUserProfileResponse
                ): ActionTypes.UpdateUserProfileResponseAction =>
                  new ActionTypes.UpdateUserProfileResponseAction(res)
              )
            )
      )
    )
  );

  /**
   * For a FetchAccreditationsRequestAction, call LoginService::fetchAccreditations() and
   * dispatch a new FetchAccreditationsResponseAction with the response.
   */
  fetchAccreditations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST),
      switchMap(
        (): Observable<Action> =>
          this.loginService
            .fetchAccreditations()
            .pipe(
              map(
                (
                  res: FetchAccreditationsResponse
                ): ActionTypes.FetchAccreditationsResponseAction =>
                  new ActionTypes.FetchAccreditationsResponseAction(res)
              )
            )
      )
    )
  );

  loginWaterTester$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.LOGIN_WATER_TESTER_REQUEST),
      concatMap(
        (
          req: ActionTypes.LoginWaterTesterRequestAction
        ): Observable<ActionTypes.LoginWaterTesterResponseAction> =>
          this.loginService.loginWaterTester(req.payload).pipe(
            map(
              (
                res: LoginResponse
              ): ActionTypes.LoginWaterTesterResponseAction => {
                return new ActionTypes.LoginWaterTesterResponseAction(res);
              }
            )
          )
      )
    )
  );
  /**
   * For a LoginWaterTesterRequestAction, call LoginService::loginWaterTester() and dispatch a new
   * LoginWaterTesterResponseAction with the response. Also store the user's token if the
   * response was valid.
   */
  registerWaterTester$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.REGISTER_WATER_TESTER_REQUEST),
      concatMap(
        (
          req: ActionTypes.RegisterWaterTesterRequestAction
        ): Observable<ActionTypes.RegisterWaterTesterResponseAction> =>
          this.loginService.registerWaterTester(req.payload).pipe(
            map(
              (
                res: WaterTesterRegisterResponse
              ): ActionTypes.RegisterWaterTesterResponseAction => {
                return new ActionTypes.RegisterWaterTesterResponseAction(res);
              }
            )
          )
      )
    )
  );
}
