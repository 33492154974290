/**
 * admin-users reducer: maintains state for the user management admin CMS.
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  AdminUser,
} from '../../models/admin-users';

import {
  AccreditationItem,
} from '../../models/user-profile';

import {
  UserRole,
} from '../../models/registration';

import * as adminActions        from '../actions/admin-users';
import * as registrationActions from '../actions/registration';


// State interface definition: maintains a list of AdminUser models as well as
// related models (AccreditationItem and UserRole) used when editing an
// AdminUser model.
export interface State {

  accreditations:        AccreditationItem[];
  accreditationsPending: boolean;

  // Status flags and messages
  pending: boolean;
  error:   string;
  message: string;

  // Pagination
  pageNum:    number;
  totalPages: number;

  roles: UserRole[];
  users: AdminUser[];
}


const defaultState: State = {
  accreditations:        null,
  accreditationsPending: false,
  error:                 null,
  message:               null,
  pageNum:               1,
  pending:               false,
  roles:                 [],
  totalPages:            0,
  users:                 [],
};


export function reducer(
  state:  State = defaultState,
  action: adminActions.Actions | registrationActions.Actions,
): State {
  switch (action.type)
  {
    /**
     * Request to add a new user
     */
    case adminActions.ADD_ADMIN_USER_REQUEST:
      return Object.assign({}, state, {
        error: null,
        users: [...state.users, Object.assign({}, action.payload.user, {pending: true})],
      });

    /**
     * Response from adding a new user
     */
    case adminActions.ADD_ADMIN_USER_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        users: action.payload.error
          ? state.users
          : state.users.map((u: AdminUser): AdminUser =>
            !u.id && u.pending
              ? Object.assign({}, action.payload.user, {pending: false})
              : u
          ),
      });

    /**
     * Request to delete a user
     */
    case adminActions.DELETE_ADMIN_USER_REQUEST:
      return Object.assign({}, state, {
        error: null,
        users: state.users.map((u: AdminUser): AdminUser =>
          u.id === action.payload.user.id
            ? Object.assign({}, u, {pending: true})
            : u
        ),
      });

    /**
     * Response from deleting a user
     */
    case adminActions.DELETE_ADMIN_USER_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        users: action.payload.error
          ? state.users
          : state.users.filter((u: AdminUser): boolean => u.id !== action.payload.user.id),
      });

    /**
     * Request to fetch all UserRoles
     */
    case registrationActions.FETCH_USER_ROLES_REQUEST:
      return Object.assign({}, state, {
        roles: [],
        error: null,
      });

    /**
     * Response from fetching all UserRoles
     */
    case registrationActions.FETCH_USER_ROLES_RESPONSE:
      return Object.assign({}, state, {
        roles: action.payload.roles,
        error: action.payload.error,
      });

    /**
     * Request to fetch a page of AdminUsers
     */
    case adminActions.FETCH_ADMIN_USERS_REQUEST:
      return Object.assign({}, state, {
        users:      [],
        pending:    true,
        error:      null,
        pageNum:    Math.max(1, action.payload.page),
        totalPages: 0,
      });

    /**
     * Response from fetching a page of AdminUsers
     */
    case adminActions.FETCH_ADMIN_USERS_RESPONSE:
      return Object.assign({}, state, {
        pending:    false,
        error:      action.payload.error,
        users:      action.payload.error ? state.users : action.payload.users,
        totalPages: action.payload.error ? 0           : action.payload.totalPages,
      });

    /**
     * Request to update an existing user
     */
    case adminActions.UPDATE_ADMIN_USER_REQUEST:
      return Object.assign({}, state, {
        error: null,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.user.id)
            return Object.assign({}, u, {pending: true});
          else
            return u;
        }),
      });

    /**
     * Response from updating an existing user
     */
    case adminActions.UPDATE_ADMIN_USER_RESPONSE:
      return Object.assign({}, state, {
        error: action.payload.error,
        users: state.users.map((u: AdminUser): AdminUser => {
          if (u.id === action.payload.user.id)
            return Object.assign({}, u, {pending: false}, action.payload.user);
          else
            return u;
        }),
      });

    /**
     * Request to fetch all AccreditationItems
     */
    case adminActions.FETCH_ACCREDITATIONS_REQUEST:
        return Object.assign({}, state, {
          accreditationsPending:        true,
          accreditations: null,
          error:          null,
        });

    /**
     * Response from fetching all AccreditationItems
     */
    case adminActions.FETCH_ACCREDITATIONS_RESPONSE:
      return Object.assign({}, state, {
        accreditationsPending:        false,
        accreditations: action.payload.accreditations,
        error:          action.payload.error,
      });

    default:
      return state;
  }
}
