/**
 * Shared Module
 *
 * TypeScript
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { WaitingSpinnerComponent } from './waiting-spinner/waiting-spinner.component';

@NgModule({
  imports: [
    CommonModule,

    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    WaitingSpinnerComponent,
  ],
  exports: [
    WaitingSpinnerComponent,
  ]
})
export class SharedModule { }
