/**
 * diary reducer: maintains state for the user diary and events
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  DiaryEvent,
  EventType,
} from '../../models/diary';

import * as actions from '../actions/diary';


// State interface definition: maintains a list of DiaryEvent models related to
// the user (user's own and ADEY events) as well as a list of EventType models
// used when adding/editing events.
export interface State {
  events:     DiaryEvent[];
  eventTypes: EventType[];
  pending:    boolean;
  error:      string;
  message:    string;
}


const defaultState: State = {
  events:     [],
  eventTypes: [],
  pending:    false,
  error:      null,
  message:    null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Adds a new DiaryEvent
     */
    case actions.ADD_EVENT:
      return Object.assign({}, state, {events: [...state.events, action.payload]});

    /**
     * Requests a booking of a DiaryEvent (currently unusued, future
     * development)
     */
    case actions.BOOK_EVENT:
      return Object.assign({}, state, {
        events: state.events.map((v: DiaryEvent) =>
          v.id === action.payload
            ? Object.assign({}, v, {booked: true})
            : v
        ),
        pending: true,
        error:   null,
      });


    /**
     * Dispatched when changes to a specified DiaryEvent (by ID) have been
     * pushed to the API
     */
    case actions.COMMIT_EVENT:
      if (action.payload.error)
        return Object.assign({}, state, {
          error: action.payload.error
        });
      else
        return Object.assign({}, state, {
          pending: false,
          error:   action.payload.error,
          events:  action.payload.error
            ? state.events
            : state.events

              // Mark DiaryEvent as committed by ID. If payload contains a
              // newId field then update the existing DiaryEvent ID to match.
              .map((v: DiaryEvent) =>
                v.id === action.payload.id
                  ? Object.assign({}, v, {committed: true, id: action.payload.newId ? action.payload.newId : v.id})
                  : v
              )

              // Remove all DiaryEvent models that are both deleted and committed
              .filter((v: DiaryEvent) => !(v.committed && v.deleted))
        });


    /**
     * Deletes an existing DiaryEvent
     */
    case actions.DELETE_EVENT:
      return Object.assign({}, state, {
        events: state.events.map((v: DiaryEvent) =>
          v.id === action.payload
            ? Object.assign({}, v, {committed: false, deleted: true})
            : v
        ),
        pending: true,
        error:   null,
      });

    /**
     * Request to fetch all DiaryEvents related to the user
     */
    case actions.FETCH_EVENTS_REQUEST:
      return Object.assign({}, state, {pending: true});

    /**
     * Response from fetching all DiaryEvents
     */
    case actions.FETCH_EVENTS_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        events:  action.payload.error ? state.events : action.payload.items,
      });

    /**
     * Request to fetch all EventTypes
     */
    case actions.FETCH_EVENT_TYPES_REQUEST:
      return Object.assign({}, state, {pending: true});

    /**
     * Response from fetching all EventTypes
     */
    case actions.FETCH_EVENT_TYPES_RESPONSE:
      return Object.assign({}, state, {
        pending:    false,
        error:      action.payload.error,
        eventTypes: action.payload.error ? state.eventTypes : action.payload.types,
      });

    /**
     * Updates an existing DiaryEvent
     */
    case actions.UPDATE_EVENT:
      return Object.assign({}, state, {
        events: state.events.map((v: DiaryEvent) =>
          v.id === action.payload.id
            ? Object.assign({}, action.payload, {committed: false})
            : v
        ),
        pending: true,
        error:   null,
      });

    default:
      return state;
  }
}
