<div class="container-fluid warranty-page">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">Register warranty</h1>
      <div *ngIf="loginDetails$ | async; let loginDetails" class="btn-group pull-right">
        <button class="btn background-adey-red color-white" (click)="goToMyList()">My warranties</button>
        <button class="btn background-adey-red color-white" (click)="goToMyStatistics()">Warranty statistics</button>
      </div>
    </div>

    <app-page-content page="WARRANTY_SUBMIT" field="intro" class="intro-above-content color-white"></app-page-content>

    <div #warrantyContainer class="warranty-container background-white col-xs-12" *ngIf="state$ | async; let state">

      <ng-container *ngIf="jobState$ | async; let job">
        <app-waiting-spinner class="form-header" kind="bar" *ngIf="job.pending">
          Registering warranty, please wait...
          <hr />
        </app-waiting-spinner>

        <app-message-panel class="form-header" kind="ERROR" *ngIf="job.error">
          {{ job.message }}
        </app-message-panel>

        <app-message-panel class="form-header" kind="SUCCESS" *ngIf="job.success">
          Warranty registered successfully
        </app-message-panel>
      </ng-container>

      <app-warranty-details [productTypes]="state.products" submitLabel="Register" [promoCode]="state.promoCode"
        [register]="true" (onPromoCode)="handlePromoCode($event)" (onSubmit)="handleFormSubmit($event)">
        <p class="product-list" *ngIf="state.productsPending">Loading products...</p>
      </app-warranty-details>

    </div>

  </div>
</div>