import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

/**
 * Summary
 *    Displays a link to the product selector page.
 *    
 *
 * Description
 *    Displays a link to the product selector page, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-product-widget',
  templateUrl: './product-widget.component.html',
  styleUrls: ['./product-widget.component.scss']
})
export class ProductWidgetComponent implements OnInit {

  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  
  /**
   * Navigate the user to a given page
   *
   * @param {string} pageRef The page chosen to navigate to
   */
  goToPage(pageRef: string)
  {
    this.router.navigate([pageRef]);
  }
}
