/**
 * service-record models
 */

import * as moment from 'moment';


import { Warranty } from './warranty';
import { Job }      from './job';
import { Customer } from './customer';


/**
 * Encapsulates a simple type requiring only a serial number
 */
export class SerialFields {
  serialNumber: string;
}

/**
 * Fields required when registering a filter
 */
export class FilterFields {
  filterInstall:     string;
  filterInstallDate: string;
  filterSerial:      string;
  filterProductID:   string;
  filterExpiryDate:  string;
}

/**
 * Fields required when completing a service record
 */
export class CompleteFields {
  completionDate: string;
}

/**
 * Encapsulates the set of flags and their associated fields for a service
 * record
 */
export class ServiceRecordFlags {
  mc1Used:           boolean;
  mc3Used:           boolean;
  magnaCleanseFlush: boolean;
  magnaCleanFitted:  boolean;
  waterTestComplete: boolean;
  serviceComplete:   boolean;

  mc1EditFields?:             SerialFields;
  mc3EditFields?:             SerialFields;
  waterTestFields?:           SerialFields;
  filterEditFields?:          FilterFields;
  serviceCompleteEditFields?: CompleteFields;

  /**
   * Creates a model instance from form data
   *
   * @param {any} data Data from the form
   * @return {ServiceRecordFlags}
   */
  static fromFormData(data: any): ServiceRecordFlags {
    return {
      mc1Used:           data.mc1Used,
      mc3Used:           data.mc3Used,
      magnaCleanseFlush: data.magnaCleanseFlush,
      magnaCleanFitted:  data.magnaCleanFitted,
      waterTestComplete: data.waterTestComplete,
      serviceComplete:   data.serviceComplete,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {ServiceRecordFlags}
   */
  static fromApiData(apiData: any): ServiceRecordFlags {
    const srFlags: ServiceRecordFlags = new ServiceRecordFlags();

    // Set flags from ServiceRecord model
    srFlags.mc1Used           = apiData.mc1_used;
    srFlags.mc3Used           = apiData.mc3_used;
    srFlags.magnaCleanseFlush = apiData.magnacleanse_flush;
    srFlags.magnaCleanFitted  = apiData.magnaclean_fitted;
    srFlags.waterTestComplete = apiData.water_test_complete;
    srFlags.serviceComplete   = apiData.service_complete;

    // Set related fields from related Job/Warranty models
    srFlags.mc1EditFields = {
      serialNumber: apiData.job_by_job_id ? apiData.job_by_job_id.protect_serial_no : null,
    };

    srFlags.mc3EditFields = {
      serialNumber: apiData.job_by_job_id ? apiData.job_by_job_id.cleaner_serial_no : null,
    };

    srFlags.waterTestFields = {

      // TODO: needs to be added to Job model
      serialNumber: apiData.job_by_job_id ? apiData.job_by_job_id.water_serial_no : null,

    };

    srFlags.filterEditFields = {
      filterInstall:     apiData.job_by_job_id ? (apiData.job_by_job_id.new_filter ? 'NEW' : 'EXISTING') : 'NEW',
      filterInstallDate: apiData.warranty_by_warranty_id ? apiData.warranty_by_warranty_id.install_date : null,
      filterSerial:      apiData.job_by_job_id ? apiData.job_by_job_id.filter_serial_no : null,
      filterProductID:   apiData.job_by_job_id ? apiData.job_by_job_id.filterproduct_id : null,

      // TODO: add field to Job
      filterExpiryDate:  apiData.job_by_job_id ? apiData.job_by_job_id.filter_expiry_date : null,

    };

    srFlags.serviceCompleteEditFields = {

      // TODO: needs to be added to ServiceRecord model
      completionDate: apiData.completion_date,

    };

    return srFlags;
  }

  /**
   * Converts the model to the API data format (PATCH existing service record)
   *
   * @param {ServiceRecord}      sr
   * @param {ServiceRecordFlags} srf
   * @return {object}
   */
  static toAPIPatchServiceRecord(sr: ServiceRecord, srf: ServiceRecordFlags): any {
    return {
      id:                  parseInt(sr.id, 10),
      mc1_used:            srf.mc1Used,
      mc3_used:            srf.mc3Used,
      magnacleanse_flush:  srf.magnaCleanseFlush,
      magnaclean_fitted:   srf.magnaCleanFitted,
      water_test_complete: srf.waterTestComplete,
      service_complete:    srf.serviceComplete,
      completion_date:     srf.serviceCompleteEditFields.completionDate,
    };
  }

  /**
   * Converts the model to the API data format (PATCH existing Job)
   *
   * @param {ServiceRecord}      sr
   * @param {ServiceRecordFlags} srf
   * @return {object}
   */
  static toAPIPatchJob(sr: ServiceRecord, srf: ServiceRecordFlags): any {
    if (!sr.job)
      return {};

    return {
      id:                 parseInt(sr.jobId, 10),
      protect_serial_no:  srf.mc1EditFields.serialNumber,
      cleaner_serial_no:  srf.mc3EditFields.serialNumber,
      water_serial_no:    srf.waterTestFields.serialNumber,
      new_filter:         srf.filterEditFields.filterInstall === 'NEW',
      filter_serial_no:   srf.filterEditFields.filterSerial,
      filterproduct_id:   srf.filterEditFields.filterProductID,
      filter_expiry_date: srf.filterEditFields.filterExpiryDate,
    };
  }

  /**
   * Converts the model to the API data format (PATCH existing Warranty)
   *
   * @param {ServiceRecord}      sr
   * @param {ServiceRecordFlags} srf
   * @return {object}
   */
  static toAPIPatchWarranty(sr: ServiceRecord, srf: ServiceRecordFlags): any {
    if (!sr.warranty)
      return {};

    return {
      id:           parseInt(sr.warrantyId, 10),
      install_date: srf.filterEditFields.filterInstallDate,
    };
  }

  /**
   * Returns an example model
   *
   * @return {ServiceRecordFlags}
   */
  static getExample(): ServiceRecordFlags {
    const bComplete: boolean = (Math.floor(Math.random() * 10) % 2) === 0;

    return {
      mc1Used:           (Math.floor(Math.random() * 10) % 2) === 0,
      mc3Used:           (Math.floor(Math.random() * 10) % 2) === 0,
      magnaCleanseFlush: (Math.floor(Math.random() * 10) % 2) === 0,
      magnaCleanFitted:  (Math.floor(Math.random() * 10) % 2) === 0,
      waterTestComplete: (Math.floor(Math.random() * 10) % 2) === 0,
      serviceComplete:   bComplete,

      mc1EditFields: {
        serialNumber: Math.floor(Math.random() * 10).toString(),
      },
      mc3EditFields: {
        serialNumber: Math.floor(Math.random() * 10).toString(),
      },
      waterTestFields: {
        serialNumber: Math.floor(Math.random() * 10).toString(),
      },
      filterEditFields: {
        filterInstall:      'New',
        filterInstallDate:  moment().format('YYYY-MM-DD'),
        filterSerial:       Math.floor(Math.random() * 10).toString(),
        filterProductID:    Math.floor(Math.random() * 10).toString(),
        filterExpiryDate:   moment().add(1000, 'day').format('YYYY-MM-DD'),
      },
      serviceCompleteEditFields: {
        completionDate: (bComplete ? moment().format('YYYY-MM-DD') : ''),
      }
    };
  }
}

/**
 * Encapsulates service record statistics for a user
 */
export class ServiceRecordStats {
  completed: number;
  pending:   number;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {ServiceRecordStats}
   */
  static fromAPI(data: any): ServiceRecordStats {
    return {
      completed: data.services_completed,
      pending:   data.services_pending,
    };
  }
}

/**
 * Encapsulates a single service record
 */
export class ServiceRecord {
  id?:         string;
  jobId?:      string;
  warrantyId?: string;
  userId?:     string;
  createdAt?:  string;
  updatedAt?:  string;

  // "JOB" or "WARRANTY"
  type: string;

  // TRUE when record matches authority (API)
  confirmed: boolean;

  // YYYY-MM-DD
  date: string;

  // Related Customer model
  customer: Customer;

  // Related Job/Warranty models
  job?:      Job;
  warranty?: Warranty;

  // YYYY-MM-DD
  lastServiceDate: string;

  lastServiceStatus: string;

  flags: ServiceRecordFlags;

  /**
   * Creates a model instance from form data
   *
   * @param {any} data Data from the form
   * @return {ServiceRecord}
   */
  static fromFormData(data: any) {
    return {
      confirmed:         false,
      id:                data.id,
      date:              moment(data.date).format('YYYY-MM-DD'),
      customer:          Customer.fromFormData(data),
      type:              'JOB',
      lastServiceDate:   moment(data.lastServiceDate).format('YYYY-MM-DD'),
      lastServiceStatus: data.lastServiceStatus,
      flags:             ServiceRecordFlags.fromFormData(data.flags),
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} apiData API data
   * @return {ServiceRecord}
   */
  static fromApiData(apiData: any): ServiceRecord {
    const sr: ServiceRecord = new ServiceRecord();

    sr.id                 = apiData.id;
    sr.jobId              = apiData.job_id;
    sr.warrantyId         = apiData.warranty_id;
    sr.userId             = apiData.user_id;
    sr.confirmed          = true;
    sr.createdAt          = apiData.created_at;
    sr.updatedAt          = apiData.updated_at;
    sr.date               = apiData.service_date; // YYYY-MM-DD
    sr.type               = 'JOB';
    sr.lastServiceDate    = apiData.last_service_date; // YYYY-MM-DD
    sr.lastServiceStatus  = apiData.last_service_status;
    sr.flags              = ServiceRecordFlags.fromApiData(apiData);

    sr.customer = apiData.customer_by_customer_id ? Customer.fromApiData(apiData.customer_by_customer_id) : null;
    sr.job      = apiData.job_by_job_id           ? Job.fromAPI(apiData.job_by_job_id)                    : null;
    sr.warranty = apiData.warranty_by_warranty_id ? Warranty.getFromApi(apiData.warranty_by_warranty_id)  : null;

    return sr;
  }

  /**
   * Returns an example model
   *
   * @return {ServiceRecord}
   */
  static getExample(): ServiceRecord {
    return {
      id:                Math.floor(Math.random() * 1000).toString(),
      confirmed:         true,
      date:              moment().format('YYYY-MM-DD'),
      customer:          Customer.getExample(),
      jobId:             Math.floor(Math.random() * 1000).toString(),
      type:              'JOB', //Math.floor((Math.random() * 10) % 2) === 0 ? 'WARRANTY' : 'JOB',
      lastServiceDate:   moment().format('YYYY-MM-DD'),
      lastServiceStatus: Math.floor((Math.random() * 10) % 2) === 0 ? 'Complete' : 'Incomplete',
      warrantyId:        Math.floor(Math.random() * 1000).toString(),
      flags:             ServiceRecordFlags.getExample(),
    };
  }
}

export class ServiceRequest {
  customerTitle:    string;
  customerForename: string;
  customerSurname:  string;
  customerAddress1: string;
  customerAddress2: string;
  customerTown:     string;
  customerCounty:   string;
  customerPostcode: string;

  // YYYY-MM-DD
  date: string;

  // YYYY-MM-DD HH:mm:ss
  serviceDateTime: string;

  typeOfService: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any} data Data from the form
   * @return {ServiceRequest}
   */
  static fromFormData(data: any): ServiceRequest {
    return {
      customerTitle:    data.customer.customerTitle,
      customerForename: data.customer.customerForename,
      customerSurname:  data.customer.customerSurname,
      customerAddress1: data.customer.customerAddress1,
      customerAddress2: data.customer.customerAddress2,
      customerTown:     data.customer.customerTown,
      customerCounty:   data.customer.customerCounty,
      customerPostcode: data.customer.customerPostcode,

      date:            moment(data.date).utc().format('YYYY-MM-DD'),
      serviceDateTime:
        moment(
          moment(data.serviceDate).format('YYYY-MM-DD') + ' ' + data.serviceTime
        ).utc().format('YYYY-MM-DD HH:mm:ss'),

      typeOfService: data.typeOfService,
    };
  }
}


export class AddServiceRequestResponse {
  error:          string;
  serviceRequest: ServiceRequest;
}

export class FetchServiceRecordStatsResponse {
  error: string;
  stats: ServiceRecordStats;
}

export class FetchServiceRecordsRequest {
  perPage: number;
  pageNo:  number;
  upcoming: boolean;

  customerQuery?: string;
}

export class FetchServiceRecordsResponse {
  error:      string;
  records:    ServiceRecord[];
  totalPages: number;
}

export class SetServiceRecordFlagRequest {
  record: ServiceRecord;
  flags:  ServiceRecordFlags;
}

export class SetServiceRecordFlagResponse {
  record: ServiceRecord;
  error:  string;
  flags:  ServiceRecordFlags;
}

export class UpdateServiceRecordRequest {
  record: ServiceRecord;
}

export class UpdateServiceRecordResponse {
  error:  string;
  record: ServiceRecord;
}
