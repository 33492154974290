<div class="container-fluid">

  <div class="row content-page" *ngIf="loginDetails$ | async; let loginDetails">
    <div class="col-xs-12 background-white main-content">

      <app-message-panel kind="ERROR" *ngIf="loginDetails.activationError">
        {{loginDetails.activationError}}
        <hr />
        <button class="btn btn-default" routerLink="/">Return to homepage</button>
      </app-message-panel>

      <app-message-panel kind="ERROR" *ngIf="!token || !email">
        The required activation email and/or token could not be found. Please
        follow the link that was sent in your welcome e-mail. If this does not
        work, please try copying and pasting the link into your browser's
        address bar.
        <hr />
        <button class="btn btn-default" routerLink="/">Return to homepage</button>
      </app-message-panel>

      <app-message-panel kind="INFO" *ngIf="loginDetails.activationMessage && homeowner">
        Account successfully activated, please close this window and log in to the HomeZone app to continue registering your filters.
      </app-message-panel>

      <app-waiting-spinner kind="spinner" *ngIf="token && email && loginDetails.activationPending">
        Your account is being activated, please wait...
      </app-waiting-spinner>

    </div>
  </div>
</div>
