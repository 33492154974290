<form [formGroup]="fg" novalidate (ngSubmit)="onSubmitForm()">

  <ng-content select=".form-header"></ng-content>

  <app-message-panel kind="WARNING" *ngIf="errors">{{errors}}</app-message-panel>

  <div class="product-section col-xs-12 col-sm-6">
    <div class="col-xs-12">
      <h3>Job details</h3>
    </div>

    <div [class]="formGroupClass('userId', 'col-xs-12 col-sm-10 col-md-6 clear-both')" *ngIf="adminMode">
      <label class="control-label">User ID <span class="color-adey-red">*</span></label>
      <input type="text" class="form-control" formControlName="userId" />
    </div>

    <div [class]="formGroupClass('cleanerUsed', 'col-xs-12 half-input-checkbox')">

      <label class="control-label medium  has-icon">
        <img class="icon-ring" src="/assets/i/common/rings/adey-ring-red.png" />
        <i class="stage-icon icon-mc3"></i>
        <span>ADEY MC3+ or MC5 used?</span>
      </label>
      <mat-checkbox md-no-ink class="md-primary" (change)="updateCleanerFields()" formControlName="cleanerUsed">
      </mat-checkbox>
    </div>
    <div [class]="formGroupClass('cleanerSerial', 'col-xs-12')" *ngIf="fg.controls.cleanerUsed.value == true">
      <label class="control-label">Serial number</label>
      <input type="text" class="form-control" formControlName="cleanerSerial" />
    </div>

    <div [class]="formGroupClass('magnaCleanseUsed', 'col-xs-12 half-input-checkbox')">

      <label class="control-label medium  has-icon">
        <img class="icon-ring" src="/assets/i/common/rings/adey-ring-blue.png" />
        <i class="stage-icon icon-flush"></i>
        <span>MagnaCleanse system flush complete?</span>
      </label>
      <mat-checkbox md-no-ink class="md-primary" formControlName="magnaCleanseUsed"></mat-checkbox>
    </div>

    <div [class]="formGroupClass('filterUsed', 'col-xs-12 half-input-checkbox')">
      <label class="control-label medium has-icon">
        <img class="icon-ring" src="/assets/i/common/rings/adey-ring-orange.png" />
        <i class="stage-icon icon-fitted"></i>
        <span>MagnaClean filter installed?</span>
      </label>
      <mat-checkbox md-no-ink class="md-primary" (change)="updateSerialFields()" formControlName="filterUsed">
      </mat-checkbox>
    </div>
    <div *ngIf="fg.controls.filterUsed.value == true">

      <div [class]="formGroupClass('filterProductID', 'col-xs-12 col-sm-10 col-md-6 clear-both')">
        <label class="control-label">Product type <span class="color-adey-red">*</span></label>
        <ng-content select=".product-list"></ng-content>
        <select class="form-control" formControlName="filterProductID" (change)="handleProductChange()"
          *ngIf="productTypes && productTypes.products">
          <option value="">-- Please select --</option>
          <option *ngFor="let pt of productTypes.products" [value]="pt.id">{{pt.name}}</option>
        </select>
      </div>

      <div *ngIf="!job && fg.value.filterProductID === '40'" class="col-xs-12 color-adey-red">
        <p>Pro3 warranty is automatically activated once the Pro3 is connected. You can register the warranty here, but
          in order to connect the filter and earn points for the Pro3 install, please follow the connection process.</p>
      </div>

      <div [class]="formGroupClass('filterSerial', 'col-xs-12')">
        <label class="control-label">Serial number <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="filterSerial" />
        <app-waiting-spinner *ngIf="filterSerialValidation.pending" kind="spinner">
          Checking serial number...
        </app-waiting-spinner>
        <app-message-panel *ngIf="filterSerialValidation.message"
          [kind]="filterSerialValidation.valid ? 'SUCCESS' : 'WARNING'">
          {{filterSerialValidation.message}}
        </app-message-panel>
      </div>

      <div [class]="formGroupClass('filterInstallDate', 'col-xs-12 button-right date-input')">
        <mat-form-field>
          <input matInput formControlName="filterInstallDate" (dateChange)="filterInstallDateChanged()"
            [min]="minInstallDate" [max]="maxInstallDate" [matDatepicker]="dpFilterInstallDate"
            placeholder="Filter installation date" />
          <mat-datepicker #dpFilterInstallDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpFilterInstallDate" *ngIf="!readOnly"></mat-datepicker-toggle>
      </div>

      <div
        [class]="formGroupClass('filterExpiryDate', 'col-xs-12 col-sm-10 col-md-6 clear-both button-right date-input')">
        <mat-form-field>
          <input matInput formControlName="filterExpiryDate" [min]="fg.get('filterInstallDate').value"
            [matDatepicker]="dpExpiryDate" placeholder="Filter expiry date" />
          <mat-datepicker #dpExpiryDate></mat-datepicker>
        </mat-form-field>
        <mat-datepicker-toggle [for]="dpExpiryDate" *ngIf="!readOnly"></mat-datepicker-toggle>
      </div>

      <div class="col-xs-12 radio-buttons">
        <input type="radio" value="New" id="new_filter" formControlName="filterInstall" name="filterInstall">
        <label for="new_filter"
          [class]="fg.controls.filterInstall.value == 'New' ? 'background-adey-red color-white' : 'background-beige color-gray'">New
          filter</label>
        <input type="radio" value="Replacement" id="existing_filter" formControlName="filterInstall"
          name="filterInstall">
        <label for="existing_filter"
          [class]="fg.controls.filterInstall.value == 'Replacement' ? 'background-adey-red color-white' : 'background-beige color-gray'">Replacement
          filter</label>
      </div>
      <!--<div [class]="formGroupClass(time, 'col-xs-12 col-sm-6')">
        <mat-form-field>
          <input
            matInput
            formControlName="filterInstallTime"
            placeholder="Filter installation time"
          />
        </mat-form-field>
      </div>-->
    </div>
    <div [class]="formGroupClass('waterTestComplete', 'col-xs-12 half-input-checkbox')">
      <label class="control-label medium has-icon">
        <img class="icon-ring" src="/assets/i/common/rings/adey-ring-green.png" />
        <i class="stage-icon icon-water-test"></i>
        <span>Water test complete?</span>
      </label>
      <mat-checkbox md-no-ink class="md-primary" (change)="updateWaterFields()" formControlName="waterTestComplete">
      </mat-checkbox>
    </div>
    <div [class]="formGroupClass('waterSerialNo', 'col-xs-12')" *ngIf="fg.controls.waterTestComplete.value === true">
      <label class="control-label">Water test serial number</label>
      <input type="text" class="form-control" formControlName="waterSerialNo" />
    </div>

    <div [class]="formGroupClass('protectionUsed', 'col-xs-12 half-input-checkbox')">
      <label class="control-label medium has-icon">
        <img class="icon-ring" src="/assets/i/common/rings/adey-ring-yellow.png" />
        <i class="stage-icon icon-mc1"></i>
        <span>ADEY MC1+ protection used?</span>
      </label>
      <mat-checkbox md-no-ink class="md-primary" (change)="updateProtectionFields()" formControlName="protectionUsed">
      </mat-checkbox>
    </div>
    <div [class]="formGroupClass('protectionSerial', 'col-xs-12')" *ngIf="fg.controls.protectionUsed.value == true">
      <label class="control-label">Serial number</label>
      <input type="text" class="form-control" formControlName="protectionSerial" />
    </div>

    <div [class]="formGroupClass('boilerBrand', 'col-xs-12')">
      <label class="control-label">Boiler brand</label>
      <input type="text" class="form-control" formControlName="boilerBrand" />
    </div>

    <div [class]="formGroupClass('warrantyLength', 'col-xs-12')">
      <label class="control-label">Boiler warranty expiry date (year)</label>
      <select class="form-control" formControlName="warrantyLength">
        <option [value]="null" disabled selected>Please select a year</option>
        <option [value]="year" *ngFor="let year of expiryYears">{{year}}</option>
      </select>
    </div>

    <div class="col-xs-12 no-pad">
      <div class="col-xs-12 col-sm-11 col-md-9 col-lg-6 radio-buttons">
        <input type="radio" value="New" id="new_boiler" formControlName="systemState" name="systemState">
        <label for="new_boiler"
          [class]="fg.controls.systemState.value == 'New' ? 'background-adey-red color-white' : 'background-beige color-gray'">New
          boiler</label>
        <input type="radio" value="Existing" id="existing_boiler" formControlName="systemState" name="systemState">
        <label for="existing_boiler"
          [class]="fg.controls.systemState.value == 'Existing' ? 'background-adey-red color-white' : 'background-beige color-gray'">Existing
          boiler</label>
      </div>
    </div>

    <div [class]="formGroupClass('systemRads', 'col-xs-12')">
      <label class="control-label">System radiators</label>
      <input type="number" class="form-control" formControlName="systemRads" />
    </div>

    <div [class]="formGroupClass('radiatorsType', 'col-xs-12')">
      <label class="control-label">Radiators type</label>
      <select class="form-control" formControlName="radiatorsType">
        <option value="0" disabled selected>Please select your radiator type</option>
        <option value="old">Old radiators</option>
        <option value="new">New radiators</option>
        <option value="mixed">New / old radiators</option>
      </select>
    </div>

    <div [class]="formGroupClass('pipeworkType', 'col-xs-12')">
      <label class="control-label">Pipework type</label>
      <select class="form-control" formControlName="pipeworkType">
        <option value="0" disabled selected>Please select your pipework type</option>
        <option value="copper">Copper</option>
        <option value="plastic">Plastic</option>
        <option value="mixed">Copper / plastic</option>
      </select>
    </div>

    <div class="col-xs-12 radio-buttons">
      <input type="radio" id="yes_smart" formControlName="smartThermostatInstalled" name="smartThermostatInstalled"
        (click)="updateThermostatInstalledField(true)">
      <label for="yes_smart"
        [class]="fg.controls.smartThermostatInstalled.value ? 'background-adey-red color-white' : 'background-beige color-gray'">Smart
        thermostat installed</label>
      <input type="radio" id="no_smart" formControlName="smartThermostatInstalled" name="smartThermostatInstalled"
        (click)="updateThermostatInstalledField(false)">
      <label for="no_smart"
        [class]="!fg.controls.smartThermostatInstalled.value ? 'background-adey-red color-white' : 'background-beige color-gray'">No
        smart thermostat installed</label>
    </div>

    <div *ngIf="fg.controls.smartThermostatInstalled.value"
      [class]="formGroupClass('smartThermostatModel', 'col-xs-12')">
      <label class="control-label">Thermostat model</label>
      <input type="text" class="form-control" formControlName="smartThermostatModel" />
    </div>

  </div>
  <div class="customer-section col-xs-12 col-sm-6">
    <div class="col-xs-12">
      <h3>Customer details</h3>
      <p class="footnote uppercase color-adey-red medium" *ngIf="!job">Your customer will not receive any confirmation
        of the job if a customer e-mail address is not supplied</p>
    </div>
    <div class="customer-details" formGroupName="customer">
      <div [class]="formGroupCustomerClass('customerTitle', 'col-xs-12 col-sm-10 col-md-6')">
        <label class="control-label">Customer title <span class="color-adey-red">*</span></label>
        <select class="form-control" formControlName="customerTitle" placeholder="Mr">
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
        </select>
      </div>

      <div [class]="formGroupCustomerClass('customerSurname', 'col-xs-12')">
        <label class="control-label">Customer surname <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="customerSurname" />
      </div>

      <div [class]="formGroupCustomerClass('customerPostcode', 'col-xs-12')">
        <label class="control-label">Postcode <span class="color-adey-red">*</span></label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="customerPostcode" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" [disabled]="!enablePostcodeLookup" (click)="postcodeLookup()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
          </span>
        </div>
      </div>

      <div [class]="formGroupCustomerClass('customerAddress1', 'col-xs-12')">
        <label class="control-label">Address line 1 <span class="color-adey-red">*</span></label>
        <input type="text" class="form-control" formControlName="customerAddress1" />
      </div>

      <div [class]="formGroupCustomerClass('customerAddress2', 'col-xs-12')">
        <label class="control-label">Address line 2</label>
        <input type="text" class="form-control" formControlName="customerAddress2" />
      </div>

      <div [class]="formGroupCustomerClass('customerTown', 'col-xs-12')">
        <label class="control-label">Town</label>
        <input type="text" class="form-control" formControlName="customerTown" />
      </div>

      <div [class]="formGroupCustomerClass('customerCounty', 'col-xs-12')">
        <label class="control-label">County</label>
        <input type="text" class="form-control" formControlName="customerCounty" />
      </div>

      <div formGroupName="emails">
        <div [class]="formGroupCustomerClass('emails', 'col-xs-12')">
          <label class="control-label">Customer email <span class="color-adey-red">*</span></label>
          <input type="email" class="form-control" formControlName="customerEmail" />
        </div>
        <div [class]="formGroupCustomerClass('emails', 'col-xs-12')">
          <label class="control-label">Confirm customer email <span class="color-adey-red">*</span></label>
          <input type="email" class="form-control" formControlName="customerConfirmEmail" />
        </div>
      </div>

      <div [class]="formGroupCustomerClass('customerPhone', 'col-xs-12')">
        <label class="control-label">Phone</label>
        <input type="text" class="form-control" formControlName="customerPhone" />
      </div>

      <!-- <div class="form-group col-xs-12 full-width-label standard-input">
        <label class="control-label"><strong>How would the customer like to keep updated?</strong></label>
      </div>
      <div class="form-group wrap col-xs-12 full-width-label standard-input">
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_email" formControlName="customerContactByEmail">
            Email
          </mat-checkbox>
        </div>
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_phone" formControlName="customerContactByPhone">
            Phone/SMS
          </mat-checkbox>
        </div>
        <div class="option-label">
          <mat-checkbox md-no-ink class="md-primary" id="contact_post" formControlName="customerContactByPost">
            Post
          </mat-checkbox>
        </div>
      </div> -->
    </div>
    <div class="btn-group col-xs-12" *ngIf="!readOnly && showSubmit">
      <button class="btn background-green color-white pull-right no-radius submit-button">{{submitLabel}}</button>
    </div>
  </div>

  <ng-content select=".form-footer"></ng-content>
</form>