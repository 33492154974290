export class BusinessTool {
  id: string;
  name: string;
  internalReference: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  static fromAPI(data: BusinessToolAPIResponse): BusinessTool {
    const tool: BusinessTool = new BusinessTool();
    tool.id = data.id;
    tool.name = data.name;
    tool.internalReference = data.internal_reference;
    tool.createdAt = data.created_at;
    tool.updatedAt = data.updated_at;
    tool.deletedAt = data.deleted_at;
    return tool;
  }
}

export interface BusinessToolAPIResponse {
  id: string;
  name: string;
  internal_reference: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface BusinessToolResponse {
  error: boolean;
  data: BusinessTool[];
  message: string;
}

export class BusinessToolRegistration {
  id: string;
  userId: string;
  businessToolId: string;
  verificationToken: string;
  verifiedAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;

  static fromAPI(data: BusinessToolRegistrationAPIResponse): BusinessToolRegistration {
    const registration: BusinessToolRegistration = new BusinessToolRegistration();
    registration.id = data.id;
    registration.userId = data.user_id;
    registration.businessToolId = data.business_tool_id;
    registration.verificationToken = data.verification_token;
    registration.verifiedAt = data.verified_at;
    registration.createdAt = data.created_at;
    registration.updatedAt = data.updated_at;
    registration.deletedAt = data.deleted_at;
    return registration;
  }
}

export interface BusinessToolRegistrationAPIResponse {
  id: string;
  user_id: string;
  business_tool_id: string;
  verification_token: string;
  verified_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export class BusinessToolVerificationToken {
  id: number;
  token: string;

  static fromAPI(data: BusinessToolVerificationTokenAPIResponse): BusinessToolVerificationToken {
    const token: BusinessToolVerificationToken = new BusinessToolVerificationToken();
    token.id = data.id;
    token.token = data.verification_token;
    return token;
  }
}

export interface BusinessToolVerificationTokenRequest {
  tool: string;
}

export interface BusinessToolVerificationTokenResponse {
  error: boolean;
  data: BusinessToolVerificationToken;
  message: string;
}

export interface BusinessToolVerificationTokenAPIResponse {
  id: number;
  verification_token: string;
}

export interface TradeHelpSignupFormData {
  referralKey: string;
  companyName: string;
  firstName: string;
  surName: string;
  contactNo: string;
}

export interface TrueQuoteSignupFormData {
  idExternal: string;
  companyName: string;
  country: string;
  email: string;
  firstName: string;
  gasSafe: string;
  lastName: string;
  oftec: string;
  source: string;
  telephone: string;
  urlWebsite: string;
}
