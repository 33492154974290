import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  AddAdminUserResponse,
  DeleteAdminUserResponse,
  FetchAdminUsersResponse,
  UpdateAdminUserResponse,
} from '../../models/admin-users';
import { FetchAccreditationsResponse } from '../../models/user-profile';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-users';

@Injectable()
export class AdminUserEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For an AddAdminUserRequestAction, call AdminService::addUser() and
   * dispatch a new AddAdminUserResponseAction with the response.
   */
   addUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_ADMIN_USER_REQUEST),
    switchMap((req: ActionTypes.AddAdminUserRequestAction): Observable<ActionTypes.AddAdminUserResponseAction> =>
      this.service.addUser(req.payload).pipe(
        map((res: AddAdminUserResponse): ActionTypes.AddAdminUserResponseAction =>
          new ActionTypes.AddAdminUserResponseAction(res)
        ))
    )));

  /**
   * For an DeleteAdminUserRequestAction, call AdminService::deleteUser() and
   * dispatch a new DeleteAdminUserResponseAction with the response.
   */
   deleteUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_ADMIN_USER_REQUEST),
    switchMap((req: ActionTypes.DeleteAdminUserRequestAction): Observable<ActionTypes.DeleteAdminUserResponseAction> =>
      this.service.deleteUser(req.payload).pipe(
        map((res: DeleteAdminUserResponse): ActionTypes.DeleteAdminUserResponseAction =>
          new ActionTypes.DeleteAdminUserResponseAction(res)
        ))
    )));

  /**
   * For a FetchAdminUsersRequestAction, call AdminService::fetchUsers() and
   * dispatch a new FetchAdminUsersResponseAction with the response.
   */
   fetchUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_USERS_REQUEST),
    switchMap((req: ActionTypes.FetchAdminUsersRequestAction): Observable<ActionTypes.FetchAdminUsersResponseAction> =>
      this.service.fetchUsers(req.payload).pipe(
        map((res: FetchAdminUsersResponse): ActionTypes.FetchAdminUsersResponseAction =>
          new ActionTypes.FetchAdminUsersResponseAction(res)
        ))
    )));

  /**
   * For an UpdateAdminUserRequestAction, call AdminService::updateUser() and
   * dispatch a new UpdateAdminUserResponseAction with the response.
   */
   updateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ADMIN_USER_REQUEST),
    switchMap((req: ActionTypes.UpdateAdminUserRequestAction): Observable<ActionTypes.UpdateAdminUserResponseAction> =>
      this.service.updateUser(req.payload).pipe(
        map((res: UpdateAdminUserResponse): ActionTypes.UpdateAdminUserResponseAction =>
          new ActionTypes.UpdateAdminUserResponseAction(res)
        ))
    )));

  /**
   * For a FetchAccreditationsRequestAction, call AdminService::fetchAccreditations() and
   * dispatch a new FetchAccreditationsResponseAction with the response.
   */
   fetchAccreditations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ACCREDITATIONS_REQUEST),
    switchMap((): Observable<Action> =>
      this.service.fetchAccreditations().pipe(
        map((res: FetchAccreditationsResponse): ActionTypes.FetchAccreditationsResponseAction =>
          new ActionTypes.FetchAccreditationsResponseAction(res)
        ))
    )));
}
