/**
 * user-suggestion models
 */

import { UserProfile } from './user-profile';


/**
 * Encapsulates a single suggested user (user name and ID)
 */
export class UserSuggestion {
  id:        string;
  title:     string;
  firstName: string;
  lastName:  string;
  companyName: string;
  postCode: string;

  /**
   * Returns an example model
   *
   * @return {UserSuggestion}
   */
  static getExample(): UserSuggestion {
    const p: UserProfile = UserProfile.getExample();
    return {
      id:        Math.floor(Math.random() * 1000).toString(),
      title:     p.title,
      firstName: p.firstName,
      lastName:  p.lastName,
      companyName: p.companyName,
      postCode: p.postCode,
    };
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {UserSuggestion}
   */
  static fromAPI(data: any): UserSuggestion {
    return {
      id:        data.id,
      title:     data.title,
      firstName: data.first_name,
      lastName:  data.last_name,
      companyName: data.companyname,
      postCode: data.postcode,
    };
  }
}


export interface FetchUserSuggestionRequest {
  query: string;
  field?: string;
}

export interface FetchUserSuggestionResponse {
  query: string;
  error: string;
  users: UserSuggestion[];
}
