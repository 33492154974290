<div [class]="'diary-box border-box border-light-gray' + (small ? ' small' : '')">

  <div class="row calendar-nav">

    <div [class]="(small ? 'col-sm-12' : '') + ' align-left month-select'">
      <h1 class="color-adey-red inline no-margin">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h1>
      <div class="prev-next">
        <i mwlCalendarPreviousView class="fa fa-caret-left" [view]="view" [(viewDate)]="viewDate" aria-hidden="true"></i>
        <i mwlCalendarNextView class="fa fa-caret-right" [view]="view" [(viewDate)]="viewDate" aria-hidden="true"></i>
      </div>
    </div>

    <div class="pull-right view-select" *ngIf="!small">
      <div class="btn-group">
        <button
          (click)="view = 'day'" 
          [class]="'btn hover-background-adey-red hover-color-white inset-hover ' + (view === 'day' ? 
            'active background-adey-red inset transform-x-offset-50-after color-adey-red-after' : 
            'background-beige')"
        >
          <p [class]="'no-margin ' + (view === 'day' ? 'color-white' : 'color-gray')">Day</p>
        </button>
        <button
          (click)="view = 'week'" 
          [class]="'btn hover-background-adey-red hover-color-white inset-hover ' + (view === 'week' ? 
            'active background-adey-red inset transform-x-offset-50-after color-adey-red-after' : 
            'background-beige')"
        >
          <p [class]="'no-margin ' + (view === 'week' ? 'color-white' : 'color-gray')">Week</p>
        </button>
        <button
          (click)="view = 'month'" 
          [class]="'btn hover-background-adey-red hover-color-white inset-hover ' + 
            (view === 'month' ? 'active background-adey-red inset transform-x-offset-50-after color-adey-red-after' : 
            'background-beige')"
        >
          <p [class]="'no-margin  ' + (view === 'month' ? 'color-white' : 'color-gray')">Month</p>
        </button>
      </div>
    </div>

  </div>
  
  <ng-template #templateRefHeader>
    <div class="empty-header"></div>
  </ng-template>
  
  <div [ngSwitch]="view" class="diary">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="_events"
      [headerTemplate]="templateRefHeader"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="editEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="_events"
      (dayHeaderClicked)="dayClicked($event.day)"
      (eventClicked)="editEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="_events"
      (hourSegmentClicked)="hourClicked($event)"
      (eventClicked)="editEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>


  <button class="btn pull-right new-button background-green  no-radius" *ngIf="!readOnly" (click)="addEvent()">
    <span class="color-white">Add event</span>
  </button>


</div>
