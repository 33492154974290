import { Component, OnInit } from '@angular/core';

import { Store }      from '@ngrx/store';
import { Observable } from 'rxjs';

import { StoreState }                    from '../../state-management/store';
import { State as ProductSelectorState } from '../../state-management/reducers/products';

import {
  ProductSelectQueryRequestAction,
} from '../../state-management/actions/products';

import {
  ProductSelectQueryRequest,
} from '../../models/product-selector';

/**
 * Summary
 *    Displays the product-selector-component.
 *
 *
 * Description
 *    Handles the state interaction for the product selector and passes the information onto
 *    the product-selector component.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-product-selector-container',
  templateUrl: './product-selector-container.component.html',
  styleUrls: ['./product-selector-container.component.scss']
})
export class ProductSelectorContainerComponent implements OnInit {
  // Reference to the product selector state
  state$: Observable<ProductSelectorState>;

  /**
   * Constructor for page
   *
   * @param {Store<StoreState>} store Initialises Store object
   */
  constructor(
    private store: Store<StoreState>
  ) {
    // Set a reference to the product state
    this.state$ = this.store.select('products');
  }

  ngOnInit() {
  }

  /**
   * Dispatches ProductSelectQueryRequestAction to the state with the user's search
   * params in order to filter the products listed
   *
   * @param {ProductSelectQueryRequest} q The new query to pass to the API
   */
  performQuery(q: ProductSelectQueryRequest) {
    this.store.dispatch(new ProductSelectQueryRequestAction(q));
  }

}
