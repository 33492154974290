import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import * as moment from 'moment';

import { StoreState } from '../../state-management/store';
import { State as ServiceRecordsState } from '../../state-management/reducers/service-record';

import {
  FetchServiceRecordStatsRequestAction,
  FetchServiceRecordsRequestAction,
  NextServiceRecordPageAction,
  PrevServiceRecordPageAction,
  SetServiceRecordFlagRequestAction,
  UpdateServiceRecordRequestAction,
  NextFiveServiceRecordPageAction,
  PrevFiveServiceRecordPageAction,
} from '../../state-management/actions/service-record';

import {
  WarrantyService
} from '../../services/warranty.service';

import {
  ServiceRecord,
  ServiceRecordFlags,
} from '../../models/service-record';

import { FetchProductTypesResponse, Warranty } from '../../models/warranty';

import { JobEditModalComponent } from '../job/job-edit-modal/job-edit-modal.component';
import { WarrantyEditModalComponent } from '../warranty/warranty-edit-modal/warranty-edit-modal.component';

import { FlagUpdateModalComponent } from './flag-update-modal/flag-update-modal.component';


/**
 * Summary
 *    Main job record dashboard page
 *
 * Description
 *    Displays a paginated list of all Job records for a user and allows the
 *    record to be opened for viewing, editing, downloading and printing. Also
 *    displays status columns which can be ticked by the user to update a Job
 *    status. When ticking an item, the user is also prompted to enter extra
 *    relevant data for that item. The page also allows Jobs to be filtered by
 *    customer name.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  styleUrls: ['./service-dashboard.component.scss']
})
export class ServiceDashboardComponent implements OnInit {

  // Current date for display in template
  public currentDate: Date = moment().toDate();

  // Store state
  public state$: Observable<ServiceRecordsState>;

  // Query form
  public fgQuery: UntypedFormGroup = null;

  // Filters for the pages records
  public fgFilters: UntypedFormGroup = null;

  // Reference to JobEditModalComponent
  private dialogRef_details: any = null;

  // Paginated page numbers
  public pagination: number[] = [];

  public productTypes$: Observable<FetchProductTypesResponse>;
  private productTypes: any[];


  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private store: Store<StoreState>,
    private service: WarrantyService,
  ) {
    this.state$ = this.store.select('serviceRecord');

    this.state$.subscribe((state) => {
      if (state.totalPages > 1) this.paginate(state.currentPage, state.totalPages);
    });

    this.fgQuery = this.fb.group({
      name: '',
      postcode: '',
      nextService: '',
      status: ''
    });
  }

  ngOnInit() {
    this.productTypes$ = this.service.FetchProductTypes(false);
    this.productTypes$.subscribe(val => {
      this.productTypes = val.products;
    });

    // OrderBy form
    this.fgFilters = this.fb.group({ orderBy: [false] });
    this.fgFilters.get('orderBy').valueChanges.subscribe(() => this.fetchRecords());

    this.fetchRecords();
    this.fetchStats();
  }

  paginate(currentPageNum: number, totalPages: number) {
    const pagination = [];

    let maxNumbersToPaginate = 9;

    if (currentPageNum > 1) {
      let timesNumbersAdded = 0;

      for (let i = currentPageNum; i > 1; i--) {
        if (timesNumbersAdded >= 5) break;

        timesNumbersAdded++;
        maxNumbersToPaginate--;
        pagination.unshift(currentPageNum - timesNumbersAdded);
      }
    }

    pagination.push(currentPageNum);

    if (totalPages > currentPageNum) {
      for (let i = currentPageNum + 1; i < totalPages + 1; i++) {
        if (maxNumbersToPaginate === 0) break;

        maxNumbersToPaginate--;
        pagination.push(i);

      }
    }

    this.pagination = pagination;
  }

  /**
   * Fetch the first page of records for the current user with an optional
   * customer name/address query
   *
   * @param { number } page The page of records to get, defaults to one
   */
  fetchRecords(page: number = 1) {
    this.store.dispatch(
      new FetchServiceRecordsRequestAction({
        pageNo: page,
        perPage: 10,
        customerQuery: this.fgQuery.controls.name.value,
        upcoming: this.fgFilters.get('orderBy').value,
      })
    );
  }

  /**
   * Formats a date string for Safari, Safari is unable to parse date strings
   * in this format 2022-03-05 17:34:54 and must be parsed to '2016-01-20T19:00:00'
   *
   * Issue: https://github.com/angular/angular/issues/12334
   */
  formatDateForSafari(str: string) {
    return str.replace(' ', 'T');
  }

  /**
   * Fetch latest statistics
   */
  fetchStats() {
    this.store.dispatch(new FetchServiceRecordStatsRequestAction());
  }

  /**
   * Called when a specific item in the table is clicked and opens the relevant
   * dialog based on the service record's type
   *
   * @param {ServiceRecord} sr
   */
  handleClickRecord(sr: ServiceRecord) {
    if (sr.type === 'JOB') {
      this.openJobRecord(sr);
    }
    else {
      this.openWarrantyRecord(sr);
    }
  }

  /**
   * For "JOB" type service records, opens JobEditModalComponent. On save, the
   * returned value is used to update the service record.
   *
   * @param {ServiceRecord} sr
   */
  openJobRecord(sr: ServiceRecord) {
    this.dialogRef_details = this.dialog.open(JobEditModalComponent, {
      data: {
        jobId: sr.jobId,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_details.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateServiceRecordRequestAction({ record: Object.assign({}, sr, { job: result }) }));
    });
  }

  /**
   * For "WARRANTY" type service records, opens WarrantyEditModalComponent. On
   * save, the returned value is used to update the service record.
   *
   * @param {ServiceRecord} sr
   */
  openWarrantyRecord(sr: ServiceRecord) {
    this.dialogRef_details = this.dialog.open(WarrantyEditModalComponent, {
      data: {
        warrantyId: sr.id,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_details.afterClosed().subscribe(result => {
      if (result)
        this.store.dispatch(new UpdateServiceRecordRequestAction({ record: Object.assign({}, sr, { warranty: result }) }));
    });
  }

  /**
   * Loads the next page of results
   */
  nextPage() {
    this.store.dispatch(new NextServiceRecordPageAction());
  }

  /**
   * Loads the previous page of results
   */
  prevPage() {
    this.store.dispatch(new PrevServiceRecordPageAction());
  }

  /**
   * Loads the results for the page, 5 pages next of the current
   */
  nextFivePages() {
    this.store.dispatch(new NextFiveServiceRecordPageAction());
  }

  /**
   * Loads the results for the page, 5 pages prev of the current
   */
  prevFivePages() {
    this.store.dispatch(new PrevFiveServiceRecordPageAction());
  }


  /**
   * When a custom query has changed, fetch records
   */
  setCustomerQuery() {
    this.fetchRecords();
  }

  /**
   * Toggles a single flag on a service record. Opens FlagUpdateModalComponent
   * to ask the user for additional information; when closed the service record
   * is updated with the flag and extra data.
   *
   * @param {ServiceRecord}      sr           ServiceRecord being edited
   * @param {ServiceRecordFlags} flags        Array of all flags for the ServiceRecord
   * @param {string}             key          Flag being toggled
   * @param {string}             label        Label to be displayed for this flag (in dialog)
   * @param {string}             editFieldKey Name of the key used to select fields that need to be edited in dialog
   */
  toggleFlag(sr: ServiceRecord, flags: ServiceRecordFlags, key: string, label: string, editFieldKey: string) {
    this.dialogRef_details = this.dialog.open(FlagUpdateModalComponent, {
      data: {
        flagChecked: flags[key],
        flagTitle: key,
        flagLabel: label,
        flagEditFields: editFieldKey ? flags[editFieldKey] : null
      },
      width: '280px',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_details.afterClosed().subscribe(result => {
      if (result) {
        const newFlags = Object.assign({}, flags);
        newFlags[key] = result.flagChecked;
        if (editFieldKey && result.editFields) {
          newFlags[editFieldKey] = result.editFields;
        }
        this.store.dispatch(new SetServiceRecordFlagRequestAction({ record: sr, flags: newFlags }));
      }
    });
  }

  /**
   * Calculates the expiry date of a warranty based on registration and product type
   *
   * @param {Warranty} w Warranty to check
   */
  calculateExpiry(w: Warranty) {

    if (!w) {
      return { valid: moment() };
    }

    let warrantyLength = 0;
    const expiryDate = moment(w.dateOfInstallation, 'YYYY-MM-DD');

    if (this.productTypes) {
      for (let i = 0; i < this.productTypes.length; ++i) {
        if (this.productTypes[i].id === w.productId) {
          warrantyLength = this.productTypes[i].warrantyLength;
        }
      }
    }

    expiryDate.add(warrantyLength, 'months');
    const now = moment();

    const result = {
      date: expiryDate.format('MMM DD, YYYY'),
      valid: now < expiryDate,
    };

    return result;
  }
}
