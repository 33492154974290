/**
 * ContentService: provides functionality to get dynamic content for site pages
 */


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';




import { Injectable } from '@angular/core';


import { ApiService } from './api.service';

import {
  pages,
  SitePage,
  FetchPageContentResponse,
} from '../models/content-pages';


@Injectable()
export class ContentService {

  constructor(
    private apiService: ApiService,
  ) { }


  /**
   * Fetches dynamic content for all configured fields for all SitePage models.
   *
   * The default content for all page fields is stored in "pages" and any
   * response is merged with this in order to update the default values with
   * the latest values from the API.
   *
   * @return {Observable<FetchPageContentResponse>}
   */
  fetchPageContent(): Observable<FetchPageContentResponse> {
    return this.apiService.apiGet('/sitepages').pipe(
      map((res: any): FetchPageContentResponse => {
        const valid:    boolean    = res && res.resource && Array.isArray(res.resource);
        let   newPages: SitePage[] = [...pages];
        if (valid)
        {
          newPages = newPages.map((p: SitePage): SitePage => {
            const apiPages: any[] = res.resource.filter((v: any): boolean => v.code.toLowerCase() === p.id.toLowerCase());
            return apiPages.length > 0
              ? Object.assign(SitePage.fromAPI(apiPages[0]), {description: p.description, title: p.title})
              : p;
          });
        }
        return {
          error: valid ? null : 'Invalid response from server',
          pages: newPages,
        };
      }),
      catchError((err: any): Observable<FetchPageContentResponse> =>
        observableOf({
          error: null,
          pages,
        })
      ),);
  }

}
