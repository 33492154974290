/**
 * email models
 */

export class PostContactUsRequest {
  email:   string;
  name:    string;
  message: string;

  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {PostContactUsRequest}
   */
  static fromFormData(formData: any): PostContactUsRequest {
    const c: PostContactUsRequest = new PostContactUsRequest();

    c.email = formData.email;
    c.name = formData.name;
    c.message = formData.message;

    return c;
  }
}

export interface PostContactUsResponse {
  message: string;
  error:   string;
}


export class PostVoucherRedeemRequest {
  callback?: Function;
  form: PostVoucherRedeemForm;
}

export class PostVoucherRedeemForm {
  name:            string;
  email:           string;
  company:         string;
  address_line_1:  string;
  address_line_2:  string;
  town:            string;
  county:          string;
  postcode:        string;
  currency_type:   string;
  pointsToConvert: number;

  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {PostVoucherRedeemForm}
   */
  static fromFormData(formData: any): PostVoucherRedeemForm {
    const v: PostVoucherRedeemForm = new PostVoucherRedeemForm();

    v.name            = formData.name;
    v.email           = formData.email;
    v.company         = formData.company;
    v.address_line_1  = formData.addressLine1;
    v.address_line_2  = formData.addressLine2;
    v.town            = formData.town;
    v.county          = formData.county;
    v.postcode        = formData.postcode;
    v.currency_type   = formData.currency_type;
    v.pointsToConvert = formData.pointsToConvert;

    return v;
  }

}

export interface PostVoucherRedeemResponse {
  callback?: Function;
  message: string;
  error:   string;
}

export class PostDemokitRedeemRequest {
  callback?: Function;
  form: PostDemokitRedeemForm;
}

export class PostDemokitRedeemForm {
  name:            string;
  email:           string;
  company:         string;
  address_line_1:  string;
  address_line_2:  string;
  town:            string;
  county:          string;
  postcode:        string;
  currency_type:   string;
  pointsToConvert: number;

  sludgeLeaflet: false;
  pro3Leaflet: false;
  rapidTestKit: false;
  magnaCleanDemoJar: false;
  mc1QuickTestKit: false;

  sludgeLeafletQuantity: number;
  pro3LeafletQuantity: number;
  rapidTestKitQuantity: number;
  magnaCleanDemoJarQuantity: number;
  mc1QuickTestKitQuantity: number;


  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {PostDemokitRedeemForm}
   */
  static fromFormData(formData: any): PostDemokitRedeemForm {
    const v: PostDemokitRedeemForm = new PostDemokitRedeemForm();

    v.name                      = formData.name;
    v.email                     = formData.email;
    v.company                   = formData.company;
    v.address_line_1            = formData.addressLine1;
    v.address_line_2            = formData.addressLine2;
    v.town                      = formData.town;
    v.county                    = formData.county;
    v.postcode                  = formData.postcode;
    v.currency_type             = formData.currency_type;
    v.pointsToConvert           = formData.pointsToConvert;

    // Assign the desired demokit products
    v.sludgeLeaflet             = formData.sludgeLeaflet;
    v.pro3Leaflet               = formData.pro3Leaflet;
    v.rapidTestKit              = formData.rapidTestKit;
    v.magnaCleanDemoJar         = formData.magnaCleanDemoJar;
    v.mc1QuickTestKit           = formData.mc1QuickTestKit;

    // Assign the quantities for the selected demokit products
    v.sludgeLeafletQuantity     = (formData.sludgeLeaflet) ? Number(formData.sludgeLeafletQuantity) : 0;
    v.pro3LeafletQuantity       = (formData.pro3Leaflet) ? Number(formData.pro3LeafletQuantity) : 0;
    v.rapidTestKitQuantity      = (formData.rapidTestKit) ? Number(formData.rapidTestKitQuantity) : 0;
    v.magnaCleanDemoJarQuantity = (formData.magnaCleanDemoJar) ? Number(formData.magnaCleanDemoJarQuantity) : 0;
    v.mc1QuickTestKitQuantity   = (formData.mc1QuickTestKit) ? Number(formData.mc1QuickTestKitQuantity) : 0;

    return v;
  }

}

export interface PostDemokitRedeemResponse {
  callback?: Function;
  message: string;
  error:   string;
}
