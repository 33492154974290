import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subscription } from 'rxjs';
import { TradeHelpSignupFormData } from '../../../models/business-tools';
import { FetchBusinessToolsVerificationTokenRequestAction } from '../../../state-management/actions/business-tools';
import { State as BusinessToolsState } from '../../../state-management/reducers/business-tools';
import { State as LoginDetailsState } from '../../../state-management/reducers/login-details';
import { StoreState } from '../../../state-management/store';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tradehelp',
  templateUrl: './tradehelp.component.html',
  styleUrls: ['./tradehelp.component.scss'],
})
export class TradehelpComponent implements OnInit, OnDestroy {

  private businessTools$: Observable<BusinessToolsState>;
  private businessToolsSub$: Subscription = null;
  public formData: Partial<TradeHelpSignupFormData> = {};
  public formUrl: string = environment.businessTools.tradehelp;
  private loginDetails$: Observable<LoginDetailsState>;
  private loginDetailsSub$: Subscription = null;

  constructor(
    private store: Store<StoreState>,
  ) {
    this.loginDetails$ = this.store.select('loginDetails');
    this.businessTools$ = this.store.select('businessTools');
    this.store.dispatch(new FetchBusinessToolsVerificationTokenRequestAction({ tool: 'tradehelp' }));
  }

  ngOnInit() {
    this.businessToolsSub$ = this.businessTools$.subscribe((state: BusinessToolsState) => {
      if (state.verificationToken) {
        this.formData.referralKey = state.verificationToken.token;
      }
    });

    this.loginDetailsSub$ = this.loginDetails$.subscribe((state: LoginDetailsState) => {
      if (state.currentProfile) {
        this.formData.companyName = state.currentProfile.companyName;
        this.formData.firstName = state.currentProfile.firstName;
        this.formData.surName = state.currentProfile.lastName;
        this.formData.contactNo = state.currentProfile.telephone ? state.currentProfile.telephone : state.currentProfile.mobile;
      }
    });
  }

  ngOnDestroy() {
    if (this.businessToolsSub$) {
      this.businessToolsSub$.unsubscribe();
    }

    if (this.loginDetailsSub$) {
      this.loginDetailsSub$.unsubscribe();
    }
  }
}
