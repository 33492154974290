<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">
        <app-page-content page="TRUEQUOTE" field="title"></app-page-content>
      </h1>
    </div>

    <app-page-content page="TRUEQUOTE" field="intro" class="intro-above-content color-white"></app-page-content>

    <div class="background-white padded-container col-xs-12">
      <app-page-content page="TRUEQUOTE" field="content"></app-page-content>

      <form ngNoForm action="{{ formUrl }}/sign-up-form" method="POST" target="_blank">
        <input type="hidden" name="id_external" value="{{ formData?.idExternal }}" />
        <input type="hidden" name="company_name" value="{{ formData?.companyName }}" />
        <input type="hidden" name="country" value="{{ formData?.country }}" />
        <input type="hidden" name="email" value="{{ formData?.email }}" />
        <input type="hidden" name="first_name" value="{{ formData?.firstName }}" />
        <input type="hidden" name="gas_safe" value="{{ formData?.gasSafe }}" />
        <input type="hidden" name="last_name" value="{{ formData?.lastName }}" />
        <input type="hidden" name="oftec" value="{{ formData?.oftec }}" />
        <input type="hidden" name="source" value="{{ formData?.source }}" />
        <input type="hidden" name="telephone" value="{{ formData?.telephone }}" />
        <input type="hidden" name="url_website" value="{{ formData?.urlWebsite }}" />
        <button class="btn background-green color-white no-radius" type="submit">
          Sign up
        </button>
      </form>
    </div>

  </div>
</div>