<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">Warranty statistics</h1>
    </div>

    <app-page-content page="WARRANTY_STATS" field="intro" class="intro-above-content color-white"></app-page-content>

    <div class="warranty-statistics background-white col-xs-12" *ngIf="warrantyStats$ | async; let warrantyStats">
      <app-waiting-spinner kind="bar" *ngIf="warrantyStats.pending || (loginDetails$ | async).pending">Loading profile information and statistics...</app-waiting-spinner>

      <div class="col-xs-12 user-container border-box border-light-gray" *ngIf="!(loginDetails$ | async).pending">

        <div class="no-pad profile-container" *ngIf="loginDetails$ | async; let loginDetails">

          <div class="profile-badge">
            <div
              class="ie-image-patch profile-photo"
              *ngIf="loginDetails.currentProfile && loginDetails.currentProfile.photoUrl"
              [style.backgroundImage]="'url(' + loginDetails.currentProfile.photoUrl + ')'">
                <img [src]="loginDetails.currentProfile.photoUrl" />
              </div>
            <div class="content col-sm-7 col-xs-12">
              <h2 class="color-light-gray user-name">{{loginDetails.currentProfile ? loginDetails.currentProfile.firstName + ' ' + loginDetails.currentProfile.lastName : '' }}</h2>
              <p
                class="color-gray"
                *ngIf="loginDetails.currentProfile && loginDetails.currentProfile.createdAt"
              >
                Member for {{getMembershipLength(loginDetails.currentProfile.createdAt)}}
              </p>
            </div>
          </div>
        </div>

        <div class="total-points border-box border-light-gray" *ngIf="warrantyStats && warrantyStats.stats">
          <div class="point-block">
            <p>Points available</p>
            <h3 class="color-adey-red">{{warrantyStats.stats.pointsAvailable}} points</h3>
          </div>
          <div class="point-block">
            <p>Total points earnt</p>
            <h3 class="color-adey-blue">{{warrantyStats.stats.pointsEarned}} points</h3>
          </div>
          <div class="point-block">
            <p>Points spent</p>
            <h3 class="color-adey-blue">{{warrantyStats.stats.pointsSpent}} points</h3>
          </div>
        </div>

        <div class="warranty-history" *ngIf="warrantyStats && warrantyStats.stats">
          <div class="border-box border-light-gray">
            <p>Warranties registered in the last 12 months</p>
            <h3 class="color-adey-red">{{warrantyStats.stats.totalWarranties}}</h3>
          </div>
          <div class="border-box border-light-gray">
            <p>Warranties registered in the last 30 days</p>
            <h3 class="color-adey-red">{{warrantyStats.stats.warrantiesLastMonth}}</h3>
          </div>
          <div class="border-box border-light-gray">
            <div class="col-xs-12 col-sm-6">
              <p>Incomplete warranties</p>
              <h3 class="color-adey-red">{{warrantyStats.stats.incompleteWarranties}}</h3>
            </div>
            <div class="col-xs-12 col-sm-6">
              <button
                class="btn background-adey-red color-white"
                type="button"
                (click)="goToWarrantyList()"
              >
                Complete warranty registrations
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="col-xs-12 date-filter" *ngIf="!warrantyStats.pending" >
        <h3 class="color-gray">Data Range</h3>
        <div class="date-inputs" [formGroup]="fgDates">
          <div class="date-input">
            <mat-form-field>
              <input matInput formControlName="dateStart" min="2017-01-01" [matDatepicker]="startDate" placeholder="Start date" />
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
          </div>
          <span>to</span>
          <div class="date-input">
            <mat-form-field>
              <input matInput formControlName="dateEnd" min="2017-01-01" [matDatepicker]="endDate" placeholder="End date" />
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
            <mat-datepicker-toggle [for]="endDate"></mat-datepicker-toggle>
          </div>
          <div class="form-group">
            <button class="btn btn-default" (click)="fetchMonthlyStats()">Refresh</button>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <app-message-panel kind="WARNING" *ngIf="warrantyStats.error">
          {{warrantyStats.error}}
        </app-message-panel>
      </div>

      <div
        class="chart-test col-xs-12"
        *ngIf="warrantyStats && warrantyStats.monthlyStats && warrantyStats.monthlyStats.data"
      >
        <app-waiting-spinner kind="bar" *ngIf="warrantyStats.pendMStats">
          Loading monthly statistics...
        </app-waiting-spinner>
        <canvas
          *ngIf="!warrantyStats.pendMStats && warrantyStats.monthlyStats.labels"
          baseChart
          [chartType]="'bar'"
          [data]="chartData(warrantyStats.monthlyStats.data)"
          [labels]="warrantyStats.monthlyStats.labels"
          [options]="chartOptions"
          [legend]="false"
          height=300
        ></canvas>
      </div>
    </div>

  </div>
</div>
