/**
 * job models
 */

import * as moment from 'moment';
import { Customer } from './customer';
import { UserProfile } from './user-profile';

/**
 * Encapsulates a product that can be selected as a filter in a Job
 */
export class ProductType {
  id: string;
  name: string;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {ProductType}
   */
  static fromAPI(data: any): ProductType {
    return {
      id: data.id.toString(),
      name: data.title,
    };
  }
}

/**
 * Encapsulates a single Job record
 */
export class Job {
  id?: string;
  userId?: string;

  // Set when the model matches the authority (API)
  committed?: boolean;

  cleanerUsed: boolean;
  cleanerSerial?: string;
  magnaCleanseUsed: boolean;
  filterUsed: boolean;

  // "New" or "Replacement"
  filterInstall?: string;

  // YYYY-MM-DD
  filterInstallDate?: string;

  // YYYY-MM-DD
  filterExpiryDate?: string;

  filterSerial?: string;
  serialNumber?: string;

  filterProductID: string;
  filterProductName?: string;
  waterTestComplete: boolean;
  waterSerialNo: string;
  protectionUsed: boolean;
  protectionSerial?: string;
  boilerBrand: string;
  warrantyLength: number;

  // "New" or "Existing"
  systemState: string;

  // Number of radiators
  systemRads: number;

  // New MagSense fields
  radiatorsType?: string;
  pipeworkType?: string;
  smartThermostatInstalled?: boolean;
  smartThermostatModel?: string;

  // Related customer details
  customer: Customer;

  // Owner details
  user?: UserProfile;

  customerContactByPost?: boolean;
  customerContactByEmail?: boolean;
  customerContactByPhone?: boolean;

  renewalOnCalendar?: boolean;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {Job}
   */
  static fromAPI(data: any): Job {
    const job: Job = new Job();

    job.committed = true;

    job.id = data.id;
    job.userId = data.user_id;

    job.boilerBrand = data.boilerbrand;
    job.cleanerUsed = data.cleaned;
    job.cleanerSerial = data.cleaner_serial_no;
    job.magnaCleanseUsed = data.magnacleanse;
    job.filterUsed = data.filter;
    job.filterInstall = data.new_filter ? 'New' : 'Replacement';
    job.filterSerial = data.filter_serial_no;
    job.filterProductID = data.filterproduct_id;
    job.filterExpiryDate = data.filter_expiry_date;
    job.filterProductName = null;
    job.protectionUsed = data.protect;
    job.protectionSerial = data.protect_serial_no;
    job.waterTestComplete = data.test;
    job.waterSerialNo = data.water_serial_no;
    job.warrantyLength = data.warranty_length;
    job.systemState = data.new_system ? 'New' : 'Existing',
    job.systemRads = data.radiator_count;
    job.renewalOnCalendar = data.add_renewal;

    job.radiatorsType = data.radiators_type;
    job.pipeworkType = data.pipework_type;
    job.smartThermostatInstalled = data.smart_thermostat_installed;
    job.smartThermostatModel = data.smart_thermostat_model;

    job.customer = data.customer_by_customer_id
      ? {
        customerTitle: data.customer_by_customer_id.title,
        customerForename: data.customer_by_customer_id.first_name,
        customerSurname: data.customer_by_customer_id.surname,
        customerPhone: data.customer_by_customer_id.phone,
        customerEmail: data.customer_by_customer_id.email,
        customerAddress1: data.customer_by_customer_id.address_1,
        customerAddress2: data.customer_by_customer_id.address_2,
        customerTown: data.customer_by_customer_id.town_city,
        customerCounty: data.customer_by_customer_id.county_state,
        customerPostcode: data.customer_by_customer_id.postcode,
        customerContactByPost: data.customer_by_customer_id.contact_by_post,
        customerContactByPhone: data.customer_by_customer_id.contact_by_phone,
        customerContactByEmail: data.customer_by_customer_id.contact_by_email,
      }
      : null;

    job.user = data.user_by_user_id ? UserProfile.fromAPIData(data.user_by_user_id) : null;

    job.filterProductName = data.product_by_filterproduct_id && data.product_by_filterproduct_id.title
      ? data.product_by_filterproduct_id.title
      : null;

    job.filterInstallDate = data.warranty_by_job_id && Array.isArray(data.warranty_by_job_id) && data.warranty_by_job_id.length > 0
      ? data.warranty_by_job_id[0].install_date
      : null;

    return job;
  }

  /**
   * Creates a model instance from form data
   *
   * @param {any} formData Data from the form
   * @return {Job}
   */
  static fromFormData(formData: any): Job {
    return {
      id: formData.id.toString().trim().length === 0 ? null : formData.id.toString().trim(),
      userId: formData.userId,
      cleanerUsed: formData.cleanerUsed,
      cleanerSerial: formData.cleanerSerial,
      magnaCleanseUsed: formData.magnaCleanseUsed,
      filterUsed: formData.filterUsed,
      filterInstall: formData.filterInstall,
      //filterInstallTime:  moment(formData.filterInstallTime).format('HH:mm'),
      filterInstallDate: moment(formData.filterInstallDate).format('YYYY-MM-DD'),
      filterExpiryDate: formData.filterExpiryDate ? moment(formData.filterExpiryDate).format('YYYY-MM-DD') : null,
      filterSerial: formData.filterSerial,
      filterProductID: formData.filterProductID,
      waterTestComplete: formData.waterTestComplete,
      waterSerialNo: formData.waterSerialNo,
      protectionUsed: formData.protectionUsed,
      protectionSerial: formData.protectionSerial,
      boilerBrand: formData.boilerBrand,
      warrantyLength: formData.warrantyLength,
      systemState: formData.systemState,
      systemRads: formData.systemRads,

      radiatorsType: formData.radiatorsType,
      pipeworkType: formData.pipeworkType,
      smartThermostatInstalled: formData.smartThermostatInstalled,
      smartThermostatModel: formData.smartThermostatModel,

      customer: Customer.fromFormData(formData.customer),

      renewalOnCalendar: formData.renewalOnCalendar,
    };
  }

  /**
   * Creates a model instance from Warranty form data
   *
   * @param {any} formData Data from the Warranty form
   * @return {Job}
   */
  static fromWarrantyFormData(formData: any): Job {
    return {
      id: formData.id.toString().trim().length === 0 ? null : formData.id.toString().trim(),

      userId: formData.userId,
      cleanerUsed: false,
      cleanerSerial: null,
      magnaCleanseUsed: true,
      filterUsed: formData.filterUsed,
      filterInstall: formData.typeOfFilter,
      //filterInstallTime:  moment(formData.filterInstallTime).format('HH:mm'),
      filterInstallDate: moment(formData.dateOfInstallation).format('YYYY-MM-DD'),
      //filterExpiryDate:   moment(formData.expiryDate).format('YYYY-MM-DD'),
      filterSerial: formData.serialNumber,
      filterProductID: formData.productType,
      waterTestComplete: false,

      waterSerialNo: null,
      protectionUsed: false,
      protectionSerial: null,
      boilerBrand: formData.boilerBrand,
      warrantyLength: formData.warrantyLength,
      systemState: formData.typeOfBoiler,
      systemRads: formData.systemRads,

      radiatorsType: formData.radiatorsType,
      pipeworkType: formData.pipeworkType,
      smartThermostatInstalled: formData.smartThermostatInstalled,
      smartThermostatModel: formData.smartThermostatModel,

      customer: Customer.fromFormData(formData.customer),

      renewalOnCalendar: formData.addRenewalDateToCalendar,
    };
  }

  /**
   * Converts the model to the API data format (for a new Customer)
   *
   * @param {Job} appData
   * @return {object}
   */
  static toApiJobNewCustomer(appData: Job): any {
    const j: any = {
      customer_title: appData.customer.customerTitle,
      customer_first_name: appData.customer.customerForename,
      customer_surname: appData.customer.customerSurname,
      customer_phone: appData.customer.customerPhone,
      customer_email: appData.customer.customerEmail,
      customer_address_1: appData.customer.customerAddress1,
      customer_address_2: appData.customer.customerAddress2,
      customer_address_3: null, // TODO: add Job.customerAddress3
      customer_town_city: appData.customer.customerTown,
      customer_county_state: appData.customer.customerCounty,
      customer_postcode: appData.customer.customerPostcode,
      customer_contact_by_post: appData.customer.customerContactByPost,
      customer_contact_by_phone: appData.customer.customerContactByPhone,
      customer_contact_by_email: appData.customer.customerContactByEmail,
      boilerbrand: appData.boilerBrand,
      cleaned: appData.cleanerUsed,
      cleaner_serial_no: appData.cleanerSerial,
      magnacleanse: appData.magnaCleanseUsed,
      filter: appData.filterUsed,
      new_filter: appData.filterInstall && appData.filterInstall.toString().toUpperCase() === 'NEW',
      filter_serial_no: appData.filterSerial,
      filterproduct_id: appData.filterProductID,
      install_date: appData.filterInstallDate,
      filter_expiry_date: appData.filterExpiryDate,
      protect: appData.protectionUsed,
      protect_serial_no: appData.protectionSerial,
      test: appData.waterTestComplete,
      water_serial_no: appData.waterSerialNo,
      warranty_length: appData.warrantyLength,
      new_system: appData.systemState && appData.systemState.toString().toUpperCase() === 'NEW',
      radiator_count: appData.systemRads,
      add_renewal: appData.renewalOnCalendar,

      radiators_type: appData.radiatorsType,
      pipework_type: appData.pipeworkType,
      smart_thermostat_installed: appData.smartThermostatInstalled,
      smart_thermostat_model: appData.smartThermostatModel
    };

    if (appData.id)
      j.id = appData.id;

    if (appData.userId)
      j.save_on_behalf_of = appData.userId;

    return j;
  }

  /**
   * Converts the model to the API data format (for an existing Customer)
   *
   * @param {Job} appData
   * @return {object}
   */
  static toApiJobExistingCustomer(appData: Job): any {
    const j: any = {
      customer_title: appData.customer.customerTitle,
      customer_first_name: appData.customer.customerForename,
      customer_surname: appData.customer.customerSurname,
      customer_phone: appData.customer.customerPhone,
      customer_email: appData.customer.customerEmail,
      customer_address_1: appData.customer.customerAddress1,
      customer_address_2: appData.customer.customerAddress2,
      customer_address_3: null, // TODO: add Job.customerAddress3
      customer_town_city: appData.customer.customerTown,
      customer_county_state: appData.customer.customerCounty,
      customer_postcode: appData.customer.customerPostcode,
      customer_contact_by_post: appData.customer.customerContactByPost,
      customer_contact_by_phone: appData.customer.customerContactByPhone,
      customer_contact_by_email: appData.customer.customerContactByEmail,
      boilerbrand: appData.boilerBrand,
      cleaned: appData.cleanerUsed,
      cleaner_serial_no: appData.cleanerSerial,
      magnacleanse: appData.magnaCleanseUsed,
      filter: appData.filterUsed,
      new_filter: appData.filterInstall && appData.filterInstall.toString().toUpperCase() === 'NEW',
      filter_serial_no: appData.filterSerial,
      filterproduct_id: appData.filterProductID,
      install_date: appData.filterInstallDate,
      filter_expiry_date: appData.filterExpiryDate,
      protect: appData.protectionUsed,
      protect_serial_no: appData.protectionSerial,
      test: appData.waterTestComplete,
      water_serial_no: appData.waterSerialNo,
      warranty_length: appData.warrantyLength,
      new_system: appData.systemState && appData.systemState.toString().toUpperCase() === 'NEW',
      radiator_count: appData.systemRads,
      add_renewal: appData.renewalOnCalendar,

      radiators_type: appData.radiatorsType,
      pipework_type: appData.pipeworkType,
      smart_thermostat_installed: appData.smartThermostatInstalled,
      smart_thermostat_model: appData.smartThermostatModel
    };

    if (appData.id)
      j.id = appData.id;

    return j;
  }

  static toApiAdminJob(appData: any): any {
    const j: any = {
      customer_title: appData.customer.customerTitle,
      customer_surname: appData.customer.customerSurname,
      customer_phone: appData.customer.customerPhone,
      customer_email: appData.customer.customerEmail,
      customer_address_1: appData.customer.customerAddress1,
      customer_address_2: appData.customer.customerAddress2,
      customer_town_city: appData.customer.customerTown,
      customer_county_state: appData.customer.customerCounty,
      customer_postcode: appData.customer.customerPostcode,

      filter_serial_no: appData.serialNumber,
      filterproduct_id: appData.productId,
      install_date: appData.dateOfInstallation,
      renewalOnCalendar: appData.addRenewalDateToCalendar,
      filterProductID: appData.productType,
      systemState: appData.typeOfBoiler,
      filterInstall: appData.filterInstall,
      new_system: appData.new_system,
    }

    if (appData.id)
      j.id = appData.id;

    if (appData.userId)
      j.save_on_behalf_of = appData.userId;

    return j;
  }

  /**
   * Returns an example model
   *
   * @return {Job}
   */
  static getExample(): Job {
    return {
      id: Math.floor(Math.random() * 1000).toString(),
      userId: Math.floor(Math.random() * 1000).toString(),
      committed: true,
      cleanerUsed: Math.floor(Math.random() * 10) % 2 === 0,
      cleanerSerial: 'cleanerabcd1234',
      magnaCleanseUsed: Math.floor(Math.random() * 10) % 2 === 0,
      filterUsed: Math.floor(Math.random() * 10) % 2 === 0,
      filterInstall: 'New',
      //filterInstallTime: moment().format('HH:mm:ss'),
      filterInstallDate: moment().subtract(Math.floor(Math.random() * 50), 'days').format('YYYY-MM-DD'),
      filterExpiryDate: moment().add(Math.floor(Math.random() * 50), 'days').format('YYYY-MM-DD'),
      filterSerial: 'filterabcd1234',
      filterProductID: Math.floor(Math.random() * 1000).toString(),
      waterTestComplete: Math.floor(Math.random() * 10) % 2 === 0,
      waterSerialNo: 'test33553',
      protectionUsed: Math.floor(Math.random() * 10) % 2 === 0,
      protectionSerial: 'protectabcd1234',
      boilerBrand: 'Boiler Brand Ltd.',
      warrantyLength: Math.floor(Math.random() * 50) + 10,
      systemState: 'New',
      systemRads: Math.floor(Math.random() * 10) + 3,
      customer: Customer.getExample(),

      renewalOnCalendar: false
    };
  }
}


export interface FetchJobRequest {
  id: number;
}

export interface FetchJobResponse {
  job: Job;
  error: string;
  message: string;
}

export interface FetchJobPdfRequest {
  id: string;
}

export interface FetchJobPdfResponse {
  error: string;
  pdfData: string;
}

export class RegisterJobRequest {
  job: Job;

  static fromJob(job: Job): RegisterJobRequest {
    return { job };
  }
}

export interface RegisterJobResponse {
  error: boolean;
  message: string;
}

export interface UpdateJobRequest {
  job: Job;
}

export interface UpdateJobResponse {
  error: boolean;
  job: Job;
  message: string;
}
