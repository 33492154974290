import { Component, OnInit, Input } from '@angular/core';

import { ResourceItem } from '../../../models/resources';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { YoutubeModalComponent }  from '../../common/youtube-modal/youtube-modal.component';

/**
 * Summary
 *    Display the content of a given resource item.
 *    
 *
 * Description
 *    Will display the content of a given resource item, items will either
 *    consist of a download link or a video player.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['./content-item.component.scss']
})
export class ContentItemComponent implements OnInit {
  // The data for the resource item
  @Input() resourceItem: ResourceItem;
  // Flag to determine whether this component is being displayed on a page or within a widget
  @Input() widget: boolean;

  /**
   * Constructor for page
   *
   * @param {MatDialog} dialog        Initialises a MatDialog component so that a new modal can be created
   * @param {DomSanitizer} sanitizer  Initialises a DomSanitizer component in order to bypass Angular's sanitizer
   */
  constructor(
    private dialog: MatDialog, 
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  /**
   * Called when a user taps the featured image of video item, will attempt to open a new YoutubeModalComponent within a popup.
   *
   * @param {any} resource Data for the selected resource
   */
  openVideoPlayer(resource: any) {
    if (resource.itemType === 'VIDEO' && !this.widget) {
      // If the resource type is VIDEO, open a new dialog
      const dialogRef = this.dialog.open(YoutubeModalComponent, {
        // Pass the resource url(id) through to the modal, ensuring it has been sanitized first,
        // otherwise Angular will refuse to play it as it deems it unsafe html
        data: {
          videoUrl: this.getSafeURL(resource.url),
        },
        panelClass: 'feature-modal-dialog',
      });
    }
  }
  
  /**
   * When called, will return a html safe url that bypasses Angular's sanitizer
   *
   * @param {string} videoUrl  the video url(id) to be sanitized
   *
   * @return {SafeUrl}  created from the video url(id)
   */
  getSafeURL(videoUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }
}
