
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
/**
 * Mock for LoginService (see main service for full details)
 */



import { Injectable } from '@angular/core';

import {
  ActivateUserRequest,
  ActivateUserResponse,
  ConfirmProfileRequest,
  ConfirmProfileResponse,
  CurrentUser,
  FetchUserProfileResponse,
  LoadLoginTokenResponse,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TokenCheckRequest,
} from '../models/login';
import { UpdateUserProfileRequest, UpdateUserProfileResponse, UserProfile } from '../models/user-profile';


@Injectable()
export class LoginService {

  activateUser(req: ActivateUserRequest): Observable<ActivateUserResponse> {
    const valid = req.token === 'valid-token' && req.email;
    return observableOf({
        error: valid ? null : 'Unable to active user account: token is invalid',
        message: valid ? 'Account successfully activated' : null,
      }).pipe(
      delay(1500));
  }

  public checkToken(req: TokenCheckRequest): Observable<any> {
    const valid = req.token === 'valid-token';
    return observableOf({
        ready: true,
        valid,
        error: valid ? null : `"${req.token}" does not equal "valid-token"`,
      }).pipe(
      delay(1000));
  }

  public confirmProfile(req: ConfirmProfileRequest): Observable<ConfirmProfileResponse> {
    if (req.profile.firstName === 'INVALID')
      return observableOf({
          error: 'Unable to update profile',
          profile: null,
        }).pipe(
        delay(1000));
    else
      return observableOf({
          error: null,
          profile: req.profile,
        }).pipe(
        delay(1000));
  }

  public fetchProfile(): Observable<FetchUserProfileResponse> {
    return observableOf({
        error: null,
        profile: UserProfile.getExample(),
      }).pipe(
      delay(1000));
  }

  public loadToken(): Observable<LoadLoginTokenResponse> {
    return observableOf({
      error: null,
      token: 'valid-token',
    });
  }

  public login(req: LoginRequest): Observable<LoginResponse> {
    const valid = req.email !== 'INVALID';
    const admin = req.email === 'admin';
    return observableOf({
        ready: true,
        error: valid ? null : 'Invalid email or password',
        user: valid
          ? <CurrentUser>{
            email: req.email,
            title: 'Mr.',
            forename: 'Test',
            surname: 'User',
            role_id: 2,
            permissions: [],
            active: true,
            admin,
          }
          : null,
        profile: UserProfile.getExample(),
        token: valid ? 'valid-token' : null,
      }).pipe(
      delay(1000));
  }

  logout() {
  }

  public resetPassword(req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    const valid = req.token && req.password === req.passwordConfirm;

    return observableOf({
        error: valid ? null : 'Unable to reset password',
        message: valid
          ? 'Your password has been reset successfully. You may now login with this new password.'
          : null,
      }).pipe(
      delay(1000));
  }

  public updateUserProfile(req: UpdateUserProfileRequest): Observable<UpdateUserProfileResponse> {
    const valid = req.profile.firstName !== 'INVALID';
    return observableOf({
        error: valid ? null : 'Unable to update profile details: first name is "INVALID"',
        profile: valid ? req.profile : null,
      }).pipe(
      delay(1000));
  }

}
