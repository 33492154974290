/**
 * register-job actions: defines all actions for registering Jobs.
 */

import { Action } from '@ngrx/store';

// Import models
import {
  RegisterJobRequest,
  RegisterJobResponse,
  FetchJobRequest,
  FetchJobResponse,
  FetchJobPdfRequest,
  FetchJobPdfResponse,
  UpdateJobRequest,
  UpdateJobResponse,
} from '../../models/job';

// Action type definitions
export const REGISTER_JOB_INIT = 'REGISTER_JOB_INIT';
export const REGISTER_JOB_REQUEST = 'REGISTER_JOB_REQUEST';
export const REGISTER_JOB_RESPONSE = 'REGISTER_JOB_RESPONSE';
export const INIT_JOB_REQUEST = 'INIT_JOB_REQUEST';
export const FETCH_JOB_REQUEST = 'FETCH_JOB_REQUEST';
export const FETCH_JOB_RESPONSE = 'FETCH_JOB_RESPONSE';
export const FETCH_JOB_PDF_REQUEST = 'FETCH_JOB_PDF_REQUEST';
export const FETCH_JOB_PDF_RESPONSE = 'FETCH_JOB_PDF_RESPONSE';
export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_RESPONSE = 'UPDATE_JOB_RESPONSE';

// Action definitions
export class RegisterJobInitAction implements Action {
  readonly type = REGISTER_JOB_INIT;
}

export class RegisterJobRequestAction implements Action {
  readonly type = REGISTER_JOB_REQUEST;
  constructor(public payload: RegisterJobRequest) { }
}

export class RegisterJobResponseAction implements Action {
  readonly type = REGISTER_JOB_RESPONSE;
  constructor(public payload: RegisterJobResponse) { }
}

export class InitJobRequestAction implements Action {
  readonly type = INIT_JOB_REQUEST;
}

export class FetchJobRequestAction implements Action {
  readonly type = FETCH_JOB_REQUEST;
  constructor(public payload: FetchJobRequest) { }
}

export class FetchJobResponseAction implements Action {
  readonly type = FETCH_JOB_RESPONSE;
  constructor(public payload: FetchJobResponse) { }
}

export class FetchJobPdfRequestAction implements Action {
  readonly type = FETCH_JOB_PDF_REQUEST;
  constructor(public payload: FetchJobPdfRequest) { }
}

export class FetchJobPdfResponseAction implements Action {
  readonly type = FETCH_JOB_PDF_RESPONSE;
  constructor(public payload: FetchJobPdfResponse) { }
}

export class UpdateJobRequestAction implements Action {
  readonly type = UPDATE_JOB_REQUEST;
  constructor(public payload: UpdateJobRequest) { }
}

export class UpdateJobResponseAction implements Action {
  readonly type = UPDATE_JOB_RESPONSE;
  constructor(public payload: UpdateJobResponse) { }
}

// Export Actions type as a union of all defined actions
export type Actions =
  | RegisterJobInitAction
  | RegisterJobRequestAction
  | RegisterJobResponseAction
  | InitJobRequestAction
  | FetchJobRequestAction
  | FetchJobResponseAction
  | FetchJobPdfRequestAction
  | FetchJobPdfResponseAction
  | UpdateJobRequestAction
  | UpdateJobResponseAction;
