/**
 * login-details actions: defines all actions relating to a user's account,
 * such as logging in, checking token, registering, updating profile.
 */
import { Action } from '@ngrx/store';

import {
  ActivateUserRequest,
  ActivateUserResponse,
  ConfirmProfileRequest,
  ConfirmProfileResponse,
  CreatePromoShopTokenResponse,
  FetchUserProfileResponse,
  LoadLoginTokenResponse,
  LoginRequest,
  LoginResponse,
  ModalWaterTesterRegisterRequest,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TokenCheckRequest,
  TokenCheckResponse,
  WaterTesterRegisterRequest,
  WaterTesterRegisterResponse,
} from '../../models/login';
import { FetchAccreditationsResponse, UpdateUserProfileRequest, UpdateUserProfileResponse } from '../../models/user-profile';


// Import models
// Action type definitions
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_RESPONSE = 'ACTIVATE_USER_RESPONSE';
export const CHECK_LOGIN_TOKEN_REQUEST = 'CHECK_LOGIN_TOKEN_REQUEST';
export const CHECK_LOGIN_TOKEN_RESPONSE = 'CHECK_LOGIN_TOKEN_RESPONSE';
export const CONFIRM_PROFILE_REQUEST = 'CONFIRM_PROFILE_REQUEST';
export const CONFIRM_PROFILE_RESPONSE = 'CONFIRM_PROFILE_RESPONSE';
export const CREATE_PROMOSHOP_TOKEN_REQUEST = 'CREATE_PROMOSHOP_TOKEN_REQUEST';
export const CREATE_PROMOSHOP_TOKEN_RESPONSE = 'CREATE_PROMOSHOP_TOKEN_RESPONSE';
export const FETCH_ACCREDITATIONS_REQUEST = 'FETCH_ACCREDITATIONS_REQUEST';
export const FETCH_ACCREDITATIONS_RESPONSE = 'FETCH_ACCREDITATIONS_RESPONSE';
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_RESPONSE = 'FETCH_USER_PROFILE_RESPONSE';
export const LOAD_LOGIN_TOKEN_REQUEST = 'LOAD_LOGIN_TOKEN_REQUEST';
export const LOAD_LOGIN_TOKEN_RESPONSE = 'LOAD_LOGIN_TOKEN_RESPONSE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_EXISTING_REQUEST = 'LOGIN_EXISTING_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT = 'LOGOUT';
export const RESEND_ACTIVATION_EMAIL_REQUEST = 'RESEND_ACTIVATION_EMAIL_REQUEST';
export const RESEND_ACTIVATION_EMAIL_RESPONSE = 'RESEND_ACTIVATION_EMAIL_RESPONSE';
export const REQUEST_RESET_PASSWORD_REQUEST = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_RESPONSE = 'REQUEST_RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_RESPONSE = 'UPDATE_USER_PROFILE_RESPONSE';
export const LOGIN_WATER_TESTER_REQUEST = 'LOGIN_WATER_TESTER_REQUEST';
export const LOGIN_WATER_TESTER_RESPONSE = 'LOGIN_WATER_TESTER_RESPONSE';
export const MODAL_REGISTER_WATER_TESTER_REQUEST = 'MODAL_REGISTER_WATER_TESTER_REQUEST';
export const REGISTER_WATER_TESTER_REQUEST = 'REGISTER_WATER_TESTER_REQUEST';
export const REGISTER_WATER_TESTER_RESPONSE = 'REGISTER_WATER_TESTER_RESPONSE';

// Action definitions
export class ActivateUserRequestAction implements Action {
  readonly type = ACTIVATE_USER_REQUEST;
  constructor(public payload: ActivateUserRequest) { }
}

export class ActivateUserResponseAction implements Action {
  readonly type = ACTIVATE_USER_RESPONSE;
  constructor(public payload: ActivateUserResponse) { }
}

export class CheckLoginTokenRequestAction implements Action {
  readonly type = CHECK_LOGIN_TOKEN_REQUEST;
  constructor(public payload: TokenCheckRequest) { }
}

export class CheckLoginTokenResponseAction implements Action {
  readonly type = CHECK_LOGIN_TOKEN_RESPONSE;
  constructor(public payload: TokenCheckResponse) { }
}

export class ConfirmProfileRequestAction implements Action {
  readonly type = CONFIRM_PROFILE_REQUEST;
  constructor(public payload: ConfirmProfileRequest) { }
}

export class ConfirmProfileResponseAction implements Action {
  readonly type = CONFIRM_PROFILE_RESPONSE;
  constructor(public payload: ConfirmProfileResponse) { }
}

export class CreatePromoShopTokenRequestAction implements Action {
  readonly type = CREATE_PROMOSHOP_TOKEN_REQUEST;
}

export class CreatePromoShopTokenResponseAction implements Action {
  readonly type = CREATE_PROMOSHOP_TOKEN_RESPONSE;
  constructor(public payload: CreatePromoShopTokenResponse) { }
}

export class FetchAccreditationsRequestAction implements Action {
  readonly type = FETCH_ACCREDITATIONS_REQUEST;
}

export class FetchAccreditationsResponseAction implements Action {
  readonly type = FETCH_ACCREDITATIONS_RESPONSE;
  constructor(public payload: FetchAccreditationsResponse) { }
}

export class FetchUserProfileRequestAction implements Action {
  readonly type = FETCH_USER_PROFILE_REQUEST;
  constructor() { }
}

export class FetchUserProfileResponseAction implements Action {
  readonly type = FETCH_USER_PROFILE_RESPONSE;
  constructor(public payload: FetchUserProfileResponse) { }
}

export class LoadLoginTokenRequestAction implements Action {
  readonly type = LOAD_LOGIN_TOKEN_REQUEST;
  constructor() { }
}

export class LoadLoginTokenResponseAction implements Action {
  readonly type = LOAD_LOGIN_TOKEN_RESPONSE;
  constructor(public payload: LoadLoginTokenResponse) { }
}

export class LoginRequestAction implements Action {
  readonly type = LOGIN_REQUEST;
  constructor(public payload: LoginRequest) { }
}

export class LoginExistingRequestAction implements Action {
  readonly type = LOGIN_EXISTING_REQUEST;
  constructor(public payload: LoginRequest) { }
}

export class LoginResponseAction implements Action {
  readonly type = LOGIN_RESPONSE;
  constructor(public payload: LoginResponse) { }
}

export class LogoutAction implements Action {
  readonly type = LOGOUT;
}

export class ResendActivationEmailRequestAction implements Action {
  readonly type = RESEND_ACTIVATION_EMAIL_REQUEST;
  // We can use the password reset model as it is identical
  constructor(public payload: RequestResetPasswordRequest) { }
}

export class ResendActivationEmailResponseAction implements Action {
  readonly type = RESEND_ACTIVATION_EMAIL_RESPONSE;
  // We can use the password reset model as it is identical
  constructor(public payload: RequestResetPasswordResponse) { }
}

export class RequestResetPasswordRequestAction implements Action {
  readonly type = REQUEST_RESET_PASSWORD_REQUEST;
  constructor(public payload: RequestResetPasswordRequest) { }
}

export class RequestResetPasswordResponseAction implements Action {
  readonly type = REQUEST_RESET_PASSWORD_RESPONSE;
  constructor(public payload: RequestResetPasswordResponse) { }
}

export class ResetPasswordRequestAction implements Action {
  readonly type = RESET_PASSWORD_REQUEST;
  constructor(public payload: ResetPasswordRequest) { }
}

export class ResetPasswordResponseAction implements Action {
  readonly type = RESET_PASSWORD_RESPONSE;
  constructor(public payload: ResetPasswordResponse) { }
}

export class UpdateUserProfileResponseAction implements Action {
  readonly type = UPDATE_USER_PROFILE_RESPONSE;
  constructor(public payload: UpdateUserProfileResponse) { }
}

export class UpdateUserProfileRequestAction implements Action {
  readonly type = UPDATE_USER_PROFILE_REQUEST;
  constructor(public payload: UpdateUserProfileRequest) { }
}

export class LoginWaterTesterRequestAction implements Action {
  readonly type = LOGIN_WATER_TESTER_REQUEST;
  constructor(public payload: LoginRequest) { }
}

export class LoginWaterTesterResponseAction implements Action {
  readonly type = LOGIN_WATER_TESTER_RESPONSE;
  constructor(public payload: LoginResponse) { }
}


export class ModalRegisterWaterTesterRequestAction implements Action {
  readonly type = MODAL_REGISTER_WATER_TESTER_REQUEST;
  constructor(public payload: ModalWaterTesterRegisterRequest) { }
}

export class RegisterWaterTesterRequestAction implements Action {
  readonly type = REGISTER_WATER_TESTER_REQUEST;
  constructor(public payload: WaterTesterRegisterRequest) {
  }
}

export class RegisterWaterTesterResponseAction implements Action {
  readonly type = REGISTER_WATER_TESTER_RESPONSE;
  constructor(public payload: WaterTesterRegisterResponse) {
  }
}

// Export Actions type as a union of all defined actions
export type Actions =
  | ActivateUserRequestAction
  | ActivateUserResponseAction
  | CheckLoginTokenRequestAction
  | CheckLoginTokenResponseAction
  | ConfirmProfileRequestAction
  | ConfirmProfileResponseAction
  | CreatePromoShopTokenRequestAction
  | CreatePromoShopTokenResponseAction
  | FetchUserProfileRequestAction
  | FetchUserProfileResponseAction
  | LoadLoginTokenRequestAction
  | LoadLoginTokenResponseAction
  | LoginRequestAction
  | LoginExistingRequestAction
  | LoginResponseAction
  | LogoutAction
  | ResendActivationEmailRequestAction
  | ResendActivationEmailResponseAction
  | RequestResetPasswordRequestAction
  | RequestResetPasswordResponseAction
  | ResetPasswordRequestAction
  | ResetPasswordResponseAction
  | UpdateUserProfileRequestAction
  | UpdateUserProfileResponseAction
  | FetchAccreditationsRequestAction
  | FetchAccreditationsResponseAction
  | LoginWaterTesterRequestAction
  | LoginWaterTesterResponseAction
  | ModalRegisterWaterTesterRequestAction
  | RegisterWaterTesterRequestAction
  | RegisterWaterTesterResponseAction
  ;
