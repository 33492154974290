<div class="container-fluid">
	<div class="row content-page" *ngIf="adminStats$ | async; let adminStats">
		<div class="container-title">
			<h1 class="white">Admin Dashboard</h1>
		</div>

		<div class="row" *ngIf="adminStats.error">
			<div class="section-container background-white col-xs-12">
				<app-message-panel kind="ERROR">{{adminStats.error}}</app-message-panel>
			</div>
		</div>
		<div class="row" *ngIf="adminStats.pending">
			<div class="section-container background-white col-xs-12">
				<app-waiting-spinner kind="bar">Loading statistics...</app-waiting-spinner>
			</div>
		</div>
		<form #downloadForm ngNoForm target="_blank" [action]="getFormAction(filterForm)" method="post"
			[formGroup]="filterForm" (ngSubmit)="filter(filterForm)">
			<input type="hidden" name="download_type" [value]="formDownloadType" />
			<input type="hidden" name="product_id" [value]="formDownloadProductId" />
			<input type="hidden" name="tool_id" [value]="formDownloadToolId" />

			<div class="overview-stats background-white"
				*ngIf="adminStats.stats && adminStats.stats.overview && !adminStats.pending" id="overview-stats">
				<h2 class="color-adey-red medium">Overview<span *ngIf="Region"> - {{Region}}</span></h2>
				<div class="row">

					<div class="col-xs-12 col-sm-4">
						<mat-form-field>
							<mat-select formControlName="region" placeholder="Region">
								<mat-option [value]="null">-- Any --</mat-option>
								<mat-option *ngFor="let r of regions" [value]="r.field">{{r.label}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-xs-12 col-sm-6" formGroupName="dates">
						<div class="col-xs-12 col-sm-6">
							<mat-form-field id="mindatediv">
								<input matInput [matDatepicker]="startPicker" formControlName="minDate"
									placeholder="Start date" [(ngModel)]="minDate" class="filterDate" id="minDate" />
								<mat-datepicker-toggle [for]="startPicker" class="date-picker-button" *ngIf="!readOnly">
								</mat-datepicker-toggle>
								<mat-error>Invalid date</mat-error>
							</mat-form-field>
							<mat-datepicker #startPicker (selectedChanged)="onStartDateChange($event)"></mat-datepicker>
						</div>
						<div class="col-xs-12 col-sm-6">
							<mat-form-field id="maxdatediv">
								<input matInput [matDatepicker]="endPicker" formControlName="maxDate"
									placeholder="End Date" [(ngModel)]="maxDate" class="filterDate" id="maxDate" />
								<mat-datepicker-toggle [for]="endPicker" class="date-picker-button" *ngIf="!readOnly">
								</mat-datepicker-toggle>
								<mat-error>Invalid date</mat-error>
							</mat-form-field>
							<mat-datepicker #endPicker></mat-datepicker>
							<mat-datepicker #endPicker (selectedChanged)="onEndDateChange($event)"></mat-datepicker>
						</div>
					</div>
					<div class="col-xs-12 col-sm-2">
						<input _ngcontent-c30="" class="btn btn-default" type="submit" value="Filter"
							id="filter-button">
					</div>
				</div>
				<div class="section-container border-box border-lighter-gray col-xs-12">
					<table class="table table-striped stats-table">
						<thead>
							<tr>
								<th class="row-label"></th>
								<th class="spacer"></th>
								<th class="row-total color-green">Total</th>
								<th class="mini-spacer"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let s of overviewStatsFields">
								<td class="row-label">{{s.label}}</td>
								<th class="spacer"></th>
								<td *ngIf="!s.download" class="row-total background-green color-white">
									{{adminStats.stats.overview[s.field] | number}}
								</td>
								<td *ngIf="s.download" class="row-total background-green color-white">
									<span class="downloadable"
										*ngIf="adminStats.stats.overview[s.field] > 0; else showNone"
										(click)="downloadStats(s.download, '0', s.directDownload, null, s.toolId)">
										{{ adminStats.stats.overview[s.field] | number }}
									</span>
									<ng-template #showNone>0</ng-template>
								</td>
								<td class="mini-spacer"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="warranty-stats background-white"
				*ngIf="adminStats.stats && adminStats.stats.products && adminStats.stats.products.length > 0 && !adminStats.pending">
				<h2 class="color-adey-red medium">Products registered</h2>
				<div class="section-container border-box border-lighter-gray col-xs-12">
					<table class="table table-striped stats-table">
						<thead>
							<tr>
								<th class="row-label">Product name</th>
								<th class="spacer"></th>
								<th class="row-total color-green">Warranty submissions</th>
								<th class="mini-spacer"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let s of adminStats.stats.products">
								<td class="row-label">{{s.title}}</td>
								<th class="spacer"></th>
								<td class="row-total background-green color-white">
									<span class="downloadable" *ngIf="s.warrantySubmissions > 0; else showZero"
										(click)="downloadStats('warranties_by_product', s.id, false, null)">
										{{s.warrantySubmissions}}
									</span>
									<ng-template #showZero>0</ng-template>
								</td>
								<td class="mini-spacer"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</form>
	</div>
</div>