import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Feature } from '../../models/feature';
import * as actions from '../actions/feature';

export interface State extends EntityState<Feature> {
  error: string;
  pending: boolean;
}

export const adapter: EntityAdapter<Feature> = createEntityAdapter<Feature>();

export const initialState: State = adapter.getInitialState({
  error: null,
  pending: false,
});

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.GET_FEATURES:
      return { ...state, ...{ pending: true } };
    case actions.GET_FEATURES_SUCCESS:
      return adapter.addMany(action.payload, state);
    case actions.GET_FEATURES_FAILURE:
      return { ...state, ...{ error: action.error.message } };
    default:
      return state;
  }
}
