import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchAdminStatsDownloadResponse, FetchAdminStatsResponse } from '../../models/admin-stats';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/admin-stats';

@Injectable()
export class AdminStatsEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchAdminStatsRequestAction, call AdminService::fetchStats() and
   * dispatch a new FetchAdminStatsResponseAction with the response.
   */
   fetchStats$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_STATS_REQUEST),
    switchMap((req: ActionTypes.FetchAdminStatsRequestAction): Observable<ActionTypes.FetchAdminStatsResponseAction> =>
      this.service.fetchStats(req.payload).pipe(
        map((res: FetchAdminStatsResponse): ActionTypes.FetchAdminStatsResponseAction =>
          new ActionTypes.FetchAdminStatsResponseAction(res)
        ))
    )));
  /*
    @Effect() fetchEvents$: Observable<Action> = this.actions$
      .ofType(ActionTypes.FETCH_ADMIN_EVENTS_REQUEST)
      .switchMap((req: ActionTypes.FetchAdminEventsRequestAction): Observable<ActionTypes.FetchAdminEventsResponseAction> =>
        this.service.fetchEvents(req.payload)
          .map((res: FetchAdminEventsResponse): ActionTypes.FetchAdminEventsResponseAction =>
            new ActionTypes.FetchAdminEventsResponseAction(res)
          )
      );
  */


  /**
   * For a FetchAdminStatsDownloadRequestAction, call
   * AdminService::fetchStatsDownload() and dispatch a new
   * FetchAdminStatsDownloadResponseAction with the response.
   */
   fetchStatsDownload$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_ADMIN_STATS_DOWNLOAD_REQUEST),
    switchMap((req: ActionTypes.FetchAdminStatsDownloadRequestAction): Observable<ActionTypes.FetchAdminStatsDownloadResponseAction> =>
      this.service.fetchStatsDownload(req.payload).pipe(
        map((res: FetchAdminStatsDownloadResponse): ActionTypes.FetchAdminStatsDownloadResponseAction =>
          new ActionTypes.FetchAdminStatsDownloadResponseAction(res)
        ))
    )));
}
