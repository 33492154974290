<h2 matDialogTitle>Edit Profile</h2>

<mat-dialog-content>
  <app-profile-details *ngIf="data.userProfile" [profileData]="data.userProfile" [roles]="data.roles"
    (onFormChange)="formChanged = $event" (onSubmit)="onSaveDetails($event)" [showCustomerTypeSelect]="false"
    [showTerms]="false" [showSubmit]="false" [accreditations]="data.accreditations" [adminUser]="false">
  </app-profile-details>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button matDialogClose>Close</button>
  <button mat-button *ngIf="!newPassword && !formChanged" (click)="onSave()">Save</button>
  <button mat-raised-button color="primary" *ngIf="newPassword || formChanged" (click)="onSave()">Save</button>
  <button mat-button *ngIf="!newPassword" (click)="openResetPassword()">Update password</button>
  <button mat-button *ngIf="newPassword" (click)="newPassword = null">Cancel password update</button>
</mat-dialog-actions>