<div class="container-fluid login-component">
  <div class="row background-beige">
    <div class="col-xs-12 feature-container container">
      <img src="assets/i/common/logos/adey-pro-club-logo-white.png" alt="ADEY® ProClub Logo" class="logo"/>
      <img class="feature-img transform-xy-offset-50" alt="ADEY® ProClub Member" src="assets/i/login/adey-pro-club-installer.png">

      <div class="feature-content">
        <h1>Welcome To The ADEY<span>&reg;</span> ProClub<span>&trade;</span></h1>
        <div class="description-block">
          <p>Together we’re the real deal</p>
        </div>
      </div>
    </div>


    <div class="login-container col-xs-12 background-beige">
      <div class="login-gradient-sidebar"></div>
      <div class="login-content">

        <div class="login-icon">
            <img alt="Profile Icon" src="assets/i/login/login-icon.jpg">
        </div>

        <app-page-content page="LOGIN" field="intro"></app-page-content>

        <app-message-panel kind="ERROR" *ngIf="(loginDetails$|async).error">
          {{(loginDetails$|async).error}}
        </app-message-panel>
        <app-message-panel kind="SUCCESS" *ngIf="(loginDetails$|async).resetMessage">
          {{(loginDetails$|async).resetMessage}}
        </app-message-panel>
        <app-message-panel kind="SUCCESS" *ngIf="(loginDetails$|async).activationMessage">
          {{(loginDetails$|async).activationMessage}}
        </app-message-panel>
        <app-message-panel kind="ERROR" *ngIf="(loginDetails$|async).resetError">
          {{(loginDetails$|async).resetError}}
        </app-message-panel>

        <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmitForm()">
          <div [class]="formGroupClass('username')" >
            <input type="email"
              id="username"
              class="form-control"
              formControlName="username"
              placeholder="Email address">
          </div>

          <div [class]="formGroupClass('password')"  >
            <input type="password"
              id="password"
              class="form-control"
              formControlName="password"
              placeholder="Password">
          </div>
          <p class="password-requirements">Your password must be at least 8 characters and contain at least 1 lowercase, uppercase and special character.</p>

        <!-- t&c's -->
        <div [class]="formGroupClass('termsAndConditionsConfirm')">
            <mat-checkbox md-no-ink class="md-primary terms" formControlName="termsAndConditionsConfirm">
                Confirm that you agree to our <a href="http://www.adey.com/terms-and-conditions" target="_blank">terms &amp; conditions</a>
            </mat-checkbox>
        </div>
        <!-- /t&c's -->

        <!-- privacy policy -->
        <div [class]="formGroupClass('privacyPolicyConfirm')"  class="terms">
            <mat-checkbox md-no-ink class="md-primary terms" formControlName="privacyPolicyConfirm">
                Confirm that you agree to our <a href="http://www.adey.com/privacy-policy" target="_blank">data protection policy</a>
            </mat-checkbox>
        </div>
        <!-- /privacy policy -->

        <button type="submit" class="btn btn-full-width background-adey-red white-theme"
            [disabled]="!loginForm.valid">

            <app-waiting-spinner colorType="primary" class="color-white hover-color-white" kind="spinner" *ngIf="(loginDetails$|async).pending"></app-waiting-spinner>
            <span class="color-white hover-color-white" *ngIf="!(loginDetails$|async).pending" >Log in</span>
        </button>

        </form>
        <div class="sign-in-options color-gray">
          <p>Not signed up? <button class="btn btn-link no-pad red-brand " (click)="goToRegister()">Create a profile</button></p>
          <p><button class="btn btn-link no-pad red-brand" (click)="goToForgotten()">Forgotten password?</button></p>
        </div>
      </div>
    </div>
  </div>
</div>
