import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-reset-password-response-modal',
  templateUrl: './reset-password-response-modal.component.html',
  styleUrls: ['./reset-password-response-modal.component.scss']
})
export class ResetPasswordResponseModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { success: boolean },
  ) { }
}
