<h2 *ngIf="!data.user" matDialogTitle>Add new user</h2>
<h2 *ngIf="data.user" matDialogTitle>Edit user</h2>

<mat-dialog-content>
  <form [formGroup]="fgAdmin" novalidate>
    <div class="row">
      <mat-radio-group formControlName="userType">
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-checkbox class="md-primary" formControlName="active">Active</mat-checkbox>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-checkbox class="md-primary" formControlName="pointsClaimable">Can spend points</mat-checkbox>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-radio-button [value]="1">User is admin</mat-radio-button>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-radio-button [value]="2">Installer (ProClub)</mat-radio-button>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-radio-button [value]="3">Homeowner (HomeZone)</mat-radio-button>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-radio-button [value]="6">Pro3 Sense Contractor (No Pro3 Rewards)</mat-radio-button>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <mat-radio-button [value]="7">Contractor (No ProClub Rewards)</mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </form>

  <app-profile-details [adminUser]="fgAdmin.value.admin" [profileData]="profileData"
    [isHomeowner]="profileData?.userType === 3" [roles]="data.roles" [allowPasswordField]="true"
    [showCustomerTypeSelect]="false" [showTerms]="false" [showSubmit]="false" [accreditations]="data.accreditations"
    [businessToolRegistrations]="data?.user?.businessToolRegistrations" (onSubmit)="onProfileDataSubmit($event)">
  </app-profile-details>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>