/**
 * admin-stats reducer: maintains state for the admin dashboard
 */

import { AdminStats } from '../../models/admin-stats';

import * as actions from '../actions/admin-stats';


// State interface definition: stores the current statistics obtained from the
// API
export interface State {
  stats:             AdminStats;
  pending:           boolean;
  error:             string;
  message:           string;
  downloadPending:   boolean;
  downloadError:     string;
  downloadType:      string;
  downloadRegion:	 string;
  downloadMinDate:	 string;
  downloadMaxDate:	 string;
  downloadProductId: number;
  downloadData:      string;
}


const defaultState: State = {
  stats:             null,
  pending:           false,
  error:             null,
  message:           null,
  downloadPending:   false,
  downloadError:     null,
  downloadType:      null,
  downloadRegion:	 null,
  downloadMinDate:	 null,
  downloadMaxDate:	 null,
  downloadProductId: null,
  downloadData:      null,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch the latest statistics from the API
     */
    case actions.FETCH_ADMIN_STATS_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error:   null,
      });

    /**
     * Response from fetching latest statistics from the API
     */
    case actions.FETCH_ADMIN_STATS_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error: action.payload.error,
        stats: action.payload.error ? state.stats : action.payload.stats,
      });

    /**
     * Request to download a specified statistics CSV
     */
    case actions.FETCH_ADMIN_STATS_DOWNLOAD_REQUEST:
  	  return Object.assign({}, state, {
        downloadPending:   	true,
        downloadError:     	null,
        downloadType:      	action.payload.download_type,
        downloadProductId: 	action.payload.product_id,
        downloadRegion: 	action.payload.region,
        downloadMinDate: 	action.payload.minDate,
        downloadMaxDate: 	action.payload.maxDate,
      });

    /**
     * Response from downloading a statistics CSV
     */
    case actions.FETCH_ADMIN_STATS_DOWNLOAD_RESPONSE:
      return Object.assign({}, state, {
        downloadPending: false,
        downloadError:   action.payload.error,
        downloadData:    action.payload.data,
      });

    default:
      return state;
  }
}
