<div [class]="'spinner-container' + (kind === 'spinner' ? ' spinner-inline' : '')">
  <div class="spinner">
    <mat-progress-bar
      *ngIf="kind === 'bar'"
      [color]="colorType"
      mode="indeterminate"
    ></mat-progress-bar>
    <mat-progress-spinner
      *ngIf="kind === 'spinner'"
      [diameter]="spinnerSize"
      [strokeWidth]="spinnerStroke"
      [color]="colorType"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
