import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CountryResponse } from '../../models/country';
import { CountryService } from '../../services/country.service';
import * as ActionTypes from '../actions/country';

@Injectable()
export class CountryEffects {
  constructor(
    private countryService: CountryService,
    private actions$: Actions
  ) { }

   countryRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.COUNTRY_REQUEST),
    switchMap((): Observable<Action> => {
      return this.countryService.getCountries().pipe(
        map((res: CountryResponse): ActionTypes.CountryResponseAction => new ActionTypes.CountryResponseAction(res)));
    })));

}
