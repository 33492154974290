<div class="modal-wrapper">
  <ng-container *ngIf="data.success">
    <h2 matDialogTitle>Success</h2>
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12">
          <p>Password reset was successful. Please log in with your new password.</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="!data.success">
    <h2 matDialogTitle>Unsuccessful</h2>
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12">
          <p>Password reset was unsuccessful. Please try again.</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  </ng-container>

</div>