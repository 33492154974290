
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
  InstallerHubVoucherRequest,
  InstallerHubVoucherValues,
  RewardsResponse,
  SparesRewardsRequest,
} from '../models/rewards';
import { ApiService } from './api.service';

@Injectable()
export class RewardsService {

  constructor(
    private apiService: ApiService,
  ) { }

  getInstallerHubVoucher(req: InstallerHubVoucherRequest): Observable<RewardsResponse<string>> {
    return this.apiService.apiPost('/rewards/installer-hub', req.form).pipe(
      map((res: RewardsResponse<string>): RewardsResponse<string> => {
        return {
          ...res,
          callback: req.callback,
        };
      }),
      catchError((err: any): Observable<RewardsResponse> => {
        return observableOf({
          error: true,
          message: err.error ? err.error.message : err.message,
        });
      }),);
  }

  getInstallerHubVoucherValues(): Observable<RewardsResponse<InstallerHubVoucherValues>> {
    return this.apiService.apiGet('/rewards/installer-hub/values').pipe(
      map((res: InstallerHubVoucherValues): RewardsResponse<InstallerHubVoucherValues> => {
        return {
          error: null,
          message: 'OK',
          payload: res,
        };
      }),
      catchError((): Observable<RewardsResponse> => {
        return observableOf({
          error: true,
          message: 'Unable to retrieve available vouchers. Please try again later.',
        });
      }),);
  }

  redeemSparesRewards(req: SparesRewardsRequest): Observable<RewardsResponse> {
    return this.apiService.apiPost('/rewards/spares', req.form).pipe(
      map((res: RewardsResponse): RewardsResponse => {
        return {
          callback: req.callback,
          message: res.message,
          error: null,
        };
      }),
      catchError((err: any): Observable<RewardsResponse> => {
        return observableOf({
          message: err.message,
          error: true,
        });
      }),);
  }
}
