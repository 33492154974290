import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SitePage } from '../../../../models/content-pages';
import { UpdatePageContentRequestAction } from '../../../../state-management/actions/content-pages';
import { State as ContentPagesState } from '../../../../state-management/reducers/content-pages';
import { StoreState } from '../../../../state-management/store';
import { AdminContentPageEditModalComponent } from './admin-content-page-edit-modal/admin-content-page-edit-modal.component';

/**
 * Summary
 *    Displays a list of all editable pages with options to allow users to edit
 *    them
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-admin-content-pages',
  templateUrl: './admin-content-pages.component.html',
  styleUrls: ['./admin-content-pages.component.scss']
})
export class AdminContentPagesComponent {

  // "contentPages" from store
  public contentPages$: Observable<ContentPagesState>;

  // Reference to page edit dialog
  private dialogRef_edit;

  constructor(
    private dialog: MatDialog,
    private store: Store<StoreState>,
  ) {
    this.contentPages$ = this.store.select('contentPages');
  }

  /**
   * Opens an AdminContentPageEditModalComponent to edit the specified
   * SitePage. When the dialog is confirmed, an UpdatePageContentRequestAction
   * will be dispatched to save the changes.
   *
   * @param {SitePage} page
   */
  editPage(page: SitePage) {
    this.dialogRef_edit = this.dialog.open(AdminContentPageEditModalComponent, {
      data: {
        page,
      },
      width: '75%',
      panelClass: 'feature-modal-dialog',
    });

    this.dialogRef_edit.afterClosed().subscribe((result) => {
      if (result)
        this.store.dispatch(new UpdatePageContentRequestAction({ page: result }));
    });
  }
}
