import { Component, OnInit, Inject} from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DiaryEvent } from '../../../models/diary';

/**
 * Summary
 *    Display a modal popup with a list of events.
 *    
 *
 * Description
 *    Display a list of events for a given day within a modal popup.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-diary-list-events-modal',
  templateUrl: './diary-list-events-modal.component.html',
  styleUrls: ['./diary-list-events-modal.component.scss']
})
export class DiaryListEventsModalComponent implements OnInit {

  /**
   * Constructor for component
   *
   * @param {MAT_DIALOG_DATA} data                                  Contains reference to data passed into the modal when created
   * @param {MatDialogRef<DiaryListEventsModalComponent>} dialogref Create a reference to this dialog 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogref: MatDialogRef<DiaryListEventsModalComponent>
  ) { 
  }

  ngOnInit() {
  }

  /**
   * Called when a user selects an event from the list, will close the modal and 
   * return the event data to the parent component
   *
   * @param {DiaryEvent} event Data for the selected event
   */
  onSelectEvent(event: DiaryEvent): void {
    this.dialogref.close(event);
  }

}
