<form [formGroup]="fg" class="form-inline" novalidate (ngSubmit)="onSubmitForm()">

  <ng-content select=".form-header"></ng-content>

  <app-message-panel kind="WARNING" *ngIf="errors">{{errors}}</app-message-panel>
  <app-message-panel kind="WARNING" *ngIf="errors && fg.get('password').hasError('pattern')">Your password must be at
    least 8 characters and contain at least 1 lowercase, uppercase and special character.</app-message-panel>

  <div class="row-header" *ngIf="showPhoto">
    <hr />
    <span>Company logo</span>
  </div>

  <div class="main-body" *ngIf="showPhoto">
    <div class="form-group">
      <app-profile-photo-upload [initialUrl]="getProfilePhotoUrl()" (onData)="handleProfilePhotoData($event)">
      </app-profile-photo-upload>
    </div>
  </div>

  <div class="row-header">
    <hr />
    <span>Main information</span>
  </div>

  <div class="row main-body" *ngIf="!simple">
    <div class="col-sm-6 col-xs-12">
      <div *ngIf="showCustomerTypeSelect" [class]="formGroupClass('registerAs', 'col-xs-12')">
        <label class="control-label required">Customer type</label>
        <select (change)="selectCustomerType($event.target.value)" class="form-control" formControlName="registerAs">
          <option value="" selected disabled>Please select</option>
          <option value="2">Heating engineer</option>
          <option value="7">Residential Contractor</option>
          <option value="3">Homeowner</option>
        </select>
      </div>
      <div [class]="formGroupClass('title', 'col-xs-12 half-width-select')">
        <label class="control-label required">Title</label>
        <select class="form-control" formControlName="title" placeholder="Mr">
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
        </select>
      </div>
      <div [class]="formGroupClass('firstName', 'col-xs-12')">
        <label class="control-label required">First name</label>
        <input type="text" class="form-control" formControlName="firstName" />
      </div>
      <div [class]="formGroupClass('lastName', 'col-xs-12')">
        <label class="control-label required">Last name</label>
        <input type="text" class="form-control" formControlName="lastName" />
      </div>
      <div class="col-xs-12 form-group third-size">
        <label class="control-label">Date of birth</label>
        <select class="form-control" formControlName="dob_dd" placeholder="DD">
          <option value="" selected disabled hidden>Day</option>
          <option *ngFor="let day of days" [value]="day">{{day}}</option>
        </select>
        <select class="form-control" formControlName="dob_mm" placeholder="MM">
          <option value="" selected disabled hidden>Month</option>
          <option *ngFor="let month of months" [value]="month">{{month}}</option>
        </select>
        <select class="form-control" formControlName="dob_yyyy" placeholder="YYYY">
          <option value="" selected disabled hidden>Year</option>
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </div>
      <div [class]="formGroupClass('mobile', 'col-xs-12')">
        <label class="control-label required">Mobile</label>
        <input type="text" class="form-control" formControlName="mobile" />
      </div>
      <div formGroupName="emails">
        <div [class]="formGroupClass('emails', 'col-xs-12')">
          <label class="control-label required">Email</label>
          <input type="text" class="form-control" formControlName="email" />
        </div>
        <div [class]="formGroupClass('emails', 'col-xs-12')">
          <label class="control-label required">Confirm email</label>
          <input type="text" class="form-control" formControlName="confirmEmail" />
        </div>
      </div>
      <div [class]="formGroupClass('password', 'col-xs-12')" *ngIf="!profileData || allowPasswordField">
        <label class="control-label" [ngClass]="!profileData ? 'required' : ''">Password</label>
        <input type="password" class="form-control" formControlName="password" />
      </div>
      <div [class]="formGroupClass('telephone', 'col-xs-12')">
        <label class="control-label">Telephone</label>
        <input type="text" class="form-control" formControlName="telephone" />
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <div *ngIf="!isHomeowner" [class]="formGroupClass('companyName', 'col-xs-12')">
        <label class="control-label required">Company name</label>
        <input type="text" class="form-control" formControlName="companyName" />
      </div>
      <div *ngIf="!isHomeowner" [class]="formGroupClass('companySize', 'col-xs-12 half-width-select')">
        <label class="control-label required">Company size</label>
        <select class="form-control" formControlName="companySize" placeholder="Please select...">
          <option value="" disabled selected>Please select...</option>
          <option value="1">1</option>
          <option value="2-5">2-5</option>
          <option value="5-10">5-10</option>
          <option value="10+">10+</option>
        </select>
      </div>
      <div [class]="formGroupClass('address1', 'col-xs-12')">
        <label class="control-label required">Address line 1</label>
        <input type="text" class="form-control" formControlName="address1" />
      </div>
      <div [class]="formGroupClass('address2', 'col-xs-12')">
        <label class="control-label">Address line 2</label>
        <input type="text" class="form-control" formControlName="address2" />
      </div>
      <div [class]="formGroupClass('town', 'col-xs-12')">
        <label class="control-label">Town</label>
        <input type="text" class="form-control" formControlName="town" />
      </div>
      <div [class]="formGroupClass('county', 'col-xs-12')">
        <label class="control-label">County</label>
        <input type="text" class="form-control" formControlName="county" />
      </div>
      <div [class]="formGroupClass('postCode', 'col-xs-12')">
        <label class="control-label required">
          Postcode
        </label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="postCode" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" [disabled]="!enablePostcodeLookup" (click)="postcodeLookup()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
          </span>
        </div>
      </div>
      <div [class]="formGroupClass('country', 'col-xs-12')">
        <label class="control-label required">Country</label>
        <select *ngIf="countryState$ | async; let countryState" class="form-control" formControlName="country">
          <option *ngFor="let country of countryState.countries" [ngValue]="country.id">{{ country.country_name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row main-body" *ngIf="simple">
    <div class="col-sm-6 col-xs-12 left-column">
      <div class="input-group col-xs-12">
        <div [class]="formGroupClass('registerAs', 'col-xs-12')">
          <label class="control-label required">Customer type</label>
          <select (change)="selectCustomerType($event.target.value)" class="form-control" formControlName="registerAs">
            <option value="" selected disabled>Please select</option>
            <option value="2">Heating engineer</option>
            <option value="7">Residential Contractor</option>
            <option value="3">Homeowner</option>
          </select>
        </div>
        <div *ngIf="fg.value.registerAs === '7'" class="col-xs-12 form-group standard-input only-checkbox">
          <label class="control-label">Do you receive contract support from ADEY?</label>
          <mat-radio-group formControlName="contractSupport">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div formGroupName="emails">
          <div [class]="formGroupClass('emails', 'col-xs-12')">
            <label class="control-label required">Email</label>
            <input type="email" class="form-control" formControlName="email" />
          </div>
          <div [class]="formGroupClass('emails', 'col-xs-12')">
            <label class="control-label required">Confirm email</label>
            <input type="email" class="form-control" formControlName="confirmEmail" />
          </div>
        </div>
        <div [class]="formGroupClass('password', 'col-xs-12')" *ngIf="!profileData">
          <label class="control-label" [ngClass]="!profileData ? 'required' : ''">Password</label>
          <input type="password" class="form-control" formControlName="password" />
        </div>
        <ul>
        </ul>
        <div [class]="formGroupClass('country', 'col-xs-12')">
          <label class="control-label required">Country</label>
          <select *ngIf="countryState$ | async; let countryState" class="form-control" formControlName="country">
            <option *ngFor="let country of countryState.countries" [ngValue]="country.id">{{ country.country_name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12 right-column">
      <div class="input-group">
        <div [class]="formGroupClass('companyName', 'col-xs-12')">
          <label class="control-label">Company name</label>
          <input type="text" class="form-control" formControlName="companyName" />
        </div>
        <div [class]="formGroupClass('firstName', 'col-xs-12')">
          <label class="control-label required">First name</label>
          <input type="text" class="form-control" formControlName="firstName" />
        </div>
        <div [class]="formGroupClass('lastName', 'col-xs-12')">
          <label class="control-label required">Last name</label>
          <input type="text" class="form-control" formControlName="lastName" />
        </div>
        <div [class]="formGroupClass('telephone', 'col-xs-12')">
          <label class="control-label required">Contact no.</label>
          <input type="tel" inputmode="numeric" pattern="[0-9 ]*" class="form-control" formControlName="telephone" />
        </div>
        <div [class]="formGroupClass('postCode', 'col-xs-12')">
          <label class="control-label required">
            Postcode
          </label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="postCode" />
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" [disabled]="!enablePostcodeLookup"
                (click)="postcodeLookup()">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </span>
          </div>
        </div>
        <div [class]="formGroupClass('address1', 'col-xs-12')">
          <label class="control-label required">Address line 1</label>
          <input type="text" class="form-control" formControlName="address1" />
        </div>
        <div [class]="formGroupClass('address2', 'col-xs-12')">
          <label class="control-label">Address line 2</label>
          <input type="text" class="form-control" formControlName="address2" />
        </div>
        <div [class]="formGroupClass('town', 'col-xs-12')">
          <label class="control-label">Town</label>
          <input type="text" class="form-control" formControlName="town" />
        </div>
        <div [class]="formGroupClass('county', 'col-xs-12')">
          <label class="control-label">County</label>
          <input type="text" class="form-control" formControlName="county" />
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 points-message">
    <p *ngIf="!simple && !isHomeowner && !proCheckView">Please ensure you register a MACK01 Serial No. or connect your
      first Pro3 Sense filter to start earning Reward Points on qualifying ADEY filters</p>
  </div>

  <div class="row-header" *ngIf="!simple && !isHomeowner && !proCheckView">
    <hr />
    <span>Additional information</span>
  </div>

  <div class="row additional-body" *ngIf="!simple && !isHomeowner && !proCheckView">
    <div class="col-sm-6 col-xs-12 left-column">
      <div [class]="formGroupClass('howDidYouHear', 'col-xs-12 half-select-checkbox-gap')">
        <label class="control-label">How did you hear about the ADEY ProClub&trade;?</label>
        <select class="form-control col-xs-12" formControlName="howDidYouHear" placeholder="Website">
          <option value="1">Website</option>
          <option value="2">Area Sales Manager</option>
          <option value="3">Colleague</option>
          <option value="4">Feature Article Trade Press</option>
          <option value="5">Flyer</option>
          <option value="6">Exhibition</option>
          <option value="7">Merchant</option>
          <option value="8">Word of Mouth</option>
          <option value="9">Other</option>
        </select>
      </div>
      <div [class]="formGroupClass('projects', 'col-xs-12 half-select-checkbox-gap')">
        <label class="control-label">What is the primary nature of your work?</label>
        <select class="form-control" formControlName="projects" placeholder="Commercial">
          <option value="Commercial">Commercial</option>
          <option value="Domestic">Domestic</option>
          <option value="Both">Both</option>
        </select>
      </div>
      <div [class]="formGroupClass('preferredMerchant', 'col-xs-12 half-input-checkbox-gap')">
        <label class="control-label">Which is your preferred merchant?</label>
        <input type="text" class="form-control" formControlName="preferredMerchant" />
      </div>
      <div [class]="formGroupClass('gasSafeNumber', 'col-xs-12 half-input-checkbox')">
        <label class="control-label">Are you Gas Safe registered?</label>
        <mat-checkbox md-no-ink class="md-primary" (change)="checkFieldEnabled('gasSafeRegistered')"
          formControlName="gasSafeRegistered"></mat-checkbox>
        <input type="text" class="form-control" formControlName="gasSafeNumber" placeholder="Gas Safe number" />
      </div>
      <div [class]="formGroupClass('oftecNumber', 'col-xs-12 half-input-checkbox')">
        <label class="control-label">Are you OFTEC registered?</label>
        <mat-checkbox md-no-ink class="md-primary" (change)="checkFieldEnabled('oftecRegistered')"
          formControlName="oftecRegistered"></mat-checkbox>
        <input type="text" class="form-control" formControlName="oftecNumber" placeholder="OFTEC number" />
      </div>
      <div [class]="formGroupClass('magnaCleanseSerial', 'col-xs-12 half-input-checkbox-gap')">
        <label [class]="'control-label'">
          <i>MagnaCleanse</i> serial number
        </label>
        <input style="width: 50%; margin-right: 20px;" type="text" class="form-control"
          *ngIf="!(productState$ | async).serialCheckPending" formControlName="magnaCleanseSerial"
          placeholder="Serial number" />
        <div *ngIf="loginDetails$ | async; let loginDetails" style="width: 40%; float: left;">
          <a class="btn btn-default" *ngIf="loginDetails.user && loginDetails.user.email=='marketing@adey.com'"
            style="cursor: pointer;" title="Toggle serial number validation" (click)="skipSerialValidation()">Toggle
            Validation</a>
        </div>
        <label class="serial-spinner" *ngIf="(productState$ | async).serialCheckPending">
          <app-waiting-spinner kind="bar" colorType="accent" class="inline">Checking serial...</app-waiting-spinner>
        </label>
        <app-message-panel kind="WARNING" *ngIf="(productState$ | async).serialCheckResult === false">
          {{(productState$ | async).serialCheckMessage}}</app-message-panel>
      </div>
      <!-- <p class="color-white col-xs-12">Your MagnaCleanse serial number can be found on the back of the red tray or on
        the warranty card within the box. Without registering a MagnaCleanse serial number, you will be unable to redeem
        your points.</p> -->
    </div>
    <div class="col-sm-6 col-xs-12 right-column">
      <div class="col-xs-12 form-group half-width-label standard-input only-checkbox">
        <label class="control-label">Do you or the company you work for receive contract support for <i>MagnaClean
            Professional2</i> or <i>Micro2&reg;</i> from ADEY?</label>
        <mat-checkbox md-no-ink class="md-primary" formControlName="contract"></mat-checkbox>
      </div>
      <div [class]="formGroupClass('preferredboiler', 'col-xs-12 standard-input half-width-label')">
        <label class="control-label no-max-width">Your preferred boiler manufacturer</label>
        <input type="text" class="form-control" formControlName="preferredBoiler" />
      </div>
      <div class="col-xs-12 form-group full-width-label standard-input">
        <label class="control-label"><strong>Are you a member of any of the following accredited
            bodies?</strong></label>
        <ng-content select=".accreditation-loading"></ng-content>
      </div>

      <div class="col-xs-12 form-group wrap memberships" formGroupName="memberships">
        <div class="option-label" *ngFor="let mt of membershipTypes">
          <mat-checkbox md-no-ink class="md-primary" [id]="mt.id" [formControlName]="mt.formControlName">
            {{mt.label}}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact Preferences (Admin's only) -->
  <div *ngIf="loginDetails$ | async; let loginDetails">
    <ng-container *ngIf="loginDetails.user && loginDetails.user.admin">
      <div class="row-header">
        <hr />
        <span>Contact Preferences</span>
      </div>
      <div class="row row-admin">
        <div class="col-xs-12">

          <p>I'd like to hear about other goods and services Adey offers that are similar to those that I have already
            purchased or enquired about</p>
          <div class="checkbox-inline">
            <input type="checkbox" id="offers_by_email" formControlName="offers_by_email" />
            <label>Yes, by email</label>
          </div>

          <div class="checkbox-inline">
            <input type="checkbox" id="offers_by_sms" formControlName="offers_by_sms" />
            <label>Yes, by SMS</label>
          </div>

          <div class="checkbox-inline">
            <input type="checkbox" id="offers_by_telephone" formControlName="offers_by_telephone" />
            <label>Yes, by telephone</label>
          </div>

          <ng-container *ngIf="!isHomeowner">
            <p>I'd like to get information about Adey's other goods, services or events that may be of interest to me
            </p>

            <div class="checkbox-inline">
              <input type="checkbox" id="information_by_email" formControlName="information_by_email" />
              <label>Yes, by email</label>
            </div>

            <div class="checkbox-inline">
              <input type="checkbox" id="information_by_sms" formControlName="information_by_sms" />
              <label>Yes, by SMS</label>
            </div>

            <div class="checkbox-inline">
              <input type="checkbox" id="information_by_telephone" formControlName="information_by_telephone" />
              <label>Yes, by telephone</label>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row-header">
        <hr />
        <span>Business Tools</span>
      </div>

      <div class="row row-admin">
        <ng-container *ngIf="businessTools$ | async; let state">
          <div class="col-xs-12" *ngFor="let tool of state.businessTools">
            <div class="row">
              <mat-checkbox class="md-primary col-sm-2" [disableRipple]="true" (click)="$event.preventDefault()"
                [checked]="businessToolRegistrations | filter: 'businessToolId':tool.id">
                {{ tool.name }}
              </mat-checkbox>
              <div class="col-sm-10">
                <p *ngIf="(businessToolRegistrations | filter: 'businessToolId':tool.id)?.length">
                  <strong>Registered:</strong>
                  {{ (businessToolRegistrations | filter: 'businessToolId':tool.id)[0]?.verifiedAt | dateTimeFormat }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

    </ng-container>

  </div>
  <!-- \Contact Preferences (Admin's only) -->

  <div class="row-header">
    <hr />
    <span *ngIf="showTerms">Terms and conditions</span>
  </div>

  <div class="row submit-body">
    <div class="col-sm-7 col-xs-12">
      <div [class]="formGroupClass('termsAndConditionsConfirm', 'col-xs-12 full-width-label standard-input')"
        *ngIf="showTerms">
        <mat-checkbox md-no-ink class="md-primary" formControlName="termsAndConditionsConfirm">
          Confirm that you agree to our <a href="https://www.adey.com/adeyproclub-terms-and-conditions"
            target="_blank">terms &amp; conditions</a> *
        </mat-checkbox>
      </div>
      <div [class]="formGroupClass('dataProtectionConfirm', 'col-xs-12 full-width-label standard-input')"
        *ngIf="showTerms">
        <mat-checkbox md-no-ink class="md-primary" formControlName="dataProtectionConfirm">
          Confirm that you agree to our <a href="http://www.adey.com/privacy-policy" target="_blank">Privacy Policy</a>
          *
        </mat-checkbox>
      </div>

      <!--<div class="form-group col-xs-12 full-width-label standard-input" *ngIf="!simple">-->
      <!--<label class="control-label"><strong>Keep me up-to-date with information on ADEY products and services by:</strong></label>-->
      <!--</div>-->
      <!--<div class="form-group wrap col-xs-12 full-width-label standard-input" *ngIf="!simple">-->
      <!--<div class="option-label">-->
      <!--<mat-checkbox md-no-ink class="md-primary" id="contact_email" formControlName="contactByEmail">-->
      <!--Email-->
      <!--</mat-checkbox>-->
      <!--</div>-->
      <!--<div class="option-label">-->
      <!--<mat-checkbox md-no-ink class="md-primary" id="contact_phone" formControlName="contactByPhone">-->
      <!--Phone/SMS-->
      <!--</mat-checkbox>-->
      <!--</div>-->
      <!--<div class="option-label">-->
      <!--<mat-checkbox md-no-ink class="md-primary" id="contact_post" formControlName="contactByPost">-->
      <!--Post-->
      <!--</mat-checkbox>-->
      <!--</div>-->
      <!--</div>-->

    </div>
    <div class="col-sm-5 col-xs-12 right-column">
      <input type="submit" class="btn btn-primary feature-btn branding-color-white branding-background-adey-blue"
        [value]="submitLabel" *ngIf="showSubmit"
        [disabled]="(productState$ | async).serialCheckPending || (registrationState$ | async).pending" />
    </div>
    <div class="col-xs-12">
      <br>
      <p *ngIf="!proCheckView">Using your phone? Don't forget to download our ADEY ProClub App on your Apple or Android
        device!</p>
      <p *ngIf="proCheckView">Using your phone? Don't forget to download the ADEY ProCheck<span>&reg;</span> App on your
        Apple or Android device!</p>
    </div>
  </div>

  <ng-content select=".form-footer"></ng-content>

</form>
