import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store }                  from '@ngrx/store';
import { Observable ,  Subscription }             from 'rxjs';


import { StoreState }                 from '../../../state-management/store';
import { State as ContentPagesState } from '../../../state-management/reducers/content-pages';

import {
  PageField,
  SitePage,
} from '../../../models/content-pages';


/**
 * Summary
 *    Display the content defined for the page via the admin dashboard.
 *
 *
 * Description
 *    Retrieve and display page content stored within the database for each page.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent implements OnDestroy, OnInit {

  // Name of the page from which to select the field
  @Input() page: string = null;

  // Name of the field to select
  @Input() field: string = null;


  // Store state and Subscription
  private contentPages$:    Observable<ContentPagesState>;
  private contentPagesSub$: Subscription;

  // The SitePage model for the page in question (contains all fields)
  public pageModel:  SitePage = null;

  // The sanitised value of the specified field from the SitePage
  public fieldValue: SafeHtml = null;


  /**
   * Constructor for page
   *
   * @param {DomSanitizer}      sanitizer Initialise reference to DomSanitizer in order to disable Angular's built-in sanitation
   * @param {Store<StoreState>} store     Initialises reference to the store
   */
  constructor(
    private sanitizer: DomSanitizer,
    private store:     Store<StoreState>,
  ) {
    // Get reference to content state
    this.contentPages$ = this.store.select('contentPages');
  }

  /**
   * Subscribe to the content state and retrieve the correct data based on the page
   */
  ngOnInit() {
    this.contentPagesSub$ = this.contentPages$.subscribe((s: ContentPagesState) => {
      this.pageModel  = null;
      this.fieldValue = null;

      if (s.pages)
      {
        // Filter the pages by the given page in order to return the correct data
        const filteredPages = s.pages.filter((p: SitePage): boolean => p.id === this.page);
        if (filteredPages.length > 0)
          this.pageModel = filteredPages[0];
      }

      if (this.pageModel)
      {
        // Set a reference to the field to display based on the field value
        const filteredFields = this.pageModel.fields.filter((f: PageField): boolean => f.fieldName === this.field);
        // Sanitize the the current field value to ensure it can be displayed on the page
        if (filteredFields.length > 0 && filteredFields[0].current && filteredFields[0].current.toString().trim().length > 0)
          this.fieldValue = this.sanitizer.bypassSecurityTrustHtml(filteredFields[0].current);
        else
          this.fieldValue = null;
      }
    });
  }

  /**
   * Unsubscribe from the content state when the page is destroyed in order to free up memory
   */
  ngOnDestroy() {
    if (this.contentPagesSub$)
      this.contentPagesSub$.unsubscribe();
  }
}
