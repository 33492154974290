import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GetPostcodeAddressResponse, PostcodeLookupResponse } from '../../models/postcode-lookup';
import { PostcodeLookupService } from '../../services/postcode-lookup.service';
import * as ActionTypes from '../actions/postcode-lookup';

@Injectable()
export class PostcodeLookupEffects {
  constructor(
    private service: PostcodeLookupService,
    private actions$: Actions
  ) { }

  /**
   * For a PostcodeLookupRequestAction, call
   * PostcodeLookupService::queryPostcode() and dispatch a new
   * PostcodeLookupResponseAction with the response.
   */
   postcodeLookupRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.POSTCODE_LOOKUP_REQUEST),
    switchMap((req: ActionTypes.PostcodeLookupRequestAction): Observable<ActionTypes.PostcodeLookupResponseAction> =>
      this.service.queryPostcode(req.payload).pipe(
        map((res: PostcodeLookupResponse): ActionTypes.PostcodeLookupResponseAction =>
          new ActionTypes.PostcodeLookupResponseAction(res)
        ))
    )));

  /**
   * For a PostcodeLookupResponseAction, call the response's "callback"
   * function if it exists. Do not dispatch any further actions.
   */
   postcodeLookupResponse$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.POSTCODE_LOOKUP_RESPONSE),
    map((res: ActionTypes.PostcodeLookupResponseAction): void => {
      if (res.payload.callback && !res.payload.error)
        res.payload.callback(res.payload.results);
    })), { dispatch: false });

  /**
   * For a GetPostcodeAddressRequestAction, call
   * PostcodeLookupService::getAddressByID() and dispatch a new
   * GetPostcodeAddressResponseAction with the response.
   */
   getPostcodeAddressRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GET_POSTCODE_ADDRESS_REQUEST),
    switchMap((req: ActionTypes.GetPostcodeAddressRequestAction): Observable<ActionTypes.GetPostcodeAddressResponseAction> =>
      this.service.getAddressByID(req.payload).pipe(
        map((res: GetPostcodeAddressResponse): ActionTypes.GetPostcodeAddressResponseAction =>
          new ActionTypes.GetPostcodeAddressResponseAction(res)
        ))
    )));

  /**
   * For a GetPostcodeAddressResponseAction, call the response's "callback"
   * function if it exists. Do not dispatch any further actions.
   */
   getPostcodeAddressResponse$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GET_POSTCODE_ADDRESS_RESPONSE),
    map((res: ActionTypes.GetPostcodeAddressResponseAction): void => {
      if (res.payload.callback && !res.payload.error)
        res.payload.callback(res.payload.address);
    })), { dispatch: false });
}
