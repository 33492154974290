/**
 * resources reducer: maintains state for searching for and viewing resources
 * (brochures, videos, etc)
 */

import { Action, ActionReducer } from '@ngrx/store';


import {
  FetchResourceItemsRequest,
  ResourceItem,
  ResourceItemSearchCriteria,
} from '../../models/resources';

import * as actions from '../actions/resources';


// State interface definition: maintains a list of ResourceItem models and the
// current search request
export interface State {
  pending: boolean;
  error:   string;

  // Current search request
  request: FetchResourceItemsRequest;

  // List of matching resources for search
  items: ResourceItem[];
}


const defaultState: State = {
  pending: false,
  error:   null,
  request: null,
  items:   [],
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {
    /**
     * Request to fetch ResourceItems based on a search query
     */
    case actions.FETCH_RESOURCE_ITEMS_REQUEST:
      return Object.assign({}, state, {
        pending: true,
        error:   null,
        items:   [],
        request: action.payload,
      });

    /**
     * Response from fetching ResourceItems
     */
    case actions.FETCH_RESOURCE_ITEMS_RESPONSE:
      return Object.assign({}, state, {
        pending: false,
        error:   action.payload.error,
        items:   action.payload.error ? state.items : action.payload.items,
      });

    default:
      return state;
  }
}
