/**
 * admin-events actions: defines all actions relating to the event management
 * admin CMS.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  AddAdminEventRequest,
  AddAdminEventResponse,
  AddAdminEventTypeRequest,
  AddAdminEventTypeResponse,
  DeleteAdminEventRequest,
  DeleteAdminEventResponse,
  DeleteAdminEventTypeRequest,
  DeleteAdminEventTypeResponse,
  FetchAdminEventsRequest,
  FetchAdminEventsResponse,
  UpdateAdminEventRequest,
  UpdateAdminEventResponse,
  UpdateAdminEventTypeRequest,
  UpdateAdminEventTypeResponse,
} from '../../models/admin-events';


// Action type definitions
export const ADD_ADMIN_EVENT_REQUEST          = 'ADD_ADMIN_EVENT_REQUEST';
export const ADD_ADMIN_EVENT_RESPONSE         = 'ADD_ADMIN_EVENT_RESPONSE';
export const ADD_ADMIN_EVENT_TYPE_REQUEST     = 'ADD_ADMIN_EVENT_TYPE_REQUEST';
export const ADD_ADMIN_EVENT_TYPE_RESPONSE    = 'ADD_ADMIN_EVENT_TYPE_RESPONSE';
export const DELETE_ADMIN_EVENT_REQUEST       = 'DELETE_ADMIN_EVENT_REQUEST';
export const DELETE_ADMIN_EVENT_RESPONSE      = 'DELETE_ADMIN_EVENT_RESPONSE';
export const DELETE_ADMIN_EVENT_TYPE_REQUEST  = 'DELETE_ADMIN_EVENT_TYPE_REQUEST';
export const DELETE_ADMIN_EVENT_TYPE_RESPONSE = 'DELETE_ADMIN_EVENT_TYPE_RESPONSE';
export const FETCH_ADMIN_EVENTS_REQUEST       = 'FETCH_ADMIN_EVENTS_REQUEST';
export const FETCH_ADMIN_EVENTS_RESPONSE      = 'FETCH_ADMIN_EVENTS_RESPONSE';
export const UPDATE_ADMIN_EVENT_REQUEST       = 'UPDATE_ADMIN_EVENT_REQUEST';
export const UPDATE_ADMIN_EVENT_RESPONSE      = 'UPDATE_ADMIN_EVENT_RESPONSE';
export const UPDATE_ADMIN_EVENT_TYPE_REQUEST  = 'UPDATE_ADMIN_EVENT_TYPE_REQUEST';
export const UPDATE_ADMIN_EVENT_TYPE_RESPONSE = 'UPDATE_ADMIN_EVENT_TYPE_RESPONSE';


// Action definitions
export class AddAdminEventTypeRequestAction implements Action {
  readonly type = ADD_ADMIN_EVENT_TYPE_REQUEST;
  constructor(public payload: AddAdminEventTypeRequest) {}
}

export class AddAdminEventTypeResponseAction implements Action {
  readonly type = ADD_ADMIN_EVENT_TYPE_RESPONSE;
  constructor(public payload: AddAdminEventTypeResponse) {}
}

export class AddAdminEventRequestAction implements Action {
  readonly type = ADD_ADMIN_EVENT_REQUEST;
  constructor(public payload: AddAdminEventRequest) {}
}

export class AddAdminEventResponseAction implements Action {
  readonly type = ADD_ADMIN_EVENT_RESPONSE;
  constructor(public payload: AddAdminEventResponse) {}
}

export class DeleteAdminEventRequestAction implements Action {
  readonly type = DELETE_ADMIN_EVENT_REQUEST;
  constructor(public payload: DeleteAdminEventRequest) {}
}

export class DeleteAdminEventResponseAction implements Action {
  readonly type = DELETE_ADMIN_EVENT_RESPONSE;
  constructor(public payload: DeleteAdminEventResponse) {}
}

export class DeleteAdminEventTypeRequestAction implements Action {
  readonly type = DELETE_ADMIN_EVENT_TYPE_REQUEST;
  constructor(public payload: DeleteAdminEventTypeRequest) {}
}

export class DeleteAdminEventTypeResponseAction implements Action {
  readonly type = DELETE_ADMIN_EVENT_TYPE_RESPONSE;
  constructor(public payload: DeleteAdminEventTypeResponse) {}
}

export class FetchAdminEventsRequestAction implements Action {
  readonly type = FETCH_ADMIN_EVENTS_REQUEST;
  constructor(public payload: FetchAdminEventsRequest) {}
}

export class FetchAdminEventsResponseAction implements Action {
  readonly type = FETCH_ADMIN_EVENTS_RESPONSE;
  constructor(public payload: FetchAdminEventsResponse) {}
}

export class UpdateAdminEventRequestAction implements Action {
  readonly type = UPDATE_ADMIN_EVENT_REQUEST;
  constructor(public payload: UpdateAdminEventRequest) {}
}

export class UpdateAdminEventResponseAction implements Action {
  readonly type = UPDATE_ADMIN_EVENT_RESPONSE;
  constructor(public payload: UpdateAdminEventResponse) {}
}

export class UpdateAdminEventTypeRequestAction implements Action {
  readonly type = UPDATE_ADMIN_EVENT_TYPE_REQUEST;
  constructor(public payload: UpdateAdminEventTypeRequest) {}
}

export class UpdateAdminEventTypeResponseAction implements Action {
  readonly type = UPDATE_ADMIN_EVENT_TYPE_RESPONSE;
  constructor(public payload: UpdateAdminEventTypeResponse) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | AddAdminEventRequestAction
  | AddAdminEventResponseAction
  | AddAdminEventTypeRequestAction
  | AddAdminEventTypeResponseAction
  | DeleteAdminEventRequestAction
  | DeleteAdminEventResponseAction
  | DeleteAdminEventTypeRequestAction
  | DeleteAdminEventTypeResponseAction
  | FetchAdminEventsRequestAction
  | FetchAdminEventsResponseAction
  | UpdateAdminEventRequestAction
  | UpdateAdminEventResponseAction
  | UpdateAdminEventTypeRequestAction
  | UpdateAdminEventTypeResponseAction;
