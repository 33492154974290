
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BusinessTool, BusinessToolAPIResponse, BusinessToolResponse, BusinessToolVerificationToken, BusinessToolVerificationTokenAPIResponse, BusinessToolVerificationTokenRequest, BusinessToolVerificationTokenResponse } from '../models/business-tools';
import { ApiService } from './api.service';

@Injectable()
export class BusinessToolsService {
  /**
   * Inject the API service
   *
   * @param apiService Application's main API data connection
   */
  constructor(
    private apiService: ApiService,
  ) { }

  getBusinessTools(): Observable<BusinessToolResponse> {
    return this.apiService.apiGet('/business-tools').pipe(
      map((res: BusinessToolAPIResponse[]): BusinessToolResponse => {
        return {
          error: null,
          data: res.map(BusinessTool.fromAPI),
          message: null,
        };
      }),
      catchError((err: { message?: string }): Observable<BusinessToolResponse> =>
        observableOf({
          error: true,
          data: null,
          message: err.message,
        })
      ),);
  }

  getBusinessToolVerificationToken(req: BusinessToolVerificationTokenRequest): Observable<BusinessToolVerificationTokenResponse> {
    return this.apiService.apiPost(`/business-tools/${req.tool}/token`, null).pipe(
      map((res: BusinessToolVerificationTokenAPIResponse): BusinessToolVerificationTokenResponse => {
        return {
          error: null,
          data: BusinessToolVerificationToken.fromAPI(res),
          message: null,
        };
      }),
      catchError((err: { message?: string }): Observable<BusinessToolVerificationTokenResponse> =>
        observableOf({
          error: true,
          data: null,
          message: err.message,
        })
      ),);
  }
}
