import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BusinessToolResponse, BusinessToolVerificationTokenResponse } from '../../models/business-tools';
import { BusinessToolsService } from '../../services/business-tools.service';
import * as ActionTypes from '../actions/business-tools';

@Injectable()
export class BusinessToolsEffects {
  constructor(
    private service: BusinessToolsService,
    private actions$: Actions,
  ) { }

   fetchBusinessToolsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_BUSINESS_TOOLS_REQUEST),
    switchMap((req: ActionTypes.FetchBusinessToolsRequestAction): Observable<ActionTypes.FetchBusinessToolsResponseAction> =>
      this.service.getBusinessTools().pipe(
        map((res: BusinessToolResponse): ActionTypes.FetchBusinessToolsResponseAction =>
          new ActionTypes.FetchBusinessToolsResponseAction(res)
        ))
    )));

   fetchBusinessToolsVerificationRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_BUSINESS_TOOLS_VERIFICATION_REQUEST),
    switchMap((req: ActionTypes.FetchBusinessToolsVerificationTokenRequestAction): Observable<ActionTypes.FetchBusinessToolsVerificationTokenResponseAction> =>
      this.service.getBusinessToolVerificationToken(req.payload).pipe(
        map((res: BusinessToolVerificationTokenResponse): ActionTypes.FetchBusinessToolsVerificationTokenResponseAction =>
          new ActionTypes.FetchBusinessToolsVerificationTokenResponseAction(res)
        ))
    )));
}
