<h2 matDialogTitle>Create points promotion</h2>

<mat-dialog-content>

  <app-message-panel kind="ERROR" *ngIf="errors">{{ errors }}</app-message-panel>

  <form [formGroup]="fg" class="row">
    <mat-form-field class="col-xs-12">
      <mat-select placeholder="Product" formControlName="productId">
        <mat-option *ngFor="let product of data?.products" [value]="product.id">
          {{ product.name }}
        </mat-option>
        <mat-option [value]="40">
          MagnaClean Professional3 Sense
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-xs-12">
      <input matInput type="text" formControlName="name" placeholder="Name" maxlength="255" />
    </mat-form-field>
    <mat-form-field class="col-xs-12">
      <textarea matInput formControlName="description" placeholder="Description" matTextareaAutosize
        matAutosizeMinRows="2" matAutosizeMaxRows="5" maxlength="65535"></textarea>
    </mat-form-field>
    <mat-form-field class="col-xs-12">
      <input matInput type="text" formControlName="reference" placeholder="Reference" maxlength="255" />
    </mat-form-field>
    <mat-form-field class="col-xs-12">
      <input matInput type="text" formControlName="points" placeholder="Points" maxlength="65535" />
    </mat-form-field>
    <mat-form-field class="col-xs-6">
      <input matInput type="date" formControlName="startDate" placeholder="Start date" />
    </mat-form-field>
    <mat-form-field class="col-xs-6">
      <input matInput type="date" formControlName="endDate" placeholder="End date" />
    </mat-form-field>
  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Close</button>
  <button mat-button [disabled]="!fg.valid" (click)="onSave()">Save</button>
</mat-dialog-actions>