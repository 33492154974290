/**
 * diary actions: defines all actions relating to the diary and events.
 */

import { Action } from '@ngrx/store';


// Import models
import {
  CommitEventResponse,
  DiaryEvent,
  FetchEventsResponse,
  FetchEventTypesResponse,
} from '../../models/diary';


// Action type definitions
export const ADD_EVENT                  = 'ADD_EVENT';
export const BOOK_EVENT                 = 'BOOK_EVENT';
export const COMMIT_EVENT               = 'COMMIT_EVENT';
export const DELETE_EVENT               = 'DELETE_EVENT';
export const FETCH_EVENTS_REQUEST       = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_RESPONSE      = 'FETCH_EVENTS_RESPONSE';
export const FETCH_EVENT_TYPES_REQUEST  = 'FETCH_EVENT_TYPES_REQUEST';
export const FETCH_EVENT_TYPES_RESPONSE = 'FETCH_EVENT_TYPES_RESPONSE';
export const UPDATE_EVENT               = 'UPDATE_EVENT';


// Action definitions
export class AddEventAction implements Action {
  readonly type = ADD_EVENT;
  constructor(public payload: DiaryEvent) {}
}

export class BookEventAction implements Action {
  readonly type = BOOK_EVENT;
  constructor(public payload: string) {}
}

export class CommitEventAction implements Action {
  readonly type = COMMIT_EVENT;
  constructor(public payload: CommitEventResponse) {}
}

export class DeleteEventAction implements Action {
  readonly type = DELETE_EVENT;
  constructor(public payload: string) {}
}

export class FetchEventTypesRequestAction implements Action {
  readonly type = FETCH_EVENT_TYPES_REQUEST;
}

export class FetchEventTypesResponseAction implements Action {
  readonly type = FETCH_EVENT_TYPES_RESPONSE;
  constructor(public payload: FetchEventTypesResponse) {}
}

export class FetchEventsRequestAction implements Action {
  readonly type = FETCH_EVENTS_REQUEST;
}

export class FetchEventsResponseAction implements Action {
  readonly type = FETCH_EVENTS_RESPONSE;
  constructor(public payload: FetchEventsResponse) {}
}

export class UpdateEventAction implements Action {
  readonly type = UPDATE_EVENT;
  constructor(public payload: DiaryEvent) {}
}


// Export Actions type as a union of all defined actions
export type Actions =
  | AddEventAction
  | BookEventAction
  | CommitEventAction
  | DeleteEventAction
  | FetchEventsRequestAction
  | FetchEventsResponseAction
  | FetchEventTypesRequestAction
  | FetchEventTypesResponseAction
  | UpdateEventAction;
