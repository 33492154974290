import { Action, ActionReducer } from '@ngrx/store';


import {
  FilterDataItem,
  FilterDataRequest,
  FilterDataResponse,
  TimelineDataRequest,
  TimelineDataResponse,
  TimelineData,
  AlertData
} from '../../models/pro3';

import * as actions from '../actions/pro3';


export interface State {

  // Pending flags and error message
  filterDataPending:         boolean;
  timelineDataPending:       boolean;
  alertDataPending:          boolean;
  dismissAlertPending:       boolean;
  error:                     string;
  message:                   string;
  type:                      string;

  //filter data items
  filterData:                FilterDataItem[];
  timelineData:              TimelineData[];
  alertData:                 AlertData[];
}


export const defaultState: State = {
  filterDataPending:         true,
  error:                     null,
  message:                   null,
  filterData:                null,
  type:                      null,
  timelineDataPending:       true,
  timelineData:              null,
  alertDataPending:          true,
  alertData:                 null,
  dismissAlertPending:       true,
};


export function reducer(
  state:  State = defaultState,
  action: actions.Actions
): State {
  switch (action.type)
  {

    case actions.FILTER_DATA_REQUEST:
      return Object.assign({}, state, {
        type:                      'FILTER_DATA_REQUEST',
        filterDataPending:         true,
        error:                     null,
        filterData:                null,
      });

    case actions.FILTER_DATA_RESPONSE:
      return Object.assign({}, state, {
        type:                      'FILTER_DATA_RESPONSE',
        filterDataPending:         false,
        error:                     action.payload.error,
        filterData:                action.payload.filterData,
      });

    case actions.TIMELINE_DATA_REQUEST:
      return Object.assign({}, state, {
        type:                 'TIMELINE_DATA_REQUEST',
        timelineDataPending:  true,
        error:                null,
        timelineData:         null,
      });

    case actions.TIMELINE_DATA_RESPONSE:
      return Object.assign({}, state, {
        type:                 'TIMELINE_DATA_RESPONSE',
        timelineDataPending:  false,
        error:                action.payload.error,
        timelineData:         action.payload.timelineData,
      });

    case actions.ALERT_DATA_REQUEST:
      return Object.assign({}, state, {
        type:                 'ALERT_DATA_REQUEST',
        alertDataPending:     true,
        error:                null,
        alertData:            null,
      });

    case actions.ALERT_DATA_RESPONSE:
      return Object.assign({}, state, {
        type:                 'ALERT_DATA_RESPONSE',
        alertDataPending:     false,
        error:                action.payload.error,
        alertData:            action.payload.alertData,
      });

    case actions.DISMISS_ALERT_REQUEST:
      return Object.assign({}, state, {
        type:                      'DISMISS_ALERT_REQUEST',
        dismissAlertPending:       true,
        error:                     null,
        message:                   null,
      });

    case actions.DISMISS_ALERT_RESPONSE:
      return Object.assign({}, state, {
        type:                      'DISMISS_ALERT_RESPONSE',
        dismissAlertPending:       false,
        error:                     action.payload.error,
        message:                   action.payload.message,
      });

    default:
      return state;
  }
}
