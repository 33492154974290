import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SystemMessageResponse } from '../../models/system-message';
import { SystemMessageService } from '../../services/system-message.service';
import * as ActionTypes from '../actions/system-message';

@Injectable()
export class SystemMessageEffects {
  constructor(
    private systemMessageService: SystemMessageService,
    private actions$: Actions
  ) { }

  /**
   * For a SystemMessageRequestAction, call SystemMessage::getSystemMessage() and
   * dispatch a new SystemMessageResponseAction with the response.
   */
   systemMessageRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SYSTEM_MESSAGE_REQUEST),
    switchMap((): Observable<Action> => {
      return this.systemMessageService.getSystemMessage().pipe(
        map((res: SystemMessageResponse): ActionTypes.SystemMessageResponseAction => new ActionTypes.SystemMessageResponseAction(res)));
    })));

}
