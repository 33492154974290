import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as moment from 'moment';


import { Job }         from '../../../models/job';
import { UserProfile } from '../../../models/user-profile';


/**
 * Summary
 *    Component which creates a printable view of a Job
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-printable-job',
  templateUrl: './printable-job.component.html',
  styleUrls: ['./printable-job.component.scss']
})
export class PrintableJobComponent implements OnInit {

  // Job being printed/downloaded
  @Input() job: Job = null;

  // Emitted when the popup created by printView() has closed
  @Output() closeView = new EventEmitter<any>();


  constructor() { }

  ngOnInit() { }


  /**
   * Returns a formatted date if possible
   *
   * @param {any} date
   * @return {string} Formatted date (DD MM YYYY)
   */
  getDate(date: any): string {
    if (!date)
      return 'not specified';
    return moment(date).format('DD MM YYYY');
  }

  /**
   * Generates a full HTML template using the compunent's template output,
   * opens a popup window and calls window.print()
   */
  printView() {

    // Generate an appropriate title (also becomes the filename when printing
    // to file)
    let documentTitle: string = 'Job record';
    if (this.job && this.job.customer)
      documentTitle = `${this.job.customer.customerTitle} ${this.job.customer.customerForename} ${this.job.customer.customerSurname}`;
    else if (this.job)
      documentTitle = `Job record ${this.job.id}`;

    let printContents, popupWin;

    // Get HTML from template
    printContents = document.getElementById('pdf').innerHTML;

    // Create a new popup window
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();

    // Build popup window HTML
    popupWin.document.write(`
        <html>
            <head>
                <title>${documentTitle}</title>
                <style>
                    * {
                      font-family: Calibri, Candara, Segoe, "Calibri (Body)";
                    }
                    p, td {
                      font-size: 12px;
                      vertical-align: top;
                    }
                    p {
                      font-size: 12px;
                    }
                    .logo-div {
                      padding-bottom: 25px;
                    }
                    .logo-img {
                      max-width: 200px;
                    }
                    .header-div {
                      text-align: center;
                      padding-bottom: 15px;
                    }
                    .header-title {
                      text-transform: uppercase;
                      font-weight: bold;
                      font-size: 18px;
                      text-align:center;
                    }
                    .detail-bold {
                      font-weight: bold;
                    }
                    .table-div {
                      overflow-x:auto;
                    }
                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        width: 100%;
                        border: 1px solid #ddd;
                    }
                    th, td {
                        border: 1px solid #000;
                        text-align: left;
                        padding: 8px;
                    }
                    .left-cell {
                      width: 64px;
                    }
                    .icon-img {
                      max-width: 64px;
                    }
                </style>
            </head>
            <body onload="window.print();window.close()">${printContents}</body>
        </html>`
    );
    popupWin.document.close();

    setTimeout(() => {
      this.closeView.emit();
    }, 50);
  }
}
