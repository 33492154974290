import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { ActivateProfileComponent } from './components/activate-profile/activate-profile.component';
import { AdminContentPagesComponent } from './components/admin/admin-content/admin-content-pages/admin-content-pages.component';
import { AdminContentResourcesComponent } from './components/admin/admin-content/admin-content-resources/admin-content-resources.component';
import { AdminContentComponent } from './components/admin/admin-content/admin-content.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { AdminEventsComponent } from './components/admin/admin-events/admin-events.component';
import { AdminInstallerJobsComponent } from './components/admin/admin-installer-jobs/admin-installer-jobs.component';
import { AdminPro3Component } from './components/admin/admin-pro3/admin-pro3.component';
import { AdminRewardsComponent } from './components/admin/admin-rewards/admin-rewards.component';
import { AdminUsersComponent } from './components/admin/admin-users/admin-users.component';
import { BusinessToolsComponent } from './components/business-tools/business-tools.component';
import { TradehelpComponent } from './components/business-tools/tradehelp/tradehelp.component';
import { TruequoteWebsiteComponent } from './components/business-tools/truequote-website/truequote-website.component';
import { TruequoteComponent } from './components/business-tools/truequote/truequote.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContentArchiveComponent } from './components/content-archive/content-archive.component';
import { ContentListComponent } from './components/content-archive/content-list/content-list.component';
import { DiaryComponent } from './components/diary/diary.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { JobComponent } from './components/job/job.component';
import { LoginExistingComponent } from './components/login-existing/login-existing.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { Pro3ListComponent } from './components/pro3-list/pro3-list.component';
import { ProductSelectorContainerComponent } from './components/product-selector-container/product-selector-container.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ReviewInvitationComponent } from './components/review-invitation/review-invitation.component';
import { DemokitRedeemComponent } from './components/rewards/demokit-redeem/demokit-redeem.component';
import { RewardsComponent } from './components/rewards/rewards.component';
import { SparesRedeemComponent } from './components/rewards/spares-redeem/spares-redeem.component';
import { VoucherRedeemComponent } from './components/rewards/voucher-redeem/voucher-redeem.component';
import { RoiCalculatorComponent } from './components/roi-calculator/roi-calculator.component';
import { ServiceDashboardComponent } from './components/service-dashboard/service-dashboard.component';
import { SubmissionSelectionComponent } from './components/submission-selection/submission-selection.component';
import { TcAppLoginComponent } from './components/tc-app-login/tc-app-login.component';
import { TcPortalLoginComponent } from './components/tc-portal-login/tc-portal-login.component';
import { TrainingComponent } from './components/training/training.component';
import { UserOnboardingComponent } from './components/user-onboarding/user-onboarding.component';
import { UserOptinComponent } from './components/user-optin/user-optin.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { WarrantyListComponent } from './components/warranty-list/warranty-list.component';
import { WarrantyStatisticsComponent } from './components/warranty-statistics/warranty-statistics.component';
import { WarrantyComponent } from './components/warranty/warranty.component';
import { ProcheckRedirectComponent } from './watertest/components/procheck-redirect/procheck-redirect.component';

// Definition of app routes
export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'tc-portal-login', component: TcPortalLoginComponent },
  { path: 'tc-app-login', component: TcAppLoginComponent },
  { path: 'review-invitation/:token', component: ReviewInvitationComponent },

  { path: 'activate-profile', component: ActivateProfileComponent },
  { path: 'business-tools', component: BusinessToolsComponent },
  { path: 'business-tools/tradehelp', component: TradehelpComponent },
  { path: 'business-tools/truequote', component: TruequoteComponent },
  {
    path: 'business-tools/truequote-website',
    component: TruequoteWebsiteComponent,
  },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'content-archive', component: ContentArchiveComponent },
  { path: 'content-list/:id/:resource', component: ContentListComponent },
  { path: 'diary', component: DiaryComponent },
  { path: 'home', component: HomepageComponent },
  { path: 'job', component: JobComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-existing', component: LoginExistingComponent },
  { path: 'pro3-list', component: Pro3ListComponent },
  { path: 'procheck-redirect', component: ProcheckRedirectComponent },
  { path: 'product-selector', component: ProductSelectorContainerComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'optin', component: UserOptinComponent },
  { path: 'onboarding', component: UserOnboardingComponent },
  { path: 'register/:mode', component: RegisterComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'rewards', component: RewardsComponent },
  { path: 'rewards/demokit', component: DemokitRedeemComponent },
  { path: 'rewards/love2shop', component: VoucherRedeemComponent },
  { path: 'rewards/spares', component: SparesRedeemComponent },
  { path: 'roi-calculator', component: RoiCalculatorComponent },
  { path: 'service-dashboard', component: ServiceDashboardComponent },
  { path: 'submission-selection', component: SubmissionSelectionComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'warranty', component: WarrantyComponent },
  { path: 'warranty-list', component: WarrantyListComponent },
  { path: 'warranty-statistics', component: WarrantyStatisticsComponent },

  { path: 'admin-dashboard', component: AdminDashboardComponent },
  { path: 'admin-events', component: AdminEventsComponent },
  { path: 'admin-jobs', component: AdminInstallerJobsComponent },
  { path: 'admin-rewards', component: AdminRewardsComponent },
  {
    path: 'admin-pro3',
    component: AdminPro3Component,
    data: { useNewPermissions: true },
  },
  { path: 'admin-users', component: AdminUsersComponent },

  {
    path: 'admin-content',
    component: AdminContentComponent,
    children: [
      { path: '', redirectTo: 'pages', pathMatch: 'full' },
      { path: 'pages', component: AdminContentPagesComponent },
      { path: 'resources', component: AdminContentResourcesComponent },
    ],
  },

  //redirects links
  {
    path: 'privacy-policy',
    component: PageNotFoundComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: environment.externalRoutes.policy_privacy,
    },
  },
  {
    path: 'license',
    component: PageNotFoundComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: environment.externalRoutes.license,
    },
  },
  {
    path: 'faqs',
    component: PageNotFoundComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: environment.externalRoutes.faqs,
    },
  },
  // Fall-through to PageNotFoundComponent
  { path: '**', component: PageNotFoundComponent },
];

export const appRouting: ModuleWithProviders<any> = RouterModule.forRoot(
  routes,
  {}
);
