<section>
  <p><strong>PLEASE READ THESE LICENCE TERMS CAREFULLY</strong></p>
  <br>
  <p>BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THESE TERMS WHICH WILL BIND YOU.</p>
  <p>IF YOU DO NOT AGREE TO THESE TERMS, CLICK ON THE "REJECT" BUTTON BELOW AND YOUR ACCESS TO THE APP WILL BE REJECTED.</p>
  <br>
  <p><strong>WHO WE ARE AND WHAT THIS AGREEMENT DOES</strong></p>
  <p>We ADEY Commercial Limited license you to use:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	ADEY Water Testing mobile application software, the data supplied with the software, (<strong>App</strong>) and any updates or supplements to it.
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	The service you connect to via the App and the content we provide to you through it (<strong>Service</strong>).</p>
  <p>as permitted in these terms.</p>
  <br>
  <p><strong>YOUR PRIVACY</strong></p>
  <p>We only use any personal data we collect through your use of the App and the Service in the ways set out in our privacy notice adey.com/privacy-policy.</p>
  <p>Please be aware that internet transmissions are never completely private or secure and that any message or information you send using the App or the Service may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.</p>
  <br>
  <p><strong>APPLE’S APP STORE AND GOOGLE’S PLAY STORE'S TERMS ALSO APPLY</strong></p>
  <p>The ways in which you can use the App may also be controlled by the Apple’s App Store and Google’s Play Store's rules and policies as may be in force and in place from time to time.  Apple’s App Store and Google’s Play Store's rules and policies will apply instead of these terms where there are differences between the two.</p>
  <br>
  <p><strong>HOW YOU MAY USE THE APP, INCLUDING HOW MANY DEVICES YOU MAY USE IT ON</strong></p>
  <p>In return for your agreeing to comply with these terms you may:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	download or stream a copy of the App onto any of your devices that will support the use of the App and view, use and display the App and the Service on such devices for your personal and business purposes.</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	receive and use any free supplementary software code or update of the App incorporating "patches" and corrections of errors as we may provide to you.</p>
  <br>
  <p><strong>YOU MUST BE 18 TO ACCEPT THESE TERMS AND BUY THE APP</strong></p>
  <p>You must be 18 or over to accept these terms and use the App.</p>
  <br>
  <p><strong>CHANGES TO THESE TERMS</strong></p>
  <p>We may need to change these terms to reflect changes in law or best practice or to deal with additional features which we introduce.</p>
  <p>In the event we change these terms the updated terms will be presented to you for acceptance next time you open the App.</p>
  <p>If you do not accept the notified changes you will not be permitted to continue to use the App and the Service.</p>
  <br>
  <p><strong>UPDATE TO THE APP AND CHANGES TO THE SERVICE</strong></p>
  <p>From time to time we may automatically update the App and change the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively, we may ask you to update the App for these reasons.</p>
  <p>If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App and the Service.</p>
  <p>The App will always work with the current or previous version of the operating system (as it may be updated from time to time) and match the description of it provided to you when you downloaded it.</p>
  <br>
  <p><strong>WE MAY COLLECT TECHNICAL DATA ABOUT YOUR DEVICE</strong></p>
  <p>By using the App or the Service, you agree to us collecting and using technical information about the devices you use the App on and related software, hardware and peripherals to improve our products and to provide any Service to you.</p>
  <br>
  <p><strong>WE MAY COLLECT LOCATION DATA (BUT YOU CAN TURN LOCATION SERVICES OFF)</strong></p>
  <p>The Service may make use of location data sent from your devices. You can turn off this functionality at any time by turning off the location services settings for the App on the device. If you use this Service, you consent to us and our affiliates' and licensees' transmission, collection, retention, maintenance, processing and use of your location data and queries to provide and improve location-based and road traffic-based products and services.</p>
  <p>You may stop us collecting such data at any time by turning off the location services settings on your phone but, by doing so, your use of certain parts of the Service will be limited or unavailable.</p>
  <br>
  <p><strong>LICENCE RESTRICTIONS</strong></p>
  <p>You agree that you will:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	not rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Service in any form, in whole or in part to any person without prior written consent from us;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	not copy the App or Service, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, or Service nor permit the App or the Service or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Service on devices as permitted in these terms;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Service nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (<strong>Permitted Objective</strong>), and provided that the information obtained by you during such activities:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	is not disclosed or communicated without Adey Innovation Limited’s prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	is not used to create any software that is substantially similar in its expression to the App;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	is kept secure; and</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	is used only for the Permitted Objective;</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App or the Service.</p>
  <br>
  <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
  <p>All intellectual property rights in the App, and the Service throughout the world belong to us (or our licensors) and the rights in the App and the Service are licensed (not sold) to you. You have no intellectual property rights in, or to, the App, or the Service other than the right to use them in accordance with these terms.</p>
  <br>
  <p><strong>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</strong></p>
  <p><strong>We are responsible to you for foreseeable loss and damage caused by us</strong>. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.</p>
  <br>
  <p><strong>We do not exclude or limit in any way our liability to you where it would be unlawful to do so</strong>. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</p>
  <br>
  <p><strong>When we are liable for damage to your property</strong>. If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
  <br>
  <p><strong>We are not liable for business losses</strong>. If you use the App for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
  <br>
  <p><strong>Limitations to the App and the Service</strong>. The App and the Service are provided for general information purposes only. They do not offer advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of information obtained from the App or the Service. Although we make reasonable efforts to update the information provided by the App and the Service, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date.</p>
  <br>
  <p><strong>Please back-up content and data used with the App</strong>. We recommend that you back up any content and data used in connection with the App, to protect yourself in case of problems with the App or the Service.</p>
  <br>
  <p><strong>Check that the App and the Service are suitable for you</strong>. The App and the Service have not been developed to meet your individual requirements. Please check that the facilities and functions of the App and the Service (as described on the Apple App Store and Google Play Store sites) meet your requirements.</p>
  <br>
  <p><strong>We are not responsible for events outside our control</strong>. If our provision of the Service or support for the App or the Service is delayed by an event outside our control, then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any Service you have paid for but not received.</p>
  <br>
  <p><strong>WE MAY END YOUR RIGHTS TO USE THE APP AND THE SERVICE IF YOU BREAK THESE TERMS</strong></p>
  <p>We may end your rights to use the App and Service at any time by contacting you if you have broken these terms in a serious way. If what you have done can be put right, we will give you a reasonable opportunity to do so.</p>
  <p>If we end your rights to use the App and Service:</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	You must stop all activities authorised by these terms, including your use of the App and any Service.</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	You must delete or remove the App from all devices in your possession and immediately destroy all copies of the App which you have and confirm to us that you have done this.</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	We may remotely access your devices and remove the App from them and cease providing you with access to the Service.</p>
  <br>
  <p><strong>WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE</strong></p>
  <p>We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
  <br>
  <p><strong>YOU NEED OUR CONSENT TO TRANSFER YOUR RIGHTS TO SOMEONE ELSE</strong></p>
  <p>You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</p>
  <br>
  <p><strong>NO RIGHTS FOR THIRD PARTIES</strong></p>
  <p>This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
  <br>
  <p><strong>IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL CONTINUE IN FORCE</strong></p>
  <p>Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
  <br>
  <p><strong>EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE IT LATER</strong></p>
  <p>Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
  <br>
  <p><strong>WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL PROCEEDINGS</strong></p>
  <p>These terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland, you can bring legal proceedings in either the Scottish or the English courts. If you live in Northern Ireland, you can bring legal proceedings in either the Northern Irish or the English courts.</p>
</section>
