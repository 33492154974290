
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ProCheckSite } from '../../models/procheck-site';
import {
  LoginRequestAction,
  LoginWaterTesterRequestAction,
  LogoutAction,
  ResendActivationEmailRequestAction,
} from '../../state-management/actions/login-details';
import { SystemMessageRequestAction } from '../../state-management/actions/system-message';
import { State as LoginDetailsState } from '../../state-management/reducers/login-details';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';

/**
 * Summary
 *    Allow the user to login to the site.
 *
 *
 * Description
 *    Provide the user with a way to log into the ADEY Pro website, displays the login view
 *    and prompts the user to reset their password or register an account if they haven't done so.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // Reference to login state
  public loginDetails$: Observable<LoginDetailsState>;
  // Flag to show if form has been submitted
  private submitted: boolean = false;

  // Instance to store the login form data
  public loginForm: any;

  private redirectTo: string;

  public displayPromotion: Boolean;

  public proCheckView: boolean = false;

  /**
   * Constructor for page
   *
   * @param {Store<StoreState>} _store  Initialises Store object
   * @param {Router} router             Initialises router object providing navigation between pages
   * @param route
   * @param {MatDialog} dialog          Initialises a dialog object so that a dialog can be opened from the page
   */
  constructor(
    private _store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    // Set a reference to the login store
    this.loginDetails$ = this._store.select('loginDetails');
  }

  /**
   * Create the login form on page load
   */
  ngOnInit() {
    this.proCheckView = ProCheckSite.getCheck();

    this.route.queryParams.pipe(
      filter(params => params.redirectTo))
      .subscribe(params => {
        this.redirectTo = params.redirectTo;
      });

    if (this.redirectTo)
      localStorage.setItem('redirectTo', this.redirectTo);

    // Create the form with a username and password control
    this.loginForm = new UntypedFormGroup({
      'username': new UntypedFormControl('', [Validators.required]),
      'password': new UntypedFormControl('', [Validators.required])
    });

    this._store.dispatch(
      new SystemMessageRequestAction({ type: '' })
    );
  }

  /**
   * Apply a class to the form field dependant on the form's state
   *
   * @param {string} fieldName    The name of the field to check
   * @param {string} extraClasses Extra classes to add to the html
   *
   * @return {string} Return a string of classes to the form field object
   */
  formGroupClass(fieldName: string, extraClasses: string = null): string {
    let classes = 'form-group';
    if (extraClasses)
      classes += ` ${extraClasses}`;

    const ff = this.loginForm.controls[fieldName];
    if (!ff)
      return classes;

    return `${classes}${!ff.valid && (this.submitted || ff.dirty || ff.touched) ? ' has-error' : ''}`;
  }

  /**
   * Called when the user submits the form
   */
  onSubmitForm() {
    this.submitted = true;
    if (this.loginForm.valid) {
      // If the form is valid dispatch LoginRequestAction to pass the form data
      // to the API. The loginEffects will handle the redirection based on the
      // paths passed into the request.
      if (this.proCheckView) {
        this._store.dispatch(new LoginWaterTesterRequestAction({
          email: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value,
        }));
      } else {
        this._store.dispatch(new LoginRequestAction({
          email: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value,
          redirectClientAdmin: '/admin-dashboard',
          redirectPage: this.redirectTo ? this.redirectTo : null,
        }));
      }
    }
  }

  public resendActivation(): void {
    this._store.dispatch(new ResendActivationEmailRequestAction({
      email: this.loginForm.controls.username.value,
    }));
  }

  /**
   * Navigate the user to the register page when they click the 'Create a profile' button
   */
  goToRegister() {
    if (this.proCheckView) {
      this.router.navigate(['/register-water-tester']);
    } else {
      this.router.navigate(['/register/simple']);

    }
  }

  /**
   * Open a new dialog with the ResetPasswordModalComponent when the user clicks the 'Forgotten password' button
   */
  goToForgotten() {
    const dialogRef = this.dialog.open(ResetPasswordModalComponent, {
      panelClass: 'feature-modal-dialog'
    });

    // Dispatch RequestResetPasswordRequestAction if the modal is closed with a result
    dialogRef.afterClosed().subscribe(() => {
      this._store.dispatch(new LogoutAction());
    });

  }

  public goToSense(): void {
    window.open('https://sense.adey.com/', '_blank');
  }
}
