<h2 matDialogTitle>Edit user rewards</h2>

<mat-dialog-content>

  <app-message-panel kind="ERROR" *ngIf="errors">{{errors}}</app-message-panel>

  <form [formGroup]="fg" novalidate>
    <mat-form-field>
      <input matInput formControlName="pointsEarned" placeholder="Points earned" />
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="pointsSpent" placeholder="Points spent" />
    </mat-form-field>
  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</mat-dialog-actions>
