<h2 matDialogTitle>Events on {{data.date | date}}</h2>

<mat-dialog-content>
  <p *ngIf="!data.events">
    No events on this date
  </p>
  <mat-list *ngIf="data.events">
    <app-diary-event *ngFor="let e of data.events" [event]="e" (onEventClick)="onSelectEvent($event)" colorScheme="dark"></app-diary-event>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button matDialogClose>Close</button>
</mat-dialog-actions>
