import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Router } from '@angular/router';

import { ResourceItem } from '../../../models/resources';

/**
 * Summary
 *    Display a series of resources in a widget.
 *    
 *
 * Description
 *    Display a series of interactable resource items within a widget, can be used in multiple places.
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-resource-widget',
  templateUrl: './resource-widget.component.html',
  styleUrls: ['./resource-widget.component.scss']
})
export class ResourceWidgetComponent implements OnInit {

  // List of resources to display
  @Input() resources: ResourceItem[] = [];

  // Flag to indicate if resources are currently being loaded
  @Input() pending: boolean = false;

  /**
   * Constructor for page
   *
   * @param {Router} router Initialises Router object providing navigation between pages
   */
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  /**
   * Navigate the user to the content archive
   */
  goToResources() {
    this.router.navigate(['/content-archive']);
  }
}
