import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tc-app-login',
  templateUrl: './tc-app-login.component.html',
  styleUrls: ['./tc-app-login.component.scss']
})
export class TcAppLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
