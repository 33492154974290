import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CommitEventResponse, FetchEventsResponse, FetchEventTypesResponse } from '../../models/diary';
import { DiaryService } from '../../services/diary.service';
import * as ActionTypes from '../actions/diary';

@Injectable()
export class DiaryEffects {
  constructor(
    private service: DiaryService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchEventsRequestAction, call DiaryService::fetchEvents() and
   * dispatch a new FetchEventsResponseAction with the response.
   */
   fetchEventsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_EVENTS_REQUEST),
    switchMap((req: ActionTypes.FetchEventsRequestAction): Observable<Action> => {
      return this.service.fetchEvents().pipe(
        map((res: FetchEventsResponse): ActionTypes.FetchEventsResponseAction =>
          new ActionTypes.FetchEventsResponseAction(res)
        ));
    })));

  /**
   * For an AddEventAction, call DiaryService::addEvent() and dispatch a new
   * CommitEventAction for the DiaryEvent.
   */
   addEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ADD_EVENT),
    switchMap((req: ActionTypes.AddEventAction): Observable<Action> => {
      return this.service.addEvent(req.payload).pipe(
        map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        ));
    })));

  /**
   * For a BookEventAction, call DiaryService::bookEvent() and dispatch a new
   * CommitEventAction for the DiaryEvent.
   */
   bookEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.BOOK_EVENT),
    switchMap((req: ActionTypes.BookEventAction): Observable<Action> => {
      return this.service.bookEvent(req.payload).pipe(
        map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        ));
    })));

  /**
   * For a DeleteEventAction, call DiaryService::deleteEvent() and dispatch a
   * new CommitEventAction for the DiaryEvent.
   */
   deleteEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DELETE_EVENT),
    switchMap((req: ActionTypes.DeleteEventAction): Observable<Action> => {
      return this.service.deleteEvent(req.payload).pipe(
        map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        ));
    })));

  /**
   * For a FetchEventTypesRequestAction, call DiaryService::fetchEventTypes()
   * and dispatch a new FetchEventTypesResponseAction with the response.
   */
   fetchEventTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_EVENT_TYPES_REQUEST),
    switchMap((req: ActionTypes.FetchEventTypesRequestAction): Observable<ActionTypes.FetchEventTypesResponseAction> =>
      this.service.fetchEventTypes().pipe(
        map((res: FetchEventTypesResponse): ActionTypes.FetchEventTypesResponseAction =>
          new ActionTypes.FetchEventTypesResponseAction(res)
        ))
    )));

  /**
   * For an UpdateEventAction, call DiaryService::updateEvent() and dispatch a
   * new CommitEventAction for the DiaryEvent.
   */
   updateEvent$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_EVENT),
    switchMap((req: ActionTypes.UpdateEventAction): Observable<Action> => {
      return this.service.updateEvent(req.payload).pipe(
        map((res: CommitEventResponse): ActionTypes.CommitEventAction =>
          new ActionTypes.CommitEventAction(res)
        ));
    })));
}
