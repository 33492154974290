/**
 * registration actions: defines all actions relating to user registration.
 */
import { Action } from '@ngrx/store';

import {
  FetchUserRolesResponse,
  RegistrationRequest,
  RegistrationResponse,
  SimpleRegistrationRequest,
} from '../../models/registration';
import { RegistrationWaterTesterRequest, RegistrationWaterTesterResponse } from '../../models/registration-watert-tester';



// Import models

// Action type definitions
export const FETCH_USER_ROLES_REQUEST = 'FETCH_USER_ROLES_REQUEST';
export const FETCH_USER_ROLES_RESPONSE = 'FETCH_USER_ROLES_RESPONSE';
export const REGISTRATION_INIT = 'REGISTRATION_INIT';
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const SIMPLE_REGISTRATION_REQUEST = 'SIMPLE_REGISTRATION_REQUEST';
export const REGISTRATION_RESPONSE = 'REGISTRATION_RESPONSE';
export const REGISTRATION_WATER_TESTER_REQUEST = 'REGISTRATION_WATER_TESTER_REQUEST';
export const REGISTRATION_WATER_TESTER_RESPONSE = 'REGISTRATION_WATER_TESTER_RESPONSE';


// Action definitions
export class FetchUserRolesRequestAction implements Action {
  readonly type = FETCH_USER_ROLES_REQUEST;
}

export class FetchUserRolesResponseAction implements Action {
  readonly type = FETCH_USER_ROLES_RESPONSE;
  constructor(public payload: FetchUserRolesResponse) { }
}

export class RegistrationInit implements Action {
  readonly type = REGISTRATION_INIT;
}

export class RegistrationRequestAction implements Action {
  readonly type = REGISTRATION_REQUEST;
  constructor(public payload: RegistrationRequest) { }
}

export class SimpleRegistrationRequestAction implements Action {
  readonly type = SIMPLE_REGISTRATION_REQUEST;
  constructor(public payload: SimpleRegistrationRequest) { }
}

export class RegistrationResponseAction implements Action {
  readonly type = REGISTRATION_RESPONSE;
  constructor(public payload: RegistrationResponse) { }
}

export class RegistrationWaterTesterRequestAction implements Action {
  readonly type = REGISTRATION_WATER_TESTER_REQUEST;
  constructor(public payload: RegistrationWaterTesterRequest) { }
}

export class RegistrationWaterTesterResponseAction implements Action {
  readonly type = REGISTRATION_WATER_TESTER_RESPONSE;
  constructor(public payload: RegistrationWaterTesterResponse) { }
}

// Export Actions type as a union of all defined actions
export type Actions =
  | FetchUserRolesRequestAction
  | FetchUserRolesResponseAction
  | RegistrationInit
  | RegistrationRequestAction
  | SimpleRegistrationRequestAction
  | RegistrationResponseAction
  | RegistrationWaterTesterRequestAction
  | RegistrationWaterTesterResponseAction
  ;
