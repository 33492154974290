/**
 * Mock for ServiceRecordService (see main service for full details)
 */


import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';




import * as moment from 'moment';

import { Injectable } from '@angular/core';

import {
  AddServiceRequestResponse,
  FetchServiceRecordStatsResponse,
  FetchServiceRecordsRequest,
  FetchServiceRecordsResponse,
  ServiceRecord,
  ServiceRecordFlags,
  ServiceRequest,
  SetServiceRecordFlagRequest,
  SetServiceRecordFlagResponse,
  UpdateServiceRecordRequest,
  UpdateServiceRecordResponse,
} from '../models/service-record';

@Injectable()
export class ServiceRecordService {

  addServiceRequest(sr: ServiceRequest): Observable<AddServiceRequestResponse> {
    const valid =
      sr.typeOfService && sr.customerTitle && sr.customerForename && sr.customerSurname;
    return observableOf({
        error:          valid ? null : 'Invalid service request',
        serviceRequest: sr,
      }).pipe(
      delay(1000));
  }

  fetchRecords(req: FetchServiceRecordsRequest): Observable<FetchServiceRecordsResponse> {

    const validPage     = req.pageNo > 0 && req.pageNo <= 3;
    const numToGenerate = validPage ? req.perPage : 0;
    let   records       = [];

    for (let i = 0; i < numToGenerate; i++)
      records.push(ServiceRecord.getExample());

    if (req.customerQuery)
      records = records.filter((sr: ServiceRecord): boolean => {
        const name = `${sr.customer.customerTitle} ${sr.customer.customerForename} ${sr.customer.customerSurname}`;
        return !!name.match(new RegExp(req.customerQuery, 'i'));
      });

    return observableOf({
        error:      validPage ? null : 'Invalid page number',
        totalPages: req.customerQuery ? Math.ceil(records.length / req.perPage) : 3,
        records,
      }).pipe(
      delay(1000));
  }

  fetchStats(): Observable<FetchServiceRecordStatsResponse> {
    const statsCompleted = Math.floor(Math.random() * 50) + 10;
    const statsPending   = Math.floor(statsCompleted / 2);

    return observableOf({
        error: null,
        stats: {completed: statsCompleted, pending: statsPending},
      }).pipe(
      delay(1000));
  }

  setFlags(req: SetServiceRecordFlagRequest): Observable<SetServiceRecordFlagResponse> {
    const valid = req.record.id;
    return observableOf({
        record: req.record,
        error:  valid ? null : 'Invalid flags or no job record ID',
        flags:  req.flags,
      }).pipe(
      delay(1000));
  }

  update(req: UpdateServiceRecordRequest): Observable<UpdateServiceRecordResponse> {
    const valid = req.record && req.record.id;
    return observableOf({
        error:  valid ? null : 'No job record ID specified',
        record: req.record,
      }).pipe(
      delay(1000));
  }

}
