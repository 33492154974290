/**
 * Provides AdminPro3Service
 *
 * @todo: Sort out some inheritance in this file
 *
 * @author        Ollie Lowson ( @ollielowson )
 * @copyright    2016-2019 System 15 Limited
 */


import {of as observableOf, Observable, throwError as _throw} from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';
// Created using PhpStorm

//required classes



import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

import {
  AdminDismissAlertRequest,
  AdminDevice,
  AdminDeviceStatus,
  AdminDeviceDetailRequest,
  AdminDeviceDetailItem,
  AdminDeviceAlert, AdminDeviceStatusRequest, AdminPro3RegisterWarrantyRequest, AdminDeviceNote, AdminDeviceNoteRequest
} from '../models/admin-pro3';
import {DFResponse, DFSPRequest, DFTableRequest} from '../models/df-proclub';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import * as moment from 'moment';

/**
 * Service class to provide data connection for the Pro3 Admin portal
 */
@Injectable()
export class AdminPro3Service {

    /**
     * Inject the API service
     *
     * @param apiService Application's main API data connection
     */
    constructor(
      private apiService: ApiService,
      private snackbar: MatSnackBar
    ) {
    }

    /**
     * Get the data frm the server
     *
     * @param req
     */
    getWarrantyInfo(req: DFSPRequest<{device_id: number}>) {
      //Build the URI
        const uri = '/pro3/device-registration?' + req.getQueryString();

        //Attempt the post
        return this.apiService.apiGet(uri)
            .pipe(
                map(result => result)
            );
    }

  /**
   * Save changes to a warranty
   *
   * @param req
   */
  registerWarranty(req: DFSPRequest<AdminPro3RegisterWarrantyRequest>) {
        //Build the URI
        const uri = '/pro3/device-registration?';

        //Fix the dates
        moment().isUTC();
        const params = {...req.params, ...{
            servicerecord_service_date: moment(req.params.servicerecord_service_date).format('YYYY-MM-DD'),
            servicerecord_last_service_date: moment(req.params.servicerecord_last_service_date).format('YYYY-MM-DD'),
            warranty_install_date: moment(req.params.warranty_install_date).format('YYYY-MM-DD'),
            job_filter_expiry_date: moment(req.params.job_filter_expiry_date).format('YYYY-MM-DD'),
        }};

        //Is this POST or PATCH?
        if (req.params.device_id !== undefined) {
            return this.apiService.apiPatch(uri, params);
        } else {
            return this.apiService.apiPost(uri, params);
        }
    }

  /**
   * Get any note for the device
   *
   * @param req
   */
  getDeviceNote(req: AdminDeviceNoteRequest): Observable<DFResponse<AdminDeviceNote>>  {
        //Build the URI to submit
        const uri = '/pro3/note/' + req.deviceId;

        //Send the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<AdminDeviceNote> => {
                return res;
            }),
            catchError((err: any): Observable<DFResponse<AdminDeviceNote>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Could not fetch device note: ${err.error.message}`
                        : err,
                    data: null,
                    paging: null,
                    timing: null,
                })
            ),);
    }

  /**
   * Save changes to a note
   *
   * @param note
   */
  saveDeviceNote(note: Partial<AdminDeviceNote>): Observable<any> {
      //Should we create or update?
      if (note.id !== undefined) {
        return this.apiService.apiPatch('/pro3/note/' + note.device_id, note);
      } else {
        return this.apiService.apiPost('/pro3/note', note);
      }
    }

  /**
   * Get a filtered list of devices
   *
   * @param req Request object
   * @param exportData
   */
    getDeviceList(req: DFTableRequest, exportData = false): Observable<DFResponse<AdminDevice>> {
        //Get the full filter string given a provided device filter
        req.filter = req.buildFilter();

        //Build the URI to submit
        let uri = '/pro3/device?';
        if (exportData) {
          uri = '/pro3/device-export?';
        }

        uri += req.getFullQuery();

        //Send the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<AdminDevice> => {
                return res;
            }),
        catchError((err: any): Observable<DFResponse<AdminDevice>> =>
            observableOf({
                error: err && err.error && err.error.message
                    ? `Could not fetch device data: ${err.error.message}`
                    : err,
                data: null,
                paging: null,
                timing: null,
            })
        ),);
    }

    /**
     * Get the current system status
     *
     * @param req Request object
     */
    getPro3Status(req: DFSPRequest<AdminDeviceStatusRequest>): Observable<DFResponse<AdminDeviceStatus>> {
        //Build the URI
        const uri = '/pro3/device-status?' + req.getQueryString();

        //Make the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<AdminDeviceStatus> => {
                return res;
            }),
            catchError((err: any): Observable<DFResponse<AdminDeviceStatus>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Could not fetch device status info: ${err.error.message}`
                        : err,
                    data: null,
                    paging: null,
                    timing: null,
                })
            ),);
    }

    /**
     * Get all alerts for devices, filtered by req and visible to the current user
     *
     * @param req
     * @param exportData
     */
    getDeviceAlerts(req: DFTableRequest, exportData = false): Observable<DFResponse<AdminDeviceAlert>> {
        //Get the full filter string given a provided device filter
        req.filter = req.buildFilter();

        //Build the URI to submit
        let uri = '/pro3/alert?';
        if (exportData) {
          uri = '/pro3/alert-export?';
        }

        uri += req.getFullQuery();

        //Make the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<AdminDeviceAlert> => {
                return res;
            }),
            catchError((err: any): Observable<DFResponse<AdminDeviceAlert>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Could not fetch alert info: ${err.error.message}`
                        : err,
                    data: null,
                    paging: null,
                    timing: null,
                })
            ),);
    }

    /**
     * Get the details for a given device
     *
     * @param req
     */
    getDeviceDetail(req: AdminDeviceDetailRequest): Observable<DFResponse<AdminDeviceDetailItem>> {
        //Build the URI to submit
        const uri = '/pro3/device-detail/' + req.filterId;

        //Make the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiGet(uri).pipe(
            map((res: any): DFResponse<AdminDeviceDetailItem> => {
                return res;
            }),
            catchError((err: any): Observable<DFResponse<AdminDeviceDetailItem>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Could not fetch filter data: ${err.error.message}`
                        : err,
                    deviceDetail: null,
                })
            ),);
    }

    /**
     * Make the request to dismiss an alert
     *
     * @param req
     */
    dismissAlert(req: AdminDismissAlertRequest): Observable<any> {
        //Build the URI to submit
        const uri = '/pro3/alert/' + req.alertId + '/dismiss/' + req.dismissFor;

        //Make the request, put the response in a DFResponse object, catch any errors
        return this.apiService.apiPatch(uri, []).pipe(
            map((res: any): DFResponse<any> => {
                const valid: boolean = res && res.resource && res.resource[0] && res.resource[0].id;
                //Notify the user on success
                if (valid) {
                  this.snackbar.open('Alert dismissed', '', {duration: 3000});
                }
                return {
                    error: (valid) ? null : 'Invalid response from server',
                };
            }),
            catchError((err: any): Observable<DFResponse<any>> =>
                observableOf({
                    error: err && err.error && err.error.message
                        ? `Unable to dimiss alert: ${err.error.message}`
                        : 'Unable to dismiss alert',
                    message: null,
                })
            ),);
    }
}
