<div class="container-fluid">
  <div class="row content-page">

    <div class="container-title has-sub">
      <h1 class="white">
        <app-page-content page="BUSINESS_TOOLS" field="title"></app-page-content>
      </h1>
    </div>

    <app-page-content page="BUSINESS_TOOLS" field="intro" class="intro-above-content color-white"></app-page-content>

    <div class="background-white padded-container col-xs-12">
      <div class="row resource-wrapper" *ngIf="resources$ | async; let resource">
        <div class="col-xs-4 item-wrapper" *ngFor="let item of resource.items">
          <div class="border-box border-light-gray">
            <h2 class="color-adey-red">{{ item.title }}</h2>
            <img *ngIf="item.imageUrl" [src]="item.imageUrl" />
            <p class="description color-adey-red">{{ item.description }}</p>
            <button *ngIf="item.attachment?.downloadName" class="btn background-green color-white no-radius"
              routerLink="/business-tools/{{ item.attachment?.downloadName }}">
              Find out more
            </button>
            <a *ngIf="item.attachment?.url" class="btn background-green color-white no-radius"
              [href]="item.attachment?.url" target="_blank">
              Already have an account? Sign in.
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>