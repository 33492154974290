import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FetchUserSuggestionResponse } from '../../models/user-suggestion';
import { AdminService } from '../../services/admin.service';
import * as ActionTypes from '../actions/user-suggestion';

@Injectable()
export class UserSuggestionEffects {
  constructor(
    private service: AdminService,
    private actions$: Actions
  ) { }

  /**
   * For a FetchUserSuggestionRequestAction, call
   * AdminService::fetchUserSuggestions() and dispatch a new
   * FetchUserSuggestionResponseAction with the response.
   */
   fetchUserSuggestions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_USER_SUGGESTION_REQUEST),
    switchMap((req: ActionTypes.FetchUserSuggestionRequestAction): Observable<ActionTypes.FetchUserSuggestionResponseAction> =>
      this.service.fetchUserSuggestions(req.payload).pipe(
        map((res: FetchUserSuggestionResponse): ActionTypes.FetchUserSuggestionResponseAction =>
          new ActionTypes.FetchUserSuggestionResponseAction(res)
        ))
    )));
}
