import { Action } from '@ngrx/store';

// Import models
import { CountryResponse } from '../../models/country';

// Action type definitions
export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_RESPONSE = 'COUNTRY_RESPONSE';

export class CountryRequestAction implements Action {
  readonly type = COUNTRY_REQUEST;
}

export class CountryResponseAction implements Action {
  readonly type = COUNTRY_RESPONSE;
  constructor(public payload: CountryResponse) { }
}

// Export Actions type as a union of all defined actions
export type Actions =
  | CountryRequestAction
  | CountryResponseAction;
