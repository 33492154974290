import { Component, Inject } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';

import * as moment from 'moment';

import {
  CompleteFields,
  FilterFields,
  SerialFields,
} from '../../../models/service-record';


/**
 * Summary
 *    Modal dialog used to update a service record flag and to accept
 *    additional input from the user
 *
 * @copyright 2017 ReallyB2B Limited
 */
@Component({
  selector: 'app-flag-update-modal',
  templateUrl: './flag-update-modal.component.html',
  styleUrls: ['./flag-update-modal.component.scss']
})
export class FlagUpdateModalComponent {
  // Update form
  public flagUpdate: UntypedFormGroup;

  // Don't allow installers to update install/service completion date to after todays date
  public maxDate = moment().format();

  // Don't allow installers to update install/service completion date to before 1st of Jan 2017
  public minDate = moment('2017-01-01').format();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FlagUpdateModalComponent>
  ) {
    this.constructForm(data);
  }

  /**
   * Builds the update form based on the flag value and type
   *
   * @param {any} data Data sent to modal during construction
   */
  constructForm(data: any) {

    // Custom validator: makes additional fields required only if the parent
    // service record flag is set
    const fieldRequired = (control: AbstractControl): { [key: string]: boolean } => {
      if (control.root.get('flagChecked') && control.root.get('flagChecked').value === false)
        return null;

      return (control.value === '' ? { error: true } : null);
    };

    if (!data.flagEditFields) {
      // If there are no additional fields, simply show the flag checkbox in
      // the form
      this.flagUpdate = new UntypedFormGroup({
        flagChecked: new UntypedFormControl(this.data.flagChecked),
      });
    }
    else {
      // Build an appropriate form based on the flag being updated

      switch (data.flagTitle) {
        case 'mc1Used':
        case 'mc3Used':
        case 'waterTestComplete':
          const serialFields: SerialFields = data.flagEditFields;

          if (serialFields) {
            this.flagUpdate = new UntypedFormGroup({
              flagChecked: new UntypedFormControl(data.flagChecked),
              serial: new UntypedFormControl(serialFields.serialNumber, [fieldRequired]),
            });
          }
          break;
        case 'magnaCleanFitted':
          const filterFields: FilterFields = data.flagEditFields;

          if (filterFields) {
            this.flagUpdate = new UntypedFormGroup({
              flagChecked: new UntypedFormControl(data.flagChecked),
              filterInstall: new UntypedFormControl(filterFields.filterInstall, [fieldRequired]),
              serial: new UntypedFormControl(filterFields.filterSerial, [fieldRequired]),
              filterProductID: new UntypedFormControl(filterFields.filterProductID, [fieldRequired]),

              filterInstallDate: new UntypedFormControl(
                filterFields.filterInstallDate ? moment(filterFields.filterInstallDate).toDate() : moment().toDate(),
                [fieldRequired]
              ),
            });
          }
          break;

        case 'serviceComplete':
          const completeFields: CompleteFields = data.flagEditFields;

          if (completeFields) {
            this.flagUpdate = new UntypedFormGroup({
              flagChecked: new UntypedFormControl(data.flagChecked),
              completionDate: new UntypedFormControl(completeFields.completionDate, [fieldRequired]),
            });
          }
          break;
      }
    }
  }

  /**
   * Opens the water testing link on the main ADEY site in a new tab
   */
  openWaterTest() {
    window.open('https://watertesting.adey.com/', '_blank');
  }

  /**
   * Closes the dialog with the appropriate response (based on flag) if the
   * form is valid
   */
  submitDialog() {
    if (this.flagUpdate.valid) {
      if (!this.data.flagEditFields) {
        // If no extra fields are needed, close the dialog passing the updated
        // flag value
        this.dialogRef.close({
          flagChecked: this.flagUpdate.get('flagChecked').value,
        });
      }
      else {
        // If extra fields are needed, close the dialog with the appropriate
        // data from the form
        switch (this.data.flagTitle) {
          case 'mc1Used':
          case 'mc3Used':
            this.dialogRef.close({
              flagChecked: this.flagUpdate.get('flagChecked').value,
              editFields: {
                serialNumber: this.flagUpdate.get('serial').value,
              }
            });
            break;
          case 'magnaCleanFitted':
            this.dialogRef.close({
              flagChecked: this.flagUpdate.get('flagChecked').value,
              editFields: {
                filterInstall: this.flagUpdate.get('filterInstall').value,
                filterInstallDate: moment(this.flagUpdate.get('filterInstallDate').value).format('YYYY-MM-DD'),
                filterSerial: this.flagUpdate.get('serial').value,
                filterProductID: this.flagUpdate.get('filterProductID').value,
              }
            });
            break;
          case 'serviceComplete':
            this.dialogRef.close({
              flagChecked: this.flagUpdate.get('flagChecked').value,
              editFields: {
                completionDate: this.flagUpdate.get('completionDate').value
              }
            });
            break;
        }
      }
    }
  }
}
