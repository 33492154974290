/**
 * login models
 */
import { UserRole } from './registration';
import { UserOptIn, UserProfile } from './user-profile';

export interface Permission {
  user_id: string;
  company_id?: number;
  company_name?: string;
  role_id: number;
  role: string;
  permission: string;
  permission_entity: string;
  action: string;
  scope: string;
  user_role_id: number;
}

/**
 * Encapsulates the current user's details
 */
export class CurrentUser {

  email: string;
  title: string;
  forename: string;
  surname: string;

  role_id: number;
  permissions: Permission[];
  roles: UserRole[];

  // True if user is an admin
  admin: boolean;

  // True if account is activated
  active: boolean;

  optIns?: UserOptIn[];

  onboarding_question_answers?: object|null;

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {CurrentUser}
   */
  static fromApi(data: any): CurrentUser {
    const user = <CurrentUser>{
      email: data.email,
      title: data.title,
      forename: data.firstname,
      surname: data.lastname,
      role_id: data.role_id,
      permissions: data.permissions,
      admin: data.role_id === 1,
      active: data.activated,
      optIns: data.user_opt_ins_by_user_id,
      onboarding_question_answers: data.onboarding_question_answers
    };

    return CurrentUser.parseRolesForUser(user);
  }

  /**
   * Creates instance from API data model
   *
   * @param {any} data API data
   * @return {CurrentUser}
   */
  static fromWaterTesterApi(data: any): CurrentUser {
    const user = <CurrentUser>{
      email: null,
      title: null,
      forename: data.firstName,
      surname: data.lastName,
      role_id: null,
      permissions: null,
      admin: false,
      active: true,
      optIns: [],
    };

    return CurrentUser.parseRolesForUser(user);
  }

  static parseRolesForUser(user: CurrentUser): CurrentUser {
    //Get the UserRoles as objects...
    user.roles = user.permissions.map((p): UserRole => <UserRole>{ id: '' + p.role_id, name: p.role })
      //...and then filter for unique ones...
      .filter((value, index, self) =>
        //...by checking each to see if the ID is the same
        self.findIndex((p) => (p.id === value.id)) === index
      );

    return user;
  }

  static userHasRole(user: CurrentUser, roleId: number): boolean {
    try {
      if (!user.roles) {
        CurrentUser.parseRolesForUser(user);
      }
      return user.roles.some(r => r.id === '' + roleId);
    } catch (error) {
      return false;
    }

  }

  static getInfoCompanyForUser(user: CurrentUser): UserCompany {
    let companyInfo = null;
    //get the first company info
    if (user.permissions.length) {
      companyInfo = user.permissions.map((p): UserCompany => <UserCompany>{ company_id: p.company_id, company_name: p.company_name })
        //...and then filter for unique ones...

        .filter((value) =>
          //...by checking each to see if the company id is not null
          value.company_id !== null
        );

      //console.log('company ', companyInfo);
    }
    return companyInfo;
  }
}


export interface ActivateUserRequest {
  email: string;
  token: string;
  magSenseOwned: string;
  homeowner?: boolean;
}

export interface ActivateUserResponse {
  error: string;
  message: string;
  magnacleanse_owned?: string;
  homeowner?: boolean;
}

export interface ConfirmProfileRequest {
  profile: UserProfile;
}

export interface ConfirmProfileResponse {
  error: string;
  profile: UserProfile;
}

export interface CreatePromoShopTokenResponse {
  error: string;
  token: string;
}

export interface FetchUserProfileResponse {
  error: string;
  profile: UserProfile;
}

export interface LoadLoginTokenResponse {
  error: string;
  token: string;
}

export interface LoginRequest {
  email: string;
  password: string;

  // Campaign
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;

  // Optional routes to which to redirect upon response
  redirectClientAdmin?: string;
  redirectPage?: string;
}

export interface LoginResponse {
  ready: boolean;
  error: string;
  user: CurrentUser;
  profile: UserProfile;
  token: string;
  code?: number;
}

export class RequestResetPasswordRequest {
  email: string;
}

export interface RequestResetPasswordResponse {
  error: string;
  message: string;
}

export class ResetPasswordRequest {
  constructor(
    public token: string,
    public username: string,
    public password: string,
    public passwordConfirm: string
  ) { }
}

export interface ResetPasswordResponse {
  error: string;
  message: string;
}

export interface TokenCheckRequest {
  token: string;

  // Optional routes to which to redirect upon response
  redirectClientInvalid?: string;
  redirectClientValid?: string;
  redirectClientValidAdmin?: string;
  redirectPage?: string;
}

export interface TokenCheckResponse {
  ready: boolean;
  valid: boolean;
  error: string;
  user: CurrentUser;
}


export interface RedirectLogin {
  returnPage?: string;
}

export interface UserCompany {
  company_id: number;
  company_name: string;
}

export interface ModalWaterTesterRegisterRequest {
  email: string;
  password: string;
}

export interface WaterTesterRegisterRequest {
  email: string;
  password: string;
  activationCode: string;
  question: string;
  answer: string;
}

export interface WaterTesterRegisterResponse {
  response: string;
  error: string;
}
