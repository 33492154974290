<h2 matDialogTitle>User points report</h2>

<mat-dialog-content>

  <app-message-panel kind="ERROR" *ngIf="errors">{{ errors }}</app-message-panel>
  <app-message-panel kind="INFO" *ngIf="!history.length">This user currently has no promotional points history</app-message-panel>

  <table *ngIf="history.length" datatable class="row-border hover">
    <thead>
      <tr>
        <th>Date</th>
        <th>Product</th>
        <th>Serial number</th>
        <th>Points awarded</th>
        <th>Points promotion</th>
        <th>Promotion code</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let h of history">
        <td>{{ h.createdAt | date:'dd/MM/yy' }}</td>
        <td>{{ h.productId }}</td>
        <td>{{ h.serialNumber }}</td>
        <td>{{ h.pointsAwarded }}</td>
        <td>{{ h.promotionName }}</td>
        <td>{{ h.promotionReference }}</td>
      </tr>
    </tbody>
  </table>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [disabled]="!history.length" (click)="getCSV()">CSV</button>
  <button mat-button [matDialogClose]="false">Close</button>
</mat-dialog-actions>