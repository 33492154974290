<div class="container-fluid reset-component">
  <div class="row wrapper-row">

    <div [class]="'col-xs-12 feature-container' + (proCheckView ? ' has-blue-back':'')">
      <img *ngIf="!proCheckView" src="assets/i/common/adey-logo-white.png" alt="ADEY® ProClub Logo" class="logo" />
      <img *ngIf="proCheckView" src="assets/i/common/logos/adey-pro-check_white_logo_r.png" alt="ADEY ProCheck Logo"
        class="logo" />
      <div class="feature-content transform-y-offset-50 full-white-theme">
        <h1 class="white">Reset your password</h1>

        <app-message-panel kind="ERROR" *ngIf="!token">
          <p>
            Your password cannot be reset as there is no valid reset token. If
            you followed a link to this page, please make sure that the link is
            valid and has not expired.
          </p>
          <p>
            If the link still does not work, click the button below to return
            to the home page and try resetting the password again:
          </p>
          <hr />
          <button class="btn btn-primary" (click)="goToHome()">Home</button>
        </app-message-panel>

        <form class="row" [formGroup]="forgottenForm" *ngIf="token" (ngSubmit)="onSubmitForm()">

          <mat-form-field class="col-lg-6 col-md-7 col-sm-6 col-xs-12">
            <input matInput placeholder="Email" formControlName="username">
            <mat-error *ngIf="!forgottenForm.controls.username.error">{{ getErrorMessage('username') }}</mat-error>
          </mat-form-field>

          <div class="clearfix"></div>

          <mat-form-field class="col-lg-6 col-md-7 col-sm-6 col-xs-12">
            <input matInput placeholder="New password" formControlName="password"
              [type]="passwordHide ? 'password' : 'text'">
            <mat-icon class="cursor-pointer" matSuffix (click)="passwordHide = !passwordHide">{{ passwordHide ?
              'visibility' : 'visibility_off'
              }}</mat-icon>
            <mat-error *ngIf="!forgottenForm.controls.password.error">{{ getErrorMessage('password') }}</mat-error>
          </mat-form-field>

          <div class="clearfix"></div>

          <mat-form-field class="col-lg-6 col-md-7 col-sm-6 col-xs-12">
            <input matInput placeholder="Confirm password" formControlName="password_confirm"
              [type]="passwordConfirmHide ? 'password' : 'text'">
            <mat-icon class="cursor-pointer" matSuffix (click)="passwordConfirmHide = !passwordConfirmHide">{{
              passwordConfirmHide ?
              'visibility' : 'visibility_off' }}</mat-icon>
            <mat-error *ngIf="!forgottenForm.controls.password_confirm.error">{{ getErrorMessage('password_confirm') }}
            </mat-error>
          </mat-form-field>

          <div class="clearfix"></div>

          <div class="col-lg-6 col-md-7 col-sm-6 col-xs-12">
            <br>
            <p>Your password must be at least 8 characters and contain at least 1 lowercase, uppercase
              and special
              character.</p>

            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <button type="submit" class="btn btn-primary full-width color-white white-theme"
                  [disabled]="!forgottenForm.valid || (loginDetails$|async).pending">
                  <span *ngIf="!(loginDetails$|async).pending">Reset password</span>
                  <app-waiting-spinner *ngIf="(loginDetails$|async).pending" kind="spinner" spinnerSize="20" spinnerStroke="2"></app-waiting-spinner>
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div class="sidebar">
      <div class="gradient-sidebar"></div>
    </div>

  </div>
</div>